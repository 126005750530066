import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
  /**
   * The upload of a file.
   *
   * Variables of this type must be set to null in mutations. They will be
   * replaced with a filename from a following multipart part containing a
   * binary file.
   *
   * See: https://github.com/jaydenseric/graphql-multipart-request-spec
   */
  UploadType: any;
};

export type ActiveUsersGroupsType = {
  __typename?: 'ActiveUsersGroupsType';
  month?: Maybe<Scalars['DateTime']>;
  count?: Maybe<Scalars['Int']>;
};

export type Address = {
  __typename?: 'Address';
  /** city */
  city: Scalars['String'];
  /** country */
  country: CountryEnum;
  /** line1 */
  line1: Scalars['String'];
  /** line2 */
  line2?: Maybe<Scalars['String']>;
  /** postal_code */
  postalCode: Scalars['String'];
  /** state */
  state: Scalars['String'];
};

export type AddressInput = {
  /** city */
  city: Scalars['String'];
  /** country */
  country: CountryEnum;
  /** line1 */
  line1: Scalars['String'];
  /** line2 */
  line2?: Maybe<Scalars['String']>;
  /** postal_code */
  postalCode: Scalars['String'];
  /** state */
  state: Scalars['String'];
};

export type AddressNode = Node & {
  __typename?: 'AddressNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  raw?: Maybe<Scalars['String']>;
  raw2?: Maybe<Scalars['String']>;
  addressLine?: Maybe<Scalars['String']>;
  cityState?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  profileSet: ProfileNodeConnection;
  userHasPermission?: Maybe<AddressPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type AddressNodeProfileSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AddressPermission = {
  __typename?: 'AddressPermission';
  /** Can add address */
  addAddress?: Maybe<Scalars['Boolean']>;
  /** Can change address */
  changeAddress?: Maybe<Scalars['Boolean']>;
  /** Can delete address */
  deleteAddress?: Maybe<Scalars['Boolean']>;
  /** Can view address */
  viewAddress?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type AnswerDictInput = {
  key: Scalars['String'];
  data: Scalars['JSONString'];
};

export type AnswerInput = {
  questionId: Scalars['ID'];
  data: Scalars['JSONString'];
};

export type AnswerNode = Node & {
  __typename?: 'AnswerNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  question?: Maybe<QuestionUnion>;
  response: SurveyResponseNode;
  data: Scalars['JSONString'];
  score?: Maybe<Scalars['Int']>;
  maxScore?: Maybe<Scalars['Int']>;
  userHasPermission?: Maybe<AnswerPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type AnswerNodeConnection = {
  __typename?: 'AnswerNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AnswerNodeEdge>>;
};

/** A Relay edge containing a `AnswerNode` and its cursor. */
export type AnswerNodeEdge = {
  __typename?: 'AnswerNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<AnswerNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type AnswerPermission = {
  __typename?: 'AnswerPermission';
  /** Can add answer */
  addAnswer?: Maybe<Scalars['Boolean']>;
  /** Can change answer */
  changeAnswer?: Maybe<Scalars['Boolean']>;
  /** Can delete answer */
  deleteAnswer?: Maybe<Scalars['Boolean']>;
  /** Can view answer */
  viewAnswer?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type AssessmentNode = Node & {
  __typename?: 'AssessmentNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  surveys: SurveyNodeConnection;
  active: Scalars['Boolean'];
  allowImmediateRetake: Scalars['Boolean'];
  /** A description of this Assessment. */
  description?: Maybe<Scalars['String']>;
  assessmentsessionSet: AssessmentSessionNodeConnection;
  userHasPermission?: Maybe<AssessmentPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type AssessmentNodeSurveysArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<SurveyType>>>;
  label_Icontains?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AssessmentNodeAssessmentsessionSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
  partial?: Maybe<Scalars['Boolean']>;
};

export type AssessmentNodeConnection = {
  __typename?: 'AssessmentNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssessmentNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AssessmentNode` and its cursor. */
export type AssessmentNodeEdge = {
  __typename?: 'AssessmentNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<AssessmentNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type AssessmentPermission = {
  __typename?: 'AssessmentPermission';
  /** Can add assessment */
  addAssessment?: Maybe<Scalars['Boolean']>;
  /** Can change assessment */
  changeAssessment?: Maybe<Scalars['Boolean']>;
  /** Can delete assessment */
  deleteAssessment?: Maybe<Scalars['Boolean']>;
  /** Can view assessment */
  viewAssessment?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type AssessmentSessionNode = Node & {
  __typename?: 'AssessmentSessionNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  user: UserNode;
  assessment: AssessmentNode;
  partial: Scalars['Boolean'];
  pickedCareer: Scalars['Boolean'];
  invitations: CompanyUserInvitationNodeConnection;
  completed?: Maybe<Scalars['Boolean']>;
  userHasPermission?: Maybe<AssessmentSessionPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type AssessmentSessionNodeInvitationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AssessmentSessionNodeConnection = {
  __typename?: 'AssessmentSessionNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AssessmentSessionNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AssessmentSessionNode` and its cursor. */
export type AssessmentSessionNodeEdge = {
  __typename?: 'AssessmentSessionNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<AssessmentSessionNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type AssessmentSessionPermission = {
  __typename?: 'AssessmentSessionPermission';
  /** Can add assessment session */
  addAssessmentsession?: Maybe<Scalars['Boolean']>;
  /** Can change assessment session */
  changeAssessmentsession?: Maybe<Scalars['Boolean']>;
  /** Can delete assessment session */
  deleteAssessmentsession?: Maybe<Scalars['Boolean']>;
  /** Can view assessment session */
  viewAssessmentsession?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type AuthorCreateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  shortBio?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['UploadType']>;
  /** Set list of authors groups */
  authorGroups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  courseSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  coursemoduleSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AuthorCreateMutationPayload = {
  __typename?: 'AuthorCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  author?: Maybe<AuthorNode>;
};

export type AuthorModuleConnection = {
  __typename?: 'AuthorModuleConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AuthorModuleEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AuthorModule` and its cursor. */
export type AuthorModuleEdge = {
  __typename?: 'AuthorModuleEdge';
  /** The item at the end of the edge */
  node?: Maybe<AuthorNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type AuthorNode = Node & {
  __typename?: 'AuthorNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  order: Scalars['Int'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  shortBio?: Maybe<Scalars['String']>;
  avatar?: Maybe<ImageFiledType>;
  authorGroups: AuthorsGroupNodeConnection;
  coursemoduleSet: CourseModuleNodeConnection;
  courseSet: CourseNodeConnection;
  userHasPermission?: Maybe<AuthorPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type AuthorNodeAuthorGroupsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AuthorNodeCoursemoduleSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  course?: Maybe<Scalars['ID']>;
  course_Slug?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ID']>;
};

export type AuthorNodeCourseSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type AuthorNodeConnection = {
  __typename?: 'AuthorNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AuthorNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AuthorNode` and its cursor. */
export type AuthorNodeEdge = {
  __typename?: 'AuthorNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<AuthorNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type AuthorPermission = {
  __typename?: 'AuthorPermission';
  /** Can add author */
  addAuthor?: Maybe<Scalars['Boolean']>;
  /** Can change author */
  changeAuthor?: Maybe<Scalars['Boolean']>;
  /** Can delete author */
  deleteAuthor?: Maybe<Scalars['Boolean']>;
  /** Can view author */
  viewAuthor?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type AuthorsGroupCreateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  courseSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AuthorsGroupCreateMutationPayload = {
  __typename?: 'AuthorsGroupCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  authorsGroup?: Maybe<AuthorsGroupNode>;
};

export type AuthorsGroupNode = Node & {
  __typename?: 'AuthorsGroupNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  order: Scalars['Int'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  authors: AuthorNodeConnection;
  courseSet: CourseNodeConnection;
  userHasPermission?: Maybe<AuthorsGroupPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type AuthorsGroupNodeAuthorsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AuthorsGroupNodeCourseSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type AuthorsGroupNodeConnection = {
  __typename?: 'AuthorsGroupNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AuthorsGroupNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AuthorsGroupNode` and its cursor. */
export type AuthorsGroupNodeEdge = {
  __typename?: 'AuthorsGroupNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<AuthorsGroupNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type AuthorsGroupPermission = {
  __typename?: 'AuthorsGroupPermission';
  /** Can add authors group */
  addAuthorsgroup?: Maybe<Scalars['Boolean']>;
  /** Can change authors group */
  changeAuthorsgroup?: Maybe<Scalars['Boolean']>;
  /** Can delete authors group */
  deleteAuthorsgroup?: Maybe<Scalars['Boolean']>;
  /** Can view authors group */
  viewAuthorsgroup?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type AuthorsGroupUpdateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  courseSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AuthorsGroupUpdateMutationPayload = {
  __typename?: 'AuthorsGroupUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  authorsGroup?: Maybe<AuthorsGroupNode>;
};

export type AuthorUpdateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  shortBio?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  avatar?: Maybe<Scalars['UploadType']>;
  /** Set list of authors groups */
  authorGroups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  courseSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  coursemoduleSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AuthorUpdateMutationPayload = {
  __typename?: 'AuthorUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  author?: Maybe<AuthorNode>;
};

export type Autocomplete = {
  __typename?: 'Autocomplete';
  companies?: Maybe<BaseCompanyNodeConnection>;
  users?: Maybe<UserNodeConnection>;
  surveys?: Maybe<SurveyNodeConnection>;
  templates?: Maybe<DbtemplatesNodeConnection>;
  liveMeetings?: Maybe<ZoomMeetingNodeConnection>;
};

export type AutocompleteCompaniesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
};

export type AutocompleteUsersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_Icontains?: Maybe<Scalars['String']>;
  email_Istartswith?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_Icontains?: Maybe<Scalars['String']>;
  firstName_Istartswith?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_Icontains?: Maybe<Scalars['String']>;
  lastName_Istartswith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  anonymousUser?: Maybe<Scalars['Boolean']>;
  manageParticipationCourseId?: Maybe<Scalars['String']>;
};

export type AutocompleteSurveysArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<SurveyType>>>;
  label_Icontains?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AutocompleteTemplatesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  label_Icontains?: Maybe<Scalars['String']>;
  label_Istartswith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type AutocompleteLiveMeetingsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  topic_Icontains?: Maybe<Scalars['String']>;
  topic_Istartswith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type AvatarCreateMutationInput = {
  avatar?: Maybe<Scalars['UploadType']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AvatarCreateMutationPayload = {
  __typename?: 'AvatarCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  avatar?: Maybe<AvatarNode>;
};

export type AvatarNode = Node & {
  __typename?: 'AvatarNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  user: UserNode;
  primary: Scalars['Boolean'];
  avatar?: Maybe<ImageFiledType>;
  dateUploaded: Scalars['DateTime'];
  userHasPermission?: Maybe<AvatarPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type AvatarNodeConnection = {
  __typename?: 'AvatarNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AvatarNodeEdge>>;
};

/** A Relay edge containing a `AvatarNode` and its cursor. */
export type AvatarNodeEdge = {
  __typename?: 'AvatarNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<AvatarNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type AvatarPermission = {
  __typename?: 'AvatarPermission';
  /** Can add avatar */
  addAvatar?: Maybe<Scalars['Boolean']>;
  /** Can change avatar */
  changeAvatar?: Maybe<Scalars['Boolean']>;
  /** Can delete avatar */
  deleteAvatar?: Maybe<Scalars['Boolean']>;
  /** Can view avatar */
  viewAvatar?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum BaseCompanyCategory {
  /** Public Company */
  C = 'C',
  /** Educational Institution */
  D = 'D',
  /** Self Employed */
  E = 'E',
  /** Government Agency */
  G = 'G',
  /** Non Profit */
  N = 'N',
  /** Self Owned */
  O = 'O',
  /** Privately Held */
  P = 'P',
  /** Partnership */
  S = 'S',
}

export type BaseCompanyNode = Node & {
  __typename?: 'BaseCompanyNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  baseLogo?: Maybe<ImageFiledType>;
  websiteUrl?: Maybe<Scalars['String']>;
  size?: Maybe<BaseCompanySize>;
  category?: Maybe<BaseCompanyCategory>;
  status?: Maybe<BaseCompanyStatus>;
  company?: Maybe<CompanyNode>;
  getSizeDisplay?: Maybe<Scalars['String']>;
  getCategoryDisplay?: Maybe<Scalars['String']>;
  getStatusDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<BaseCompanyPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type BaseCompanyNodeConnection = {
  __typename?: 'BaseCompanyNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BaseCompanyNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `BaseCompanyNode` and its cursor. */
export type BaseCompanyNodeEdge = {
  __typename?: 'BaseCompanyNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<BaseCompanyNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type BaseCompanyPermission = {
  __typename?: 'BaseCompanyPermission';
  /** Can add base company */
  addBasecompany?: Maybe<Scalars['Boolean']>;
  /** Can change base company */
  changeBasecompany?: Maybe<Scalars['Boolean']>;
  /** Can delete base company */
  deleteBasecompany?: Maybe<Scalars['Boolean']>;
  /** Can view base company */
  viewBasecompany?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum BaseCompanySize {
  /** self-employed */
  A = 'A',
  /** 1-10 employees */
  B = 'B',
  /** 11-50 employees */
  C = 'C',
  /** 51-200 employees */
  D = 'D',
  /** 201-500 employees */
  E = 'E',
  /** 501-1000 employees */
  F = 'F',
  /** 1001-5000 employees */
  G = 'G',
  /** 5001-10,000 employees */
  H = 'H',
  /** 10,001+ employees */
  I = 'I',
}

/** An enumeration. */
export enum BaseCompanyStatus {
  /** Operating */
  OPR = 'OPR',
  /** Operating Subsidiary */
  OPS = 'OPS',
  /** Reorganizing */
  RRG = 'RRG',
  /** Out of Business */
  OOB = 'OOB',
  /** Acquired */
  ACQ = 'ACQ',
}

export type CategoryCreateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  /** A human-readable name for the category of the course. */
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['ID']>;
  /** Set list of Courses */
  courses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CategoryCreateMutationPayload = {
  __typename?: 'CategoryCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseCategory?: Maybe<CourseCategoryNode>;
};

export type CategoryDeleteMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CategoryDeleteMutationPayload = {
  __typename?: 'CategoryDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseCategory?: Maybe<CourseCategoryNode>;
};

export type CategoryUpdateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  /** A human-readable name for the category of the course. */
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  company?: Maybe<Scalars['ID']>;
  /** Set list of Courses */
  courses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CategoryUpdateMutationPayload = {
  __typename?: 'CategoryUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseCategory?: Maybe<CourseCategoryNode>;
};

export type CertificateConversionNode = {
  __typename?: 'CertificateConversionNode';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  certificate: CertificateNode;
  format: Scalars['String'];
  file?: Maybe<FileFiledType>;
  userHasPermission?: Maybe<CertificateConversionPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CertificateConversionPermission = {
  __typename?: 'CertificateConversionPermission';
  /** Can add certificate conversion */
  addCertificateconversion?: Maybe<Scalars['Boolean']>;
  /** Can change certificate conversion */
  changeCertificateconversion?: Maybe<Scalars['Boolean']>;
  /** Can delete certificate conversion */
  deleteCertificateconversion?: Maybe<Scalars['Boolean']>;
  /** Can view certificate conversion */
  viewCertificateconversion?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type CertificateEmailTestMutationInput = {
  certificateMapId: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CertificateEmailTestMutationPayload = {
  __typename?: 'CertificateEmailTestMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  status?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CertificateNode = Node & {
  __typename?: 'CertificateNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  template?: Maybe<CertificateTemplateNode>;
  file?: Maybe<FileFiledType>;
  courseparticipationSet: CourseParticipationNodeConnection;
  coursemoduleassessmentSet: CourseModuleAssessmentNodeConnection;
  conversions: Array<CertificateConversionNode>;
  pdf?: Maybe<CertificateConversionNode>;
  png?: Maybe<CertificateConversionNode>;
  userHasPermission?: Maybe<CertificatePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CertificateNodeCourseparticipationSetArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
  manager?: Maybe<Scalars['ID']>;
  course?: Maybe<Scalars['ID']>;
  status?: Maybe<CourseParticipationStatus>;
  partial?: Maybe<Scalars['Boolean']>;
  course_Company?: Maybe<Scalars['ID']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  hasCertificate?: Maybe<Scalars['Boolean']>;
};

export type CertificateNodeCoursemoduleassessmentSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CertificateNodeConnection = {
  __typename?: 'CertificateNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CertificateNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CertificateNode` and its cursor. */
export type CertificateNodeEdge = {
  __typename?: 'CertificateNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CertificateNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CertificatePermission = {
  __typename?: 'CertificatePermission';
  /** Can add Certificate */
  addCertificate?: Maybe<Scalars['Boolean']>;
  /** Can change Certificate */
  changeCertificate?: Maybe<Scalars['Boolean']>;
  /** Can delete Certificate */
  deleteCertificate?: Maybe<Scalars['Boolean']>;
  /** Can view Certificate */
  viewCertificate?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type CertificateTemplateCreateMutationInput = {
  /** Certificate email body */
  emailBody?: Maybe<Scalars['String']>;
  /** Certificate email subject */
  customSubject?: Maybe<Scalars['String']>;
  /** Certificate file name */
  certificateFileName?: Maybe<Scalars['String']>;
  file: Scalars['UploadType'];
  /** Set list of Certificates */
  certificates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  courseSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  coursemoduleSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CertificateTemplateCreateMutationPayload = {
  __typename?: 'CertificateTemplateCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  certificateTemplate?: Maybe<CertificateTemplateNode>;
};

export type CertificateTemplateNode = Node & {
  __typename?: 'CertificateTemplateNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  file?: Maybe<FileFiledType>;
  /** Certificate email body */
  emailBody?: Maybe<Scalars['String']>;
  /** Certificate email subject */
  customSubject?: Maybe<Scalars['String']>;
  /** Certificate file name */
  certificateFileName?: Maybe<Scalars['String']>;
  coursecertificatetemplatemapSet: CourseCertificateTemplateMapNodeConnection;
  coursemodulecertificatetemplatemapSet: CourseModuleCertificateTemplateMapNodeConnection;
  coursemoduleSet: CourseModuleNodeConnection;
  courseSet: CourseNodeConnection;
  certificates: CertificateNodeConnection;
  userHasPermission?: Maybe<CertificateTemplatePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CertificateTemplateNodeCoursecertificatetemplatemapSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CertificateTemplateNodeCoursemodulecertificatetemplatemapSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CertificateTemplateNodeCoursemoduleSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  course?: Maybe<Scalars['ID']>;
  course_Slug?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ID']>;
};

export type CertificateTemplateNodeCourseSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type CertificateTemplateNodeCertificatesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CertificateTemplateNodeConnection = {
  __typename?: 'CertificateTemplateNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CertificateTemplateNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CertificateTemplateNode` and its cursor. */
export type CertificateTemplateNodeEdge = {
  __typename?: 'CertificateTemplateNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CertificateTemplateNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CertificateTemplatePermission = {
  __typename?: 'CertificateTemplatePermission';
  /** Can add CertificateTemplate */
  addCertificatetemplate?: Maybe<Scalars['Boolean']>;
  /** Can change CertificateTemplate */
  changeCertificatetemplate?: Maybe<Scalars['Boolean']>;
  /** Can delete CertificateTemplate */
  deleteCertificatetemplate?: Maybe<Scalars['Boolean']>;
  /** Can view CertificateTemplate */
  viewCertificatetemplate?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type CertificateTemplateUpdateMutationInput = {
  /** Certificate email body */
  emailBody?: Maybe<Scalars['String']>;
  /** Certificate email subject */
  customSubject?: Maybe<Scalars['String']>;
  /** Certificate file name */
  certificateFileName?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  file?: Maybe<Scalars['UploadType']>;
  /** Set list of Certificates */
  certificates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  courseSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  coursemoduleSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CertificateTemplateUpdateMutationPayload = {
  __typename?: 'CertificateTemplateUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  certificateTemplate?: Maybe<CertificateTemplateNode>;
};

export type ChatChannelNode = {
  __typename?: 'ChatChannelNode';
  id?: Maybe<Scalars['String']>;
  channelType?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ChatModuleCreateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  chatChannel?: Maybe<Scalars['String']>;
  chatModeratorUserId?: Maybe<Scalars['String']>;
  broadcastChatChannel?: Maybe<Scalars['String']>;
  mode?: Maybe<ChatModuleMode>;
  coursemodulePtr?: Maybe<Scalars['ID']>;
  /** The ID of the object. */
  id?: Maybe<Scalars['ID']>;
  course: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Used in default mode only - Enable broadcast channel in chat */
  broadcastChannelEnabled?: Maybe<Scalars['Boolean']>;
  /** Enable chat threads */
  threadsEnabled?: Maybe<Scalars['Boolean']>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChatModuleCreateMutationPayload = {
  __typename?: 'ChatModuleCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  chatModule?: Maybe<ChatModuleNode>;
};

/** An enumeration. */
export enum ChatModuleMode {
  /** Wall */
  WALL = 'WALL',
  /** Default */
  DEFAULT = 'DEFAULT',
}

export type ChatModuleNode = Node & {
  __typename?: 'ChatModuleNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  order: Scalars['Int'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  course: CourseNode;
  parent?: Maybe<CourseModuleNode>;
  requiredModule?: Maybe<CourseModuleNode>;
  routable: Scalars['Boolean'];
  routableExact: Scalars['Boolean'];
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** If true only staff user can test module (not locked) */
  testingMode: Scalars['Boolean'];
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete: Scalars['Boolean'];
  /** Exclude module in participation check completed */
  disableCheckComplete: Scalars['Boolean'];
  /** Display complete/continue button in module */
  displayCompleteButton: Scalars['Boolean'];
  /** send email when module is completed */
  sendEmailWhenCompleted: Scalars['Boolean'];
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug: Scalars['String'];
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  /** Force module locked for all participants */
  forceLocked: Scalars['Boolean'];
  accessMode: CourseModuleAccessMode;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy: CourseModulePrivacy;
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  coursemodulePtr: CourseModuleNode;
  chatChannel?: Maybe<Scalars['String']>;
  chatModeratorUserId?: Maybe<Scalars['String']>;
  broadcastChatChannel?: Maybe<Scalars['String']>;
  /** Used in default mode only - Enable broadcast channel in chat */
  broadcastChannelEnabled: Scalars['Boolean'];
  /** Enable chat threads */
  threadsEnabled: Scalars['Boolean'];
  mode?: Maybe<ChatModuleMode>;
  userModuleAssessment?: Maybe<CourseModuleAssessmentUnion>;
  submodules?: Maybe<CourseModuleConnection>;
  videowall?: Maybe<VideowallModuleNode>;
  frontendUrl?: Maybe<Scalars['String']>;
  authors?: Maybe<AuthorModuleConnection>;
  reminders?: Maybe<ReminderNodeConnection>;
  /** The total live count objects in this query. */
  assessmentsCounter?: Maybe<Scalars['Int']>;
  chatChannels?: Maybe<Array<Maybe<ChatChannelNode>>>;
  chatModeratorUserToken?: Maybe<Scalars['String']>;
  getAccessModeDisplay?: Maybe<Scalars['String']>;
  getPrivacyDisplay?: Maybe<Scalars['String']>;
  getModeDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<ChatModulePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type ChatModuleNodeUserModuleAssessmentArgs = {
  userId?: Maybe<Scalars['ID']>;
};

export type ChatModuleNodeSubmodulesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ChatModuleNodeAuthorsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ChatModuleNodeRemindersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ChatModulePermission = {
  __typename?: 'ChatModulePermission';
  /** Can add chat module */
  addChatmodule?: Maybe<Scalars['Boolean']>;
  /** Can change chat module */
  changeChatmodule?: Maybe<Scalars['Boolean']>;
  /** Can delete chat module */
  deleteChatmodule?: Maybe<Scalars['Boolean']>;
  /** Can view chat module */
  viewChatmodule?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type ChatModuleUpdateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  chatChannel?: Maybe<Scalars['String']>;
  mode?: Maybe<ChatModuleMode>;
  /** The ID of the object. */
  id: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Used in default mode only - Enable broadcast channel in chat */
  broadcastChannelEnabled?: Maybe<Scalars['Boolean']>;
  /** Enable chat threads */
  threadsEnabled?: Maybe<Scalars['Boolean']>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ChatModuleUpdateMutationPayload = {
  __typename?: 'ChatModuleUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  chatModule?: Maybe<ChatModuleNode>;
};

export type ChatTokenNode = {
  __typename?: 'ChatTokenNode';
  token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ChoichesInput = {
  value?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
};

export type ClockedScheduleNode = {
  __typename?: 'ClockedScheduleNode';
  id: Scalars['ID'];
  /** Run the task at clocked time */
  clockedTime: Scalars['DateTime'];
  /** Clocked Schedule to run the task on.  Set only one schedule type, leave the others null. */
  periodictaskSet: ReminderNodeConnection;
  userHasPermission?: Maybe<ClockedSchedulePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type ClockedScheduleNodePeriodictaskSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ClockedSchedulePermission = {
  __typename?: 'ClockedSchedulePermission';
  /** Can add clocked */
  addClockedschedule?: Maybe<Scalars['Boolean']>;
  /** Can change clocked */
  changeClockedschedule?: Maybe<Scalars['Boolean']>;
  /** Can delete clocked */
  deleteClockedschedule?: Maybe<Scalars['Boolean']>;
  /** Can view clocked */
  viewClockedschedule?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type CompanyCreateMutationInput = {
  /** tax */
  taxData?: Maybe<TaxInput>;
  /** Billing Address */
  billingAddress?: Maybe<AddressInput>;
  /** Address */
  address?: Maybe<AddressInput>;
  name: Scalars['String'];
  websiteUrl?: Maybe<Scalars['String']>;
  size?: Maybe<BaseCompanySize>;
  category?: Maybe<BaseCompanyCategory>;
  status?: Maybe<BaseCompanyStatus>;
  customerName?: Maybe<Scalars['String']>;
  /** The customer's phone number. */
  phone?: Maybe<Scalars['String']>;
  /** The company's SDI */
  sdi?: Maybe<Scalars['String']>;
  /** Email footer text under logo */
  emailFooterLeftText?: Maybe<Scalars['String']>;
  defaultLanguage?: Maybe<CompanyDefaultLanguage>;
  footerJsxContent?: Maybe<Scalars['String']>;
  homeJsxContent?: Maybe<Scalars['String']>;
  baseLogo?: Maybe<Scalars['UploadType']>;
  sites?: Maybe<Array<Maybe<Scalars['ID']>>>;
  owner?: Maybe<Scalars['ID']>;
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  isSuper?: Maybe<Scalars['Boolean']>;
  enableAuth?: Maybe<Scalars['Boolean']>;
  enableLogoLink?: Maybe<Scalars['Boolean']>;
  legalLinks?: Maybe<Array<Maybe<Scalars['ID']>>>;
  profileForm?: Maybe<Scalars['ID']>;
  defaultTheme?: Maybe<Scalars['ID']>;
  /** Set list of course categories */
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of surveys */
  companySurveys?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  courses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CompanyCreateMutationPayload = {
  __typename?: 'CompanyCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  company?: Maybe<CompanyNode>;
};

/** An enumeration. */
export enum CompanyDefaultLanguage {
  /** Afrikaans */
  AF = 'AF',
  /** العربيّة */
  AR = 'AR',
  /** asturian */
  AST = 'AST',
  /** Azərbaycanca */
  AZ = 'AZ',
  /** български */
  BG = 'BG',
  /** беларуская */
  BE = 'BE',
  /** বাংলা */
  BN = 'BN',
  /** Brezhoneg */
  BR = 'BR',
  /** bosanski */
  BS = 'BS',
  /** català */
  CA = 'CA',
  /** česky */
  CS = 'CS',
  /** Cymraeg */
  CY = 'CY',
  /** dansk */
  DA = 'DA',
  /** Deutsch */
  DE = 'DE',
  /** Ελληνικά */
  EL = 'EL',
  /** English */
  EN = 'EN',
  /** Australian English */
  EN_AU = 'EN_AU',
  /** British English */
  EN_GB = 'EN_GB',
  /** Esperanto */
  EO = 'EO',
  /** español */
  ES = 'ES',
  /** español de Argentina */
  ES_AR = 'ES_AR',
  /** español de Mexico */
  ES_MX = 'ES_MX',
  /** español de Nicaragua */
  ES_NI = 'ES_NI',
  /** español de Venezuela */
  ES_VE = 'ES_VE',
  /** eesti */
  ET = 'ET',
  /** Basque */
  EU = 'EU',
  /** فارسی */
  FA = 'FA',
  /** suomi */
  FI = 'FI',
  /** français */
  FR = 'FR',
  /** frysk */
  FY = 'FY',
  /** Gaeilge */
  GA = 'GA',
  /** galego */
  GL = 'GL',
  /** עברית */
  HE = 'HE',
  /** Hindi */
  HI = 'HI',
  /** Hrvatski */
  HR = 'HR',
  /** Magyar */
  HU = 'HU',
  /** Interlingua */
  IA = 'IA',
  /** Bahasa Indonesia */
  ID = 'ID',
  /** ido */
  IO = 'IO',
  /** Íslenska */
  IS = 'IS',
  /** Italiano */
  IT = 'IT',
  /** 日本語 */
  JA = 'JA',
  /** ქართული */
  KA = 'KA',
  /** Қазақ */
  KK = 'KK',
  /** Khmer */
  KM = 'KM',
  /** Kannada */
  KN = 'KN',
  /** 한국어 */
  KO = 'KO',
  /** Lëtzebuergesch */
  LB = 'LB',
  /** Lietuviškai */
  LT = 'LT',
  /** latvieš */
  LV = 'LV',
  /** Македонски */
  MK = 'MK',
  /** Malayalam */
  ML = 'ML',
  /** Mongolian */
  MN = 'MN',
  /** मराठी */
  MR = 'MR',
  /** မြန်မာဘာသာ */
  MY = 'MY',
  /** norsk (bokmål) */
  NB = 'NB',
  /** नेपाली */
  NE = 'NE',
  /** Nederlands */
  NL = 'NL',
  /** norsk (nynorsk) */
  NN = 'NN',
  /** Ирон */
  OS = 'OS',
  /** Punjabi */
  PA = 'PA',
  /** polski */
  PL = 'PL',
  /** Português */
  PT = 'PT',
  /** Português Brasileiro */
  PT_BR = 'PT_BR',
  /** Română */
  RO = 'RO',
  /** Русский */
  RU = 'RU',
  /** slovenský */
  SK = 'SK',
  /** Slovenščina */
  SL = 'SL',
  /** shqip */
  SQ = 'SQ',
  /** српски */
  SR = 'SR',
  /** srpski (latinica) */
  SR_LATN = 'SR_LATN',
  /** svenska */
  SV = 'SV',
  /** Kiswahili */
  SW = 'SW',
  /** தமிழ் */
  TA = 'TA',
  /** తెలుగు */
  TE = 'TE',
  /** ภาษาไทย */
  TH = 'TH',
  /** Türkçe */
  TR = 'TR',
  /** Татарча */
  TT = 'TT',
  /** Удмурт */
  UDM = 'UDM',
  /** Українська */
  UK = 'UK',
  /** اردو */
  UR = 'UR',
  /** Tiếng Việt */
  VI = 'VI',
  /** 简体中文 */
  ZH_CN = 'ZH_CN',
  /** 简体中文 */
  ZH_HANS = 'ZH_HANS',
  /** 繁體中文 */
  ZH_HANT = 'ZH_HANT',
  /** 繁體中文 */
  ZH_TW = 'ZH_TW',
}

export type CompanyDeleteMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CompanyDeleteMutationPayload = {
  __typename?: 'CompanyDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  company?: Maybe<CompanyNode>;
};

export type CompanyNode = Node & {
  __typename?: 'CompanyNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  baseLogo?: Maybe<ImageFiledType>;
  websiteUrl?: Maybe<Scalars['String']>;
  size?: Maybe<BaseCompanySize>;
  category?: Maybe<BaseCompanyCategory>;
  status?: Maybe<BaseCompanyStatus>;
  basecompanyPtr: BaseCompanyNode;
  sites: SiteNodeConnection;
  owner: UserNode;
  groups: GroupNodeConnection;
  customerName?: Maybe<Scalars['String']>;
  billingAddress?: Maybe<Address>;
  address?: Maybe<Address>;
  taxData?: Maybe<TaxNode>;
  /** The customer's phone number. */
  phone: Scalars['String'];
  /** The company's SDI */
  sdi?: Maybe<Scalars['String']>;
  isSuper: Scalars['Boolean'];
  enableAuth: Scalars['Boolean'];
  enableLogoLink: Scalars['Boolean'];
  legalLinks?: Maybe<WebLinkModuleConnection>;
  /** Email footer text under logo */
  emailFooterLeftText?: Maybe<Scalars['String']>;
  profileForm?: Maybe<SurveyNode>;
  defaultLanguage?: Maybe<CompanyDefaultLanguage>;
  footerJsxContent?: Maybe<Scalars['String']>;
  homeJsxContent?: Maybe<Scalars['String']>;
  defaultTheme?: Maybe<ThemeNode>;
  companySurveys: SurveyNodeConnection;
  categories: CourseCategoryNodeConnection;
  courses: CourseNodeConnection;
  theme?: Maybe<CompanyThemeCustomizationNode>;
  companyuserinvitationSet: CompanyUserInvitationNodeConnection;
  companyZoomMeetings: ZoomMeetingNodeConnection;
  companyStreamServices: StreamServiceNodeConnection;
  hasBillingData?: Maybe<Scalars['Boolean']>;
  frontendUrl?: Maybe<Scalars['String']>;
  streamService?: Maybe<StreamServiceNode>;
  activeUsersMonthGroups?: Maybe<Array<Maybe<ActiveUsersGroupsType>>>;
  getSizeDisplay?: Maybe<Scalars['String']>;
  getCategoryDisplay?: Maybe<Scalars['String']>;
  getStatusDisplay?: Maybe<Scalars['String']>;
  getDefaultLanguageDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<CompanyPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CompanyNodeSitesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyNodeGroupsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyNodeLegalLinksArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyNodeCompanySurveysArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<SurveyType>>>;
  label_Icontains?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type CompanyNodeCategoriesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CompanyNodeCoursesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type CompanyNodeCompanyuserinvitationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyNodeCompanyZoomMeetingsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  topic?: Maybe<Scalars['String']>;
  topic_Icontains?: Maybe<Scalars['String']>;
  topic_Istartswith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type CompanyNodeCompanyStreamServicesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CompanyNodeConnection = {
  __typename?: 'CompanyNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CompanyNode` and its cursor. */
export type CompanyNodeEdge = {
  __typename?: 'CompanyNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompanyNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CompanyPermission = {
  __typename?: 'CompanyPermission';
  /** Can add company */
  addCompany?: Maybe<Scalars['Boolean']>;
  /** Can change company */
  changeCompany?: Maybe<Scalars['Boolean']>;
  /** Can delete company */
  deleteCompany?: Maybe<Scalars['Boolean']>;
  /** Can view company */
  viewCompany?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type CompanyThemeCustomizationNode = Node & {
  __typename?: 'CompanyThemeCustomizationNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  company: CompanyNode;
  cover?: Maybe<ImageFiledType>;
  type: CompanyThemeCustomizationType;
  logoOnDark?: Maybe<ImageFiledType>;
  logoOnLight?: Maybe<ImageFiledType>;
  primary?: Maybe<Scalars['String']>;
  primary50?: Maybe<Scalars['String']>;
  primary100?: Maybe<Scalars['String']>;
  primary200?: Maybe<Scalars['String']>;
  primary300?: Maybe<Scalars['String']>;
  primary400?: Maybe<Scalars['String']>;
  primary500?: Maybe<Scalars['String']>;
  primary600?: Maybe<Scalars['String']>;
  primary700?: Maybe<Scalars['String']>;
  primary800?: Maybe<Scalars['String']>;
  primary900?: Maybe<Scalars['String']>;
  primaryA100?: Maybe<Scalars['String']>;
  primaryA200?: Maybe<Scalars['String']>;
  primaryA400?: Maybe<Scalars['String']>;
  primaryA700?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
  secondary50?: Maybe<Scalars['String']>;
  secondary100?: Maybe<Scalars['String']>;
  secondary200?: Maybe<Scalars['String']>;
  secondary300?: Maybe<Scalars['String']>;
  secondary400?: Maybe<Scalars['String']>;
  secondary500?: Maybe<Scalars['String']>;
  secondary600?: Maybe<Scalars['String']>;
  secondary700?: Maybe<Scalars['String']>;
  secondary800?: Maybe<Scalars['String']>;
  secondary900?: Maybe<Scalars['String']>;
  secondaryA100?: Maybe<Scalars['String']>;
  secondaryA200?: Maybe<Scalars['String']>;
  secondaryA400?: Maybe<Scalars['String']>;
  secondaryA700?: Maybe<Scalars['String']>;
  getTypeDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<CompanyThemeCustomizationPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CompanyThemeCustomizationPermission = {
  __typename?: 'CompanyThemeCustomizationPermission';
  /** Can add company theme customization */
  addCompanythemecustomization?: Maybe<Scalars['Boolean']>;
  /** Can change company theme customization */
  changeCompanythemecustomization?: Maybe<Scalars['Boolean']>;
  /** Can delete company theme customization */
  deleteCompanythemecustomization?: Maybe<Scalars['Boolean']>;
  /** Can view company theme customization */
  viewCompanythemecustomization?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum CompanyThemeCustomizationType {
  /** light */
  LIGHT = 'LIGHT',
  /** dark */
  DARK = 'DARK',
}

export type CompanyThemeCustomizationUpdateMutationInput = {
  type?: Maybe<CompanyThemeCustomizationType>;
  primary?: Maybe<Scalars['String']>;
  primary50?: Maybe<Scalars['String']>;
  primary100?: Maybe<Scalars['String']>;
  primary200?: Maybe<Scalars['String']>;
  primary300?: Maybe<Scalars['String']>;
  primary400?: Maybe<Scalars['String']>;
  primary500?: Maybe<Scalars['String']>;
  primary600?: Maybe<Scalars['String']>;
  primary700?: Maybe<Scalars['String']>;
  primary800?: Maybe<Scalars['String']>;
  primary900?: Maybe<Scalars['String']>;
  primaryA100?: Maybe<Scalars['String']>;
  primaryA200?: Maybe<Scalars['String']>;
  primaryA400?: Maybe<Scalars['String']>;
  primaryA700?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
  secondary50?: Maybe<Scalars['String']>;
  secondary100?: Maybe<Scalars['String']>;
  secondary200?: Maybe<Scalars['String']>;
  secondary300?: Maybe<Scalars['String']>;
  secondary400?: Maybe<Scalars['String']>;
  secondary500?: Maybe<Scalars['String']>;
  secondary600?: Maybe<Scalars['String']>;
  secondary700?: Maybe<Scalars['String']>;
  secondary800?: Maybe<Scalars['String']>;
  secondary900?: Maybe<Scalars['String']>;
  secondaryA100?: Maybe<Scalars['String']>;
  secondaryA200?: Maybe<Scalars['String']>;
  secondaryA400?: Maybe<Scalars['String']>;
  secondaryA700?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  cover?: Maybe<Scalars['UploadType']>;
  logoOnDark?: Maybe<Scalars['UploadType']>;
  logoOnLight?: Maybe<Scalars['UploadType']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CompanyThemeCustomizationUpdateMutationPayload = {
  __typename?: 'CompanyThemeCustomizationUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  theme?: Maybe<CompanyThemeCustomizationNode>;
};

export type CompanyUpdateMutationInput = {
  /** tax */
  taxData?: Maybe<TaxInput>;
  /** Billing Address */
  billingAddress?: Maybe<AddressInput>;
  /** Address */
  address?: Maybe<AddressInput>;
  name?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  size?: Maybe<BaseCompanySize>;
  category?: Maybe<BaseCompanyCategory>;
  status?: Maybe<BaseCompanyStatus>;
  customerName?: Maybe<Scalars['String']>;
  /** The customer's phone number. */
  phone?: Maybe<Scalars['String']>;
  /** The company's SDI */
  sdi?: Maybe<Scalars['String']>;
  /** Email footer text under logo */
  emailFooterLeftText?: Maybe<Scalars['String']>;
  defaultLanguage?: Maybe<CompanyDefaultLanguage>;
  footerJsxContent?: Maybe<Scalars['String']>;
  homeJsxContent?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  baseLogo?: Maybe<Scalars['UploadType']>;
  sites?: Maybe<Array<Maybe<Scalars['ID']>>>;
  owner?: Maybe<Scalars['ID']>;
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  isSuper?: Maybe<Scalars['Boolean']>;
  enableAuth?: Maybe<Scalars['Boolean']>;
  enableLogoLink?: Maybe<Scalars['Boolean']>;
  legalLinks?: Maybe<Array<Maybe<Scalars['ID']>>>;
  profileForm?: Maybe<Scalars['ID']>;
  defaultTheme?: Maybe<Scalars['ID']>;
  /** Set list of course categories */
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of surveys */
  companySurveys?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  courses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CompanyUpdateMutationPayload = {
  __typename?: 'CompanyUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  company?: Maybe<CompanyNode>;
};

export type CompanyUserInvitationAcceptMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CompanyUserInvitationAcceptMutationPayload = {
  __typename?: 'CompanyUserInvitationAcceptMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  companyUserInvitation?: Maybe<CompanyUserInvitationNode>;
};

export type CompanyUserInvitationConnectMutationInput = {
  /** Contextual accept invitation */
  accept?: Maybe<Scalars['Boolean']>;
  /** invitation token needed for verification */
  token: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CompanyUserInvitationConnectMutationPayload = {
  __typename?: 'CompanyUserInvitationConnectMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  companyUserInvitation?: Maybe<CompanyUserInvitationNode>;
};

export type CompanyUserInvitationCreateMutationInput = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CompanyUserInvitationCreateMutationPayload = {
  __typename?: 'CompanyUserInvitationCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  companyUserInvitation?: Maybe<CompanyUserInvitationNode>;
};

export type CompanyUserInvitationDeclineMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CompanyUserInvitationDeclineMutationPayload = {
  __typename?: 'CompanyUserInvitationDeclineMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  companyUserInvitation?: Maybe<CompanyUserInvitationNode>;
};

export type CompanyUserInvitationDeleteMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CompanyUserInvitationDeleteMutationPayload = {
  __typename?: 'CompanyUserInvitationDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  companyUserInvitation?: Maybe<CompanyUserInvitationNode>;
};

export type CompanyUserInvitationNode = Node & {
  __typename?: 'CompanyUserInvitationNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  token: Scalars['UUID'];
  email: Scalars['String'];
  owner: UserNode;
  company: CompanyNode;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  user?: Maybe<UserNode>;
  assessmentSession?: Maybe<AssessmentSessionNode>;
  status: CompanyUserInvitationStatus;
  userConnectedAt?: Maybe<Scalars['DateTime']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  repliedAt?: Maybe<Scalars['DateTime']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  getStatusDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<CompanyUserInvitationPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CompanyUserInvitationNodeConnection = {
  __typename?: 'CompanyUserInvitationNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CompanyUserInvitationNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CompanyUserInvitationNode` and its cursor. */
export type CompanyUserInvitationNodeEdge = {
  __typename?: 'CompanyUserInvitationNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CompanyUserInvitationNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CompanyUserInvitationPermission = {
  __typename?: 'CompanyUserInvitationPermission';
  /** Can add company user invitation */
  addCompanyuserinvitation?: Maybe<Scalars['Boolean']>;
  /** Can change company user invitation */
  changeCompanyuserinvitation?: Maybe<Scalars['Boolean']>;
  /** Can delete company user invitation */
  deleteCompanyuserinvitation?: Maybe<Scalars['Boolean']>;
  /** Can view company user invitation */
  viewCompanyuserinvitation?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type CompanyUserInvitationRevokeMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CompanyUserInvitationRevokeMutationPayload = {
  __typename?: 'CompanyUserInvitationRevokeMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  companyUserInvitation?: Maybe<CompanyUserInvitationNode>;
};

/** An enumeration. */
export enum CompanyUserInvitationStatus {
  /** Pending */
  PENDING = 'PENDING',
  /** Sent */
  SENT = 'SENT',
  /** Accepted */
  ACCEPTED = 'ACCEPTED',
  /** Declined */
  DECLINED = 'DECLINED',
  /** Expired */
  EXPIRED = 'EXPIRED',
  /** Revoked */
  REVOKED = 'REVOKED',
  /** Completed */
  COMPLETED = 'COMPLETED',
}

export type ContactMutationInput = {
  email: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ContactMutationPayload = {
  __typename?: 'ContactMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  status?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum CountryEnum {
  /** Afghanistan */
  AF = 'AF',
  /** Åland Islands */
  AX = 'AX',
  /** Albania */
  AL = 'AL',
  /** Algeria */
  DZ = 'DZ',
  /** American Samoa */
  AS = 'AS',
  /** Andorra */
  AD = 'AD',
  /** Angola */
  AO = 'AO',
  /** Anguilla */
  AI = 'AI',
  /** Antarctica */
  AQ = 'AQ',
  /** Antigua and Barbuda */
  AG = 'AG',
  /** Argentina */
  AR = 'AR',
  /** Armenia */
  AM = 'AM',
  /** Aruba */
  AW = 'AW',
  /** Australia */
  AU = 'AU',
  /** Austria */
  AT = 'AT',
  /** Azerbaijan */
  AZ = 'AZ',
  /** Bahamas */
  BS = 'BS',
  /** Bahrain */
  BH = 'BH',
  /** Bangladesh */
  BD = 'BD',
  /** Barbados */
  BB = 'BB',
  /** Belarus */
  BY = 'BY',
  /** Belgium */
  BE = 'BE',
  /** Belize */
  BZ = 'BZ',
  /** Benin */
  BJ = 'BJ',
  /** Bermuda */
  BM = 'BM',
  /** Bhutan */
  BT = 'BT',
  /** Bolivia (Plurinational State of) */
  BO = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  BQ = 'BQ',
  /** Bosnia and Herzegovina */
  BA = 'BA',
  /** Botswana */
  BW = 'BW',
  /** Bouvet Island */
  BV = 'BV',
  /** Brazil */
  BR = 'BR',
  /** British Indian Ocean Territory */
  IO = 'IO',
  /** Brunei Darussalam */
  BN = 'BN',
  /** Bulgaria */
  BG = 'BG',
  /** Burkina Faso */
  BF = 'BF',
  /** Burundi */
  BI = 'BI',
  /** Cabo Verde */
  CV = 'CV',
  /** Cambodia */
  KH = 'KH',
  /** Cameroon */
  CM = 'CM',
  /** Canada */
  CA = 'CA',
  /** Cayman Islands */
  KY = 'KY',
  /** Central African Republic */
  CF = 'CF',
  /** Chad */
  TD = 'TD',
  /** Chile */
  CL = 'CL',
  /** China */
  CN = 'CN',
  /** Christmas Island */
  CX = 'CX',
  /** Cocos (Keeling) Islands */
  CC = 'CC',
  /** Colombia */
  CO = 'CO',
  /** Comoros */
  KM = 'KM',
  /** Congo */
  CG = 'CG',
  /** Congo (the Democratic Republic of the) */
  CD = 'CD',
  /** Cook Islands */
  CK = 'CK',
  /** Costa Rica */
  CR = 'CR',
  /** Côte d'Ivoire */
  CI = 'CI',
  /** Croatia */
  HR = 'HR',
  /** Cuba */
  CU = 'CU',
  /** Curaçao */
  CW = 'CW',
  /** Cyprus */
  CY = 'CY',
  /** Czechia */
  CZ = 'CZ',
  /** Denmark */
  DK = 'DK',
  /** Djibouti */
  DJ = 'DJ',
  /** Dominica */
  DM = 'DM',
  /** Dominican Republic */
  DO = 'DO',
  /** Ecuador */
  EC = 'EC',
  /** Egypt */
  EG = 'EG',
  /** El Salvador */
  SV = 'SV',
  /** Equatorial Guinea */
  GQ = 'GQ',
  /** Eritrea */
  ER = 'ER',
  /** Estonia */
  EE = 'EE',
  /** Eswatini */
  SZ = 'SZ',
  /** Ethiopia */
  ET = 'ET',
  /** Falkland Islands (Malvinas) */
  FK = 'FK',
  /** Faroe Islands */
  FO = 'FO',
  /** Fiji */
  FJ = 'FJ',
  /** Finland */
  FI = 'FI',
  /** France */
  FR = 'FR',
  /** French Guiana */
  GF = 'GF',
  /** French Polynesia */
  PF = 'PF',
  /** French Southern Territories */
  TF = 'TF',
  /** Gabon */
  GA = 'GA',
  /** Gambia */
  GM = 'GM',
  /** Georgia */
  GE = 'GE',
  /** Germany */
  DE = 'DE',
  /** Ghana */
  GH = 'GH',
  /** Gibraltar */
  GI = 'GI',
  /** Greece */
  GR = 'GR',
  /** Greenland */
  GL = 'GL',
  /** Grenada */
  GD = 'GD',
  /** Guadeloupe */
  GP = 'GP',
  /** Guam */
  GU = 'GU',
  /** Guatemala */
  GT = 'GT',
  /** Guernsey */
  GG = 'GG',
  /** Guinea */
  GN = 'GN',
  /** Guinea-Bissau */
  GW = 'GW',
  /** Guyana */
  GY = 'GY',
  /** Haiti */
  HT = 'HT',
  /** Heard Island and McDonald Islands */
  HM = 'HM',
  /** Holy See */
  VA = 'VA',
  /** Honduras */
  HN = 'HN',
  /** Hong Kong */
  HK = 'HK',
  /** Hungary */
  HU = 'HU',
  /** Iceland */
  IS = 'IS',
  /** India */
  IN = 'IN',
  /** Indonesia */
  ID = 'ID',
  /** Iran (Islamic Republic of) */
  IR = 'IR',
  /** Iraq */
  IQ = 'IQ',
  /** Ireland */
  IE = 'IE',
  /** Isle of Man */
  IM = 'IM',
  /** Israel */
  IL = 'IL',
  /** Italy */
  IT = 'IT',
  /** Jamaica */
  JM = 'JM',
  /** Japan */
  JP = 'JP',
  /** Jersey */
  JE = 'JE',
  /** Jordan */
  JO = 'JO',
  /** Kazakhstan */
  KZ = 'KZ',
  /** Kenya */
  KE = 'KE',
  /** Kiribati */
  KI = 'KI',
  /** Korea (the Democratic People's Republic of) */
  KP = 'KP',
  /** Korea (the Republic of) */
  KR = 'KR',
  /** Kuwait */
  KW = 'KW',
  /** Kyrgyzstan */
  KG = 'KG',
  /** Lao People's Democratic Republic */
  LA = 'LA',
  /** Latvia */
  LV = 'LV',
  /** Lebanon */
  LB = 'LB',
  /** Lesotho */
  LS = 'LS',
  /** Liberia */
  LR = 'LR',
  /** Libya */
  LY = 'LY',
  /** Liechtenstein */
  LI = 'LI',
  /** Lithuania */
  LT = 'LT',
  /** Luxembourg */
  LU = 'LU',
  /** Macao */
  MO = 'MO',
  /** Madagascar */
  MG = 'MG',
  /** Malawi */
  MW = 'MW',
  /** Malaysia */
  MY = 'MY',
  /** Maldives */
  MV = 'MV',
  /** Mali */
  ML = 'ML',
  /** Malta */
  MT = 'MT',
  /** Marshall Islands */
  MH = 'MH',
  /** Martinique */
  MQ = 'MQ',
  /** Mauritania */
  MR = 'MR',
  /** Mauritius */
  MU = 'MU',
  /** Mayotte */
  YT = 'YT',
  /** Mexico */
  MX = 'MX',
  /** Micronesia (Federated States of) */
  FM = 'FM',
  /** Moldova (the Republic of) */
  MD = 'MD',
  /** Monaco */
  MC = 'MC',
  /** Mongolia */
  MN = 'MN',
  /** Montenegro */
  ME = 'ME',
  /** Montserrat */
  MS = 'MS',
  /** Morocco */
  MA = 'MA',
  /** Mozambique */
  MZ = 'MZ',
  /** Myanmar */
  MM = 'MM',
  /** Namibia */
  NA = 'NA',
  /** Nauru */
  NR = 'NR',
  /** Nepal */
  NP = 'NP',
  /** Netherlands */
  NL = 'NL',
  /** New Caledonia */
  NC = 'NC',
  /** New Zealand */
  NZ = 'NZ',
  /** Nicaragua */
  NI = 'NI',
  /** Niger */
  NE = 'NE',
  /** Nigeria */
  NG = 'NG',
  /** Niue */
  NU = 'NU',
  /** Norfolk Island */
  NF = 'NF',
  /** North Macedonia */
  MK = 'MK',
  /** Northern Mariana Islands */
  MP = 'MP',
  /** Norway */
  NO = 'NO',
  /** Oman */
  OM = 'OM',
  /** Pakistan */
  PK = 'PK',
  /** Palau */
  PW = 'PW',
  /** Palestine, State of */
  PS = 'PS',
  /** Panama */
  PA = 'PA',
  /** Papua New Guinea */
  PG = 'PG',
  /** Paraguay */
  PY = 'PY',
  /** Peru */
  PE = 'PE',
  /** Philippines */
  PH = 'PH',
  /** Pitcairn */
  PN = 'PN',
  /** Poland */
  PL = 'PL',
  /** Portugal */
  PT = 'PT',
  /** Puerto Rico */
  PR = 'PR',
  /** Qatar */
  QA = 'QA',
  /** Réunion */
  RE = 'RE',
  /** Romania */
  RO = 'RO',
  /** Russian Federation */
  RU = 'RU',
  /** Rwanda */
  RW = 'RW',
  /** Saint Barthélemy */
  BL = 'BL',
  /** Saint Helena, Ascension and Tristan da Cunha */
  SH = 'SH',
  /** Saint Kitts and Nevis */
  KN = 'KN',
  /** Saint Lucia */
  LC = 'LC',
  /** Saint Martin (French part) */
  MF = 'MF',
  /** Saint Pierre and Miquelon */
  PM = 'PM',
  /** Saint Vincent and the Grenadines */
  VC = 'VC',
  /** Samoa */
  WS = 'WS',
  /** San Marino */
  SM = 'SM',
  /** Sao Tome and Principe */
  ST = 'ST',
  /** Saudi Arabia */
  SA = 'SA',
  /** Senegal */
  SN = 'SN',
  /** Serbia */
  RS = 'RS',
  /** Seychelles */
  SC = 'SC',
  /** Sierra Leone */
  SL = 'SL',
  /** Singapore */
  SG = 'SG',
  /** Sint Maarten (Dutch part) */
  SX = 'SX',
  /** Slovakia */
  SK = 'SK',
  /** Slovenia */
  SI = 'SI',
  /** Solomon Islands */
  SB = 'SB',
  /** Somalia */
  SO = 'SO',
  /** South Africa */
  ZA = 'ZA',
  /** South Georgia and the South Sandwich Islands */
  GS = 'GS',
  /** South Sudan */
  SS = 'SS',
  /** Spain */
  ES = 'ES',
  /** Sri Lanka */
  LK = 'LK',
  /** Sudan */
  SD = 'SD',
  /** Suriname */
  SR = 'SR',
  /** Svalbard and Jan Mayen */
  SJ = 'SJ',
  /** Sweden */
  SE = 'SE',
  /** Switzerland */
  CH = 'CH',
  /** Syrian Arab Republic */
  SY = 'SY',
  /** Taiwan (Republic of China) */
  TW = 'TW',
  /** Tajikistan */
  TJ = 'TJ',
  /** Tanzania, the United Republic of */
  TZ = 'TZ',
  /** Thailand */
  TH = 'TH',
  /** Timor-Leste */
  TL = 'TL',
  /** Togo */
  TG = 'TG',
  /** Tokelau */
  TK = 'TK',
  /** Tonga */
  TO = 'TO',
  /** Trinidad and Tobago */
  TT = 'TT',
  /** Tunisia */
  TN = 'TN',
  /** Türkiye */
  TR = 'TR',
  /** Turkmenistan */
  TM = 'TM',
  /** Turks and Caicos Islands */
  TC = 'TC',
  /** Tuvalu */
  TV = 'TV',
  /** Uganda */
  UG = 'UG',
  /** Ukraine */
  UA = 'UA',
  /** United Arab Emirates */
  AE = 'AE',
  /** United Kingdom of Great Britain and Northern Ireland */
  GB = 'GB',
  /** United States Minor Outlying Islands */
  UM = 'UM',
  /** United States of America */
  US = 'US',
  /** Uruguay */
  UY = 'UY',
  /** Uzbekistan */
  UZ = 'UZ',
  /** Vanuatu */
  VU = 'VU',
  /** Venezuela (Bolivarian Republic of) */
  VE = 'VE',
  /** Viet Nam */
  VN = 'VN',
  /** Virgin Islands (British) */
  VG = 'VG',
  /** Virgin Islands (U.S.) */
  VI = 'VI',
  /** Wallis and Futuna */
  WF = 'WF',
  /** Western Sahara */
  EH = 'EH',
  /** Yemen */
  YE = 'YE',
  /** Zambia */
  ZM = 'ZM',
  /** Zimbabwe */
  ZW = 'ZW',
}

export type CourseCategoryNode = Node & {
  __typename?: 'CourseCategoryNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  order: Scalars['Int'];
  company?: Maybe<CompanyNode>;
  /** A human-readable name for the category of the course. */
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  courses: CourseNodeConnection;
  userHasPermission?: Maybe<CourseCategoryPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CourseCategoryNodeCoursesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type CourseCategoryNodeConnection = {
  __typename?: 'CourseCategoryNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseCategoryNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CourseCategoryNode` and its cursor. */
export type CourseCategoryNodeEdge = {
  __typename?: 'CourseCategoryNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CourseCategoryNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CourseCategoryPermission = {
  __typename?: 'CourseCategoryPermission';
  /** Can add course category */
  addCoursecategory?: Maybe<Scalars['Boolean']>;
  /** Can change course category */
  changeCoursecategory?: Maybe<Scalars['Boolean']>;
  /** Can delete course category */
  deleteCoursecategory?: Maybe<Scalars['Boolean']>;
  /** Can view course category */
  viewCoursecategory?: Maybe<Scalars['Boolean']>;
  /** Can manage course category */
  manageCourseCategory?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type CourseCertificateMapsCreateMutationInput = {
  generationRole?: Maybe<CourseCertificateTemplateMapGenerationRole>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  certificateTemplate: Scalars['ID'];
  sendMail: Scalars['Boolean'];
  course: Scalars['ID'];
  certifiedParticipations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseCertificateMapsCreateMutationPayload = {
  __typename?: 'CourseCertificateMapsCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseCertificateTemplateMap?: Maybe<CourseCertificateTemplateMapNode>;
};

export type CourseCertificateMapsDeleteMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseCertificateMapsDeleteMutationPayload = {
  __typename?: 'CourseCertificateMapsDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseCertificateTemplateMap?: Maybe<CourseCertificateTemplateMapNode>;
};

export type CourseCertificateMapsUpdateMutationInput = {
  generationRole?: Maybe<CourseCertificateTemplateMapGenerationRole>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  certificateTemplate?: Maybe<Scalars['ID']>;
  sendMail?: Maybe<Scalars['Boolean']>;
  course?: Maybe<Scalars['ID']>;
  certifiedParticipations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseCertificateMapsUpdateMutationPayload = {
  __typename?: 'CourseCertificateMapsUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseCertificateTemplateMap?: Maybe<CourseCertificateTemplateMapNode>;
};

/** An enumeration. */
export enum CourseCertificateTemplateMapGenerationRole {
  /** On completed */
  ON_COMPLETED = 'ON_COMPLETED',
  /** On created */
  ON_CREATED = 'ON_CREATED',
  /** On not passed */
  ON_NOT_PASSED = 'ON_NOT_PASSED',
}

export type CourseCertificateTemplateMapNode = Node & {
  __typename?: 'CourseCertificateTemplateMapNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  certificateTemplate: CertificateTemplateNode;
  generationRole?: Maybe<CourseCertificateTemplateMapGenerationRole>;
  sendMail: Scalars['Boolean'];
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  course: CourseNode;
  certifiedParticipations: CourseParticipationNodeConnection;
  getGenerationRoleDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<CourseCertificateTemplateMapPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CourseCertificateTemplateMapNodeCertifiedParticipationsArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
  manager?: Maybe<Scalars['ID']>;
  course?: Maybe<Scalars['ID']>;
  status?: Maybe<CourseParticipationStatus>;
  partial?: Maybe<Scalars['Boolean']>;
  course_Company?: Maybe<Scalars['ID']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  hasCertificate?: Maybe<Scalars['Boolean']>;
};

export type CourseCertificateTemplateMapNodeConnection = {
  __typename?: 'CourseCertificateTemplateMapNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseCertificateTemplateMapNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CourseCertificateTemplateMapNode` and its cursor. */
export type CourseCertificateTemplateMapNodeEdge = {
  __typename?: 'CourseCertificateTemplateMapNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CourseCertificateTemplateMapNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CourseCertificateTemplateMapPermission = {
  __typename?: 'CourseCertificateTemplateMapPermission';
  /** Can add course certificate template map */
  addCoursecertificatetemplatemap?: Maybe<Scalars['Boolean']>;
  /** Can change course certificate template map */
  changeCoursecertificatetemplatemap?: Maybe<Scalars['Boolean']>;
  /** Can delete course certificate template map */
  deleteCoursecertificatetemplatemap?: Maybe<Scalars['Boolean']>;
  /** Can view course certificate template map */
  viewCoursecertificatetemplatemap?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type CourseCloneMutationInput = {
  courseId: Scalars['ID'];
  title: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseCloneMutationPayload = {
  __typename?: 'CourseCloneMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  course?: Maybe<CourseNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseCreateFromTemplateMutationInput = {
  owner?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['UploadType']>;
  courseTemplateId: Scalars['Int'];
  companyId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseCreateFromTemplateMutationPayload = {
  __typename?: 'CourseCreateFromTemplateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  course?: Maybe<CourseNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseCreateMutationInput = {
  owner?: Maybe<Scalars['ID']>;
  /** A human-readable name for the course. (e.g. 'Numerical Methods') */
  name: Scalars['String'];
  fromEmailSender?: Maybe<Scalars['String']>;
  /** The sender name of email (e.g. 'Sender name email@email.com') */
  fromEmailSenderName?: Maybe<Scalars['String']>;
  /** A human-readable headline for the course.  */
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  /** End course content */
  endCourseContent?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  /** Name of the parameter in the url with which the uuid is sent, (deafult is uuid) */
  externalUuidParameterName?: Maybe<Scalars['String']>;
  /**
   * If setted the course is protected by password and the user must enter the
   * password in participation form to access the course
   */
  password?: Maybe<Scalars['String']>;
  /** if setted participation button has external link */
  participationExternalLink?: Maybe<Scalars['String']>;
  /** Used as author list title */
  authorListLabel?: Maybe<Scalars['String']>;
  /** Label for the button that allows you to download the course program */
  pdfProgramButtonLabel?: Maybe<Scalars['String']>;
  /** Replace participation toast success message */
  participationCustomToastSuccessMessage?: Maybe<Scalars['String']>;
  /** Replace participation email */
  participationCustomEmailContent?: Maybe<Scalars['String']>;
  /** Extra content in email */
  emailExtraContent?: Maybe<Scalars['String']>;
  layoutType?: Maybe<CourseLayoutType>;
  navigationType?: Maybe<CourseNavigationType>;
  defaultLanguage?: Maybe<CourseDefaultLanguage>;
  location?: Maybe<Scalars['String']>;
  sites?: Maybe<Array<Maybe<Scalars['ID']>>>;
  company?: Maybe<Scalars['ID']>;
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Display end course content */
  displayEndCourseContent?: Maybe<Scalars['Boolean']>;
  cover?: Maybe<Scalars['UploadType']>;
  background?: Maybe<Scalars['UploadType']>;
  /** Force Display Auth  component if not logged */
  forceDisplayAuthComponentIfNotLogged?: Maybe<Scalars['Boolean']>;
  /** Enable signup in Auth modal dialog */
  signupButtonEnable?: Maybe<Scalars['Boolean']>;
  /** Enable google login button */
  loginGoogleButtonEnable?: Maybe<Scalars['Boolean']>;
  /** Display participants counter */
  displayParticipantsCounter?: Maybe<Scalars['Boolean']>;
  /** Accept anonymous enrollment */
  acceptAnonyumousEnrollment?: Maybe<Scalars['Boolean']>;
  /** Force anonymous enrollment */
  forceAnonyumousEnrollment?: Maybe<Scalars['Boolean']>;
  /** Can participate only if participation email is in course contact list */
  onlyContactsAuthorized?: Maybe<Scalars['Boolean']>;
  /** Display continue button between modules */
  displayContinueButton?: Maybe<Scalars['Boolean']>;
  /** Limit one automatic participation for each invitation. From the second access you have to fill in the form  */
  onlyOneParticipationPerInvitation?: Maybe<Scalars['Boolean']>;
  /** The invitation does not require email verification   */
  useInsecureInvitation?: Maybe<Scalars['Boolean']>;
  automaticallyAcceptsParticipation?: Maybe<Scalars['Boolean']>;
  automaticallyAssignManageParticipationsPerm?: Maybe<Scalars['Boolean']>;
  /** If true system send email for requested participations */
  enablePendingParticipationEmail?: Maybe<Scalars['Boolean']>;
  /** If true system send email for confirmed participations */
  enableConfirmParticipationEmail?: Maybe<Scalars['Boolean']>;
  acceptsParticipationByExternalUuid?: Maybe<Scalars['Boolean']>;
  externalUuidParameterRequired?: Maybe<Scalars['Boolean']>;
  generateParticipationQrcode?: Maybe<Scalars['Boolean']>;
  /** Force auto open participation dialog */
  requireDataParticipation?: Maybe<Scalars['Boolean']>;
  participationForm?: Maybe<Scalars['ID']>;
  /** if setted replace next webinar for course */
  featuredWebinar?: Maybe<Scalars['ID']>;
  /** Is the course only accessible to course staff? */
  hidden?: Maybe<Scalars['Boolean']>;
  /** Should the course be listed on the main page? */
  listed?: Maybe<Scalars['Boolean']>;
  /** Demo course */
  demo?: Maybe<Scalars['Boolean']>;
  commonAuthors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  authorsGroups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  templates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  pdfProgram?: Maybe<Scalars['UploadType']>;
  /** Display toast message on participation creation success */
  displayParticipationSuccessToast?: Maybe<Scalars['Boolean']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  mosaico?: Maybe<Scalars['ID']>;
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  theme?: Maybe<Scalars['ID']>;
  /** Set list of Contacts */
  contacts?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseCreateMutationPayload = {
  __typename?: 'CourseCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  course?: Maybe<CourseNode>;
};

/** An enumeration. */
export enum CourseDefaultLanguage {
  /** Afrikaans */
  AF = 'AF',
  /** العربيّة */
  AR = 'AR',
  /** asturian */
  AST = 'AST',
  /** Azərbaycanca */
  AZ = 'AZ',
  /** български */
  BG = 'BG',
  /** беларуская */
  BE = 'BE',
  /** বাংলা */
  BN = 'BN',
  /** Brezhoneg */
  BR = 'BR',
  /** bosanski */
  BS = 'BS',
  /** català */
  CA = 'CA',
  /** česky */
  CS = 'CS',
  /** Cymraeg */
  CY = 'CY',
  /** dansk */
  DA = 'DA',
  /** Deutsch */
  DE = 'DE',
  /** Ελληνικά */
  EL = 'EL',
  /** English */
  EN = 'EN',
  /** Australian English */
  EN_AU = 'EN_AU',
  /** British English */
  EN_GB = 'EN_GB',
  /** Esperanto */
  EO = 'EO',
  /** español */
  ES = 'ES',
  /** español de Argentina */
  ES_AR = 'ES_AR',
  /** español de Mexico */
  ES_MX = 'ES_MX',
  /** español de Nicaragua */
  ES_NI = 'ES_NI',
  /** español de Venezuela */
  ES_VE = 'ES_VE',
  /** eesti */
  ET = 'ET',
  /** Basque */
  EU = 'EU',
  /** فارسی */
  FA = 'FA',
  /** suomi */
  FI = 'FI',
  /** français */
  FR = 'FR',
  /** frysk */
  FY = 'FY',
  /** Gaeilge */
  GA = 'GA',
  /** galego */
  GL = 'GL',
  /** עברית */
  HE = 'HE',
  /** Hindi */
  HI = 'HI',
  /** Hrvatski */
  HR = 'HR',
  /** Magyar */
  HU = 'HU',
  /** Interlingua */
  IA = 'IA',
  /** Bahasa Indonesia */
  ID = 'ID',
  /** ido */
  IO = 'IO',
  /** Íslenska */
  IS = 'IS',
  /** Italiano */
  IT = 'IT',
  /** 日本語 */
  JA = 'JA',
  /** ქართული */
  KA = 'KA',
  /** Қазақ */
  KK = 'KK',
  /** Khmer */
  KM = 'KM',
  /** Kannada */
  KN = 'KN',
  /** 한국어 */
  KO = 'KO',
  /** Lëtzebuergesch */
  LB = 'LB',
  /** Lietuviškai */
  LT = 'LT',
  /** latvieš */
  LV = 'LV',
  /** Македонски */
  MK = 'MK',
  /** Malayalam */
  ML = 'ML',
  /** Mongolian */
  MN = 'MN',
  /** मराठी */
  MR = 'MR',
  /** မြန်မာဘာသာ */
  MY = 'MY',
  /** norsk (bokmål) */
  NB = 'NB',
  /** नेपाली */
  NE = 'NE',
  /** Nederlands */
  NL = 'NL',
  /** norsk (nynorsk) */
  NN = 'NN',
  /** Ирон */
  OS = 'OS',
  /** Punjabi */
  PA = 'PA',
  /** polski */
  PL = 'PL',
  /** Português */
  PT = 'PT',
  /** Português Brasileiro */
  PT_BR = 'PT_BR',
  /** Română */
  RO = 'RO',
  /** Русский */
  RU = 'RU',
  /** slovenský */
  SK = 'SK',
  /** Slovenščina */
  SL = 'SL',
  /** shqip */
  SQ = 'SQ',
  /** српски */
  SR = 'SR',
  /** srpski (latinica) */
  SR_LATN = 'SR_LATN',
  /** svenska */
  SV = 'SV',
  /** Kiswahili */
  SW = 'SW',
  /** தமிழ் */
  TA = 'TA',
  /** తెలుగు */
  TE = 'TE',
  /** ภาษาไทย */
  TH = 'TH',
  /** Türkçe */
  TR = 'TR',
  /** Татарча */
  TT = 'TT',
  /** Удмурт */
  UDM = 'UDM',
  /** Українська */
  UK = 'UK',
  /** اردو */
  UR = 'UR',
  /** Tiếng Việt */
  VI = 'VI',
  /** 简体中文 */
  ZH_CN = 'ZH_CN',
  /** 简体中文 */
  ZH_HANS = 'ZH_HANS',
  /** 繁體中文 */
  ZH_HANT = 'ZH_HANT',
  /** 繁體中文 */
  ZH_TW = 'ZH_TW',
}

export type CourseDeleteAllPossibleInvitationsInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseDeleteAllPossibleInvitationsPayload = {
  __typename?: 'CourseDeleteAllPossibleInvitationsPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  count?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  course?: Maybe<CourseNode>;
};

export type CourseInvitationCreateMutationInput = {
  email: Scalars['String'];
  data?: Maybe<Scalars['JSONString']>;
  lastEmailSent?: Maybe<Scalars['DateTime']>;
  LinkWithAuthentication?: Maybe<Scalars['String']>;
  course: Scalars['ID'];
  destinationGroups: Array<Maybe<Scalars['ID']>>;
  autoCreateParticipation: Scalars['Boolean'];
  autoAcceptParticipation: Scalars['Boolean'];
  UseInsecureInvitation?: Maybe<Scalars['Boolean']>;
  /** Set list of course participations */
  participations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseInvitationCreateMutationPayload = {
  __typename?: 'CourseInvitationCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseInvitation?: Maybe<CourseInvitationNode>;
};

export type CourseInvitationDeleteMutationInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseInvitationDeleteMutationPayload = {
  __typename?: 'CourseInvitationDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseInvitation?: Maybe<CourseInvitationNode>;
};

export type CourseInvitationNode = Node & {
  __typename?: 'CourseInvitationNode';
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  uuid: Scalars['UUID'];
  owner: UserNode;
  course: CourseNode;
  email: Scalars['String'];
  data?: Maybe<Scalars['JSONString']>;
  destinationGroups: GroupNodeConnection;
  autoCreateParticipation: Scalars['Boolean'];
  autoAcceptParticipation: Scalars['Boolean'];
  lastEmailSent?: Maybe<Scalars['DateTime']>;
  LinkWithAuthentication?: Maybe<Scalars['String']>;
  UseInsecureInvitation?: Maybe<Scalars['Boolean']>;
  participations: CourseParticipationNodeConnection;
  /** The ID of the object. */
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  linkWithAuthentication?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<CourseInvitationPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CourseInvitationNodeDestinationGroupsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseInvitationNodeParticipationsArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
  manager?: Maybe<Scalars['ID']>;
  course?: Maybe<Scalars['ID']>;
  status?: Maybe<CourseParticipationStatus>;
  partial?: Maybe<Scalars['Boolean']>;
  course_Company?: Maybe<Scalars['ID']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  hasCertificate?: Maybe<Scalars['Boolean']>;
};

export type CourseInvitationNodeConnection = {
  __typename?: 'CourseInvitationNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseInvitationNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CourseInvitationNode` and its cursor. */
export type CourseInvitationNodeEdge = {
  __typename?: 'CourseInvitationNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CourseInvitationNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CourseInvitationPermission = {
  __typename?: 'CourseInvitationPermission';
  /** Can add course invitation */
  addCourseinvitation?: Maybe<Scalars['Boolean']>;
  /** Can change course invitation */
  changeCourseinvitation?: Maybe<Scalars['Boolean']>;
  /** Can delete course invitation */
  deleteCourseinvitation?: Maybe<Scalars['Boolean']>;
  /** Can view course invitation */
  viewCourseinvitation?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type CourseInvitationPublicResendEmailMutationInput = {
  id: Scalars['ID'];
  email: Scalars['String'];
  updateLink?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseInvitationPublicResendEmailMutationPayload = {
  __typename?: 'CourseInvitationPublicResendEmailMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  sentResult?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseInvitation?: Maybe<CourseInvitationNode>;
};

export type CourseInvitationSearchAndSendByEmailInput = {
  courseId: Scalars['ID'];
  email: Scalars['String'];
  updateLink?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseInvitationSearchAndSendByEmailPayload = {
  __typename?: 'CourseInvitationSearchAndSendByEmailPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  sentResult?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseInvitation?: Maybe<CourseInvitationNode>;
};

export type CourseInvitationSendEmailMutationInput = {
  id: Scalars['ID'];
  updateLink?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseInvitationSendEmailMutationPayload = {
  __typename?: 'CourseInvitationSendEmailMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  sentResult?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseInvitation?: Maybe<CourseInvitationNode>;
};

export type CourseInvitationUpdateMutationInput = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSONString']>;
  lastEmailSent?: Maybe<Scalars['DateTime']>;
  LinkWithAuthentication?: Maybe<Scalars['String']>;
  destinationGroups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  autoCreateParticipation?: Maybe<Scalars['Boolean']>;
  autoAcceptParticipation?: Maybe<Scalars['Boolean']>;
  UseInsecureInvitation?: Maybe<Scalars['Boolean']>;
  /** Set list of course participations */
  participations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseInvitationUpdateMutationPayload = {
  __typename?: 'CourseInvitationUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseInvitation?: Maybe<CourseInvitationNode>;
};

/** An enumeration. */
export enum CourseLayoutType {
  /** Sidebar layout */
  SIDEBAR = 'SIDEBAR',
  /** Default layout */
  DEFAULT = 'DEFAULT',
  /** Fullscreen layout */
  FULLSCREEN = 'FULLSCREEN',
}

/** An enumeration. */
export enum CourseModuleAccessMode {
  /** Default */
  DEFAULT_ACCESS = 'DEFAULT_ACCESS',
  /** Single access qrcode */
  SINGLE_ACCESS_QRCODE = 'SINGLE_ACCESS_QRCODE',
  /** Multiple access qrcode */
  MULTIPLE_ACCESS_QRCODE = 'MULTIPLE_ACCESS_QRCODE',
}

export type CourseModuleAssessmentExternalWebinarNode = Node & {
  __typename?: 'CourseModuleAssessmentExternalWebinarNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  courseParticipation: CourseParticipationNode;
  courseModule: CourseModuleNode;
  status: CourseModuleAssessmentStatus;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  certificate?: Maybe<CertificateNode>;
  coursemoduleassessmentPtr: CourseModuleAssessmentNode;
  presenceTime?: Maybe<Scalars['Float']>;
  presencePercentile?: Maybe<Scalars['Int']>;
  requiredUserPresencePercentile?: Maybe<Scalars['Int']>;
  getStatusDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<CourseModuleAssessmentExternalWebinarPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CourseModuleAssessmentExternalWebinarPermission = {
  __typename?: 'CourseModuleAssessmentExternalWebinarPermission';
  /** Can add course module assessment external webinar */
  addCoursemoduleassessmentexternalwebinar?: Maybe<Scalars['Boolean']>;
  /** Can change course module assessment external webinar */
  changeCoursemoduleassessmentexternalwebinar?: Maybe<Scalars['Boolean']>;
  /** Can delete course module assessment external webinar */
  deleteCoursemoduleassessmentexternalwebinar?: Maybe<Scalars['Boolean']>;
  /** Can view course module assessment external webinar */
  viewCoursemoduleassessmentexternalwebinar?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type CourseModuleAssessmentNode = Node & {
  __typename?: 'CourseModuleAssessmentNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  courseParticipation: CourseParticipationNode;
  courseModule: CourseModuleNode;
  status: CourseModuleAssessmentStatus;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  certificate?: Maybe<CertificateNode>;
  certificates: CertificateNodeConnection;
  coursemodulecertificatetemplatemapSet: CourseModuleCertificateTemplateMapNodeConnection;
  coursemoduleassessmentsurvey?: Maybe<CourseModuleAssessmentSurveyNode>;
  coursemoduleassessmentexternalwebinar?: Maybe<CourseModuleAssessmentExternalWebinarNode>;
  coursemoduleassessmentvideo?: Maybe<CourseModuleAssessmentVideoNode>;
  getStatusDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<CourseModuleAssessmentPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CourseModuleAssessmentNodeCertificatesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseModuleAssessmentNodeCoursemodulecertificatetemplatemapSetArgs =
  {
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['String']>;
    after?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
  };

export type CourseModuleAssessmentNodeConnection = {
  __typename?: 'CourseModuleAssessmentNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseModuleAssessmentNodeEdge>>;
};

/** A Relay edge containing a `CourseModuleAssessmentNode` and its cursor. */
export type CourseModuleAssessmentNodeEdge = {
  __typename?: 'CourseModuleAssessmentNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CourseModuleAssessmentNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CourseModuleAssessmentPermission = {
  __typename?: 'CourseModuleAssessmentPermission';
  /** Can add course module assessment */
  addCoursemoduleassessment?: Maybe<Scalars['Boolean']>;
  /** Can change course module assessment */
  changeCoursemoduleassessment?: Maybe<Scalars['Boolean']>;
  /** Can delete course module assessment */
  deleteCoursemoduleassessment?: Maybe<Scalars['Boolean']>;
  /** Can view course module assessment */
  viewCoursemoduleassessment?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum CourseModuleAssessmentStatus {
  /** Pending */
  PENDING = 'PENDING',
  /** In progress */
  IN_PROGRESS = 'IN_PROGRESS',
  /** Completed */
  COMPLETED = 'COMPLETED',
  /** Not passed */
  NOT_PASSED = 'NOT_PASSED',
}

export type CourseModuleAssessmentSurveyNode = Node & {
  __typename?: 'CourseModuleAssessmentSurveyNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  courseParticipation: CourseParticipationNode;
  courseModule: CourseModuleNode;
  status: CourseModuleAssessmentStatus;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  certificate?: Maybe<CertificateNode>;
  coursemoduleassessmentPtr: CourseModuleAssessmentNode;
  surveyResponse?: Maybe<SurveyResponseNode>;
  surveyResponsesList: SurveyResponseNodeConnection;
  attemptsAvailable?: Maybe<Scalars['Int']>;
  attemptNumber?: Maybe<Scalars['Int']>;
  numberOfAttempts?: Maybe<Scalars['Int']>;
  minimumScore?: Maybe<Scalars['Int']>;
  minimumScoreType?: Maybe<Scalars['String']>;
  getStatusDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<CourseModuleAssessmentSurveyPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CourseModuleAssessmentSurveyNodeSurveyResponsesListArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  surveyType?: Maybe<Scalars['String']>;
  partial?: Maybe<Scalars['Boolean']>;
  survey?: Maybe<Scalars['String']>;
};

export type CourseModuleAssessmentSurveyNodeConnection = {
  __typename?: 'CourseModuleAssessmentSurveyNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseModuleAssessmentSurveyNodeEdge>>;
};

/** A Relay edge containing a `CourseModuleAssessmentSurveyNode` and its cursor. */
export type CourseModuleAssessmentSurveyNodeEdge = {
  __typename?: 'CourseModuleAssessmentSurveyNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CourseModuleAssessmentSurveyNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CourseModuleAssessmentSurveyPermission = {
  __typename?: 'CourseModuleAssessmentSurveyPermission';
  /** Can add course module assessment survey */
  addCoursemoduleassessmentsurvey?: Maybe<Scalars['Boolean']>;
  /** Can change course module assessment survey */
  changeCoursemoduleassessmentsurvey?: Maybe<Scalars['Boolean']>;
  /** Can delete course module assessment survey */
  deleteCoursemoduleassessmentsurvey?: Maybe<Scalars['Boolean']>;
  /** Can view course module assessment survey */
  viewCoursemoduleassessmentsurvey?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type CourseModuleAssessmentUnion =
  | CourseModuleAssessmentVideoNode
  | CourseModuleAssessmentExternalWebinarNode
  | CourseModuleAssessmentSurveyNode
  | CourseModuleAssessmentNode;

export type CourseModuleAssessmentVideoNode = Node & {
  __typename?: 'CourseModuleAssessmentVideoNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  courseParticipation: CourseParticipationNode;
  courseModule: CourseModuleNode;
  status: CourseModuleAssessmentStatus;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  certificate?: Maybe<CertificateNode>;
  coursemoduleassessmentPtr: CourseModuleAssessmentNode;
  videoPlayedSeconds?: Maybe<Scalars['Float']>;
  userLivePercentile?: Maybe<Scalars['Int']>;
  getStatusDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<CourseModuleAssessmentVideoPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CourseModuleAssessmentVideoPermission = {
  __typename?: 'CourseModuleAssessmentVideoPermission';
  /** Can add course module assessment video */
  addCoursemoduleassessmentvideo?: Maybe<Scalars['Boolean']>;
  /** Can change course module assessment video */
  changeCoursemoduleassessmentvideo?: Maybe<Scalars['Boolean']>;
  /** Can delete course module assessment video */
  deleteCoursemoduleassessmentvideo?: Maybe<Scalars['Boolean']>;
  /** Can view course module assessment video */
  viewCoursemoduleassessmentvideo?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type CourseModuleCertificateMapsCreateMutationInput = {
  generationRole?: Maybe<CourseModuleCertificateTemplateMapGenerationRole>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  certificateTemplate: Scalars['ID'];
  sendMail: Scalars['Boolean'];
  coursemodule: Scalars['ID'];
  certifiedAssessment?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseModuleCertificateMapsCreateMutationPayload = {
  __typename?: 'CourseModuleCertificateMapsCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseModuleCertificateTemplateMap?: Maybe<CourseModuleCertificateTemplateMapNode>;
};

export type CourseModuleCertificateMapsDeleteMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseModuleCertificateMapsDeleteMutationPayload = {
  __typename?: 'CourseModuleCertificateMapsDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseModuleCertificateTemplateMap?: Maybe<CourseModuleCertificateTemplateMapNode>;
};

export type CourseModuleCertificateMapsUpdateMutationInput = {
  generationRole?: Maybe<CourseModuleCertificateTemplateMapGenerationRole>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  certificateTemplate?: Maybe<Scalars['ID']>;
  sendMail?: Maybe<Scalars['Boolean']>;
  coursemodule?: Maybe<Scalars['ID']>;
  certifiedAssessment?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseModuleCertificateMapsUpdateMutationPayload = {
  __typename?: 'CourseModuleCertificateMapsUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseModuleCertificateTemplateMap?: Maybe<CourseModuleCertificateTemplateMapNode>;
};

/** An enumeration. */
export enum CourseModuleCertificateTemplateMapGenerationRole {
  /** On completed */
  ON_COMPLETED = 'ON_COMPLETED',
  /** On created */
  ON_CREATED = 'ON_CREATED',
  /** On not passed */
  ON_NOT_PASSED = 'ON_NOT_PASSED',
}

export type CourseModuleCertificateTemplateMapNode = Node & {
  __typename?: 'CourseModuleCertificateTemplateMapNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  certificateTemplate: CertificateTemplateNode;
  generationRole?: Maybe<CourseModuleCertificateTemplateMapGenerationRole>;
  sendMail: Scalars['Boolean'];
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  coursemodule: CourseModuleNode;
  certifiedAssessment: CourseModuleAssessmentNodeConnection;
  getGenerationRoleDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<CourseModuleCertificateTemplateMapPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CourseModuleCertificateTemplateMapNodeCertifiedAssessmentArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseModuleCertificateTemplateMapNodeConnection = {
  __typename?: 'CourseModuleCertificateTemplateMapNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseModuleCertificateTemplateMapNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CourseModuleCertificateTemplateMapNode` and its cursor. */
export type CourseModuleCertificateTemplateMapNodeEdge = {
  __typename?: 'CourseModuleCertificateTemplateMapNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CourseModuleCertificateTemplateMapNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CourseModuleCertificateTemplateMapPermission = {
  __typename?: 'CourseModuleCertificateTemplateMapPermission';
  /** Can add course module certificate template map */
  addCoursemodulecertificatetemplatemap?: Maybe<Scalars['Boolean']>;
  /** Can change course module certificate template map */
  changeCoursemodulecertificatetemplatemap?: Maybe<Scalars['Boolean']>;
  /** Can delete course module certificate template map */
  deleteCoursemodulecertificatetemplatemap?: Maybe<Scalars['Boolean']>;
  /** Can view course module certificate template map */
  viewCoursemodulecertificatetemplatemap?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type CourseModuleConnection = {
  __typename?: 'CourseModuleConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseModuleEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

export type CourseModuleCreateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  /** The ID of the object. */
  id?: Maybe<Scalars['ID']>;
  course: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseModuleCreateMutationPayload = {
  __typename?: 'CourseModuleCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseModule?: Maybe<CourseModuleNode>;
};

export type CourseModuleDeleteMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseModuleDeleteMutationPayload = {
  __typename?: 'CourseModuleDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseModule?: Maybe<CourseModuleNode>;
};

/** A Relay edge containing a `CourseModule` and its cursor. */
export type CourseModuleEdge = {
  __typename?: 'CourseModuleEdge';
  /** The item at the end of the edge */
  node?: Maybe<CourseModuleUnion>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CourseModuleNode = Node & {
  __typename?: 'CourseModuleNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  order: Scalars['Int'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  course: CourseNode;
  parent?: Maybe<CourseModuleNode>;
  requiredModule?: Maybe<CourseModuleNode>;
  routable: Scalars['Boolean'];
  routableExact: Scalars['Boolean'];
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** If true only staff user can test module (not locked) */
  testingMode: Scalars['Boolean'];
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete: Scalars['Boolean'];
  /** Exclude module in participation check completed */
  disableCheckComplete: Scalars['Boolean'];
  /** Display complete/continue button in module */
  displayCompleteButton: Scalars['Boolean'];
  /** send email when module is completed */
  sendEmailWhenCompleted: Scalars['Boolean'];
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug: Scalars['String'];
  authors?: Maybe<AuthorModuleConnection>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  /** Force module locked for all participants */
  forceLocked: Scalars['Boolean'];
  accessMode: CourseModuleAccessMode;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy: CourseModulePrivacy;
  reminders?: Maybe<ReminderNodeConnection>;
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  certificateTemplates: CertificateTemplateNodeConnection;
  certificateTemplateMaps: CourseModuleCertificateTemplateMapNodeConnection;
  submodules?: Maybe<CourseModuleConnection>;
  dependants: CourseModuleNodeConnection;
  trainingFor: SurveyModuleNodeConnection;
  surveymodule?: Maybe<SurveyModuleNode>;
  externalwebinarmodule?: Maybe<ExternalWebinarModuleNode>;
  chatmodule?: Maybe<ChatModuleNode>;
  videomodule?: Maybe<VideoModuleNode>;
  layoutmodule?: Maybe<LayoutModuleNode>;
  documentmodule?: Maybe<DocumentModuleNode>;
  videowallmodule?: Maybe<VideowallModuleNode>;
  iframemodule?: Maybe<IframeModuleNode>;
  moduleAssessments: CourseModuleAssessmentNodeConnection;
  /** if setted replace next webinar for course */
  featuredModuleCourses: CourseNodeConnection;
  userModuleAssessment?: Maybe<CourseModuleAssessmentUnion>;
  videowall?: Maybe<VideowallModuleNode>;
  frontendUrl?: Maybe<Scalars['String']>;
  /** The total live count objects in this query. */
  assessmentsCounter?: Maybe<Scalars['Int']>;
  realTypename?: Maybe<Scalars['String']>;
  getAccessModeDisplay?: Maybe<Scalars['String']>;
  getPrivacyDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<CourseModulePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CourseModuleNodeAuthorsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseModuleNodeRemindersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseModuleNodeCertificateTemplatesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseModuleNodeCertificateTemplateMapsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseModuleNodeSubmodulesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseModuleNodeDependantsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  course?: Maybe<Scalars['ID']>;
  course_Slug?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ID']>;
};

export type CourseModuleNodeTrainingForArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseModuleNodeModuleAssessmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseModuleNodeFeaturedModuleCoursesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type CourseModuleNodeUserModuleAssessmentArgs = {
  userId?: Maybe<Scalars['ID']>;
};

export type CourseModuleNodeConnection = {
  __typename?: 'CourseModuleNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseModuleNodeEdge>>;
};

/** A Relay edge containing a `CourseModuleNode` and its cursor. */
export type CourseModuleNodeEdge = {
  __typename?: 'CourseModuleNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CourseModuleNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CourseModulePermission = {
  __typename?: 'CourseModulePermission';
  /** Can add course module */
  addCoursemodule?: Maybe<Scalars['Boolean']>;
  /** Can change course module */
  changeCoursemodule?: Maybe<Scalars['Boolean']>;
  /** Can delete course module */
  deleteCoursemodule?: Maybe<Scalars['Boolean']>;
  /** Can view course module */
  viewCoursemodule?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum CourseModulePrivacy {
  /** visible to all participants */
  PUBLIC = 'PUBLIC',
  /** visible to participants who have authorizations */
  PRIVATE = 'PRIVATE',
  /** Password Protected */
  PASSWORD = 'PASSWORD',
}

export type CourseModuleUnion =
  | SurveyModuleNode
  | ExternalWebinarModuleNode
  | CourseModuleNode
  | ChatModuleNode
  | VideoModuleNode
  | LayoutModuleNode
  | DocumentModuleNode
  | IframeModuleNode
  | VideowallModuleNode;

export type CourseModuleUpdateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  /** The ID of the object. */
  id: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseModuleUpdateMutationPayload = {
  __typename?: 'CourseModuleUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseModule?: Maybe<CourseModuleNode>;
};

/** An enumeration. */
export enum CourseNavigationType {
  /** Stepper */
  STEPPER = 'STEPPER',
  /** Button */
  BUTTON = 'BUTTON',
  /** Tabs */
  TABS = 'TABS',
  /** Speed Dial */
  SPEEDDIAL = 'SPEEDDIAL',
}

export type CourseNode = Node & {
  __typename?: 'CourseNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  sites: SiteNodeConnection;
  owner: UserNode;
  company?: Maybe<CompanyNode>;
  groups: GroupNodeConnection;
  /** A human-readable name for the course. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  fromEmailSender?: Maybe<Scalars['String']>;
  /** The sender name of email (e.g. 'Sender name email@email.com') */
  fromEmailSenderName?: Maybe<Scalars['String']>;
  /** A human-readable headline for the course.  */
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  /** Display end course content */
  displayEndCourseContent: Scalars['Boolean'];
  /** End course content */
  endCourseContent?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  cover?: Maybe<ImageFiledType>;
  background?: Maybe<ImageFiledType>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  /** Force Display Auth  component if not logged */
  forceDisplayAuthComponentIfNotLogged: Scalars['Boolean'];
  /** Enable signup in Auth modal dialog */
  signupButtonEnable: Scalars['Boolean'];
  /** Enable google login button */
  loginGoogleButtonEnable: Scalars['Boolean'];
  /** Display participants counter */
  displayParticipantsCounter: Scalars['Boolean'];
  /** Accept anonymous enrollment */
  acceptAnonyumousEnrollment: Scalars['Boolean'];
  /** Force anonymous enrollment */
  forceAnonyumousEnrollment: Scalars['Boolean'];
  /** Can participate only if participation email is in course contact list */
  onlyContactsAuthorized: Scalars['Boolean'];
  /** Display continue button between modules */
  displayContinueButton: Scalars['Boolean'];
  /** Limit one automatic participation for each invitation. From the second access you have to fill in the form  */
  onlyOneParticipationPerInvitation: Scalars['Boolean'];
  /** The invitation does not require email verification   */
  useInsecureInvitation: Scalars['Boolean'];
  automaticallyAcceptsParticipation: Scalars['Boolean'];
  automaticallyAssignManageParticipationsPerm: Scalars['Boolean'];
  /** If true system send email for requested participations */
  enablePendingParticipationEmail: Scalars['Boolean'];
  /** If true system send email for confirmed participations */
  enableConfirmParticipationEmail: Scalars['Boolean'];
  acceptsParticipationByExternalUuid: Scalars['Boolean'];
  /** Name of the parameter in the url with which the uuid is sent, (deafult is uuid) */
  externalUuidParameterName?: Maybe<Scalars['String']>;
  /**
   * If setted the course is protected by password and the user must enter the
   * password in participation form to access the course
   */
  password?: Maybe<Scalars['String']>;
  externalUuidParameterRequired: Scalars['Boolean'];
  generateParticipationQrcode: Scalars['Boolean'];
  /** if setted participation button has external link */
  participationExternalLink?: Maybe<Scalars['String']>;
  /** Force auto open participation dialog */
  requireDataParticipation: Scalars['Boolean'];
  participationForm?: Maybe<SurveyNode>;
  /** if setted replace next webinar for course */
  featuredWebinar?: Maybe<CourseModuleNode>;
  /** Is the course only accessible to course staff? */
  hidden: Scalars['Boolean'];
  /** Should the course be listed on the main page? */
  listed: Scalars['Boolean'];
  /** Demo course */
  demo: Scalars['Boolean'];
  /** Used as author list title */
  authorListLabel?: Maybe<Scalars['String']>;
  commonAuthors: AuthorNodeConnection;
  authorsGroups: AuthorsGroupNodeConnection;
  templates: DbtemplatesNodeConnection;
  pdfProgram?: Maybe<FileFiledType>;
  /** Label for the button that allows you to download the course program */
  pdfProgramButtonLabel?: Maybe<Scalars['String']>;
  /** Replace participation toast success message */
  participationCustomToastSuccessMessage?: Maybe<Scalars['String']>;
  /** Display toast message on participation creation success */
  displayParticipationSuccessToast: Scalars['Boolean'];
  /** Replace participation email */
  participationCustomEmailContent?: Maybe<Scalars['String']>;
  /** Extra content in email */
  emailExtraContent?: Maybe<Scalars['String']>;
  certificateTemplates: CertificateTemplateNodeConnection;
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  tags?: Maybe<Array<Maybe<TagType>>>;
  layoutType?: Maybe<CourseLayoutType>;
  navigationType?: Maybe<CourseNavigationType>;
  mosaico?: Maybe<MosaicoNode>;
  defaultLanguage?: Maybe<CourseDefaultLanguage>;
  categories: CourseCategoryNodeConnection;
  theme?: Maybe<ThemeNode>;
  location?: Maybe<Scalars['String']>;
  certificateTemplateMaps: CourseCertificateTemplateMapNodeConnection;
  modules: CourseModuleNodeConnection;
  participations: CourseParticipationNodeConnection;
  courseparticipationimportfileSet: CourseParticipationImportFileNodeConnection;
  courseuserparticipationquotasSet: CourseUserParticipationQuotasNodeConnection;
  invitations: CourseInvitationNodeConnection;
  submodules?: Maybe<CourseModuleConnection>;
  participation?: Maybe<CourseParticipationNode>;
  participationsCount?: Maybe<Scalars['Int']>;
  authors?: Maybe<AuthorModuleConnection>;
  nextWebinar?: Maybe<ExternalWebinarModuleNode>;
  available?: Maybe<Scalars['Boolean']>;
  frontendUrl?: Maybe<Scalars['String']>;
  dashboardUrl?: Maybe<Scalars['String']>;
  activeUsersMonthGroups?: Maybe<Array<Maybe<ActiveUsersGroupsType>>>;
  getLayoutTypeDisplay?: Maybe<Scalars['String']>;
  getNavigationTypeDisplay?: Maybe<Scalars['String']>;
  getDefaultLanguageDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<CoursePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CourseNodeSitesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseNodeGroupsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseNodeCommonAuthorsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseNodeAuthorsGroupsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseNodeTemplatesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  label_Icontains?: Maybe<Scalars['String']>;
  label_Istartswith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type CourseNodeCertificateTemplatesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseNodeCategoriesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CourseNodeCertificateTemplateMapsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseNodeModulesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  course?: Maybe<Scalars['ID']>;
  course_Slug?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ID']>;
};

export type CourseNodeParticipationsArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
  manager?: Maybe<Scalars['ID']>;
  course?: Maybe<Scalars['ID']>;
  status?: Maybe<CourseParticipationStatus>;
  partial?: Maybe<Scalars['Boolean']>;
  course_Company?: Maybe<Scalars['ID']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  hasCertificate?: Maybe<Scalars['Boolean']>;
};

export type CourseNodeCourseparticipationimportfileSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseNodeCourseuserparticipationquotasSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseNodeInvitationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['ID']>;
  course?: Maybe<Scalars['ID']>;
};

export type CourseNodeSubmodulesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseNodeParticipationArgs = {
  userId?: Maybe<Scalars['ID']>;
  participationId?: Maybe<Scalars['ID']>;
};

export type CourseNodeAuthorsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseNodeConnection = {
  __typename?: 'CourseNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CourseNode` and its cursor. */
export type CourseNodeEdge = {
  __typename?: 'CourseNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CourseNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CoursePartecipationManageStatusMutationInput = {
  status: CourseParticipationStatus;
  courseId: Scalars['ID'];
  participationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CoursePartecipationManageStatusMutationPayload = {
  __typename?: 'CoursePartecipationManageStatusMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  participation?: Maybe<CourseParticipationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  course?: Maybe<CourseNode>;
};

export type CoursePartecipationResendConfirmEmailInput = {
  courseId: Scalars['ID'];
  participationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CoursePartecipationResendConfirmEmailPayload = {
  __typename?: 'CoursePartecipationResendConfirmEmailPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  participation?: Maybe<CourseParticipationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  course?: Maybe<CourseNode>;
};

export type CourseParticipationApplyImportMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseParticipationApplyImportMutationPayload = {
  __typename?: 'CourseParticipationApplyImportMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  result?: Maybe<ImportResultNode>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseParticipationImportFile?: Maybe<CourseParticipationImportFileNode>;
};

export type CourseParticipationCreateMutationInput = {
  user?: Maybe<Scalars['ID']>;
  data?: Maybe<Scalars['JSONString']>;
  externalUuid?: Maybe<Scalars['String']>;
  manager?: Maybe<Scalars['ID']>;
  course: Scalars['ID'];
  invitation?: Maybe<Scalars['ID']>;
  formResponse?: Maybe<Scalars['ID']>;
  certificate?: Maybe<Scalars['ID']>;
  certificates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  qrcode?: Maybe<Scalars['UploadType']>;
  /** Set list of course certificate template maps */
  coursecertificatetemplatemapSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseParticipationCreateMutationPayload = {
  __typename?: 'CourseParticipationCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseParticipation?: Maybe<CourseParticipationNode>;
};

export type CourseParticipationDeleteMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseParticipationDeleteMutationPayload = {
  __typename?: 'CourseParticipationDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseParticipation?: Maybe<CourseParticipationNode>;
};

export type CourseParticipationImportFileCreateMutationInput = {
  course: Scalars['ID'];
  file: Scalars['UploadType'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseParticipationImportFileCreateMutationPayload = {
  __typename?: 'CourseParticipationImportFileCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseParticipationImportFile?: Maybe<CourseParticipationImportFileNode>;
};

export type CourseParticipationImportFileNode = Node & {
  __typename?: 'CourseParticipationImportFileNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  course: CourseNode;
  file?: Maybe<FileFiledType>;
  userHasPermission?: Maybe<CourseParticipationImportFilePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CourseParticipationImportFileNodeConnection = {
  __typename?: 'CourseParticipationImportFileNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseParticipationImportFileNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CourseParticipationImportFileNode` and its cursor. */
export type CourseParticipationImportFileNodeEdge = {
  __typename?: 'CourseParticipationImportFileNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CourseParticipationImportFileNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CourseParticipationImportFilePermission = {
  __typename?: 'CourseParticipationImportFilePermission';
  /** Can add course participation import file */
  addCourseparticipationimportfile?: Maybe<Scalars['Boolean']>;
  /** Can change course participation import file */
  changeCourseparticipationimportfile?: Maybe<Scalars['Boolean']>;
  /** Can delete course participation import file */
  deleteCourseparticipationimportfile?: Maybe<Scalars['Boolean']>;
  /** Can view course participation import file */
  viewCourseparticipationimportfile?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type CourseParticipationImportFileUpdateMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  course?: Maybe<Scalars['ID']>;
  file?: Maybe<Scalars['UploadType']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseParticipationImportFileUpdateMutationPayload = {
  __typename?: 'CourseParticipationImportFileUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseParticipationImportFile?: Maybe<CourseParticipationImportFileNode>;
};

export type CourseParticipationNode = Node & {
  __typename?: 'CourseParticipationNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  user: UserNode;
  manager?: Maybe<UserNode>;
  course: CourseNode;
  invitation?: Maybe<CourseInvitationNode>;
  data?: Maybe<Scalars['JSONString']>;
  formResponse?: Maybe<SurveyResponseNode>;
  status: CourseParticipationStatus;
  externalUuid?: Maybe<Scalars['String']>;
  partial: Scalars['Boolean'];
  certificate?: Maybe<CertificateNode>;
  certificates: CertificateNodeConnection;
  qrcode?: Maybe<ImageFiledType>;
  coursecertificatetemplatemapSet: CourseCertificateTemplateMapNodeConnection;
  moduleAssessments: CourseModuleAssessmentNodeConnection;
  isOnline?: Maybe<Scalars['Boolean']>;
  lockedModules?: Maybe<Array<Maybe<CourseModuleUnion>>>;
  dataChanges?: Maybe<Scalars['JSONString']>;
  getStatusDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<CourseParticipationPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CourseParticipationNodeCertificatesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseParticipationNodeCoursecertificatetemplatemapSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseParticipationNodeModuleAssessmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CourseParticipationNodeConnection = {
  __typename?: 'CourseParticipationNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseParticipationNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
  /** The total live count objects in this query. */
  liveCount?: Maybe<Scalars['Int']>;
  monthGroupCount?: Maybe<Array<Maybe<MonthGroupType>>>;
};

/** A Relay edge containing a `CourseParticipationNode` and its cursor. */
export type CourseParticipationNodeEdge = {
  __typename?: 'CourseParticipationNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CourseParticipationNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CourseParticipationPermission = {
  __typename?: 'CourseParticipationPermission';
  /** Can add course participation */
  addCourseparticipation?: Maybe<Scalars['Boolean']>;
  /** Can change course participation */
  changeCourseparticipation?: Maybe<Scalars['Boolean']>;
  /** Can delete course participation */
  deleteCourseparticipation?: Maybe<Scalars['Boolean']>;
  /** Can view course participation */
  viewCourseparticipation?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum CourseParticipationStatus {
  /** Requested */
  REQUESTED = 'REQUESTED',
  /** Active */
  ACTIVE = 'ACTIVE',
  /** Dropped */
  DROPPED = 'DROPPED',
  /** Denied */
  DENIED = 'DENIED',
}

export type CourseParticipationUpdateMutationInput = {
  data?: Maybe<Scalars['JSONString']>;
  externalUuid?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  manager?: Maybe<Scalars['ID']>;
  course?: Maybe<Scalars['ID']>;
  invitation?: Maybe<Scalars['ID']>;
  formResponse?: Maybe<Scalars['ID']>;
  certificate?: Maybe<Scalars['ID']>;
  certificates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  qrcode?: Maybe<Scalars['UploadType']>;
  /** Set list of course certificate template maps */
  coursecertificatetemplatemapSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseParticipationUpdateMutationPayload = {
  __typename?: 'CourseParticipationUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseParticipation?: Maybe<CourseParticipationNode>;
};

export type CoursePermission = {
  __typename?: 'CoursePermission';
  /** Can add Course */
  addCourse?: Maybe<Scalars['Boolean']>;
  /** Can change Course */
  changeCourse?: Maybe<Scalars['Boolean']>;
  /** Can delete Course */
  deleteCourse?: Maybe<Scalars['Boolean']>;
  /** Can view Course */
  viewCourse?: Maybe<Scalars['Boolean']>;
  /** Can manage invitations */
  manageInvitationsCourse?: Maybe<Scalars['Boolean']>;
  /** Can manage participation */
  manageParticipationCourse?: Maybe<Scalars['Boolean']>;
  /** Can add participation */
  addParticipationCourse?: Maybe<Scalars['Boolean']>;
  /** Can moderate chat */
  moderateChatCourse?: Maybe<Scalars['Boolean']>;
  /** Can download full report of course */
  downloadFullReport?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export enum CourseReminderRecipientList {
  GUESTS = 'GUESTS',
  CONTACTS = 'CONTACTS',
  ASSESSMENTS = 'ASSESSMENTS',
}

export type CourseSendAllInvitationsInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseSendAllInvitationsPayload = {
  __typename?: 'CourseSendAllInvitationsPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  sentCount?: Maybe<Scalars['Int']>;
  skypCount?: Maybe<Scalars['Int']>;
  failCount?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  course?: Maybe<CourseNode>;
};

export type CourseUpdateMutationInput = {
  owner?: Maybe<Scalars['ID']>;
  /** A human-readable name for the course. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  fromEmailSender?: Maybe<Scalars['String']>;
  /** The sender name of email (e.g. 'Sender name email@email.com') */
  fromEmailSenderName?: Maybe<Scalars['String']>;
  /** A human-readable headline for the course.  */
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  /** End course content */
  endCourseContent?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  /** Name of the parameter in the url with which the uuid is sent, (deafult is uuid) */
  externalUuidParameterName?: Maybe<Scalars['String']>;
  /**
   * If setted the course is protected by password and the user must enter the
   * password in participation form to access the course
   */
  password?: Maybe<Scalars['String']>;
  /** if setted participation button has external link */
  participationExternalLink?: Maybe<Scalars['String']>;
  /** Used as author list title */
  authorListLabel?: Maybe<Scalars['String']>;
  /** Label for the button that allows you to download the course program */
  pdfProgramButtonLabel?: Maybe<Scalars['String']>;
  /** Replace participation toast success message */
  participationCustomToastSuccessMessage?: Maybe<Scalars['String']>;
  /** Replace participation email */
  participationCustomEmailContent?: Maybe<Scalars['String']>;
  /** Extra content in email */
  emailExtraContent?: Maybe<Scalars['String']>;
  layoutType?: Maybe<CourseLayoutType>;
  navigationType?: Maybe<CourseNavigationType>;
  defaultLanguage?: Maybe<CourseDefaultLanguage>;
  location?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  sites?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Display end course content */
  displayEndCourseContent?: Maybe<Scalars['Boolean']>;
  cover?: Maybe<Scalars['UploadType']>;
  background?: Maybe<Scalars['UploadType']>;
  /** Force Display Auth  component if not logged */
  forceDisplayAuthComponentIfNotLogged?: Maybe<Scalars['Boolean']>;
  /** Enable signup in Auth modal dialog */
  signupButtonEnable?: Maybe<Scalars['Boolean']>;
  /** Enable google login button */
  loginGoogleButtonEnable?: Maybe<Scalars['Boolean']>;
  /** Display participants counter */
  displayParticipantsCounter?: Maybe<Scalars['Boolean']>;
  /** Accept anonymous enrollment */
  acceptAnonyumousEnrollment?: Maybe<Scalars['Boolean']>;
  /** Force anonymous enrollment */
  forceAnonyumousEnrollment?: Maybe<Scalars['Boolean']>;
  /** Can participate only if participation email is in course contact list */
  onlyContactsAuthorized?: Maybe<Scalars['Boolean']>;
  /** Display continue button between modules */
  displayContinueButton?: Maybe<Scalars['Boolean']>;
  /** Limit one automatic participation for each invitation. From the second access you have to fill in the form  */
  onlyOneParticipationPerInvitation?: Maybe<Scalars['Boolean']>;
  /** The invitation does not require email verification   */
  useInsecureInvitation?: Maybe<Scalars['Boolean']>;
  automaticallyAcceptsParticipation?: Maybe<Scalars['Boolean']>;
  automaticallyAssignManageParticipationsPerm?: Maybe<Scalars['Boolean']>;
  /** If true system send email for requested participations */
  enablePendingParticipationEmail?: Maybe<Scalars['Boolean']>;
  /** If true system send email for confirmed participations */
  enableConfirmParticipationEmail?: Maybe<Scalars['Boolean']>;
  acceptsParticipationByExternalUuid?: Maybe<Scalars['Boolean']>;
  externalUuidParameterRequired?: Maybe<Scalars['Boolean']>;
  generateParticipationQrcode?: Maybe<Scalars['Boolean']>;
  /** Force auto open participation dialog */
  requireDataParticipation?: Maybe<Scalars['Boolean']>;
  participationForm?: Maybe<Scalars['ID']>;
  /** if setted replace next webinar for course */
  featuredWebinar?: Maybe<Scalars['ID']>;
  /** Is the course only accessible to course staff? */
  hidden?: Maybe<Scalars['Boolean']>;
  /** Should the course be listed on the main page? */
  listed?: Maybe<Scalars['Boolean']>;
  /** Demo course */
  demo?: Maybe<Scalars['Boolean']>;
  commonAuthors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  authorsGroups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  templates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  pdfProgram?: Maybe<Scalars['UploadType']>;
  /** Display toast message on participation creation success */
  displayParticipationSuccessToast?: Maybe<Scalars['Boolean']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  mosaico?: Maybe<Scalars['ID']>;
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  theme?: Maybe<Scalars['ID']>;
  /** Set list of Contacts */
  contacts?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseUpdateMutationPayload = {
  __typename?: 'CourseUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  course?: Maybe<CourseNode>;
};

export type CourseUserParticipationQuotasNode = Node & {
  __typename?: 'CourseUserParticipationQuotasNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  participationsQuota: Scalars['Int'];
  course: CourseNode;
  label?: Maybe<Scalars['String']>;
  user: UserNode;
  userHasPermission?: Maybe<CourseUserParticipationQuotasPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type CourseUserParticipationQuotasNodeConnection = {
  __typename?: 'CourseUserParticipationQuotasNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CourseUserParticipationQuotasNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CourseUserParticipationQuotasNode` and its cursor. */
export type CourseUserParticipationQuotasNodeEdge = {
  __typename?: 'CourseUserParticipationQuotasNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CourseUserParticipationQuotasNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CourseUserParticipationQuotasPermission = {
  __typename?: 'CourseUserParticipationQuotasPermission';
  /** Can add course user participation quotas */
  addCourseuserparticipationquotas?: Maybe<Scalars['Boolean']>;
  /** Can change course user participation quotas */
  changeCourseuserparticipationquotas?: Maybe<Scalars['Boolean']>;
  /** Can delete course user participation quotas */
  deleteCourseuserparticipationquotas?: Maybe<Scalars['Boolean']>;
  /** Can view course user participation quotas */
  viewCourseuserparticipationquotas?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type CourseUserParticipationsQuotaCreateMutationInput = {
  participationsQuota: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  course: Scalars['ID'];
  user: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseUserParticipationsQuotaCreateMutationPayload = {
  __typename?: 'CourseUserParticipationsQuotaCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseUserParticipationQuotas?: Maybe<CourseUserParticipationQuotasNode>;
};

export type CourseUserParticipationsQuotaDeleteMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseUserParticipationsQuotaDeleteMutationPayload = {
  __typename?: 'CourseUserParticipationsQuotaDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseUserParticipationQuotas?: Maybe<CourseUserParticipationQuotasNode>;
};

export type CourseUserParticipationsQuotaUpdateMutationInput = {
  participationsQuota?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  course?: Maybe<Scalars['ID']>;
  user?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CourseUserParticipationsQuotaUpdateMutationPayload = {
  __typename?: 'CourseUserParticipationsQuotaUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseUserParticipationQuotas?: Maybe<CourseUserParticipationQuotasNode>;
};

export type CreateDemoCourseInput = {
  email?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  primaryColor: Scalars['String'];
  secondaryColor: Scalars['String'];
  logo: Scalars['UploadType'];
  courseTemplateId: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDemoCoursePayload = {
  __typename?: 'CreateDemoCoursePayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  status?: Maybe<Scalars['String']>;
  course?: Maybe<CourseNode>;
  courseUrl?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DbtemplateCreateMutationInput = {
  /** label */
  label?: Maybe<Scalars['String']>;
  /** Set custom email subject */
  subject?: Maybe<Scalars['String']>;
  /** Example: 'herald/html/default.html' */
  name: TemplateName;
  content?: Maybe<Scalars['String']>;
  /** Email design */
  design?: Maybe<Scalars['JSONString']>;
  /** Set list of Courses */
  courseSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DbtemplateCreateMutationPayload = {
  __typename?: 'DbtemplateCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  template?: Maybe<DbtemplatesNode>;
};

export type DbtemplatesNode = Node & {
  __typename?: 'DbtemplatesNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  /** label */
  label?: Maybe<Scalars['String']>;
  /** Set custom email subject */
  subject?: Maybe<Scalars['String']>;
  /** Example: 'herald/html/default.html' */
  name: TemplateName;
  content: Scalars['String'];
  creationDate: Scalars['DateTime'];
  lastChanged: Scalars['DateTime'];
  /** Email design */
  design?: Maybe<Scalars['JSONString']>;
  courseSet: CourseNodeConnection;
  preview?: Maybe<Scalars['String']>;
  getNameDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<TemplatePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type DbtemplatesNodeCourseSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type DbtemplatesNodePreviewArgs = {
  courseId?: Maybe<Scalars['ID']>;
};

export type DbtemplatesNodeConnection = {
  __typename?: 'DbtemplatesNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DbtemplatesNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DbtemplatesNode` and its cursor. */
export type DbtemplatesNodeEdge = {
  __typename?: 'DbtemplatesNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DbtemplatesNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type DbtemplateUpdateMutationInput = {
  /** label */
  label?: Maybe<Scalars['String']>;
  /** Set custom email subject */
  subject?: Maybe<Scalars['String']>;
  /** Example: 'herald/html/default.html' */
  name?: Maybe<TemplateName>;
  content?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  lastChanged?: Maybe<Scalars['DateTime']>;
  /** Email design */
  design?: Maybe<Scalars['JSONString']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Set list of Courses */
  courseSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DbtemplateUpdateMutationPayload = {
  __typename?: 'DbtemplateUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  template?: Maybe<DbtemplatesNode>;
};

export type DocumentModuleCreateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  coursemodulePtr?: Maybe<Scalars['ID']>;
  /** The ID of the object. */
  id?: Maybe<Scalars['ID']>;
  course: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  document?: Maybe<Scalars['UploadType']>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DocumentModuleCreateMutationPayload = {
  __typename?: 'DocumentModuleCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  documentModule?: Maybe<DocumentModuleNode>;
};

export type DocumentModuleNode = Node & {
  __typename?: 'DocumentModuleNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  order: Scalars['Int'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  course: CourseNode;
  parent?: Maybe<CourseModuleNode>;
  requiredModule?: Maybe<CourseModuleNode>;
  routable: Scalars['Boolean'];
  routableExact: Scalars['Boolean'];
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** If true only staff user can test module (not locked) */
  testingMode: Scalars['Boolean'];
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete: Scalars['Boolean'];
  /** Exclude module in participation check completed */
  disableCheckComplete: Scalars['Boolean'];
  /** Display complete/continue button in module */
  displayCompleteButton: Scalars['Boolean'];
  /** send email when module is completed */
  sendEmailWhenCompleted: Scalars['Boolean'];
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug: Scalars['String'];
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  /** Force module locked for all participants */
  forceLocked: Scalars['Boolean'];
  accessMode: CourseModuleAccessMode;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy: CourseModulePrivacy;
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  coursemodulePtr: CourseModuleNode;
  document?: Maybe<FileFiledType>;
  userModuleAssessment?: Maybe<CourseModuleAssessmentUnion>;
  submodules?: Maybe<CourseModuleConnection>;
  videowall?: Maybe<VideowallModuleNode>;
  frontendUrl?: Maybe<Scalars['String']>;
  authors?: Maybe<AuthorModuleConnection>;
  reminders?: Maybe<ReminderNodeConnection>;
  /** The total live count objects in this query. */
  assessmentsCounter?: Maybe<Scalars['Int']>;
  getAccessModeDisplay?: Maybe<Scalars['String']>;
  getPrivacyDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<DocumentModulePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type DocumentModuleNodeUserModuleAssessmentArgs = {
  userId?: Maybe<Scalars['ID']>;
};

export type DocumentModuleNodeSubmodulesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DocumentModuleNodeAuthorsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DocumentModuleNodeRemindersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DocumentModulePermission = {
  __typename?: 'DocumentModulePermission';
  /** Can add document module */
  addDocumentmodule?: Maybe<Scalars['Boolean']>;
  /** Can change document module */
  changeDocumentmodule?: Maybe<Scalars['Boolean']>;
  /** Can delete document module */
  deleteDocumentmodule?: Maybe<Scalars['Boolean']>;
  /** Can view document module */
  viewDocumentmodule?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type DocumentModuleUpdateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  /** The ID of the object. */
  id: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  document?: Maybe<Scalars['UploadType']>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DocumentModuleUpdateMutationPayload = {
  __typename?: 'DocumentModuleUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  documentModule?: Maybe<DocumentModuleNode>;
};

/** Represents an error in the input of a mutation. */
export type Error = {
  __typename?: 'Error';
  /**
   * Name of a field that caused the error. A value of
   *         `null` indicates that the error isn't associated with a particular
   *         field.
   */
  field?: Maybe<Scalars['String']>;
  /** The error message. */
  message?: Maybe<Scalars['String']>;
  /** The error code. */
  errorCode?: Maybe<Scalars['String']>;
};

export type ExternalWebinarModuleChangeStatusMutationInput = {
  status?: Maybe<ExternalWebinarModuleStatus>;
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ExternalWebinarModuleChangeStatusMutationPayload = {
  __typename?: 'ExternalWebinarModuleChangeStatusMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  lockedWarning?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  externalWebinarModule?: Maybe<ExternalWebinarModuleNode>;
};

export type ExternalWebinarModuleCreateMutationInput = {
  /** the webinar variants */
  variants?: Maybe<Scalars['JSONString']>;
  order?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  liveUrl?: Maybe<Scalars['String']>;
  recordedUrl?: Maybe<Scalars['String']>;
  /** if webinar is not embed in platform */
  externalLink?: Maybe<Scalars['String']>;
  /** name of link */
  externalLinkName?: Maybe<Scalars['String']>;
  /** Custom iframe Attributes  */
  iframeProps?: Maybe<Scalars['JSONString']>;
  /** Wall chat channel */
  chatChannel?: Maybe<Scalars['String']>;
  broadcastChatChannel?: Maybe<Scalars['String']>;
  chatModeratorUserId?: Maybe<Scalars['String']>;
  plannedStartDate?: Maybe<Scalars['DateTime']>;
  plannedEndDate?: Maybe<Scalars['DateTime']>;
  /** Custom text in calendar */
  icalendarText?: Maybe<Scalars['String']>;
  status?: Maybe<ExternalWebinarModuleStatus>;
  startedAt?: Maybe<Scalars['DateTime']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  requiredUserPresencePercentile?: Maybe<Scalars['Int']>;
  coursemodulePtr?: Maybe<Scalars['ID']>;
  /** The ID of the object. */
  id?: Maybe<Scalars['ID']>;
  course: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  liveMeeting?: Maybe<Scalars['ID']>;
  iframeMode?: Maybe<Scalars['Boolean']>;
  /** Enable webinar ical in participation email */
  icalendarEnabled?: Maybe<Scalars['Boolean']>;
  /** Enable chat in webinar participant view */
  chatEnabled?: Maybe<Scalars['Boolean']>;
  /** Enable broadcast channel in webinar chat */
  broadcastChannelEnabled?: Maybe<Scalars['Boolean']>;
  /** Enable wall channel in webinar chat */
  wallChannelEnabled?: Maybe<Scalars['Boolean']>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ExternalWebinarModuleCreateMutationPayload = {
  __typename?: 'ExternalWebinarModuleCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  externalWebinarModule?: Maybe<ExternalWebinarModuleNode>;
};

export type ExternalWebinarModuleNode = Node & {
  __typename?: 'ExternalWebinarModuleNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  order: Scalars['Int'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  course: CourseNode;
  parent?: Maybe<CourseModuleNode>;
  requiredModule?: Maybe<CourseModuleNode>;
  routable: Scalars['Boolean'];
  routableExact: Scalars['Boolean'];
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** If true only staff user can test module (not locked) */
  testingMode: Scalars['Boolean'];
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete: Scalars['Boolean'];
  /** Exclude module in participation check completed */
  disableCheckComplete: Scalars['Boolean'];
  /** Display complete/continue button in module */
  displayCompleteButton: Scalars['Boolean'];
  /** send email when module is completed */
  sendEmailWhenCompleted: Scalars['Boolean'];
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug: Scalars['String'];
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  /** Force module locked for all participants */
  forceLocked: Scalars['Boolean'];
  accessMode: CourseModuleAccessMode;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy: CourseModulePrivacy;
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  coursemodulePtr: CourseModuleNode;
  liveUrl?: Maybe<Scalars['String']>;
  liveMeeting?: Maybe<ZoomMeetingNode>;
  recordedUrl?: Maybe<Scalars['String']>;
  /** if webinar is not embed in platform */
  externalLink?: Maybe<Scalars['String']>;
  /** name of link */
  externalLinkName?: Maybe<Scalars['String']>;
  iframeMode: Scalars['Boolean'];
  iframeProps?: Maybe<Array<HtmlAttributeNode>>;
  variants?: Maybe<Array<WebinarVariant>>;
  /** Wall chat channel */
  chatChannel?: Maybe<Scalars['String']>;
  broadcastChatChannel?: Maybe<Scalars['String']>;
  chatModeratorUserId?: Maybe<Scalars['String']>;
  plannedStartDate?: Maybe<Scalars['DateTime']>;
  plannedEndDate?: Maybe<Scalars['DateTime']>;
  /** Enable webinar ical in participation email */
  icalendarEnabled: Scalars['Boolean'];
  /** Custom text in calendar */
  icalendarText?: Maybe<Scalars['String']>;
  /** Enable chat in webinar participant view */
  chatEnabled: Scalars['Boolean'];
  /** Enable broadcast channel in webinar chat */
  broadcastChannelEnabled: Scalars['Boolean'];
  /** Enable wall channel in webinar chat */
  wallChannelEnabled: Scalars['Boolean'];
  status: ExternalWebinarModuleStatus;
  startedAt: Scalars['DateTime'];
  endedAt: Scalars['DateTime'];
  requiredUserPresencePercentile?: Maybe<Scalars['Int']>;
  userModuleAssessment?: Maybe<CourseModuleAssessmentUnion>;
  submodules?: Maybe<CourseModuleConnection>;
  videowall?: Maybe<VideowallModuleNode>;
  frontendUrl?: Maybe<Scalars['String']>;
  authors?: Maybe<AuthorModuleConnection>;
  reminders?: Maybe<ReminderNodeConnection>;
  /** The total live count objects in this query. */
  assessmentsCounter?: Maybe<Scalars['Int']>;
  isOver?: Maybe<Scalars['Boolean']>;
  chatChannels?: Maybe<Array<Maybe<ChatChannelNode>>>;
  wallChatChannels?: Maybe<Array<Maybe<ChatChannelNode>>>;
  chatModeratorUserToken?: Maybe<Scalars['String']>;
  lastStatusEvent?: Maybe<ExternalWebinarStatusEventNode>;
  statusEvents?: Maybe<Array<Maybe<ExternalWebinarStatusEventNode>>>;
  livePoll?: Maybe<SurveyModuleNode>;
  liveTime?: Maybe<Scalars['Int']>;
  getAccessModeDisplay?: Maybe<Scalars['String']>;
  getPrivacyDisplay?: Maybe<Scalars['String']>;
  getStatusDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<ExternalWebinarModulePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type ExternalWebinarModuleNodeUserModuleAssessmentArgs = {
  userId?: Maybe<Scalars['ID']>;
};

export type ExternalWebinarModuleNodeSubmodulesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ExternalWebinarModuleNodeAuthorsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ExternalWebinarModuleNodeRemindersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ExternalWebinarModuleNodeConnection = {
  __typename?: 'ExternalWebinarModuleNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ExternalWebinarModuleNodeEdge>>;
};

/** A Relay edge containing a `ExternalWebinarModuleNode` and its cursor. */
export type ExternalWebinarModuleNodeEdge = {
  __typename?: 'ExternalWebinarModuleNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ExternalWebinarModuleNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ExternalWebinarModulePermission = {
  __typename?: 'ExternalWebinarModulePermission';
  /** Can add external webinar module */
  addExternalwebinarmodule?: Maybe<Scalars['Boolean']>;
  /** Can change external webinar module */
  changeExternalwebinarmodule?: Maybe<Scalars['Boolean']>;
  /** Can delete external webinar module */
  deleteExternalwebinarmodule?: Maybe<Scalars['Boolean']>;
  /** Can view external webinar module */
  viewExternalwebinarmodule?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum ExternalWebinarModuleStatus {
  /** Is Coming */
  IS_COMING = 'IS_COMING',
  /** In Progress */
  IN_PROGRESS = 'IN_PROGRESS',
  /** Is Over */
  IS_OVER = 'IS_OVER',
}

export type ExternalWebinarModuleUpdateMutationInput = {
  variants?: Maybe<Array<Maybe<WebinarVariantInput>>>;
  order?: Maybe<Scalars['Int']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  liveUrl?: Maybe<Scalars['String']>;
  recordedUrl?: Maybe<Scalars['String']>;
  /** if webinar is not embed in platform */
  externalLink?: Maybe<Scalars['String']>;
  /** name of link */
  externalLinkName?: Maybe<Scalars['String']>;
  /** Custom iframe Attributes  */
  iframeProps?: Maybe<Scalars['JSONString']>;
  /** Wall chat channel */
  chatChannel?: Maybe<Scalars['String']>;
  plannedStartDate?: Maybe<Scalars['DateTime']>;
  plannedEndDate?: Maybe<Scalars['DateTime']>;
  /** Custom text in calendar */
  icalendarText?: Maybe<Scalars['String']>;
  status?: Maybe<ExternalWebinarModuleStatus>;
  requiredUserPresencePercentile?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  liveMeeting?: Maybe<Scalars['ID']>;
  iframeMode?: Maybe<Scalars['Boolean']>;
  /** Enable webinar ical in participation email */
  icalendarEnabled?: Maybe<Scalars['Boolean']>;
  /** Enable chat in webinar participant view */
  chatEnabled?: Maybe<Scalars['Boolean']>;
  /** Enable broadcast channel in webinar chat */
  broadcastChannelEnabled?: Maybe<Scalars['Boolean']>;
  /** Enable wall channel in webinar chat */
  wallChannelEnabled?: Maybe<Scalars['Boolean']>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ExternalWebinarModuleUpdateMutationPayload = {
  __typename?: 'ExternalWebinarModuleUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  externalWebinarModule?: Maybe<ExternalWebinarModuleNode>;
};

export type ExternalWebinarStatusEventNode = {
  __typename?: 'ExternalWebinarStatusEventNode';
  status: ExternalWebinarModuleStatus;
  user?: Maybe<UserNode>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export type FaviconGeneratorNode = Node & {
  __typename?: 'FaviconGeneratorNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  masterPicture?: Maybe<ImageFiledType>;
  appName: Scalars['String'];
  appDescription: Scalars['String'];
  developerName?: Maybe<Scalars['String']>;
  developerUrl?: Maybe<Scalars['String']>;
  site: SiteNodeConnection;
  version: Scalars['String'];
  /** last resp data */
  response?: Maybe<Scalars['JSONString']>;
  htmlCode?: Maybe<Scalars['String']>;
  preview?: Maybe<ImageFiledType>;
  htmlTags?: Maybe<Array<Maybe<MetaTagNode>>>;
  userHasPermission?: Maybe<FaviconGeneratorPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type FaviconGeneratorNodeSiteArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type FaviconGeneratorNodeConnection = {
  __typename?: 'FaviconGeneratorNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FaviconGeneratorNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `FaviconGeneratorNode` and its cursor. */
export type FaviconGeneratorNodeEdge = {
  __typename?: 'FaviconGeneratorNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<FaviconGeneratorNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type FaviconGeneratorPermission = {
  __typename?: 'FaviconGeneratorPermission';
  /** Can add favicon generator */
  addFavicongenerator?: Maybe<Scalars['Boolean']>;
  /** Can change favicon generator */
  changeFavicongenerator?: Maybe<Scalars['Boolean']>;
  /** Can delete favicon generator */
  deleteFavicongenerator?: Maybe<Scalars['Boolean']>;
  /** Can view favicon generator */
  viewFavicongenerator?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** Represents an File field */
export type FileFiledType = {
  __typename?: 'FileFiledType';
  /** name */
  name?: Maybe<Scalars['String']>;
  /** file name */
  fileName?: Maybe<Scalars['String']>;
  /** url */
  url?: Maybe<Scalars['String']>;
  /** extension */
  extension?: Maybe<Scalars['String']>;
  /** Size */
  size?: Maybe<Scalars['Int']>;
};

export type FileNode = Node & {
  __typename?: 'FileNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  title: Scalars['String'];
  file?: Maybe<FileFiledType>;
  createdAt: Scalars['DateTime'];
  uploadedByUser?: Maybe<UserNode>;
  fileSize?: Maybe<Scalars['Int']>;
  userHasPermission?: Maybe<FilePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type FileNodeConnection = {
  __typename?: 'FileNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FileNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `FileNode` and its cursor. */
export type FileNodeEdge = {
  __typename?: 'FileNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<FileNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type FilePermission = {
  __typename?: 'FilePermission';
  /** Can add file */
  addFile?: Maybe<Scalars['Boolean']>;
  /** Can change file */
  changeFile?: Maybe<Scalars['Boolean']>;
  /** Can delete file */
  deleteFile?: Maybe<Scalars['Boolean']>;
  /** Can view file */
  viewFile?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type GenericInstancePermission = {
  __typename?: 'GenericInstancePermission';
  /** List of available Permission. */
  availablePermissionsOfInstance?: Maybe<Array<PermissionNode>>;
  /** List of User with Permission. */
  usersPermissionsOfInstance?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  groupsPermissionsOfInstance?: Maybe<Array<GroupPermissions>>;
};

export type GroupAddUserMutationInput = {
  referredInstance?: Maybe<Scalars['ID']>;
  user: Scalars['ID'];
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GroupAddUserMutationPayload = {
  __typename?: 'GroupAddUserMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** Instance Permission */
  genericInstancePermissions?: Maybe<GenericInstancePermission>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  group?: Maybe<GroupNode>;
};

export type GroupCreateMutationInput = {
  referredInstance?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of companys */
  companySet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  courseSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of users */
  userSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GroupCreateMutationPayload = {
  __typename?: 'GroupCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** Instance Permission */
  genericInstancePermissions?: Maybe<GenericInstancePermission>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  group?: Maybe<GroupNode>;
};

export type GroupDeleteMutationInput = {
  referredInstance?: Maybe<Scalars['ID']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GroupDeleteMutationPayload = {
  __typename?: 'GroupDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** Instance Permission */
  genericInstancePermissions?: Maybe<GenericInstancePermission>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  group?: Maybe<GroupNode>;
};

export type GroupNode = Node & {
  __typename?: 'GroupNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: PermissionNodeConnection;
  label?: Maybe<Scalars['String']>;
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  userSet: UserNodeConnection;
  courseSet: CourseNodeConnection;
  companySet: CompanyNodeConnection;
  userHasPermission?: Maybe<GroupPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type GroupNodePermissionsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type GroupNodeUserSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_Icontains?: Maybe<Scalars['String']>;
  email_Istartswith?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_Icontains?: Maybe<Scalars['String']>;
  firstName_Istartswith?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_Icontains?: Maybe<Scalars['String']>;
  lastName_Istartswith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  anonymousUser?: Maybe<Scalars['Boolean']>;
  manageParticipationCourseId?: Maybe<Scalars['String']>;
};

export type GroupNodeCourseSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type GroupNodeCompanySetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type GroupNodeConnection = {
  __typename?: 'GroupNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GroupNodeEdge>>;
};

/** A Relay edge containing a `GroupNode` and its cursor. */
export type GroupNodeEdge = {
  __typename?: 'GroupNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<GroupNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type GroupPermission = {
  __typename?: 'GroupPermission';
  /** Can add group */
  addGroup?: Maybe<Scalars['Boolean']>;
  /** Can change group */
  changeGroup?: Maybe<Scalars['Boolean']>;
  /** Can delete group */
  deleteGroup?: Maybe<Scalars['Boolean']>;
  /** Can view group */
  viewGroup?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type GroupPermissions = {
  __typename?: 'GroupPermissions';
  permissions?: Maybe<Array<Scalars['String']>>;
  group?: Maybe<GroupNode>;
};

export type GroupRemoveUserMutationInput = {
  referredInstance?: Maybe<Scalars['ID']>;
  user: Scalars['ID'];
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GroupRemoveUserMutationPayload = {
  __typename?: 'GroupRemoveUserMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** Instance Permission */
  genericInstancePermissions?: Maybe<GenericInstancePermission>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  group?: Maybe<GroupNode>;
};

export type HtmlAttributeInput = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type HtmlAttributeNode = {
  __typename?: 'HtmlAttributeNode';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type IframeModuleCreateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  url?: Maybe<Scalars['String']>;
  iframeHeight?: Maybe<Scalars['Int']>;
  iframeWidth?: Maybe<Scalars['Int']>;
  coursemodulePtr?: Maybe<Scalars['ID']>;
  /** The ID of the object. */
  id?: Maybe<Scalars['ID']>;
  course: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  external?: Maybe<Scalars['Boolean']>;
  participationDataInUrlParams?: Maybe<Scalars['Boolean']>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type IframeModuleCreateMutationPayload = {
  __typename?: 'IframeModuleCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  iframeModule?: Maybe<IframeModuleNode>;
};

export type IframeModuleNode = Node & {
  __typename?: 'IframeModuleNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  order: Scalars['Int'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  course: CourseNode;
  parent?: Maybe<CourseModuleNode>;
  requiredModule?: Maybe<CourseModuleNode>;
  routable: Scalars['Boolean'];
  routableExact: Scalars['Boolean'];
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** If true only staff user can test module (not locked) */
  testingMode: Scalars['Boolean'];
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete: Scalars['Boolean'];
  /** Exclude module in participation check completed */
  disableCheckComplete: Scalars['Boolean'];
  /** Display complete/continue button in module */
  displayCompleteButton: Scalars['Boolean'];
  /** send email when module is completed */
  sendEmailWhenCompleted: Scalars['Boolean'];
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug: Scalars['String'];
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  /** Force module locked for all participants */
  forceLocked: Scalars['Boolean'];
  accessMode: CourseModuleAccessMode;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy: CourseModulePrivacy;
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  coursemodulePtr: CourseModuleNode;
  url?: Maybe<Scalars['String']>;
  iframeHeight?: Maybe<Scalars['Int']>;
  iframeWidth?: Maybe<Scalars['Int']>;
  external: Scalars['Boolean'];
  participationDataInUrlParams: Scalars['Boolean'];
  userModuleAssessment?: Maybe<CourseModuleAssessmentUnion>;
  submodules?: Maybe<CourseModuleConnection>;
  videowall?: Maybe<VideowallModuleNode>;
  frontendUrl?: Maybe<Scalars['String']>;
  authors?: Maybe<AuthorModuleConnection>;
  reminders?: Maybe<ReminderNodeConnection>;
  /** The total live count objects in this query. */
  assessmentsCounter?: Maybe<Scalars['Int']>;
  urlWithParams?: Maybe<Scalars['String']>;
  getAccessModeDisplay?: Maybe<Scalars['String']>;
  getPrivacyDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<IframeModulePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type IframeModuleNodeUserModuleAssessmentArgs = {
  userId?: Maybe<Scalars['ID']>;
};

export type IframeModuleNodeSubmodulesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type IframeModuleNodeAuthorsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type IframeModuleNodeRemindersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type IframeModulePermission = {
  __typename?: 'IframeModulePermission';
  /** Can add iframe module */
  addIframemodule?: Maybe<Scalars['Boolean']>;
  /** Can change iframe module */
  changeIframemodule?: Maybe<Scalars['Boolean']>;
  /** Can delete iframe module */
  deleteIframemodule?: Maybe<Scalars['Boolean']>;
  /** Can view iframe module */
  viewIframemodule?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type IframeModuleUpdateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  url?: Maybe<Scalars['String']>;
  iframeHeight?: Maybe<Scalars['Int']>;
  iframeWidth?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  external?: Maybe<Scalars['Boolean']>;
  participationDataInUrlParams?: Maybe<Scalars['Boolean']>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type IframeModuleUpdateMutationPayload = {
  __typename?: 'IframeModuleUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  iframeModule?: Maybe<IframeModuleNode>;
};

/** Represents an Image field */
export type ImageFiledType = {
  __typename?: 'ImageFiledType';
  /** name */
  name?: Maybe<Scalars['String']>;
  /** file name */
  fileName?: Maybe<Scalars['String']>;
  /** url */
  url?: Maybe<Scalars['String']>;
  /** extension */
  extension?: Maybe<Scalars['String']>;
  /** Size */
  size?: Maybe<Scalars['Int']>;
  /** thumbnail */
  thumbnail?: Maybe<ThumbnailType>;
};

/** Represents an Image field */
export type ImageFiledTypeThumbnailArgs = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  quality?: Maybe<Scalars['Int']>;
  crop?: Maybe<Scalars['String']>;
  upscale?: Maybe<Scalars['Boolean']>;
  progressive?: Maybe<Scalars['Boolean']>;
  orientation?: Maybe<Scalars['Boolean']>;
  padding?: Maybe<Scalars['Boolean']>;
  paddingColor?: Maybe<Scalars['String']>;
  format?: Maybe<ThumbnailFormat>;
  colorspace?: Maybe<ThumbnailColorspace>;
};

export type ImageNode = Node & {
  __typename?: 'ImageNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  title: Scalars['String'];
  createdAt: Scalars['DateTime'];
  uploadedByUser?: Maybe<UserNode>;
  fileSize?: Maybe<Scalars['Int']>;
  file?: Maybe<ImageFiledType>;
  width: Scalars['Int'];
  height: Scalars['Int'];
  focalPointX?: Maybe<Scalars['Int']>;
  focalPointY?: Maybe<Scalars['Int']>;
  focalPointWidth?: Maybe<Scalars['Int']>;
  focalPointHeight?: Maybe<Scalars['Int']>;
  mosaicoSet: MosaicoNodeConnection;
  userHasPermission?: Maybe<ImagePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type ImageNodeMosaicoSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ImageNodeConnection = {
  __typename?: 'ImageNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ImageNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ImageNode` and its cursor. */
export type ImageNodeEdge = {
  __typename?: 'ImageNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ImageNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ImagePermission = {
  __typename?: 'ImagePermission';
  /** Can add image */
  addImage?: Maybe<Scalars['Boolean']>;
  /** Can change image */
  changeImage?: Maybe<Scalars['Boolean']>;
  /** Can delete image */
  deleteImage?: Maybe<Scalars['Boolean']>;
  /** Can view image */
  viewImage?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type ImportResultNode = {
  __typename?: 'ImportResultNode';
  importCount?: Maybe<Scalars['Int']>;
  errorCount?: Maybe<Scalars['Int']>;
  updateCount?: Maybe<Scalars['Int']>;
  errorEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LayoutModuleCreateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  type?: Maybe<LayoutModuleType>;
  coursemodulePtr?: Maybe<Scalars['ID']>;
  /** The ID of the object. */
  id?: Maybe<Scalars['ID']>;
  course: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LayoutModuleCreateMutationPayload = {
  __typename?: 'LayoutModuleCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  layoutModule?: Maybe<LayoutModuleNode>;
};

export type LayoutModuleNode = Node & {
  __typename?: 'LayoutModuleNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  order: Scalars['Int'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  course: CourseNode;
  parent?: Maybe<CourseModuleNode>;
  requiredModule?: Maybe<CourseModuleNode>;
  routable: Scalars['Boolean'];
  routableExact: Scalars['Boolean'];
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** If true only staff user can test module (not locked) */
  testingMode: Scalars['Boolean'];
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete: Scalars['Boolean'];
  /** Exclude module in participation check completed */
  disableCheckComplete: Scalars['Boolean'];
  /** Display complete/continue button in module */
  displayCompleteButton: Scalars['Boolean'];
  /** send email when module is completed */
  sendEmailWhenCompleted: Scalars['Boolean'];
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug: Scalars['String'];
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  /** Force module locked for all participants */
  forceLocked: Scalars['Boolean'];
  accessMode: CourseModuleAccessMode;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy: CourseModulePrivacy;
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  coursemodulePtr: CourseModuleNode;
  type?: Maybe<LayoutModuleType>;
  userModuleAssessment?: Maybe<CourseModuleAssessmentUnion>;
  submodules?: Maybe<CourseModuleConnection>;
  videowall?: Maybe<VideowallModuleNode>;
  frontendUrl?: Maybe<Scalars['String']>;
  authors?: Maybe<AuthorModuleConnection>;
  reminders?: Maybe<ReminderNodeConnection>;
  /** The total live count objects in this query. */
  assessmentsCounter?: Maybe<Scalars['Int']>;
  livePoll?: Maybe<SurveyModuleNode>;
  getAccessModeDisplay?: Maybe<Scalars['String']>;
  getPrivacyDisplay?: Maybe<Scalars['String']>;
  getTypeDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<LayoutModulePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type LayoutModuleNodeUserModuleAssessmentArgs = {
  userId?: Maybe<Scalars['ID']>;
};

export type LayoutModuleNodeSubmodulesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LayoutModuleNodeAuthorsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LayoutModuleNodeRemindersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LayoutModulePermission = {
  __typename?: 'LayoutModulePermission';
  /** Can add layout module */
  addLayoutmodule?: Maybe<Scalars['Boolean']>;
  /** Can change layout module */
  changeLayoutmodule?: Maybe<Scalars['Boolean']>;
  /** Can delete layout module */
  deleteLayoutmodule?: Maybe<Scalars['Boolean']>;
  /** Can view layout module */
  viewLayoutmodule?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum LayoutModuleType {
  /** Accordion */
  ACCORDION = 'ACCORDION',
  /** Stepper */
  STEPPER = 'STEPPER',
  /** Router */
  ROUTER = 'ROUTER',
}

export type LayoutModuleUpdateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  type?: Maybe<LayoutModuleType>;
  /** The ID of the object. */
  id: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LayoutModuleUpdateMutationPayload = {
  __typename?: 'LayoutModuleUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  layoutModule?: Maybe<LayoutModuleNode>;
};

export type LogInInput = {
  /** anoymousUserIdToken */
  anoymousUserIdToken?: Maybe<Scalars['String']>;
  /** accessToken */
  accessToken?: Maybe<Scalars['String']>;
  /** idToken */
  idToken: Scalars['String'];
  /** isNewUser */
  isNewUser?: Maybe<Scalars['Boolean']>;
  /** providerId */
  providerId: Scalars['String'];
  /** currentUser */
  currentUser: Scalars['JSONString'];
  /** profile */
  profile?: Maybe<Scalars['JSONString']>;
  /** Redirect url */
  redirectUrl?: Maybe<Scalars['String']>;
  /** Login with code verification */
  codeVerificationMode?: Maybe<Scalars['Boolean']>;
  /** Invitation id */
  invitationId?: Maybe<Scalars['String']>;
};

export type LogInMutationSuccess = {
  __typename?: 'LogInMutationSuccess';
  /** List of errors that occurred executing the mutation. */
  errors?: Maybe<Array<Error>>;
  user: UserNode;
};

/** Logout */
export type LogoutMutation = {
  __typename?: 'LogoutMutation';
  /** List of errors that occurred executing the mutation. */
  errors?: Maybe<Array<Error>>;
  message?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type MatchInvitationType = {
  __typename?: 'MatchInvitationType';
  match?: Maybe<Scalars['Boolean']>;
  autoCreateParticipation?: Maybe<Scalars['Boolean']>;
  hideEmail?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type MetaNode = {
  __typename?: 'MetaNode';
  remoteAddress?: Maybe<Scalars['String']>;
  realIp?: Maybe<Scalars['String']>;
  serverName?: Maybe<Scalars['String']>;
  json?: Maybe<Scalars['JSONString']>;
};

export type MetaTagAttributeNode = {
  __typename?: 'MetaTagAttributeNode';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MetaTagNode = {
  __typename?: 'MetaTagNode';
  tag?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<Maybe<MetaTagAttributeNode>>>;
};

export type MeUpdateMutationInput = {
  /** preferences */
  preferences?: Maybe<MyPreferenceUpdateMutationInput>;
  /** profile */
  profile?: Maybe<MyProfileUpdateMutationInput>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  dateDeactivation?: Maybe<Scalars['DateTime']>;
  dateJoined?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id?: Maybe<Scalars['ID']>;
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specific permissions for this user. */
  userPermissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of company user invitations */
  companyuserinvitationSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of files */
  fileSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of images */
  imageSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course participations */
  managerParticipations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of presences */
  presenceSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of sent notifications */
  sentnotificationSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of SurveyResponses */
  surveyresponseSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MeUpdateMutationPayload = {
  __typename?: 'MeUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserNode>;
};

export type MonthGroupType = {
  __typename?: 'MonthGroupType';
  month?: Maybe<Scalars['DateTime']>;
  count?: Maybe<Scalars['Int']>;
};

export type MosaicoCredentialNode = {
  __typename?: 'MosaicoCredentialNode';
  type?: Maybe<Scalars['String']>;
  apiKey?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type MosaicoNode = Node & {
  __typename?: 'MosaicoNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  plannedStartDate?: Maybe<Scalars['DateTime']>;
  plannedEndDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  sites: SiteNodeConnection;
  cameraPlaceholders: ImageNodeConnection;
  courseSet: CourseNodeConnection;
  embedCode?: Maybe<Scalars['String']>;
  credential?: Maybe<MosaicoCredentialNode>;
  userHasPermission?: Maybe<MosaicoPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type MosaicoNodeSitesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MosaicoNodeCameraPlaceholdersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type MosaicoNodeCourseSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type MosaicoNodeConnection = {
  __typename?: 'MosaicoNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MosaicoNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `MosaicoNode` and its cursor. */
export type MosaicoNodeEdge = {
  __typename?: 'MosaicoNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<MosaicoNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type MosaicoPermission = {
  __typename?: 'MosaicoPermission';
  /** Can add mosaico */
  addMosaico?: Maybe<Scalars['Boolean']>;
  /** Can change mosaico */
  changeMosaico?: Maybe<Scalars['Boolean']>;
  /** Can delete mosaico */
  deleteMosaico?: Maybe<Scalars['Boolean']>;
  /** Can view mosaico */
  viewMosaico?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  zoomMeetingCreate?: Maybe<ZoomMeetingCreateMutationPayload>;
  zoomMeetingUpdate?: Maybe<ZoomMeetingUpdateMutationPayload>;
  zoomMeetingDelete?: Maybe<ZoomMeetingDeleteMutationPayload>;
  zoomMeetingAuthorize?: Maybe<ZoomMeetingAuthorizeMutationPayload>;
  themeCreate?: Maybe<ThemeCreateMutationPayload>;
  themeUpdate?: Maybe<ThemeUpdateMutationPayload>;
  themeDelete?: Maybe<ThemeDeleteMutationPayload>;
  certificateTemplateCreate?: Maybe<CertificateTemplateCreateMutationPayload>;
  certificateTemplateUpdate?: Maybe<CertificateTemplateUpdateMutationPayload>;
  courseModuleCertificateMapsCreate?: Maybe<CourseModuleCertificateMapsCreateMutationPayload>;
  courseModuleCertificateMapsUpdate?: Maybe<CourseModuleCertificateMapsUpdateMutationPayload>;
  courseModuleCertificateMapsDelete?: Maybe<CourseModuleCertificateMapsDeleteMutationPayload>;
  courseCertificateMapsCreate?: Maybe<CourseCertificateMapsCreateMutationPayload>;
  courseCertificateMapsUpdate?: Maybe<CourseCertificateMapsUpdateMutationPayload>;
  courseCertificateMapsDelete?: Maybe<CourseCertificateMapsDeleteMutationPayload>;
  dbtemplateCreate?: Maybe<DbtemplateCreateMutationPayload>;
  dbtemplateUpdate?: Maybe<DbtemplateUpdateMutationPayload>;
  sendChannelMessage?: Maybe<SendChannelMessagePayload>;
  courseParticipationCreate?: Maybe<CourseParticipationCreateMutationPayload>;
  courseParticipationUpdate?: Maybe<CourseParticipationUpdateMutationPayload>;
  courseParticipationDelete?: Maybe<CourseParticipationDeleteMutationPayload>;
  courseCreate?: Maybe<CourseCreateMutationPayload>;
  courseCreateFromTemplate?: Maybe<CourseCreateFromTemplateMutationPayload>;
  courseClone?: Maybe<CourseCloneMutationPayload>;
  courseUpdate?: Maybe<CourseUpdateMutationPayload>;
  userModuleAssessmentStart?: Maybe<UserModuleAssessmentStartPayload>;
  userModuleAssessmentCreate?: Maybe<UserModuleAssessmentCreatePayload>;
  userModuleAssessmentSetCompleted?: Maybe<UserModuleAssessmentSetCompletedPayload>;
  userModuleAssessmentSurveyCreateNewResponse?: Maybe<UserModuleAssessmentSurveyCreateNewResponsePayload>;
  courseModuleUpdate?: Maybe<CourseModuleUpdateMutationPayload>;
  courseModuleCreate?: Maybe<CourseModuleCreateMutationPayload>;
  courseModuleDelete?: Maybe<CourseModuleDeleteMutationPayload>;
  externalWebinarModuleUpdate?: Maybe<ExternalWebinarModuleUpdateMutationPayload>;
  externalWebinarModuleCreate?: Maybe<ExternalWebinarModuleCreateMutationPayload>;
  externalWebinarModuleChangeStatus?: Maybe<ExternalWebinarModuleChangeStatusMutationPayload>;
  videoModuleUpdate?: Maybe<VideoModuleUpdateMutationPayload>;
  videoModuleCreate?: Maybe<VideoModuleCreateMutationPayload>;
  videoModuleUserActions?: Maybe<VideoModuleUserActionsMutationPayload>;
  videoModuleAssessmentUpdate?: Maybe<UserVideoModuleAssessmentUpdateMutationPayload>;
  layoutModuleUpdate?: Maybe<LayoutModuleUpdateMutationPayload>;
  layoutModuleCreate?: Maybe<LayoutModuleCreateMutationPayload>;
  surveyModuleCreate?: Maybe<SurveyModuleCreateMutationPayload>;
  surveyModuleUpdate?: Maybe<SurveyModuleUpdateMutationPayload>;
  iframeModuleCreate?: Maybe<IframeModuleCreateMutationPayload>;
  iframeModuleUpdate?: Maybe<IframeModuleUpdateMutationPayload>;
  documentModuleCreate?: Maybe<DocumentModuleCreateMutationPayload>;
  documentModuleUpdate?: Maybe<DocumentModuleUpdateMutationPayload>;
  chatModuleCreate?: Maybe<ChatModuleCreateMutationPayload>;
  chatModuleUpdate?: Maybe<ChatModuleUpdateMutationPayload>;
  authorCreate?: Maybe<AuthorCreateMutationPayload>;
  authorUpdate?: Maybe<AuthorUpdateMutationPayload>;
  categoryCreate?: Maybe<CategoryCreateMutationPayload>;
  categoryUpdate?: Maybe<CategoryUpdateMutationPayload>;
  categoryDelete?: Maybe<CategoryDeleteMutationPayload>;
  reminderCreate?: Maybe<ReminderCreateMutationPayload>;
  reminderUpdate?: Maybe<ReminderUpdateMutationPayload>;
  reminderDelete?: Maybe<ReminderDeleteMutationPayload>;
  reminderTest?: Maybe<ReminderTestMutationPayload>;
  certificateEmailTest?: Maybe<CertificateEmailTestMutationPayload>;
  authorsGroupCreate?: Maybe<AuthorsGroupCreateMutationPayload>;
  authorsGroupUpdate?: Maybe<AuthorsGroupUpdateMutationPayload>;
  coursePartecipationManageStatus?: Maybe<CoursePartecipationManageStatusMutationPayload>;
  coursePartecipationResendConfirmEmail?: Maybe<CoursePartecipationResendConfirmEmailPayload>;
  courseParticipationImportFileCreate?: Maybe<CourseParticipationImportFileCreateMutationPayload>;
  courseParticipationImportFileUpdate?: Maybe<CourseParticipationImportFileUpdateMutationPayload>;
  courseParticipationApplyImport?: Maybe<CourseParticipationApplyImportMutationPayload>;
  courseInvitationCreate?: Maybe<CourseInvitationCreateMutationPayload>;
  courseInvitationUpdate?: Maybe<CourseInvitationUpdateMutationPayload>;
  courseInvitationDelete?: Maybe<CourseInvitationDeleteMutationPayload>;
  courseInvitationSendEmail?: Maybe<CourseInvitationSendEmailMutationPayload>;
  courseInvitationPublicResendEmail?: Maybe<CourseInvitationPublicResendEmailMutationPayload>;
  courseInvitationPublicSearchAndSendByEmail?: Maybe<CourseInvitationSearchAndSendByEmailPayload>;
  courseSendAllInvitations?: Maybe<CourseSendAllInvitationsPayload>;
  courseDeleteAllPossibleInvitations?: Maybe<CourseDeleteAllPossibleInvitationsPayload>;
  createDemoCourse?: Maybe<CreateDemoCoursePayload>;
  userModuleAssessmentGateCheck?: Maybe<UserModuleAssessmentGateCheckPayload>;
  webinarModuleClearTimeIntervals?: Maybe<WebinarModuleClearTimeIntervalsPayload>;
  courseUserParticipationsQuotaCreate?: Maybe<CourseUserParticipationsQuotaCreateMutationPayload>;
  courseUserParticipationsQuotaUpdate?: Maybe<CourseUserParticipationsQuotaUpdateMutationPayload>;
  courseUserParticipationsQuotaDelete?: Maybe<CourseUserParticipationsQuotaDeleteMutationPayload>;
  companyCreate?: Maybe<CompanyCreateMutationPayload>;
  companyUpdate?: Maybe<CompanyUpdateMutationPayload>;
  companyDelete?: Maybe<CompanyDeleteMutationPayload>;
  companyUserInvitationCreate?: Maybe<CompanyUserInvitationCreateMutationPayload>;
  companyUserInvitationDelete?: Maybe<CompanyUserInvitationDeleteMutationPayload>;
  companyUserInvitationAccept?: Maybe<CompanyUserInvitationAcceptMutationPayload>;
  companyUserInvitationDecline?: Maybe<CompanyUserInvitationDeclineMutationPayload>;
  companyUserInvitationRevoke?: Maybe<CompanyUserInvitationRevokeMutationPayload>;
  companyUserInvitationConnectUser?: Maybe<CompanyUserInvitationConnectMutationPayload>;
  /** update company billing */
  companyUpdateBilling?: Maybe<UpdateBillingMutation>;
  companyThemeUpdate?: Maybe<CompanyThemeCustomizationUpdateMutationPayload>;
  /** SaveAnswerToTheQuestion */
  saveAnswerToTheQuestion?: Maybe<SaveAnswerToTheQuestion>;
  /** SaveSurveyBulkResponseMutation */
  saveSurveyBulkResponse?: Maybe<SaveSurveyBulkResponse>;
  surveyCreate?: Maybe<SurveyCreateMutationPayload>;
  surveyUpdate?: Maybe<SurveyUpdateMutationPayload>;
  surveyDelete?: Maybe<SurveyDeleteMutationPayload>;
  surveyClone?: Maybe<SurveyCloneMutationPayload>;
  questionCreate?: Maybe<QuestionCreateMutationPayload>;
  questionUpdate?: Maybe<QuestionUpdateMutationPayload>;
  questionDelete?: Maybe<QuestionDeleteMutationPayload>;
  questionClone?: Maybe<QuestionCloneMutationPayload>;
  meUpdate?: Maybe<MeUpdateMutationPayload>;
  userUpdate?: Maybe<UserUpdateMutationPayload>;
  userDelete?: Maybe<UserDeleteMutationPayload>;
  avatarCreate?: Maybe<AvatarCreateMutationPayload>;
  userRequestDeactivation?: Maybe<UserRequestDeactivationMutationPayload>;
  userDeactivate?: Maybe<UserDeactivateMutationPayload>;
  /** ... */
  logIn?: Maybe<LogInMutationSuccess>;
  /** Logout */
  logOut?: Maybe<LogoutMutation>;
  /** Reqest a reset password */
  passwordReset?: Maybe<PasswordResetSuccess>;
  /** Reqest a reset password */
  passwordChange?: Maybe<PasswordChangeSuccess>;
  groupCreate?: Maybe<GroupCreateMutationPayload>;
  groupDelete?: Maybe<GroupDeleteMutationPayload>;
  groupAddUser?: Maybe<GroupAddUserMutationPayload>;
  groupRemoveUser?: Maybe<GroupRemoveUserMutationPayload>;
  permissionChange?: Maybe<PermissionChangePayload>;
  /** Check if verification code match */
  verificationCodeMatch?: Maybe<VerificationCodeMatchSuccess>;
  contact?: Maybe<ContactMutationPayload>;
};

export type MutationZoomMeetingCreateArgs = {
  input: ZoomMeetingCreateMutationInput;
};

export type MutationZoomMeetingUpdateArgs = {
  input: ZoomMeetingUpdateMutationInput;
};

export type MutationZoomMeetingDeleteArgs = {
  input: ZoomMeetingDeleteMutationInput;
};

export type MutationZoomMeetingAuthorizeArgs = {
  input: ZoomMeetingAuthorizeMutationInput;
};

export type MutationThemeCreateArgs = {
  input: ThemeCreateMutationInput;
};

export type MutationThemeUpdateArgs = {
  input: ThemeUpdateMutationInput;
};

export type MutationThemeDeleteArgs = {
  input: ThemeDeleteMutationInput;
};

export type MutationCertificateTemplateCreateArgs = {
  input: CertificateTemplateCreateMutationInput;
};

export type MutationCertificateTemplateUpdateArgs = {
  input: CertificateTemplateUpdateMutationInput;
};

export type MutationCourseModuleCertificateMapsCreateArgs = {
  input: CourseModuleCertificateMapsCreateMutationInput;
};

export type MutationCourseModuleCertificateMapsUpdateArgs = {
  input: CourseModuleCertificateMapsUpdateMutationInput;
};

export type MutationCourseModuleCertificateMapsDeleteArgs = {
  input: CourseModuleCertificateMapsDeleteMutationInput;
};

export type MutationCourseCertificateMapsCreateArgs = {
  input: CourseCertificateMapsCreateMutationInput;
};

export type MutationCourseCertificateMapsUpdateArgs = {
  input: CourseCertificateMapsUpdateMutationInput;
};

export type MutationCourseCertificateMapsDeleteArgs = {
  input: CourseCertificateMapsDeleteMutationInput;
};

export type MutationDbtemplateCreateArgs = {
  input: DbtemplateCreateMutationInput;
};

export type MutationDbtemplateUpdateArgs = {
  input: DbtemplateUpdateMutationInput;
};

export type MutationSendChannelMessageArgs = {
  input: SendChannelMessageInput;
};

export type MutationCourseParticipationCreateArgs = {
  input: CourseParticipationCreateMutationInput;
};

export type MutationCourseParticipationUpdateArgs = {
  input: CourseParticipationUpdateMutationInput;
};

export type MutationCourseParticipationDeleteArgs = {
  input: CourseParticipationDeleteMutationInput;
};

export type MutationCourseCreateArgs = {
  input: CourseCreateMutationInput;
};

export type MutationCourseCreateFromTemplateArgs = {
  input: CourseCreateFromTemplateMutationInput;
};

export type MutationCourseCloneArgs = {
  input: CourseCloneMutationInput;
};

export type MutationCourseUpdateArgs = {
  input: CourseUpdateMutationInput;
};

export type MutationUserModuleAssessmentStartArgs = {
  input: UserModuleAssessmentStartInput;
};

export type MutationUserModuleAssessmentCreateArgs = {
  input: UserModuleAssessmentCreateInput;
};

export type MutationUserModuleAssessmentSetCompletedArgs = {
  input: UserModuleAssessmentSetCompletedInput;
};

export type MutationUserModuleAssessmentSurveyCreateNewResponseArgs = {
  input: UserModuleAssessmentSurveyCreateNewResponseInput;
};

export type MutationCourseModuleUpdateArgs = {
  input: CourseModuleUpdateMutationInput;
};

export type MutationCourseModuleCreateArgs = {
  input: CourseModuleCreateMutationInput;
};

export type MutationCourseModuleDeleteArgs = {
  input: CourseModuleDeleteMutationInput;
};

export type MutationExternalWebinarModuleUpdateArgs = {
  input: ExternalWebinarModuleUpdateMutationInput;
};

export type MutationExternalWebinarModuleCreateArgs = {
  input: ExternalWebinarModuleCreateMutationInput;
};

export type MutationExternalWebinarModuleChangeStatusArgs = {
  input: ExternalWebinarModuleChangeStatusMutationInput;
};

export type MutationVideoModuleUpdateArgs = {
  input: VideoModuleUpdateMutationInput;
};

export type MutationVideoModuleCreateArgs = {
  input: VideoModuleCreateMutationInput;
};

export type MutationVideoModuleUserActionsArgs = {
  input: VideoModuleUserActionsMutationInput;
};

export type MutationVideoModuleAssessmentUpdateArgs = {
  input: UserVideoModuleAssessmentUpdateMutationInput;
};

export type MutationLayoutModuleUpdateArgs = {
  input: LayoutModuleUpdateMutationInput;
};

export type MutationLayoutModuleCreateArgs = {
  input: LayoutModuleCreateMutationInput;
};

export type MutationSurveyModuleCreateArgs = {
  input: SurveyModuleCreateMutationInput;
};

export type MutationSurveyModuleUpdateArgs = {
  input: SurveyModuleUpdateMutationInput;
};

export type MutationIframeModuleCreateArgs = {
  input: IframeModuleCreateMutationInput;
};

export type MutationIframeModuleUpdateArgs = {
  input: IframeModuleUpdateMutationInput;
};

export type MutationDocumentModuleCreateArgs = {
  input: DocumentModuleCreateMutationInput;
};

export type MutationDocumentModuleUpdateArgs = {
  input: DocumentModuleUpdateMutationInput;
};

export type MutationChatModuleCreateArgs = {
  input: ChatModuleCreateMutationInput;
};

export type MutationChatModuleUpdateArgs = {
  input: ChatModuleUpdateMutationInput;
};

export type MutationAuthorCreateArgs = {
  input: AuthorCreateMutationInput;
};

export type MutationAuthorUpdateArgs = {
  input: AuthorUpdateMutationInput;
};

export type MutationCategoryCreateArgs = {
  input: CategoryCreateMutationInput;
};

export type MutationCategoryUpdateArgs = {
  input: CategoryUpdateMutationInput;
};

export type MutationCategoryDeleteArgs = {
  input: CategoryDeleteMutationInput;
};

export type MutationReminderCreateArgs = {
  input: ReminderCreateMutationInput;
};

export type MutationReminderUpdateArgs = {
  input: ReminderUpdateMutationInput;
};

export type MutationReminderDeleteArgs = {
  input: ReminderDeleteMutationInput;
};

export type MutationReminderTestArgs = {
  input: ReminderTestMutationInput;
};

export type MutationCertificateEmailTestArgs = {
  input: CertificateEmailTestMutationInput;
};

export type MutationAuthorsGroupCreateArgs = {
  input: AuthorsGroupCreateMutationInput;
};

export type MutationAuthorsGroupUpdateArgs = {
  input: AuthorsGroupUpdateMutationInput;
};

export type MutationCoursePartecipationManageStatusArgs = {
  input: CoursePartecipationManageStatusMutationInput;
};

export type MutationCoursePartecipationResendConfirmEmailArgs = {
  input: CoursePartecipationResendConfirmEmailInput;
};

export type MutationCourseParticipationImportFileCreateArgs = {
  input: CourseParticipationImportFileCreateMutationInput;
};

export type MutationCourseParticipationImportFileUpdateArgs = {
  input: CourseParticipationImportFileUpdateMutationInput;
};

export type MutationCourseParticipationApplyImportArgs = {
  input: CourseParticipationApplyImportMutationInput;
};

export type MutationCourseInvitationCreateArgs = {
  input: CourseInvitationCreateMutationInput;
};

export type MutationCourseInvitationUpdateArgs = {
  input: CourseInvitationUpdateMutationInput;
};

export type MutationCourseInvitationDeleteArgs = {
  input: CourseInvitationDeleteMutationInput;
};

export type MutationCourseInvitationSendEmailArgs = {
  input: CourseInvitationSendEmailMutationInput;
};

export type MutationCourseInvitationPublicResendEmailArgs = {
  input: CourseInvitationPublicResendEmailMutationInput;
};

export type MutationCourseInvitationPublicSearchAndSendByEmailArgs = {
  input: CourseInvitationSearchAndSendByEmailInput;
};

export type MutationCourseSendAllInvitationsArgs = {
  input: CourseSendAllInvitationsInput;
};

export type MutationCourseDeleteAllPossibleInvitationsArgs = {
  input: CourseDeleteAllPossibleInvitationsInput;
};

export type MutationCreateDemoCourseArgs = {
  input: CreateDemoCourseInput;
};

export type MutationUserModuleAssessmentGateCheckArgs = {
  input: UserModuleAssessmentGateCheckInput;
};

export type MutationWebinarModuleClearTimeIntervalsArgs = {
  input: WebinarModuleClearTimeIntervalsInput;
};

export type MutationCourseUserParticipationsQuotaCreateArgs = {
  input: CourseUserParticipationsQuotaCreateMutationInput;
};

export type MutationCourseUserParticipationsQuotaUpdateArgs = {
  input: CourseUserParticipationsQuotaUpdateMutationInput;
};

export type MutationCourseUserParticipationsQuotaDeleteArgs = {
  input: CourseUserParticipationsQuotaDeleteMutationInput;
};

export type MutationCompanyCreateArgs = {
  input: CompanyCreateMutationInput;
};

export type MutationCompanyUpdateArgs = {
  input: CompanyUpdateMutationInput;
};

export type MutationCompanyDeleteArgs = {
  input: CompanyDeleteMutationInput;
};

export type MutationCompanyUserInvitationCreateArgs = {
  input: CompanyUserInvitationCreateMutationInput;
};

export type MutationCompanyUserInvitationDeleteArgs = {
  input: CompanyUserInvitationDeleteMutationInput;
};

export type MutationCompanyUserInvitationAcceptArgs = {
  input: CompanyUserInvitationAcceptMutationInput;
};

export type MutationCompanyUserInvitationDeclineArgs = {
  input: CompanyUserInvitationDeclineMutationInput;
};

export type MutationCompanyUserInvitationRevokeArgs = {
  input: CompanyUserInvitationRevokeMutationInput;
};

export type MutationCompanyUserInvitationConnectUserArgs = {
  input: CompanyUserInvitationConnectMutationInput;
};

export type MutationCompanyUpdateBillingArgs = {
  input: UpdateBillingDataMutationInput;
};

export type MutationCompanyThemeUpdateArgs = {
  input: CompanyThemeCustomizationUpdateMutationInput;
};

export type MutationSaveAnswerToTheQuestionArgs = {
  input: SaveAnswerToTheQuestionInput;
};

export type MutationSaveSurveyBulkResponseArgs = {
  input: SaveSurveyBulkResponseInput;
};

export type MutationSurveyCreateArgs = {
  input: SurveyCreateMutationInput;
};

export type MutationSurveyUpdateArgs = {
  input: SurveyUpdateMutationInput;
};

export type MutationSurveyDeleteArgs = {
  input: SurveyDeleteMutationInput;
};

export type MutationSurveyCloneArgs = {
  input: SurveyCloneMutationInput;
};

export type MutationQuestionCreateArgs = {
  input: QuestionCreateMutationInput;
};

export type MutationQuestionUpdateArgs = {
  input: QuestionUpdateMutationInput;
};

export type MutationQuestionDeleteArgs = {
  input: QuestionDeleteMutationInput;
};

export type MutationQuestionCloneArgs = {
  input: QuestionCloneMutationInput;
};

export type MutationMeUpdateArgs = {
  input: MeUpdateMutationInput;
};

export type MutationUserUpdateArgs = {
  input: UserUpdateMutationInput;
};

export type MutationUserDeleteArgs = {
  input: UserDeleteMutationInput;
};

export type MutationAvatarCreateArgs = {
  input: AvatarCreateMutationInput;
};

export type MutationUserRequestDeactivationArgs = {
  input: UserRequestDeactivationMutationInput;
};

export type MutationUserDeactivateArgs = {
  input: UserDeactivateMutationInput;
};

export type MutationLogInArgs = {
  input: LogInInput;
};

export type MutationPasswordResetArgs = {
  input: PasswordResetInput;
};

export type MutationPasswordChangeArgs = {
  input: PasswordChangeInput;
};

export type MutationGroupCreateArgs = {
  input: GroupCreateMutationInput;
};

export type MutationGroupDeleteArgs = {
  input: GroupDeleteMutationInput;
};

export type MutationGroupAddUserArgs = {
  input: GroupAddUserMutationInput;
};

export type MutationGroupRemoveUserArgs = {
  input: GroupRemoveUserMutationInput;
};

export type MutationPermissionChangeArgs = {
  input: PermissionChangeInput;
};

export type MutationVerificationCodeMatchArgs = {
  input: VerificationCodeMatchInput;
};

export type MutationContactArgs = {
  input: ContactMutationInput;
};

/** An error that happened in a mutation. */
export type MutationErrorType = {
  __typename?: 'MutationErrorType';
  /** The field that caused the error, or `null` if it isn't associated with any particular field. */
  field?: Maybe<Scalars['String']>;
  /** The error message. */
  message?: Maybe<Scalars['String']>;
};

export type MyPreferenceUpdateMutationInput = {
  timezone?: Maybe<PreferencesTimezone>;
  language?: Maybe<PreferencesLanguage>;
  user?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MyProfileUpdateMutationInput = {
  birthPlace?: Maybe<Scalars['String']>;
  gender?: Maybe<ProfileGender>;
  birthDay?: Maybe<Scalars['Date']>;
  user?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type NoteNode = Node & {
  __typename?: 'NoteNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  objectId: Scalars['Int'];
  text: Scalars['String'];
  userHasPermission?: Maybe<NotePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type NoteNodeConnection = {
  __typename?: 'NoteNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NoteNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `NoteNode` and its cursor. */
export type NoteNodeEdge = {
  __typename?: 'NoteNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<NoteNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type NotePermission = {
  __typename?: 'NotePermission';
  /** Can add Note */
  addNote?: Maybe<Scalars['Boolean']>;
  /** Can change Note */
  changeNote?: Maybe<Scalars['Boolean']>;
  /** Can delete Note */
  deleteNote?: Maybe<Scalars['Boolean']>;
  /** Can view Note */
  viewNote?: Maybe<Scalars['Boolean']>;
  /** Can read the this object's info. */
  canRead?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type OnCourseInvitationUpdate = {
  __typename?: 'OnCourseInvitationUpdate';
  invitation?: Maybe<CourseInvitationNode>;
  created?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export type OnCourseModuleUpdate = {
  __typename?: 'OnCourseModuleUpdate';
  courseModule?: Maybe<CourseModuleUnion>;
};

export type OnCourseParticipationUpdate = {
  __typename?: 'OnCourseParticipationUpdate';
  participation?: Maybe<CourseParticipationNode>;
  deleted?: Maybe<Scalars['Boolean']>;
};

export type OnLoginSubscription = {
  __typename?: 'OnLoginSubscription';
  user?: Maybe<UserNode>;
};

export type OnLogoutSubscription = {
  __typename?: 'OnLogoutSubscription';
  user?: Maybe<UserNode>;
};

export type OnQuestionUpdate = {
  __typename?: 'OnQuestionUpdate';
  question?: Maybe<QuestionNode>;
};

export type OnRoomPresenceMessage = {
  __typename?: 'OnRoomPresenceMessage';
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<UserNode>;
};

export type OnRoomPresenceTrack = {
  __typename?: 'OnRoomPresenceTrack';
  registered?: Maybe<Scalars['Boolean']>;
};

export type OnRoomPresenceUpdate = {
  __typename?: 'OnRoomPresenceUpdate';
  room?: Maybe<RoomNode>;
  roomSummary?: Maybe<SummaryRoomNode>;
  userAdded?: Maybe<UserNode>;
  userRemoved?: Maybe<UserNode>;
  bulkChange?: Maybe<Scalars['Boolean']>;
};

export type OnThemeUpdate = {
  __typename?: 'OnThemeUpdate';
  created?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
  theme?: Maybe<ThemeNode>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type PasswordChangeInput = {
  /** password */
  password: Scalars['String'];
  /** password check */
  password1: Scalars['String'];
};

export type PasswordChangeSuccess = {
  __typename?: 'PasswordChangeSuccess';
  /** List of errors that occurred executing the mutation. */
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
};

export type PasswordResetInput = {
  /** email */
  email: Scalars['String'];
};

export type PasswordResetSuccess = {
  __typename?: 'PasswordResetSuccess';
  /** List of errors that occurred executing the mutation. */
  errors?: Maybe<Array<Error>>;
  sended: Scalars['Boolean'];
  email: Scalars['String'];
};

export type PeriodicTaskPermission = {
  __typename?: 'PeriodicTaskPermission';
  /** Can add periodic task */
  addPeriodictask?: Maybe<Scalars['Boolean']>;
  /** Can change periodic task */
  changePeriodictask?: Maybe<Scalars['Boolean']>;
  /** Can delete periodic task */
  deletePeriodictask?: Maybe<Scalars['Boolean']>;
  /** Can view periodic task */
  viewPeriodictask?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type PermissionChangeInput = {
  userOrGroup: Scalars['ID'];
  instance: Scalars['ID'];
  permissionCodename: Scalars['String'];
  permissionValue: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PermissionChangePayload = {
  __typename?: 'PermissionChangePayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  /** Instance Permission */
  genericInstancePermissions?: Maybe<GenericInstancePermission>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PermissionNode = Node & {
  __typename?: 'PermissionNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  codename: Scalars['String'];
  groupSet: GroupNodeConnection;
  /** Specific permissions for this user. */
  userSet: UserNodeConnection;
  userHasPermission?: Maybe<PermissionPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type PermissionNodeGroupSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PermissionNodeUserSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_Icontains?: Maybe<Scalars['String']>;
  email_Istartswith?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_Icontains?: Maybe<Scalars['String']>;
  firstName_Istartswith?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_Icontains?: Maybe<Scalars['String']>;
  lastName_Istartswith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  anonymousUser?: Maybe<Scalars['Boolean']>;
  manageParticipationCourseId?: Maybe<Scalars['String']>;
};

export type PermissionNodeConnection = {
  __typename?: 'PermissionNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PermissionNodeEdge>>;
};

/** A Relay edge containing a `PermissionNode` and its cursor. */
export type PermissionNodeEdge = {
  __typename?: 'PermissionNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PermissionNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PermissionPermission = {
  __typename?: 'PermissionPermission';
  /** Can add permission */
  addPermission?: Maybe<Scalars['Boolean']>;
  /** Can change permission */
  changePermission?: Maybe<Scalars['Boolean']>;
  /** Can delete permission */
  deletePermission?: Maybe<Scalars['Boolean']>;
  /** Can view permission */
  viewPermission?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum PreferencesLanguage {
  /** Afrikaans */
  AF = 'AF',
  /** العربيّة */
  AR = 'AR',
  /** asturian */
  AST = 'AST',
  /** Azərbaycanca */
  AZ = 'AZ',
  /** български */
  BG = 'BG',
  /** беларуская */
  BE = 'BE',
  /** বাংলা */
  BN = 'BN',
  /** Brezhoneg */
  BR = 'BR',
  /** bosanski */
  BS = 'BS',
  /** català */
  CA = 'CA',
  /** česky */
  CS = 'CS',
  /** Cymraeg */
  CY = 'CY',
  /** dansk */
  DA = 'DA',
  /** Deutsch */
  DE = 'DE',
  /** Ελληνικά */
  EL = 'EL',
  /** English */
  EN = 'EN',
  /** Australian English */
  EN_AU = 'EN_AU',
  /** British English */
  EN_GB = 'EN_GB',
  /** Esperanto */
  EO = 'EO',
  /** español */
  ES = 'ES',
  /** español de Argentina */
  ES_AR = 'ES_AR',
  /** español de Mexico */
  ES_MX = 'ES_MX',
  /** español de Nicaragua */
  ES_NI = 'ES_NI',
  /** español de Venezuela */
  ES_VE = 'ES_VE',
  /** eesti */
  ET = 'ET',
  /** Basque */
  EU = 'EU',
  /** فارسی */
  FA = 'FA',
  /** suomi */
  FI = 'FI',
  /** français */
  FR = 'FR',
  /** frysk */
  FY = 'FY',
  /** Gaeilge */
  GA = 'GA',
  /** galego */
  GL = 'GL',
  /** עברית */
  HE = 'HE',
  /** Hindi */
  HI = 'HI',
  /** Hrvatski */
  HR = 'HR',
  /** Magyar */
  HU = 'HU',
  /** Interlingua */
  IA = 'IA',
  /** Bahasa Indonesia */
  ID = 'ID',
  /** ido */
  IO = 'IO',
  /** Íslenska */
  IS = 'IS',
  /** Italiano */
  IT = 'IT',
  /** 日本語 */
  JA = 'JA',
  /** ქართული */
  KA = 'KA',
  /** Қазақ */
  KK = 'KK',
  /** Khmer */
  KM = 'KM',
  /** Kannada */
  KN = 'KN',
  /** 한국어 */
  KO = 'KO',
  /** Lëtzebuergesch */
  LB = 'LB',
  /** Lietuviškai */
  LT = 'LT',
  /** latvieš */
  LV = 'LV',
  /** Македонски */
  MK = 'MK',
  /** Malayalam */
  ML = 'ML',
  /** Mongolian */
  MN = 'MN',
  /** मराठी */
  MR = 'MR',
  /** မြန်မာဘာသာ */
  MY = 'MY',
  /** norsk (bokmål) */
  NB = 'NB',
  /** नेपाली */
  NE = 'NE',
  /** Nederlands */
  NL = 'NL',
  /** norsk (nynorsk) */
  NN = 'NN',
  /** Ирон */
  OS = 'OS',
  /** Punjabi */
  PA = 'PA',
  /** polski */
  PL = 'PL',
  /** Português */
  PT = 'PT',
  /** Português Brasileiro */
  PT_BR = 'PT_BR',
  /** Română */
  RO = 'RO',
  /** Русский */
  RU = 'RU',
  /** slovenský */
  SK = 'SK',
  /** Slovenščina */
  SL = 'SL',
  /** shqip */
  SQ = 'SQ',
  /** српски */
  SR = 'SR',
  /** srpski (latinica) */
  SR_LATN = 'SR_LATN',
  /** svenska */
  SV = 'SV',
  /** Kiswahili */
  SW = 'SW',
  /** தமிழ் */
  TA = 'TA',
  /** తెలుగు */
  TE = 'TE',
  /** ภาษาไทย */
  TH = 'TH',
  /** Türkçe */
  TR = 'TR',
  /** Татарча */
  TT = 'TT',
  /** Удмурт */
  UDM = 'UDM',
  /** Українська */
  UK = 'UK',
  /** اردو */
  UR = 'UR',
  /** Tiếng Việt */
  VI = 'VI',
  /** 简体中文 */
  ZH_CN = 'ZH_CN',
  /** 简体中文 */
  ZH_HANS = 'ZH_HANS',
  /** 繁體中文 */
  ZH_HANT = 'ZH_HANT',
  /** 繁體中文 */
  ZH_TW = 'ZH_TW',
}

export type PreferencesNode = Node & {
  __typename?: 'PreferencesNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  user: UserNode;
  timezone?: Maybe<PreferencesTimezone>;
  language: PreferencesLanguage;
  getTimezoneDisplay?: Maybe<Scalars['String']>;
  getLanguageDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<PreferencesPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type PreferencesPermission = {
  __typename?: 'PreferencesPermission';
  /** Can add preferences */
  addPreferences?: Maybe<Scalars['Boolean']>;
  /** Can change preferences */
  changePreferences?: Maybe<Scalars['Boolean']>;
  /** Can delete preferences */
  deletePreferences?: Maybe<Scalars['Boolean']>;
  /** Can view preferences */
  viewPreferences?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum PreferencesTimezone {
  /** Afrikaans */
  AF = 'AF',
  /** العربيّة */
  AR = 'AR',
  /** asturian */
  AST = 'AST',
  /** Azərbaycanca */
  AZ = 'AZ',
  /** български */
  BG = 'BG',
  /** беларуская */
  BE = 'BE',
  /** বাংলা */
  BN = 'BN',
  /** Brezhoneg */
  BR = 'BR',
  /** bosanski */
  BS = 'BS',
  /** català */
  CA = 'CA',
  /** česky */
  CS = 'CS',
  /** Cymraeg */
  CY = 'CY',
  /** dansk */
  DA = 'DA',
  /** Deutsch */
  DE = 'DE',
  /** Ελληνικά */
  EL = 'EL',
  /** English */
  EN = 'EN',
  /** Australian English */
  EN_AU = 'EN_AU',
  /** British English */
  EN_GB = 'EN_GB',
  /** Esperanto */
  EO = 'EO',
  /** español */
  ES = 'ES',
  /** español de Argentina */
  ES_AR = 'ES_AR',
  /** español de Mexico */
  ES_MX = 'ES_MX',
  /** español de Nicaragua */
  ES_NI = 'ES_NI',
  /** español de Venezuela */
  ES_VE = 'ES_VE',
  /** eesti */
  ET = 'ET',
  /** Basque */
  EU = 'EU',
  /** فارسی */
  FA = 'FA',
  /** suomi */
  FI = 'FI',
  /** français */
  FR = 'FR',
  /** frysk */
  FY = 'FY',
  /** Gaeilge */
  GA = 'GA',
  /** galego */
  GL = 'GL',
  /** עברית */
  HE = 'HE',
  /** Hindi */
  HI = 'HI',
  /** Hrvatski */
  HR = 'HR',
  /** Magyar */
  HU = 'HU',
  /** Interlingua */
  IA = 'IA',
  /** Bahasa Indonesia */
  ID = 'ID',
  /** ido */
  IO = 'IO',
  /** Íslenska */
  IS = 'IS',
  /** Italiano */
  IT = 'IT',
  /** 日本語 */
  JA = 'JA',
  /** ქართული */
  KA = 'KA',
  /** Қазақ */
  KK = 'KK',
  /** Khmer */
  KM = 'KM',
  /** Kannada */
  KN = 'KN',
  /** 한국어 */
  KO = 'KO',
  /** Lëtzebuergesch */
  LB = 'LB',
  /** Lietuviškai */
  LT = 'LT',
  /** latvieš */
  LV = 'LV',
  /** Македонски */
  MK = 'MK',
  /** Malayalam */
  ML = 'ML',
  /** Mongolian */
  MN = 'MN',
  /** मराठी */
  MR = 'MR',
  /** မြန်မာဘာသာ */
  MY = 'MY',
  /** norsk (bokmål) */
  NB = 'NB',
  /** नेपाली */
  NE = 'NE',
  /** Nederlands */
  NL = 'NL',
  /** norsk (nynorsk) */
  NN = 'NN',
  /** Ирон */
  OS = 'OS',
  /** Punjabi */
  PA = 'PA',
  /** polski */
  PL = 'PL',
  /** Português */
  PT = 'PT',
  /** Português Brasileiro */
  PT_BR = 'PT_BR',
  /** Română */
  RO = 'RO',
  /** Русский */
  RU = 'RU',
  /** slovenský */
  SK = 'SK',
  /** Slovenščina */
  SL = 'SL',
  /** shqip */
  SQ = 'SQ',
  /** српски */
  SR = 'SR',
  /** srpski (latinica) */
  SR_LATN = 'SR_LATN',
  /** svenska */
  SV = 'SV',
  /** Kiswahili */
  SW = 'SW',
  /** தமிழ் */
  TA = 'TA',
  /** తెలుగు */
  TE = 'TE',
  /** ภาษาไทย */
  TH = 'TH',
  /** Türkçe */
  TR = 'TR',
  /** Татарча */
  TT = 'TT',
  /** Удмурт */
  UDM = 'UDM',
  /** Українська */
  UK = 'UK',
  /** اردو */
  UR = 'UR',
  /** Tiếng Việt */
  VI = 'VI',
  /** 简体中文 */
  ZH_CN = 'ZH_CN',
  /** 简体中文 */
  ZH_HANS = 'ZH_HANS',
  /** 繁體中文 */
  ZH_HANT = 'ZH_HANT',
  /** 繁體中文 */
  ZH_TW = 'ZH_TW',
}

export type PresenceNode = Node & {
  __typename?: 'PresenceNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  room: RoomNode;
  /** Reply channel for connection that is present */
  channelName: Scalars['String'];
  user?: Maybe<UserNode>;
  firstSeen: Scalars['DateTime'];
  lastSeen: Scalars['DateTime'];
  onLine: Scalars['Boolean'];
  userHasPermission?: Maybe<PresencePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type PresenceNodeConnection = {
  __typename?: 'PresenceNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PresenceNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PresenceNode` and its cursor. */
export type PresenceNodeEdge = {
  __typename?: 'PresenceNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PresenceNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PresencePermission = {
  __typename?: 'PresencePermission';
  /** Can add presence */
  addPresence?: Maybe<Scalars['Boolean']>;
  /** Can change presence */
  changePresence?: Maybe<Scalars['Boolean']>;
  /** Can delete presence */
  deletePresence?: Maybe<Scalars['Boolean']>;
  /** Can view presence */
  viewPresence?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum ProfileGender {
  /** Male */
  MALE = 'MALE',
  /** Female */
  FEMALE = 'FEMALE',
}

export type ProfileNode = Node & {
  __typename?: 'ProfileNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  user: UserNode;
  gender?: Maybe<ProfileGender>;
  birthDay?: Maybe<Scalars['Date']>;
  birthPlace?: Maybe<AddressNode>;
  getGenderDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<ProfilePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type ProfileNodeConnection = {
  __typename?: 'ProfileNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProfileNodeEdge>>;
};

/** A Relay edge containing a `ProfileNode` and its cursor. */
export type ProfileNodeEdge = {
  __typename?: 'ProfileNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ProfileNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ProfilePermission = {
  __typename?: 'ProfilePermission';
  /** Can add profile */
  addProfile?: Maybe<Scalars['Boolean']>;
  /** Can change profile */
  changeProfile?: Maybe<Scalars['Boolean']>;
  /** Can delete profile */
  deleteProfile?: Maybe<Scalars['Boolean']>;
  /** Can view profile */
  viewProfile?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type PublicCompanyNode = Node & {
  __typename?: 'PublicCompanyNode';
  name: Scalars['String'];
  slug: Scalars['String'];
  websiteUrl?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  /** The customer's phone number. */
  phone: Scalars['String'];
  enableAuth: Scalars['Boolean'];
  enableLogoLink: Scalars['Boolean'];
  footerJsxContent?: Maybe<Scalars['String']>;
  homeJsxContent?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  theme?: Maybe<CompanyThemeCustomizationNode>;
  address?: Maybe<Address>;
  legalLinks?: Maybe<WebLinkModuleConnection>;
  defaultTheme?: Maybe<ThemeNode>;
  userHasPermission?: Maybe<PublicCompanyPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type PublicCompanyNodeLegalLinksArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PublicCompanyNodeConnection = {
  __typename?: 'PublicCompanyNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PublicCompanyNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PublicCompanyNode` and its cursor. */
export type PublicCompanyNodeEdge = {
  __typename?: 'PublicCompanyNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PublicCompanyNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PublicCompanyPermission = {
  __typename?: 'PublicCompanyPermission';
  /** Can add public company */
  addPubliccompany?: Maybe<Scalars['Boolean']>;
  /** Can change public company */
  changePubliccompany?: Maybe<Scalars['Boolean']>;
  /** Can delete public company */
  deletePubliccompany?: Maybe<Scalars['Boolean']>;
  /** Can view public company */
  viewPubliccompany?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  zoomMeeting?: Maybe<ZoomMeetingNode>;
  theme?: Maybe<ThemeNode>;
  allThemes?: Maybe<ThemeNodeConnection>;
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  courseModuleCertificateMaps?: Maybe<CourseModuleCertificateTemplateMapNodeConnection>;
  courseCertificateMaps?: Maybe<CourseCertificateTemplateMapNodeConnection>;
  courseCertificateMap?: Maybe<CourseCertificateTemplateMapNode>;
  courseModuleCertificateMap?: Maybe<CourseModuleCertificateTemplateMapNode>;
  mosaico?: Maybe<MosaicoNode>;
  allCourseDbtemplates?: Maybe<DbtemplatesNodeConnection>;
  dbtemplate?: Maybe<DbtemplatesNode>;
  allNotes?: Maybe<NoteNodeConnection>;
  note?: Maybe<NoteNode>;
  roomPresence?: Maybe<RoomNode>;
  presencesOfUser?: Maybe<PresenceNodeConnection>;
  siteLookup?: Maybe<SiteNode>;
  siteCurrent?: Maybe<SiteNode>;
  getChatToken?: Maybe<ChatTokenNode>;
  courseInvitation?: Maybe<CourseInvitationNode>;
  courseInvitations?: Maybe<CourseInvitationNodeConnection>;
  userMatchInvitation?: Maybe<MatchInvitationType>;
  course?: Maybe<CourseNode>;
  authorsGroup?: Maybe<AuthorsGroupNode>;
  allAuthorsGroup?: Maybe<AuthorsGroupNodeConnection>;
  courseModule?: Maybe<CourseModuleUnion>;
  allCourses?: Maybe<CourseNodeConnection>;
  allPrivateCourses?: Maybe<CourseNodeConnection>;
  allCompanyParticipations?: Maybe<CourseParticipationNodeConnection>;
  courseAuthors?: Maybe<AuthorNodeConnection>;
  author?: Maybe<AuthorNode>;
  reminder?: Maybe<ReminderNode>;
  allPublicCourses?: Maybe<CourseNodeConnection>;
  allCourseCategories?: Maybe<CourseCategoryNodeConnection>;
  /** The Slug of the object */
  publicCourseBySlug?: Maybe<CourseNode>;
  category?: Maybe<CourseCategoryNode>;
  courseModules?: Maybe<CourseModuleNodeConnection>;
  videoCourseModuleAssessment?: Maybe<CourseModuleAssessmentVideoNode>;
  courseParticipations?: Maybe<CourseParticipationNodeConnection>;
  courseParticipation?: Maybe<CourseParticipationNode>;
  courseCertificates?: Maybe<Array<Maybe<UserCourseCertificate>>>;
  courseUsersParticipationsQuotas?: Maybe<CourseUserParticipationQuotasNodeConnection>;
  courseUserParticipationsQuotas?: Maybe<CourseUserParticipationQuotasNode>;
  courseMyParticipationsQuotas?: Maybe<CourseUserParticipationQuotasNode>;
  courseParticipationImportFile?: Maybe<CourseParticipationImportFileNode>;
  autocomplete?: Maybe<Autocomplete>;
  allCompanies?: Maybe<CompanyNodeConnection>;
  company?: Maybe<CompanyNode>;
  allPublicCompanies?: Maybe<PublicCompanyNodeConnection>;
  publicCompany?: Maybe<PublicCompanyNode>;
  /** The Slug of the object */
  publicCompanyBySlug?: Maybe<PublicCompanyNode>;
  companyTheme?: Maybe<CompanyThemeCustomizationNode>;
  companyInvitations?: Maybe<CompanyUserInvitationNodeConnection>;
  myInvitationsFromCompanies?: Maybe<CompanyUserInvitationNodeConnection>;
  companyUserInvitationFromToken?: Maybe<CompanyUserInvitationNode>;
  survey?: Maybe<SurveyNode>;
  question?: Maybe<QuestionNode>;
  allSurveys?: Maybe<SurveyNodeConnection>;
  allCompanySurveys?: Maybe<SurveyNodeConnection>;
  surveyResponse?: Maybe<SurveyResponseNode>;
  allSurveyResponse?: Maybe<SurveyResponseNodeConnection>;
  allAssessments?: Maybe<AssessmentNodeConnection>;
  currentAssessment?: Maybe<AssessmentNode>;
  assessment?: Maybe<AssessmentNode>;
  allAssessmentSession?: Maybe<AssessmentSessionNodeConnection>;
  assessmentSession?: Maybe<AssessmentSessionNode>;
  lastSurveyResponseOfSurvey?: Maybe<SurveyResponseNode>;
  allUsers?: Maybe<UserNodeConnection>;
  user?: Maybe<UserNode>;
  me?: Maybe<UserNode>;
  /** Instance Permission */
  genericInstancePermissions?: Maybe<GenericInstancePermission>;
  serverInfo?: Maybe<ServerInfoNode>;
  testSerializer?: Maybe<Scalars['String']>;
};

export type QueryZoomMeetingArgs = {
  id: Scalars['ID'];
};

export type QueryThemeArgs = {
  id: Scalars['ID'];
};

export type QueryAllThemesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type QueryCertificateTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryCourseModuleCertificateMapsArgs = {
  moduleId: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryCourseCertificateMapsArgs = {
  courseId: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryCourseCertificateMapArgs = {
  id: Scalars['ID'];
};

export type QueryCourseModuleCertificateMapArgs = {
  id: Scalars['ID'];
};

export type QueryMosaicoArgs = {
  id: Scalars['ID'];
};

export type QueryAllCourseDbtemplatesArgs = {
  courseId: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDbtemplateArgs = {
  id: Scalars['ID'];
};

export type QueryAllNotesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  contentObject?: Maybe<Scalars['String']>;
};

export type QueryNoteArgs = {
  id: Scalars['ID'];
};

export type QueryRoomPresenceArgs = {
  channelName?: Maybe<Scalars['ID']>;
};

export type QueryPresencesOfUserArgs = {
  roomName: Scalars['ID'];
  userId: Scalars['ID'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QuerySiteLookupArgs = {
  domain: Scalars['String'];
};

export type QueryCourseInvitationArgs = {
  id: Scalars['ID'];
};

export type QueryCourseInvitationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['ID']>;
  course?: Maybe<Scalars['ID']>;
};

export type QueryUserMatchInvitationArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryCourseArgs = {
  id: Scalars['ID'];
};

export type QueryAuthorsGroupArgs = {
  id: Scalars['ID'];
};

export type QueryAllAuthorsGroupArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryCourseModuleArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type QueryAllCoursesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type QueryAllPrivateCoursesArgs = {
  companyId?: Maybe<Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type QueryAllCompanyParticipationsArgs = {
  companyId?: Maybe<Scalars['ID']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
  manager?: Maybe<Scalars['ID']>;
  course?: Maybe<Scalars['ID']>;
  status?: Maybe<CourseParticipationStatus>;
  partial?: Maybe<Scalars['Boolean']>;
  course_Company?: Maybe<Scalars['ID']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  hasCertificate?: Maybe<Scalars['Boolean']>;
};

export type QueryCourseAuthorsArgs = {
  id: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryAuthorArgs = {
  id: Scalars['ID'];
};

export type QueryReminderArgs = {
  id: Scalars['ID'];
};

export type QueryAllPublicCoursesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type QueryAllCourseCategoriesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type QueryPublicCourseBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryCourseModulesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  course?: Maybe<Scalars['ID']>;
  course_Slug?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ID']>;
};

export type QueryVideoCourseModuleAssessmentArgs = {
  id: Scalars['ID'];
};

export type QueryCourseParticipationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
  manager?: Maybe<Scalars['ID']>;
  course?: Maybe<Scalars['ID']>;
  status?: Maybe<CourseParticipationStatus>;
  partial?: Maybe<Scalars['Boolean']>;
  course_Company?: Maybe<Scalars['ID']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  hasCertificate?: Maybe<Scalars['Boolean']>;
};

export type QueryCourseParticipationArgs = {
  id: Scalars['ID'];
};

export type QueryCourseCertificatesArgs = {
  user: Scalars['ID'];
  company?: Maybe<Scalars['ID']>;
};

export type QueryCourseUsersParticipationsQuotasArgs = {
  courseId: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryCourseUserParticipationsQuotasArgs = {
  id: Scalars['ID'];
};

export type QueryCourseMyParticipationsQuotasArgs = {
  courseId: Scalars['ID'];
};

export type QueryCourseParticipationImportFileArgs = {
  id: Scalars['ID'];
};

export type QueryAllCompaniesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type QueryCompanyArgs = {
  id: Scalars['ID'];
};

export type QueryAllPublicCompaniesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type QueryPublicCompanyArgs = {
  id: Scalars['ID'];
};

export type QueryPublicCompanyBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryCompanyThemeArgs = {
  id: Scalars['ID'];
};

export type QueryCompanyInvitationsArgs = {
  companyId: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryMyInvitationsFromCompaniesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryCompanyUserInvitationFromTokenArgs = {
  token: Scalars['String'];
};

export type QuerySurveyArgs = {
  id: Scalars['ID'];
};

export type QueryQuestionArgs = {
  id: Scalars['ID'];
};

export type QueryAllSurveysArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryAllCompanySurveysArgs = {
  companyId: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<SurveyType>>>;
  label_Icontains?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type QuerySurveyResponseArgs = {
  id: Scalars['ID'];
};

export type QueryAllSurveyResponseArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  surveyType?: Maybe<Scalars['String']>;
  partial?: Maybe<Scalars['Boolean']>;
  survey?: Maybe<Scalars['String']>;
};

export type QueryAllAssessmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryAssessmentArgs = {
  id: Scalars['ID'];
};

export type QueryAllAssessmentSessionArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
  partial?: Maybe<Scalars['Boolean']>;
};

export type QueryAssessmentSessionArgs = {
  id: Scalars['ID'];
};

export type QueryLastSurveyResponseOfSurveyArgs = {
  surveyId: Scalars['ID'];
};

export type QueryAllUsersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_Icontains?: Maybe<Scalars['String']>;
  email_Istartswith?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  firstName_Icontains?: Maybe<Scalars['String']>;
  firstName_Istartswith?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastName_Icontains?: Maybe<Scalars['String']>;
  lastName_Istartswith?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  anonymousUser?: Maybe<Scalars['Boolean']>;
  manageParticipationCourseId?: Maybe<Scalars['String']>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryGenericInstancePermissionsArgs = {
  instanceId: Scalars['ID'];
};

export type QuestionChoicheItem = {
  __typename?: 'QuestionChoicheItem';
  value?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
};

export type QuestionCloneMutationInput = {
  itemId: Scalars['ID'];
  name: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type QuestionCloneMutationPayload = {
  __typename?: 'QuestionCloneMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  question?: Maybe<QuestionNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type QuestionConnection = {
  __typename?: 'QuestionConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<QuestionEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

export type QuestionCreateMutationInput = {
  choiches?: Maybe<Array<Maybe<ChoichesInput>>>;
  requirements?: Maybe<Array<Maybe<RequirementInput>>>;
  enumType?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  /** Not rendered */
  question?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  /** Title above survey question */
  title?: Maybe<Scalars['String']>;
  /** This is a description for a specific question */
  description?: Maybe<Scalars['String']>;
  /** Field label */
  label?: Maybe<Scalars['String']>;
  /** Field placeholder */
  placeholder?: Maybe<Scalars['String']>;
  /** Field help text */
  helpText?: Maybe<Scalars['String']>;
  datetimeTypeStartDate?: Maybe<Scalars['DateTime']>;
  datetimeTypeEndDate?: Maybe<Scalars['DateTime']>;
  options?: Maybe<Scalars['JSONString']>;
  type: QuestionType;
  survey: Scalars['ID'];
  required: Scalars['Boolean'];
  staffOnly?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type QuestionCreateMutationPayload = {
  __typename?: 'QuestionCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  question?: Maybe<QuestionNode>;
};

export type QuestionDeleteMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type QuestionDeleteMutationPayload = {
  __typename?: 'QuestionDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  question?: Maybe<QuestionNode>;
};

/** A Relay edge containing a `Question` and its cursor. */
export type QuestionEdge = {
  __typename?: 'QuestionEdge';
  /** The item at the end of the edge */
  node?: Maybe<QuestionUnion>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type QuestionNode = Node & {
  __typename?: 'QuestionNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  survey: SurveyNode;
  required: Scalars['Boolean'];
  staffOnly?: Maybe<Scalars['Boolean']>;
  order: Scalars['Int'];
  /** Not rendered */
  question?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  /** Title above survey question */
  title?: Maybe<Scalars['String']>;
  /** This is a description for a specific question */
  description?: Maybe<Scalars['String']>;
  /** Field label */
  label?: Maybe<Scalars['String']>;
  /** Field placeholder */
  placeholder?: Maybe<Scalars['String']>;
  /** Field help text */
  helpText?: Maybe<Scalars['String']>;
  datetimeTypeStartDate?: Maybe<Scalars['DateTime']>;
  datetimeTypeEndDate?: Maybe<Scalars['DateTime']>;
  options?: Maybe<Scalars['JSONString']>;
  type: QuestionType;
  answers: AnswerNodeConnection;
  info?: Maybe<Scalars['JSONString']>;
  choiches?: Maybe<Array<Maybe<QuestionChoicheItem>>>;
  requirements?: Maybe<Array<Maybe<QuestionRequirementItem>>>;
  enumType?: Maybe<Scalars['String']>;
  randomizeChoiches?: Maybe<Scalars['Boolean']>;
  getTypeDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<QuestionPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type QuestionNodeAnswersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QuestionPermission = {
  __typename?: 'QuestionPermission';
  /** Can add Question */
  addQuestion?: Maybe<Scalars['Boolean']>;
  /** Can change Question */
  changeQuestion?: Maybe<Scalars['Boolean']>;
  /** Can delete Question */
  deleteQuestion?: Maybe<Scalars['Boolean']>;
  /** Can view Question */
  viewQuestion?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type QuestionRequirementItem = {
  __typename?: 'QuestionRequirementItem';
  value?: Maybe<Scalars['String']>;
  question?: Maybe<QuestionUnion>;
};

/** An enumeration. */
export enum QuestionType {
  /** Short Text (one line) */
  SHORT_TEXT = 'SHORT_TEXT',
  /** Text (multiple line) */
  TEXT = 'TEXT',
  /** Email */
  EMAIL = 'EMAIL',
  /** Integer */
  INTEGER = 'INTEGER',
  /** Float */
  FLOAT = 'FLOAT',
  /** Select */
  SELECT = 'SELECT',
  /** Multiple Select */
  MULTIPLE_SELECT = 'MULTIPLE_SELECT',
  /** Boolean */
  BOOLEAN = 'BOOLEAN',
  /** Checkbox */
  CHECKBOX = 'CHECKBOX',
  /** Markdown */
  MARKDOWN = 'MARKDOWN',
  /** Datetime */
  DATETIME = 'DATETIME',
  /** Time */
  TIME = 'TIME',
  /** Date */
  DATE = 'DATE',
  /** Gql enum type select */
  GQL_ENUM_TYPE_SELECT = 'GQL_ENUM_TYPE_SELECT',
  /** File Input */
  FILE_INPUT = 'FILE_INPUT',
}

export type QuestionUnion = QuestionNode;

export type QuestionUpdateMutationInput = {
  choiches?: Maybe<Array<Maybe<ChoichesInput>>>;
  requirements?: Maybe<Array<Maybe<RequirementInput>>>;
  enumType?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  /** Not rendered */
  question?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  /** Title above survey question */
  title?: Maybe<Scalars['String']>;
  /** This is a description for a specific question */
  description?: Maybe<Scalars['String']>;
  /** Field label */
  label?: Maybe<Scalars['String']>;
  /** Field placeholder */
  placeholder?: Maybe<Scalars['String']>;
  /** Field help text */
  helpText?: Maybe<Scalars['String']>;
  datetimeTypeStartDate?: Maybe<Scalars['DateTime']>;
  datetimeTypeEndDate?: Maybe<Scalars['DateTime']>;
  options?: Maybe<Scalars['JSONString']>;
  type?: Maybe<QuestionType>;
  /** The ID of the object. */
  id: Scalars['ID'];
  survey?: Maybe<Scalars['ID']>;
  required?: Maybe<Scalars['Boolean']>;
  staffOnly?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type QuestionUpdateMutationPayload = {
  __typename?: 'QuestionUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  question?: Maybe<QuestionNode>;
};

export type ReminderCreateMutationInput = {
  subject?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  moduleId: Scalars['ID'];
  /** clocked datetime */
  clockedDatetime?: Maybe<Scalars['DateTime']>;
  htmlBody?: Maybe<Scalars['String']>;
  htmlCta?: Maybe<Scalars['String']>;
  htmlExtraContent?: Maybe<Scalars['String']>;
  enableEmailCalendar?: Maybe<Scalars['Boolean']>;
  calendarDescription?: Maybe<Scalars['String']>;
  courseReminderRecipientList?: Maybe<CourseReminderRecipientList>;
  attachCoursePdfProgramm?: Maybe<Scalars['Boolean']>;
  assessmentsStatus?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ReminderCreateMutationPayload = {
  __typename?: 'ReminderCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  reminder?: Maybe<ReminderNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ReminderDeleteMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ReminderDeleteMutationPayload = {
  __typename?: 'ReminderDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  reminder?: Maybe<ReminderNode>;
};

export type ReminderNode = Node & {
  __typename?: 'ReminderNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Short Description For This Task */
  name: Scalars['String'];
  /** The Name of the Celery Task that Should be Run.  (Example: "proj.tasks.import_contacts") */
  task: Scalars['String'];
  /** Clocked Schedule to run the task on.  Set only one schedule type, leave the others null. */
  clocked?: Maybe<ClockedScheduleNode>;
  /** JSON encoded positional arguments (Example: ["arg1", "arg2"]) */
  args: Scalars['String'];
  /** JSON encoded keyword arguments (Example: {"argument": "value"}) */
  kwargs: Scalars['String'];
  /** Queue defined in CELERY_TASK_QUEUES. Leave None for default queuing. */
  queue?: Maybe<Scalars['String']>;
  /** Override Exchange for low-level AMQP routing */
  exchange?: Maybe<Scalars['String']>;
  /** Override Routing Key for low-level AMQP routing */
  routingKey?: Maybe<Scalars['String']>;
  /** JSON encoded message headers for the AMQP message. */
  headers: Scalars['String'];
  /** Priority Number between 0 and 255. Supported by: RabbitMQ, Redis (priority reversed, 0 is highest). */
  priority?: Maybe<Scalars['Int']>;
  /** Datetime after which the schedule will no longer trigger the task to run */
  expires?: Maybe<Scalars['DateTime']>;
  /** Timedelta with seconds which the schedule will no longer trigger the task to run */
  expireSeconds?: Maybe<Scalars['Int']>;
  /** If True, the schedule will only run the task a single time */
  oneOff: Scalars['Boolean'];
  /** Datetime when the schedule should begin triggering the task to run */
  startTime?: Maybe<Scalars['DateTime']>;
  /** Set to False to disable the schedule */
  enabled: Scalars['Boolean'];
  /** Datetime that the schedule last triggered the task to run. Reset to None if enabled is set to False. */
  lastRunAt?: Maybe<Scalars['DateTime']>;
  /** Running count of how many times the schedule has triggered the task */
  totalRunCount: Scalars['Int'];
  /** Datetime that this PeriodicTask was last modified */
  dateChanged: Scalars['DateTime'];
  /** Detailed description about the details of this Periodic Task */
  description: Scalars['String'];
  coursemoduleSet: CourseModuleNodeConnection;
  result?: Maybe<TaskResultNodeConnection>;
  userHasPermission?: Maybe<PeriodicTaskPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type ReminderNodeCoursemoduleSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  course?: Maybe<Scalars['ID']>;
  course_Slug?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['ID']>;
};

export type ReminderNodeResultArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ReminderNodeConnection = {
  __typename?: 'ReminderNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReminderNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ReminderNode` and its cursor. */
export type ReminderNodeEdge = {
  __typename?: 'ReminderNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ReminderNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ReminderTestMutationInput = {
  reminderId: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ReminderTestMutationPayload = {
  __typename?: 'ReminderTestMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  status?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ReminderUpdateMutationInput = {
  subject?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  reminderId: Scalars['ID'];
  moduleId?: Maybe<Scalars['ID']>;
  /** clocked datetime */
  clockedDatetime?: Maybe<Scalars['DateTime']>;
  htmlBody?: Maybe<Scalars['String']>;
  htmlCta?: Maybe<Scalars['String']>;
  htmlExtraContent?: Maybe<Scalars['String']>;
  enableEmailCalendar?: Maybe<Scalars['Boolean']>;
  calendarDescription?: Maybe<Scalars['String']>;
  courseReminderRecipientList?: Maybe<CourseReminderRecipientList>;
  attachCoursePdfProgramm?: Maybe<Scalars['Boolean']>;
  assessmentsStatus?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ReminderUpdateMutationPayload = {
  __typename?: 'ReminderUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  reminder?: Maybe<ReminderNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RequirementInput = {
  value?: Maybe<Scalars['String']>;
  question?: Maybe<Scalars['ID']>;
};

export type RoomNode = Node & {
  __typename?: 'RoomNode';
  uuid: Scalars['UUID'];
  /** Group channel name for this room */
  channelName: Scalars['String'];
  presenceSet: PresenceNodeConnection;
  /** The ID of the object. */
  id: Scalars['ID'];
  users?: Maybe<UserConnection>;
  graphs?: Maybe<Scalars['JSONString']>;
  userHasPermission?: Maybe<RoomPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type RoomNodePresenceSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type RoomNodeUsersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type RoomNodeGraphsArgs = {
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  resolution?: Maybe<Scalars['Int']>;
};

export type RoomPermission = {
  __typename?: 'RoomPermission';
  /** Can add room */
  addRoom?: Maybe<Scalars['Boolean']>;
  /** Can change room */
  changeRoom?: Maybe<Scalars['Boolean']>;
  /** Can delete room */
  deleteRoom?: Maybe<Scalars['Boolean']>;
  /** Can view room */
  viewRoom?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** SaveAnswerToTheQuestion */
export type SaveAnswerToTheQuestion = {
  __typename?: 'SaveAnswerToTheQuestion';
  /** List of errors that occurred executing the mutation. */
  errors?: Maybe<Array<Error>>;
  ok?: Maybe<Scalars['Boolean']>;
  response?: Maybe<SurveyResponseNode>;
  answer?: Maybe<AnswerNode>;
  question?: Maybe<QuestionUnion>;
};

export type SaveAnswerToTheQuestionInput = {
  data: Scalars['JSONString'];
  questionId?: Maybe<Scalars['ID']>;
  responseId?: Maybe<Scalars['ID']>;
  createResponse?: Maybe<Scalars['Boolean']>;
};

/** SaveSurveyBulkResponseMutation */
export type SaveSurveyBulkResponse = {
  __typename?: 'SaveSurveyBulkResponse';
  /** List of errors that occurred executing the mutation. */
  errors?: Maybe<Array<Error>>;
  ok?: Maybe<Scalars['Boolean']>;
  response?: Maybe<SurveyResponseNode>;
  warnings?: Maybe<Array<Scalars['String']>>;
};

export type SaveSurveyBulkResponseInput = {
  responseId?: Maybe<Scalars['ID']>;
  surveyId?: Maybe<Scalars['ID']>;
  answers?: Maybe<Array<AnswerInput>>;
  answersDict?: Maybe<Array<AnswerDictInput>>;
  useLastResponse?: Maybe<Scalars['Boolean']>;
};

export type SendChannelMessageInput = {
  type: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  channelName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendChannelMessagePayload = {
  __typename?: 'SendChannelMessagePayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  status?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Server information */
export type ServerInfoNode = {
  __typename?: 'ServerInfoNode';
  meta?: Maybe<MetaNode>;
  ipAddress?: Maybe<Scalars['String']>;
  pythonVersion?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  system?: Maybe<Scalars['String']>;
  node?: Maybe<Scalars['String']>;
};

export type SiteNode = Node & {
  __typename?: 'SiteNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  domain: Scalars['String'];
  name: Scalars['String'];
  course?: Maybe<CourseNode>;
  company?: Maybe<CompanyNode>;
  favicongeneratorSet: FaviconGeneratorNodeConnection;
  mosaico?: Maybe<MosaicoNode>;
  faviconData?: Maybe<FaviconGeneratorNode>;
  userHasPermission?: Maybe<SitePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type SiteNodeFavicongeneratorSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SiteNodeConnection = {
  __typename?: 'SiteNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SiteNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SiteNode` and its cursor. */
export type SiteNodeEdge = {
  __typename?: 'SiteNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<SiteNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type SitePermission = {
  __typename?: 'SitePermission';
  /** Can add site */
  addSite?: Maybe<Scalars['Boolean']>;
  /** Can change site */
  changeSite?: Maybe<Scalars['Boolean']>;
  /** Can delete site */
  deleteSite?: Maybe<Scalars['Boolean']>;
  /** Can view site */
  viewSite?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum StreamServiceAccountType {
  /** Zoom */
  ZOOM = 'ZOOM',
  /** Meet */
  MEET = 'MEET',
}

export type StreamServiceNode = Node & {
  __typename?: 'StreamServiceNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  company: CompanyNode;
  accountType?: Maybe<StreamServiceAccountType>;
  accountId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  sdkClientId?: Maybe<Scalars['String']>;
  sdkClientSecret?: Maybe<Scalars['String']>;
  getAccountTypeDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<StreamServicePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type StreamServiceNodeConnection = {
  __typename?: 'StreamServiceNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StreamServiceNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `StreamServiceNode` and its cursor. */
export type StreamServiceNodeEdge = {
  __typename?: 'StreamServiceNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<StreamServiceNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type StreamServicePermission = {
  __typename?: 'StreamServicePermission';
  /** Can add Stream service account */
  addStreamservice?: Maybe<Scalars['Boolean']>;
  /** Can change Stream service account */
  changeStreamservice?: Maybe<Scalars['Boolean']>;
  /** Can delete Stream service account */
  deleteStreamservice?: Maybe<Scalars['Boolean']>;
  /** Can view Stream service account */
  viewStreamservice?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onQuestionUpdateSubscription?: Maybe<OnQuestionUpdate>;
  onThemeUpdateSubscription?: Maybe<OnThemeUpdate>;
  onRoomPresenceMessageSubscription?: Maybe<OnRoomPresenceMessage>;
  onRoomPresenceUpdateSubscription?: Maybe<OnRoomPresenceUpdate>;
  onRoomPresenceTrack?: Maybe<OnRoomPresenceTrack>;
  onCourseModuleUpdateSubscription?: Maybe<OnCourseModuleUpdate>;
  onCourseParticipationUpdateSubscription?: Maybe<OnCourseParticipationUpdate>;
  onCourseInvitationUpdateSubscription?: Maybe<OnCourseInvitationUpdate>;
  onLoginSubscription?: Maybe<OnLoginSubscription>;
  onLogoutSubscription?: Maybe<OnLogoutSubscription>;
};

export type SubscriptionOnQuestionUpdateSubscriptionArgs = {
  id: Scalars['ID'];
};

export type SubscriptionOnThemeUpdateSubscriptionArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type SubscriptionOnRoomPresenceMessageSubscriptionArgs = {
  channelName: Scalars['ID'];
};

export type SubscriptionOnRoomPresenceUpdateSubscriptionArgs = {
  channelName: Scalars['ID'];
};

export type SubscriptionOnRoomPresenceTrackArgs = {
  roomChannelName: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
};

export type SubscriptionOnCourseModuleUpdateSubscriptionArgs = {
  id: Scalars['ID'];
};

export type SubscriptionOnCourseParticipationUpdateSubscriptionArgs = {
  courseId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
};

export type SubscriptionOnCourseInvitationUpdateSubscriptionArgs = {
  courseId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
};

export type SummaryRoomNode = {
  __typename?: 'SummaryRoomNode';
  channelName?: Maybe<Scalars['String']>;
  usersCount?: Maybe<Scalars['Int']>;
  anonymousUsersCount?: Maybe<Scalars['Int']>;
};

export type SurveyCloneMutationInput = {
  itemId: Scalars['ID'];
  name: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SurveyCloneMutationPayload = {
  __typename?: 'SurveyCloneMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  survey?: Maybe<SurveyNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SurveyCreateMutationInput = {
  name: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<SurveyType>;
  ownership?: Maybe<Scalars['ID']>;
  isPublished: Scalars['Boolean'];
  randomizeQuestions: Scalars['Boolean'];
  needLoggedUser: Scalars['Boolean'];
  editableAnswers: Scalars['Boolean'];
  /** Set list of assessments */
  assessments?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of companys */
  companySet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  courseSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  surveymoduleSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SurveyCreateMutationPayload = {
  __typename?: 'SurveyCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  survey?: Maybe<SurveyNode>;
};

export type SurveyDeleteMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SurveyDeleteMutationPayload = {
  __typename?: 'SurveyDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  survey?: Maybe<SurveyNode>;
};

export type SurveyModuleCreateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  minimumScore?: Maybe<Scalars['Float']>;
  minimumScoreType?: Maybe<SurveyModuleMinimumScoreType>;
  viewTrainingModuleMinimumTime?: Maybe<Scalars['Float']>;
  numberOfAttempts?: Maybe<Scalars['Int']>;
  coursemodulePtr?: Maybe<Scalars['ID']>;
  /** The ID of the object. */
  id?: Maybe<Scalars['ID']>;
  course: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  survey?: Maybe<Scalars['ID']>;
  showScoreResults?: Maybe<Scalars['Boolean']>;
  showOnlyErrorsOnScoreResults?: Maybe<Scalars['Boolean']>;
  trainingModule?: Maybe<Scalars['ID']>;
  forceViewTrainingModuleOnFailAttempt?: Maybe<Scalars['Boolean']>;
  hideUserDataInReports?: Maybe<Scalars['Boolean']>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SurveyModuleCreateMutationPayload = {
  __typename?: 'SurveyModuleCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  surveyModule?: Maybe<SurveyModuleNode>;
};

/** An enumeration. */
export enum SurveyModuleMinimumScoreType {
  /** Absolute */
  ABSOLUTE = 'ABSOLUTE',
  /** Percentile */
  PERCENTILE = 'PERCENTILE',
}

export type SurveyModuleNode = Node & {
  __typename?: 'SurveyModuleNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  order: Scalars['Int'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  course: CourseNode;
  parent?: Maybe<CourseModuleNode>;
  requiredModule?: Maybe<CourseModuleNode>;
  routable: Scalars['Boolean'];
  routableExact: Scalars['Boolean'];
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** If true only staff user can test module (not locked) */
  testingMode: Scalars['Boolean'];
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete: Scalars['Boolean'];
  /** Exclude module in participation check completed */
  disableCheckComplete: Scalars['Boolean'];
  /** Display complete/continue button in module */
  displayCompleteButton: Scalars['Boolean'];
  /** send email when module is completed */
  sendEmailWhenCompleted: Scalars['Boolean'];
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug: Scalars['String'];
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  /** Force module locked for all participants */
  forceLocked: Scalars['Boolean'];
  accessMode: CourseModuleAccessMode;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy: CourseModulePrivacy;
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  coursemodulePtr: CourseModuleNode;
  survey?: Maybe<SurveyNode>;
  minimumScore?: Maybe<Scalars['Float']>;
  minimumScoreType: SurveyModuleMinimumScoreType;
  showScoreResults: Scalars['Boolean'];
  showOnlyErrorsOnScoreResults: Scalars['Boolean'];
  trainingModule?: Maybe<CourseModuleUnion>;
  forceViewTrainingModuleOnFailAttempt: Scalars['Boolean'];
  viewTrainingModuleMinimumTime?: Maybe<Scalars['Float']>;
  numberOfAttempts?: Maybe<Scalars['Int']>;
  hideUserDataInReports: Scalars['Boolean'];
  userModuleAssessment?: Maybe<CourseModuleAssessmentUnion>;
  submodules?: Maybe<CourseModuleConnection>;
  videowall?: Maybe<VideowallModuleNode>;
  frontendUrl?: Maybe<Scalars['String']>;
  authors?: Maybe<AuthorModuleConnection>;
  reminders?: Maybe<ReminderNodeConnection>;
  /** The total live count objects in this query. */
  assessmentsCounter?: Maybe<Scalars['Int']>;
  getAccessModeDisplay?: Maybe<Scalars['String']>;
  getPrivacyDisplay?: Maybe<Scalars['String']>;
  getMinimumScoreTypeDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<SurveyModulePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type SurveyModuleNodeUserModuleAssessmentArgs = {
  userId?: Maybe<Scalars['ID']>;
};

export type SurveyModuleNodeSubmodulesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SurveyModuleNodeAuthorsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SurveyModuleNodeRemindersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SurveyModuleNodeConnection = {
  __typename?: 'SurveyModuleNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SurveyModuleNodeEdge>>;
};

/** A Relay edge containing a `SurveyModuleNode` and its cursor. */
export type SurveyModuleNodeEdge = {
  __typename?: 'SurveyModuleNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<SurveyModuleNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type SurveyModulePermission = {
  __typename?: 'SurveyModulePermission';
  /** Can add survey module */
  addSurveymodule?: Maybe<Scalars['Boolean']>;
  /** Can change survey module */
  changeSurveymodule?: Maybe<Scalars['Boolean']>;
  /** Can delete survey module */
  deleteSurveymodule?: Maybe<Scalars['Boolean']>;
  /** Can view survey module */
  viewSurveymodule?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type SurveyModuleUpdateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  minimumScore?: Maybe<Scalars['Float']>;
  minimumScoreType?: Maybe<SurveyModuleMinimumScoreType>;
  viewTrainingModuleMinimumTime?: Maybe<Scalars['Float']>;
  numberOfAttempts?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  survey?: Maybe<Scalars['ID']>;
  showScoreResults?: Maybe<Scalars['Boolean']>;
  showOnlyErrorsOnScoreResults?: Maybe<Scalars['Boolean']>;
  trainingModule?: Maybe<Scalars['ID']>;
  forceViewTrainingModuleOnFailAttempt?: Maybe<Scalars['Boolean']>;
  hideUserDataInReports?: Maybe<Scalars['Boolean']>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SurveyModuleUpdateMutationPayload = {
  __typename?: 'SurveyModuleUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  surveyModule?: Maybe<SurveyModuleNode>;
};

export type SurveyNode = Node & {
  __typename?: 'SurveyNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  name: Scalars['String'];
  ownership?: Maybe<CompanyNode>;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isPublished: Scalars['Boolean'];
  randomizeQuestions: Scalars['Boolean'];
  needLoggedUser: Scalars['Boolean'];
  editableAnswers: Scalars['Boolean'];
  type?: Maybe<SurveyType>;
  assessments: AssessmentNodeConnection;
  questions?: Maybe<QuestionConnection>;
  responses: SurveyResponseNodeConnection;
  surveymoduleSet: SurveyModuleNodeConnection;
  courseSet: CourseNodeConnection;
  companySet: CompanyNodeConnection;
  getTypeDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<SurveyPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type SurveyNodeAssessmentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SurveyNodeQuestionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SurveyNodeResponsesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  surveyType?: Maybe<Scalars['String']>;
  partial?: Maybe<Scalars['Boolean']>;
  survey?: Maybe<Scalars['String']>;
};

export type SurveyNodeSurveymoduleSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SurveyNodeCourseSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type SurveyNodeCompanySetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type SurveyNodeConnection = {
  __typename?: 'SurveyNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SurveyNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SurveyNode` and its cursor. */
export type SurveyNodeEdge = {
  __typename?: 'SurveyNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<SurveyNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type SurveyPermission = {
  __typename?: 'SurveyPermission';
  /** Can add survey */
  addSurvey?: Maybe<Scalars['Boolean']>;
  /** Can change survey */
  changeSurvey?: Maybe<Scalars['Boolean']>;
  /** Can delete survey */
  deleteSurvey?: Maybe<Scalars['Boolean']>;
  /** Can view survey */
  viewSurvey?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type SurveyResponseNode = Node & {
  __typename?: 'SurveyResponseNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  interviewUuid: Scalars['UUID'];
  survey?: Maybe<SurveyUnion>;
  user?: Maybe<UserNode>;
  partial: Scalars['Boolean'];
  enough: Scalars['Boolean'];
  answers: AnswerNodeConnection;
  courseparticipationSet: CourseParticipationNodeConnection;
  coursemoduleassessmentsurveySet: CourseModuleAssessmentSurveyNodeConnection;
  score?: Maybe<Scalars['Int']>;
  maxScore?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Boolean']>;
  userHasPermission?: Maybe<SurveyResponsePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type SurveyResponseNodeAnswersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SurveyResponseNodeCourseparticipationSetArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
  manager?: Maybe<Scalars['ID']>;
  course?: Maybe<Scalars['ID']>;
  status?: Maybe<CourseParticipationStatus>;
  partial?: Maybe<Scalars['Boolean']>;
  course_Company?: Maybe<Scalars['ID']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  hasCertificate?: Maybe<Scalars['Boolean']>;
};

export type SurveyResponseNodeCoursemoduleassessmentsurveySetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SurveyResponseNodeConnection = {
  __typename?: 'SurveyResponseNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SurveyResponseNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SurveyResponseNode` and its cursor. */
export type SurveyResponseNodeEdge = {
  __typename?: 'SurveyResponseNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<SurveyResponseNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type SurveyResponsePermission = {
  __typename?: 'SurveyResponsePermission';
  /** Can add SurveyResponse */
  addSurveyresponse?: Maybe<Scalars['Boolean']>;
  /** Can change SurveyResponse */
  changeSurveyresponse?: Maybe<Scalars['Boolean']>;
  /** Can delete SurveyResponse */
  deleteSurveyresponse?: Maybe<Scalars['Boolean']>;
  /** Can view SurveyResponse */
  viewSurveyresponse?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum SurveyType {
  /** Generic Survey */
  GENERIC_SURVEY = 'GENERIC_SURVEY',
  /** Form */
  FORM = 'FORM',
  /** Poll */
  POLL = 'POLL',
}

export type SurveyUnion = SurveyNode;

export type SurveyUpdateMutationInput = {
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<SurveyType>;
  /** The ID of the object. */
  id: Scalars['ID'];
  ownership?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  randomizeQuestions?: Maybe<Scalars['Boolean']>;
  needLoggedUser?: Maybe<Scalars['Boolean']>;
  editableAnswers?: Maybe<Scalars['Boolean']>;
  /** Set list of assessments */
  assessments?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of companys */
  companySet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  courseSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  surveymoduleSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SurveyUpdateMutationPayload = {
  __typename?: 'SurveyUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  survey?: Maybe<SurveyNode>;
};

export type TagPermission = {
  __typename?: 'TagPermission';
  /** Can add tag */
  addTag?: Maybe<Scalars['Boolean']>;
  /** Can change tag */
  changeTag?: Maybe<Scalars['Boolean']>;
  /** Can delete tag */
  deleteTag?: Maybe<Scalars['Boolean']>;
  /** Can view tag */
  viewTag?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type TagType = Node & {
  __typename?: 'TagType';
  /** The ID of the object. */
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  userHasPermission?: Maybe<TagPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type TaskResultNode = Node & {
  __typename?: 'TaskResultNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Celery ID for the Task that was run */
  taskId: Scalars['String'];
  /** Name of the Periodic Task which was run */
  periodicTaskName?: Maybe<Scalars['String']>;
  /** Name of the Task which was run */
  taskName?: Maybe<Scalars['String']>;
  /** JSON representation of the positional arguments used with the task */
  taskArgs?: Maybe<Scalars['String']>;
  /** JSON representation of the named arguments used with the task */
  taskKwargs?: Maybe<Scalars['String']>;
  /** Current state of the task being run */
  status: TaskResultStatus;
  /** Worker that executes the task */
  worker?: Maybe<Scalars['String']>;
  /** Content type of the result data */
  contentType: Scalars['String'];
  /** The encoding used to save the task result data */
  contentEncoding: Scalars['String'];
  /** The data returned by the task.  Use content_encoding and content_type fields to read. */
  result?: Maybe<Scalars['String']>;
  /** Datetime field when the task result was created in UTC */
  dateCreated: Scalars['DateTime'];
  /** Datetime field when the task was completed in UTC */
  dateDone: Scalars['DateTime'];
  /** Text of the traceback if the task generated one */
  traceback?: Maybe<Scalars['String']>;
  /** JSON meta information about the task, such as information on child tasks */
  meta?: Maybe<Scalars['String']>;
  reminderId?: Maybe<Scalars['ID']>;
  getStatusDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<TaskResultPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type TaskResultNodeConnection = {
  __typename?: 'TaskResultNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TaskResultNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `TaskResultNode` and its cursor. */
export type TaskResultNodeEdge = {
  __typename?: 'TaskResultNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<TaskResultNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type TaskResultPermission = {
  __typename?: 'TaskResultPermission';
  /** Can add task result */
  addTaskresult?: Maybe<Scalars['Boolean']>;
  /** Can change task result */
  changeTaskresult?: Maybe<Scalars['Boolean']>;
  /** Can delete task result */
  deleteTaskresult?: Maybe<Scalars['Boolean']>;
  /** Can view task result */
  viewTaskresult?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum TaskResultStatus {
  /** FAILURE */
  FAILURE = 'FAILURE',
  /** PENDING */
  PENDING = 'PENDING',
  /** RECEIVED */
  RECEIVED = 'RECEIVED',
  /** RETRY */
  RETRY = 'RETRY',
  /** REVOKED */
  REVOKED = 'REVOKED',
  /** STARTED */
  STARTED = 'STARTED',
  /** SUCCESS */
  SUCCESS = 'SUCCESS',
}

export type TaxInput = {
  /** type */
  type: TaxTypeEnum;
  /** value */
  value: Scalars['String'];
};

export type TaxNode = {
  __typename?: 'TaxNode';
  /** type */
  type: TaxTypeEnum;
  /** value */
  value: Scalars['String'];
};

export enum TaxTypeEnum {
  /** AT VAT - Austria */
  AT_VAT = 'AT_VAT',
  /** AU ABN - Australia */
  AU_ABN = 'AU_ABN',
  /** BE VAT - Belgium */
  BE_VAT = 'BE_VAT',
  /** BG VAT - Bulgaria */
  BG_VAT = 'BG_VAT',
  /** CA BN  - Canada */
  CA_BN = 'CA_BN',
  /** CA QST - Canada */
  CA_QST = 'CA_QST',
  /** CH VAT - Switzerland */
  CH_VAT = 'CH_VAT',
  /** CY VAT - Cyprus */
  CY_VAT = 'CY_VAT',
  /** CZ VAT - CZech Republic */
  CZ_VAT = 'CZ_VAT',
  /** DE VAT - Germany */
  DE_VAT = 'DE_VAT',
  /** DK VAT - Denmark */
  DK_VAT = 'DK_VAT',
  /** EE VAT - Estonia */
  EE_VAT = 'EE_VAT',
  /** ES CIF - Spain */
  ES_CIF = 'ES_CIF',
  /** ES VAT - Spain */
  ES_VAT = 'ES_VAT',
  /** FI VAT - Finland */
  FI_VAT = 'FI_VAT',
  /** FR VAT - France */
  FR_VAT = 'FR_VAT',
  /** GB VAT - Regno Unito */
  GB_VAT = 'GB_VAT',
  /** GR VAT - Grecee */
  GR_VAT = 'GR_VAT',
  /** HK BR  - Hong Kong RAS China */
  HK_BR = 'HK_BR',
  /** HR VAT - Croatia */
  HR_VAT = 'HR_VAT',
  /** HU VAT - Hunghery */
  HU_VAT = 'HU_VAT',
  /** IE VAT - Ireland */
  IE_VAT = 'IE_VAT',
  /** IN GST - India */
  IN_GST = 'IN_GST',
  /** IT VAT - Italy */
  IT_VAT = 'IT_VAT',
  /** JP CN  - Japan */
  JP_CN = 'JP_CN',
  /** KR BRN - South Korea */
  KR_BRN = 'KR_BRN',
  /** LI UID - Liechtenstein */
  LI_UID = 'LI_UID',
  /** LT VAT - Lituania */
  LT_VAT = 'LT_VAT',
  /** LU VAT - Luxembourg */
  LU_VAT = 'LU_VAT',
  /** LV VAT - Latvia */
  LV_VAT = 'LV_VAT',
  /** MT VAT - Malta */
  MT_VAT = 'MT_VAT',
  /** MX RFC - Mexico */
  MX_RFC = 'MX_RFC',
  /** MY ITN - Malaysia */
  MY_ITN = 'MY_ITN',
  /** NL VAT - Netherlands */
  NL_VAT = 'NL_VAT',
  /** NO VAT - Norway */
  NO_VAT = 'NO_VAT',
  /** NZ GST - New Zeland */
  NZ_GST = 'NZ_GST',
  /** PL VAT - Polond */
  PL_VAT = 'PL_VAT',
  /** PT VAT - Portugal */
  PT_VAT = 'PT_VAT',
  /** RO VAT - Romania */
  RO_VAT = 'RO_VAT',
  /** RU INN - Russia */
  RU_INN = 'RU_INN',
  /** SE VAT - Sweden */
  SE_VAT = 'SE_VAT',
  /** SG UEN - Singapore */
  SG_UEN = 'SG_UEN',
  /** SI VAT - Slovenia */
  SI_VAT = 'SI_VAT',
  /** SK VAT - Slovakia */
  SK_VAT = 'SK_VAT',
  /** TH VAT - Thailand */
  TH_VAT = 'TH_VAT',
  /** TW VAT - Taiwan */
  TW_VAT = 'TW_VAT',
  /** US EIN - USA */
  US_EIN = 'US_EIN',
  /** ZA VAT - South Africa */
  ZA_VAT = 'ZA_VAT',
}

/** An enumeration. */
export enum TemplateName {
  /** [TXT] webinar upcoming */
  HERALD_TEXT_WEBINAR_UPCOMING_TXT = 'HERALD_TEXT_WEBINAR_UPCOMING_TXT',
  /** [HTML] welcome email with code */
  HERALD_HTML_WELCOME_EMAIL_WITH_CODE_HTML = 'HERALD_HTML_WELCOME_EMAIL_WITH_CODE_HTML',
  /** [TXT] post module email */
  HERALD_TEXT_POST_MODULE_EMAIL_TXT = 'HERALD_TEXT_POST_MODULE_EMAIL_TXT',
  /** [HTML] user deactivated email */
  HERALD_HTML_USER_DEACTIVATED_EMAIL_HTML = 'HERALD_HTML_USER_DEACTIVATED_EMAIL_HTML',
  /** [TXT] welcome email with code */
  HERALD_TEXT_WELCOME_EMAIL_WITH_CODE_TXT = 'HERALD_TEXT_WELCOME_EMAIL_WITH_CODE_TXT',
  /** [TXT] user deactivated email */
  HERALD_TEXT_USER_DEACTIVATED_EMAIL_TXT = 'HERALD_TEXT_USER_DEACTIVATED_EMAIL_TXT',
  /** [TXT] company user invitation accepted */
  HERALD_TEXT_COMPANY_USER_INVITATION_ACCEPTED_TXT = 'HERALD_TEXT_COMPANY_USER_INVITATION_ACCEPTED_TXT',
  /** [TXT] welcome email */
  HERALD_TEXT_WELCOME_EMAIL_TXT = 'HERALD_TEXT_WELCOME_EMAIL_TXT',
  /** [TXT] contact email */
  HERALD_TEXT_CONTACT_EMAIL_TXT = 'HERALD_TEXT_CONTACT_EMAIL_TXT',
  /** [HTML] update password email */
  HERALD_HTML_UPDATE_PASSWORD_EMAIL_HTML = 'HERALD_HTML_UPDATE_PASSWORD_EMAIL_HTML',
  /** [HTML] user deactivation request email */
  HERALD_HTML_USER_DEACTIVATION_REQUEST_EMAIL_HTML = 'HERALD_HTML_USER_DEACTIVATION_REQUEST_EMAIL_HTML',
  /** [HTML] welcome email */
  HERALD_HTML_WELCOME_EMAIL_HTML = 'HERALD_HTML_WELCOME_EMAIL_HTML',
  /** [HTML] company user invitation */
  HERALD_HTML_COMPANY_USER_INVITATION_HTML = 'HERALD_HTML_COMPANY_USER_INVITATION_HTML',
  /** [TXT] company user invitation */
  HERALD_TEXT_COMPANY_USER_INVITATION_TXT = 'HERALD_TEXT_COMPANY_USER_INVITATION_TXT',
  /** [HTML] course invitation email */
  HERALD_HTML_COURSE_INVITATION_EMAIL_HTML = 'HERALD_HTML_COURSE_INVITATION_EMAIL_HTML',
  /** [HTML] send certificate email */
  HERALD_HTML_SEND_CERTIFICATE_EMAIL_HTML = 'HERALD_HTML_SEND_CERTIFICATE_EMAIL_HTML',
  /** [TXT] base */
  HERALD_TEXT_BASE_TXT = 'HERALD_TEXT_BASE_TXT',
  /** [TXT] update password email */
  HERALD_TEXT_UPDATE_PASSWORD_EMAIL_TXT = 'HERALD_TEXT_UPDATE_PASSWORD_EMAIL_TXT',
  /** [HTML] reset password email */
  HERALD_HTML_RESET_PASSWORD_EMAIL_HTML = 'HERALD_HTML_RESET_PASSWORD_EMAIL_HTML',
  /** [TXT] user deactivation request email */
  HERALD_TEXT_USER_DEACTIVATION_REQUEST_EMAIL_TXT = 'HERALD_TEXT_USER_DEACTIVATION_REQUEST_EMAIL_TXT',
  /** [TXT] send certificate email */
  HERALD_TEXT_SEND_CERTIFICATE_EMAIL_TXT = 'HERALD_TEXT_SEND_CERTIFICATE_EMAIL_TXT',
  /** [TXT] generic module reminder */
  HERALD_TEXT_GENERIC_MODULE_REMINDER_TXT = 'HERALD_TEXT_GENERIC_MODULE_REMINDER_TXT',
  /** [HTML] confirm participation email */
  HERALD_HTML_CONFIRM_PARTICIPATION_EMAIL_HTML = 'HERALD_HTML_CONFIRM_PARTICIPATION_EMAIL_HTML',
  /** [HTML] user has completed test session company administrators email */
  HERALD_HTML_USER_HAS_COMPLETED_TEST_SESSION_COMPANY_ADMINISTRATORS_EMAIL_HTML = 'HERALD_HTML_USER_HAS_COMPLETED_TEST_SESSION_COMPANY_ADMINISTRATORS_EMAIL_HTML',
  /** [TXT] course invitation email */
  HERALD_TEXT_COURSE_INVITATION_EMAIL_TXT = 'HERALD_TEXT_COURSE_INVITATION_EMAIL_TXT',
  /** [TXT] user has completed test session company administrators email */
  HERALD_TEXT_USER_HAS_COMPLETED_TEST_SESSION_COMPANY_ADMINISTRATORS_EMAIL_TXT = 'HERALD_TEXT_USER_HAS_COMPLETED_TEST_SESSION_COMPANY_ADMINISTRATORS_EMAIL_TXT',
  /** [HTML] created demo palco email */
  HERALD_HTML_CREATED_DEMO_PALCO_EMAIL_HTML = 'HERALD_HTML_CREATED_DEMO_PALCO_EMAIL_HTML',
  /** [TXT] created demo palco email */
  HERALD_TEXT_CREATED_DEMO_PALCO_EMAIL_TXT = 'HERALD_TEXT_CREATED_DEMO_PALCO_EMAIL_TXT',
  /** [HTML] generic module reminder */
  HERALD_HTML_GENERIC_MODULE_REMINDER_HTML = 'HERALD_HTML_GENERIC_MODULE_REMINDER_HTML',
  /** [HTML] contact email */
  HERALD_HTML_CONTACT_EMAIL_HTML = 'HERALD_HTML_CONTACT_EMAIL_HTML',
  /** [HTML] webinar upcoming */
  HERALD_HTML_WEBINAR_UPCOMING_HTML = 'HERALD_HTML_WEBINAR_UPCOMING_HTML',
  /** [HTML] base */
  HERALD_HTML_BASE_HTML = 'HERALD_HTML_BASE_HTML',
  /** [HTML] post module email */
  HERALD_HTML_POST_MODULE_EMAIL_HTML = 'HERALD_HTML_POST_MODULE_EMAIL_HTML',
  /** [TXT] confirm participation email */
  HERALD_TEXT_CONFIRM_PARTICIPATION_EMAIL_TXT = 'HERALD_TEXT_CONFIRM_PARTICIPATION_EMAIL_TXT',
  /** [TXT] reset password email */
  HERALD_TEXT_RESET_PASSWORD_EMAIL_TXT = 'HERALD_TEXT_RESET_PASSWORD_EMAIL_TXT',
  /** [HTML] company user invitation accepted */
  HERALD_HTML_COMPANY_USER_INVITATION_ACCEPTED_HTML = 'HERALD_HTML_COMPANY_USER_INVITATION_ACCEPTED_HTML',
  /** [TXT] participation approval email */
  HERALD_TEXT_PARTICIPATION_APPROVAL_EMAIL_TXT = 'HERALD_TEXT_PARTICIPATION_APPROVAL_EMAIL_TXT',
  /** [HTML] participation approval email */
  HERALD_HTML_PARTICIPATION_APPROVAL_EMAIL_HTML = 'HERALD_HTML_PARTICIPATION_APPROVAL_EMAIL_HTML',
}

export type TemplatePermission = {
  __typename?: 'TemplatePermission';
  /** Can add template */
  addTemplate?: Maybe<Scalars['Boolean']>;
  /** Can change template */
  changeTemplate?: Maybe<Scalars['Boolean']>;
  /** Can delete template */
  deleteTemplate?: Maybe<Scalars['Boolean']>;
  /** Can view template */
  viewTemplate?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type ThemeCreateMutationInput = {
  /** label */
  name?: Maybe<Scalars['String']>;
  /** Theme configuration */
  configuration?: Maybe<Scalars['JSONString']>;
  cover?: Maybe<Scalars['UploadType']>;
  logoOnDark?: Maybe<Scalars['UploadType']>;
  logoOnLight?: Maybe<Scalars['UploadType']>;
  logoSquare?: Maybe<Scalars['UploadType']>;
  /** Enable theme in dashboard */
  enableInDashboard: Scalars['Boolean'];
  /** Set list of companys */
  companySet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  courseSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ThemeCreateMutationPayload = {
  __typename?: 'ThemeCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  theme?: Maybe<ThemeNode>;
};

export type ThemeDeleteMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ThemeDeleteMutationPayload = {
  __typename?: 'ThemeDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  theme?: Maybe<ThemeNode>;
};

export type ThemeNode = Node & {
  __typename?: 'ThemeNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  /** label */
  name?: Maybe<Scalars['String']>;
  cover?: Maybe<ImageFiledType>;
  logoOnDark?: Maybe<ImageFiledType>;
  logoOnLight?: Maybe<ImageFiledType>;
  logoSquare?: Maybe<ImageFiledType>;
  /** Enable theme in dashboard */
  enableInDashboard: Scalars['Boolean'];
  /** Theme configuration */
  configuration?: Maybe<Scalars['JSONString']>;
  courseSet: CourseNodeConnection;
  companySet: CompanyNodeConnection;
  userHasPermission?: Maybe<ThemePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type ThemeNodeCourseSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type ThemeNodeCompanySetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type ThemeNodeConnection = {
  __typename?: 'ThemeNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ThemeNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ThemeNode` and its cursor. */
export type ThemeNodeEdge = {
  __typename?: 'ThemeNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ThemeNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ThemePermission = {
  __typename?: 'ThemePermission';
  /** Can add Theme */
  addTheme?: Maybe<Scalars['Boolean']>;
  /** Can change Theme */
  changeTheme?: Maybe<Scalars['Boolean']>;
  /** Can delete Theme */
  deleteTheme?: Maybe<Scalars['Boolean']>;
  /** Can view Theme */
  viewTheme?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type ThemeUpdateMutationInput = {
  /** label */
  name?: Maybe<Scalars['String']>;
  /** Theme configuration */
  configuration?: Maybe<Scalars['JSONString']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  cover?: Maybe<Scalars['UploadType']>;
  logoOnDark?: Maybe<Scalars['UploadType']>;
  logoOnLight?: Maybe<Scalars['UploadType']>;
  logoSquare?: Maybe<Scalars['UploadType']>;
  /** Enable theme in dashboard */
  enableInDashboard?: Maybe<Scalars['Boolean']>;
  /** Set list of companys */
  companySet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  courseSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ThemeUpdateMutationPayload = {
  __typename?: 'ThemeUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  theme?: Maybe<ThemeNode>;
};

export enum ThumbnailColorspace {
  RGB = 'RGB',
  GRAY = 'GRAY',
}

export enum ThumbnailFormat {
  JPEG = 'JPEG',
  PNG = 'PNG',
}

export type ThumbnailType = {
  __typename?: 'ThumbnailType';
  /** error */
  error?: Maybe<Scalars['String']>;
  /** url */
  url?: Maybe<Scalars['String']>;
  /** exist */
  exists?: Maybe<Scalars['Boolean']>;
  /** is_portrait */
  isPortrait?: Maybe<Scalars['Boolean']>;
  /** width */
  width?: Maybe<Scalars['Int']>;
  /** height */
  height?: Maybe<Scalars['Int']>;
  /** ratio */
  ratio?: Maybe<Scalars['Float']>;
};

export type UpdateBillingDataMutationInput = {
  /** Company */
  company: Scalars['ID'];
  /** Customer name */
  customerName: Scalars['String'];
  /** SDI */
  sdi?: Maybe<Scalars['String']>;
  /** phone */
  phone?: Maybe<Scalars['String']>;
  /** Address */
  address: AddressInput;
  /** tax */
  tax: TaxInput;
};

/** update company billing */
export type UpdateBillingMutation = {
  __typename?: 'UpdateBillingMutation';
  /** List of errors that occurred executing the mutation. */
  errors?: Maybe<Array<Error>>;
  success: Scalars['Boolean'];
  company?: Maybe<CompanyNode>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

export type UserCourseCertificate = {
  __typename?: 'UserCourseCertificate';
  user: UserNode;
  certificate: CertificateNode;
  courseModuleAssessment?: Maybe<CourseModuleAssessmentNode>;
  courseParticipation?: Maybe<CourseParticipationNode>;
};

export type UserDeactivateMutationInput = {
  /** Deactivation code */
  deactivationCode: Scalars['String'];
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserDeactivateMutationPayload = {
  __typename?: 'UserDeactivateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserNode>;
};

export type UserDeleteMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserDeleteMutationPayload = {
  __typename?: 'UserDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserNode>;
};

/** A Relay edge containing a `User` and its cursor. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type UserModuleAssessmentCreateInput = {
  moduleId: Scalars['ID'];
  participationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserModuleAssessmentCreatePayload = {
  __typename?: 'UserModuleAssessmentCreatePayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  moduleAssessment?: Maybe<CourseModuleAssessmentUnion>;
  module?: Maybe<CourseModuleUnion>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserModuleAssessmentGateCheckInput = {
  moduleId: Scalars['ID'];
  participationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserModuleAssessmentGateCheckPayload = {
  __typename?: 'UserModuleAssessmentGateCheckPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  moduleAssessment?: Maybe<CourseModuleAssessmentUnion>;
  module?: Maybe<CourseModuleUnion>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserModuleAssessmentSetCompletedInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserModuleAssessmentSetCompletedPayload = {
  __typename?: 'UserModuleAssessmentSetCompletedPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  moduleAssessment?: Maybe<CourseModuleAssessmentUnion>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseModuleAssessment?: Maybe<CourseModuleAssessmentNode>;
};

export type UserModuleAssessmentStartInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserModuleAssessmentStartPayload = {
  __typename?: 'UserModuleAssessmentStartPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  moduleAssessment?: Maybe<CourseModuleAssessmentUnion>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseModuleAssessment?: Maybe<CourseModuleAssessmentNode>;
};

export type UserModuleAssessmentSurveyCreateNewResponseInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserModuleAssessmentSurveyCreateNewResponsePayload = {
  __typename?: 'UserModuleAssessmentSurveyCreateNewResponsePayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  moduleAssessment?: Maybe<CourseModuleAssessmentUnion>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseModuleAssessment?: Maybe<CourseModuleAssessmentNode>;
};

export type UserNode = Node & {
  __typename?: 'UserNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  password: Scalars['String'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  groups: GroupNodeConnection;
  /** Specific permissions for this user. */
  userPermissions: PermissionNodeConnection;
  firebaseUid: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  isActive?: Maybe<Scalars['Boolean']>;
  dateDeactivation?: Maybe<Scalars['DateTime']>;
  dateJoined: Scalars['DateTime'];
  anonymousUser: Scalars['Boolean'];
  avatarSet: AvatarNodeConnection;
  fileSet: FileNodeConnection;
  imageSet: ImageNodeConnection;
  profile?: Maybe<ProfileNode>;
  preferences?: Maybe<PreferencesNode>;
  assessmentSessions: AssessmentSessionNodeConnection;
  surveyresponseSet: SurveyResponseNodeConnection;
  presenceSet: PresenceNodeConnection;
  participations: CourseParticipationNodeConnection;
  managerParticipations: CourseParticipationNodeConnection;
  coursesCreated: CourseNodeConnection;
  coursesUsersParticipationQuotas: CourseUserParticipationQuotasNodeConnection;
  courseinvitationSet: CourseInvitationNodeConnection;
  companySet: CompanyNodeConnection;
  companyuserinvitationSet: CompanyUserInvitationNodeConnection;
  avatar?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  isAnonymous?: Maybe<Scalars['Boolean']>;
  userHasPermission?: Maybe<UserPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type UserNodeGroupsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserNodeUserPermissionsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserNodeAvatarSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserNodeFileSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserNodeImageSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserNodeAssessmentSessionsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
  partial?: Maybe<Scalars['Boolean']>;
};

export type UserNodeSurveyresponseSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  surveyType?: Maybe<Scalars['String']>;
  partial?: Maybe<Scalars['Boolean']>;
  survey?: Maybe<Scalars['String']>;
};

export type UserNodePresenceSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserNodeParticipationsArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
  manager?: Maybe<Scalars['ID']>;
  course?: Maybe<Scalars['ID']>;
  status?: Maybe<CourseParticipationStatus>;
  partial?: Maybe<Scalars['Boolean']>;
  course_Company?: Maybe<Scalars['ID']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  hasCertificate?: Maybe<Scalars['Boolean']>;
};

export type UserNodeManagerParticipationsArgs = {
  orderby?: Maybe<Array<Maybe<Scalars['String']>>>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
  manager?: Maybe<Scalars['ID']>;
  course?: Maybe<Scalars['ID']>;
  status?: Maybe<CourseParticipationStatus>;
  partial?: Maybe<Scalars['Boolean']>;
  course_Company?: Maybe<Scalars['ID']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  hasCertificate?: Maybe<Scalars['Boolean']>;
};

export type UserNodeCoursesCreatedArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
};

export type UserNodeCoursesUsersParticipationQuotasArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserNodeCourseinvitationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  owner?: Maybe<Scalars['ID']>;
  course?: Maybe<Scalars['ID']>;
};

export type UserNodeCompanySetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type UserNodeCompanyuserinvitationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type UserNodeAvatarArgs = {
  size?: Maybe<Scalars['Int']>;
};

export type UserNodeConnection = {
  __typename?: 'UserNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename?: 'UserNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type UserPermission = {
  __typename?: 'UserPermission';
  /** Can add user */
  addUser?: Maybe<Scalars['Boolean']>;
  /** Can change user */
  changeUser?: Maybe<Scalars['Boolean']>;
  /** Can delete user */
  deleteUser?: Maybe<Scalars['Boolean']>;
  /** Can view user */
  viewUser?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type UserPermissions = {
  __typename?: 'UserPermissions';
  permissions?: Maybe<Array<Scalars['String']>>;
  user?: Maybe<UserNode>;
};

export type UserRequestDeactivationMutationInput = {
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserRequestDeactivationMutationPayload = {
  __typename?: 'UserRequestDeactivationMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  requestDeactivationSent?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserNode>;
};

export type UserUpdateMutationInput = {
  password?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  firebaseUid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  dateDeactivation?: Maybe<Scalars['DateTime']>;
  dateJoined?: Maybe<Scalars['DateTime']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser?: Maybe<Scalars['Boolean']>;
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specific permissions for this user. */
  userPermissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Designates whether the user can log into this admin site. */
  isStaff?: Maybe<Scalars['Boolean']>;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive?: Maybe<Scalars['Boolean']>;
  anonymousUser?: Maybe<Scalars['Boolean']>;
  /** Set list of company user invitations */
  companyuserinvitationSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of files */
  fileSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of images */
  imageSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course participations */
  managerParticipations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of presences */
  presenceSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of sent notifications */
  sentnotificationSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of SurveyResponses */
  surveyresponseSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserUpdateMutationPayload = {
  __typename?: 'UserUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  user?: Maybe<UserNode>;
};

export type UserVideoModuleAssessmentUpdateMutationInput = {
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CourseModuleAssessmentStatus>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  videoPlayedSeconds?: Maybe<Scalars['Float']>;
  coursemoduleassessmentPtr?: Maybe<Scalars['ID']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  courseParticipation?: Maybe<Scalars['ID']>;
  courseModule?: Maybe<Scalars['ID']>;
  certificate?: Maybe<Scalars['ID']>;
  certificates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course module certificate template maps */
  coursemodulecertificatetemplatemapSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserVideoModuleAssessmentUpdateMutationPayload = {
  __typename?: 'UserVideoModuleAssessmentUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  courseModuleAssessmentVideo?: Maybe<CourseModuleAssessmentVideoNode>;
};

export type VerificationCodeMatchInput = {
  /** verification code */
  code: Scalars['String'];
  /** email */
  email: Scalars['String'];
};

export type VerificationCodeMatchSuccess = {
  __typename?: 'VerificationCodeMatchSuccess';
  /** List of errors that occurred executing the mutation. */
  errors?: Maybe<Array<Error>>;
  match: Scalars['Boolean'];
  verificationLink?: Maybe<Scalars['String']>;
};

export type VideoModuleCreateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  url?: Maybe<Scalars['String']>;
  videoDuration?: Maybe<Scalars['Float']>;
  requiredUserLivePercentile?: Maybe<Scalars['Int']>;
  coursemodulePtr?: Maybe<Scalars['ID']>;
  /** The ID of the object. */
  id?: Maybe<Scalars['ID']>;
  course: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  iframeMode?: Maybe<Scalars['Boolean']>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type VideoModuleCreateMutationPayload = {
  __typename?: 'VideoModuleCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  videoModule?: Maybe<VideoModuleNode>;
};

export type VideoModuleNode = Node & {
  __typename?: 'VideoModuleNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  order: Scalars['Int'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  course: CourseNode;
  parent?: Maybe<CourseModuleNode>;
  requiredModule?: Maybe<CourseModuleNode>;
  routable: Scalars['Boolean'];
  routableExact: Scalars['Boolean'];
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** If true only staff user can test module (not locked) */
  testingMode: Scalars['Boolean'];
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete: Scalars['Boolean'];
  /** Exclude module in participation check completed */
  disableCheckComplete: Scalars['Boolean'];
  /** Display complete/continue button in module */
  displayCompleteButton: Scalars['Boolean'];
  /** send email when module is completed */
  sendEmailWhenCompleted: Scalars['Boolean'];
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug: Scalars['String'];
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  /** Force module locked for all participants */
  forceLocked: Scalars['Boolean'];
  accessMode: CourseModuleAccessMode;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy: CourseModulePrivacy;
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  coursemodulePtr: CourseModuleNode;
  url?: Maybe<Scalars['String']>;
  videoDuration?: Maybe<Scalars['Float']>;
  requiredUserLivePercentile?: Maybe<Scalars['Int']>;
  iframeMode: Scalars['Boolean'];
  userModuleAssessment?: Maybe<CourseModuleAssessmentUnion>;
  submodules?: Maybe<CourseModuleConnection>;
  videowall?: Maybe<VideowallModuleNode>;
  frontendUrl?: Maybe<Scalars['String']>;
  authors?: Maybe<AuthorModuleConnection>;
  reminders?: Maybe<ReminderNodeConnection>;
  /** The total live count objects in this query. */
  assessmentsCounter?: Maybe<Scalars['Int']>;
  getAccessModeDisplay?: Maybe<Scalars['String']>;
  getPrivacyDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<VideoModulePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type VideoModuleNodeUserModuleAssessmentArgs = {
  userId?: Maybe<Scalars['ID']>;
};

export type VideoModuleNodeSubmodulesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type VideoModuleNodeAuthorsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type VideoModuleNodeRemindersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type VideoModulePermission = {
  __typename?: 'VideoModulePermission';
  /** Can add video module */
  addVideomodule?: Maybe<Scalars['Boolean']>;
  /** Can change video module */
  changeVideomodule?: Maybe<Scalars['Boolean']>;
  /** Can delete video module */
  deleteVideomodule?: Maybe<Scalars['Boolean']>;
  /** Can view video module */
  viewVideomodule?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type VideoModuleUpdateMutationInput = {
  order?: Maybe<Scalars['Int']>;
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug?: Maybe<Scalars['String']>;
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  accessMode?: Maybe<CourseModuleAccessMode>;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy?: Maybe<CourseModulePrivacy>;
  url?: Maybe<Scalars['String']>;
  videoDuration?: Maybe<Scalars['Float']>;
  requiredUserLivePercentile?: Maybe<Scalars['Int']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  requiredModule?: Maybe<Scalars['ID']>;
  routable?: Maybe<Scalars['Boolean']>;
  routableExact?: Maybe<Scalars['Boolean']>;
  /** If true only staff user can test module (not locked) */
  testingMode?: Maybe<Scalars['Boolean']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete?: Maybe<Scalars['Boolean']>;
  /** Exclude module in participation check completed */
  disableCheckComplete?: Maybe<Scalars['Boolean']>;
  /** Display complete/continue button in module */
  displayCompleteButton?: Maybe<Scalars['Boolean']>;
  /** send email when module is completed */
  sendEmailWhenCompleted?: Maybe<Scalars['Boolean']>;
  authors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Force module locked for all participants */
  forceLocked?: Maybe<Scalars['Boolean']>;
  reminders?: Maybe<Array<Maybe<Scalars['ID']>>>;
  certificateTemplate?: Maybe<Scalars['ID']>;
  certificateTemplates?: Maybe<Array<Maybe<Scalars['ID']>>>;
  iframeMode?: Maybe<Scalars['Boolean']>;
  /** Set list of course modules */
  dependants?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of Courses */
  featuredModuleCourses?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of course modules */
  submodules?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Set list of survey modules */
  trainingFor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type VideoModuleUpdateMutationPayload = {
  __typename?: 'VideoModuleUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  videoModule?: Maybe<VideoModuleNode>;
};

export type VideoModuleUserActionsMutationInput = {
  action: Scalars['String'];
  moduleId: Scalars['ID'];
  assessmentId: Scalars['ID'];
  playedSeconds?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type VideoModuleUserActionsMutationPayload = {
  __typename?: 'VideoModuleUserActionsMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  livePercentile?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type VideowallModuleNode = Node & {
  __typename?: 'VideowallModuleNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  order: Scalars['Int'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  course: CourseNode;
  parent?: Maybe<CourseModuleNode>;
  requiredModule?: Maybe<CourseModuleNode>;
  routable: Scalars['Boolean'];
  routableExact: Scalars['Boolean'];
  /** module options */
  moduleOptions?: Maybe<Scalars['JSONString']>;
  /** whitelist user emails */
  whitelistEmail?: Maybe<Scalars['JSONString']>;
  /** blacklist user emails */
  blacklistEmail?: Maybe<Scalars['JSONString']>;
  /** A human-readable name for the module. (e.g. 'Numerical Methods') */
  name?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  overview?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  jsxContent?: Maybe<Scalars['String']>;
  /** If true only staff user can test module (not locked) */
  testingMode: Scalars['Boolean'];
  /** Name of material icon: https://fonts.google.com/icons?icon.set=Material+Icons */
  materialIcon?: Maybe<Scalars['String']>;
  /** Actually used on VideoModule, ChatModule, IframeModule, LayoutModule (only router layoutType) */
  disableAutoComplete: Scalars['Boolean'];
  /** Exclude module in participation check completed */
  disableCheckComplete: Scalars['Boolean'];
  /** Display complete/continue button in module */
  displayCompleteButton: Scalars['Boolean'];
  /** send email when module is completed */
  sendEmailWhenCompleted: Scalars['Boolean'];
  /** Used in module completed email */
  completedCustomEmailContent?: Maybe<Scalars['String']>;
  /** Used in module completed email */
  completedCustomEmailSubject?: Maybe<Scalars['String']>;
  /**
   * A course identifier. Alphanumeric with dashes, no spaces. This is visible in
   * URLs and determines the location on your file system where the course's git
   * repository lives. This should <em>not</em> be changed after the course has
   * been created without also moving the course's git on the server.
   */
  slug: Scalars['String'];
  availableFrom?: Maybe<Scalars['DateTime']>;
  availableUntil?: Maybe<Scalars['DateTime']>;
  /** Force module locked for all participants */
  forceLocked: Scalars['Boolean'];
  accessMode: CourseModuleAccessMode;
  /** Used when module in not available yet */
  availableFromCustomText?: Maybe<Scalars['String']>;
  /** Used when module is locked */
  moduleLockedCustomText?: Maybe<Scalars['String']>;
  privacy: CourseModulePrivacy;
  certificateTemplate?: Maybe<CertificateTemplateNode>;
  coursemodulePtr: CourseModuleNode;
  url?: Maybe<Scalars['String']>;
  userModuleAssessment?: Maybe<CourseModuleAssessmentUnion>;
  submodules?: Maybe<CourseModuleConnection>;
  videowall?: Maybe<VideowallModuleNode>;
  frontendUrl?: Maybe<Scalars['String']>;
  authors?: Maybe<AuthorModuleConnection>;
  reminders?: Maybe<ReminderNodeConnection>;
  /** The total live count objects in this query. */
  assessmentsCounter?: Maybe<Scalars['Int']>;
  urlWithParams?: Maybe<Scalars['String']>;
  getAccessModeDisplay?: Maybe<Scalars['String']>;
  getPrivacyDisplay?: Maybe<Scalars['String']>;
  userHasPermission?: Maybe<VideowallModulePermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type VideowallModuleNodeUserModuleAssessmentArgs = {
  userId?: Maybe<Scalars['ID']>;
};

export type VideowallModuleNodeSubmodulesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type VideowallModuleNodeAuthorsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type VideowallModuleNodeRemindersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type VideowallModulePermission = {
  __typename?: 'VideowallModulePermission';
  /** Can add videowall module */
  addVideowallmodule?: Maybe<Scalars['Boolean']>;
  /** Can change videowall module */
  changeVideowallmodule?: Maybe<Scalars['Boolean']>;
  /** Can delete videowall module */
  deleteVideowallmodule?: Maybe<Scalars['Boolean']>;
  /** Can view videowall module */
  viewVideowallmodule?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type WebinarModuleClearTimeIntervalsInput = {
  moduleId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type WebinarModuleClearTimeIntervalsPayload = {
  __typename?: 'WebinarModuleClearTimeIntervalsPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  status?: Maybe<Scalars['String']>;
  cleanedData?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type WebinarVariant = {
  __typename?: 'WebinarVariant';
  /** Live url */
  liveUrl: Scalars['String'];
  /** Recorded url */
  recordedUrl: Scalars['String'];
  /** Use iframe */
  iframeMode: Scalars['Boolean'];
  /** Field name in select */
  name: Scalars['String'];
  /** idx */
  idx: Scalars['Int'];
  iframeProps?: Maybe<Array<HtmlAttributeNode>>;
};

export type WebinarVariantInput = {
  /** Live url */
  liveUrl: Scalars['String'];
  /** Recorded url */
  recordedUrl: Scalars['String'];
  /** Use iframe */
  iframeMode: Scalars['Boolean'];
  /** Field name in select */
  name: Scalars['String'];
  /** idx */
  idx: Scalars['Int'];
  iframeProps?: Maybe<Array<HtmlAttributeInput>>;
};

export type WebLinkModuleConnection = {
  __typename?: 'WebLinkModuleConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WebLinkModuleEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `WebLinkModule` and its cursor. */
export type WebLinkModuleEdge = {
  __typename?: 'WebLinkModuleEdge';
  /** The item at the end of the edge */
  node?: Maybe<WebLinkNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type WebLinkNode = Node & {
  __typename?: 'WebLinkNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  label: Scalars['String'];
  url: Scalars['String'];
  companySet: CompanyNodeConnection;
  userHasPermission?: Maybe<WebLinkPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type WebLinkNodeCompanySetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type WebLinkPermission = {
  __typename?: 'WebLinkPermission';
  /** Can add web link */
  addWeblink?: Maybe<Scalars['Boolean']>;
  /** Can change web link */
  changeWeblink?: Maybe<Scalars['Boolean']>;
  /** Can delete web link */
  deleteWeblink?: Maybe<Scalars['Boolean']>;
  /** Can view web link */
  viewWeblink?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type ZoomAuthorizationNode = {
  __typename?: 'ZoomAuthorizationNode';
  token?: Maybe<Scalars['String']>;
  sdkKey?: Maybe<Scalars['String']>;
  meetingNo?: Maybe<Scalars['Int']>;
};

export type ZoomMeetingAuthorizeMutationInput = {
  meetingNo: Scalars['Float'];
  role: Scalars['Int'];
  companyId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ZoomMeetingAuthorizeMutationPayload = {
  __typename?: 'ZoomMeetingAuthorizeMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  authData?: Maybe<ZoomAuthorizationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ZoomMeetingCreateMutationInput = {
  topic: Scalars['String'];
  agenda?: Maybe<Scalars['String']>;
  /** start_time */
  startTime?: Maybe<Scalars['DateTime']>;
  type: Scalars['Int'];
  companyId: Scalars['ID'];
  webinarModuleId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ZoomMeetingCreateMutationPayload = {
  __typename?: 'ZoomMeetingCreateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  meeting?: Maybe<ZoomMeetingNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ZoomMeetingDeleteMutationInput = {
  companyId?: Maybe<Scalars['ID']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ZoomMeetingDeleteMutationPayload = {
  __typename?: 'ZoomMeetingDeleteMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  zoomMeeting?: Maybe<ZoomMeetingNode>;
};

export type ZoomMeetingNode = Node & {
  __typename?: 'ZoomMeetingNode';
  /** The ID of the object. */
  id: Scalars['ID'];
  ownership: CompanyNode;
  meetingNo: Scalars['String'];
  meetingPwd: Scalars['String'];
  topic?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  /** zoom meeting data */
  data?: Maybe<Scalars['JSONString']>;
  webinarModules: ExternalWebinarModuleNodeConnection;
  userHasPermission?: Maybe<ZoomMeetingPermission>;
  /** List of User with Permission. */
  getUsersWithPerms?: Maybe<Array<UserPermissions>>;
  /** List of Group with Permission. */
  getGroupsWithPerms?: Maybe<Array<GroupPermissions>>;
  /** List of available Permission. */
  availablePermissions?: Maybe<Array<PermissionNode>>;
};

export type ZoomMeetingNodeWebinarModulesArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ZoomMeetingNodeConnection = {
  __typename?: 'ZoomMeetingNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ZoomMeetingNodeEdge>>;
  /** The total count of objects in this query. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `ZoomMeetingNode` and its cursor. */
export type ZoomMeetingNodeEdge = {
  __typename?: 'ZoomMeetingNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<ZoomMeetingNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ZoomMeetingPermission = {
  __typename?: 'ZoomMeetingPermission';
  /** Can add zoom meeting */
  addZoommeeting?: Maybe<Scalars['Boolean']>;
  /** Can change zoom meeting */
  changeZoommeeting?: Maybe<Scalars['Boolean']>;
  /** Can delete zoom meeting */
  deleteZoommeeting?: Maybe<Scalars['Boolean']>;
  /** Can view zoom meeting */
  viewZoommeeting?: Maybe<Scalars['Boolean']>;
  /** Has any permission */
  anyPermission?: Maybe<Scalars['Boolean']>;
};

export type ZoomMeetingUpdateMutationInput = {
  topic?: Maybe<Scalars['String']>;
  agenda?: Maybe<Scalars['String']>;
  /** start_time */
  startTime: Scalars['DateTime'];
  webinarModuleId: Scalars['ID'];
  companyId?: Maybe<Scalars['ID']>;
  type: Scalars['Int'];
  /** The ID of the object. */
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ZoomMeetingUpdateMutationPayload = {
  __typename?: 'ZoomMeetingUpdateMutationPayload';
  /** List of errors that occurred while executing the mutation. */
  errors?: Maybe<Array<MutationErrorType>>;
  meeting?: Maybe<ZoomMeetingNode>;
  clientMutationId?: Maybe<Scalars['String']>;
  /** The mutated object. */
  zoomMeeting?: Maybe<ZoomMeetingNode>;
};

/**
 * One possible value for a given Enum. Enum values are unique values, not a
 * placeholder for a string or numeric value. However an Enum value is returned in
 * a JSON response as a string.
 */
export type EnumValue = {
  __typename?: '__EnumValue';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/**
 * Object and Interface types are described by a list of Fields, each of which has
 * a name, potentially a list of arguments, and a return type.
 */
export type Field = {
  __typename?: '__Field';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  args: Array<InputValue>;
  type: Type;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/**
 * Arguments provided to Fields or Directives and the input fields of an
 * InputObject are represented as Input Values which describe their type and
 * optionally a default value.
 */
export type InputValue = {
  __typename?: '__InputValue';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars['String']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of
 * types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that
 * type. Scalar types provide no information beyond a name and description, while
 * Enum types provide their values. Object and Interface types provide the fields
 * they describe. Abstract types, Union and Interface, provide the Object types
 * possible at runtime. List and NonNull types compose other types.
 */
export type Type = {
  __typename?: '__Type';
  kind: TypeKind;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Field>>;
  interfaces?: Maybe<Array<Type>>;
  possibleTypes?: Maybe<Array<Type>>;
  enumValues?: Maybe<Array<EnumValue>>;
  inputFields?: Maybe<Array<InputValue>>;
  ofType?: Maybe<Type>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of
 * types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that
 * type. Scalar types provide no information beyond a name and description, while
 * Enum types provide their values. Object and Interface types provide the fields
 * they describe. Abstract types, Union and Interface, provide the Object types
 * possible at runtime. List and NonNull types compose other types.
 */
export type TypeFieldsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of
 * types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that
 * type. Scalar types provide no information beyond a name and description, while
 * Enum types provide their values. Object and Interface types provide the fields
 * they describe. Abstract types, Union and Interface, provide the Object types
 * possible at runtime. List and NonNull types compose other types.
 */
export type TypeEnumValuesArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};

/** An enum describing what kind of type a given `__Type` is. */
export enum TypeKind {
  /** Indicates this type is a scalar. */
  SCALAR = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  OBJECT = 'OBJECT',
  /** Indicates this type is an interface. `fields` and `possibleTypes` are valid fields. */
  INTERFACE = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  UNION = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  ENUM = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  INPUT_OBJECT = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  LIST = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NON_NULL = 'NON_NULL',
}

export type AutocompleteCompaniesQueryVariables = Exact<{
  q: Scalars['String'];
}>;

export type AutocompleteCompaniesQuery = { __typename?: 'Query' } & {
  autocomplete?: Maybe<
    { __typename?: 'Autocomplete' } & {
      companies?: Maybe<
        { __typename?: 'BaseCompanyNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'BaseCompanyNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'BaseCompanyNode' } & Pick<
                    BaseCompanyNode,
                    'name' | 'id'
                  >
                >;
              }
            >
          >;
        }
      >;
    }
  >;
};

export type AutocompleteUsersQueryVariables = Exact<{
  q: Scalars['String'];
  anonymousUser?: Maybe<Scalars['Boolean']>;
  manageParticipationCourseId?: Maybe<Scalars['String']>;
}>;

export type AutocompleteUsersQuery = { __typename?: 'Query' } & {
  autocomplete?: Maybe<
    { __typename?: 'Autocomplete' } & {
      users?: Maybe<
        { __typename?: 'UserNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'UserNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'UserNode' } & Pick<
                    UserNode,
                    'displayName' | 'id' | 'email'
                  >
                >;
              }
            >
          >;
        }
      >;
    }
  >;
};

export type AutocompleteSurveyQueryVariables = Exact<{
  q: Scalars['String'];
  type_In?: Maybe<Array<Maybe<SurveyType>> | Maybe<SurveyType>>;
}>;

export type AutocompleteSurveyQuery = { __typename?: 'Query' } & {
  autocomplete?: Maybe<
    { __typename?: 'Autocomplete' } & {
      surveys?: Maybe<
        { __typename?: 'SurveyNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'SurveyNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'SurveyNode' } & Pick<
                    SurveyNode,
                    'id' | 'name'
                  >
                >;
              }
            >
          >;
        }
      >;
    }
  >;
};

export type AutocompleteTemplateQueryVariables = Exact<{
  q: Scalars['String'];
}>;

export type AutocompleteTemplateQuery = { __typename?: 'Query' } & {
  autocomplete?: Maybe<
    { __typename?: 'Autocomplete' } & {
      templates?: Maybe<
        { __typename?: 'DbtemplatesNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'DbtemplatesNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'DbtemplatesNode' } & Pick<
                    DbtemplatesNode,
                    'id' | 'name' | 'label'
                  >
                >;
              }
            >
          >;
        }
      >;
    }
  >;
};

export type AutocompleteLiveMeetingsQueryVariables = Exact<{
  q: Scalars['String'];
}>;

export type AutocompleteLiveMeetingsQuery = { __typename?: 'Query' } & {
  autocomplete?: Maybe<
    { __typename?: 'Autocomplete' } & {
      liveMeetings?: Maybe<
        { __typename?: 'ZoomMeetingNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'ZoomMeetingNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'ZoomMeetingNode' } & Pick<
                    ZoomMeetingNode,
                    'id' | 'meetingNo' | 'meetingPwd' | 'topic' | 'startTime'
                  >
                >;
              }
            >
          >;
        }
      >;
    }
  >;
};

export type TypeEnumValuesQueryVariables = Exact<{
  enumType: Scalars['String'];
}>;

export type TypeEnumValuesQuery = { __typename?: 'Query' } & {
  __type?: Maybe<
    { __typename?: '__Type' } & Pick<Type, 'name'> & {
        enumValues?: Maybe<
          Array<
            { __typename?: '__EnumValue' } & Pick<
              EnumValue,
              'name' | 'description'
            >
          >
        >;
      }
  >;
};

export type CourseModulesQueryVariables = Exact<{
  courseId: Scalars['ID'];
}>;

export type CourseModulesQuery = { __typename?: 'Query' } & {
  courseModules?: Maybe<
    { __typename?: 'CourseModuleNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'CourseModuleNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'CourseModuleNode' } & Pick<
                CourseModuleNode,
                | 'id'
                | 'name'
                | 'slug'
                | 'order'
                | 'routable'
                | 'routableExact'
                | 'privacy'
              > & {
                  parent?: Maybe<
                    { __typename?: 'CourseModuleNode' } & Pick<
                      CourseModuleNode,
                      'id'
                    >
                  >;
                  requiredModule?: Maybe<
                    { __typename?: 'CourseModuleNode' } & Pick<
                      CourseModuleNode,
                      'id'
                    >
                  >;
                  externalwebinarmodule?: Maybe<
                    { __typename?: 'ExternalWebinarModuleNode' } & Pick<
                      ExternalWebinarModuleNode,
                      'id'
                    >
                  >;
                  iframemodule?: Maybe<
                    { __typename?: 'IframeModuleNode' } & Pick<
                      IframeModuleNode,
                      'id'
                    >
                  >;
                  surveymodule?: Maybe<
                    { __typename?: 'SurveyModuleNode' } & Pick<
                      SurveyModuleNode,
                      'id'
                    >
                  >;
                  videomodule?: Maybe<
                    { __typename?: 'VideoModuleNode' } & Pick<
                      VideoModuleNode,
                      'id'
                    >
                  >;
                  layoutmodule?: Maybe<
                    { __typename?: 'LayoutModuleNode' } & Pick<
                      LayoutModuleNode,
                      'id'
                    >
                  >;
                  documentmodule?: Maybe<
                    { __typename?: 'DocumentModuleNode' } & Pick<
                      DocumentModuleNode,
                      'id'
                    >
                  >;
                  chatmodule?: Maybe<
                    { __typename?: 'ChatModuleNode' } & Pick<
                      ChatModuleNode,
                      'id'
                    >
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
};

export type CourseModuleUpdateParentMutationVariables = Exact<{
  input: CourseModuleUpdateMutationInput;
}>;

export type CourseModuleUpdateParentMutation = { __typename?: 'Mutation' } & {
  courseModuleUpdate?: Maybe<
    { __typename?: 'CourseModuleUpdateMutationPayload' } & {
      courseModule?: Maybe<
        { __typename?: 'CourseModuleNode' } & Pick<CourseModuleNode, 'id'> & {
            parent?: Maybe<
              { __typename?: 'CourseModuleNode' } & Pick<CourseModuleNode, 'id'>
            >;
          }
      >;
    }
  >;
};

export type CourseModuleDeleteMutationVariables = Exact<{
  input: CourseModuleDeleteMutationInput;
}>;

export type CourseModuleDeleteMutation = { __typename?: 'Mutation' } & {
  courseModuleDelete?: Maybe<
    { __typename?: 'CourseModuleDeleteMutationPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type ProfileQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'UserNode' } & Pick<
      UserNode,
      'id' | 'displayName' | 'email' | 'isStaff'
    > & { avatar300: UserNode['avatar'] } & {
        companySet: { __typename?: 'CompanyNodeConnection' } & Pick<
          CompanyNodeConnection,
          'totalCount'
        >;
        companyuserinvitationSet: {
          __typename?: 'CompanyUserInvitationNodeConnection';
        } & Pick<CompanyUserInvitationNodeConnection, 'totalCount'>;
      }
  >;
};

export type CompanyUserInvitationFragmentFragment = {
  __typename?: 'CompanyUserInvitationNode';
} & Pick<
  CompanyUserInvitationNode,
  'id' | 'email' | 'status' | 'sentAt' | 'repliedAt' | 'revokedAt' | 'expiredAt'
> & {
    company: { __typename?: 'CompanyNode' } & Pick<CompanyNode, 'id' | 'name'>;
  };

export type MyInvitationsFromCompaniesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type MyInvitationsFromCompaniesQuery = { __typename?: 'Query' } & {
  myInvitationsFromCompanies?: Maybe<
    { __typename?: 'CompanyUserInvitationNodeConnection' } & Pick<
      CompanyUserInvitationNodeConnection,
      'totalCount'
    > & {
        edges: Array<
          Maybe<
            { __typename?: 'CompanyUserInvitationNodeEdge' } & {
              node?: Maybe<
                {
                  __typename?: 'CompanyUserInvitationNode';
                } & CompanyUserInvitationFragmentFragment
              >;
            }
          >
        >;
      }
  >;
};

export type CompanyUserInvitationFromTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type CompanyUserInvitationFromTokenQuery = { __typename?: 'Query' } & {
  companyUserInvitationFromToken?: Maybe<
    { __typename?: 'CompanyUserInvitationNode' } & Pick<
      CompanyUserInvitationNode,
      | 'id'
      | 'email'
      | 'status'
      | 'sentAt'
      | 'repliedAt'
      | 'revokedAt'
      | 'expiredAt'
    > & {
        company: { __typename?: 'CompanyNode' } & Pick<
          CompanyNode,
          'id' | 'name'
        > & {
            baseLogo?: Maybe<
              { __typename?: 'ImageFiledType' } & Pick<
                ImageFiledType,
                'name' | 'url'
              > & {
                  thumb160?: Maybe<
                    { __typename?: 'ThumbnailType' } & Pick<
                      ThumbnailType,
                      'url'
                    >
                  >;
                }
            >;
          };
      }
  >;
};

export type MeStaffQueryVariables = Exact<{ [key: string]: never }>;

export type MeStaffQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'UserNode' } & Pick<
      UserNode,
      'id' | 'displayName' | 'email'
    > & { avatar300: UserNode['avatar'] }
  >;
};

export type SurveyResponseNodeStaffFragment = {
  __typename?: 'SurveyResponseNode';
} & {
  answers: { __typename?: 'AnswerNodeConnection' } & {
    edges: Array<
      Maybe<
        { __typename?: 'AnswerNodeEdge' } & {
          node?: Maybe<
            { __typename?: 'AnswerNode' } & Pick<AnswerNode, 'id' | 'data'> & {
                question?: Maybe<
                  { __typename?: 'QuestionNode' } & Pick<
                    QuestionNode,
                    'question' | 'id'
                  >
                >;
              }
          >;
        }
      >
    >;
  };
};

export type AllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type AllUsersQuery = { __typename?: 'Query' } & {
  allUsers?: Maybe<
    { __typename?: 'UserNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'UserNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'UserNode' } & Pick<
                UserNode,
                'id' | 'email' | 'displayName'
              >
            >;
          }
        >
      >;
    }
  >;
};

export type SendChannelMessageMutationVariables = Exact<{
  input: SendChannelMessageInput;
}>;

export type SendChannelMessageMutation = { __typename?: 'Mutation' } & {
  sendChannelMessage?: Maybe<
    { __typename?: 'SendChannelMessagePayload' } & Pick<
      SendChannelMessagePayload,
      'status'
    >
  >;
};

export type CompanyUserInvitationAcceptMutationVariables = Exact<{
  input: CompanyUserInvitationAcceptMutationInput;
}>;

export type CompanyUserInvitationAcceptMutation = {
  __typename?: 'Mutation';
} & {
  companyUserInvitationAccept?: Maybe<
    { __typename?: 'CompanyUserInvitationAcceptMutationPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
      companyUserInvitation?: Maybe<
        {
          __typename?: 'CompanyUserInvitationNode';
        } & CompanyUserInvitationFragmentFragment
      >;
    }
  >;
};

export type CompanyUserInvitationDeclineMutationVariables = Exact<{
  input: CompanyUserInvitationDeclineMutationInput;
}>;

export type CompanyUserInvitationDeclineMutation = {
  __typename?: 'Mutation';
} & {
  companyUserInvitationDecline?: Maybe<
    { __typename?: 'CompanyUserInvitationDeclineMutationPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
      companyUserInvitation?: Maybe<
        {
          __typename?: 'CompanyUserInvitationNode';
        } & CompanyUserInvitationFragmentFragment
      >;
    }
  >;
};

export type CompanyUserInvitationRevokeMutationVariables = Exact<{
  input: CompanyUserInvitationRevokeMutationInput;
}>;

export type CompanyUserInvitationRevokeMutation = {
  __typename?: 'Mutation';
} & {
  companyUserInvitationRevoke?: Maybe<
    { __typename?: 'CompanyUserInvitationRevokeMutationPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
      companyUserInvitation?: Maybe<
        {
          __typename?: 'CompanyUserInvitationNode';
        } & CompanyUserInvitationFragmentFragment
      >;
    }
  >;
};

export type CompanyUserInvitationConnectUserMutationVariables = Exact<{
  input: CompanyUserInvitationConnectMutationInput;
}>;

export type CompanyUserInvitationConnectUserMutation = {
  __typename?: 'Mutation';
} & {
  companyUserInvitationConnectUser?: Maybe<
    { __typename?: 'CompanyUserInvitationConnectMutationPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
      companyUserInvitation?: Maybe<
        { __typename?: 'CompanyUserInvitationNode' } & Pick<
          CompanyUserInvitationNode,
          | 'id'
          | 'token'
          | 'email'
          | 'firstName'
          | 'lastName'
          | 'status'
          | 'sentAt'
          | 'repliedAt'
          | 'revokedAt'
          | 'expiredAt'
        > & {
            company: { __typename?: 'CompanyNode' } & Pick<
              CompanyNode,
              'id' | 'name'
            > & {
                baseLogo?: Maybe<
                  { __typename?: 'ImageFiledType' } & Pick<
                    ImageFiledType,
                    'name' | 'url'
                  > & {
                      thumb160?: Maybe<
                        { __typename?: 'ThumbnailType' } & Pick<
                          ThumbnailType,
                          'url'
                        >
                      >;
                    }
                >;
              };
          }
      >;
    }
  >;
};

export type AuthorFieldsFragment = { __typename?: 'AuthorNode' } & Pick<
  AuthorNode,
  'id' | 'title' | 'firstName' | 'lastName' | 'headline' | 'shortBio'
> & {
    avatar?: Maybe<
      { __typename?: 'ImageFiledType' } & {
        thumbnail?: Maybe<
          { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
        >;
      }
    >;
  };

export type AuthorUpdateMutationVariables = Exact<{
  input: AuthorUpdateMutationInput;
}>;

export type AuthorUpdateMutation = { __typename?: 'Mutation' } & {
  authorUpdate?: Maybe<
    { __typename?: 'AuthorUpdateMutationPayload' } & {
      author?: Maybe<{ __typename?: 'AuthorNode' } & AuthorFieldsFragment>;
    }
  >;
};

export type AuthorCreateMutationVariables = Exact<{
  input: AuthorCreateMutationInput;
}>;

export type AuthorCreateMutation = { __typename?: 'Mutation' } & {
  authorCreate?: Maybe<
    { __typename?: 'AuthorCreateMutationPayload' } & {
      author?: Maybe<{ __typename?: 'AuthorNode' } & AuthorFieldsFragment>;
    }
  >;
};

export type AuthorsGroupCreateMutationVariables = Exact<{
  input: AuthorsGroupCreateMutationInput;
}>;

export type AuthorsGroupCreateMutation = { __typename?: 'Mutation' } & {
  authorsGroupCreate?: Maybe<
    { __typename?: 'AuthorsGroupCreateMutationPayload' } & {
      authorsGroup?: Maybe<
        { __typename?: 'AuthorsGroupNode' } & Pick<
          AuthorsGroupNode,
          'id' | 'name' | 'description'
        >
      >;
    }
  >;
};

export type AuthorsGroupUpdateMutationVariables = Exact<{
  input: AuthorsGroupUpdateMutationInput;
}>;

export type AuthorsGroupUpdateMutation = { __typename?: 'Mutation' } & {
  authorsGroupUpdate?: Maybe<
    { __typename?: 'AuthorsGroupUpdateMutationPayload' } & {
      authorsGroup?: Maybe<
        { __typename?: 'AuthorsGroupNode' } & Pick<
          AuthorsGroupNode,
          'id' | 'name' | 'description'
        >
      >;
    }
  >;
};

export type CertificateTemplateCreateMutationVariables = Exact<{
  input: CertificateTemplateCreateMutationInput;
}>;

export type CertificateTemplateCreateMutation = { __typename?: 'Mutation' } & {
  certificateTemplateCreate?: Maybe<
    { __typename?: 'CertificateTemplateCreateMutationPayload' } & {
      certificateTemplate?: Maybe<
        { __typename?: 'CertificateTemplateNode' } & Pick<
          CertificateTemplateNode,
          'id' | 'emailBody' | 'customSubject' | 'certificateFileName'
        > & {
            file?: Maybe<
              { __typename?: 'FileFiledType' } & Pick<
                FileFiledType,
                'url' | 'name'
              >
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CertificateTemplateUpdateMutationVariables = Exact<{
  input: CertificateTemplateUpdateMutationInput;
}>;

export type CertificateTemplateUpdateMutation = { __typename?: 'Mutation' } & {
  certificateTemplateUpdate?: Maybe<
    { __typename?: 'CertificateTemplateUpdateMutationPayload' } & {
      certificateTemplate?: Maybe<
        { __typename?: 'CertificateTemplateNode' } & Pick<
          CertificateTemplateNode,
          'emailBody' | 'customSubject' | 'certificateFileName' | 'id'
        > & {
            file?: Maybe<
              { __typename?: 'FileFiledType' } & Pick<
                FileFiledType,
                'url' | 'name'
              >
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CourseModuleCertificateMapsCreateMutationVariables = Exact<{
  input: CourseModuleCertificateMapsCreateMutationInput;
}>;

export type CourseModuleCertificateMapsCreateMutation = {
  __typename?: 'Mutation';
} & {
  courseModuleCertificateMapsCreate?: Maybe<
    { __typename?: 'CourseModuleCertificateMapsCreateMutationPayload' } & {
      courseModuleCertificateTemplateMap?: Maybe<
        { __typename?: 'CourseModuleCertificateTemplateMapNode' } & Pick<
          CourseModuleCertificateTemplateMapNode,
          | 'id'
          | 'sendMail'
          | 'generationRole'
          | 'availableFrom'
          | 'availableUntil'
        > & {
            certificateTemplate: {
              __typename?: 'CertificateTemplateNode';
            } & Pick<
              CertificateTemplateNode,
              'emailBody' | 'customSubject' | 'certificateFileName'
            > & {
                file?: Maybe<
                  { __typename?: 'FileFiledType' } & Pick<
                    FileFiledType,
                    'url' | 'name'
                  >
                >;
              };
            coursemodule: { __typename?: 'CourseModuleNode' } & Pick<
              CourseModuleNode,
              'id'
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CourseModuleCertificateMapsUpdateMutationVariables = Exact<{
  input: CourseModuleCertificateMapsUpdateMutationInput;
}>;

export type CourseModuleCertificateMapsUpdateMutation = {
  __typename?: 'Mutation';
} & {
  courseModuleCertificateMapsUpdate?: Maybe<
    { __typename?: 'CourseModuleCertificateMapsUpdateMutationPayload' } & {
      courseModuleCertificateTemplateMap?: Maybe<
        { __typename?: 'CourseModuleCertificateTemplateMapNode' } & Pick<
          CourseModuleCertificateTemplateMapNode,
          | 'id'
          | 'sendMail'
          | 'generationRole'
          | 'availableFrom'
          | 'availableUntil'
        > & {
            certificateTemplate: {
              __typename?: 'CertificateTemplateNode';
            } & Pick<
              CertificateTemplateNode,
              'emailBody' | 'customSubject' | 'certificateFileName'
            > & {
                file?: Maybe<
                  { __typename?: 'FileFiledType' } & Pick<
                    FileFiledType,
                    'url' | 'name'
                  >
                >;
              };
            coursemodule: { __typename?: 'CourseModuleNode' } & Pick<
              CourseModuleNode,
              'id'
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CourseModuleCertificateMapsDeleteMutationVariables = Exact<{
  input: CourseModuleCertificateMapsDeleteMutationInput;
}>;

export type CourseModuleCertificateMapsDeleteMutation = {
  __typename?: 'Mutation';
} & {
  courseModuleCertificateMapsDelete?: Maybe<
    { __typename?: 'CourseModuleCertificateMapsDeleteMutationPayload' } & {
      courseModuleCertificateTemplateMap?: Maybe<
        { __typename?: 'CourseModuleCertificateTemplateMapNode' } & Pick<
          CourseModuleCertificateTemplateMapNode,
          'id'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CourseCertificateMapsCreateMutationVariables = Exact<{
  input: CourseCertificateMapsCreateMutationInput;
}>;

export type CourseCertificateMapsCreateMutation = {
  __typename?: 'Mutation';
} & {
  courseCertificateMapsCreate?: Maybe<
    { __typename?: 'CourseCertificateMapsCreateMutationPayload' } & {
      courseCertificateTemplateMap?: Maybe<
        { __typename?: 'CourseCertificateTemplateMapNode' } & Pick<
          CourseCertificateTemplateMapNode,
          | 'id'
          | 'sendMail'
          | 'generationRole'
          | 'availableFrom'
          | 'availableUntil'
        > & {
            certificateTemplate: {
              __typename?: 'CertificateTemplateNode';
            } & Pick<
              CertificateTemplateNode,
              'emailBody' | 'customSubject' | 'certificateFileName'
            > & {
                file?: Maybe<
                  { __typename?: 'FileFiledType' } & Pick<
                    FileFiledType,
                    'url' | 'name'
                  >
                >;
              };
            course: { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'>;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CourseCertificateMapsUpdateMutationVariables = Exact<{
  input: CourseCertificateMapsUpdateMutationInput;
}>;

export type CourseCertificateMapsUpdateMutation = {
  __typename?: 'Mutation';
} & {
  courseCertificateMapsUpdate?: Maybe<
    { __typename?: 'CourseCertificateMapsUpdateMutationPayload' } & {
      courseCertificateTemplateMap?: Maybe<
        { __typename?: 'CourseCertificateTemplateMapNode' } & Pick<
          CourseCertificateTemplateMapNode,
          | 'id'
          | 'sendMail'
          | 'generationRole'
          | 'availableFrom'
          | 'availableUntil'
        > & {
            certificateTemplate: {
              __typename?: 'CertificateTemplateNode';
            } & Pick<
              CertificateTemplateNode,
              'emailBody' | 'customSubject' | 'certificateFileName'
            > & {
                file?: Maybe<
                  { __typename?: 'FileFiledType' } & Pick<
                    FileFiledType,
                    'url' | 'name'
                  >
                >;
              };
            course: { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'>;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CourseCertificateMapsDeleteMutationVariables = Exact<{
  input: CourseCertificateMapsDeleteMutationInput;
}>;

export type CourseCertificateMapsDeleteMutation = {
  __typename?: 'Mutation';
} & {
  courseCertificateMapsDelete?: Maybe<
    { __typename?: 'CourseCertificateMapsDeleteMutationPayload' } & {
      courseCertificateTemplateMap?: Maybe<
        { __typename?: 'CourseCertificateTemplateMapNode' } & Pick<
          CourseCertificateTemplateMapNode,
          'id'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CertificateEmailTestMutationVariables = Exact<{
  input: CertificateEmailTestMutationInput;
}>;

export type CertificateEmailTestMutation = { __typename?: 'Mutation' } & {
  certificateEmailTest?: Maybe<
    { __typename?: 'CertificateEmailTestMutationPayload' } & Pick<
      CertificateEmailTestMutationPayload,
      'status'
    >
  >;
};

export type CourseModuleUpdateJsxContentMutationVariables = Exact<{
  input: CourseModuleUpdateMutationInput;
}>;

export type CourseModuleUpdateJsxContentMutation = {
  __typename?: 'Mutation';
} & {
  courseModuleUpdate?: Maybe<
    { __typename?: 'CourseModuleUpdateMutationPayload' } & {
      courseModule?: Maybe<
        { __typename?: 'CourseModuleNode' } & Pick<
          CourseModuleNode,
          'id' | 'jsxContent'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CourseParticipationImportFileUpdateMutationVariables = Exact<{
  input: CourseParticipationImportFileUpdateMutationInput;
}>;

export type CourseParticipationImportFileUpdateMutation = {
  __typename?: 'Mutation';
} & {
  courseParticipationImportFileUpdate?: Maybe<
    { __typename?: 'CourseParticipationImportFileUpdateMutationPayload' } & {
      courseParticipationImportFile?: Maybe<
        { __typename?: 'CourseParticipationImportFileNode' } & Pick<
          CourseParticipationImportFileNode,
          'id'
        > & {
            course: { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'>;
            file?: Maybe<
              { __typename?: 'FileFiledType' } & Pick<
                FileFiledType,
                'fileName' | 'url' | 'extension'
              >
            >;
          }
      >;
    }
  >;
};

export type CourseParticipationImportFileCreateMutationVariables = Exact<{
  input: CourseParticipationImportFileCreateMutationInput;
}>;

export type CourseParticipationImportFileCreateMutation = {
  __typename?: 'Mutation';
} & {
  courseParticipationImportFileCreate?: Maybe<
    { __typename?: 'CourseParticipationImportFileCreateMutationPayload' } & {
      courseParticipationImportFile?: Maybe<
        { __typename?: 'CourseParticipationImportFileNode' } & Pick<
          CourseParticipationImportFileNode,
          'id'
        > & {
            course: { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'>;
            file?: Maybe<
              { __typename?: 'FileFiledType' } & Pick<
                FileFiledType,
                'fileName' | 'url' | 'extension'
              >
            >;
          }
      >;
    }
  >;
};

export type CourseParticipationApplyImportMutationVariables = Exact<{
  input: CourseParticipationApplyImportMutationInput;
}>;

export type CourseParticipationApplyImportMutation = {
  __typename?: 'Mutation';
} & {
  courseParticipationApplyImport?: Maybe<
    { __typename?: 'CourseParticipationApplyImportMutationPayload' } & {
      result?: Maybe<
        { __typename?: 'ImportResultNode' } & Pick<
          ImportResultNode,
          'errorCount' | 'errorEmails' | 'updateCount' | 'importCount'
        >
      >;
    }
  >;
};

export type CourseFieldsFragment = { __typename?: 'CourseNode' } & Pick<
  CourseNode,
  | 'id'
  | 'name'
  | 'headline'
  | 'overview'
  | 'demo'
  | 'code'
  | 'label'
  | 'requireDataParticipation'
  | 'fromEmailSenderName'
  | 'emailExtraContent'
  | 'enableConfirmParticipationEmail'
  | 'enablePendingParticipationEmail'
  | 'automaticallyAssignManageParticipationsPerm'
  | 'password'
  | 'pdfProgramButtonLabel'
  | 'description'
  | 'layoutType'
  | 'navigationType'
  | 'jsxContent'
  | 'defaultLanguage'
  | 'displayParticipantsCounter'
  | 'displayContinueButton'
  | 'acceptAnonyumousEnrollment'
  | 'forceAnonyumousEnrollment'
  | 'onlyOneParticipationPerInvitation'
  | 'automaticallyAcceptsParticipation'
  | 'acceptsParticipationByExternalUuid'
  | 'externalUuidParameterName'
  | 'externalUuidParameterRequired'
  | 'generateParticipationQrcode'
  | 'participationExternalLink'
  | 'slug'
  | 'listed'
  | 'startDate'
  | 'available'
  | 'endDate'
> & {
    participationForm?: Maybe<
      { __typename?: 'SurveyNode' } & Pick<SurveyNode, 'id' | 'name' | 'label'>
    >;
    owner: { __typename?: 'UserNode' } & Pick<
      UserNode,
      'id' | 'displayName' | 'email'
    >;
    pdfProgram?: Maybe<
      { __typename?: 'FileFiledType' } & Pick<
        FileFiledType,
        'fileName' | 'url' | 'extension'
      >
    >;
    userHasPermission?: Maybe<
      { __typename?: 'CoursePermission' } & Pick<
        CoursePermission,
        | 'viewCourse'
        | 'changeCourse'
        | 'moderateChatCourse'
        | 'manageParticipationCourse'
        | 'addParticipationCourse'
        | 'manageInvitationsCourse'
        | 'downloadFullReport'
        | 'anyPermission'
      >
    >;
    theme?: Maybe<
      { __typename?: 'ThemeNode' } & Pick<
        ThemeNode,
        'id' | 'name' | 'enableInDashboard'
      >
    >;
    templates: { __typename?: 'DbtemplatesNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'DbtemplatesNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'DbtemplatesNode' } & Pick<
                DbtemplatesNode,
                'id' | 'name' | 'label'
              >
            >;
          }
        >
      >;
    };
    mosaico?: Maybe<{ __typename?: 'MosaicoNode' } & Pick<MosaicoNode, 'id'>>;
    commonAuthors: { __typename?: 'AuthorNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'AuthorNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'AuthorNode' } & Pick<
                AuthorNode,
                'id' | 'firstName'
              >
            >;
          }
        >
      >;
    };
    authorsGroups: { __typename?: 'AuthorsGroupNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'AuthorsGroupNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'AuthorsGroupNode' } & AuthorsGroupNodeFragment
            >;
          }
        >
      >;
    };
    cover?: Maybe<
      { __typename?: 'ImageFiledType' } & {
        thumbnail?: Maybe<
          { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
        >;
      }
    >;
    background?: Maybe<
      { __typename?: 'ImageFiledType' } & {
        thumbnail?: Maybe<
          { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
        >;
      }
    >;
    company?: Maybe<
      { __typename?: 'CompanyNode' } & Pick<CompanyNode, 'id' | 'name'> & {
          streamService?: Maybe<
            { __typename?: 'StreamServiceNode' } & Pick<
              StreamServiceNode,
              'id' | 'accountId'
            >
          >;
          defaultTheme?: Maybe<
            { __typename?: 'ThemeNode' } & Pick<
              ThemeNode,
              'id' | 'name' | 'enableInDashboard'
            >
          >;
        }
    >;
  };

export type CreateCourseByIdMutationVariables = Exact<{
  input: CourseCreateMutationInput;
}>;

export type CreateCourseByIdMutation = { __typename?: 'Mutation' } & {
  courseCreate?: Maybe<
    { __typename?: 'CourseCreateMutationPayload' } & {
      course?: Maybe<{ __typename?: 'CourseNode' } & CourseFieldsFragment>;
    }
  >;
};

export type UpdateCourseByIdMutationVariables = Exact<{
  input: CourseUpdateMutationInput;
}>;

export type UpdateCourseByIdMutation = { __typename?: 'Mutation' } & {
  courseUpdate?: Maybe<
    { __typename?: 'CourseUpdateMutationPayload' } & {
      course?: Maybe<{ __typename?: 'CourseNode' } & CourseFieldsFragment>;
    }
  >;
};

export type CourseCreateFromTemplateMutationVariables = Exact<{
  input: CourseCreateFromTemplateMutationInput;
}>;

export type CourseCreateFromTemplateMutation = { __typename?: 'Mutation' } & {
  courseCreateFromTemplate?: Maybe<
    { __typename?: 'CourseCreateFromTemplateMutationPayload' } & {
      course?: Maybe<
        { __typename?: 'CourseNode' } & Pick<CourseNode, 'id' | 'name'>
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CourseCloneMutationVariables = Exact<{
  input: CourseCloneMutationInput;
}>;

export type CourseCloneMutation = { __typename?: 'Mutation' } & {
  courseClone?: Maybe<
    { __typename?: 'CourseCloneMutationPayload' } & {
      course?: Maybe<
        { __typename?: 'CourseNode' } & Pick<CourseNode, 'name' | 'id'>
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CourseUpdateJsxContentMutationVariables = Exact<{
  input: CourseUpdateMutationInput;
}>;

export type CourseUpdateJsxContentMutation = { __typename?: 'Mutation' } & {
  courseUpdate?: Maybe<
    { __typename?: 'CourseUpdateMutationPayload' } & {
      course?: Maybe<
        { __typename?: 'CourseNode' } & Pick<CourseNode, 'id' | 'jsxContent'>
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CourseInvitationtNodeFragment = {
  __typename?: 'CourseInvitationNode';
} & Pick<
  CourseInvitationNode,
  | 'id'
  | 'created'
  | 'email'
  | 'data'
  | 'link'
  | 'linkWithAuthentication'
  | 'lastEmailSent'
  | 'autoAcceptParticipation'
  | 'autoCreateParticipation'
> & {
    owner: { __typename?: 'UserNode' } & Pick<
      UserNode,
      'id' | 'displayName' | 'email' | 'avatar'
    >;
    participations: { __typename?: 'CourseParticipationNodeConnection' } & Pick<
      CourseParticipationNodeConnection,
      'totalCount'
    >;
    destinationGroups: { __typename?: 'GroupNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'GroupNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'GroupNode' } & Pick<
                GroupNode,
                'id' | 'name' | 'label'
              >
            >;
          }
        >
      >;
    };
  };

export type CourseInvitationCreateMutationVariables = Exact<{
  input: CourseInvitationCreateMutationInput;
}>;

export type CourseInvitationCreateMutation = { __typename?: 'Mutation' } & {
  courseInvitationCreate?: Maybe<
    { __typename?: 'CourseInvitationCreateMutationPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
      courseInvitation?: Maybe<
        { __typename?: 'CourseInvitationNode' } & CourseInvitationtNodeFragment
      >;
    }
  >;
};

export type CourseInvitationUpdateMutationVariables = Exact<{
  input: CourseInvitationUpdateMutationInput;
}>;

export type CourseInvitationUpdateMutation = { __typename?: 'Mutation' } & {
  courseInvitationUpdate?: Maybe<
    { __typename?: 'CourseInvitationUpdateMutationPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
      courseInvitation?: Maybe<
        { __typename?: 'CourseInvitationNode' } & CourseInvitationtNodeFragment
      >;
    }
  >;
};

export type CourseInvitationDeleteMutationVariables = Exact<{
  input: CourseInvitationDeleteMutationInput;
}>;

export type CourseInvitationDeleteMutation = { __typename?: 'Mutation' } & {
  courseInvitationDelete?: Maybe<
    { __typename?: 'CourseInvitationDeleteMutationPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
      courseInvitation?: Maybe<
        { __typename?: 'CourseInvitationNode' } & Pick<
          CourseInvitationNode,
          'id'
        >
      >;
    }
  >;
};

export type CourseSendAllInvitationsMutationVariables = Exact<{
  input: CourseSendAllInvitationsInput;
}>;

export type CourseSendAllInvitationsMutation = { __typename?: 'Mutation' } & {
  courseSendAllInvitations?: Maybe<
    { __typename?: 'CourseSendAllInvitationsPayload' } & Pick<
      CourseSendAllInvitationsPayload,
      'sentCount' | 'skypCount' | 'failCount'
    > & {
        errors?: Maybe<
          Array<
            { __typename?: 'MutationErrorType' } & Pick<
              MutationErrorType,
              'field' | 'message'
            >
          >
        >;
      }
  >;
};

export type CourseDeleteAllPossibleInvitationsMutationVariables = Exact<{
  input: CourseDeleteAllPossibleInvitationsInput;
}>;

export type CourseDeleteAllPossibleInvitationsMutation = {
  __typename?: 'Mutation';
} & {
  courseDeleteAllPossibleInvitations?: Maybe<
    { __typename?: 'CourseDeleteAllPossibleInvitationsPayload' } & Pick<
      CourseDeleteAllPossibleInvitationsPayload,
      'count'
    > & {
        errors?: Maybe<
          Array<
            { __typename?: 'MutationErrorType' } & Pick<
              MutationErrorType,
              'field' | 'message'
            >
          >
        >;
      }
  >;
};

export type CourseInvitationSendEmailMutationVariables = Exact<{
  input: CourseInvitationSendEmailMutationInput;
}>;

export type CourseInvitationSendEmailMutation = { __typename?: 'Mutation' } & {
  courseInvitationSendEmail?: Maybe<
    { __typename?: 'CourseInvitationSendEmailMutationPayload' } & {
      courseInvitation?: Maybe<
        { __typename?: 'CourseInvitationNode' } & CourseInvitationtNodeFragment
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CourseInvitationPublicResendEmailMutationVariables = Exact<{
  input: CourseInvitationPublicResendEmailMutationInput;
}>;

export type CourseInvitationPublicResendEmailMutation = {
  __typename?: 'Mutation';
} & {
  courseInvitationPublicResendEmail?: Maybe<
    { __typename?: 'CourseInvitationPublicResendEmailMutationPayload' } & Pick<
      CourseInvitationPublicResendEmailMutationPayload,
      'sentResult'
    > & {
        errors?: Maybe<
          Array<
            { __typename?: 'MutationErrorType' } & Pick<
              MutationErrorType,
              'field' | 'message'
            >
          >
        >;
      }
  >;
};

export type CourseInvitationPublicSearchAndSendByEmailMutationVariables =
  Exact<{
    input: CourseInvitationSearchAndSendByEmailInput;
  }>;

export type CourseInvitationPublicSearchAndSendByEmailMutation = {
  __typename?: 'Mutation';
} & {
  courseInvitationPublicSearchAndSendByEmail?: Maybe<
    { __typename?: 'CourseInvitationSearchAndSendByEmailPayload' } & Pick<
      CourseInvitationSearchAndSendByEmailPayload,
      'sentResult'
    > & {
        errors?: Maybe<
          Array<
            { __typename?: 'MutationErrorType' } & Pick<
              MutationErrorType,
              'field' | 'message'
            >
          >
        >;
      }
  >;
};

export type CourseModuleUpdateMutationVariables = Exact<{
  input: CourseModuleUpdateMutationInput;
}>;

export type CourseModuleUpdateMutation = { __typename?: 'Mutation' } & {
  courseModuleUpdate?: Maybe<
    { __typename?: 'CourseModuleUpdateMutationPayload' } & {
      courseModule?: Maybe<
        {
          __typename?: 'CourseModuleNode';
        } & CourseModuleUnionCourseModuleNodeFragment
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type UserModuleAssessmentStartMutationVariables = Exact<{
  input: UserModuleAssessmentStartInput;
}>;

export type UserModuleAssessmentStartMutation = { __typename?: 'Mutation' } & {
  userModuleAssessmentStart?: Maybe<
    { __typename?: 'UserModuleAssessmentStartPayload' } & {
      moduleAssessment?: Maybe<
        | ({
            __typename?: 'CourseModuleAssessmentVideoNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
        | ({
            __typename?: 'CourseModuleAssessmentExternalWebinarNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
        | ({
            __typename?: 'CourseModuleAssessmentSurveyNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
        | ({
            __typename?: 'CourseModuleAssessmentNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
      >;
    }
  >;
};

export type UserModuleAssessmentSetCompletedMutationVariables = Exact<{
  input: UserModuleAssessmentSetCompletedInput;
}>;

export type UserModuleAssessmentSetCompletedMutation = {
  __typename?: 'Mutation';
} & {
  userModuleAssessmentSetCompleted?: Maybe<
    { __typename?: 'UserModuleAssessmentSetCompletedPayload' } & {
      moduleAssessment?: Maybe<
        | ({
            __typename?: 'CourseModuleAssessmentVideoNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
        | ({
            __typename?: 'CourseModuleAssessmentExternalWebinarNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
        | ({
            __typename?: 'CourseModuleAssessmentSurveyNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
        | ({
            __typename?: 'CourseModuleAssessmentNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
      >;
    }
  >;
};

export type UserModuleAssessmentSurveyCreateNewResponseMutationVariables =
  Exact<{
    input: UserModuleAssessmentSurveyCreateNewResponseInput;
  }>;

export type UserModuleAssessmentSurveyCreateNewResponseMutation = {
  __typename?: 'Mutation';
} & {
  userModuleAssessmentSurveyCreateNewResponse?: Maybe<
    { __typename?: 'UserModuleAssessmentSurveyCreateNewResponsePayload' } & {
      moduleAssessment?: Maybe<
        | ({
            __typename?: 'CourseModuleAssessmentVideoNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
        | ({
            __typename?: 'CourseModuleAssessmentExternalWebinarNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
        | ({
            __typename?: 'CourseModuleAssessmentSurveyNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
        | ({
            __typename?: 'CourseModuleAssessmentNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
      >;
    }
  >;
};

export type VideoModuleAssessmentUpdateMutationVariables = Exact<{
  input: UserVideoModuleAssessmentUpdateMutationInput;
}>;

export type VideoModuleAssessmentUpdateMutation = {
  __typename?: 'Mutation';
} & {
  videoModuleAssessmentUpdate?: Maybe<
    { __typename?: 'UserVideoModuleAssessmentUpdateMutationPayload' } & {
      courseModuleAssessmentVideo?: Maybe<
        { __typename?: 'CourseModuleAssessmentVideoNode' } & Pick<
          CourseModuleAssessmentVideoNode,
          'id' | 'status' | 'videoPlayedSeconds' | 'userLivePercentile'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type UserModuleAssessmentCreateMutationVariables = Exact<{
  input: UserModuleAssessmentCreateInput;
}>;

export type UserModuleAssessmentCreateMutation = { __typename?: 'Mutation' } & {
  userModuleAssessmentCreate?: Maybe<
    { __typename?: 'UserModuleAssessmentCreatePayload' } & {
      moduleAssessment?: Maybe<
        | ({
            __typename?: 'CourseModuleAssessmentVideoNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
        | ({
            __typename?: 'CourseModuleAssessmentExternalWebinarNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
        | ({
            __typename?: 'CourseModuleAssessmentSurveyNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
        | ({
            __typename?: 'CourseModuleAssessmentNode';
          } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
      >;
      module?: Maybe<
        | ({
            __typename?: 'SurveyModuleNode';
          } & CourseModuleUnionOverviewWithPartecipationDataSurveyModuleNodeFragment)
        | ({
            __typename?: 'ExternalWebinarModuleNode';
          } & CourseModuleUnionOverviewWithPartecipationDataExternalWebinarModuleNodeFragment)
        | ({
            __typename?: 'CourseModuleNode';
          } & CourseModuleUnionOverviewWithPartecipationDataCourseModuleNodeFragment)
        | ({
            __typename?: 'ChatModuleNode';
          } & CourseModuleUnionOverviewWithPartecipationDataChatModuleNodeFragment)
        | ({
            __typename?: 'VideoModuleNode';
          } & CourseModuleUnionOverviewWithPartecipationDataVideoModuleNodeFragment)
        | ({
            __typename?: 'LayoutModuleNode';
          } & CourseModuleUnionOverviewWithPartecipationDataLayoutModuleNodeFragment)
        | ({
            __typename?: 'DocumentModuleNode';
          } & CourseModuleUnionOverviewWithPartecipationDataDocumentModuleNodeFragment)
        | ({
            __typename?: 'IframeModuleNode';
          } & CourseModuleUnionOverviewWithPartecipationDataIframeModuleNodeFragment)
        | ({
            __typename?: 'VideowallModuleNode';
          } & CourseModuleUnionOverviewWithPartecipationDataVideowallModuleNodeFragment)
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type UserModuleAssessmentGateCheckMutationVariables = Exact<{
  input: UserModuleAssessmentGateCheckInput;
}>;

export type UserModuleAssessmentGateCheckMutation = {
  __typename?: 'Mutation';
} & {
  userModuleAssessmentGateCheck?: Maybe<
    { __typename?: 'UserModuleAssessmentGateCheckPayload' } & Pick<
      UserModuleAssessmentGateCheckPayload,
      'email' | 'firstName' | 'lastName'
    > & {
        moduleAssessment?: Maybe<
          | ({
              __typename?: 'CourseModuleAssessmentVideoNode';
            } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
          | ({
              __typename?: 'CourseModuleAssessmentExternalWebinarNode';
            } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
          | ({
              __typename?: 'CourseModuleAssessmentSurveyNode';
            } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
          | ({
              __typename?: 'CourseModuleAssessmentNode';
            } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
        >;
        errors?: Maybe<
          Array<
            { __typename?: 'MutationErrorType' } & Pick<
              MutationErrorType,
              'field' | 'message'
            >
          >
        >;
      }
  >;
};

export type CourseParticipationCreateMutationVariables = Exact<{
  input: CourseParticipationCreateMutationInput;
}>;

export type CourseParticipationCreateMutation = { __typename?: 'Mutation' } & {
  courseParticipationCreate?: Maybe<
    { __typename?: 'CourseParticipationCreateMutationPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
      courseParticipation?: Maybe<
        { __typename?: 'CourseParticipationNode' } & Pick<
          CourseParticipationNode,
          'id' | 'data' | 'status'
        > & {
            qrcode?: Maybe<
              { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'url'>
            >;
            course: { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
                participation?: Maybe<
                  { __typename?: 'CourseParticipationNode' } & Pick<
                    CourseParticipationNode,
                    'id' | 'status'
                  >
                >;
                submodules?: Maybe<
                  { __typename?: 'CourseModuleConnection' } & Pick<
                    CourseModuleConnection,
                    'totalCount'
                  > & {
                      edges: Array<
                        Maybe<
                          { __typename?: 'CourseModuleEdge' } & {
                            node?: Maybe<
                              | ({
                                  __typename?: 'SurveyModuleNode';
                                } & CourseModuleUnionOverviewWithPartecipationDataSurveyModuleNodeFragment)
                              | ({
                                  __typename?: 'ExternalWebinarModuleNode';
                                } & CourseModuleUnionOverviewWithPartecipationDataExternalWebinarModuleNodeFragment)
                              | ({
                                  __typename?: 'CourseModuleNode';
                                } & CourseModuleUnionOverviewWithPartecipationDataCourseModuleNodeFragment)
                              | ({
                                  __typename?: 'ChatModuleNode';
                                } & CourseModuleUnionOverviewWithPartecipationDataChatModuleNodeFragment)
                              | ({
                                  __typename?: 'VideoModuleNode';
                                } & CourseModuleUnionOverviewWithPartecipationDataVideoModuleNodeFragment)
                              | ({
                                  __typename?: 'LayoutModuleNode';
                                } & CourseModuleUnionOverviewWithPartecipationDataLayoutModuleNodeFragment)
                              | ({
                                  __typename?: 'DocumentModuleNode';
                                } & CourseModuleUnionOverviewWithPartecipationDataDocumentModuleNodeFragment)
                              | ({
                                  __typename?: 'IframeModuleNode';
                                } & CourseModuleUnionOverviewWithPartecipationDataIframeModuleNodeFragment)
                              | ({
                                  __typename?: 'VideowallModuleNode';
                                } & CourseModuleUnionOverviewWithPartecipationDataVideowallModuleNodeFragment)
                            >;
                          }
                        >
                      >;
                    }
                >;
              };
            user: { __typename?: 'UserNode' } & Pick<UserNode, 'id'>;
            manager?: Maybe<{ __typename?: 'UserNode' } & Pick<UserNode, 'id'>>;
          }
      >;
    }
  >;
};

export type CourseParticipationUpdateMutationVariables = Exact<{
  input: CourseParticipationUpdateMutationInput;
}>;

export type CourseParticipationUpdateMutation = { __typename?: 'Mutation' } & {
  courseParticipationUpdate?: Maybe<
    { __typename?: 'CourseParticipationUpdateMutationPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
      courseParticipation?: Maybe<
        { __typename?: 'CourseParticipationNode' } & Pick<
          CourseParticipationNode,
          'id' | 'data'
        > & {
            user: { __typename?: 'UserNode' } & Pick<UserNode, 'id'>;
            manager?: Maybe<{ __typename?: 'UserNode' } & Pick<UserNode, 'id'>>;
          }
      >;
    }
  >;
};

export type CourseParticipationDeleteMutationVariables = Exact<{
  input: CourseParticipationDeleteMutationInput;
}>;

export type CourseParticipationDeleteMutation = { __typename?: 'Mutation' } & {
  courseParticipationDelete?: Maybe<
    { __typename?: 'CourseParticipationDeleteMutationPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CourseParticipationManageStatusMutationVariables = Exact<{
  input: CoursePartecipationManageStatusMutationInput;
}>;

export type CourseParticipationManageStatusMutation = {
  __typename?: 'Mutation';
} & {
  coursePartecipationManageStatus?: Maybe<
    { __typename?: 'CoursePartecipationManageStatusMutationPayload' } & {
      participation?: Maybe<
        { __typename?: 'CourseParticipationNode' } & Pick<
          CourseParticipationNode,
          'id' | 'status'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CoursePartecipationResendConfirmEmailMutationVariables = Exact<{
  input: CoursePartecipationResendConfirmEmailInput;
}>;

export type CoursePartecipationResendConfirmEmailMutation = {
  __typename?: 'Mutation';
} & {
  coursePartecipationResendConfirmEmail?: Maybe<
    { __typename?: 'CoursePartecipationResendConfirmEmailPayload' } & {
      participation?: Maybe<
        { __typename?: 'CourseParticipationNode' } & Pick<
          CourseParticipationNode,
          'id'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type ZoomMeetingAuthorizeMutationMutationVariables = Exact<{
  input: ZoomMeetingAuthorizeMutationInput;
}>;

export type ZoomMeetingAuthorizeMutationMutation = {
  __typename?: 'Mutation';
} & {
  zoomMeetingAuthorize?: Maybe<
    { __typename?: 'ZoomMeetingAuthorizeMutationPayload' } & {
      authData?: Maybe<
        { __typename?: 'ZoomAuthorizationNode' } & Pick<
          ZoomAuthorizationNode,
          'token' | 'sdkKey'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type ZoomMeetingCreateMutationMutationVariables = Exact<{
  input: ZoomMeetingCreateMutationInput;
}>;

export type ZoomMeetingCreateMutationMutation = { __typename?: 'Mutation' } & {
  zoomMeetingCreate?: Maybe<
    { __typename?: 'ZoomMeetingCreateMutationPayload' } & {
      meeting?: Maybe<
        { __typename?: 'ZoomMeetingNode' } & Pick<
          ZoomMeetingNode,
          'meetingNo' | 'id' | 'meetingPwd' | 'topic' | 'startTime' | 'data'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type ZoomMeetingUpdateMutationMutationVariables = Exact<{
  input: ZoomMeetingUpdateMutationInput;
}>;

export type ZoomMeetingUpdateMutationMutation = { __typename?: 'Mutation' } & {
  zoomMeetingUpdate?: Maybe<
    { __typename?: 'ZoomMeetingUpdateMutationPayload' } & {
      meeting?: Maybe<
        { __typename?: 'ZoomMeetingNode' } & Pick<
          ZoomMeetingNode,
          'meetingNo' | 'id' | 'meetingPwd' | 'topic' | 'startTime' | 'data'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type ZoomMeetingDeleteMutationMutationVariables = Exact<{
  input: ZoomMeetingDeleteMutationInput;
}>;

export type ZoomMeetingDeleteMutationMutation = { __typename?: 'Mutation' } & {
  zoomMeetingDelete?: Maybe<
    { __typename?: 'ZoomMeetingDeleteMutationPayload' } & Pick<
      ZoomMeetingDeleteMutationPayload,
      'success'
    > & {
        errors?: Maybe<
          Array<
            { __typename?: 'MutationErrorType' } & Pick<
              MutationErrorType,
              'field' | 'message'
            >
          >
        >;
      }
  >;
};

export type CourseUserParticipationsQuotaDeleteMutationVariables = Exact<{
  input: CourseUserParticipationsQuotaDeleteMutationInput;
}>;

export type CourseUserParticipationsQuotaDeleteMutation = {
  __typename?: 'Mutation';
} & {
  courseUserParticipationsQuotaDelete?: Maybe<
    { __typename?: 'CourseUserParticipationsQuotaDeleteMutationPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
      courseUserParticipationQuotas?: Maybe<
        { __typename?: 'CourseUserParticipationQuotasNode' } & Pick<
          CourseUserParticipationQuotasNode,
          'id'
        >
      >;
    }
  >;
};

export type CourseUserParticipationsQuotaCreateMutationVariables = Exact<{
  input: CourseUserParticipationsQuotaCreateMutationInput;
}>;

export type CourseUserParticipationsQuotaCreateMutation = {
  __typename?: 'Mutation';
} & {
  courseUserParticipationsQuotaCreate?: Maybe<
    { __typename?: 'CourseUserParticipationsQuotaCreateMutationPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
      courseUserParticipationQuotas?: Maybe<
        { __typename?: 'CourseUserParticipationQuotasNode' } & Pick<
          CourseUserParticipationQuotasNode,
          'id' | 'participationsQuota' | 'label'
        > & {
            user: { __typename?: 'UserNode' } & Pick<
              UserNode,
              'id' | 'displayName' | 'email'
            >;
          }
      >;
    }
  >;
};

export type CourseUserParticipationsQuotaUpdateMutationVariables = Exact<{
  input: CourseUserParticipationsQuotaUpdateMutationInput;
}>;

export type CourseUserParticipationsQuotaUpdateMutation = {
  __typename?: 'Mutation';
} & {
  courseUserParticipationsQuotaUpdate?: Maybe<
    { __typename?: 'CourseUserParticipationsQuotaUpdateMutationPayload' } & {
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
      courseUserParticipationQuotas?: Maybe<
        { __typename?: 'CourseUserParticipationQuotasNode' } & Pick<
          CourseUserParticipationQuotasNode,
          'id' | 'participationsQuota' | 'label'
        > & {
            user: { __typename?: 'UserNode' } & Pick<
              UserNode,
              'id' | 'displayName' | 'email'
            >;
          }
      >;
    }
  >;
};

export type DbtemplateUpdateMutationVariables = Exact<{
  input: DbtemplateUpdateMutationInput;
}>;

export type DbtemplateUpdateMutation = { __typename?: 'Mutation' } & {
  dbtemplateUpdate?: Maybe<
    { __typename?: 'DbtemplateUpdateMutationPayload' } & {
      template?: Maybe<
        { __typename?: 'DbtemplatesNode' } & Pick<
          DbtemplatesNode,
          | 'content'
          | 'design'
          | 'name'
          | 'subject'
          | 'label'
          | 'preview'
          | 'getNameDisplay'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'message'
          >
        >
      >;
    }
  >;
};

export type DbtemplateCreateMutationVariables = Exact<{
  input: DbtemplateCreateMutationInput;
}>;

export type DbtemplateCreateMutation = { __typename?: 'Mutation' } & {
  dbtemplateCreate?: Maybe<
    { __typename?: 'DbtemplateCreateMutationPayload' } & {
      template?: Maybe<
        { __typename?: 'DbtemplatesNode' } & Pick<
          DbtemplatesNode,
          | 'content'
          | 'design'
          | 'name'
          | 'subject'
          | 'label'
          | 'preview'
          | 'getNameDisplay'
        > & {
            courseSet: { __typename?: 'CourseNodeConnection' } & {
              edges: Array<
                Maybe<
                  { __typename?: 'CourseNodeEdge' } & {
                    node?: Maybe<
                      { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'>
                    >;
                  }
                >
              >;
            };
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'message'
          >
        >
      >;
    }
  >;
};

export type CreateDemoCourseMutationVariables = Exact<{
  input: CreateDemoCourseInput;
}>;

export type CreateDemoCourseMutation = { __typename?: 'Mutation' } & {
  createDemoCourse?: Maybe<
    { __typename?: 'CreateDemoCoursePayload' } & Pick<
      CreateDemoCoursePayload,
      'status' | 'courseUrl'
    > & {
        course?: Maybe<
          { __typename?: 'CourseNode' } & Pick<CourseNode, 'name'>
        >;
      }
  >;
};

export type SurveyCloneMutationVariables = Exact<{
  input: SurveyCloneMutationInput;
}>;

export type SurveyCloneMutation = { __typename?: 'Mutation' } & {
  surveyClone?: Maybe<
    { __typename?: 'SurveyCloneMutationPayload' } & {
      survey?: Maybe<
        { __typename?: 'SurveyNode' } & Pick<
          SurveyNode,
          'id' | 'name' | 'label'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type SurveyCreateMutationVariables = Exact<{
  input: SurveyCreateMutationInput;
}>;

export type SurveyCreateMutation = { __typename?: 'Mutation' } & {
  surveyCreate?: Maybe<
    { __typename?: 'SurveyCreateMutationPayload' } & {
      survey?: Maybe<
        { __typename?: 'SurveyNode' } & Pick<
          SurveyNode,
          | 'id'
          | 'name'
          | 'label'
          | 'description'
          | 'type'
          | 'randomizeQuestions'
          | 'isPublished'
          | 'needLoggedUser'
          | 'editableAnswers'
        > & {
            ownership?: Maybe<
              { __typename?: 'CompanyNode' } & Pick<CompanyNode, 'name'>
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type SurveyUpdateMutationVariables = Exact<{
  input: SurveyUpdateMutationInput;
}>;

export type SurveyUpdateMutation = { __typename?: 'Mutation' } & {
  surveyUpdate?: Maybe<
    { __typename?: 'SurveyUpdateMutationPayload' } & {
      survey?: Maybe<
        { __typename?: 'SurveyNode' } & Pick<
          SurveyNode,
          | 'id'
          | 'name'
          | 'label'
          | 'description'
          | 'type'
          | 'isPublished'
          | 'needLoggedUser'
          | 'editableAnswers'
          | 'randomizeQuestions'
        > & {
            ownership?: Maybe<
              { __typename?: 'CompanyNode' } & Pick<CompanyNode, 'name'>
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type SurveyDeleteMutationVariables = Exact<{
  input: SurveyDeleteMutationInput;
}>;

export type SurveyDeleteMutation = { __typename?: 'Mutation' } & {
  surveyDelete?: Maybe<
    { __typename?: 'SurveyDeleteMutationPayload' } & {
      survey?: Maybe<{ __typename?: 'SurveyNode' } & Pick<SurveyNode, 'id'>>;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type QuestionCreateMutationVariables = Exact<{
  input: QuestionCreateMutationInput;
}>;

export type QuestionCreateMutation = { __typename?: 'Mutation' } & {
  questionCreate?: Maybe<
    { __typename?: 'QuestionCreateMutationPayload' } & {
      question?: Maybe<
        { __typename?: 'QuestionNode' } & QuestionUnionIdFragmentFragment
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type QuestionCloneMutationVariables = Exact<{
  input: QuestionCloneMutationInput;
}>;

export type QuestionCloneMutation = { __typename?: 'Mutation' } & {
  questionClone?: Maybe<
    { __typename?: 'QuestionCloneMutationPayload' } & {
      question?: Maybe<
        { __typename?: 'QuestionNode' } & Pick<QuestionNode, 'id'>
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type QuestionUpdateMutationVariables = Exact<{
  input: QuestionUpdateMutationInput;
}>;

export type QuestionUpdateMutation = { __typename?: 'Mutation' } & {
  questionUpdate?: Maybe<
    { __typename?: 'QuestionUpdateMutationPayload' } & {
      question?: Maybe<
        { __typename?: 'QuestionNode' } & QuestionUnionFragmentFragment
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type QuestionDeleteMutationVariables = Exact<{
  input: QuestionDeleteMutationInput;
}>;

export type QuestionDeleteMutation = { __typename?: 'Mutation' } & {
  questionDelete?: Maybe<
    { __typename?: 'QuestionDeleteMutationPayload' } & {
      question?: Maybe<
        { __typename?: 'QuestionNode' } & QuestionUnionIdFragmentFragment
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type UpdateBirthDayMutationVariables = Exact<{
  input: MeUpdateMutationInput;
}>;

export type UpdateBirthDayMutation = { __typename?: 'Mutation' } & {
  meUpdate?: Maybe<
    { __typename?: 'MeUpdateMutationPayload' } & {
      user?: Maybe<
        { __typename?: 'UserNode' } & Pick<UserNode, 'id'> & {
            profile?: Maybe<
              { __typename?: 'ProfileNode' } & Pick<
                ProfileNode,
                'id' | 'birthDay'
              >
            >;
          }
      >;
    }
  >;
};

export type UpdateMeMutationVariables = Exact<{
  input: MeUpdateMutationInput;
}>;

export type UpdateMeMutation = { __typename?: 'Mutation' } & {
  meUpdate?: Maybe<
    { __typename?: 'MeUpdateMutationPayload' } & {
      user?: Maybe<
        { __typename?: 'UserNode' } & Pick<
          UserNode,
          'id' | 'firstName' | 'lastName'
        > & {
            profile?: Maybe<
              { __typename?: 'ProfileNode' } & Pick<
                ProfileNode,
                'id' | 'birthDay' | 'gender'
              > & {
                  birthPlace?: Maybe<
                    { __typename?: 'AddressNode' } & Pick<
                      AddressNode,
                      'cityState'
                    >
                  >;
                }
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type AvatarCreateMutationVariables = Exact<{
  input: AvatarCreateMutationInput;
}>;

export type AvatarCreateMutation = { __typename?: 'Mutation' } & {
  avatarCreate?: Maybe<
    { __typename?: 'AvatarCreateMutationPayload' } & {
      avatar?: Maybe<
        { __typename?: 'AvatarNode' } & Pick<
          AvatarNode,
          'primary' | 'dateUploaded'
        > & {
            user: { __typename?: 'UserNode' } & Pick<UserNode, 'id'>;
            avatar?: Maybe<
              { __typename?: 'ImageFiledType' } & Pick<
                ImageFiledType,
                'url' | 'name' | 'size'
              >
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type PasswordResetMutationVariables = Exact<{
  input: PasswordResetInput;
}>;

export type PasswordResetMutation = { __typename?: 'Mutation' } & {
  passwordReset?: Maybe<
    { __typename?: 'PasswordResetSuccess' } & Pick<
      PasswordResetSuccess,
      'sended'
    >
  >;
};

export type PasswordChangeMutationVariables = Exact<{
  input: PasswordChangeInput;
}>;

export type PasswordChangeMutation = { __typename?: 'Mutation' } & {
  passwordChange?: Maybe<
    { __typename?: 'PasswordChangeSuccess' } & Pick<
      PasswordChangeSuccess,
      'success'
    > & {
        errors?: Maybe<
          Array<{ __typename?: 'Error' } & Pick<Error, 'field' | 'message'>>
        >;
      }
  >;
};

export type LogInMutationVariables = Exact<{
  input: LogInInput;
}>;

export type LogInMutation = { __typename?: 'Mutation' } & {
  logIn?: Maybe<
    { __typename?: 'LogInMutationSuccess' } & {
      user: { __typename?: 'UserNode' } & Pick<
        UserNode,
        'id' | 'avatar' | 'firstName' | 'lastName' | 'isAnonymous' | 'isActive'
      >;
    }
  >;
};

export type LogOutMutationVariables = Exact<{ [key: string]: never }>;

export type LogOutMutation = { __typename?: 'Mutation' } & {
  logOut?: Maybe<
    { __typename?: 'LogoutMutation' } & Pick<
      LogoutMutation,
      'message' | 'ok'
    > & {
        errors?: Maybe<
          Array<{ __typename?: 'Error' } & Pick<Error, 'field' | 'message'>>
        >;
      }
  >;
};

export type UserRequestDeactivationMutationVariables = Exact<{
  input: UserRequestDeactivationMutationInput;
}>;

export type UserRequestDeactivationMutation = { __typename?: 'Mutation' } & {
  userRequestDeactivation?: Maybe<
    { __typename?: 'UserRequestDeactivationMutationPayload' } & Pick<
      UserRequestDeactivationMutationPayload,
      'requestDeactivationSent'
    > & {
        errors?: Maybe<
          Array<
            { __typename?: 'MutationErrorType' } & Pick<
              MutationErrorType,
              'field' | 'message'
            >
          >
        >;
      }
  >;
};

export type UserDeactivateMutationVariables = Exact<{
  input: UserDeactivateMutationInput;
}>;

export type UserDeactivateMutation = { __typename?: 'Mutation' } & {
  userDeactivate?: Maybe<
    { __typename?: 'UserDeactivateMutationPayload' } & {
      user?: Maybe<{ __typename?: 'UserNode' } & Pick<UserNode, 'isActive'>>;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type VerificationCodeMatchMutationMutationVariables = Exact<{
  input: VerificationCodeMatchInput;
}>;

export type VerificationCodeMatchMutationMutation = {
  __typename?: 'Mutation';
} & {
  verificationCodeMatch?: Maybe<
    { __typename?: 'VerificationCodeMatchSuccess' } & Pick<
      VerificationCodeMatchSuccess,
      'match' | 'verificationLink'
    >
  >;
};

export type AllCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type AllCompaniesQuery = { __typename?: 'Query' } & {
  allCompanies?: Maybe<
    { __typename?: 'CompanyNodeConnection' } & Pick<
      CompanyNodeConnection,
      'totalCount'
    > & {
        edges: Array<
          Maybe<
            { __typename?: 'CompanyNodeEdge' } & {
              node?: Maybe<
                { __typename?: 'CompanyNode' } & Pick<
                  CompanyNode,
                  'id' | 'name' | 'slug' | 'frontendUrl' | 'getCategoryDisplay'
                > & {
                    courses: { __typename?: 'CourseNodeConnection' } & Pick<
                      CourseNodeConnection,
                      'totalCount'
                    >;
                    defaultTheme?: Maybe<
                      { __typename?: 'ThemeNode' } & Pick<ThemeNode, 'id'>
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type CategoryCreateMutationVariables = Exact<{
  input: CategoryCreateMutationInput;
}>;

export type CategoryCreateMutation = { __typename?: 'Mutation' } & {
  categoryCreate?: Maybe<
    { __typename?: 'CategoryCreateMutationPayload' } & {
      courseCategory?: Maybe<
        { __typename?: 'CourseCategoryNode' } & Pick<
          CourseCategoryNode,
          'name' | 'description' | 'order'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CategoryUpdateMutationVariables = Exact<{
  input: CategoryUpdateMutationInput;
}>;

export type CategoryUpdateMutation = { __typename?: 'Mutation' } & {
  categoryUpdate?: Maybe<
    { __typename?: 'CategoryUpdateMutationPayload' } & {
      courseCategory?: Maybe<
        { __typename?: 'CourseCategoryNode' } & Pick<
          CourseCategoryNode,
          'name' | 'description' | 'order'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CategoryDeleteMutationVariables = Exact<{
  input: CategoryDeleteMutationInput;
}>;

export type CategoryDeleteMutation = { __typename?: 'Mutation' } & {
  categoryDelete?: Maybe<
    { __typename?: 'CategoryDeleteMutationPayload' } & {
      courseCategory?: Maybe<
        { __typename?: 'CourseCategoryNode' } & Pick<CourseCategoryNode, 'id'>
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CategoryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CategoryQuery = { __typename?: 'Query' } & {
  category?: Maybe<
    { __typename?: 'CourseCategoryNode' } & Pick<
      CourseCategoryNode,
      'id' | 'name' | 'description'
    >
  >;
};

export type GetRoomPresenceQueryVariables = Exact<{
  channelName: Scalars['ID'];
  withUsers: Scalars['Boolean'];
}>;

export type GetRoomPresenceQuery = { __typename?: 'Query' } & {
  roomPresence?: Maybe<
    { __typename?: 'RoomNode' } & Pick<
      RoomNode,
      'id' | 'uuid' | 'channelName'
    > & {
        users?: Maybe<
          { __typename?: 'UserConnection' } & Pick<
            UserConnection,
            'totalCount'
          > & {
              edges?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'UserEdge' } & {
                      node?: Maybe<
                        { __typename?: 'UserNode' } & Pick<
                          UserNode,
                          'id' | 'displayName' | 'avatar'
                        >
                      >;
                    }
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type GetRoomPresenceGraphQueryVariables = Exact<{
  channelName: Scalars['ID'];
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  resolution?: Maybe<Scalars['Int']>;
}>;

export type GetRoomPresenceGraphQuery = { __typename?: 'Query' } & {
  roomPresence?: Maybe<
    { __typename?: 'RoomNode' } & Pick<
      RoomNode,
      'id' | 'uuid' | 'channelName' | 'graphs'
    > & {
        users?: Maybe<
          { __typename?: 'UserConnection' } & Pick<UserConnection, 'totalCount'>
        >;
      }
  >;
};

export type GetPresenceOfOuserQueryVariables = Exact<{
  roomName: Scalars['ID'];
  userId: Scalars['ID'];
}>;

export type GetPresenceOfOuserQuery = { __typename?: 'Query' } & {
  presencesOfUser?: Maybe<
    { __typename?: 'PresenceNodeConnection' } & Pick<
      PresenceNodeConnection,
      'totalCount'
    > & {
        edges: Array<
          Maybe<
            { __typename?: 'PresenceNodeEdge' } & {
              node?: Maybe<
                { __typename?: 'PresenceNode' } & Pick<
                  PresenceNode,
                  'id' | 'channelName' | 'firstSeen' | 'lastSeen' | 'onLine'
                >
              >;
            }
          >
        >;
      }
  >;
};

export type GetChatTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetChatTokenQuery = { __typename?: 'Query' } & {
  getChatToken?: Maybe<
    { __typename?: 'ChatTokenNode' } & Pick<ChatTokenNode, 'token' | 'id'>
  >;
};

export type CompanyBillingDataFragment = { __typename?: 'CompanyNode' } & Pick<
  CompanyNode,
  'id' | 'name' | 'customerName' | 'sdi' | 'hasBillingData'
> & {
    billingAddress?: Maybe<
      { __typename?: 'Address' } & Pick<
        Address,
        'city' | 'country' | 'line1' | 'line2' | 'state' | 'postalCode'
      >
    >;
    taxData?: Maybe<
      { __typename?: 'TaxNode' } & Pick<TaxNode, 'value' | 'type'>
    >;
  };

export type CompanyAddressDataFragment = { __typename?: 'CompanyNode' } & Pick<
  CompanyNode,
  'id'
> & {
    address?: Maybe<
      { __typename?: 'Address' } & Pick<
        Address,
        'city' | 'country' | 'line1' | 'line2' | 'state' | 'postalCode'
      >
    >;
  };

export type CompanyFragment = { __typename?: 'CompanyNode' } & Pick<
  CompanyNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'size'
  | 'websiteUrl'
  | 'phone'
  | 'customerName'
  | 'category'
  | 'status'
  | 'defaultLanguage'
  | 'enableLogoLink'
  | 'enableAuth'
  | 'homeJsxContent'
  | 'footerJsxContent'
> & {
    owner: { __typename?: 'UserNode' } & Pick<UserNode, 'id' | 'displayName'>;
    baseLogo?: Maybe<
      { __typename?: 'ImageFiledType' } & Pick<
        ImageFiledType,
        'name' | 'url'
      > & {
          thumb160?: Maybe<
            { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
          >;
        }
    >;
  };

export type GetCompanyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCompanyQuery = { __typename?: 'Query' } & {
  company?: Maybe<{ __typename?: 'CompanyNode' } & CompanyFragment>;
};

export type GetCompanyDefaultValueQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCompanyDefaultValueQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'CompanyNode' } & Pick<CompanyNode, 'name' | 'id'>
  >;
};

export type CompanyHomeJsxUpdateMutationVariables = Exact<{
  input: CompanyUpdateMutationInput;
}>;

export type CompanyHomeJsxUpdateMutation = { __typename?: 'Mutation' } & {
  companyUpdate?: Maybe<
    { __typename?: 'CompanyUpdateMutationPayload' } & Pick<
      CompanyUpdateMutationPayload,
      'clientMutationId'
    > & {
        company?: Maybe<
          { __typename?: 'CompanyNode' } & Pick<CompanyNode, 'homeJsxContent'>
        >;
      }
  >;
};

export type CompanyFooterJsxUpdateMutationVariables = Exact<{
  input: CompanyUpdateMutationInput;
}>;

export type CompanyFooterJsxUpdateMutation = { __typename?: 'Mutation' } & {
  companyUpdate?: Maybe<
    { __typename?: 'CompanyUpdateMutationPayload' } & Pick<
      CompanyUpdateMutationPayload,
      'clientMutationId'
    > & {
        company?: Maybe<
          { __typename?: 'CompanyNode' } & Pick<CompanyNode, 'footerJsxContent'>
        >;
      }
  >;
};

export type CompanyUpdateMutationVariables = Exact<{
  input: CompanyUpdateMutationInput;
}>;

export type CompanyUpdateMutation = { __typename?: 'Mutation' } & {
  companyUpdate?: Maybe<
    { __typename?: 'CompanyUpdateMutationPayload' } & Pick<
      CompanyUpdateMutationPayload,
      'clientMutationId'
    > & { company?: Maybe<{ __typename?: 'CompanyNode' } & CompanyFragment> }
  >;
};

export type CompanyDeleteMutationVariables = Exact<{
  input: CompanyDeleteMutationInput;
}>;

export type CompanyDeleteMutation = { __typename?: 'Mutation' } & {
  companyDelete?: Maybe<
    { __typename?: 'CompanyDeleteMutationPayload' } & Pick<
      CompanyDeleteMutationPayload,
      'clientMutationId'
    > & {
        company?: Maybe<
          { __typename?: 'CompanyNode' } & Pick<CompanyNode, 'id' | 'name'>
        >;
      }
  >;
};

export type CompanyCreateMutationVariables = Exact<{
  input: CompanyCreateMutationInput;
}>;

export type CompanyCreateMutation = { __typename?: 'Mutation' } & {
  companyCreate?: Maybe<
    { __typename?: 'CompanyCreateMutationPayload' } & Pick<
      CompanyCreateMutationPayload,
      'clientMutationId'
    > & { company?: Maybe<{ __typename?: 'CompanyNode' } & CompanyFragment> }
  >;
};

export type GetCompanyPrivateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCompanyPrivateQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'CompanyNode' } & Pick<
      CompanyNode,
      | 'id'
      | 'name'
      | 'slug'
      | 'homeJsxContent'
      | 'footerJsxContent'
      | 'frontendUrl'
      | 'defaultLanguage'
    > & {
        courses: { __typename?: 'CourseNodeConnection' } & Pick<
          CourseNodeConnection,
          'totalCount'
        >;
        defaultTheme?: Maybe<
          { __typename?: 'ThemeNode' } & Pick<
            ThemeNode,
            'id' | 'enableInDashboard'
          >
        >;
        baseLogo?: Maybe<
          { __typename?: 'ImageFiledType' } & Pick<
            ImageFiledType,
            'name' | 'url'
          > & {
              thumb160?: Maybe<
                { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
              >;
            }
        >;
      }
  >;
};

export type GetCompanyPrivateCategoriesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCompanyPrivateCategoriesQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'CompanyNode' } & {
      categories: { __typename?: 'CourseCategoryNodeConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'CourseCategoryNodeEdge' } & {
              node?: Maybe<
                { __typename?: 'CourseCategoryNode' } & Pick<
                  CourseCategoryNode,
                  'id' | 'name' | 'description' | 'order'
                > & {
                    courses: { __typename?: 'CourseNodeConnection' } & {
                      edges: Array<
                        Maybe<
                          { __typename?: 'CourseNodeEdge' } & {
                            node?: Maybe<
                              { __typename?: 'CourseNode' } & Pick<
                                CourseNode,
                                'id' | 'name' | 'label'
                              >
                            >;
                          }
                        >
                      >;
                    };
                  }
              >;
            }
          >
        >;
      };
    }
  >;
};

export type CompanyActiveUsersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CompanyActiveUsersQuery = { __typename?: 'Query' } & {
  company?: Maybe<
    { __typename?: 'CompanyNode' } & Pick<CompanyNode, 'id'> & {
        activeUsersMonthGroups?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ActiveUsersGroupsType' } & Pick<
                ActiveUsersGroupsType,
                'month' | 'count'
              >
            >
          >
        >;
      }
  >;
};

export type CompanyParticipationsMonthGroupCounterQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;

export type CompanyParticipationsMonthGroupCounterQuery = {
  __typename?: 'Query';
} & {
  allCompanyParticipations?: Maybe<
    { __typename?: 'CourseParticipationNodeConnection' } & Pick<
      CourseParticipationNodeConnection,
      'totalCount'
    > & {
        monthGroupCount?: Maybe<
          Array<
            Maybe<
              { __typename?: 'MonthGroupType' } & Pick<
                MonthGroupType,
                'month' | 'count'
              >
            >
          >
        >;
      }
  >;
};

export type CompanyCustomizationDataFragment = {
  __typename?: 'CompanyNode';
} & Pick<
  CompanyNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'phone'
  | 'websiteUrl'
  | 'customerName'
  | 'enableAuth'
  | 'enableLogoLink'
  | 'defaultLanguage'
  | 'footerJsxContent'
  | 'homeJsxContent'
> & {
    defaultTheme?: Maybe<
      { __typename?: 'ThemeNode' } & Pick<ThemeNode, 'id' | 'configuration'>
    >;
    profileForm?: Maybe<{ __typename?: 'SurveyNode' } & Pick<SurveyNode, 'id'>>;
    legalLinks?: Maybe<
      { __typename?: 'WebLinkModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'WebLinkModuleEdge' } & {
              node?: Maybe<
                { __typename?: 'WebLinkNode' } & Pick<
                  WebLinkNode,
                  'label' | 'url'
                >
              >;
            }
          >
        >;
      }
    >;
    address?: Maybe<
      { __typename?: 'Address' } & Pick<
        Address,
        'city' | 'country' | 'line1' | 'line2' | 'state' | 'postalCode'
      >
    >;
    theme?: Maybe<
      {
        __typename?: 'CompanyThemeCustomizationNode';
      } & ThemeCustomizationFragment
    >;
  };

export type PublicCompanyCustomizationDataFragment = {
  __typename?: 'PublicCompanyNode';
} & Pick<
  PublicCompanyNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'phone'
  | 'customerName'
  | 'websiteUrl'
  | 'enableAuth'
  | 'footerJsxContent'
  | 'homeJsxContent'
  | 'enableLogoLink'
> & {
    defaultTheme?: Maybe<
      { __typename?: 'ThemeNode' } & Pick<ThemeNode, 'id' | 'configuration'>
    >;
    legalLinks?: Maybe<
      { __typename?: 'WebLinkModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'WebLinkModuleEdge' } & {
              node?: Maybe<
                { __typename?: 'WebLinkNode' } & Pick<
                  WebLinkNode,
                  'label' | 'url'
                >
              >;
            }
          >
        >;
      }
    >;
    address?: Maybe<
      { __typename?: 'Address' } & Pick<
        Address,
        'city' | 'country' | 'line1' | 'line2' | 'state' | 'postalCode'
      >
    >;
    theme?: Maybe<
      {
        __typename?: 'CompanyThemeCustomizationNode';
      } & ThemeCustomizationFragment
    >;
  };

export type PublicCompanyBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type PublicCompanyBySlugQuery = { __typename?: 'Query' } & {
  publicCompanyBySlug?: Maybe<
    {
      __typename?: 'PublicCompanyNode';
    } & PublicCompanyCustomizationDataFragment
  >;
};

export type AllCompanySurveysQueryVariables = Exact<{
  companyId: Scalars['ID'];
  search?: Maybe<Scalars['String']>;
  type_In?: Maybe<Array<Maybe<SurveyType>> | Maybe<SurveyType>>;
}>;

export type AllCompanySurveysQuery = { __typename?: 'Query' } & {
  allCompanySurveys?: Maybe<
    { __typename?: 'SurveyNodeConnection' } & Pick<
      SurveyNodeConnection,
      'totalCount'
    > & {
        edges: Array<
          Maybe<
            { __typename?: 'SurveyNodeEdge' } & {
              node?: Maybe<
                { __typename?: 'SurveyNode' } & Pick<
                  SurveyNode,
                  'id' | 'name' | 'getTypeDisplay' | 'type' | 'label'
                > & {
                    questions?: Maybe<
                      { __typename?: 'QuestionConnection' } & Pick<
                        QuestionConnection,
                        'totalCount'
                      >
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type GetCompanyBillingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCompanyBillingQuery = { __typename?: 'Query' } & {
  company?: Maybe<{ __typename?: 'CompanyNode' } & CompanyBillingDataFragment>;
};

export type GetCompanyAddressQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCompanyAddressQuery = { __typename?: 'Query' } & {
  company?: Maybe<{ __typename?: 'CompanyNode' } & CompanyAddressDataFragment>;
};

export type UpdateCompanyBillingMutationVariables = Exact<{
  input: CompanyUpdateMutationInput;
}>;

export type UpdateCompanyBillingMutation = { __typename?: 'Mutation' } & {
  companyUpdate?: Maybe<
    { __typename?: 'CompanyUpdateMutationPayload' } & Pick<
      CompanyUpdateMutationPayload,
      'clientMutationId'
    > & {
        company?: Maybe<
          { __typename?: 'CompanyNode' } & CompanyBillingDataFragment
        >;
        errors?: Maybe<
          Array<
            { __typename?: 'MutationErrorType' } & Pick<
              MutationErrorType,
              'field' | 'message'
            >
          >
        >;
      }
  >;
};

export type UpdateCompanyAddressMutationVariables = Exact<{
  input: CompanyUpdateMutationInput;
}>;

export type UpdateCompanyAddressMutation = { __typename?: 'Mutation' } & {
  companyUpdate?: Maybe<
    { __typename?: 'CompanyUpdateMutationPayload' } & Pick<
      CompanyUpdateMutationPayload,
      'clientMutationId'
    > & {
        company?: Maybe<
          { __typename?: 'CompanyNode' } & CompanyAddressDataFragment
        >;
        errors?: Maybe<
          Array<
            { __typename?: 'MutationErrorType' } & Pick<
              MutationErrorType,
              'field' | 'message'
            >
          >
        >;
      }
  >;
};

export type CompanyThemeFragment = {
  __typename?: 'CompanyThemeCustomizationNode';
} & Pick<
  CompanyThemeCustomizationNode,
  'id' | 'type' | 'primary' | 'secondary'
>;

export type CompanyThemeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CompanyThemeQuery = { __typename?: 'Query' } & {
  companyTheme?: Maybe<
    { __typename?: 'CompanyThemeCustomizationNode' } & CompanyThemeFragment
  >;
};

export type CompanyThemeUpdateMutationVariables = Exact<{
  input: CompanyThemeCustomizationUpdateMutationInput;
}>;

export type CompanyThemeUpdateMutation = { __typename?: 'Mutation' } & {
  companyThemeUpdate?: Maybe<
    { __typename?: 'CompanyThemeCustomizationUpdateMutationPayload' } & Pick<
      CompanyThemeCustomizationUpdateMutationPayload,
      'clientMutationId'
    > & {
        theme?: Maybe<
          {
            __typename?: 'CompanyThemeCustomizationNode';
          } & CompanyThemeFragment
        >;
      }
  >;
};

export type ContactMutationVariables = Exact<{
  input: ContactMutationInput;
}>;

export type ContactMutation = { __typename?: 'Mutation' } & {
  contact?: Maybe<
    { __typename?: 'ContactMutationPayload' } & Pick<
      ContactMutationPayload,
      'status' | 'firstName'
    > & {
        errors?: Maybe<
          Array<
            { __typename?: 'MutationErrorType' } & Pick<
              MutationErrorType,
              'field' | 'message'
            >
          >
        >;
      }
  >;
};

export type GetCourseModuleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCourseModuleQuery = { __typename?: 'Query' } & {
  courseModule?: Maybe<
    | ({
        __typename?: 'SurveyModuleNode';
      } & CourseModuleUnionSurveyModuleNodeFragment)
    | ({
        __typename?: 'ExternalWebinarModuleNode';
      } & CourseModuleUnionExternalWebinarModuleNodeFragment)
    | ({
        __typename?: 'CourseModuleNode';
      } & CourseModuleUnionCourseModuleNodeFragment)
    | ({
        __typename?: 'ChatModuleNode';
      } & CourseModuleUnionChatModuleNodeFragment)
    | ({
        __typename?: 'VideoModuleNode';
      } & CourseModuleUnionVideoModuleNodeFragment)
    | ({
        __typename?: 'LayoutModuleNode';
      } & CourseModuleUnionLayoutModuleNodeFragment)
    | ({
        __typename?: 'DocumentModuleNode';
      } & CourseModuleUnionDocumentModuleNodeFragment)
    | ({
        __typename?: 'IframeModuleNode';
      } & CourseModuleUnionIframeModuleNodeFragment)
    | ({
        __typename?: 'VideowallModuleNode';
      } & CourseModuleUnionVideowallModuleNodeFragment)
  >;
};

export type ReminderNodeFragment = { __typename?: 'ReminderNode' } & Pick<
  ReminderNode,
  'id' | 'task' | 'name' | 'kwargs' | 'enabled'
> & {
    result?: Maybe<
      { __typename?: 'TaskResultNodeConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'TaskResultNodeEdge' } & {
              node?: Maybe<
                { __typename?: 'TaskResultNode' } & Pick<
                  TaskResultNode,
                  'status' | 'dateDone' | 'getStatusDisplay' | 'taskKwargs'
                >
              >;
            }
          >
        >;
      }
    >;
    clocked?: Maybe<
      { __typename?: 'ClockedScheduleNode' } & Pick<
        ClockedScheduleNode,
        'clockedTime' | 'id'
      >
    >;
  };

type CourseModuleUnionIdSurveyModuleNodeFragment = {
  __typename?: 'SurveyModuleNode';
} & Pick<SurveyModuleNode, 'id'>;

type CourseModuleUnionIdExternalWebinarModuleNodeFragment = {
  __typename?: 'ExternalWebinarModuleNode';
} & Pick<ExternalWebinarModuleNode, 'id'>;

type CourseModuleUnionIdCourseModuleNodeFragment = {
  __typename?: 'CourseModuleNode';
} & Pick<CourseModuleNode, 'id'>;

type CourseModuleUnionIdChatModuleNodeFragment = {
  __typename?: 'ChatModuleNode';
} & Pick<ChatModuleNode, 'id'>;

type CourseModuleUnionIdVideoModuleNodeFragment = {
  __typename?: 'VideoModuleNode';
} & Pick<VideoModuleNode, 'id'>;

type CourseModuleUnionIdLayoutModuleNodeFragment = {
  __typename?: 'LayoutModuleNode';
} & Pick<LayoutModuleNode, 'id'>;

type CourseModuleUnionIdDocumentModuleNodeFragment = {
  __typename?: 'DocumentModuleNode';
} & Pick<DocumentModuleNode, 'id'>;

type CourseModuleUnionIdIframeModuleNodeFragment = {
  __typename?: 'IframeModuleNode';
} & Pick<IframeModuleNode, 'id'>;

type CourseModuleUnionIdVideowallModuleNodeFragment = {
  __typename?: 'VideowallModuleNode';
} & Pick<VideowallModuleNode, 'id'>;

export type CourseModuleUnionIdFragment =
  | CourseModuleUnionIdSurveyModuleNodeFragment
  | CourseModuleUnionIdExternalWebinarModuleNodeFragment
  | CourseModuleUnionIdCourseModuleNodeFragment
  | CourseModuleUnionIdChatModuleNodeFragment
  | CourseModuleUnionIdVideoModuleNodeFragment
  | CourseModuleUnionIdLayoutModuleNodeFragment
  | CourseModuleUnionIdDocumentModuleNodeFragment
  | CourseModuleUnionIdIframeModuleNodeFragment
  | CourseModuleUnionIdVideowallModuleNodeFragment;

type CourseModuleUnionWithSubmodulesSurveyModuleNodeFragment = {
  __typename?: 'SurveyModuleNode';
} & SurveyModuleNodeWithSubmodulesFragment;

type CourseModuleUnionWithSubmodulesExternalWebinarModuleNodeFragment = {
  __typename?: 'ExternalWebinarModuleNode';
} & ExternalWebinarModuleNodeWithSubmodulesFragment;

type CourseModuleUnionWithSubmodulesCourseModuleNodeFragment = {
  __typename?: 'CourseModuleNode';
} & CourseModuleNodeWithSubmodulesFragment;

type CourseModuleUnionWithSubmodulesChatModuleNodeFragment = {
  __typename?: 'ChatModuleNode';
} & ChatModuleNodeWithSubmodulesFragment;

type CourseModuleUnionWithSubmodulesVideoModuleNodeFragment = {
  __typename?: 'VideoModuleNode';
} & VideoModuleNodeWithSubmodulesFragment;

type CourseModuleUnionWithSubmodulesLayoutModuleNodeFragment = {
  __typename?: 'LayoutModuleNode';
} & LayoutModuleNodeWithSubmodulesFragment;

type CourseModuleUnionWithSubmodulesDocumentModuleNodeFragment = {
  __typename?: 'DocumentModuleNode';
} & DocumentModuleNodeWithSubmodulesFragment;

type CourseModuleUnionWithSubmodulesIframeModuleNodeFragment = {
  __typename?: 'IframeModuleNode';
} & IframeModuleNodeWithSubmodulesFragment;

type CourseModuleUnionWithSubmodulesVideowallModuleNodeFragment = {
  __typename?: 'VideowallModuleNode';
} & VideowallModuleNodeWithSubmodulesFragment;

export type CourseModuleUnionWithSubmodulesFragment =
  | CourseModuleUnionWithSubmodulesSurveyModuleNodeFragment
  | CourseModuleUnionWithSubmodulesExternalWebinarModuleNodeFragment
  | CourseModuleUnionWithSubmodulesCourseModuleNodeFragment
  | CourseModuleUnionWithSubmodulesChatModuleNodeFragment
  | CourseModuleUnionWithSubmodulesVideoModuleNodeFragment
  | CourseModuleUnionWithSubmodulesLayoutModuleNodeFragment
  | CourseModuleUnionWithSubmodulesDocumentModuleNodeFragment
  | CourseModuleUnionWithSubmodulesIframeModuleNodeFragment
  | CourseModuleUnionWithSubmodulesVideowallModuleNodeFragment;

type CourseModuleUnionSurveyModuleNodeFragment = {
  __typename?: 'SurveyModuleNode';
} & SurveyModuleNodeFragment;

type CourseModuleUnionExternalWebinarModuleNodeFragment = {
  __typename?: 'ExternalWebinarModuleNode';
} & ExternalWebinarModuleNodeFragment;

type CourseModuleUnionCourseModuleNodeFragment = {
  __typename?: 'CourseModuleNode';
} & CourseModuleNodeFragment;

type CourseModuleUnionChatModuleNodeFragment = {
  __typename?: 'ChatModuleNode';
} & ChatModuleNodeFragment;

type CourseModuleUnionVideoModuleNodeFragment = {
  __typename?: 'VideoModuleNode';
} & VideoModuleNodeFragment;

type CourseModuleUnionLayoutModuleNodeFragment = {
  __typename?: 'LayoutModuleNode';
} & LayoutModuleNodeFragment;

type CourseModuleUnionDocumentModuleNodeFragment = {
  __typename?: 'DocumentModuleNode';
} & DocumentModuleNodeFragment;

type CourseModuleUnionIframeModuleNodeFragment = {
  __typename?: 'IframeModuleNode';
} & IframeModuleNodeFragment;

type CourseModuleUnionVideowallModuleNodeFragment = {
  __typename?: 'VideowallModuleNode';
} & VideowallModuleNodeFragment;

export type CourseModuleUnionFragment =
  | CourseModuleUnionSurveyModuleNodeFragment
  | CourseModuleUnionExternalWebinarModuleNodeFragment
  | CourseModuleUnionCourseModuleNodeFragment
  | CourseModuleUnionChatModuleNodeFragment
  | CourseModuleUnionVideoModuleNodeFragment
  | CourseModuleUnionLayoutModuleNodeFragment
  | CourseModuleUnionDocumentModuleNodeFragment
  | CourseModuleUnionIframeModuleNodeFragment
  | CourseModuleUnionVideowallModuleNodeFragment;

type CourseModuleUnionOverviewSurveyModuleNodeFragment = {
  __typename?: 'SurveyModuleNode';
} & SurveyModuleNodeOverviewFragment;

type CourseModuleUnionOverviewExternalWebinarModuleNodeFragment = {
  __typename?: 'ExternalWebinarModuleNode';
} & ExternalWebinarModuleNodeOverviewFragment;

type CourseModuleUnionOverviewCourseModuleNodeFragment = {
  __typename?: 'CourseModuleNode';
} & CourseModuleNodeOverviewFragment;

type CourseModuleUnionOverviewChatModuleNodeFragment = {
  __typename?: 'ChatModuleNode';
} & ChatModuleNodeOverviewFragment;

type CourseModuleUnionOverviewVideoModuleNodeFragment = {
  __typename?: 'VideoModuleNode';
} & VideoModuleNodeOverviewFragment;

type CourseModuleUnionOverviewLayoutModuleNodeFragment = {
  __typename?: 'LayoutModuleNode';
} & LayoutModuleNodeOverviewFragment;

type CourseModuleUnionOverviewDocumentModuleNodeFragment = {
  __typename?: 'DocumentModuleNode';
} & DocumentModuleNodeOverviewFragment;

type CourseModuleUnionOverviewIframeModuleNodeFragment = {
  __typename?: 'IframeModuleNode';
} & IframeModuleNodeOverviewFragment;

type CourseModuleUnionOverviewVideowallModuleNodeFragment = {
  __typename?: 'VideowallModuleNode';
} & VideowallModuleNodeOverviewFragment;

export type CourseModuleUnionOverviewFragment =
  | CourseModuleUnionOverviewSurveyModuleNodeFragment
  | CourseModuleUnionOverviewExternalWebinarModuleNodeFragment
  | CourseModuleUnionOverviewCourseModuleNodeFragment
  | CourseModuleUnionOverviewChatModuleNodeFragment
  | CourseModuleUnionOverviewVideoModuleNodeFragment
  | CourseModuleUnionOverviewLayoutModuleNodeFragment
  | CourseModuleUnionOverviewDocumentModuleNodeFragment
  | CourseModuleUnionOverviewIframeModuleNodeFragment
  | CourseModuleUnionOverviewVideowallModuleNodeFragment;

type CourseModuleUnionRemindersSurveyModuleNodeFragment = {
  __typename?: 'SurveyModuleNode';
} & {
  reminders?: Maybe<
    { __typename?: 'ReminderNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'ReminderNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'ReminderNode' } & ReminderNodeFragment
            >;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionRemindersExternalWebinarModuleNodeFragment = {
  __typename?: 'ExternalWebinarModuleNode';
} & {
  reminders?: Maybe<
    { __typename?: 'ReminderNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'ReminderNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'ReminderNode' } & ReminderNodeFragment
            >;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionRemindersCourseModuleNodeFragment = {
  __typename?: 'CourseModuleNode';
} & {
  reminders?: Maybe<
    { __typename?: 'ReminderNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'ReminderNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'ReminderNode' } & ReminderNodeFragment
            >;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionRemindersChatModuleNodeFragment = {
  __typename?: 'ChatModuleNode';
} & {
  reminders?: Maybe<
    { __typename?: 'ReminderNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'ReminderNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'ReminderNode' } & ReminderNodeFragment
            >;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionRemindersVideoModuleNodeFragment = {
  __typename?: 'VideoModuleNode';
} & {
  reminders?: Maybe<
    { __typename?: 'ReminderNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'ReminderNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'ReminderNode' } & ReminderNodeFragment
            >;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionRemindersLayoutModuleNodeFragment = {
  __typename?: 'LayoutModuleNode';
} & {
  reminders?: Maybe<
    { __typename?: 'ReminderNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'ReminderNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'ReminderNode' } & ReminderNodeFragment
            >;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionRemindersDocumentModuleNodeFragment = {
  __typename?: 'DocumentModuleNode';
} & {
  reminders?: Maybe<
    { __typename?: 'ReminderNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'ReminderNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'ReminderNode' } & ReminderNodeFragment
            >;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionRemindersIframeModuleNodeFragment = {
  __typename?: 'IframeModuleNode';
} & {
  reminders?: Maybe<
    { __typename?: 'ReminderNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'ReminderNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'ReminderNode' } & ReminderNodeFragment
            >;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionRemindersVideowallModuleNodeFragment = {
  __typename?: 'VideowallModuleNode';
} & {
  reminders?: Maybe<
    { __typename?: 'ReminderNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'ReminderNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'ReminderNode' } & ReminderNodeFragment
            >;
          }
        >
      >;
    }
  >;
};

export type CourseModuleUnionRemindersFragment =
  | CourseModuleUnionRemindersSurveyModuleNodeFragment
  | CourseModuleUnionRemindersExternalWebinarModuleNodeFragment
  | CourseModuleUnionRemindersCourseModuleNodeFragment
  | CourseModuleUnionRemindersChatModuleNodeFragment
  | CourseModuleUnionRemindersVideoModuleNodeFragment
  | CourseModuleUnionRemindersLayoutModuleNodeFragment
  | CourseModuleUnionRemindersDocumentModuleNodeFragment
  | CourseModuleUnionRemindersIframeModuleNodeFragment
  | CourseModuleUnionRemindersVideowallModuleNodeFragment;

type CourseModuleUnionOverviewWithPartecipationDataSurveyModuleNodeFragment = {
  __typename?: 'SurveyModuleNode';
} & {
  userModuleAssessment?: Maybe<
    | ({
        __typename?: 'CourseModuleAssessmentVideoNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentExternalWebinarNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentSurveyNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
  >;
} & SurveyModuleNodeOverviewFragment;

type CourseModuleUnionOverviewWithPartecipationDataExternalWebinarModuleNodeFragment =
  { __typename?: 'ExternalWebinarModuleNode' } & {
    userModuleAssessment?: Maybe<
      | ({
          __typename?: 'CourseModuleAssessmentVideoNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentExternalWebinarNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentSurveyNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
    >;
  } & ExternalWebinarModuleNodeOverviewFragment;

type CourseModuleUnionOverviewWithPartecipationDataCourseModuleNodeFragment = {
  __typename?: 'CourseModuleNode';
} & {
  userModuleAssessment?: Maybe<
    | ({
        __typename?: 'CourseModuleAssessmentVideoNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentExternalWebinarNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentSurveyNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
  >;
} & CourseModuleNodeOverviewFragment;

type CourseModuleUnionOverviewWithPartecipationDataChatModuleNodeFragment = {
  __typename?: 'ChatModuleNode';
} & {
  userModuleAssessment?: Maybe<
    | ({
        __typename?: 'CourseModuleAssessmentVideoNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentExternalWebinarNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentSurveyNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
  >;
} & ChatModuleNodeOverviewFragment;

type CourseModuleUnionOverviewWithPartecipationDataVideoModuleNodeFragment = {
  __typename?: 'VideoModuleNode';
} & {
  userModuleAssessment?: Maybe<
    | ({
        __typename?: 'CourseModuleAssessmentVideoNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment &
        CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentExternalWebinarNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment &
        CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentSurveyNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment &
        CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment &
        CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
  >;
} & VideoModuleNodeOverviewFragment &
  VideoModuleNodeOverviewFragment;

type CourseModuleUnionOverviewWithPartecipationDataLayoutModuleNodeFragment = {
  __typename?: 'LayoutModuleNode';
} & {
  userModuleAssessment?: Maybe<
    | ({
        __typename?: 'CourseModuleAssessmentVideoNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentExternalWebinarNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentSurveyNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
  >;
} & LayoutModuleNodeOverviewFragment;

type CourseModuleUnionOverviewWithPartecipationDataDocumentModuleNodeFragment =
  { __typename?: 'DocumentModuleNode' } & {
    userModuleAssessment?: Maybe<
      | ({
          __typename?: 'CourseModuleAssessmentVideoNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentExternalWebinarNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentSurveyNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
    >;
  } & DocumentModuleNodeOverviewFragment;

type CourseModuleUnionOverviewWithPartecipationDataIframeModuleNodeFragment = {
  __typename?: 'IframeModuleNode';
} & {
  userModuleAssessment?: Maybe<
    | ({
        __typename?: 'CourseModuleAssessmentVideoNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentExternalWebinarNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentSurveyNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
    | ({
        __typename?: 'CourseModuleAssessmentNode';
      } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
  >;
} & IframeModuleNodeOverviewFragment;

type CourseModuleUnionOverviewWithPartecipationDataVideowallModuleNodeFragment =
  { __typename?: 'VideowallModuleNode' };

export type CourseModuleUnionOverviewWithPartecipationDataFragment =
  | CourseModuleUnionOverviewWithPartecipationDataSurveyModuleNodeFragment
  | CourseModuleUnionOverviewWithPartecipationDataExternalWebinarModuleNodeFragment
  | CourseModuleUnionOverviewWithPartecipationDataCourseModuleNodeFragment
  | CourseModuleUnionOverviewWithPartecipationDataChatModuleNodeFragment
  | CourseModuleUnionOverviewWithPartecipationDataVideoModuleNodeFragment
  | CourseModuleUnionOverviewWithPartecipationDataLayoutModuleNodeFragment
  | CourseModuleUnionOverviewWithPartecipationDataDocumentModuleNodeFragment
  | CourseModuleUnionOverviewWithPartecipationDataIframeModuleNodeFragment
  | CourseModuleUnionOverviewWithPartecipationDataVideowallModuleNodeFragment;

type CourseModuleUnionAuthorsSurveyModuleNodeFragment = {
  __typename?: 'SurveyModuleNode';
} & {
  authors?: Maybe<
    { __typename?: 'AuthorModuleConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'AuthorModuleEdge' } & {
            node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionAuthorsExternalWebinarModuleNodeFragment = {
  __typename?: 'ExternalWebinarModuleNode';
} & {
  authors?: Maybe<
    { __typename?: 'AuthorModuleConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'AuthorModuleEdge' } & {
            node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionAuthorsCourseModuleNodeFragment = {
  __typename?: 'CourseModuleNode';
} & {
  authors?: Maybe<
    { __typename?: 'AuthorModuleConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'AuthorModuleEdge' } & {
            node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionAuthorsChatModuleNodeFragment = {
  __typename?: 'ChatModuleNode';
} & {
  authors?: Maybe<
    { __typename?: 'AuthorModuleConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'AuthorModuleEdge' } & {
            node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionAuthorsVideoModuleNodeFragment = {
  __typename?: 'VideoModuleNode';
} & {
  authors?: Maybe<
    { __typename?: 'AuthorModuleConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'AuthorModuleEdge' } & {
            node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionAuthorsLayoutModuleNodeFragment = {
  __typename?: 'LayoutModuleNode';
} & {
  authors?: Maybe<
    { __typename?: 'AuthorModuleConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'AuthorModuleEdge' } & {
            node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionAuthorsDocumentModuleNodeFragment = {
  __typename?: 'DocumentModuleNode';
} & {
  authors?: Maybe<
    { __typename?: 'AuthorModuleConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'AuthorModuleEdge' } & {
            node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionAuthorsIframeModuleNodeFragment = {
  __typename?: 'IframeModuleNode';
} & {
  authors?: Maybe<
    { __typename?: 'AuthorModuleConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'AuthorModuleEdge' } & {
            node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
          }
        >
      >;
    }
  >;
};

type CourseModuleUnionAuthorsVideowallModuleNodeFragment = {
  __typename?: 'VideowallModuleNode';
} & {
  authors?: Maybe<
    { __typename?: 'AuthorModuleConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'AuthorModuleEdge' } & {
            node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
          }
        >
      >;
    }
  >;
};

export type CourseModuleUnionAuthorsFragment =
  | CourseModuleUnionAuthorsSurveyModuleNodeFragment
  | CourseModuleUnionAuthorsExternalWebinarModuleNodeFragment
  | CourseModuleUnionAuthorsCourseModuleNodeFragment
  | CourseModuleUnionAuthorsChatModuleNodeFragment
  | CourseModuleUnionAuthorsVideoModuleNodeFragment
  | CourseModuleUnionAuthorsLayoutModuleNodeFragment
  | CourseModuleUnionAuthorsDocumentModuleNodeFragment
  | CourseModuleUnionAuthorsIframeModuleNodeFragment
  | CourseModuleUnionAuthorsVideowallModuleNodeFragment;

export type AuthorsGroupNodeFragment = {
  __typename?: 'AuthorsGroupNode';
} & Pick<AuthorsGroupNode, 'id' | 'name' | 'description'> & {
    authors: { __typename?: 'AuthorNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'AuthorNodeEdge' } & {
            node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
          }
        >
      >;
    };
  };

export type AuthorNodeFragment = { __typename?: 'AuthorNode' } & Pick<
  AuthorNode,
  'id' | 'title' | 'firstName' | 'lastName' | 'headline' | 'shortBio'
> & {
    avatar?: Maybe<
      { __typename?: 'ImageFiledType' } & {
        thumbnail?: Maybe<
          { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
        >;
      }
    >;
  };

export type GetAuthorByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetAuthorByIdQuery = { __typename?: 'Query' } & {
  author?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
};

export type GetAuthorsGroupByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetAuthorsGroupByIdQuery = { __typename?: 'Query' } & {
  authorsGroup?: Maybe<
    { __typename?: 'AuthorsGroupNode' } & Pick<
      AuthorsGroupNode,
      'id' | 'name' | 'description'
    > & {
        authors: { __typename?: 'AuthorNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'AuthorNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'AuthorNode' } & AuthorNodeFragment
                >;
              }
            >
          >;
        };
      }
  >;
};

export type CourseAuthorsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CourseAuthorsQuery = { __typename?: 'Query' } & {
  courseAuthors?: Maybe<
    { __typename?: 'AuthorNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'AuthorNodeEdge' } & {
            node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
          }
        >
      >;
    }
  >;
};

export type CourseModuleCertificateMapsQueryVariables = Exact<{
  moduleId: Scalars['ID'];
}>;

export type CourseModuleCertificateMapsQuery = { __typename?: 'Query' } & {
  courseModuleCertificateMaps?: Maybe<
    { __typename?: 'CourseModuleCertificateTemplateMapNodeConnection' } & Pick<
      CourseModuleCertificateTemplateMapNodeConnection,
      'totalCount'
    > & {
        edges: Array<
          Maybe<
            { __typename?: 'CourseModuleCertificateTemplateMapNodeEdge' } & {
              node?: Maybe<
                {
                  __typename?: 'CourseModuleCertificateTemplateMapNode';
                } & Pick<
                  CourseModuleCertificateTemplateMapNode,
                  | 'id'
                  | 'sendMail'
                  | 'generationRole'
                  | 'availableFrom'
                  | 'availableUntil'
                  | 'getGenerationRoleDisplay'
                > & {
                    coursemodule: { __typename?: 'CourseModuleNode' } & Pick<
                      CourseModuleNode,
                      'id'
                    >;
                    certificateTemplate: {
                      __typename?: 'CertificateTemplateNode';
                    } & Pick<
                      CertificateTemplateNode,
                      | 'id'
                      | 'emailBody'
                      | 'customSubject'
                      | 'certificateFileName'
                    > & {
                        file?: Maybe<
                          { __typename?: 'FileFiledType' } & Pick<
                            FileFiledType,
                            'name' | 'url'
                          >
                        >;
                      };
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type CourseModuleCertificateMapQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CourseModuleCertificateMapQuery = { __typename?: 'Query' } & {
  courseModuleCertificateMap?: Maybe<
    { __typename?: 'CourseModuleCertificateTemplateMapNode' } & Pick<
      CourseModuleCertificateTemplateMapNode,
      | 'id'
      | 'sendMail'
      | 'generationRole'
      | 'getGenerationRoleDisplay'
      | 'availableFrom'
      | 'availableUntil'
    > & {
        coursemodule: { __typename?: 'CourseModuleNode' } & Pick<
          CourseModuleNode,
          'id'
        >;
        certificateTemplate: { __typename?: 'CertificateTemplateNode' } & Pick<
          CertificateTemplateNode,
          'id' | 'emailBody' | 'customSubject' | 'certificateFileName'
        > & {
            file?: Maybe<
              { __typename?: 'FileFiledType' } & Pick<
                FileFiledType,
                'name' | 'url'
              >
            >;
          };
      }
  >;
};

export type CourseCertificateMapQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CourseCertificateMapQuery = { __typename?: 'Query' } & {
  courseCertificateMap?: Maybe<
    { __typename?: 'CourseCertificateTemplateMapNode' } & Pick<
      CourseCertificateTemplateMapNode,
      | 'id'
      | 'sendMail'
      | 'availableFrom'
      | 'availableUntil'
      | 'generationRole'
      | 'getGenerationRoleDisplay'
    > & {
        course: { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'>;
        certificateTemplate: { __typename?: 'CertificateTemplateNode' } & Pick<
          CertificateTemplateNode,
          'id' | 'emailBody' | 'customSubject' | 'certificateFileName'
        > & {
            file?: Maybe<
              { __typename?: 'FileFiledType' } & Pick<
                FileFiledType,
                'name' | 'url'
              >
            >;
          };
      }
  >;
};

export type CourseCertificateMapsQueryVariables = Exact<{
  courseId: Scalars['ID'];
}>;

export type CourseCertificateMapsQuery = { __typename?: 'Query' } & {
  courseCertificateMaps?: Maybe<
    { __typename?: 'CourseCertificateTemplateMapNodeConnection' } & Pick<
      CourseCertificateTemplateMapNodeConnection,
      'totalCount'
    > & {
        edges: Array<
          Maybe<
            { __typename?: 'CourseCertificateTemplateMapNodeEdge' } & {
              node?: Maybe<
                { __typename?: 'CourseCertificateTemplateMapNode' } & Pick<
                  CourseCertificateTemplateMapNode,
                  | 'id'
                  | 'sendMail'
                  | 'availableFrom'
                  | 'availableUntil'
                  | 'generationRole'
                  | 'getGenerationRoleDisplay'
                > & {
                    course: { __typename?: 'CourseNode' } & Pick<
                      CourseNode,
                      'id'
                    >;
                    certificateTemplate: {
                      __typename?: 'CertificateTemplateNode';
                    } & Pick<
                      CertificateTemplateNode,
                      | 'id'
                      | 'emailBody'
                      | 'customSubject'
                      | 'certificateFileName'
                    > & {
                        file?: Maybe<
                          { __typename?: 'FileFiledType' } & Pick<
                            FileFiledType,
                            'name' | 'url'
                          >
                        >;
                      };
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type CertificateTemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CertificateTemplateQuery = { __typename?: 'Query' } & {
  certificateTemplate?: Maybe<
    { __typename?: 'CertificateTemplateNode' } & Pick<
      CertificateTemplateNode,
      'id' | 'emailBody' | 'customSubject' | 'certificateFileName'
    > & {
        file?: Maybe<
          { __typename?: 'FileFiledType' } & Pick<FileFiledType, 'name' | 'url'>
        >;
      }
  >;
};

export type AllPrivateCoursesForOptionsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
}>;

export type AllPrivateCoursesForOptionsQuery = { __typename?: 'Query' } & {
  allPrivateCourses?: Maybe<
    { __typename?: 'CourseNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'CourseNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'CourseNode' } & Pick<
                CourseNode,
                'id' | 'name' | 'label'
              > & {
                  cover?: Maybe<
                    { __typename?: 'ImageFiledType' } & {
                      thumbnail?: Maybe<
                        { __typename?: 'ThumbnailType' } & Pick<
                          ThumbnailType,
                          'url'
                        >
                      >;
                    }
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
};

export type AllPrivateCoursesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
}>;

export type AllPrivateCoursesQuery = { __typename?: 'Query' } & {
  allPrivateCourses?: Maybe<
    { __typename?: 'CourseNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'CourseNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'CourseNode' } & Pick<
                CourseNode,
                | 'id'
                | 'name'
                | 'headline'
                | 'overview'
                | 'description'
                | 'slug'
                | 'listed'
                | 'label'
                | 'startDate'
                | 'endDate'
                | 'frontendUrl'
                | 'dashboardUrl'
              > & {
                  theme?: Maybe<
                    { __typename?: 'ThemeNode' } & Pick<
                      ThemeNode,
                      'id' | 'name'
                    >
                  >;
                  cover?: Maybe<
                    { __typename?: 'ImageFiledType' } & {
                      thumbnail?: Maybe<
                        { __typename?: 'ThumbnailType' } & Pick<
                          ThumbnailType,
                          'url'
                        >
                      >;
                    }
                  >;
                  sites: { __typename?: 'SiteNodeConnection' } & {
                    edges: Array<
                      Maybe<
                        { __typename?: 'SiteNodeEdge' } & {
                          node?: Maybe<
                            { __typename?: 'SiteNode' } & Pick<
                              SiteNode,
                              'domain' | 'id' | 'name'
                            >
                          >;
                        }
                      >
                    >;
                  };
                  company?: Maybe<
                    { __typename?: 'CompanyNode' } & Pick<
                      CompanyNode,
                      'name'
                    > & {
                        defaultTheme?: Maybe<
                          { __typename?: 'ThemeNode' } & Pick<
                            ThemeNode,
                            'id' | 'name'
                          >
                        >;
                        sites: { __typename?: 'SiteNodeConnection' } & {
                          edges: Array<
                            Maybe<
                              { __typename?: 'SiteNodeEdge' } & {
                                node?: Maybe<
                                  { __typename?: 'SiteNode' } & Pick<
                                    SiteNode,
                                    'domain' | 'id' | 'name'
                                  >
                                >;
                              }
                            >
                          >;
                        };
                      }
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
};

export type AllPublicCoursesQueryVariables = Exact<{
  categoriesName?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  listed?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Scalars['ID']>;
}>;

export type AllPublicCoursesQuery = { __typename?: 'Query' } & {
  allPublicCourses?: Maybe<
    { __typename?: 'CourseNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'CourseNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'CourseNode' } & Pick<
                CourseNode,
                | 'id'
                | 'name'
                | 'headline'
                | 'overview'
                | 'description'
                | 'slug'
                | 'listed'
                | 'startDate'
                | 'endDate'
              > & {
                  theme?: Maybe<
                    { __typename?: 'ThemeNode' } & Pick<ThemeNode, 'id'>
                  >;
                  cover?: Maybe<
                    { __typename?: 'ImageFiledType' } & {
                      thumbnail?: Maybe<
                        { __typename?: 'ThumbnailType' } & Pick<
                          ThumbnailType,
                          'url'
                        >
                      >;
                    }
                  >;
                  company?: Maybe<
                    { __typename?: 'CompanyNode' } & Pick<
                      CompanyNode,
                      'name'
                    > & {
                        defaultTheme?: Maybe<
                          { __typename?: 'ThemeNode' } & Pick<ThemeNode, 'id'>
                        >;
                      }
                  >;
                  participation?: Maybe<
                    { __typename?: 'CourseParticipationNode' } & Pick<
                      CourseParticipationNode,
                      'id' | 'status'
                    >
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
};

export type AllCourseCategoriesQueryVariables = Exact<{
  isAvailable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  listed?: Maybe<Scalars['Boolean']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;

export type AllCourseCategoriesQuery = { __typename?: 'Query' } & {
  allCourseCategories?: Maybe<
    { __typename?: 'CourseCategoryNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'CourseCategoryNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'CourseCategoryNode' } & Pick<
                CourseCategoryNode,
                'id' | 'name' | 'description'
              > & {
                  courses: { __typename?: 'CourseNodeConnection' } & {
                    edges: Array<
                      Maybe<
                        { __typename?: 'CourseNodeEdge' } & {
                          node?: Maybe<
                            { __typename?: 'CourseNode' } & Pick<
                              CourseNode,
                              | 'id'
                              | 'name'
                              | 'headline'
                              | 'overview'
                              | 'label'
                              | 'frontendUrl'
                              | 'dashboardUrl'
                              | 'slug'
                              | 'listed'
                              | 'startDate'
                              | 'available'
                              | 'endDate'
                            > & {
                                owner: { __typename?: 'UserNode' } & Pick<
                                  UserNode,
                                  'id' | 'displayName' | 'email'
                                >;
                                participation?: Maybe<
                                  {
                                    __typename?: 'CourseParticipationNode';
                                  } & Pick<
                                    CourseParticipationNode,
                                    'id' | 'status' | 'created'
                                  >
                                >;
                                userHasPermission?: Maybe<
                                  { __typename?: 'CoursePermission' } & Pick<
                                    CoursePermission,
                                    | 'viewCourse'
                                    | 'changeCourse'
                                    | 'moderateChatCourse'
                                    | 'manageParticipationCourse'
                                    | 'manageInvitationsCourse'
                                    | 'addParticipationCourse'
                                    | 'downloadFullReport'
                                    | 'anyPermission'
                                  >
                                >;
                                theme?: Maybe<
                                  { __typename?: 'ThemeNode' } & Pick<
                                    ThemeNode,
                                    'id'
                                  >
                                >;
                                cover?: Maybe<
                                  { __typename?: 'ImageFiledType' } & {
                                    thumbnail?: Maybe<
                                      { __typename?: 'ThumbnailType' } & Pick<
                                        ThumbnailType,
                                        'url'
                                      >
                                    >;
                                  }
                                >;
                                background?: Maybe<
                                  { __typename?: 'ImageFiledType' } & {
                                    thumbnail?: Maybe<
                                      { __typename?: 'ThumbnailType' } & Pick<
                                        ThumbnailType,
                                        'url'
                                      >
                                    >;
                                  }
                                >;
                                company?: Maybe<
                                  { __typename?: 'CompanyNode' } & Pick<
                                    CompanyNode,
                                    'id' | 'name'
                                  > & {
                                      defaultTheme?: Maybe<
                                        { __typename?: 'ThemeNode' } & Pick<
                                          ThemeNode,
                                          'id'
                                        >
                                      >;
                                    }
                                >;
                                authorsGroups: {
                                  __typename?: 'AuthorsGroupNodeConnection';
                                } & {
                                  edges: Array<
                                    Maybe<
                                      {
                                        __typename?: 'AuthorsGroupNodeEdge';
                                      } & {
                                        node?: Maybe<
                                          {
                                            __typename?: 'AuthorsGroupNode';
                                          } & AuthorsGroupNodeFragment
                                        >;
                                      }
                                    >
                                  >;
                                };
                              }
                          >;
                        }
                      >
                    >;
                  };
                }
            >;
          }
        >
      >;
    }
  >;
};

export type AllCourseCategoriesNamesQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  ids?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;

export type AllCourseCategoriesNamesQuery = { __typename?: 'Query' } & {
  allCourseCategories?: Maybe<
    { __typename?: 'CourseCategoryNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'CourseCategoryNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'CourseCategoryNode' } & Pick<
                CourseCategoryNode,
                'id' | 'name' | 'description'
              >
            >;
          }
        >
      >;
    }
  >;
};

export type GetCourseByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCourseByIdQuery = { __typename?: 'Query' } & {
  course?: Maybe<{ __typename?: 'CourseNode' } & CourseFieldsFragment>;
};

export type GetCommonCourseInfoBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetCommonCourseInfoBySlugQuery = { __typename?: 'Query' } & {
  publicCourseBySlug?: Maybe<
    { __typename?: 'CourseNode' } & Pick<
      CourseNode,
      | 'id'
      | 'name'
      | 'slug'
      | 'overview'
      | 'code'
      | 'demo'
      | 'headline'
      | 'description'
      | 'created'
      | 'startDate'
      | 'layoutType'
      | 'navigationType'
      | 'jsxContent'
      | 'displayParticipationSuccessToast'
      | 'participationCustomToastSuccessMessage'
      | 'displayEndCourseContent'
      | 'endCourseContent'
      | 'endDate'
      | 'authorListLabel'
      | 'participationExternalLink'
      | 'participationsCount'
      | 'defaultLanguage'
      | 'displayParticipantsCounter'
      | 'displayContinueButton'
      | 'acceptAnonyumousEnrollment'
      | 'forceAnonyumousEnrollment'
      | 'onlyOneParticipationPerInvitation'
      | 'signupButtonEnable'
      | 'loginGoogleButtonEnable'
      | 'forceDisplayAuthComponentIfNotLogged'
      | 'requireDataParticipation'
      | 'acceptsParticipationByExternalUuid'
      | 'externalUuidParameterName'
      | 'externalUuidParameterRequired'
      | 'generateParticipationQrcode'
    > & {
        mosaico?: Maybe<
          { __typename?: 'MosaicoNode' } & Pick<MosaicoNode, 'id'>
        >;
        commonAuthors: { __typename?: 'AuthorNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'AuthorNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'AuthorNode' } & Pick<AuthorNode, 'id'>
                >;
              }
            >
          >;
        };
        authorsGroups: { __typename?: 'AuthorsGroupNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'AuthorsGroupNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'AuthorsGroupNode' } & AuthorsGroupNodeFragment
                >;
              }
            >
          >;
        };
        authors?: Maybe<
          { __typename?: 'AuthorModuleConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'AuthorModuleEdge' } & {
                  node?: Maybe<
                    { __typename?: 'AuthorNode' } & AuthorNodeFragment
                  >;
                }
              >
            >;
          }
        >;
        tags?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TagType' } & Pick<TagType, 'name' | 'slug' | 'id'>
            >
          >
        >;
        theme?: Maybe<
          { __typename?: 'ThemeNode' } & Pick<
            ThemeNode,
            'id' | 'enableInDashboard'
          >
        >;
        participationForm?: Maybe<
          { __typename?: 'SurveyNode' } & Pick<SurveyNode, 'id'>
        >;
        cover?: Maybe<
          { __typename?: 'ImageFiledType' } & {
            thumbnail?: Maybe<
              { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
            >;
          }
        >;
        background?: Maybe<
          { __typename?: 'ImageFiledType' } & {
            thumbnail?: Maybe<
              { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
            >;
          }
        >;
        backgroundFullScreen?: Maybe<
          { __typename?: 'ImageFiledType' } & {
            thumbnail?: Maybe<
              { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
            >;
          }
        >;
      }
  >;
};

export type GetCourseSubmodulesOverviewQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetCourseSubmodulesOverviewQuery = { __typename?: 'Query' } & {
  publicCourseBySlug?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
        submodules?: Maybe<
          { __typename?: 'CourseModuleConnection' } & Pick<
            CourseModuleConnection,
            'totalCount'
          > & {
              edges: Array<
                Maybe<
                  { __typename?: 'CourseModuleEdge' } & {
                    node?: Maybe<
                      | ({
                          __typename?: 'SurveyModuleNode';
                        } & CourseModuleUnionOverviewSurveyModuleNodeFragment)
                      | ({
                          __typename?: 'ExternalWebinarModuleNode';
                        } & CourseModuleUnionOverviewExternalWebinarModuleNodeFragment)
                      | ({
                          __typename?: 'CourseModuleNode';
                        } & CourseModuleUnionOverviewCourseModuleNodeFragment)
                      | ({
                          __typename?: 'ChatModuleNode';
                        } & CourseModuleUnionOverviewChatModuleNodeFragment)
                      | ({
                          __typename?: 'VideoModuleNode';
                        } & CourseModuleUnionOverviewVideoModuleNodeFragment)
                      | ({
                          __typename?: 'LayoutModuleNode';
                        } & CourseModuleUnionOverviewLayoutModuleNodeFragment)
                      | ({
                          __typename?: 'DocumentModuleNode';
                        } & CourseModuleUnionOverviewDocumentModuleNodeFragment)
                      | ({
                          __typename?: 'IframeModuleNode';
                        } & CourseModuleUnionOverviewIframeModuleNodeFragment)
                      | ({
                          __typename?: 'VideowallModuleNode';
                        } & CourseModuleUnionOverviewVideowallModuleNodeFragment)
                    >;
                  }
                >
              >;
            }
        >;
      }
  >;
};

export type GetCourseSubmodulesWithParticipationDataQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetCourseSubmodulesWithParticipationDataQuery = {
  __typename?: 'Query';
} & {
  publicCourseBySlug?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
        submodules?: Maybe<
          { __typename?: 'CourseModuleConnection' } & Pick<
            CourseModuleConnection,
            'totalCount'
          > & {
              edges: Array<
                Maybe<
                  { __typename?: 'CourseModuleEdge' } & {
                    node?: Maybe<
                      | ({
                          __typename?: 'SurveyModuleNode';
                        } & CourseModuleUnionOverviewWithPartecipationDataSurveyModuleNodeFragment)
                      | ({
                          __typename?: 'ExternalWebinarModuleNode';
                        } & CourseModuleUnionOverviewWithPartecipationDataExternalWebinarModuleNodeFragment)
                      | ({
                          __typename?: 'CourseModuleNode';
                        } & CourseModuleUnionOverviewWithPartecipationDataCourseModuleNodeFragment)
                      | ({
                          __typename?: 'ChatModuleNode';
                        } & CourseModuleUnionOverviewWithPartecipationDataChatModuleNodeFragment)
                      | ({
                          __typename?: 'VideoModuleNode';
                        } & CourseModuleUnionOverviewWithPartecipationDataVideoModuleNodeFragment)
                      | ({
                          __typename?: 'LayoutModuleNode';
                        } & CourseModuleUnionOverviewWithPartecipationDataLayoutModuleNodeFragment)
                      | ({
                          __typename?: 'DocumentModuleNode';
                        } & CourseModuleUnionOverviewWithPartecipationDataDocumentModuleNodeFragment)
                      | ({
                          __typename?: 'IframeModuleNode';
                        } & CourseModuleUnionOverviewWithPartecipationDataIframeModuleNodeFragment)
                      | ({
                          __typename?: 'VideowallModuleNode';
                        } & CourseModuleUnionOverviewWithPartecipationDataVideowallModuleNodeFragment)
                    >;
                  }
                >
              >;
            }
        >;
      }
  >;
};

export type GetCourseOverviewContentQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetCourseOverviewContentQuery = { __typename?: 'Query' } & {
  publicCourseBySlug?: Maybe<
    { __typename?: 'CourseNode' } & Pick<
      CourseNode,
      | 'id'
      | 'description'
      | 'jsxContent'
      | 'pdfProgramButtonLabel'
      | 'authorListLabel'
    > & {
        mosaico?: Maybe<
          { __typename?: 'MosaicoNode' } & Pick<MosaicoNode, 'id'>
        >;
        pdfProgram?: Maybe<
          { __typename?: 'FileFiledType' } & Pick<
            FileFiledType,
            'fileName' | 'url' | 'extension'
          >
        >;
        authorsGroups: { __typename?: 'AuthorsGroupNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'AuthorsGroupNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'AuthorsGroupNode' } & AuthorsGroupNodeFragment
                >;
              }
            >
          >;
        };
        authors?: Maybe<
          { __typename?: 'AuthorModuleConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'AuthorModuleEdge' } & {
                  node?: Maybe<
                    { __typename?: 'AuthorNode' } & AuthorNodeFragment
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type GetCourseNextWebinarQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetCourseNextWebinarQuery = { __typename?: 'Query' } & {
  publicCourseBySlug?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
        nextWebinar?: Maybe<
          { __typename?: 'ExternalWebinarModuleNode' } & Pick<
            ExternalWebinarModuleNode,
            | 'name'
            | 'status'
            | 'id'
            | 'plannedStartDate'
            | 'plannedEndDate'
            | 'testingMode'
          >
        >;
      }
  >;
};

export type GetCoursePermissionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCoursePermissionQuery = { __typename?: 'Query' } & {
  course?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id' | 'slug'> & {
        userHasPermission?: Maybe<
          { __typename?: 'CoursePermission' } & Pick<
            CoursePermission,
            | 'viewCourse'
            | 'changeCourse'
            | 'moderateChatCourse'
            | 'manageParticipationCourse'
            | 'manageInvitationsCourse'
            | 'addParticipationCourse'
            | 'downloadFullReport'
            | 'anyPermission'
          >
        >;
      }
  >;
};

export type GetCourseParticipationFormQuestionsQueryVariables = Exact<{
  courseId: Scalars['ID'];
}>;

export type GetCourseParticipationFormQuestionsQuery = {
  __typename?: 'Query';
} & {
  course?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
        participationForm?: Maybe<
          { __typename?: 'SurveyNode' } & Pick<SurveyNode, 'id'> & {
              questions?: Maybe<
                { __typename?: 'QuestionConnection' } & {
                  edges: Array<
                    Maybe<
                      { __typename?: 'QuestionEdge' } & {
                        node?: Maybe<
                          { __typename: 'QuestionNode' } & Pick<
                            QuestionNode,
                            | 'id'
                            | 'question'
                            | 'title'
                            | 'label'
                            | 'description'
                            | 'placeholder'
                            | 'helpText'
                            | 'type'
                            | 'slug'
                            | 'enumType'
                            | 'required'
                            | 'staffOnly'
                          > & {
                              choiches?: Maybe<
                                Array<
                                  Maybe<
                                    {
                                      __typename?: 'QuestionChoicheItem';
                                    } & Pick<
                                      QuestionChoicheItem,
                                      'value' | 'label'
                                    >
                                  >
                                >
                              >;
                            }
                        >;
                      }
                    >
                  >;
                }
              >;
            }
        >;
      }
  >;
};

export type GetCourseInvitationsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCourseInvitationsQuery = { __typename?: 'Query' } & {
  course?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
        invitations: { __typename?: 'CourseInvitationNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'CourseInvitationNodeEdge' } & {
                node?: Maybe<
                  {
                    __typename?: 'CourseInvitationNode';
                  } & CourseInvitationtNodeFragment
                >;
              }
            >
          >;
        };
      }
  >;
};

export type GetPublicCourseAuthorsQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetPublicCourseAuthorsQuery = { __typename?: 'Query' } & {
  publicCourseBySlug?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
        authorsGroups: { __typename?: 'AuthorsGroupNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'AuthorsGroupNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'AuthorsGroupNode' } & AuthorsGroupNodeFragment
                >;
              }
            >
          >;
        };
        commonAuthors: { __typename?: 'AuthorNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'AuthorNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'AuthorNode' } & AuthorNodeFragment
                >;
              }
            >
          >;
        };
        authors?: Maybe<
          { __typename?: 'AuthorModuleConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'AuthorModuleEdge' } & {
                  node?: Maybe<
                    { __typename?: 'AuthorNode' } & AuthorNodeFragment
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type GetCourseAuthorsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCourseAuthorsQuery = { __typename?: 'Query' } & {
  course?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
        authorsGroups: { __typename?: 'AuthorsGroupNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'AuthorsGroupNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'AuthorsGroupNode' } & AuthorsGroupNodeFragment
                >;
              }
            >
          >;
        };
        commonAuthors: { __typename?: 'AuthorNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'AuthorNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'AuthorNode' } & AuthorNodeFragment
                >;
              }
            >
          >;
        };
        authors?: Maybe<
          { __typename?: 'AuthorModuleConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'AuthorModuleEdge' } & {
                  node?: Maybe<
                    { __typename?: 'AuthorNode' } & AuthorNodeFragment
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type PublicCourseBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type PublicCourseBySlugQuery = { __typename?: 'Query' } & {
  publicCourseBySlug?: Maybe<
    { __typename?: 'CourseNode' } & Pick<
      CourseNode,
      | 'id'
      | 'name'
      | 'slug'
      | 'overview'
      | 'code'
      | 'demo'
      | 'headline'
      | 'description'
      | 'created'
      | 'startDate'
      | 'endDate'
      | 'layoutType'
      | 'navigationType'
      | 'jsxContent'
      | 'displayEndCourseContent'
      | 'endCourseContent'
      | 'displayParticipationSuccessToast'
      | 'participationCustomToastSuccessMessage'
      | 'participationExternalLink'
      | 'participationsCount'
      | 'defaultLanguage'
      | 'displayParticipantsCounter'
      | 'displayContinueButton'
      | 'acceptAnonyumousEnrollment'
      | 'forceAnonyumousEnrollment'
      | 'onlyOneParticipationPerInvitation'
      | 'signupButtonEnable'
      | 'loginGoogleButtonEnable'
      | 'forceDisplayAuthComponentIfNotLogged'
      | 'requireDataParticipation'
      | 'acceptsParticipationByExternalUuid'
      | 'externalUuidParameterName'
      | 'externalUuidParameterRequired'
      | 'generateParticipationQrcode'
      | 'pdfProgramButtonLabel'
      | 'authorListLabel'
    > & {
        mosaico?: Maybe<
          { __typename?: 'MosaicoNode' } & Pick<MosaicoNode, 'id'>
        >;
        tags?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TagType' } & Pick<TagType, 'name' | 'slug' | 'id'>
            >
          >
        >;
        theme?: Maybe<
          { __typename?: 'ThemeNode' } & Pick<
            ThemeNode,
            'id' | 'enableInDashboard'
          >
        >;
        participationForm?: Maybe<
          { __typename?: 'SurveyNode' } & Pick<SurveyNode, 'id'>
        >;
        userHasPermission?: Maybe<
          { __typename?: 'CoursePermission' } & Pick<
            CoursePermission,
            | 'viewCourse'
            | 'changeCourse'
            | 'moderateChatCourse'
            | 'manageParticipationCourse'
            | 'manageInvitationsCourse'
            | 'addParticipationCourse'
            | 'downloadFullReport'
            | 'anyPermission'
          >
        >;
        pdfProgram?: Maybe<
          { __typename?: 'FileFiledType' } & Pick<
            FileFiledType,
            'fileName' | 'url' | 'extension'
          >
        >;
        cover?: Maybe<
          { __typename?: 'ImageFiledType' } & {
            thumbnail?: Maybe<
              { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
            >;
          }
        >;
        background?: Maybe<
          { __typename?: 'ImageFiledType' } & {
            thumbnail?: Maybe<
              { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
            >;
          }
        >;
        backgroundFullScreen?: Maybe<
          { __typename?: 'ImageFiledType' } & {
            thumbnail?: Maybe<
              { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
            >;
          }
        >;
        authorsGroups: { __typename?: 'AuthorsGroupNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'AuthorsGroupNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'AuthorsGroupNode' } & AuthorsGroupNodeFragment
                >;
              }
            >
          >;
        };
        authors?: Maybe<
          { __typename?: 'AuthorModuleConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'AuthorModuleEdge' } & {
                  node?: Maybe<
                    { __typename?: 'AuthorNode' } & AuthorNodeFragment
                  >;
                }
              >
            >;
          }
        >;
        participation?: Maybe<
          { __typename?: 'CourseParticipationNode' } & Pick<
            CourseParticipationNode,
            'id' | 'status' | 'data'
          > & {
              qrcode?: Maybe<
                { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'url'>
              >;
              lockedModules?: Maybe<
                Array<
                  Maybe<
                    | ({
                        __typename?: 'SurveyModuleNode';
                      } & CourseModuleUnionIdSurveyModuleNodeFragment)
                    | ({
                        __typename?: 'ExternalWebinarModuleNode';
                      } & CourseModuleUnionIdExternalWebinarModuleNodeFragment)
                    | ({
                        __typename?: 'CourseModuleNode';
                      } & CourseModuleUnionIdCourseModuleNodeFragment)
                    | ({
                        __typename?: 'ChatModuleNode';
                      } & CourseModuleUnionIdChatModuleNodeFragment)
                    | ({
                        __typename?: 'VideoModuleNode';
                      } & CourseModuleUnionIdVideoModuleNodeFragment)
                    | ({
                        __typename?: 'LayoutModuleNode';
                      } & CourseModuleUnionIdLayoutModuleNodeFragment)
                    | ({
                        __typename?: 'DocumentModuleNode';
                      } & CourseModuleUnionIdDocumentModuleNodeFragment)
                    | ({
                        __typename?: 'IframeModuleNode';
                      } & CourseModuleUnionIdIframeModuleNodeFragment)
                    | ({
                        __typename?: 'VideowallModuleNode';
                      } & CourseModuleUnionIdVideowallModuleNodeFragment)
                  >
                >
              >;
            }
        >;
        submodules?: Maybe<
          { __typename?: 'CourseModuleConnection' } & Pick<
            CourseModuleConnection,
            'totalCount'
          > & {
              edges: Array<
                Maybe<
                  { __typename?: 'CourseModuleEdge' } & {
                    node?: Maybe<
                      | ({
                          __typename?: 'SurveyModuleNode';
                        } & CourseModuleUnionSurveyModuleNodeFragment)
                      | ({
                          __typename?: 'ExternalWebinarModuleNode';
                        } & CourseModuleUnionExternalWebinarModuleNodeFragment)
                      | ({
                          __typename?: 'CourseModuleNode';
                        } & CourseModuleUnionCourseModuleNodeFragment)
                      | ({
                          __typename?: 'ChatModuleNode';
                        } & CourseModuleUnionChatModuleNodeFragment)
                      | ({
                          __typename?: 'VideoModuleNode';
                        } & CourseModuleUnionVideoModuleNodeFragment)
                      | ({
                          __typename?: 'LayoutModuleNode';
                        } & CourseModuleUnionLayoutModuleNodeFragment)
                      | ({
                          __typename?: 'DocumentModuleNode';
                        } & CourseModuleUnionDocumentModuleNodeFragment)
                      | ({
                          __typename?: 'IframeModuleNode';
                        } & CourseModuleUnionIframeModuleNodeFragment)
                      | ({
                          __typename?: 'VideowallModuleNode';
                        } & CourseModuleUnionVideowallModuleNodeFragment)
                    >;
                  }
                >
              >;
            }
        >;
      }
  >;
};

export type GetCourseCategoriesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCourseCategoriesQuery = { __typename?: 'Query' } & {
  course?: Maybe<
    { __typename?: 'CourseNode' } & {
      categories: { __typename?: 'CourseCategoryNodeConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'CourseCategoryNodeEdge' } & {
              node?: Maybe<
                { __typename?: 'CourseCategoryNode' } & Pick<
                  CourseCategoryNode,
                  'id' | 'name' | 'description' | 'order'
                >
              >;
            }
          >
        >;
      };
    }
  >;
};

export type GetCourseGroupsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCourseGroupsQuery = { __typename?: 'Query' } & {
  course?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
        groups: { __typename?: 'GroupNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'GroupNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'GroupNode' } & Pick<
                    GroupNode,
                    'id' | 'name' | 'label'
                  >
                >;
              }
            >
          >;
        };
      }
  >;
};

export type GetCourseGuestsCounterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCourseGuestsCounterQuery = { __typename?: 'Query' } & {
  course?: Maybe<
    { __typename?: 'CourseNode' } & Pick<
      CourseNode,
      'id' | 'participationsCount'
    > & {
        activeUsersMonthGroups?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ActiveUsersGroupsType' } & Pick<
                ActiveUsersGroupsType,
                'month' | 'count'
              >
            >
          >
        >;
      }
  >;
};

export type CourseParticipationImportFileQueryVariables = Exact<{
  courseParticipationImportFileId: Scalars['ID'];
}>;

export type CourseParticipationImportFileQuery = { __typename?: 'Query' } & {
  courseParticipationImportFile?: Maybe<
    { __typename?: 'CourseParticipationImportFileNode' } & {
      file?: Maybe<
        { __typename?: 'FileFiledType' } & Pick<
          FileFiledType,
          'name' | 'extension' | 'url'
        >
      >;
    }
  >;
};

export type GetCourseInvitationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCourseInvitationQuery = { __typename?: 'Query' } & {
  courseInvitation?: Maybe<
    { __typename?: 'CourseInvitationNode' } & CourseInvitationtNodeFragment
  >;
};

export type GetMyCourseInvitationsQueryVariables = Exact<{
  owner?: Maybe<Scalars['ID']>;
  course?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;

export type GetMyCourseInvitationsQuery = { __typename?: 'Query' } & {
  courseInvitations?: Maybe<
    { __typename?: 'CourseInvitationNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'CourseInvitationNodeEdge' } & {
            node?: Maybe<
              {
                __typename?: 'CourseInvitationNode';
              } & CourseInvitationtNodeFragment
            >;
          }
        >
      >;
    }
  >;
};

export type UserMatchInvitationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserMatchInvitationQuery = { __typename?: 'Query' } & {
  userMatchInvitation?: Maybe<
    { __typename?: 'MatchInvitationType' } & Pick<
      MatchInvitationType,
      'match' | 'hideEmail' | 'email' | 'autoCreateParticipation'
    >
  >;
};

export type CourseModuleNodeWithSubmodulesFragment = {
  __typename?: 'CourseModuleNode';
} & Pick<
  CourseModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'order'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'moduleLockedCustomText'
  | 'forceLocked'
  | 'availableFrom'
  | 'availableUntil'
  | 'accessMode'
  | 'sendEmailWhenCompleted'
> & {
    submodules?: Maybe<
      { __typename?: 'CourseModuleConnection' } & Pick<
        CourseModuleConnection,
        'totalCount'
      > & {
          edges: Array<
            Maybe<
              { __typename?: 'CourseModuleEdge' } & {
                node?: Maybe<
                  | ({
                      __typename?: 'SurveyModuleNode';
                    } & CourseModuleUnionSurveyModuleNodeFragment)
                  | ({
                      __typename?: 'ExternalWebinarModuleNode';
                    } & CourseModuleUnionExternalWebinarModuleNodeFragment)
                  | ({
                      __typename?: 'CourseModuleNode';
                    } & CourseModuleUnionCourseModuleNodeFragment)
                  | ({
                      __typename?: 'ChatModuleNode';
                    } & CourseModuleUnionChatModuleNodeFragment)
                  | ({
                      __typename?: 'VideoModuleNode';
                    } & CourseModuleUnionVideoModuleNodeFragment)
                  | ({
                      __typename?: 'LayoutModuleNode';
                    } & CourseModuleUnionLayoutModuleNodeFragment)
                  | ({
                      __typename?: 'DocumentModuleNode';
                    } & CourseModuleUnionDocumentModuleNodeFragment)
                  | ({
                      __typename?: 'IframeModuleNode';
                    } & CourseModuleUnionIframeModuleNodeFragment)
                  | ({
                      __typename?: 'VideowallModuleNode';
                    } & CourseModuleUnionVideowallModuleNodeFragment)
                >;
              }
            >
          >;
        }
    >;
  };

export type CourseModuleNodeOverviewFragment = {
  __typename?: 'CourseModuleNode';
} & Pick<
  CourseModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'order'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'moduleLockedCustomText'
  | 'forceLocked'
  | 'availableFrom'
  | 'availableUntil'
  | 'accessMode'
  | 'jsxContent'
>;

export type CourseModuleNodeFragment = {
  __typename?: 'CourseModuleNode';
} & Pick<
  CourseModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'order'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'sendEmailWhenCompleted'
  | 'jsxContent'
  | 'overview'
  | 'headline'
  | 'description'
  | 'moduleOptions'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'availableFromCustomText'
  | 'moduleLockedCustomText'
  | 'privacy'
> & {
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
    userModuleAssessment?: Maybe<
      | ({
          __typename?: 'CourseModuleAssessmentVideoNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentExternalWebinarNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentSurveyNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
    >;
    externalwebinarmodule?: Maybe<
      { __typename?: 'ExternalWebinarModuleNode' } & Pick<
        ExternalWebinarModuleNode,
        'id'
      >
    >;
    iframemodule?: Maybe<
      { __typename?: 'IframeModuleNode' } & Pick<IframeModuleNode, 'id'>
    >;
  };

export type GetModuleWithSubModulesQueryVariables = Exact<{
  courseModuleId: Scalars['ID'];
}>;

export type GetModuleWithSubModulesQuery = { __typename?: 'Query' } & {
  courseModule?: Maybe<
    | ({
        __typename?: 'SurveyModuleNode';
      } & CourseModuleUnionWithSubmodulesSurveyModuleNodeFragment)
    | ({
        __typename?: 'ExternalWebinarModuleNode';
      } & CourseModuleUnionWithSubmodulesExternalWebinarModuleNodeFragment)
    | ({
        __typename?: 'CourseModuleNode';
      } & CourseModuleUnionWithSubmodulesCourseModuleNodeFragment)
    | ({
        __typename?: 'ChatModuleNode';
      } & CourseModuleUnionWithSubmodulesChatModuleNodeFragment)
    | ({
        __typename?: 'VideoModuleNode';
      } & CourseModuleUnionWithSubmodulesVideoModuleNodeFragment)
    | ({
        __typename?: 'LayoutModuleNode';
      } & CourseModuleUnionWithSubmodulesLayoutModuleNodeFragment)
    | ({
        __typename?: 'DocumentModuleNode';
      } & CourseModuleUnionWithSubmodulesDocumentModuleNodeFragment)
    | ({
        __typename?: 'IframeModuleNode';
      } & CourseModuleUnionWithSubmodulesIframeModuleNodeFragment)
    | ({
        __typename?: 'VideowallModuleNode';
      } & CourseModuleUnionWithSubmodulesVideowallModuleNodeFragment)
  >;
};

export type GetModuleRemindersQueryVariables = Exact<{
  courseModuleId: Scalars['ID'];
}>;

export type GetModuleRemindersQuery = { __typename?: 'Query' } & {
  courseModule?: Maybe<
    | ({
        __typename?: 'SurveyModuleNode';
      } & CourseModuleUnionRemindersSurveyModuleNodeFragment)
    | ({
        __typename?: 'ExternalWebinarModuleNode';
      } & CourseModuleUnionRemindersExternalWebinarModuleNodeFragment)
    | ({
        __typename?: 'CourseModuleNode';
      } & CourseModuleUnionRemindersCourseModuleNodeFragment)
    | ({
        __typename?: 'ChatModuleNode';
      } & CourseModuleUnionRemindersChatModuleNodeFragment)
    | ({
        __typename?: 'VideoModuleNode';
      } & CourseModuleUnionRemindersVideoModuleNodeFragment)
    | ({
        __typename?: 'LayoutModuleNode';
      } & CourseModuleUnionRemindersLayoutModuleNodeFragment)
    | ({
        __typename?: 'DocumentModuleNode';
      } & CourseModuleUnionRemindersDocumentModuleNodeFragment)
    | ({
        __typename?: 'IframeModuleNode';
      } & CourseModuleUnionRemindersIframeModuleNodeFragment)
    | ({
        __typename?: 'VideowallModuleNode';
      } & CourseModuleUnionRemindersVideowallModuleNodeFragment)
  >;
};

export type GetModuleAuthorsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetModuleAuthorsQuery = { __typename?: 'Query' } & {
  courseModule?: Maybe<
    | ({
        __typename?: 'SurveyModuleNode';
      } & CourseModuleUnionAuthorsSurveyModuleNodeFragment)
    | ({
        __typename?: 'ExternalWebinarModuleNode';
      } & CourseModuleUnionAuthorsExternalWebinarModuleNodeFragment)
    | ({
        __typename?: 'CourseModuleNode';
      } & CourseModuleUnionAuthorsCourseModuleNodeFragment)
    | ({
        __typename?: 'ChatModuleNode';
      } & CourseModuleUnionAuthorsChatModuleNodeFragment)
    | ({
        __typename?: 'VideoModuleNode';
      } & CourseModuleUnionAuthorsVideoModuleNodeFragment)
    | ({
        __typename?: 'LayoutModuleNode';
      } & CourseModuleUnionAuthorsLayoutModuleNodeFragment)
    | ({
        __typename?: 'DocumentModuleNode';
      } & CourseModuleUnionAuthorsDocumentModuleNodeFragment)
    | ({
        __typename?: 'IframeModuleNode';
      } & CourseModuleUnionAuthorsIframeModuleNodeFragment)
    | ({
        __typename?: 'VideowallModuleNode';
      } & CourseModuleUnionAuthorsVideowallModuleNodeFragment)
  >;
};

export type CourseModuleAssessmentSurveyNodeFragment = {
  __typename?: 'CourseModuleAssessmentSurveyNode';
} & Pick<
  CourseModuleAssessmentSurveyNode,
  | 'id'
  | 'status'
  | 'attemptNumber'
  | 'attemptsAvailable'
  | 'numberOfAttempts'
  | 'minimumScore'
  | 'minimumScoreType'
> & {
    surveyResponse?: Maybe<
      { __typename?: 'SurveyResponseNode' } & Pick<
        SurveyResponseNode,
        'id' | 'completed' | 'score' | 'maxScore'
      >
    >;
  };

export type CourseModuleAssessmentExternalWebinarNodeFragment = {
  __typename?: 'CourseModuleAssessmentExternalWebinarNode';
} & Pick<
  CourseModuleAssessmentExternalWebinarNode,
  'id' | 'status' | 'requiredUserPresencePercentile'
>;

export type CourseModuleAssessmentVideoNodeFragment = {
  __typename?: 'CourseModuleAssessmentVideoNode';
} & Pick<CourseModuleAssessmentVideoNode, 'id' | 'status'>;

export type CourseModuleAssessmentChatNodeFragment = {
  __typename?: 'CourseModuleAssessmentExternalWebinarNode';
} & Pick<CourseModuleAssessmentExternalWebinarNode, 'id' | 'status'>;

export type CourseModuleAssessmentNodeFragment = {
  __typename?: 'CourseModuleAssessmentNode';
} & Pick<CourseModuleAssessmentNode, 'id' | 'status'>;

type CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment = {
  __typename: 'CourseModuleAssessmentVideoNode';
} & CourseModuleAssessmentVideoNodeFragment;

type CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment =
  {
    __typename: 'CourseModuleAssessmentExternalWebinarNode';
  } & CourseModuleAssessmentExternalWebinarNodeFragment;

type CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment = {
  __typename: 'CourseModuleAssessmentSurveyNode';
} & CourseModuleAssessmentSurveyNodeFragment;

type CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment = {
  __typename: 'CourseModuleAssessmentNode';
} & CourseModuleAssessmentNodeFragment;

export type CourseModuleAssessmentUnionFragment =
  | CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment
  | CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment
  | CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment
  | CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment;

export type VideoCourseModuleAssessmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type VideoCourseModuleAssessmentQuery = { __typename?: 'Query' } & {
  videoCourseModuleAssessment?: Maybe<
    { __typename?: 'CourseModuleAssessmentVideoNode' } & Pick<
      CourseModuleAssessmentVideoNode,
      'id' | 'userLivePercentile' | 'videoPlayedSeconds' | 'status'
    >
  >;
};

export type ChatModuleNodeWithSubmodulesFragment = {
  __typename?: 'ChatModuleNode';
} & Pick<
  ChatModuleNode,
  | 'id'
  | 'name'
  | 'order'
  | 'sendEmailWhenCompleted'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'mode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'moduleLockedCustomText'
  | 'threadsEnabled'
  | 'broadcastChannelEnabled'
> & {
    submodules?: Maybe<
      { __typename?: 'CourseModuleConnection' } & Pick<
        CourseModuleConnection,
        'totalCount'
      > & {
          edges: Array<
            Maybe<
              { __typename?: 'CourseModuleEdge' } & {
                node?: Maybe<
                  | ({
                      __typename?: 'SurveyModuleNode';
                    } & CourseModuleUnionSurveyModuleNodeFragment)
                  | ({
                      __typename?: 'ExternalWebinarModuleNode';
                    } & CourseModuleUnionExternalWebinarModuleNodeFragment)
                  | ({
                      __typename?: 'CourseModuleNode';
                    } & CourseModuleUnionCourseModuleNodeFragment)
                  | ({
                      __typename?: 'ChatModuleNode';
                    } & CourseModuleUnionChatModuleNodeFragment)
                  | ({
                      __typename?: 'VideoModuleNode';
                    } & CourseModuleUnionVideoModuleNodeFragment)
                  | ({
                      __typename?: 'LayoutModuleNode';
                    } & CourseModuleUnionLayoutModuleNodeFragment)
                  | ({
                      __typename?: 'DocumentModuleNode';
                    } & CourseModuleUnionDocumentModuleNodeFragment)
                  | ({
                      __typename?: 'IframeModuleNode';
                    } & CourseModuleUnionIframeModuleNodeFragment)
                  | ({
                      __typename?: 'VideowallModuleNode';
                    } & CourseModuleUnionVideowallModuleNodeFragment)
                >;
              }
            >
          >;
        }
    >;
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type ChatModuleNodeOverviewFragment = {
  __typename?: 'ChatModuleNode';
} & Pick<
  ChatModuleNode,
  | 'id'
  | 'name'
  | 'order'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'availableFrom'
  | 'moduleLockedCustomText'
  | 'forceLocked'
  | 'accessMode'
  | 'jsxContent'
  | 'mode'
  | 'threadsEnabled'
  | 'broadcastChannelEnabled'
> & {
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type ChatModuleNodeFragment = { __typename?: 'ChatModuleNode' } & Pick<
  ChatModuleNode,
  | 'id'
  | 'name'
  | 'headline'
  | 'overview'
  | 'description'
  | 'jsxContent'
  | 'order'
  | 'slug'
  | 'privacy'
  | 'frontendUrl'
  | 'moduleOptions'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'availableFrom'
  | 'availableFromCustomText'
  | 'moduleLockedCustomText'
  | 'forceLocked'
  | 'accessMode'
  | 'chatModeratorUserId'
  | 'chatModeratorUserToken'
  | 'threadsEnabled'
  | 'broadcastChannelEnabled'
  | 'sendEmailWhenCompleted'
  | 'mode'
> & {
    chatChannels?: Maybe<
      Array<
        Maybe<
          { __typename?: 'ChatChannelNode' } & Pick<
            ChatChannelNode,
            'id' | 'channelType' | 'url'
          >
        >
      >
    >;
    userModuleAssessment?: Maybe<
      | ({
          __typename?: 'CourseModuleAssessmentVideoNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentExternalWebinarNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentSurveyNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
    >;
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type DocumentModuleNodeWithSubmodulesFragment = {
  __typename?: 'DocumentModuleNode';
} & Pick<
  DocumentModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'order'
  | 'sendEmailWhenCompleted'
  | 'routable'
  | 'privacy'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'availableFrom'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'forceLocked'
  | 'availableUntil'
  | 'accessMode'
  | 'moduleLockedCustomText'
> & {
    document?: Maybe<
      { __typename?: 'FileFiledType' } & Pick<
        FileFiledType,
        'fileName' | 'url' | 'extension'
      >
    >;
    submodules?: Maybe<
      { __typename?: 'CourseModuleConnection' } & Pick<
        CourseModuleConnection,
        'totalCount'
      > & {
          edges: Array<
            Maybe<
              { __typename?: 'CourseModuleEdge' } & {
                node?: Maybe<
                  | ({
                      __typename?: 'SurveyModuleNode';
                    } & CourseModuleUnionSurveyModuleNodeFragment)
                  | ({
                      __typename?: 'ExternalWebinarModuleNode';
                    } & CourseModuleUnionExternalWebinarModuleNodeFragment)
                  | ({
                      __typename?: 'CourseModuleNode';
                    } & CourseModuleUnionCourseModuleNodeFragment)
                  | ({
                      __typename?: 'ChatModuleNode';
                    } & CourseModuleUnionChatModuleNodeFragment)
                  | ({
                      __typename?: 'VideoModuleNode';
                    } & CourseModuleUnionVideoModuleNodeFragment)
                  | ({
                      __typename?: 'LayoutModuleNode';
                    } & CourseModuleUnionLayoutModuleNodeFragment)
                  | ({
                      __typename?: 'DocumentModuleNode';
                    } & CourseModuleUnionDocumentModuleNodeFragment)
                  | ({
                      __typename?: 'IframeModuleNode';
                    } & CourseModuleUnionIframeModuleNodeFragment)
                  | ({
                      __typename?: 'VideowallModuleNode';
                    } & CourseModuleUnionVideowallModuleNodeFragment)
                >;
              }
            >
          >;
        }
    >;
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type DocumentModuleNodeOverviewFragment = {
  __typename?: 'DocumentModuleNode';
} & Pick<
  DocumentModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'order'
  | 'privacy'
  | 'routable'
  | 'routableExact'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'moduleLockedCustomText'
  | 'jsxContent'
> & {
    document?: Maybe<
      { __typename?: 'FileFiledType' } & Pick<
        FileFiledType,
        'fileName' | 'url' | 'extension'
      >
    >;
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type DocumentModuleNodeFragment = {
  __typename?: 'DocumentModuleNode';
} & Pick<
  DocumentModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'privacy'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'availableUntil'
  | 'forceLocked'
  | 'assessmentsCounter'
  | 'sendEmailWhenCompleted'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'availableFrom'
  | 'availableFromCustomText'
  | 'moduleLockedCustomText'
  | 'order'
  | 'headline'
  | 'overview'
  | 'description'
  | 'jsxContent'
> & {
    document?: Maybe<
      { __typename?: 'FileFiledType' } & Pick<
        FileFiledType,
        'fileName' | 'url' | 'extension'
      >
    >;
    userModuleAssessment?: Maybe<
      | ({
          __typename?: 'CourseModuleAssessmentVideoNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentExternalWebinarNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentSurveyNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
    >;
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type ExternalWebinarModuleNodeWithSubmodulesFragment = {
  __typename?: 'ExternalWebinarModuleNode';
} & Pick<
  ExternalWebinarModuleNode,
  | 'id'
  | 'name'
  | 'order'
  | 'sendEmailWhenCompleted'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'status'
  | 'plannedStartDate'
  | 'plannedEndDate'
  | 'moduleLockedCustomText'
  | 'headline'
> & {
    submodules?: Maybe<
      { __typename?: 'CourseModuleConnection' } & Pick<
        CourseModuleConnection,
        'totalCount'
      > & {
          edges: Array<
            Maybe<
              { __typename?: 'CourseModuleEdge' } & {
                node?: Maybe<
                  | ({
                      __typename?: 'SurveyModuleNode';
                    } & CourseModuleUnionSurveyModuleNodeFragment)
                  | ({
                      __typename?: 'ExternalWebinarModuleNode';
                    } & CourseModuleUnionExternalWebinarModuleNodeFragment)
                  | ({
                      __typename?: 'CourseModuleNode';
                    } & CourseModuleUnionCourseModuleNodeFragment)
                  | ({
                      __typename?: 'ChatModuleNode';
                    } & CourseModuleUnionChatModuleNodeFragment)
                  | ({
                      __typename?: 'VideoModuleNode';
                    } & CourseModuleUnionVideoModuleNodeFragment)
                  | ({
                      __typename?: 'LayoutModuleNode';
                    } & CourseModuleUnionLayoutModuleNodeFragment)
                  | ({
                      __typename?: 'DocumentModuleNode';
                    } & CourseModuleUnionDocumentModuleNodeFragment)
                  | ({
                      __typename?: 'IframeModuleNode';
                    } & CourseModuleUnionIframeModuleNodeFragment)
                  | ({
                      __typename?: 'VideowallModuleNode';
                    } & CourseModuleUnionVideowallModuleNodeFragment)
                >;
              }
            >
          >;
        }
    >;
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type ExternalWebinarModuleNodeOverviewFragment = {
  __typename?: 'ExternalWebinarModuleNode';
} & Pick<
  ExternalWebinarModuleNode,
  | 'id'
  | 'name'
  | 'order'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'availableFrom'
  | 'availableUntil'
  | 'disableCheckComplete'
  | 'forceLocked'
  | 'accessMode'
  | 'status'
  | 'plannedStartDate'
  | 'plannedEndDate'
  | 'moduleLockedCustomText'
  | 'headline'
  | 'jsxContent'
> & {
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type ExternalWebinarModuleNodeFragment = {
  __typename?: 'ExternalWebinarModuleNode';
} & Pick<
  ExternalWebinarModuleNode,
  | 'id'
  | 'name'
  | 'description'
  | 'headline'
  | 'overview'
  | 'jsxContent'
  | 'order'
  | 'privacy'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'sendEmailWhenCompleted'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'liveUrl'
  | 'recordedUrl'
  | 'iframeMode'
  | 'requiredUserPresencePercentile'
  | 'moduleOptions'
  | 'externalLink'
  | 'externalLinkName'
  | 'status'
  | 'isOver'
  | 'liveTime'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'availableFromCustomText'
  | 'moduleLockedCustomText'
  | 'chatEnabled'
  | 'wallChannelEnabled'
  | 'broadcastChannelEnabled'
  | 'plannedStartDate'
  | 'plannedEndDate'
  | 'chatModeratorUserId'
  | 'chatModeratorUserToken'
> & {
    liveMeeting?: Maybe<
      { __typename?: 'ZoomMeetingNode' } & Pick<
        ZoomMeetingNode,
        'id' | 'meetingPwd' | 'meetingNo' | 'topic' | 'startTime' | 'data'
      >
    >;
    iframeProps?: Maybe<
      Array<
        { __typename?: 'HtmlAttributeNode' } & Pick<
          HtmlAttributeNode,
          'key' | 'value'
        >
      >
    >;
    wallChatChannels?: Maybe<
      Array<
        Maybe<
          { __typename?: 'ChatChannelNode' } & Pick<
            ChatChannelNode,
            'id' | 'channelType' | 'url'
          >
        >
      >
    >;
    chatChannels?: Maybe<
      Array<
        Maybe<
          { __typename?: 'ChatChannelNode' } & Pick<
            ChatChannelNode,
            'id' | 'channelType' | 'url'
          >
        >
      >
    >;
    variants?: Maybe<
      Array<
        { __typename?: 'WebinarVariant' } & Pick<
          WebinarVariant,
          'idx' | 'liveUrl' | 'recordedUrl' | 'iframeMode' | 'name'
        > & {
            iframeProps?: Maybe<
              Array<
                { __typename?: 'HtmlAttributeNode' } & Pick<
                  HtmlAttributeNode,
                  'key' | 'value'
                >
              >
            >;
          }
      >
    >;
    videowall?: Maybe<
      { __typename?: 'VideowallModuleNode' } & VideowallModuleNodeFragment
    >;
    livePoll?: Maybe<
      { __typename?: 'SurveyModuleNode' } & PollModuleNodeFragment
    >;
    userModuleAssessment?: Maybe<
      | ({
          __typename?: 'CourseModuleAssessmentVideoNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentExternalWebinarNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentSurveyNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
    >;
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type GetExternalWebinarModuleUpdateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetExternalWebinarModuleUpdateQuery = { __typename?: 'Query' } & {
  courseModule?: Maybe<
    | { __typename?: 'SurveyModuleNode' }
    | ({
        __typename?: 'ExternalWebinarModuleNode';
      } & ExternalWebinarModuleNodeFragment)
    | { __typename?: 'CourseModuleNode' }
    | { __typename?: 'ChatModuleNode' }
    | { __typename?: 'VideoModuleNode' }
    | { __typename?: 'LayoutModuleNode' }
    | { __typename?: 'DocumentModuleNode' }
    | { __typename?: 'IframeModuleNode' }
    | { __typename?: 'VideowallModuleNode' }
  >;
};

export type GetExternalWebinarCurrentStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetExternalWebinarCurrentStatusQuery = { __typename?: 'Query' } & {
  courseModule?: Maybe<
    | { __typename?: 'SurveyModuleNode' }
    | ({ __typename?: 'ExternalWebinarModuleNode' } & Pick<
        ExternalWebinarModuleNode,
        'id' | 'status'
      > & {
          lastStatusEvent?: Maybe<
            { __typename?: 'ExternalWebinarStatusEventNode' } & Pick<
              ExternalWebinarStatusEventNode,
              'timestamp' | 'status'
            > & {
                user?: Maybe<
                  { __typename?: 'UserNode' } & Pick<UserNode, 'id' | 'email'>
                >;
              }
          >;
        })
    | { __typename?: 'CourseModuleNode' }
    | { __typename?: 'ChatModuleNode' }
    | { __typename?: 'VideoModuleNode' }
    | { __typename?: 'LayoutModuleNode' }
    | { __typename?: 'DocumentModuleNode' }
    | { __typename?: 'IframeModuleNode' }
    | { __typename?: 'VideowallModuleNode' }
  >;
};

export type ExternalWebinarModuleChangeStatusMutationVariables = Exact<{
  input: ExternalWebinarModuleChangeStatusMutationInput;
}>;

export type ExternalWebinarModuleChangeStatusMutation = {
  __typename?: 'Mutation';
} & {
  externalWebinarModuleChangeStatus?: Maybe<
    { __typename?: 'ExternalWebinarModuleChangeStatusMutationPayload' } & Pick<
      ExternalWebinarModuleChangeStatusMutationPayload,
      'lockedWarning'
    > & {
        externalWebinarModule?: Maybe<
          { __typename?: 'ExternalWebinarModuleNode' } & Pick<
            ExternalWebinarModuleNode,
            'id' | 'status'
          > & {
              lastStatusEvent?: Maybe<
                { __typename?: 'ExternalWebinarStatusEventNode' } & Pick<
                  ExternalWebinarStatusEventNode,
                  'timestamp' | 'status'
                > & {
                    user?: Maybe<
                      { __typename?: 'UserNode' } & Pick<
                        UserNode,
                        'id' | 'email'
                      >
                    >;
                  }
              >;
            }
        >;
        errors?: Maybe<
          Array<
            { __typename?: 'MutationErrorType' } & Pick<
              MutationErrorType,
              'field' | 'message'
            >
          >
        >;
      }
  >;
};

export type WebinarModuleClearTimeIntervalsMutationVariables = Exact<{
  input: WebinarModuleClearTimeIntervalsInput;
}>;

export type WebinarModuleClearTimeIntervalsMutation = {
  __typename?: 'Mutation';
} & {
  webinarModuleClearTimeIntervals?: Maybe<
    { __typename?: 'WebinarModuleClearTimeIntervalsPayload' } & Pick<
      WebinarModuleClearTimeIntervalsPayload,
      'status' | 'cleanedData'
    > & {
        errors?: Maybe<
          Array<
            { __typename?: 'MutationErrorType' } & Pick<
              MutationErrorType,
              'field' | 'message'
            >
          >
        >;
      }
  >;
};

export type GetExternalWebinarLiveTimeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetExternalWebinarLiveTimeQuery = { __typename?: 'Query' } & {
  courseModule?: Maybe<
    | { __typename?: 'SurveyModuleNode' }
    | ({ __typename?: 'ExternalWebinarModuleNode' } & Pick<
        ExternalWebinarModuleNode,
        'id' | 'liveTime' | 'status'
      >)
    | { __typename?: 'CourseModuleNode' }
    | { __typename?: 'ChatModuleNode' }
    | { __typename?: 'VideoModuleNode' }
    | { __typename?: 'LayoutModuleNode' }
    | { __typename?: 'DocumentModuleNode' }
    | { __typename?: 'IframeModuleNode' }
    | { __typename?: 'VideowallModuleNode' }
  >;
};

export type IframeModuleNodeWithSubmodulesFragment = {
  __typename?: 'IframeModuleNode';
} & Pick<
  IframeModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'order'
  | 'sendEmailWhenCompleted'
  | 'moduleLockedCustomText'
> & {
    submodules?: Maybe<
      { __typename?: 'CourseModuleConnection' } & Pick<
        CourseModuleConnection,
        'totalCount'
      > & {
          edges: Array<
            Maybe<
              { __typename?: 'CourseModuleEdge' } & {
                node?: Maybe<
                  | ({
                      __typename?: 'SurveyModuleNode';
                    } & CourseModuleUnionSurveyModuleNodeFragment)
                  | ({
                      __typename?: 'ExternalWebinarModuleNode';
                    } & CourseModuleUnionExternalWebinarModuleNodeFragment)
                  | ({
                      __typename?: 'CourseModuleNode';
                    } & CourseModuleUnionCourseModuleNodeFragment)
                  | ({
                      __typename?: 'ChatModuleNode';
                    } & CourseModuleUnionChatModuleNodeFragment)
                  | ({
                      __typename?: 'VideoModuleNode';
                    } & CourseModuleUnionVideoModuleNodeFragment)
                  | ({
                      __typename?: 'LayoutModuleNode';
                    } & CourseModuleUnionLayoutModuleNodeFragment)
                  | ({
                      __typename?: 'DocumentModuleNode';
                    } & CourseModuleUnionDocumentModuleNodeFragment)
                  | ({
                      __typename?: 'IframeModuleNode';
                    } & CourseModuleUnionIframeModuleNodeFragment)
                  | ({
                      __typename?: 'VideowallModuleNode';
                    } & CourseModuleUnionVideowallModuleNodeFragment)
                >;
              }
            >
          >;
        }
    >;
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type IframeModuleNodeOverviewFragment = {
  __typename?: 'IframeModuleNode';
} & Pick<
  IframeModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'order'
  | 'moduleLockedCustomText'
  | 'jsxContent'
> & {
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type IframeModuleNodeFragment = {
  __typename?: 'IframeModuleNode';
} & Pick<
  IframeModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'privacy'
  | 'frontendUrl'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'order'
  | 'jsxContent'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'availableFromCustomText'
  | 'moduleLockedCustomText'
  | 'sendEmailWhenCompleted'
  | 'headline'
  | 'overview'
  | 'description'
  | 'url'
  | 'iframeHeight'
  | 'iframeWidth'
  | 'urlWithParams'
  | 'participationDataInUrlParams'
  | 'external'
> & {
    userModuleAssessment?: Maybe<
      | ({
          __typename?: 'CourseModuleAssessmentVideoNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentExternalWebinarNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentSurveyNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
    >;
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type LayoutModuleNodeWithSubmodulesFragment = {
  __typename?: 'LayoutModuleNode';
} & Pick<
  LayoutModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'order'
  | 'sendEmailWhenCompleted'
  | 'moduleLockedCustomText'
> & {
    livePoll?: Maybe<
      { __typename?: 'SurveyModuleNode' } & PollModuleNodeFragment
    >;
    submodules?: Maybe<
      { __typename?: 'CourseModuleConnection' } & Pick<
        CourseModuleConnection,
        'totalCount'
      > & {
          edges: Array<
            Maybe<
              { __typename?: 'CourseModuleEdge' } & {
                node?: Maybe<
                  | ({
                      __typename?: 'SurveyModuleNode';
                    } & CourseModuleUnionSurveyModuleNodeFragment)
                  | ({
                      __typename?: 'ExternalWebinarModuleNode';
                    } & CourseModuleUnionExternalWebinarModuleNodeFragment)
                  | ({
                      __typename?: 'CourseModuleNode';
                    } & CourseModuleUnionCourseModuleNodeFragment)
                  | ({
                      __typename?: 'ChatModuleNode';
                    } & CourseModuleUnionChatModuleNodeFragment)
                  | ({
                      __typename?: 'VideoModuleNode';
                    } & CourseModuleUnionVideoModuleNodeFragment)
                  | ({
                      __typename?: 'LayoutModuleNode';
                    } & CourseModuleUnionLayoutModuleNodeFragment)
                  | ({
                      __typename?: 'DocumentModuleNode';
                    } & CourseModuleUnionDocumentModuleNodeFragment)
                  | ({
                      __typename?: 'IframeModuleNode';
                    } & CourseModuleUnionIframeModuleNodeFragment)
                  | ({
                      __typename?: 'VideowallModuleNode';
                    } & CourseModuleUnionVideowallModuleNodeFragment)
                >;
              }
            >
          >;
        }
    >;
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type LayoutModuleNodeOverviewFragment = {
  __typename?: 'LayoutModuleNode';
} & Pick<
  LayoutModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'order'
  | 'moduleLockedCustomText'
  | 'jsxContent'
> & {
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type LayoutModuleNodeFragment = {
  __typename?: 'LayoutModuleNode';
} & Pick<
  LayoutModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'headline'
  | 'frontendUrl'
  | 'routable'
  | 'privacy'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'sendEmailWhenCompleted'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'type'
  | 'order'
  | 'overview'
  | 'description'
  | 'jsxContent'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'availableFromCustomText'
  | 'moduleLockedCustomText'
> & {
    userModuleAssessment?: Maybe<
      | ({
          __typename?: 'CourseModuleAssessmentVideoNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentExternalWebinarNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentSurveyNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
    >;
    livePoll?: Maybe<
      { __typename?: 'SurveyModuleNode' } & PollModuleNodeFragment
    >;
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type GetLayoutSubModulesQueryVariables = Exact<{
  courseModuleId: Scalars['ID'];
}>;

export type GetLayoutSubModulesQuery = { __typename?: 'Query' } & {
  courseModule?: Maybe<
    | { __typename?: 'SurveyModuleNode' }
    | { __typename?: 'ExternalWebinarModuleNode' }
    | { __typename?: 'CourseModuleNode' }
    | { __typename?: 'ChatModuleNode' }
    | { __typename?: 'VideoModuleNode' }
    | ({ __typename?: 'LayoutModuleNode' } & Pick<LayoutModuleNode, 'id'> & {
          submodules?: Maybe<
            { __typename?: 'CourseModuleConnection' } & Pick<
              CourseModuleConnection,
              'totalCount'
            > & {
                edges: Array<
                  Maybe<
                    { __typename?: 'CourseModuleEdge' } & {
                      node?: Maybe<
                        | ({
                            __typename?: 'SurveyModuleNode';
                          } & CourseModuleUnionOverviewWithPartecipationDataSurveyModuleNodeFragment)
                        | ({
                            __typename?: 'ExternalWebinarModuleNode';
                          } & CourseModuleUnionOverviewWithPartecipationDataExternalWebinarModuleNodeFragment)
                        | ({
                            __typename?: 'CourseModuleNode';
                          } & CourseModuleUnionOverviewWithPartecipationDataCourseModuleNodeFragment)
                        | ({
                            __typename?: 'ChatModuleNode';
                          } & CourseModuleUnionOverviewWithPartecipationDataChatModuleNodeFragment)
                        | ({
                            __typename?: 'VideoModuleNode';
                          } & CourseModuleUnionOverviewWithPartecipationDataVideoModuleNodeFragment)
                        | ({
                            __typename?: 'LayoutModuleNode';
                          } & CourseModuleUnionOverviewWithPartecipationDataLayoutModuleNodeFragment)
                        | ({
                            __typename?: 'DocumentModuleNode';
                          } & CourseModuleUnionOverviewWithPartecipationDataDocumentModuleNodeFragment)
                        | ({
                            __typename?: 'IframeModuleNode';
                          } & CourseModuleUnionOverviewWithPartecipationDataIframeModuleNodeFragment)
                        | ({
                            __typename?: 'VideowallModuleNode';
                          } & CourseModuleUnionOverviewWithPartecipationDataVideowallModuleNodeFragment)
                      >;
                    }
                  >
                >;
              }
          >;
        })
    | { __typename?: 'DocumentModuleNode' }
    | { __typename?: 'IframeModuleNode' }
    | { __typename?: 'VideowallModuleNode' }
  >;
};

export type SurveyModuleNodeWithSubmodulesFragment = {
  __typename?: 'SurveyModuleNode';
} & Pick<
  SurveyModuleNode,
  | 'id'
  | 'name'
  | 'order'
  | 'sendEmailWhenCompleted'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'moduleLockedCustomText'
> & {
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
    submodules?: Maybe<
      { __typename?: 'CourseModuleConnection' } & Pick<
        CourseModuleConnection,
        'totalCount'
      > & {
          edges: Array<
            Maybe<
              { __typename?: 'CourseModuleEdge' } & {
                node?: Maybe<
                  | ({
                      __typename?: 'SurveyModuleNode';
                    } & CourseModuleUnionSurveyModuleNodeFragment)
                  | ({
                      __typename?: 'ExternalWebinarModuleNode';
                    } & CourseModuleUnionExternalWebinarModuleNodeFragment)
                  | ({
                      __typename?: 'CourseModuleNode';
                    } & CourseModuleUnionCourseModuleNodeFragment)
                  | ({
                      __typename?: 'ChatModuleNode';
                    } & CourseModuleUnionChatModuleNodeFragment)
                  | ({
                      __typename?: 'VideoModuleNode';
                    } & CourseModuleUnionVideoModuleNodeFragment)
                  | ({
                      __typename?: 'LayoutModuleNode';
                    } & CourseModuleUnionLayoutModuleNodeFragment)
                  | ({
                      __typename?: 'DocumentModuleNode';
                    } & CourseModuleUnionDocumentModuleNodeFragment)
                  | ({
                      __typename?: 'IframeModuleNode';
                    } & CourseModuleUnionIframeModuleNodeFragment)
                  | ({
                      __typename?: 'VideowallModuleNode';
                    } & CourseModuleUnionVideowallModuleNodeFragment)
                >;
              }
            >
          >;
        }
    >;
  };

export type SurveyModuleNodeOverviewFragment = {
  __typename?: 'SurveyModuleNode';
} & Pick<
  SurveyModuleNode,
  | 'id'
  | 'name'
  | 'order'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'sendEmailWhenCompleted'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'moduleLockedCustomText'
  | 'jsxContent'
> & {
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type SurveyModuleNodeFragment = {
  __typename?: 'SurveyModuleNode';
} & Pick<
  SurveyModuleNode,
  | 'id'
  | 'name'
  | 'headline'
  | 'overview'
  | 'description'
  | 'jsxContent'
  | 'order'
  | 'slug'
  | 'frontendUrl'
  | 'privacy'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'moduleOptions'
  | 'availableFromCustomText'
  | 'moduleLockedCustomText'
  | 'sendEmailWhenCompleted'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'minimumScore'
  | 'minimumScoreType'
  | 'numberOfAttempts'
  | 'hideUserDataInReports'
  | 'showScoreResults'
  | 'showOnlyErrorsOnScoreResults'
  | 'forceViewTrainingModuleOnFailAttempt'
  | 'viewTrainingModuleMinimumTime'
> & {
    trainingModule?: Maybe<
      | ({
          __typename?: 'SurveyModuleNode';
        } & CourseModuleUnionIdSurveyModuleNodeFragment)
      | ({
          __typename?: 'ExternalWebinarModuleNode';
        } & CourseModuleUnionIdExternalWebinarModuleNodeFragment)
      | ({
          __typename?: 'CourseModuleNode';
        } & CourseModuleUnionIdCourseModuleNodeFragment)
      | ({
          __typename?: 'ChatModuleNode';
        } & CourseModuleUnionIdChatModuleNodeFragment)
      | ({
          __typename?: 'VideoModuleNode';
        } & CourseModuleUnionIdVideoModuleNodeFragment)
      | ({
          __typename?: 'LayoutModuleNode';
        } & CourseModuleUnionIdLayoutModuleNodeFragment)
      | ({
          __typename?: 'DocumentModuleNode';
        } & CourseModuleUnionIdDocumentModuleNodeFragment)
      | ({
          __typename?: 'IframeModuleNode';
        } & CourseModuleUnionIdIframeModuleNodeFragment)
      | ({
          __typename?: 'VideowallModuleNode';
        } & CourseModuleUnionIdVideowallModuleNodeFragment)
    >;
    survey?: Maybe<{ __typename?: 'SurveyNode' } & SurveyNodeFragmentFragment>;
    userModuleAssessment?: Maybe<
      | ({
          __typename?: 'CourseModuleAssessmentVideoNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentExternalWebinarNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentSurveyNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
    >;
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type PollModuleNodeFragment = { __typename?: 'SurveyModuleNode' } & Pick<
  SurveyModuleNode,
  | 'id'
  | 'name'
  | 'headline'
  | 'overview'
  | 'description'
  | 'order'
  | 'slug'
  | 'privacy'
  | 'accessMode'
  | 'routable'
  | 'routableExact'
  | 'forceLocked'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'showScoreResults'
> & {
    survey?: Maybe<{ __typename?: 'SurveyNode' } & SurveyNodeFragmentFragment>;
    userModuleAssessment?: Maybe<
      | ({
          __typename?: 'CourseModuleAssessmentVideoNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentExternalWebinarNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentSurveyNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
    >;
  };

export type VideoModuleNodeWithSubmodulesFragment = {
  __typename?: 'VideoModuleNode';
} & Pick<
  VideoModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'order'
  | 'sendEmailWhenCompleted'
  | 'routable'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'moduleLockedCustomText'
  | 'displayCompleteButton'
> & {
    submodules?: Maybe<
      { __typename?: 'CourseModuleConnection' } & Pick<
        CourseModuleConnection,
        'totalCount'
      > & {
          edges: Array<
            Maybe<
              { __typename?: 'CourseModuleEdge' } & {
                node?: Maybe<
                  | ({
                      __typename?: 'SurveyModuleNode';
                    } & CourseModuleUnionSurveyModuleNodeFragment)
                  | ({
                      __typename?: 'ExternalWebinarModuleNode';
                    } & CourseModuleUnionExternalWebinarModuleNodeFragment)
                  | ({
                      __typename?: 'CourseModuleNode';
                    } & CourseModuleUnionCourseModuleNodeFragment)
                  | ({
                      __typename?: 'ChatModuleNode';
                    } & CourseModuleUnionChatModuleNodeFragment)
                  | ({
                      __typename?: 'VideoModuleNode';
                    } & CourseModuleUnionVideoModuleNodeFragment)
                  | ({
                      __typename?: 'LayoutModuleNode';
                    } & CourseModuleUnionLayoutModuleNodeFragment)
                  | ({
                      __typename?: 'DocumentModuleNode';
                    } & CourseModuleUnionDocumentModuleNodeFragment)
                  | ({
                      __typename?: 'IframeModuleNode';
                    } & CourseModuleUnionIframeModuleNodeFragment)
                  | ({
                      __typename?: 'VideowallModuleNode';
                    } & CourseModuleUnionVideowallModuleNodeFragment)
                >;
              }
            >
          >;
        }
    >;
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type VideoModuleNodeOverviewFragment = {
  __typename?: 'VideoModuleNode';
} & Pick<
  VideoModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'order'
  | 'routable'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'moduleLockedCustomText'
  | 'jsxContent'
> & {
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type VideoModuleNodeFragment = { __typename?: 'VideoModuleNode' } & Pick<
  VideoModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'displayCompleteButton'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'disableAutoComplete'
  | 'disableCheckComplete'
  | 'completedCustomEmailContent'
  | 'completedCustomEmailSubject'
  | 'sendEmailWhenCompleted'
  | 'url'
  | 'order'
  | 'headline'
  | 'overview'
  | 'description'
  | 'jsxContent'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'privacy'
  | 'availableFromCustomText'
  | 'moduleLockedCustomText'
  | 'iframeMode'
  | 'videoDuration'
  | 'requiredUserLivePercentile'
  | 'assessmentsCounter'
> & {
    userModuleAssessment?: Maybe<
      | ({
          __typename?: 'CourseModuleAssessmentVideoNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentExternalWebinarNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentSurveyNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
    >;
    authors?: Maybe<
      { __typename?: 'AuthorModuleConnection' } & {
        edges: Array<
          Maybe<
            { __typename?: 'AuthorModuleEdge' } & {
              node?: Maybe<{ __typename?: 'AuthorNode' } & AuthorNodeFragment>;
            }
          >
        >;
      }
    >;
  };

export type VideowallModuleNodeWithSubmodulesFragment = {
  __typename?: 'VideowallModuleNode';
} & Pick<
  VideowallModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'order'
  | 'sendEmailWhenCompleted'
  | 'moduleLockedCustomText'
> & {
    submodules?: Maybe<
      { __typename?: 'CourseModuleConnection' } & Pick<
        CourseModuleConnection,
        'totalCount'
      > & {
          edges: Array<
            Maybe<
              { __typename?: 'CourseModuleEdge' } & {
                node?: Maybe<
                  | ({
                      __typename?: 'SurveyModuleNode';
                    } & CourseModuleUnionSurveyModuleNodeFragment)
                  | ({
                      __typename?: 'ExternalWebinarModuleNode';
                    } & CourseModuleUnionExternalWebinarModuleNodeFragment)
                  | ({
                      __typename?: 'CourseModuleNode';
                    } & CourseModuleUnionCourseModuleNodeFragment)
                  | ({
                      __typename?: 'ChatModuleNode';
                    } & CourseModuleUnionChatModuleNodeFragment)
                  | ({
                      __typename?: 'VideoModuleNode';
                    } & CourseModuleUnionVideoModuleNodeFragment)
                  | ({
                      __typename?: 'LayoutModuleNode';
                    } & CourseModuleUnionLayoutModuleNodeFragment)
                  | ({
                      __typename?: 'DocumentModuleNode';
                    } & CourseModuleUnionDocumentModuleNodeFragment)
                  | ({
                      __typename?: 'IframeModuleNode';
                    } & CourseModuleUnionIframeModuleNodeFragment)
                  | ({
                      __typename?: 'VideowallModuleNode';
                    } & CourseModuleUnionVideowallModuleNodeFragment)
                >;
              }
            >
          >;
        }
    >;
  };

export type VideowallModuleNodeOverviewFragment = {
  __typename?: 'VideowallModuleNode';
} & Pick<
  VideowallModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'order'
  | 'moduleLockedCustomText'
  | 'jsxContent'
>;

export type VideowallModuleNodeFragment = {
  __typename?: 'VideowallModuleNode';
} & Pick<
  VideowallModuleNode,
  | 'id'
  | 'name'
  | 'slug'
  | 'frontendUrl'
  | 'routable'
  | 'routableExact'
  | 'testingMode'
  | 'materialIcon'
  | 'assessmentsCounter'
  | 'order'
  | 'availableFrom'
  | 'availableUntil'
  | 'forceLocked'
  | 'accessMode'
  | 'availableFromCustomText'
  | 'moduleLockedCustomText'
  | 'sendEmailWhenCompleted'
  | 'headline'
  | 'overview'
  | 'description'
  | 'jsxContent'
  | 'url'
  | 'urlWithParams'
> & {
    userModuleAssessment?: Maybe<
      | ({
          __typename?: 'CourseModuleAssessmentVideoNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentVideoNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentExternalWebinarNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentExternalWebinarNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentSurveyNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentSurveyNodeFragment)
      | ({
          __typename?: 'CourseModuleAssessmentNode';
        } & CourseModuleAssessmentUnionCourseModuleAssessmentNodeFragment)
    >;
  };

export type AllCompanyParticipationsQueryVariables = Exact<{
  companyId?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
}>;

export type AllCompanyParticipationsQuery = { __typename?: 'Query' } & {
  allCompanyParticipations?: Maybe<
    { __typename?: 'CourseParticipationNodeConnection' } & Pick<
      CourseParticipationNodeConnection,
      'totalCount'
    > & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
        edges: Array<
          Maybe<
            { __typename?: 'CourseParticipationNodeEdge' } & {
              node?: Maybe<
                { __typename?: 'CourseParticipationNode' } & Pick<
                  CourseParticipationNode,
                  'data' | 'id' | 'status' | 'isOnline' | 'created'
                > & {
                    course: { __typename?: 'CourseNode' } & Pick<
                      CourseNode,
                      'id' | 'name'
                    >;
                    user: { __typename?: 'UserNode' } & Pick<
                      UserNode,
                      'email' | 'displayName'
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type GetCourseParticipationsQueryVariables = Exact<{
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  status?: Maybe<CourseParticipationStatus>;
  searchText?: Maybe<Scalars['String']>;
}>;

export type GetCourseParticipationsQuery = { __typename?: 'Query' } & {
  course?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
        participations: {
          __typename?: 'CourseParticipationNodeConnection';
        } & Pick<CourseParticipationNodeConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<
              PageInfo,
              'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
            >;
            edges: Array<
              Maybe<
                { __typename?: 'CourseParticipationNodeEdge' } & {
                  node?: Maybe<
                    { __typename?: 'CourseParticipationNode' } & Pick<
                      CourseParticipationNode,
                      | 'id'
                      | 'getStatusDisplay'
                      | 'status'
                      | 'data'
                      | 'dataChanges'
                      | 'created'
                      | 'modified'
                      | 'isOnline'
                    > & {
                        invitation?: Maybe<
                          { __typename?: 'CourseInvitationNode' } & Pick<
                            CourseInvitationNode,
                            'id'
                          >
                        >;
                        formResponse?: Maybe<
                          { __typename?: 'SurveyResponseNode' } & Pick<
                            SurveyResponseNode,
                            'id'
                          >
                        >;
                        user: { __typename?: 'UserNode' } & Pick<
                          UserNode,
                          | 'id'
                          | 'avatar'
                          | 'isAnonymous'
                          | 'isStaff'
                          | 'displayName'
                          | 'email'
                        >;
                      }
                  >;
                }
              >
            >;
          };
      }
  >;
};

export type GetCourseParticipationsTotalCountQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCourseParticipationsTotalCountQuery = {
  __typename?: 'Query';
} & {
  course?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
        participations: {
          __typename?: 'CourseParticipationNodeConnection';
        } & Pick<CourseParticipationNodeConnection, 'totalCount'>;
      }
  >;
};

export type GetMyCourseParticipationStatusQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetMyCourseParticipationStatusQuery = { __typename?: 'Query' } & {
  publicCourseBySlug?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
        participation?: Maybe<
          { __typename?: 'CourseParticipationNode' } & Pick<
            CourseParticipationNode,
            'id' | 'status' | 'created'
          >
        >;
      }
  >;
};

export type GetMyCourseParticipationQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetMyCourseParticipationQuery = { __typename?: 'Query' } & {
  publicCourseBySlug?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
        participation?: Maybe<
          { __typename?: 'CourseParticipationNode' } & Pick<
            CourseParticipationNode,
            'data' | 'id' | 'status'
          > & {
              qrcode?: Maybe<
                { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'url'>
              >;
              lockedModules?: Maybe<
                Array<
                  Maybe<
                    | ({
                        __typename?: 'SurveyModuleNode';
                      } & CourseModuleUnionIdSurveyModuleNodeFragment)
                    | ({
                        __typename?: 'ExternalWebinarModuleNode';
                      } & CourseModuleUnionIdExternalWebinarModuleNodeFragment)
                    | ({
                        __typename?: 'CourseModuleNode';
                      } & CourseModuleUnionIdCourseModuleNodeFragment)
                    | ({
                        __typename?: 'ChatModuleNode';
                      } & CourseModuleUnionIdChatModuleNodeFragment)
                    | ({
                        __typename?: 'VideoModuleNode';
                      } & CourseModuleUnionIdVideoModuleNodeFragment)
                    | ({
                        __typename?: 'LayoutModuleNode';
                      } & CourseModuleUnionIdLayoutModuleNodeFragment)
                    | ({
                        __typename?: 'DocumentModuleNode';
                      } & CourseModuleUnionIdDocumentModuleNodeFragment)
                    | ({
                        __typename?: 'IframeModuleNode';
                      } & CourseModuleUnionIdIframeModuleNodeFragment)
                    | ({
                        __typename?: 'VideowallModuleNode';
                      } & CourseModuleUnionIdVideowallModuleNodeFragment)
                  >
                >
              >;
            }
        >;
      }
  >;
};

export type GetMyCourseParticipationsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  course?: Maybe<Scalars['ID']>;
  company?: Maybe<Scalars['ID']>;
  user?: Maybe<Scalars['ID']>;
  manager?: Maybe<Scalars['ID']>;
  hasCertificate?: Maybe<Scalars['Boolean']>;
}>;

export type GetMyCourseParticipationsQuery = { __typename?: 'Query' } & {
  courseParticipations?: Maybe<
    { __typename?: 'CourseParticipationNodeConnection' } & Pick<
      CourseParticipationNodeConnection,
      'totalCount'
    > & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
        edges: Array<
          Maybe<
            { __typename?: 'CourseParticipationNodeEdge' } & {
              node?: Maybe<
                { __typename?: 'CourseParticipationNode' } & Pick<
                  CourseParticipationNode,
                  | 'id'
                  | 'status'
                  | 'getStatusDisplay'
                  | 'created'
                  | 'partial'
                  | 'data'
                > & {
                    course: { __typename?: 'CourseNode' } & Pick<
                      CourseNode,
                      | 'id'
                      | 'slug'
                      | 'name'
                      | 'startDate'
                      | 'endDate'
                      | 'listed'
                      | 'available'
                    > & {
                        company?: Maybe<
                          { __typename?: 'CompanyNode' } & Pick<
                            CompanyNode,
                            'id' | 'name'
                          >
                        >;
                        cover?: Maybe<
                          { __typename?: 'ImageFiledType' } & {
                            thumbnail?: Maybe<
                              { __typename?: 'ThumbnailType' } & Pick<
                                ThumbnailType,
                                'url'
                              >
                            >;
                          }
                        >;
                      };
                    certificate?: Maybe<
                      { __typename?: 'CertificateNode' } & Pick<
                        CertificateNode,
                        'id' | 'created'
                      > & {
                          file?: Maybe<
                            { __typename?: 'FileFiledType' } & Pick<
                              FileFiledType,
                              'url'
                            >
                          >;
                          png?: Maybe<
                            { __typename?: 'CertificateConversionNode' } & Pick<
                              CertificateConversionNode,
                              'id' | 'format'
                            > & {
                                file?: Maybe<
                                  { __typename?: 'FileFiledType' } & Pick<
                                    FileFiledType,
                                    'name' | 'fileName' | 'url'
                                  >
                                >;
                              }
                          >;
                          pdf?: Maybe<
                            { __typename?: 'CertificateConversionNode' } & Pick<
                              CertificateConversionNode,
                              'id' | 'format'
                            > & {
                                file?: Maybe<
                                  { __typename?: 'FileFiledType' } & Pick<
                                    FileFiledType,
                                    'name' | 'fileName' | 'url'
                                  >
                                >;
                              }
                          >;
                        }
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type GetManagerCourseParticipationsTotalCountQueryVariables = Exact<{
  manager?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
}>;

export type GetManagerCourseParticipationsTotalCountQuery = {
  __typename?: 'Query';
} & {
  course?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
        participations: {
          __typename?: 'CourseParticipationNodeConnection';
        } & Pick<CourseParticipationNodeConnection, 'totalCount'> & {
            edges: Array<
              Maybe<
                { __typename?: 'CourseParticipationNodeEdge' } & {
                  node?: Maybe<
                    { __typename?: 'CourseParticipationNode' } & {
                      manager?: Maybe<
                        { __typename?: 'UserNode' } & Pick<UserNode, 'id'>
                      >;
                    }
                  >;
                }
              >
            >;
          };
      }
  >;
};

export type GetUserCourseParticipationsQueryVariables = Exact<{
  user?: Maybe<Scalars['ID']>;
  manager?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
}>;

export type GetUserCourseParticipationsQuery = { __typename?: 'Query' } & {
  course?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
        participations: {
          __typename?: 'CourseParticipationNodeConnection';
        } & Pick<CourseParticipationNodeConnection, 'totalCount'> & {
            pageInfo: { __typename: 'PageInfo' } & Pick<
              PageInfo,
              'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
            >;
            edges: Array<
              Maybe<
                { __typename?: 'CourseParticipationNodeEdge' } & {
                  node?: Maybe<
                    { __typename?: 'CourseParticipationNode' } & Pick<
                      CourseParticipationNode,
                      | 'id'
                      | 'status'
                      | 'getStatusDisplay'
                      | 'created'
                      | 'modified'
                      | 'data'
                      | 'dataChanges'
                      | 'isOnline'
                    > & {
                        formResponse?: Maybe<
                          { __typename?: 'SurveyResponseNode' } & Pick<
                            SurveyResponseNode,
                            'id'
                          >
                        >;
                        course: { __typename?: 'CourseNode' } & Pick<
                          CourseNode,
                          'id'
                        >;
                        user: { __typename?: 'UserNode' } & Pick<
                          UserNode,
                          | 'id'
                          | 'avatar'
                          | 'displayName'
                          | 'email'
                          | 'isAnonymous'
                        >;
                        manager?: Maybe<
                          { __typename?: 'UserNode' } & Pick<
                            UserNode,
                            'id' | 'avatar' | 'displayName'
                          >
                        >;
                      }
                  >;
                }
              >
            >;
          };
      }
  >;
};

export type GetMyCertificateQueryVariables = Exact<{
  company?: Maybe<Scalars['ID']>;
  user: Scalars['ID'];
}>;

export type GetMyCertificateQuery = { __typename?: 'Query' } & {
  courseCertificates?: Maybe<
    Array<
      Maybe<
        { __typename?: 'UserCourseCertificate' } & {
          user: { __typename?: 'UserNode' } & Pick<UserNode, 'id'>;
          courseParticipation?: Maybe<
            { __typename?: 'CourseParticipationNode' } & {
              course: { __typename?: 'CourseNode' } & Pick<CourseNode, 'name'>;
            }
          >;
          courseModuleAssessment?: Maybe<
            { __typename?: 'CourseModuleAssessmentNode' } & {
              courseModule: { __typename?: 'CourseModuleNode' } & Pick<
                CourseModuleNode,
                'name'
              >;
              courseParticipation: {
                __typename?: 'CourseParticipationNode';
              } & {
                course: { __typename?: 'CourseNode' } & Pick<
                  CourseNode,
                  'name'
                >;
              };
            }
          >;
          certificate: { __typename?: 'CertificateNode' } & Pick<
            CertificateNode,
            'id' | 'created'
          > & {
              file?: Maybe<
                { __typename?: 'FileFiledType' } & Pick<FileFiledType, 'url'>
              >;
              png?: Maybe<
                { __typename?: 'CertificateConversionNode' } & Pick<
                  CertificateConversionNode,
                  'id' | 'format'
                > & {
                    file?: Maybe<
                      { __typename?: 'FileFiledType' } & Pick<
                        FileFiledType,
                        'name' | 'fileName' | 'url'
                      >
                    >;
                  }
              >;
              pdf?: Maybe<
                { __typename?: 'CertificateConversionNode' } & Pick<
                  CertificateConversionNode,
                  'id' | 'format'
                > & {
                    file?: Maybe<
                      { __typename?: 'FileFiledType' } & Pick<
                        FileFiledType,
                        'name' | 'fileName' | 'url'
                      >
                    >;
                  }
              >;
            };
        }
      >
    >
  >;
};

export type CourseUserParticipationsQuotasQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CourseUserParticipationsQuotasQuery = { __typename?: 'Query' } & {
  courseUserParticipationsQuotas?: Maybe<
    { __typename?: 'CourseUserParticipationQuotasNode' } & Pick<
      CourseUserParticipationQuotasNode,
      'id' | 'participationsQuota' | 'label'
    > & {
        user: { __typename?: 'UserNode' } & Pick<
          UserNode,
          'id' | 'avatar' | 'displayName' | 'email'
        >;
      }
  >;
};

export type CourseMyParticipationsQuotasQueryVariables = Exact<{
  courseId: Scalars['ID'];
}>;

export type CourseMyParticipationsQuotasQuery = { __typename?: 'Query' } & {
  courseMyParticipationsQuotas?: Maybe<
    { __typename?: 'CourseUserParticipationQuotasNode' } & Pick<
      CourseUserParticipationQuotasNode,
      'id' | 'participationsQuota' | 'label'
    >
  >;
};

export type CourseUsersParticipationsQuotasQueryVariables = Exact<{
  courseId: Scalars['ID'];
}>;

export type CourseUsersParticipationsQuotasQuery = { __typename?: 'Query' } & {
  courseUsersParticipationsQuotas?: Maybe<
    { __typename?: 'CourseUserParticipationQuotasNodeConnection' } & Pick<
      CourseUserParticipationQuotasNodeConnection,
      'totalCount'
    > & {
        edges: Array<
          Maybe<
            { __typename?: 'CourseUserParticipationQuotasNodeEdge' } & {
              node?: Maybe<
                { __typename?: 'CourseUserParticipationQuotasNode' } & Pick<
                  CourseUserParticipationQuotasNode,
                  'id' | 'participationsQuota' | 'label'
                > & {
                    user: { __typename?: 'UserNode' } & Pick<
                      UserNode,
                      'id' | 'avatar' | 'displayName' | 'email'
                    >;
                  }
              >;
            }
          >
        >;
      }
  >;
};

export type OverviewPublicCourseBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type OverviewPublicCourseBySlugQuery = { __typename?: 'Query' } & {
  publicCourseBySlug?: Maybe<
    { __typename?: 'CourseNode' } & Pick<
      CourseNode,
      | 'id'
      | 'name'
      | 'slug'
      | 'overview'
      | 'code'
      | 'demo'
      | 'description'
      | 'headline'
      | 'created'
      | 'startDate'
      | 'layoutType'
      | 'navigationType'
      | 'jsxContent'
      | 'displayEndCourseContent'
      | 'displayParticipationSuccessToast'
      | 'participationCustomToastSuccessMessage'
      | 'endCourseContent'
      | 'endDate'
      | 'pdfProgramButtonLabel'
      | 'acceptAnonyumousEnrollment'
      | 'defaultLanguage'
      | 'forceAnonyumousEnrollment'
      | 'onlyOneParticipationPerInvitation'
      | 'requireDataParticipation'
      | 'acceptsParticipationByExternalUuid'
      | 'externalUuidParameterName'
      | 'externalUuidParameterRequired'
      | 'generateParticipationQrcode'
      | 'participationsCount'
      | 'displayParticipantsCounter'
      | 'displayContinueButton'
      | 'authorListLabel'
    > & {
        tags?: Maybe<
          Array<
            Maybe<
              { __typename?: 'TagType' } & Pick<TagType, 'name' | 'slug' | 'id'>
            >
          >
        >;
        userHasPermission?: Maybe<
          { __typename?: 'CoursePermission' } & Pick<
            CoursePermission,
            | 'viewCourse'
            | 'changeCourse'
            | 'moderateChatCourse'
            | 'manageParticipationCourse'
            | 'downloadFullReport'
            | 'anyPermission'
          >
        >;
        pdfProgram?: Maybe<
          { __typename?: 'FileFiledType' } & Pick<
            FileFiledType,
            'fileName' | 'url' | 'extension'
          >
        >;
        cover?: Maybe<
          { __typename?: 'ImageFiledType' } & {
            thumbnail?: Maybe<
              { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
            >;
          }
        >;
        background?: Maybe<
          { __typename?: 'ImageFiledType' } & {
            thumbnail?: Maybe<
              { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
            >;
          }
        >;
        backgroundFullScreen?: Maybe<
          { __typename?: 'ImageFiledType' } & {
            thumbnail?: Maybe<
              { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
            >;
          }
        >;
        theme?: Maybe<
          { __typename?: 'ThemeNode' } & Pick<
            ThemeNode,
            'id' | 'enableInDashboard'
          >
        >;
        participationForm?: Maybe<
          { __typename?: 'SurveyNode' } & Pick<SurveyNode, 'id'>
        >;
        authorsGroups: { __typename?: 'AuthorsGroupNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'AuthorsGroupNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'AuthorsGroupNode' } & AuthorsGroupNodeFragment
                >;
              }
            >
          >;
        };
        authors?: Maybe<
          { __typename?: 'AuthorModuleConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'AuthorModuleEdge' } & {
                  node?: Maybe<
                    { __typename?: 'AuthorNode' } & AuthorNodeFragment
                  >;
                }
              >
            >;
          }
        >;
        participation?: Maybe<
          { __typename?: 'CourseParticipationNode' } & Pick<
            CourseParticipationNode,
            'id' | 'status'
          >
        >;
        categories: { __typename?: 'CourseCategoryNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'CourseCategoryNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'CourseCategoryNode' } & Pick<
                    CourseCategoryNode,
                    'id' | 'name' | 'description'
                  >
                >;
              }
            >
          >;
        };
        submodules?: Maybe<
          { __typename?: 'CourseModuleConnection' } & Pick<
            CourseModuleConnection,
            'totalCount'
          > & {
              edges: Array<
                Maybe<
                  { __typename?: 'CourseModuleEdge' } & {
                    node?: Maybe<
                      | ({
                          __typename?: 'SurveyModuleNode';
                        } & CourseModuleUnionOverviewSurveyModuleNodeFragment)
                      | ({
                          __typename?: 'ExternalWebinarModuleNode';
                        } & CourseModuleUnionOverviewExternalWebinarModuleNodeFragment)
                      | ({
                          __typename?: 'CourseModuleNode';
                        } & CourseModuleUnionOverviewCourseModuleNodeFragment)
                      | ({
                          __typename?: 'ChatModuleNode';
                        } & CourseModuleUnionOverviewChatModuleNodeFragment)
                      | ({
                          __typename?: 'VideoModuleNode';
                        } & CourseModuleUnionOverviewVideoModuleNodeFragment)
                      | ({
                          __typename?: 'LayoutModuleNode';
                        } & CourseModuleUnionOverviewLayoutModuleNodeFragment)
                      | ({
                          __typename?: 'DocumentModuleNode';
                        } & CourseModuleUnionOverviewDocumentModuleNodeFragment)
                      | ({
                          __typename?: 'IframeModuleNode';
                        } & CourseModuleUnionOverviewIframeModuleNodeFragment)
                      | ({
                          __typename?: 'VideowallModuleNode';
                        } & CourseModuleUnionOverviewVideowallModuleNodeFragment)
                    >;
                  }
                >
              >;
            }
        >;
      }
  >;
};

export type ReminderTestMutationVariables = Exact<{
  input: ReminderTestMutationInput;
}>;

export type ReminderTestMutation = { __typename?: 'Mutation' } & {
  reminderTest?: Maybe<
    { __typename?: 'ReminderTestMutationPayload' } & Pick<
      ReminderTestMutationPayload,
      'status'
    > & {
        errors?: Maybe<
          Array<
            { __typename?: 'MutationErrorType' } & Pick<
              MutationErrorType,
              'field' | 'message'
            >
          >
        >;
      }
  >;
};

export type ReminderCreateMutationVariables = Exact<{
  input: ReminderCreateMutationInput;
}>;

export type ReminderCreateMutation = { __typename?: 'Mutation' } & {
  reminderCreate?: Maybe<
    { __typename?: 'ReminderCreateMutationPayload' } & {
      reminder?: Maybe<
        { __typename?: 'ReminderNode' } & Pick<
          ReminderNode,
          'name' | 'kwargs' | 'enabled' | 'task' | 'id'
        > & {
            clocked?: Maybe<
              { __typename?: 'ClockedScheduleNode' } & Pick<
                ClockedScheduleNode,
                'clockedTime'
              >
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type ReminderUpdateMutationVariables = Exact<{
  input: ReminderUpdateMutationInput;
}>;

export type ReminderUpdateMutation = { __typename?: 'Mutation' } & {
  reminderUpdate?: Maybe<
    { __typename?: 'ReminderUpdateMutationPayload' } & {
      reminder?: Maybe<
        { __typename?: 'ReminderNode' } & Pick<
          ReminderNode,
          'name' | 'kwargs' | 'enabled' | 'task' | 'id'
        > & {
            clocked?: Maybe<
              { __typename?: 'ClockedScheduleNode' } & Pick<
                ClockedScheduleNode,
                'clockedTime'
              >
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type ReminderDeleteMutationVariables = Exact<{
  input: ReminderDeleteMutationInput;
}>;

export type ReminderDeleteMutation = { __typename?: 'Mutation' } & {
  reminderDelete?: Maybe<
    { __typename?: 'ReminderDeleteMutationPayload' } & {
      reminder?: Maybe<
        { __typename?: 'ReminderNode' } & Pick<
          ReminderNode,
          'name' | 'kwargs' | 'enabled' | 'task' | 'id'
        > & {
            clocked?: Maybe<
              { __typename?: 'ClockedScheduleNode' } & Pick<
                ClockedScheduleNode,
                'clockedTime'
              >
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type ReminderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ReminderQuery = { __typename?: 'Query' } & {
  reminder?: Maybe<
    { __typename?: 'ReminderNode' } & Pick<
      ReminderNode,
      'name' | 'kwargs' | 'enabled' | 'task' | 'id'
    > & {
        result?: Maybe<
          { __typename?: 'TaskResultNodeConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'TaskResultNodeEdge' } & {
                  node?: Maybe<
                    { __typename?: 'TaskResultNode' } & Pick<
                      TaskResultNode,
                      'status' | 'taskKwargs' | 'dateDone' | 'result'
                    >
                  >;
                }
              >
            >;
          }
        >;
        clocked?: Maybe<
          { __typename?: 'ClockedScheduleNode' } & Pick<
            ClockedScheduleNode,
            'clockedTime' | 'id'
          >
        >;
      }
  >;
};

export type CreateExternalWebinarModuleMutationVariables = Exact<{
  input: ExternalWebinarModuleCreateMutationInput;
}>;

export type CreateExternalWebinarModuleMutation = {
  __typename?: 'Mutation';
} & {
  externalWebinarModuleCreate?: Maybe<
    { __typename?: 'ExternalWebinarModuleCreateMutationPayload' } & {
      externalWebinarModule?: Maybe<
        { __typename?: 'ExternalWebinarModuleNode' } & Pick<
          ExternalWebinarModuleNode,
          'id' | 'liveUrl' | 'recordedUrl' | 'status' | 'endedAt'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CreateSurveyModuleMutationVariables = Exact<{
  input: SurveyModuleCreateMutationInput;
}>;

export type CreateSurveyModuleMutation = { __typename?: 'Mutation' } & {
  surveyModuleCreate?: Maybe<
    { __typename?: 'SurveyModuleCreateMutationPayload' } & {
      surveyModule?: Maybe<
        { __typename?: 'SurveyModuleNode' } & Pick<SurveyModuleNode, 'id'>
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CreateLayoutModuleMutationVariables = Exact<{
  input: LayoutModuleCreateMutationInput;
}>;

export type CreateLayoutModuleMutation = { __typename?: 'Mutation' } & {
  layoutModuleCreate?: Maybe<
    { __typename?: 'LayoutModuleCreateMutationPayload' } & {
      layoutModule?: Maybe<
        { __typename?: 'LayoutModuleNode' } & Pick<LayoutModuleNode, 'id'>
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CreateVideoModuleMutationVariables = Exact<{
  input: VideoModuleCreateMutationInput;
}>;

export type CreateVideoModuleMutation = { __typename?: 'Mutation' } & {
  videoModuleCreate?: Maybe<
    { __typename?: 'VideoModuleCreateMutationPayload' } & {
      videoModule?: Maybe<
        { __typename?: 'VideoModuleNode' } & Pick<VideoModuleNode, 'id'>
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CreateIframeModuleMutationVariables = Exact<{
  input: IframeModuleCreateMutationInput;
}>;

export type CreateIframeModuleMutation = { __typename?: 'Mutation' } & {
  iframeModuleCreate?: Maybe<
    { __typename?: 'IframeModuleCreateMutationPayload' } & {
      iframeModule?: Maybe<
        { __typename?: 'IframeModuleNode' } & Pick<
          IframeModuleNode,
          'id' | 'url'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CreateDocumentModuleMutationVariables = Exact<{
  input: DocumentModuleCreateMutationInput;
}>;

export type CreateDocumentModuleMutation = { __typename?: 'Mutation' } & {
  documentModuleCreate?: Maybe<
    { __typename?: 'DocumentModuleCreateMutationPayload' } & {
      documentModule?: Maybe<
        { __typename?: 'DocumentModuleNode' } & Pick<DocumentModuleNode, 'id'>
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CreateChatModuleMutationVariables = Exact<{
  input: ChatModuleCreateMutationInput;
}>;

export type CreateChatModuleMutation = { __typename?: 'Mutation' } & {
  chatModuleCreate?: Maybe<
    { __typename?: 'ChatModuleCreateMutationPayload' } & {
      chatModule?: Maybe<
        { __typename?: 'ChatModuleNode' } & Pick<ChatModuleNode, 'id'>
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type SurveyModuleUpdateMutationVariables = Exact<{
  input: SurveyModuleUpdateMutationInput;
}>;

export type SurveyModuleUpdateMutation = { __typename?: 'Mutation' } & {
  surveyModuleUpdate?: Maybe<
    { __typename?: 'SurveyModuleUpdateMutationPayload' } & {
      surveyModule?: Maybe<
        { __typename?: 'SurveyModuleNode' } & Pick<
          SurveyModuleNode,
          | 'id'
          | 'name'
          | 'slug'
          | 'overview'
          | 'headline'
          | 'description'
          | 'jsxContent'
          | 'minimumScore'
          | 'minimumScoreType'
          | 'numberOfAttempts'
          | 'hideUserDataInReports'
          | 'showScoreResults'
          | 'sendEmailWhenCompleted'
          | 'disableAutoComplete'
          | 'disableCheckComplete'
          | 'completedCustomEmailContent'
          | 'completedCustomEmailSubject'
          | 'routable'
          | 'routableExact'
          | 'testingMode'
          | 'materialIcon'
          | 'order'
          | 'privacy'
          | 'availableFrom'
          | 'availableUntil'
          | 'forceLocked'
          | 'accessMode'
          | 'availableFromCustomText'
          | 'moduleLockedCustomText'
        > & {
            survey?: Maybe<
              { __typename?: 'SurveyNode' } & SurveyNodeFragmentFragment
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type LayoutModuleUpdateMutationVariables = Exact<{
  input: LayoutModuleUpdateMutationInput;
}>;

export type LayoutModuleUpdateMutation = { __typename?: 'Mutation' } & {
  layoutModuleUpdate?: Maybe<
    { __typename?: 'LayoutModuleUpdateMutationPayload' } & {
      layoutModule?: Maybe<
        { __typename?: 'LayoutModuleNode' } & Pick<
          LayoutModuleNode,
          | 'id'
          | 'slug'
          | 'name'
          | 'overview'
          | 'headline'
          | 'description'
          | 'jsxContent'
          | 'type'
          | 'routable'
          | 'routableExact'
          | 'testingMode'
          | 'materialIcon'
          | 'disableAutoComplete'
          | 'disableCheckComplete'
          | 'completedCustomEmailContent'
          | 'completedCustomEmailSubject'
          | 'sendEmailWhenCompleted'
          | 'order'
          | 'privacy'
          | 'availableFrom'
          | 'availableUntil'
          | 'forceLocked'
          | 'accessMode'
          | 'availableFromCustomText'
          | 'moduleLockedCustomText'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type VideoModuleUpdateMutationVariables = Exact<{
  input: VideoModuleUpdateMutationInput;
}>;

export type VideoModuleUpdateMutation = { __typename?: 'Mutation' } & {
  videoModuleUpdate?: Maybe<
    { __typename?: 'VideoModuleUpdateMutationPayload' } & {
      videoModule?: Maybe<
        { __typename?: 'VideoModuleNode' } & Pick<
          VideoModuleNode,
          | 'id'
          | 'name'
          | 'slug'
          | 'overview'
          | 'headline'
          | 'description'
          | 'jsxContent'
          | 'url'
          | 'iframeMode'
          | 'videoDuration'
          | 'requiredUserLivePercentile'
          | 'routable'
          | 'routableExact'
          | 'testingMode'
          | 'materialIcon'
          | 'disableAutoComplete'
          | 'disableCheckComplete'
          | 'completedCustomEmailContent'
          | 'completedCustomEmailSubject'
          | 'sendEmailWhenCompleted'
          | 'order'
          | 'privacy'
          | 'availableFrom'
          | 'availableUntil'
          | 'forceLocked'
          | 'accessMode'
          | 'availableFromCustomText'
          | 'moduleLockedCustomText'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type VideoModuleUserActionsMutationVariables = Exact<{
  input: VideoModuleUserActionsMutationInput;
}>;

export type VideoModuleUserActionsMutation = { __typename?: 'Mutation' } & {
  videoModuleUserActions?: Maybe<
    { __typename?: 'VideoModuleUserActionsMutationPayload' } & Pick<
      VideoModuleUserActionsMutationPayload,
      'livePercentile'
    > & {
        errors?: Maybe<
          Array<
            { __typename?: 'MutationErrorType' } & Pick<
              MutationErrorType,
              'field' | 'message'
            >
          >
        >;
      }
  >;
};

export type IframeModuleUpdateMutationVariables = Exact<{
  input: IframeModuleUpdateMutationInput;
}>;

export type IframeModuleUpdateMutation = { __typename?: 'Mutation' } & {
  iframeModuleUpdate?: Maybe<
    { __typename?: 'IframeModuleUpdateMutationPayload' } & {
      iframeModule?: Maybe<
        { __typename?: 'IframeModuleNode' } & Pick<
          IframeModuleNode,
          | 'id'
          | 'name'
          | 'slug'
          | 'overview'
          | 'headline'
          | 'description'
          | 'jsxContent'
          | 'url'
          | 'routable'
          | 'routableExact'
          | 'testingMode'
          | 'materialIcon'
          | 'disableAutoComplete'
          | 'disableCheckComplete'
          | 'completedCustomEmailContent'
          | 'completedCustomEmailSubject'
          | 'sendEmailWhenCompleted'
          | 'order'
          | 'privacy'
          | 'availableFrom'
          | 'availableUntil'
          | 'forceLocked'
          | 'accessMode'
          | 'availableFromCustomText'
          | 'moduleLockedCustomText'
          | 'iframeHeight'
          | 'iframeWidth'
          | 'external'
          | 'participationDataInUrlParams'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type DocumentModuleUpdateMutationVariables = Exact<{
  input: DocumentModuleUpdateMutationInput;
}>;

export type DocumentModuleUpdateMutation = { __typename?: 'Mutation' } & {
  documentModuleUpdate?: Maybe<
    { __typename?: 'DocumentModuleUpdateMutationPayload' } & {
      documentModule?: Maybe<
        { __typename?: 'DocumentModuleNode' } & Pick<
          DocumentModuleNode,
          | 'id'
          | 'name'
          | 'slug'
          | 'overview'
          | 'headline'
          | 'description'
          | 'jsxContent'
          | 'routable'
          | 'routableExact'
          | 'testingMode'
          | 'materialIcon'
          | 'disableAutoComplete'
          | 'disableCheckComplete'
          | 'completedCustomEmailContent'
          | 'completedCustomEmailSubject'
          | 'sendEmailWhenCompleted'
          | 'order'
          | 'privacy'
          | 'availableFrom'
          | 'availableUntil'
          | 'forceLocked'
          | 'accessMode'
          | 'availableFromCustomText'
          | 'moduleLockedCustomText'
        > & {
            document?: Maybe<
              { __typename?: 'FileFiledType' } & Pick<
                FileFiledType,
                'fileName' | 'url' | 'extension'
              >
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type UpdateChatModuleMutationVariables = Exact<{
  input: ChatModuleUpdateMutationInput;
}>;

export type UpdateChatModuleMutation = { __typename?: 'Mutation' } & {
  chatModuleUpdate?: Maybe<
    { __typename?: 'ChatModuleUpdateMutationPayload' } & {
      chatModule?: Maybe<
        { __typename?: 'ChatModuleNode' } & Pick<
          ChatModuleNode,
          | 'id'
          | 'name'
          | 'slug'
          | 'overview'
          | 'headline'
          | 'description'
          | 'jsxContent'
          | 'routable'
          | 'routableExact'
          | 'testingMode'
          | 'materialIcon'
          | 'disableAutoComplete'
          | 'disableCheckComplete'
          | 'completedCustomEmailContent'
          | 'completedCustomEmailSubject'
          | 'sendEmailWhenCompleted'
          | 'order'
          | 'privacy'
          | 'availableFrom'
          | 'availableUntil'
          | 'forceLocked'
          | 'accessMode'
          | 'availableFromCustomText'
          | 'moduleLockedCustomText'
          | 'threadsEnabled'
          | 'broadcastChannelEnabled'
          | 'mode'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type CreateDefaultModuleMutationVariables = Exact<{
  input: CourseModuleCreateMutationInput;
}>;

export type CreateDefaultModuleMutation = { __typename?: 'Mutation' } & {
  courseModuleCreate?: Maybe<
    { __typename?: 'CourseModuleCreateMutationPayload' } & {
      courseModule?: Maybe<
        { __typename?: 'CourseModuleNode' } & Pick<CourseModuleNode, 'id'>
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type ExternalWebinarModuleUpdateMutationVariables = Exact<{
  input: ExternalWebinarModuleUpdateMutationInput;
}>;

export type ExternalWebinarModuleUpdateMutation = {
  __typename?: 'Mutation';
} & {
  externalWebinarModuleUpdate?: Maybe<
    { __typename?: 'ExternalWebinarModuleUpdateMutationPayload' } & {
      externalWebinarModule?: Maybe<
        { __typename?: 'ExternalWebinarModuleNode' } & Pick<
          ExternalWebinarModuleNode,
          | 'id'
          | 'name'
          | 'slug'
          | 'order'
          | 'privacy'
          | 'availableFrom'
          | 'availableUntil'
          | 'forceLocked'
          | 'accessMode'
          | 'availableFromCustomText'
          | 'moduleLockedCustomText'
          | 'sendEmailWhenCompleted'
          | 'disableAutoComplete'
          | 'disableCheckComplete'
          | 'completedCustomEmailContent'
          | 'completedCustomEmailSubject'
          | 'headline'
          | 'overview'
          | 'description'
          | 'liveUrl'
          | 'recordedUrl'
          | 'iframeMode'
          | 'routable'
          | 'routableExact'
          | 'testingMode'
          | 'materialIcon'
          | 'externalLink'
          | 'externalLinkName'
          | 'plannedStartDate'
          | 'plannedEndDate'
          | 'requiredUserPresencePercentile'
          | 'status'
          | 'endedAt'
          | 'chatEnabled'
          | 'wallChannelEnabled'
          | 'broadcastChannelEnabled'
        > & {
            liveMeeting?: Maybe<
              { __typename?: 'ZoomMeetingNode' } & Pick<
                ZoomMeetingNode,
                | 'id'
                | 'meetingNo'
                | 'meetingPwd'
                | 'topic'
                | 'startTime'
                | 'data'
              >
            >;
            iframeProps?: Maybe<
              Array<
                { __typename?: 'HtmlAttributeNode' } & Pick<
                  HtmlAttributeNode,
                  'key' | 'value'
                >
              >
            >;
            variants?: Maybe<
              Array<
                { __typename?: 'WebinarVariant' } & Pick<
                  WebinarVariant,
                  'idx' | 'liveUrl' | 'recordedUrl' | 'iframeMode' | 'name'
                > & {
                    iframeProps?: Maybe<
                      Array<
                        { __typename?: 'HtmlAttributeNode' } & Pick<
                          HtmlAttributeNode,
                          'key' | 'value'
                        >
                      >
                    >;
                  }
              >
            >;
          }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type GetGetUserCourseParticipationQueryVariables = Exact<{
  courseId: Scalars['ID'];
  participationId: Scalars['ID'];
}>;

export type GetGetUserCourseParticipationQuery = { __typename?: 'Query' } & {
  course?: Maybe<
    { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'> & {
        submodules?: Maybe<
          { __typename?: 'CourseModuleConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'CourseModuleEdge' } & {
                  node?: Maybe<
                    | ({
                        __typename?: 'SurveyModuleNode';
                      } & CourseModuleUnionOverviewSurveyModuleNodeFragment)
                    | ({
                        __typename?: 'ExternalWebinarModuleNode';
                      } & CourseModuleUnionOverviewExternalWebinarModuleNodeFragment)
                    | ({
                        __typename?: 'CourseModuleNode';
                      } & CourseModuleUnionOverviewCourseModuleNodeFragment)
                    | ({
                        __typename?: 'ChatModuleNode';
                      } & CourseModuleUnionOverviewChatModuleNodeFragment)
                    | ({
                        __typename?: 'VideoModuleNode';
                      } & CourseModuleUnionOverviewVideoModuleNodeFragment)
                    | ({
                        __typename?: 'LayoutModuleNode';
                      } & CourseModuleUnionOverviewLayoutModuleNodeFragment)
                    | ({
                        __typename?: 'DocumentModuleNode';
                      } & CourseModuleUnionOverviewDocumentModuleNodeFragment)
                    | ({
                        __typename?: 'IframeModuleNode';
                      } & CourseModuleUnionOverviewIframeModuleNodeFragment)
                    | ({
                        __typename?: 'VideowallModuleNode';
                      } & CourseModuleUnionOverviewVideowallModuleNodeFragment)
                  >;
                }
              >
            >;
          }
        >;
        participation?: Maybe<
          { __typename?: 'CourseParticipationNode' } & Pick<
            CourseParticipationNode,
            'id'
          > & { user: { __typename?: 'UserNode' } & Pick<UserNode, 'id'> }
        >;
      }
  >;
};

export type GetParticipationModuleAssessmentsQueryVariables = Exact<{
  participationId: Scalars['ID'];
}>;

export type GetParticipationModuleAssessmentsQuery = {
  __typename?: 'Query';
} & {
  courseParticipation?: Maybe<
    { __typename?: 'CourseParticipationNode' } & Pick<
      CourseParticipationNode,
      'id' | 'created' | 'getStatusDisplay' | 'status' | 'data'
    > & {
        user: { __typename?: 'UserNode' } & Pick<
          UserNode,
          'id' | 'displayName' | 'avatar'
        >;
        moduleAssessments: {
          __typename?: 'CourseModuleAssessmentNodeConnection';
        } & {
          edges: Array<
            Maybe<
              { __typename?: 'CourseModuleAssessmentNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'CourseModuleAssessmentNode' } & Pick<
                    CourseModuleAssessmentNode,
                    | 'id'
                    | 'status'
                    | 'startedAt'
                    | 'created'
                    | 'completedAt'
                    | 'getStatusDisplay'
                  > & {
                      courseModule: { __typename?: 'CourseModuleNode' } & Pick<
                        CourseModuleNode,
                        'id' | 'name' | 'realTypename' | 'materialIcon'
                      >;
                      coursemoduleassessmentexternalwebinar?: Maybe<
                        {
                          __typename?: 'CourseModuleAssessmentExternalWebinarNode';
                        } & Pick<
                          CourseModuleAssessmentExternalWebinarNode,
                          | 'presenceTime'
                          | 'presencePercentile'
                          | 'requiredUserPresencePercentile'
                        >
                      >;
                      coursemoduleassessmentsurvey?: Maybe<
                        {
                          __typename?: 'CourseModuleAssessmentSurveyNode';
                        } & Pick<
                          CourseModuleAssessmentSurveyNode,
                          'minimumScore' | 'minimumScoreType'
                        > & {
                            surveyResponsesList: {
                              __typename?: 'SurveyResponseNodeConnection';
                            } & {
                              edges: Array<
                                Maybe<
                                  { __typename?: 'SurveyResponseNodeEdge' } & {
                                    node?: Maybe<
                                      {
                                        __typename?: 'SurveyResponseNode';
                                      } & Pick<
                                        SurveyResponseNode,
                                        | 'id'
                                        | 'created'
                                        | 'modified'
                                        | 'score'
                                        | 'completed'
                                        | 'maxScore'
                                      >
                                    >;
                                  }
                                >
                              >;
                            };
                          }
                      >;
                    }
                >;
              }
            >
          >;
        };
      }
  >;
};

export type GetCourseInvitationConfigQueryVariables = Exact<{
  courseId: Scalars['ID'];
}>;

export type GetCourseInvitationConfigQuery = { __typename?: 'Query' } & {
  course?: Maybe<
    { __typename?: 'CourseNode' } & Pick<
      CourseNode,
      'id' | 'onlyOneParticipationPerInvitation' | 'useInsecureInvitation'
    >
  >;
};

export type UpdateCourseInvitationConfigMutationVariables = Exact<{
  input: CourseUpdateMutationInput;
}>;

export type UpdateCourseInvitationConfigMutation = {
  __typename?: 'Mutation';
} & {
  courseUpdate?: Maybe<
    { __typename?: 'CourseUpdateMutationPayload' } & {
      course?: Maybe<
        { __typename?: 'CourseNode' } & Pick<
          CourseNode,
          'id' | 'onlyOneParticipationPerInvitation' | 'useInsecureInvitation'
        >
      >;
    }
  >;
};

export type WebinarPollModulesQueryVariables = Exact<{
  courseId: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
}>;

export type WebinarPollModulesQuery = { __typename?: 'Query' } & {
  courseModules?: Maybe<
    { __typename?: 'CourseModuleNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'CourseModuleNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'CourseModuleNode' } & Pick<
                CourseModuleNode,
                'id'
              > & {
                  surveymodule?: Maybe<
                    { __typename?: 'SurveyModuleNode' } & Pick<
                      SurveyModuleNode,
                      | 'assessmentsCounter'
                      | 'id'
                      | 'name'
                      | 'slug'
                      | 'forceLocked'
                    > & {
                        survey?: Maybe<
                          { __typename?: 'SurveyNode' } & Pick<
                            SurveyNode,
                            'id' | 'name'
                          >
                        >;
                      }
                  >;
                }
            >;
          }
        >
      >;
    }
  >;
};

export type PollGoLiveMutationVariables = Exact<{
  input: SurveyModuleUpdateMutationInput;
}>;

export type PollGoLiveMutation = { __typename?: 'Mutation' } & {
  surveyModuleUpdate?: Maybe<
    { __typename?: 'SurveyModuleUpdateMutationPayload' } & {
      surveyModule?: Maybe<
        { __typename?: 'SurveyModuleNode' } & Pick<
          SurveyModuleNode,
          'id' | 'forceLocked'
        >
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type AllCourseDbtemplatesQueryVariables = Exact<{
  courseId: Scalars['ID'];
}>;

export type AllCourseDbtemplatesQuery = { __typename?: 'Query' } & {
  allCourseDbtemplates?: Maybe<
    { __typename?: 'DbtemplatesNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'DbtemplatesNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'DbtemplatesNode' } & Pick<
                DbtemplatesNode,
                | 'id'
                | 'label'
                | 'name'
                | 'content'
                | 'design'
                | 'getNameDisplay'
              >
            >;
          }
        >
      >;
    }
  >;
};

export type DbtemplatePreviewQueryVariables = Exact<{
  id: Scalars['ID'];
  courseId?: Maybe<Scalars['ID']>;
}>;

export type DbtemplatePreviewQuery = { __typename?: 'Query' } & {
  dbtemplate?: Maybe<
    { __typename?: 'DbtemplatesNode' } & Pick<DbtemplatesNode, 'id' | 'preview'>
  >;
};

export type DbtemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DbtemplateQuery = { __typename?: 'Query' } & {
  dbtemplate?: Maybe<
    { __typename?: 'DbtemplatesNode' } & Pick<
      DbtemplatesNode,
      | 'id'
      | 'label'
      | 'subject'
      | 'name'
      | 'content'
      | 'design'
      | 'creationDate'
      | 'lastChanged'
      | 'getNameDisplay'
    > & {
        userHasPermission?: Maybe<
          { __typename?: 'TemplatePermission' } & Pick<
            TemplatePermission,
            'viewTemplate'
          >
        >;
      }
  >;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'UserNode' } & Pick<
      UserNode,
      | 'id'
      | 'avatar'
      | 'firstName'
      | 'lastName'
      | 'displayName'
      | 'isAnonymous'
      | 'isActive'
      | 'isStaff'
      | 'isSuperuser'
      | 'email'
    >
  >;
};

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UserQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'UserNode' } & Pick<
      UserNode,
      'id' | 'firstName' | 'lastName' | 'avatar'
    > & {
        profile?: Maybe<
          { __typename?: 'ProfileNode' } & Pick<
            ProfileNode,
            'id' | 'birthDay' | 'gender'
          > & {
              birthPlace?: Maybe<
                { __typename?: 'AddressNode' } & Pick<AddressNode, 'cityState'>
              >;
            }
        >;
      }
  >;
};

export type GetMosaicoCredentialQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMosaicoCredentialQuery = { __typename?: 'Query' } & {
  mosaico?: Maybe<
    { __typename?: 'MosaicoNode' } & Pick<
      MosaicoNode,
      'active' | 'plannedStartDate' | 'plannedEndDate' | 'name' | 'embedCode'
    > & {
        cameraPlaceholders: { __typename?: 'ImageNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'ImageNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'ImageNode' } & {
                    file?: Maybe<
                      { __typename?: 'ImageFiledType' } & Pick<
                        ImageFiledType,
                        'url'
                      >
                    >;
                  }
                >;
              }
            >
          >;
        };
        credential?: Maybe<
          { __typename?: 'MosaicoCredentialNode' } & Pick<
            MosaicoCredentialNode,
            'type' | 'apiKey' | 'sessionId' | 'token'
          >
        >;
      }
  >;
};

export type AllNotesQueryVariables = Exact<{ [key: string]: never }>;

export type AllNotesQuery = { __typename?: 'Query' } & {
  allNotes?: Maybe<
    { __typename?: 'NoteNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'NoteNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'NoteNode' } & Pick<NoteNode, 'id' | 'text'>
            >;
          }
        >
      >;
    }
  >;
};

export type AllNotesByContentObjectQueryVariables = Exact<{
  contentObject: Scalars['String'];
}>;

export type AllNotesByContentObjectQuery = { __typename?: 'Query' } & {
  allNotes?: Maybe<
    { __typename?: 'NoteNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'NoteNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'NoteNode' } & Pick<NoteNode, 'id' | 'text'>
            >;
          }
        >
      >;
    }
  >;
};

export type UserForPermissionFragment = { __typename?: 'UserNode' } & Pick<
  UserNode,
  'id' | 'displayName' | 'avatar' | 'email'
>;

export type PermissionFragment = { __typename?: 'PermissionNode' } & Pick<
  PermissionNode,
  'id' | 'name' | 'codename'
>;

export type UserPermissionFragment = { __typename?: 'UserPermissions' } & Pick<
  UserPermissions,
  'permissions'
> & { user?: Maybe<{ __typename?: 'UserNode' } & UserForPermissionFragment> };

export type GroupDataForPermissionsFragment = {
  __typename?: 'GroupNode';
} & Pick<GroupNode, 'id' | 'name' | 'label'> & {
    userSet: { __typename?: 'UserNodeConnection' } & {
      edges: Array<
        Maybe<
          { __typename?: 'UserNodeEdge' } & {
            node?: Maybe<
              { __typename?: 'UserNode' } & UserForPermissionFragment
            >;
          }
        >
      >;
    };
  };

export type GroupPermissionsFragment = {
  __typename?: 'GroupPermissions';
} & Pick<GroupPermissions, 'permissions'> & {
    group?: Maybe<
      { __typename?: 'GroupNode' } & GroupDataForPermissionsFragment
    >;
  };

export type GetGenericInstancePermissionsQueryVariables = Exact<{
  instanceId: Scalars['ID'];
}>;

export type GetGenericInstancePermissionsQuery = { __typename?: 'Query' } & {
  genericInstancePermissions?: Maybe<
    { __typename?: 'GenericInstancePermission' } & {
      availablePermissionsOfInstance?: Maybe<
        Array<{ __typename?: 'PermissionNode' } & PermissionFragment>
      >;
      usersPermissionsOfInstance?: Maybe<
        Array<{ __typename?: 'UserPermissions' } & UserPermissionFragment>
      >;
      groupsPermissionsOfInstance?: Maybe<
        Array<{ __typename?: 'GroupPermissions' } & GroupPermissionsFragment>
      >;
    }
  >;
};

export type GroupCreateMutationVariables = Exact<{
  input: GroupCreateMutationInput;
}>;

export type GroupCreateMutation = { __typename?: 'Mutation' } & {
  groupCreate?: Maybe<
    { __typename?: 'GroupCreateMutationPayload' } & {
      group?: Maybe<
        { __typename?: 'GroupNode' } & GroupDataForPermissionsFragment
      >;
      genericInstancePermissions?: Maybe<
        { __typename?: 'GenericInstancePermission' } & {
          groupsPermissionsOfInstance?: Maybe<
            Array<
              { __typename?: 'GroupPermissions' } & GroupPermissionsFragment
            >
          >;
        }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type GroupDeleteMutationVariables = Exact<{
  input: GroupDeleteMutationInput;
}>;

export type GroupDeleteMutation = { __typename?: 'Mutation' } & {
  groupDelete?: Maybe<
    { __typename?: 'GroupDeleteMutationPayload' } & {
      group?: Maybe<
        { __typename?: 'GroupNode' } & GroupDataForPermissionsFragment
      >;
      genericInstancePermissions?: Maybe<
        { __typename?: 'GenericInstancePermission' } & {
          groupsPermissionsOfInstance?: Maybe<
            Array<
              { __typename?: 'GroupPermissions' } & GroupPermissionsFragment
            >
          >;
        }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type GroupRemoveUserMutationVariables = Exact<{
  input: GroupRemoveUserMutationInput;
}>;

export type GroupRemoveUserMutation = { __typename?: 'Mutation' } & {
  groupRemoveUser?: Maybe<
    { __typename?: 'GroupRemoveUserMutationPayload' } & {
      group?: Maybe<
        { __typename?: 'GroupNode' } & GroupDataForPermissionsFragment
      >;
      genericInstancePermissions?: Maybe<
        { __typename?: 'GenericInstancePermission' } & {
          groupsPermissionsOfInstance?: Maybe<
            Array<
              { __typename?: 'GroupPermissions' } & GroupPermissionsFragment
            >
          >;
        }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type GroupAddUserMutationVariables = Exact<{
  input: GroupAddUserMutationInput;
}>;

export type GroupAddUserMutation = { __typename?: 'Mutation' } & {
  groupAddUser?: Maybe<
    { __typename?: 'GroupAddUserMutationPayload' } & {
      group?: Maybe<
        { __typename?: 'GroupNode' } & GroupDataForPermissionsFragment
      >;
      genericInstancePermissions?: Maybe<
        { __typename?: 'GenericInstancePermission' } & {
          groupsPermissionsOfInstance?: Maybe<
            Array<
              { __typename?: 'GroupPermissions' } & GroupPermissionsFragment
            >
          >;
        }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type PermissionChangeMutationVariables = Exact<{
  input: PermissionChangeInput;
}>;

export type PermissionChangeMutation = { __typename?: 'Mutation' } & {
  permissionChange?: Maybe<
    { __typename?: 'PermissionChangePayload' } & {
      genericInstancePermissions?: Maybe<
        { __typename?: 'GenericInstancePermission' } & {
          availablePermissionsOfInstance?: Maybe<
            Array<{ __typename?: 'PermissionNode' } & PermissionFragment>
          >;
          usersPermissionsOfInstance?: Maybe<
            Array<{ __typename?: 'UserPermissions' } & UserPermissionFragment>
          >;
          groupsPermissionsOfInstance?: Maybe<
            Array<
              { __typename?: 'GroupPermissions' } & GroupPermissionsFragment
            >
          >;
        }
      >;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type QuestionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type QuestionQuery = { __typename?: 'Query' } & {
  question?: Maybe<
    { __typename?: 'QuestionNode' } & QuestionUnionFragmentFragment
  >;
};

export type SiteCurrentQueryVariables = Exact<{ [key: string]: never }>;

export type SiteCurrentQuery = { __typename?: 'Query' } & {
  siteCurrent?: Maybe<
    { __typename?: 'SiteNode' } & Pick<SiteNode, 'id'> & {
        faviconData?: Maybe<
          { __typename?: 'FaviconGeneratorNode' } & Pick<
            FaviconGeneratorNode,
            'id'
          > & {
              htmlTags?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'MetaTagNode' } & Pick<
                      MetaTagNode,
                      'tag'
                    > & {
                        attributes?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: 'MetaTagAttributeNode' } & Pick<
                                MetaTagAttributeNode,
                                'key' | 'value'
                              >
                            >
                          >
                        >;
                      }
                  >
                >
              >;
            }
        >;
        mosaico?: Maybe<
          { __typename?: 'MosaicoNode' } & Pick<MosaicoNode, 'id'>
        >;
        company?: Maybe<
          { __typename?: 'CompanyNode' } & CompanyCustomizationDataFragment
        >;
        course?: Maybe<
          { __typename?: 'CourseNode' } & Pick<
            CourseNode,
            'id' | 'slug' | 'layoutType' | 'demo' | 'name'
          > & {
              theme?: Maybe<{ __typename?: 'ThemeNode' } & ThemeFragment>;
              company?: Maybe<
                {
                  __typename?: 'CompanyNode';
                } & CompanyCustomizationDataFragment
              >;
            }
        >;
      }
  >;
};

export type QuestionUnionIdFragmentFragment = {
  __typename?: 'QuestionNode';
} & Pick<QuestionNode, 'id' | 'slug' | 'type'>;

export type QuestionUnionFragmentFragment = {
  __typename?: 'QuestionNode';
} & Pick<
  QuestionNode,
  | 'question'
  | 'order'
  | 'id'
  | 'title'
  | 'label'
  | 'description'
  | 'placeholder'
  | 'helpText'
  | 'type'
  | 'slug'
  | 'enumType'
  | 'required'
  | 'datetimeTypeStartDate'
  | 'datetimeTypeEndDate'
  | 'randomizeChoiches'
  | 'staffOnly'
> & {
    choiches?: Maybe<
      Array<
        Maybe<
          { __typename?: 'QuestionChoicheItem' } & Pick<
            QuestionChoicheItem,
            'value' | 'label' | 'score' | 'filter'
          >
        >
      >
    >;
    requirements?: Maybe<
      Array<
        Maybe<
          { __typename?: 'QuestionRequirementItem' } & Pick<
            QuestionRequirementItem,
            'value'
          > & {
              question?: Maybe<
                { __typename?: 'QuestionNode' } & Pick<
                  QuestionNode,
                  'id' | 'slug' | 'type'
                > & {
                    choiches?: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'QuestionChoicheItem' } & Pick<
                            QuestionChoicheItem,
                            'value' | 'label'
                          >
                        >
                      >
                    >;
                  }
              >;
            }
        >
      >
    >;
  };

export type SurveyNodeFragmentFragment = { __typename?: 'SurveyNode' } & Pick<
  SurveyNode,
  | 'id'
  | 'name'
  | 'description'
  | 'isPublished'
  | 'randomizeQuestions'
  | 'needLoggedUser'
  | 'editableAnswers'
  | 'type'
  | 'label'
> & {
    questions?: Maybe<
      { __typename?: 'QuestionConnection' } & Pick<
        QuestionConnection,
        'totalCount'
      > & {
          edges: Array<
            Maybe<
              { __typename?: 'QuestionEdge' } & {
                node?: Maybe<
                  {
                    __typename?: 'QuestionNode';
                  } & QuestionUnionFragmentFragment
                >;
              }
            >
          >;
        }
    >;
  };

export type QuestionnaireUnionFragmentFragment = {
  __typename?: 'SurveyNode';
} & Pick<SurveyNode, 'id' | 'name' | 'description' | 'isPublished'> & {
    questions?: Maybe<
      { __typename?: 'QuestionConnection' } & Pick<
        QuestionConnection,
        'totalCount'
      > & {
          edges: Array<
            Maybe<
              { __typename?: 'QuestionEdge' } & {
                node?: Maybe<
                  {
                    __typename?: 'QuestionNode';
                  } & QuestionUnionFragmentFragment
                >;
              }
            >
          >;
        }
    >;
  };

export type SaveAnswerToTheQuestionMutationVariables = Exact<{
  input: SaveAnswerToTheQuestionInput;
}>;

export type SaveAnswerToTheQuestionMutation = { __typename?: 'Mutation' } & {
  saveAnswerToTheQuestion?: Maybe<
    { __typename?: 'SaveAnswerToTheQuestion' } & Pick<
      SaveAnswerToTheQuestion,
      'ok'
    > & {
        response?: Maybe<
          { __typename?: 'SurveyResponseNode' } & Pick<
            SurveyResponseNode,
            'id' | 'partial'
          > & {
              answers: { __typename?: 'AnswerNodeConnection' } & {
                edges: Array<
                  Maybe<
                    { __typename?: 'AnswerNodeEdge' } & {
                      node?: Maybe<
                        { __typename?: 'AnswerNode' } & Pick<
                          AnswerNode,
                          'id' | 'data'
                        > & {
                            question?: Maybe<
                              {
                                __typename?: 'QuestionNode';
                              } & QuestionUnionIdFragmentFragment
                            >;
                          }
                      >;
                    }
                  >
                >;
              };
            }
        >;
      }
  >;
};

export type SaveSurveyBulkResponseMutationVariables = Exact<{
  input: SaveSurveyBulkResponseInput;
}>;

export type SaveSurveyBulkResponseMutation = { __typename?: 'Mutation' } & {
  saveSurveyBulkResponse?: Maybe<
    { __typename?: 'SaveSurveyBulkResponse' } & Pick<
      SaveSurveyBulkResponse,
      'ok'
    > & {
        response?: Maybe<
          { __typename?: 'SurveyResponseNode' } & Pick<
            SurveyResponseNode,
            'id' | 'partial'
          > & {
              answers: { __typename?: 'AnswerNodeConnection' } & {
                edges: Array<
                  Maybe<
                    { __typename?: 'AnswerNodeEdge' } & {
                      node?: Maybe<
                        { __typename?: 'AnswerNode' } & Pick<
                          AnswerNode,
                          'id' | 'data'
                        > & {
                            question?: Maybe<
                              {
                                __typename?: 'QuestionNode';
                              } & QuestionUnionIdFragmentFragment
                            >;
                          }
                      >;
                    }
                  >
                >;
              };
            }
        >;
      }
  >;
};

export type AllSurveyResponseQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  surveyType?: Maybe<Scalars['String']>;
}>;

export type AllSurveyResponseQuery = { __typename?: 'Query' } & {
  allSurveyResponse?: Maybe<
    { __typename?: 'SurveyResponseNodeConnection' } & Pick<
      SurveyResponseNodeConnection,
      'totalCount'
    > & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'hasNextPage' | 'startCursor' | 'endCursor'
        >;
        edges: Array<
          Maybe<
            { __typename?: 'SurveyResponseNodeEdge' } & {
              node?: Maybe<
                { __typename?: 'SurveyResponseNode' } & Pick<
                  SurveyResponseNode,
                  'id' | 'modified' | 'partial'
                >
              >;
            }
          >
        >;
      }
  >;
};

export type GetSurveyResponseWithSurveyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetSurveyResponseWithSurveyQuery = { __typename?: 'Query' } & {
  surveyResponse?: Maybe<
    { __typename?: 'SurveyResponseNode' } & Pick<
      SurveyResponseNode,
      'partial'
    > & {
        survey?: Maybe<
          { __typename?: 'SurveyNode' } & QuestionnaireUnionFragmentFragment
        >;
        answers: { __typename?: 'AnswerNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'AnswerNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'AnswerNode' } & Pick<
                    AnswerNode,
                    'id' | 'data'
                  > & {
                      question?: Maybe<
                        {
                          __typename?: 'QuestionNode';
                        } & QuestionUnionIdFragmentFragment
                      >;
                    }
                >;
              }
            >
          >;
        };
      }
  >;
};

export type GetSurveyResponseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetSurveyResponseQuery = { __typename?: 'Query' } & {
  surveyResponse?: Maybe<
    { __typename?: 'SurveyResponseNode' } & Pick<
      SurveyResponseNode,
      'partial'
    > & {
        answers: { __typename?: 'AnswerNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'AnswerNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'AnswerNode' } & Pick<
                    AnswerNode,
                    'id' | 'data'
                  >
                >;
              }
            >
          >;
        };
      }
  >;
};

export type GetSurveyResponseResultsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetSurveyResponseResultsQuery = { __typename?: 'Query' } & {
  surveyResponse?: Maybe<
    { __typename?: 'SurveyResponseNode' } & Pick<
      SurveyResponseNode,
      'partial' | 'score' | 'maxScore'
    > & {
        answers: { __typename?: 'AnswerNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'AnswerNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'AnswerNode' } & Pick<
                    AnswerNode,
                    'id' | 'data' | 'score' | 'maxScore'
                  > & {
                      question?: Maybe<
                        { __typename?: 'QuestionNode' } & Pick<
                          QuestionNode,
                          'title' | 'description' | 'slug' | 'required' | 'type'
                        >
                      >;
                    }
                >;
              }
            >
          >;
        };
      }
  >;
};

export type GetAllSurveysQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllSurveysQuery = { __typename?: 'Query' } & {
  allSurveys?: Maybe<
    { __typename?: 'SurveyNodeConnection' } & Pick<
      SurveyNodeConnection,
      'totalCount'
    > & {
        edges: Array<
          Maybe<
            { __typename?: 'SurveyNodeEdge' } & {
              node?: Maybe<
                { __typename?: 'SurveyNode' } & Pick<
                  SurveyNode,
                  'id' | 'description' | 'name' | 'created'
                >
              >;
            }
          >
        >;
      }
  >;
};

export type GetSurveyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetSurveyQuery = { __typename?: 'Query' } & {
  survey?: Maybe<{ __typename?: 'SurveyNode' } & SurveyNodeFragmentFragment>;
};

export type GetSurveyQuestionsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetSurveyQuestionsQuery = { __typename?: 'Query' } & {
  survey?: Maybe<
    { __typename?: 'SurveyNode' } & Pick<
      SurveyNode,
      'id' | 'name' | 'label'
    > & {
        questions?: Maybe<
          { __typename?: 'QuestionConnection' } & {
            edges: Array<
              Maybe<
                { __typename?: 'QuestionEdge' } & {
                  node?: Maybe<
                    { __typename: 'QuestionNode' } & Pick<QuestionNode, 'id'>
                  >;
                }
              >
            >;
          }
        >;
      }
  >;
};

export type GetQuestionWithAnswersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetQuestionWithAnswersQuery = { __typename?: 'Query' } & {
  question?: Maybe<
    { __typename?: 'QuestionNode' } & Pick<
      QuestionNode,
      'id' | 'question' | 'title' | 'description' | 'label' | 'slug' | 'type'
    > & {
        survey: { __typename?: 'SurveyNode' } & Pick<SurveyNode, 'id'>;
        choiches?: Maybe<
          Array<
            Maybe<
              { __typename?: 'QuestionChoicheItem' } & Pick<
                QuestionChoicheItem,
                'value'
              >
            >
          >
        >;
        answers: { __typename?: 'AnswerNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'AnswerNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'AnswerNode' } & Pick<
                    AnswerNode,
                    'id' | 'data'
                  >
                >;
              }
            >
          >;
        };
      }
  >;
};

export type GetMyLastSurveyResponseOfSurveyQueryVariables = Exact<{
  surveyId: Scalars['ID'];
}>;

export type GetMyLastSurveyResponseOfSurveyQuery = { __typename?: 'Query' } & {
  lastSurveyResponseOfSurvey?: Maybe<
    { __typename?: 'SurveyResponseNode' } & Pick<
      SurveyResponseNode,
      'id' | 'partial' | 'enough'
    >
  >;
};

export type GetMyLastSurveyResponseDataOfSurveyQueryVariables = Exact<{
  surveyId: Scalars['ID'];
}>;

export type GetMyLastSurveyResponseDataOfSurveyQuery = {
  __typename?: 'Query';
} & {
  lastSurveyResponseOfSurvey?: Maybe<
    { __typename?: 'SurveyResponseNode' } & Pick<
      SurveyResponseNode,
      'id' | 'partial' | 'enough'
    > & {
        answers: { __typename?: 'AnswerNodeConnection' } & {
          edges: Array<
            Maybe<
              { __typename?: 'AnswerNodeEdge' } & {
                node?: Maybe<
                  { __typename?: 'AnswerNode' } & Pick<
                    AnswerNode,
                    'id' | 'data'
                  > & {
                      question?: Maybe<
                        { __typename: 'QuestionNode' } & Pick<
                          QuestionNode,
                          'id' | 'title' | 'description' | 'label' | 'slug'
                        >
                      >;
                    }
                >;
              }
            >
          >;
        };
      }
  >;
};

export type ThemeCustomizationFragment = {
  __typename?: 'CompanyThemeCustomizationNode';
} & Pick<
  CompanyThemeCustomizationNode,
  'id' | 'primary' | 'secondary' | 'type'
> & {
    cover?: Maybe<
      { __typename?: 'ImageFiledType' } & {
        thumbnail?: Maybe<
          { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
        >;
      }
    >;
    navBarLogo?: Maybe<
      { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'name'> & {
          thumbnail?: Maybe<
            { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
          >;
        }
    >;
    footerLogo?: Maybe<
      { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'name'> & {
          thumbnail?: Maybe<
            { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
          >;
        }
    >;
    sideBarLogo?: Maybe<
      { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'name'> & {
          thumbnail?: Maybe<
            { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
          >;
        }
    >;
    logoOnDark?: Maybe<
      { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'name'> & {
          thumbnail?: Maybe<
            { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
          >;
        }
    >;
    logoOnLight?: Maybe<
      { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'name'> & {
          thumbnail?: Maybe<
            { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
          >;
        }
    >;
  };

export type ThemeFragment = { __typename?: 'ThemeNode' } & Pick<
  ThemeNode,
  'id' | 'name' | 'configuration' | 'enableInDashboard'
> & {
    cover?: Maybe<
      { __typename?: 'ImageFiledType' } & {
        thumbnail?: Maybe<
          { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
        >;
      }
    >;
    navBarLogo?: Maybe<
      { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'name'> & {
          thumbnail?: Maybe<
            { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
          >;
        }
    >;
    primaryLogo?: Maybe<
      { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'name'> & {
          thumbnail?: Maybe<
            { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
          >;
        }
    >;
    secondaryLogo?: Maybe<
      { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'name'> & {
          thumbnail?: Maybe<
            { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
          >;
        }
    >;
    avatarLogo?: Maybe<
      { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'name'> & {
          thumbnail?: Maybe<
            { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
          >;
        }
    >;
    logoSquare?: Maybe<
      { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'name'> & {
          thumbnail?: Maybe<
            { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
          >;
        }
    >;
    footerLogo?: Maybe<
      { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'name'> & {
          thumbnail?: Maybe<
            { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
          >;
        }
    >;
    sideBarLogo?: Maybe<
      { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'name'> & {
          thumbnail?: Maybe<
            { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
          >;
        }
    >;
    logoOnDark?: Maybe<
      { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'name'> & {
          thumbnail?: Maybe<
            { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
          >;
        }
    >;
    logoOnLight?: Maybe<
      { __typename?: 'ImageFiledType' } & Pick<ImageFiledType, 'name'> & {
          thumbnail?: Maybe<
            { __typename?: 'ThumbnailType' } & Pick<ThumbnailType, 'url'>
          >;
        }
    >;
  };

export type GetThemeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetThemeQuery = { __typename?: 'Query' } & {
  theme?: Maybe<{ __typename?: 'ThemeNode' } & ThemeFragment>;
};

export type ThemeUpdateMutationVariables = Exact<{
  input: ThemeUpdateMutationInput;
}>;

export type ThemeUpdateMutation = { __typename?: 'Mutation' } & {
  themeUpdate?: Maybe<
    { __typename?: 'ThemeUpdateMutationPayload' } & {
      theme?: Maybe<{ __typename?: 'ThemeNode' } & ThemeFragment>;
    }
  >;
};

export type ThemeCreateMutationVariables = Exact<{
  input: ThemeCreateMutationInput;
}>;

export type ThemeCreateMutation = { __typename?: 'Mutation' } & {
  themeCreate?: Maybe<
    { __typename?: 'ThemeCreateMutationPayload' } & {
      theme?: Maybe<{ __typename?: 'ThemeNode' } & ThemeFragment>;
    }
  >;
};

export type ThemeDeleteMutationVariables = Exact<{
  input: ThemeDeleteMutationInput;
}>;

export type ThemeDeleteMutation = { __typename?: 'Mutation' } & {
  themeDelete?: Maybe<
    { __typename?: 'ThemeDeleteMutationPayload' } & {
      theme?: Maybe<{ __typename?: 'ThemeNode' } & ThemeFragment>;
      errors?: Maybe<
        Array<
          { __typename?: 'MutationErrorType' } & Pick<
            MutationErrorType,
            'field' | 'message'
          >
        >
      >;
    }
  >;
};

export type ZoomMeetingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ZoomMeetingQuery = { __typename?: 'Query' } & {
  zoomMeeting?: Maybe<
    { __typename?: 'ZoomMeetingNode' } & Pick<
      ZoomMeetingNode,
      'id' | 'meetingNo' | 'meetingPwd' | 'topic' | 'startTime' | 'data'
    >
  >;
};

export type OnCourseModuleUpdateSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OnCourseModuleUpdateSubscription = {
  __typename?: 'Subscription';
} & {
  onCourseModuleUpdateSubscription?: Maybe<
    { __typename?: 'OnCourseModuleUpdate' } & {
      courseModule?: Maybe<
        | ({
            __typename?: 'SurveyModuleNode';
          } & CourseModuleUnionSurveyModuleNodeFragment)
        | ({
            __typename?: 'ExternalWebinarModuleNode';
          } & CourseModuleUnionExternalWebinarModuleNodeFragment)
        | ({
            __typename?: 'CourseModuleNode';
          } & CourseModuleUnionCourseModuleNodeFragment)
        | ({
            __typename?: 'ChatModuleNode';
          } & CourseModuleUnionChatModuleNodeFragment)
        | ({
            __typename?: 'VideoModuleNode';
          } & CourseModuleUnionVideoModuleNodeFragment)
        | ({
            __typename?: 'LayoutModuleNode';
          } & CourseModuleUnionLayoutModuleNodeFragment)
        | ({
            __typename?: 'DocumentModuleNode';
          } & CourseModuleUnionDocumentModuleNodeFragment)
        | ({
            __typename?: 'IframeModuleNode';
          } & CourseModuleUnionIframeModuleNodeFragment)
        | ({
            __typename?: 'VideowallModuleNode';
          } & CourseModuleUnionVideowallModuleNodeFragment)
      >;
    }
  >;
};

export type OnRoomPresenceUpdateSubscriptionVariables = Exact<{
  channelName: Scalars['ID'];
  withUsers: Scalars['Boolean'];
}>;

export type OnRoomPresenceUpdateSubscription = {
  __typename?: 'Subscription';
} & {
  onRoomPresenceUpdateSubscription?: Maybe<
    { __typename?: 'OnRoomPresenceUpdate' } & Pick<
      OnRoomPresenceUpdate,
      'bulkChange'
    > & {
        roomSummary?: Maybe<
          { __typename?: 'SummaryRoomNode' } & Pick<
            SummaryRoomNode,
            'channelName' | 'usersCount' | 'anonymousUsersCount'
          >
        >;
        userAdded?: Maybe<
          { __typename?: 'UserNode' } & Pick<
            UserNode,
            'id' | 'displayName' | 'avatar'
          >
        >;
        userRemoved?: Maybe<
          { __typename?: 'UserNode' } & Pick<
            UserNode,
            'id' | 'displayName' | 'avatar'
          >
        >;
      }
  >;
};

export type OnRoomPresenceMessageSubscriptionVariables = Exact<{
  channelName: Scalars['ID'];
}>;

export type OnRoomPresenceMessageSubscription = {
  __typename?: 'Subscription';
} & {
  onRoomPresenceMessageSubscription?: Maybe<
    { __typename?: 'OnRoomPresenceMessage' } & Pick<
      OnRoomPresenceMessage,
      'type' | 'content'
    > & {
        user?: Maybe<
          { __typename?: 'UserNode' } & Pick<
            UserNode,
            'id' | 'displayName' | 'avatar'
          >
        >;
      }
  >;
};

export type OnRoomPresenceTrackSubscriptionVariables = Exact<{
  roomChannelName: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
}>;

export type OnRoomPresenceTrackSubscription = {
  __typename?: 'Subscription';
} & {
  onRoomPresenceTrack?: Maybe<
    { __typename?: 'OnRoomPresenceTrack' } & Pick<
      OnRoomPresenceTrack,
      'registered'
    >
  >;
};

export type OnCourseParticipationUpdateSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OnCourseParticipationUpdateSubscription = {
  __typename?: 'Subscription';
} & {
  onCourseParticipationUpdateSubscription?: Maybe<
    { __typename?: 'OnCourseParticipationUpdate' } & Pick<
      OnCourseParticipationUpdate,
      'deleted'
    > & {
        participation?: Maybe<
          { __typename?: 'CourseParticipationNode' } & Pick<
            CourseParticipationNode,
            'id' | 'status' | 'partial'
          >
        >;
      }
  >;
};

export type OnAllCourseParticipationUpdateSubscriptionVariables = Exact<{
  courseId: Scalars['ID'];
}>;

export type OnAllCourseParticipationUpdateSubscription = {
  __typename?: 'Subscription';
} & {
  onCourseParticipationUpdateSubscription?: Maybe<
    { __typename?: 'OnCourseParticipationUpdate' } & Pick<
      OnCourseParticipationUpdate,
      'deleted'
    > & {
        participation?: Maybe<
          { __typename?: 'CourseParticipationNode' } & Pick<
            CourseParticipationNode,
            | 'id'
            | 'status'
            | 'data'
            | 'dataChanges'
            | 'isOnline'
            | 'created'
            | 'modified'
          > & {
              course: { __typename?: 'CourseNode' } & Pick<CourseNode, 'id'>;
              formResponse?: Maybe<
                { __typename?: 'SurveyResponseNode' } & Pick<
                  SurveyResponseNode,
                  'id'
                >
              >;
              invitation?: Maybe<
                { __typename?: 'CourseInvitationNode' } & Pick<
                  CourseInvitationNode,
                  'id'
                >
              >;
              user: { __typename?: 'UserNode' } & Pick<
                UserNode,
                | 'id'
                | 'avatar'
                | 'isAnonymous'
                | 'isStaff'
                | 'displayName'
                | 'email'
              >;
            }
        >;
      }
  >;
};

export type OnAllCourseInvitationUpdateSubscriptionVariables = Exact<{
  courseId: Scalars['ID'];
}>;

export type OnAllCourseInvitationUpdateSubscription = {
  __typename?: 'Subscription';
} & {
  onCourseInvitationUpdateSubscription?: Maybe<
    { __typename?: 'OnCourseInvitationUpdate' } & Pick<
      OnCourseInvitationUpdate,
      'created' | 'deleted'
    > & {
        invitation?: Maybe<
          {
            __typename?: 'CourseInvitationNode';
          } & CourseInvitationtNodeFragment
        >;
      }
  >;
};

export type OnQuestionUpdateSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OnQuestionUpdateSubscription = { __typename?: 'Subscription' } & {
  onQuestionUpdateSubscription?: Maybe<
    { __typename?: 'OnQuestionUpdate' } & {
      question?: Maybe<
        { __typename?: 'QuestionNode' } & Pick<
          QuestionNode,
          | 'id'
          | 'question'
          | 'title'
          | 'description'
          | 'label'
          | 'slug'
          | 'type'
        > & {
            answers: { __typename?: 'AnswerNodeConnection' } & {
              edges: Array<
                Maybe<
                  { __typename?: 'AnswerNodeEdge' } & {
                    node?: Maybe<
                      { __typename?: 'AnswerNode' } & Pick<
                        AnswerNode,
                        'id' | 'data'
                      >
                    >;
                  }
                >
              >;
            };
          }
      >;
    }
  >;
};

export type OnThemeUpdateSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OnThemeUpdateSubscription = { __typename?: 'Subscription' } & {
  onThemeUpdateSubscription?: Maybe<
    { __typename?: 'OnThemeUpdate' } & Pick<
      OnThemeUpdate,
      'created' | 'deleted'
    > & { theme?: Maybe<{ __typename?: 'ThemeNode' } & ThemeFragment> }
  >;
};

export const CompanyUserInvitationFragmentFragmentDoc = gql`
  fragment companyUserInvitationFragment on CompanyUserInvitationNode {
    id
    email
    company {
      id
      name
    }
    status
    sentAt
    repliedAt
    revokedAt
    expiredAt
  }
`;
export const SurveyResponseNodeStaffFragmentDoc = gql`
  fragment SurveyResponseNodeStaff on SurveyResponseNode {
    answers {
      edges {
        node {
          id
          data
          question {
            ... on QuestionNode {
              question
              id
            }
          }
        }
      }
    }
  }
`;
export const AuthorFieldsFragmentDoc = gql`
  fragment authorFields on AuthorNode {
    id
    title
    firstName
    lastName
    headline
    shortBio
    avatar {
      thumbnail(width: 150, height: 150) {
        url
      }
    }
  }
`;
export const AuthorNodeFragmentDoc = gql`
  fragment authorNode on AuthorNode {
    id
    title
    firstName
    lastName
    headline
    shortBio
    avatar {
      thumbnail(width: 150, height: 150) {
        url
      }
    }
  }
`;
export const AuthorsGroupNodeFragmentDoc = gql`
  fragment authorsGroupNode on AuthorsGroupNode {
    id
    name
    description
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${AuthorNodeFragmentDoc}
`;
export const CourseFieldsFragmentDoc = gql`
  fragment CourseFields on CourseNode {
    id
    name
    headline
    overview
    demo
    code
    label
    requireDataParticipation
    fromEmailSenderName
    emailExtraContent
    enableConfirmParticipationEmail
    enablePendingParticipationEmail
    automaticallyAssignManageParticipationsPerm
    password
    participationForm {
      id
      name
      label
    }
    owner {
      id
      displayName
      email
    }
    pdfProgramButtonLabel
    pdfProgram {
      fileName
      url
      extension
    }
    userHasPermission {
      viewCourse
      changeCourse
      moderateChatCourse
      manageParticipationCourse
      addParticipationCourse
      manageInvitationsCourse
      downloadFullReport
      anyPermission
    }
    description
    layoutType
    navigationType
    jsxContent
    defaultLanguage
    theme {
      id
      name
      enableInDashboard
    }
    displayParticipantsCounter
    displayContinueButton
    acceptAnonyumousEnrollment
    forceAnonyumousEnrollment
    onlyOneParticipationPerInvitation
    automaticallyAcceptsParticipation
    acceptsParticipationByExternalUuid
    externalUuidParameterName
    externalUuidParameterRequired
    generateParticipationQrcode
    participationExternalLink
    slug
    listed
    startDate
    available
    templates {
      edges {
        node {
          id
          name
          label
        }
      }
    }
    mosaico {
      id
    }
    commonAuthors {
      edges {
        node {
          id
          firstName
        }
      }
    }
    authorsGroups {
      edges {
        node {
          ...authorsGroupNode
        }
      }
    }
    endDate
    cover {
      thumbnail(height: 400) {
        url
      }
    }
    background {
      thumbnail(height: 916) {
        url
      }
    }
    company {
      id
      name
      streamService {
        id
        accountId
      }
      defaultTheme {
        id
        name
        enableInDashboard
      }
    }
  }
  ${AuthorsGroupNodeFragmentDoc}
`;
export const CourseInvitationtNodeFragmentDoc = gql`
  fragment courseInvitationtNode on CourseInvitationNode {
    id
    created
    email
    data
    link
    owner {
      id
      displayName
      email
      avatar(size: 40)
    }
    linkWithAuthentication
    lastEmailSent
    autoAcceptParticipation
    autoCreateParticipation
    participations {
      totalCount
    }
    destinationGroups {
      edges {
        node {
          id
          name
          label
        }
      }
    }
  }
`;
export const CompanyBillingDataFragmentDoc = gql`
  fragment companyBillingData on CompanyNode {
    id
    name
    customerName
    sdi
    hasBillingData
    billingAddress {
      city
      country
      line1
      line2
      state
      postalCode
    }
    taxData {
      value
      type
    }
  }
`;
export const CompanyAddressDataFragmentDoc = gql`
  fragment companyAddressData on CompanyNode {
    id
    address {
      city
      country
      line1
      line2
      state
      postalCode
    }
  }
`;
export const CompanyFragmentDoc = gql`
  fragment company on CompanyNode {
    id
    name
    slug
    size
    websiteUrl
    phone
    customerName
    slug
    owner {
      id
      displayName
    }
    category
    status
    defaultLanguage
    enableLogoLink
    enableAuth
    homeJsxContent
    footerJsxContent
    baseLogo {
      name
      url
      thumb160: thumbnail(width: 160, height: 160, padding: true, format: PNG) {
        url
      }
    }
  }
`;
export const ThemeCustomizationFragmentDoc = gql`
  fragment themeCustomization on CompanyThemeCustomizationNode {
    id
    primary
    secondary
    type
    cover {
      thumbnail(height: 400) {
        url
      }
    }
    navBarLogo: logoOnLight {
      name
      thumbnail(height: 45, format: PNG) {
        url
      }
    }
    footerLogo: logoOnDark {
      name
      thumbnail(height: 45, format: PNG) {
        url
      }
    }
    sideBarLogo: logoOnLight {
      name
      thumbnail(height: 300, format: PNG) {
        url
      }
    }
    logoOnDark {
      name
      thumbnail(width: 200, format: PNG) {
        url
      }
    }
    logoOnLight {
      name
      thumbnail(width: 200, padding: true, format: PNG) {
        url
      }
    }
  }
`;
export const CompanyCustomizationDataFragmentDoc = gql`
  fragment companyCustomizationData on CompanyNode {
    id
    name
    slug
    phone
    websiteUrl
    customerName
    enableAuth
    enableLogoLink
    defaultLanguage
    footerJsxContent
    homeJsxContent
    defaultTheme {
      id
      configuration
    }
    profileForm {
      id
    }
    legalLinks {
      edges {
        node {
          label
          url
        }
      }
    }
    address {
      city
      country
      line1
      line2
      state
      postalCode
    }
    theme {
      ...themeCustomization
    }
  }
  ${ThemeCustomizationFragmentDoc}
`;
export const PublicCompanyCustomizationDataFragmentDoc = gql`
  fragment publicCompanyCustomizationData on PublicCompanyNode {
    id
    name
    slug
    phone
    customerName
    websiteUrl
    enableAuth
    footerJsxContent
    homeJsxContent
    enableLogoLink
    defaultTheme {
      id
      configuration
    }
    legalLinks {
      edges {
        node {
          label
          url
        }
      }
    }
    address {
      city
      country
      line1
      line2
      state
      postalCode
    }
    theme {
      ...themeCustomization
    }
  }
  ${ThemeCustomizationFragmentDoc}
`;
export const CompanyThemeFragmentDoc = gql`
  fragment companyTheme on CompanyThemeCustomizationNode {
    id
    type
    primary
    secondary
  }
`;
export const CourseModuleAssessmentSurveyNodeFragmentDoc = gql`
  fragment courseModuleAssessmentSurveyNode on CourseModuleAssessmentSurveyNode {
    id
    status
    attemptNumber
    attemptsAvailable
    numberOfAttempts
    minimumScore
    minimumScoreType
    surveyResponse {
      id
      completed
      score
      maxScore
    }
  }
`;
export const CourseModuleAssessmentExternalWebinarNodeFragmentDoc = gql`
  fragment courseModuleAssessmentExternalWebinarNode on CourseModuleAssessmentExternalWebinarNode {
    id
    status
    requiredUserPresencePercentile
  }
`;
export const CourseModuleAssessmentVideoNodeFragmentDoc = gql`
  fragment courseModuleAssessmentVideoNode on CourseModuleAssessmentVideoNode {
    id
    status
  }
`;
export const CourseModuleAssessmentNodeFragmentDoc = gql`
  fragment courseModuleAssessmentNode on CourseModuleAssessmentNode {
    id
    status
  }
`;
export const CourseModuleAssessmentUnionFragmentDoc = gql`
  fragment courseModuleAssessmentUnion on CourseModuleAssessmentUnion {
    __typename
    ... on CourseModuleAssessmentSurveyNode {
      ...courseModuleAssessmentSurveyNode
    }
    ... on CourseModuleAssessmentExternalWebinarNode {
      ...courseModuleAssessmentExternalWebinarNode
    }
    ... on CourseModuleAssessmentVideoNode {
      ...courseModuleAssessmentVideoNode
    }
    ... on CourseModuleAssessmentNode {
      ...courseModuleAssessmentNode
    }
  }
  ${CourseModuleAssessmentSurveyNodeFragmentDoc}
  ${CourseModuleAssessmentExternalWebinarNodeFragmentDoc}
  ${CourseModuleAssessmentVideoNodeFragmentDoc}
  ${CourseModuleAssessmentNodeFragmentDoc}
`;
export const ChatModuleNodeFragmentDoc = gql`
  fragment chatModuleNode on ChatModuleNode {
    id
    name
    headline
    overview
    description
    jsxContent
    order
    slug
    privacy
    frontendUrl
    moduleOptions
    routable
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    availableFrom
    availableFromCustomText
    moduleLockedCustomText
    forceLocked
    accessMode
    chatModeratorUserId
    chatModeratorUserToken
    threadsEnabled
    broadcastChannelEnabled
    sendEmailWhenCompleted
    mode
    chatChannels {
      id
      channelType
      url
    }
    userModuleAssessment {
      ...courseModuleAssessmentUnion
    }
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${CourseModuleAssessmentUnionFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;
export const CourseModuleNodeFragmentDoc = gql`
  fragment courseModuleNode on CourseModuleNode {
    id
    name
    slug
    frontendUrl
    order
    routable
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    sendEmailWhenCompleted
    jsxContent
    overview
    headline
    description
    moduleOptions
    availableFrom
    availableUntil
    forceLocked
    accessMode
    availableFromCustomText
    moduleLockedCustomText
    jsxContent
    privacy
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
    userModuleAssessment {
      ...courseModuleAssessmentUnion
    }
    externalwebinarmodule {
      id
    }
    iframemodule {
      id
    }
  }
  ${AuthorNodeFragmentDoc}
  ${CourseModuleAssessmentUnionFragmentDoc}
`;
export const CourseModuleUnionIdFragmentDoc = gql`
  fragment courseModuleUnionId on CourseModuleUnion {
    ... on ChatModuleNode {
      id
    }
    ... on CourseModuleNode {
      id
    }
    ... on SurveyModuleNode {
      id
    }
    ... on ExternalWebinarModuleNode {
      id
    }
    ... on VideoModuleNode {
      id
    }
    ... on LayoutModuleNode {
      id
    }
    ... on DocumentModuleNode {
      id
    }
    ... on IframeModuleNode {
      id
    }
    ... on VideowallModuleNode {
      id
    }
  }
`;
export const QuestionUnionFragmentFragmentDoc = gql`
  fragment QuestionUnionFragment on QuestionUnion {
    ... on QuestionNode {
      question
      order
      id
      title
      label
      description
      placeholder
      helpText
      type
      slug
      enumType
      required
      datetimeTypeStartDate
      datetimeTypeEndDate
      choiches {
        value
        label
        score
        filter
      }
      randomizeChoiches
      staffOnly
      requirements {
        value
        question {
          ... on QuestionNode {
            id
            slug
            type
            choiches {
              value
              label
            }
          }
        }
      }
    }
  }
`;
export const SurveyNodeFragmentFragmentDoc = gql`
  fragment SurveyNodeFragment on SurveyNode {
    id
    name
    description
    isPublished
    randomizeQuestions
    needLoggedUser
    editableAnswers
    type
    label
    questions {
      totalCount
      edges {
        node {
          ...QuestionUnionFragment
        }
      }
    }
  }
  ${QuestionUnionFragmentFragmentDoc}
`;
export const SurveyModuleNodeFragmentDoc = gql`
  fragment surveyModuleNode on SurveyModuleNode {
    id
    name
    headline
    overview
    description
    jsxContent
    order
    slug
    frontendUrl
    privacy
    routable
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    availableFrom
    availableUntil
    forceLocked
    accessMode
    moduleOptions
    availableFromCustomText
    moduleLockedCustomText
    sendEmailWhenCompleted
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    minimumScore
    minimumScoreType
    numberOfAttempts
    hideUserDataInReports
    showScoreResults
    showOnlyErrorsOnScoreResults
    trainingModule {
      ...courseModuleUnionId
    }
    forceViewTrainingModuleOnFailAttempt
    viewTrainingModuleMinimumTime
    survey {
      ...SurveyNodeFragment
    }
    userModuleAssessment {
      ...courseModuleAssessmentUnion
    }
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${CourseModuleUnionIdFragmentDoc}
  ${SurveyNodeFragmentFragmentDoc}
  ${CourseModuleAssessmentUnionFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;
export const VideowallModuleNodeFragmentDoc = gql`
  fragment videowallModuleNode on VideowallModuleNode {
    id
    name
    slug
    frontendUrl
    routable
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    order
    availableFrom
    availableUntil
    forceLocked
    accessMode
    availableFromCustomText
    moduleLockedCustomText
    sendEmailWhenCompleted
    headline
    overview
    description
    jsxContent
    url
    urlWithParams
    userModuleAssessment {
      ...courseModuleAssessmentUnion
    }
  }
  ${CourseModuleAssessmentUnionFragmentDoc}
`;
export const PollModuleNodeFragmentDoc = gql`
  fragment pollModuleNode on SurveyModuleNode {
    id
    name
    headline
    overview
    description
    order
    slug
    privacy
    accessMode
    routable
    routableExact
    forceLocked
    disableAutoComplete
    disableCheckComplete
    showScoreResults
    survey {
      ...SurveyNodeFragment
    }
    userModuleAssessment {
      ...courseModuleAssessmentUnion
    }
  }
  ${SurveyNodeFragmentFragmentDoc}
  ${CourseModuleAssessmentUnionFragmentDoc}
`;
export const ExternalWebinarModuleNodeFragmentDoc = gql`
  fragment externalWebinarModuleNode on ExternalWebinarModuleNode {
    id
    name
    description
    headline
    overview
    jsxContent
    order
    privacy
    slug
    frontendUrl
    routable
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    sendEmailWhenCompleted
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    liveMeeting {
      id
      meetingPwd
      meetingNo
      topic
      startTime
      data
    }
    liveUrl
    recordedUrl
    iframeMode
    requiredUserPresencePercentile
    moduleOptions
    iframeProps {
      key
      value
    }
    externalLink
    externalLinkName
    status
    isOver
    liveTime
    availableFrom
    availableUntil
    forceLocked
    accessMode
    availableFromCustomText
    moduleLockedCustomText
    chatEnabled
    wallChannelEnabled
    broadcastChannelEnabled
    wallChatChannels {
      id
      channelType
      url
    }
    plannedStartDate
    plannedEndDate
    chatModeratorUserId
    chatModeratorUserToken
    chatChannels {
      id
      channelType
      url
    }
    variants {
      idx
      liveUrl
      recordedUrl
      iframeMode
      iframeProps {
        key
        value
      }
      name
    }
    videowall {
      ...videowallModuleNode
    }
    livePoll {
      ...pollModuleNode
    }
    userModuleAssessment {
      ...courseModuleAssessmentUnion
    }
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${VideowallModuleNodeFragmentDoc}
  ${PollModuleNodeFragmentDoc}
  ${CourseModuleAssessmentUnionFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;
export const VideoModuleNodeFragmentDoc = gql`
  fragment videoModuleNode on VideoModuleNode {
    id
    name
    slug
    frontendUrl
    routable
    displayCompleteButton
    routableExact
    testingMode
    materialIcon
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    sendEmailWhenCompleted
    url
    order
    headline
    overview
    description
    jsxContent
    availableFrom
    availableUntil
    forceLocked
    accessMode
    privacy
    availableFromCustomText
    moduleLockedCustomText
    iframeMode
    videoDuration
    requiredUserLivePercentile
    assessmentsCounter
    userModuleAssessment {
      ...courseModuleAssessmentUnion
    }
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${CourseModuleAssessmentUnionFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;
export const LayoutModuleNodeFragmentDoc = gql`
  fragment layoutModuleNode on LayoutModuleNode {
    id
    name
    slug
    headline
    frontendUrl
    routable
    privacy
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    sendEmailWhenCompleted
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    type
    order
    overview
    description
    type
    jsxContent
    availableFrom
    availableUntil
    forceLocked
    accessMode
    availableFromCustomText
    moduleLockedCustomText
    userModuleAssessment {
      ...courseModuleAssessmentUnion
    }
    livePoll {
      ...pollModuleNode
    }
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${CourseModuleAssessmentUnionFragmentDoc}
  ${PollModuleNodeFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;
export const DocumentModuleNodeFragmentDoc = gql`
  fragment documentModuleNode on DocumentModuleNode {
    id
    name
    slug
    frontendUrl
    routable
    privacy
    routableExact
    testingMode
    materialIcon
    availableUntil
    forceLocked
    assessmentsCounter
    sendEmailWhenCompleted
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    document {
      fileName
      url
      extension
    }
    availableFrom
    availableFromCustomText
    moduleLockedCustomText
    order
    headline
    overview
    description
    jsxContent
    userModuleAssessment {
      ...courseModuleAssessmentUnion
    }
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${CourseModuleAssessmentUnionFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;
export const IframeModuleNodeFragmentDoc = gql`
  fragment iframeModuleNode on IframeModuleNode {
    id
    name
    slug
    privacy
    frontendUrl
    routable
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    order
    jsxContent
    availableFrom
    availableUntil
    forceLocked
    accessMode
    availableFromCustomText
    moduleLockedCustomText
    sendEmailWhenCompleted
    headline
    overview
    description
    url
    iframeHeight
    iframeWidth
    urlWithParams
    participationDataInUrlParams
    external
    userModuleAssessment {
      ...courseModuleAssessmentUnion
    }
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${CourseModuleAssessmentUnionFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;
export const CourseModuleUnionFragmentDoc = gql`
  fragment courseModuleUnion on CourseModuleUnion {
    ... on ChatModuleNode {
      ...chatModuleNode
    }
    ... on CourseModuleNode {
      ...courseModuleNode
    }
    ... on SurveyModuleNode {
      ...surveyModuleNode
    }
    ... on ExternalWebinarModuleNode {
      ...externalWebinarModuleNode
    }
    ... on VideoModuleNode {
      ...videoModuleNode
    }
    ... on LayoutModuleNode {
      ...layoutModuleNode
    }
    ... on DocumentModuleNode {
      ...documentModuleNode
    }
    ... on IframeModuleNode {
      ...iframeModuleNode
    }
    ... on VideowallModuleNode {
      ...videowallModuleNode
    }
  }
  ${ChatModuleNodeFragmentDoc}
  ${CourseModuleNodeFragmentDoc}
  ${SurveyModuleNodeFragmentDoc}
  ${ExternalWebinarModuleNodeFragmentDoc}
  ${VideoModuleNodeFragmentDoc}
  ${LayoutModuleNodeFragmentDoc}
  ${DocumentModuleNodeFragmentDoc}
  ${IframeModuleNodeFragmentDoc}
  ${VideowallModuleNodeFragmentDoc}
`;
export const ChatModuleNodeWithSubmodulesFragmentDoc = gql`
  fragment chatModuleNodeWithSubmodules on ChatModuleNode {
    id
    name
    order
    sendEmailWhenCompleted
    slug
    frontendUrl
    routable
    routableExact
    testingMode
    mode
    materialIcon
    assessmentsCounter
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    availableFrom
    availableUntil
    forceLocked
    accessMode
    moduleLockedCustomText
    threadsEnabled
    broadcastChannelEnabled
    submodules {
      totalCount
      edges {
        node {
          ...courseModuleUnion
        }
      }
    }
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${CourseModuleUnionFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;
export const CourseModuleNodeWithSubmodulesFragmentDoc = gql`
  fragment courseModuleNodeWithSubmodules on CourseModuleNode {
    id
    name
    slug
    frontendUrl
    order
    routable
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    moduleLockedCustomText
    forceLocked
    availableFrom
    availableUntil
    accessMode
    sendEmailWhenCompleted
    submodules {
      totalCount
      edges {
        node {
          ...courseModuleUnion
        }
      }
    }
  }
  ${CourseModuleUnionFragmentDoc}
`;
export const SurveyModuleNodeWithSubmodulesFragmentDoc = gql`
  fragment surveyModuleNodeWithSubmodules on SurveyModuleNode {
    id
    name
    order
    sendEmailWhenCompleted
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    slug
    frontendUrl
    routable
    availableFrom
    availableUntil
    forceLocked
    accessMode
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    moduleLockedCustomText
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
    submodules {
      totalCount
      edges {
        node {
          ...courseModuleUnion
        }
      }
    }
  }
  ${AuthorNodeFragmentDoc}
  ${CourseModuleUnionFragmentDoc}
`;
export const ExternalWebinarModuleNodeWithSubmodulesFragmentDoc = gql`
  fragment externalWebinarModuleNodeWithSubmodules on ExternalWebinarModuleNode {
    id
    name
    order
    sendEmailWhenCompleted
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    availableFrom
    availableUntil
    forceLocked
    slug
    frontendUrl
    routable
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    availableFrom
    status
    plannedStartDate
    plannedEndDate
    moduleLockedCustomText
    headline
    submodules {
      totalCount
      edges {
        node {
          ...courseModuleUnion
        }
      }
    }
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${CourseModuleUnionFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;
export const VideoModuleNodeWithSubmodulesFragmentDoc = gql`
  fragment videoModuleNodeWithSubmodules on VideoModuleNode {
    id
    name
    slug
    frontendUrl
    order
    sendEmailWhenCompleted
    routable
    availableFrom
    availableUntil
    forceLocked
    accessMode
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    sendEmailWhenCompleted
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    moduleLockedCustomText
    displayCompleteButton
    submodules {
      totalCount
      edges {
        node {
          ...courseModuleUnion
        }
      }
    }
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${CourseModuleUnionFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;
export const LayoutModuleNodeWithSubmodulesFragmentDoc = gql`
  fragment layoutModuleNodeWithSubmodules on LayoutModuleNode {
    id
    name
    slug
    frontendUrl
    routable
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    availableFrom
    availableUntil
    forceLocked
    accessMode
    order
    sendEmailWhenCompleted
    moduleLockedCustomText
    livePoll {
      ...pollModuleNode
    }
    submodules {
      totalCount
      edges {
        node {
          ...courseModuleUnion
        }
      }
    }
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${PollModuleNodeFragmentDoc}
  ${CourseModuleUnionFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;
export const DocumentModuleNodeWithSubmodulesFragmentDoc = gql`
  fragment documentModuleNodeWithSubmodules on DocumentModuleNode {
    id
    name
    slug
    frontendUrl
    order
    sendEmailWhenCompleted
    routable
    privacy
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    availableFrom
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    forceLocked
    availableFrom
    availableUntil
    accessMode
    document {
      fileName
      url
      extension
    }
    moduleLockedCustomText
    submodules {
      totalCount
      edges {
        node {
          ...courseModuleUnion
        }
      }
    }
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${CourseModuleUnionFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;
export const IframeModuleNodeWithSubmodulesFragmentDoc = gql`
  fragment iframeModuleNodeWithSubmodules on IframeModuleNode {
    id
    name
    slug
    frontendUrl
    routable
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    availableFrom
    availableUntil
    forceLocked
    accessMode
    order
    sendEmailWhenCompleted
    moduleLockedCustomText
    submodules {
      totalCount
      edges {
        node {
          ...courseModuleUnion
        }
      }
    }
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${CourseModuleUnionFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;
export const VideowallModuleNodeWithSubmodulesFragmentDoc = gql`
  fragment videowallModuleNodeWithSubmodules on VideowallModuleNode {
    id
    name
    slug
    frontendUrl
    routable
    routableExact
    testingMode
    order
    sendEmailWhenCompleted
    moduleLockedCustomText
    submodules {
      totalCount
      edges {
        node {
          ...courseModuleUnion
        }
      }
    }
  }
  ${CourseModuleUnionFragmentDoc}
`;
export const CourseModuleUnionWithSubmodulesFragmentDoc = gql`
  fragment courseModuleUnionWithSubmodules on CourseModuleUnion {
    ... on ChatModuleNode {
      ...chatModuleNodeWithSubmodules
    }
    ... on CourseModuleNode {
      ...courseModuleNodeWithSubmodules
    }
    ... on SurveyModuleNode {
      ...surveyModuleNodeWithSubmodules
    }
    ... on ExternalWebinarModuleNode {
      ...externalWebinarModuleNodeWithSubmodules
    }
    ... on VideoModuleNode {
      ...videoModuleNodeWithSubmodules
    }
    ... on LayoutModuleNode {
      ...layoutModuleNodeWithSubmodules
    }
    ... on DocumentModuleNode {
      ...documentModuleNodeWithSubmodules
    }
    ... on IframeModuleNode {
      ...iframeModuleNodeWithSubmodules
    }
    ... on VideowallModuleNode {
      ...videowallModuleNodeWithSubmodules
    }
  }
  ${ChatModuleNodeWithSubmodulesFragmentDoc}
  ${CourseModuleNodeWithSubmodulesFragmentDoc}
  ${SurveyModuleNodeWithSubmodulesFragmentDoc}
  ${ExternalWebinarModuleNodeWithSubmodulesFragmentDoc}
  ${VideoModuleNodeWithSubmodulesFragmentDoc}
  ${LayoutModuleNodeWithSubmodulesFragmentDoc}
  ${DocumentModuleNodeWithSubmodulesFragmentDoc}
  ${IframeModuleNodeWithSubmodulesFragmentDoc}
  ${VideowallModuleNodeWithSubmodulesFragmentDoc}
`;
export const CourseModuleNodeOverviewFragmentDoc = gql`
  fragment courseModuleNodeOverview on CourseModuleNode {
    id
    name
    slug
    frontendUrl
    order
    routable
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    moduleLockedCustomText
    forceLocked
    availableFrom
    availableUntil
    accessMode
    jsxContent
    assessmentsCounter
  }
`;
export const SurveyModuleNodeOverviewFragmentDoc = gql`
  fragment surveyModuleNodeOverview on SurveyModuleNode {
    id
    name
    order
    slug
    frontendUrl
    routable
    availableFrom
    availableUntil
    forceLocked
    accessMode
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    sendEmailWhenCompleted
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    moduleLockedCustomText
    jsxContent
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${AuthorNodeFragmentDoc}
`;
export const ExternalWebinarModuleNodeOverviewFragmentDoc = gql`
  fragment externalWebinarModuleNodeOverview on ExternalWebinarModuleNode {
    id
    name
    order
    slug
    frontendUrl
    routable
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    availableFrom
    availableUntil
    disableCheckComplete
    forceLocked
    accessMode
    status
    plannedStartDate
    plannedEndDate
    moduleLockedCustomText
    headline
    jsxContent
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${AuthorNodeFragmentDoc}
`;
export const ChatModuleNodeOverviewFragmentDoc = gql`
  fragment chatModuleNodeOverview on ChatModuleNode {
    id
    name
    order
    slug
    frontendUrl
    routable
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    availableFrom
    moduleLockedCustomText
    forceLocked
    accessMode
    jsxContent
    mode
    threadsEnabled
    broadcastChannelEnabled
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${AuthorNodeFragmentDoc}
`;
export const VideoModuleNodeOverviewFragmentDoc = gql`
  fragment videoModuleNodeOverview on VideoModuleNode {
    id
    name
    slug
    frontendUrl
    order
    routable
    availableFrom
    availableUntil
    forceLocked
    accessMode
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    moduleLockedCustomText
    jsxContent
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${AuthorNodeFragmentDoc}
`;
export const LayoutModuleNodeOverviewFragmentDoc = gql`
  fragment layoutModuleNodeOverview on LayoutModuleNode {
    id
    name
    slug
    frontendUrl
    routable
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    availableFrom
    availableUntil
    forceLocked
    accessMode
    order
    moduleLockedCustomText
    jsxContent
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${AuthorNodeFragmentDoc}
`;
export const DocumentModuleNodeOverviewFragmentDoc = gql`
  fragment documentModuleNodeOverview on DocumentModuleNode {
    id
    name
    slug
    frontendUrl
    order
    privacy
    routable
    routableExact
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    testingMode
    document {
      fileName
      url
      extension
    }
    materialIcon
    assessmentsCounter
    availableFrom
    availableUntil
    forceLocked
    accessMode
    moduleLockedCustomText
    jsxContent
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${AuthorNodeFragmentDoc}
`;
export const IframeModuleNodeOverviewFragmentDoc = gql`
  fragment iframeModuleNodeOverview on IframeModuleNode {
    id
    name
    slug
    frontendUrl
    routable
    routableExact
    testingMode
    materialIcon
    assessmentsCounter
    disableAutoComplete
    disableCheckComplete
    completedCustomEmailContent
    completedCustomEmailSubject
    availableFrom
    availableUntil
    forceLocked
    accessMode
    order
    moduleLockedCustomText
    jsxContent
    authors {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${AuthorNodeFragmentDoc}
`;
export const VideowallModuleNodeOverviewFragmentDoc = gql`
  fragment videowallModuleNodeOverview on VideowallModuleNode {
    id
    name
    slug
    frontendUrl
    routable
    routableExact
    testingMode
    materialIcon
    order
    moduleLockedCustomText
    jsxContent
  }
`;
export const CourseModuleUnionOverviewFragmentDoc = gql`
  fragment courseModuleUnionOverview on CourseModuleUnion {
    ... on CourseModuleNode {
      ...courseModuleNodeOverview
    }
    ... on SurveyModuleNode {
      ...surveyModuleNodeOverview
    }
    ... on ExternalWebinarModuleNode {
      ...externalWebinarModuleNodeOverview
    }
    ... on ChatModuleNode {
      ...chatModuleNodeOverview
    }
    ... on VideoModuleNode {
      ...videoModuleNodeOverview
    }
    ... on LayoutModuleNode {
      ...layoutModuleNodeOverview
    }
    ... on DocumentModuleNode {
      ...documentModuleNodeOverview
    }
    ... on IframeModuleNode {
      ...iframeModuleNodeOverview
    }
    ... on VideowallModuleNode {
      ...videowallModuleNodeOverview
    }
  }
  ${CourseModuleNodeOverviewFragmentDoc}
  ${SurveyModuleNodeOverviewFragmentDoc}
  ${ExternalWebinarModuleNodeOverviewFragmentDoc}
  ${ChatModuleNodeOverviewFragmentDoc}
  ${VideoModuleNodeOverviewFragmentDoc}
  ${LayoutModuleNodeOverviewFragmentDoc}
  ${DocumentModuleNodeOverviewFragmentDoc}
  ${IframeModuleNodeOverviewFragmentDoc}
  ${VideowallModuleNodeOverviewFragmentDoc}
`;
export const ReminderNodeFragmentDoc = gql`
  fragment reminderNode on ReminderNode {
    id
    task
    name
    kwargs
    result {
      edges {
        node {
          status
          dateDone
          getStatusDisplay
          taskKwargs
        }
      }
    }
    enabled
    clocked {
      clockedTime
      id
    }
  }
`;
export const CourseModuleUnionRemindersFragmentDoc = gql`
  fragment courseModuleUnionReminders on CourseModuleUnion {
    ... on CourseModuleNode {
      reminders {
        edges {
          node {
            ...reminderNode
          }
        }
      }
    }
    ... on SurveyModuleNode {
      reminders {
        edges {
          node {
            ...reminderNode
          }
        }
      }
    }
    ... on ExternalWebinarModuleNode {
      reminders {
        edges {
          node {
            ...reminderNode
          }
        }
      }
    }
    ... on ChatModuleNode {
      reminders {
        edges {
          node {
            ...reminderNode
          }
        }
      }
    }
    ... on VideoModuleNode {
      reminders {
        edges {
          node {
            ...reminderNode
          }
        }
      }
    }
    ... on LayoutModuleNode {
      reminders {
        edges {
          node {
            ...reminderNode
          }
        }
      }
    }
    ... on DocumentModuleNode {
      reminders {
        edges {
          node {
            ...reminderNode
          }
        }
      }
    }
    ... on IframeModuleNode {
      reminders {
        edges {
          node {
            ...reminderNode
          }
        }
      }
    }
    ... on VideowallModuleNode {
      reminders {
        edges {
          node {
            ...reminderNode
          }
        }
      }
    }
  }
  ${ReminderNodeFragmentDoc}
`;
export const CourseModuleUnionOverviewWithPartecipationDataFragmentDoc = gql`
  fragment courseModuleUnionOverviewWithPartecipationData on CourseModuleUnion {
    ... on CourseModuleNode {
      ...courseModuleNodeOverview
      userModuleAssessment {
        ...courseModuleAssessmentUnion
      }
    }
    ... on SurveyModuleNode {
      ...surveyModuleNodeOverview
      userModuleAssessment {
        ...courseModuleAssessmentUnion
      }
    }
    ... on ExternalWebinarModuleNode {
      ...externalWebinarModuleNodeOverview
      userModuleAssessment {
        ...courseModuleAssessmentUnion
      }
    }
    ... on ChatModuleNode {
      ...chatModuleNodeOverview
      userModuleAssessment {
        ...courseModuleAssessmentUnion
      }
    }
    ... on VideoModuleNode {
      ...videoModuleNodeOverview
      userModuleAssessment {
        ...courseModuleAssessmentUnion
        ...courseModuleAssessmentUnion
      }
    }
    ... on LayoutModuleNode {
      ...layoutModuleNodeOverview
      userModuleAssessment {
        ...courseModuleAssessmentUnion
      }
    }
    ... on DocumentModuleNode {
      ...documentModuleNodeOverview
      userModuleAssessment {
        ...courseModuleAssessmentUnion
      }
    }
    ... on IframeModuleNode {
      ...iframeModuleNodeOverview
      userModuleAssessment {
        ...courseModuleAssessmentUnion
      }
    }
    ... on VideoModuleNode {
      ...videoModuleNodeOverview
      userModuleAssessment {
        ...courseModuleAssessmentUnion
      }
    }
  }
  ${CourseModuleNodeOverviewFragmentDoc}
  ${CourseModuleAssessmentUnionFragmentDoc}
  ${SurveyModuleNodeOverviewFragmentDoc}
  ${ExternalWebinarModuleNodeOverviewFragmentDoc}
  ${ChatModuleNodeOverviewFragmentDoc}
  ${VideoModuleNodeOverviewFragmentDoc}
  ${LayoutModuleNodeOverviewFragmentDoc}
  ${DocumentModuleNodeOverviewFragmentDoc}
  ${IframeModuleNodeOverviewFragmentDoc}
`;
export const CourseModuleUnionAuthorsFragmentDoc = gql`
  fragment courseModuleUnionAuthors on CourseModuleUnion {
    ... on CourseModuleNode {
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
    }
    ... on SurveyModuleNode {
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
    }
    ... on ExternalWebinarModuleNode {
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
    }
    ... on ChatModuleNode {
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
    }
    ... on VideoModuleNode {
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
    }
    ... on LayoutModuleNode {
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
    }
    ... on DocumentModuleNode {
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
    }
    ... on IframeModuleNode {
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
    }
    ... on VideowallModuleNode {
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
    }
  }
  ${AuthorNodeFragmentDoc}
`;
export const CourseModuleAssessmentChatNodeFragmentDoc = gql`
  fragment courseModuleAssessmentChatNode on CourseModuleAssessmentExternalWebinarNode {
    id
    status
  }
`;
export const PermissionFragmentDoc = gql`
  fragment permission on PermissionNode {
    id
    name
    codename
  }
`;
export const UserForPermissionFragmentDoc = gql`
  fragment userForPermission on UserNode {
    id
    displayName
    avatar
    email
  }
`;
export const UserPermissionFragmentDoc = gql`
  fragment userPermission on UserPermissions {
    permissions
    user {
      ...userForPermission
    }
  }
  ${UserForPermissionFragmentDoc}
`;
export const GroupDataForPermissionsFragmentDoc = gql`
  fragment groupDataForPermissions on GroupNode {
    id
    name
    label
    userSet {
      edges {
        node {
          ...userForPermission
        }
      }
    }
  }
  ${UserForPermissionFragmentDoc}
`;
export const GroupPermissionsFragmentDoc = gql`
  fragment groupPermissions on GroupPermissions {
    permissions
    group {
      ...groupDataForPermissions
    }
  }
  ${GroupDataForPermissionsFragmentDoc}
`;
export const QuestionUnionIdFragmentFragmentDoc = gql`
  fragment QuestionUnionIdFragment on QuestionUnion {
    ... on QuestionNode {
      id
      slug
      type
    }
  }
`;
export const QuestionnaireUnionFragmentFragmentDoc = gql`
  fragment QuestionnaireUnionFragment on SurveyUnion {
    ... on SurveyNode {
      id
      name
      description
      isPublished
      questions {
        totalCount
        edges {
          node {
            ...QuestionUnionFragment
          }
        }
      }
    }
  }
  ${QuestionUnionFragmentFragmentDoc}
`;
export const ThemeFragmentDoc = gql`
  fragment theme on ThemeNode {
    id
    name
    configuration
    enableInDashboard
    cover {
      thumbnail(height: 400) {
        url
      }
    }
    navBarLogo: logoOnLight {
      name
      thumbnail(height: 45, format: PNG) {
        url
      }
    }
    primaryLogo: logoOnLight {
      name
      thumbnail(height: 45, format: PNG) {
        url
      }
    }
    secondaryLogo: logoOnDark {
      name
      thumbnail(height: 45, format: PNG) {
        url
      }
    }
    avatarLogo: logoSquare {
      name
      thumbnail(height: 50, width: 50, format: PNG) {
        url
      }
    }
    logoSquare {
      name
      thumbnail(height: 50, width: 50, format: PNG) {
        url
      }
    }
    footerLogo: logoOnDark {
      name
      thumbnail(height: 45, format: PNG) {
        url
      }
    }
    sideBarLogo: logoOnLight {
      name
      thumbnail(height: 300, format: PNG) {
        url
      }
    }
    logoOnDark {
      name
      thumbnail(width: 200, format: PNG) {
        url
      }
    }
    logoOnLight {
      name
      thumbnail(width: 200, format: PNG) {
        url
      }
    }
  }
`;
export const AutocompleteCompaniesDocument = gql`
  query autocompleteCompanies($q: String!) {
    autocomplete {
      companies(name_Icontains: $q) {
        edges {
          node {
            name
            id
          }
        }
      }
    }
  }
`;

/**
 * __useAutocompleteCompaniesQuery__
 *
 * To run a query within a React component, call `useAutocompleteCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteCompaniesQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useAutocompleteCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AutocompleteCompaniesQuery,
    AutocompleteCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AutocompleteCompaniesQuery,
    AutocompleteCompaniesQueryVariables
  >(AutocompleteCompaniesDocument, options);
}
export function useAutocompleteCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutocompleteCompaniesQuery,
    AutocompleteCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AutocompleteCompaniesQuery,
    AutocompleteCompaniesQueryVariables
  >(AutocompleteCompaniesDocument, options);
}
export type AutocompleteCompaniesQueryHookResult = ReturnType<
  typeof useAutocompleteCompaniesQuery
>;
export type AutocompleteCompaniesLazyQueryHookResult = ReturnType<
  typeof useAutocompleteCompaniesLazyQuery
>;
export type AutocompleteCompaniesQueryResult = Apollo.QueryResult<
  AutocompleteCompaniesQuery,
  AutocompleteCompaniesQueryVariables
>;
export const AutocompleteUsersDocument = gql`
  query autocompleteUsers(
    $q: String!
    $anonymousUser: Boolean
    $manageParticipationCourseId: String
  ) {
    autocomplete {
      users(
        search: $q
        anonymousUser: $anonymousUser
        manageParticipationCourseId: $manageParticipationCourseId
      ) {
        edges {
          node {
            displayName
            id
            email
          }
        }
      }
    }
  }
`;

/**
 * __useAutocompleteUsersQuery__
 *
 * To run a query within a React component, call `useAutocompleteUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteUsersQuery({
 *   variables: {
 *      q: // value for 'q'
 *      anonymousUser: // value for 'anonymousUser'
 *      manageParticipationCourseId: // value for 'manageParticipationCourseId'
 *   },
 * });
 */
export function useAutocompleteUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    AutocompleteUsersQuery,
    AutocompleteUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AutocompleteUsersQuery,
    AutocompleteUsersQueryVariables
  >(AutocompleteUsersDocument, options);
}
export function useAutocompleteUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutocompleteUsersQuery,
    AutocompleteUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AutocompleteUsersQuery,
    AutocompleteUsersQueryVariables
  >(AutocompleteUsersDocument, options);
}
export type AutocompleteUsersQueryHookResult = ReturnType<
  typeof useAutocompleteUsersQuery
>;
export type AutocompleteUsersLazyQueryHookResult = ReturnType<
  typeof useAutocompleteUsersLazyQuery
>;
export type AutocompleteUsersQueryResult = Apollo.QueryResult<
  AutocompleteUsersQuery,
  AutocompleteUsersQueryVariables
>;
export const AutocompleteSurveyDocument = gql`
  query autocompleteSurvey($q: String!, $type_In: [SurveyType]) {
    autocomplete {
      surveys(search: $q, type_In: $type_In) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useAutocompleteSurveyQuery__
 *
 * To run a query within a React component, call `useAutocompleteSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteSurveyQuery({
 *   variables: {
 *      q: // value for 'q'
 *      type_In: // value for 'type_In'
 *   },
 * });
 */
export function useAutocompleteSurveyQuery(
  baseOptions: Apollo.QueryHookOptions<
    AutocompleteSurveyQuery,
    AutocompleteSurveyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AutocompleteSurveyQuery,
    AutocompleteSurveyQueryVariables
  >(AutocompleteSurveyDocument, options);
}
export function useAutocompleteSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutocompleteSurveyQuery,
    AutocompleteSurveyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AutocompleteSurveyQuery,
    AutocompleteSurveyQueryVariables
  >(AutocompleteSurveyDocument, options);
}
export type AutocompleteSurveyQueryHookResult = ReturnType<
  typeof useAutocompleteSurveyQuery
>;
export type AutocompleteSurveyLazyQueryHookResult = ReturnType<
  typeof useAutocompleteSurveyLazyQuery
>;
export type AutocompleteSurveyQueryResult = Apollo.QueryResult<
  AutocompleteSurveyQuery,
  AutocompleteSurveyQueryVariables
>;
export const AutocompleteTemplateDocument = gql`
  query autocompleteTemplate($q: String!) {
    autocomplete {
      templates(search: $q) {
        edges {
          node {
            id
            name
            label
          }
        }
      }
    }
  }
`;

/**
 * __useAutocompleteTemplateQuery__
 *
 * To run a query within a React component, call `useAutocompleteTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteTemplateQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useAutocompleteTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    AutocompleteTemplateQuery,
    AutocompleteTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AutocompleteTemplateQuery,
    AutocompleteTemplateQueryVariables
  >(AutocompleteTemplateDocument, options);
}
export function useAutocompleteTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutocompleteTemplateQuery,
    AutocompleteTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AutocompleteTemplateQuery,
    AutocompleteTemplateQueryVariables
  >(AutocompleteTemplateDocument, options);
}
export type AutocompleteTemplateQueryHookResult = ReturnType<
  typeof useAutocompleteTemplateQuery
>;
export type AutocompleteTemplateLazyQueryHookResult = ReturnType<
  typeof useAutocompleteTemplateLazyQuery
>;
export type AutocompleteTemplateQueryResult = Apollo.QueryResult<
  AutocompleteTemplateQuery,
  AutocompleteTemplateQueryVariables
>;
export const AutocompleteLiveMeetingsDocument = gql`
  query autocompleteLiveMeetings($q: String!) {
    autocomplete {
      liveMeetings(search: $q) {
        edges {
          node {
            id
            meetingNo
            meetingPwd
            topic
            startTime
          }
        }
      }
    }
  }
`;

/**
 * __useAutocompleteLiveMeetingsQuery__
 *
 * To run a query within a React component, call `useAutocompleteLiveMeetingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteLiveMeetingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteLiveMeetingsQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useAutocompleteLiveMeetingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AutocompleteLiveMeetingsQuery,
    AutocompleteLiveMeetingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AutocompleteLiveMeetingsQuery,
    AutocompleteLiveMeetingsQueryVariables
  >(AutocompleteLiveMeetingsDocument, options);
}
export function useAutocompleteLiveMeetingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutocompleteLiveMeetingsQuery,
    AutocompleteLiveMeetingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AutocompleteLiveMeetingsQuery,
    AutocompleteLiveMeetingsQueryVariables
  >(AutocompleteLiveMeetingsDocument, options);
}
export type AutocompleteLiveMeetingsQueryHookResult = ReturnType<
  typeof useAutocompleteLiveMeetingsQuery
>;
export type AutocompleteLiveMeetingsLazyQueryHookResult = ReturnType<
  typeof useAutocompleteLiveMeetingsLazyQuery
>;
export type AutocompleteLiveMeetingsQueryResult = Apollo.QueryResult<
  AutocompleteLiveMeetingsQuery,
  AutocompleteLiveMeetingsQueryVariables
>;
export const TypeEnumValuesDocument = gql`
  query typeEnumValues($enumType: String!) {
    __type(name: $enumType) {
      name
      enumValues {
        name
        description
      }
    }
  }
`;

/**
 * __useTypeEnumValuesQuery__
 *
 * To run a query within a React component, call `useTypeEnumValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTypeEnumValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTypeEnumValuesQuery({
 *   variables: {
 *      enumType: // value for 'enumType'
 *   },
 * });
 */
export function useTypeEnumValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TypeEnumValuesQuery,
    TypeEnumValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TypeEnumValuesQuery, TypeEnumValuesQueryVariables>(
    TypeEnumValuesDocument,
    options,
  );
}
export function useTypeEnumValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TypeEnumValuesQuery,
    TypeEnumValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TypeEnumValuesQuery, TypeEnumValuesQueryVariables>(
    TypeEnumValuesDocument,
    options,
  );
}
export type TypeEnumValuesQueryHookResult = ReturnType<
  typeof useTypeEnumValuesQuery
>;
export type TypeEnumValuesLazyQueryHookResult = ReturnType<
  typeof useTypeEnumValuesLazyQuery
>;
export type TypeEnumValuesQueryResult = Apollo.QueryResult<
  TypeEnumValuesQuery,
  TypeEnumValuesQueryVariables
>;
export const CourseModulesDocument = gql`
  query courseModules($courseId: ID!) {
    courseModules(course: $courseId) {
      edges {
        node {
          id
          name
          slug
          order
          routable
          routableExact
          privacy
          parent {
            id
          }
          requiredModule {
            id
          }
          externalwebinarmodule {
            id
          }
          iframemodule {
            id
          }
          surveymodule {
            id
          }
          videomodule {
            id
          }
          layoutmodule {
            id
          }
          documentmodule {
            id
          }
          chatmodule {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useCourseModulesQuery__
 *
 * To run a query within a React component, call `useCourseModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseModulesQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCourseModulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseModulesQuery,
    CourseModulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CourseModulesQuery, CourseModulesQueryVariables>(
    CourseModulesDocument,
    options,
  );
}
export function useCourseModulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseModulesQuery,
    CourseModulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CourseModulesQuery, CourseModulesQueryVariables>(
    CourseModulesDocument,
    options,
  );
}
export type CourseModulesQueryHookResult = ReturnType<
  typeof useCourseModulesQuery
>;
export type CourseModulesLazyQueryHookResult = ReturnType<
  typeof useCourseModulesLazyQuery
>;
export type CourseModulesQueryResult = Apollo.QueryResult<
  CourseModulesQuery,
  CourseModulesQueryVariables
>;
export const CourseModuleUpdateParentDocument = gql`
  mutation courseModuleUpdateParent($input: CourseModuleUpdateMutationInput!) {
    courseModuleUpdate(input: $input) {
      courseModule {
        id
        parent {
          id
        }
      }
    }
  }
`;
export type CourseModuleUpdateParentMutationFn = Apollo.MutationFunction<
  CourseModuleUpdateParentMutation,
  CourseModuleUpdateParentMutationVariables
>;

/**
 * __useCourseModuleUpdateParentMutation__
 *
 * To run a mutation, you first call `useCourseModuleUpdateParentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseModuleUpdateParentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseModuleUpdateParentMutation, { data, loading, error }] = useCourseModuleUpdateParentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseModuleUpdateParentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseModuleUpdateParentMutation,
    CourseModuleUpdateParentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseModuleUpdateParentMutation,
    CourseModuleUpdateParentMutationVariables
  >(CourseModuleUpdateParentDocument, options);
}
export type CourseModuleUpdateParentMutationHookResult = ReturnType<
  typeof useCourseModuleUpdateParentMutation
>;
export type CourseModuleUpdateParentMutationResult =
  Apollo.MutationResult<CourseModuleUpdateParentMutation>;
export type CourseModuleUpdateParentMutationOptions =
  Apollo.BaseMutationOptions<
    CourseModuleUpdateParentMutation,
    CourseModuleUpdateParentMutationVariables
  >;
export const CourseModuleDeleteDocument = gql`
  mutation courseModuleDelete($input: CourseModuleDeleteMutationInput!) {
    courseModuleDelete(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;
export type CourseModuleDeleteMutationFn = Apollo.MutationFunction<
  CourseModuleDeleteMutation,
  CourseModuleDeleteMutationVariables
>;

/**
 * __useCourseModuleDeleteMutation__
 *
 * To run a mutation, you first call `useCourseModuleDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseModuleDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseModuleDeleteMutation, { data, loading, error }] = useCourseModuleDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseModuleDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseModuleDeleteMutation,
    CourseModuleDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseModuleDeleteMutation,
    CourseModuleDeleteMutationVariables
  >(CourseModuleDeleteDocument, options);
}
export type CourseModuleDeleteMutationHookResult = ReturnType<
  typeof useCourseModuleDeleteMutation
>;
export type CourseModuleDeleteMutationResult =
  Apollo.MutationResult<CourseModuleDeleteMutation>;
export type CourseModuleDeleteMutationOptions = Apollo.BaseMutationOptions<
  CourseModuleDeleteMutation,
  CourseModuleDeleteMutationVariables
>;
export const ProfileDocument = gql`
  query Profile {
    me {
      id
      avatar300: avatar(size: 300)
      displayName
      email
      isStaff
      companySet {
        totalCount
      }
      companyuserinvitationSet {
        totalCount
      }
    }
  }
`;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(
    ProfileDocument,
    options,
  );
}
export function useProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfileQuery,
    ProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(
    ProfileDocument,
    options,
  );
}
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = Apollo.QueryResult<
  ProfileQuery,
  ProfileQueryVariables
>;
export const MyInvitationsFromCompaniesDocument = gql`
  query myInvitationsFromCompanies {
    myInvitationsFromCompanies {
      totalCount
      edges {
        node {
          ...companyUserInvitationFragment
        }
      }
    }
  }
  ${CompanyUserInvitationFragmentFragmentDoc}
`;

/**
 * __useMyInvitationsFromCompaniesQuery__
 *
 * To run a query within a React component, call `useMyInvitationsFromCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyInvitationsFromCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyInvitationsFromCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyInvitationsFromCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyInvitationsFromCompaniesQuery,
    MyInvitationsFromCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MyInvitationsFromCompaniesQuery,
    MyInvitationsFromCompaniesQueryVariables
  >(MyInvitationsFromCompaniesDocument, options);
}
export function useMyInvitationsFromCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyInvitationsFromCompaniesQuery,
    MyInvitationsFromCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyInvitationsFromCompaniesQuery,
    MyInvitationsFromCompaniesQueryVariables
  >(MyInvitationsFromCompaniesDocument, options);
}
export type MyInvitationsFromCompaniesQueryHookResult = ReturnType<
  typeof useMyInvitationsFromCompaniesQuery
>;
export type MyInvitationsFromCompaniesLazyQueryHookResult = ReturnType<
  typeof useMyInvitationsFromCompaniesLazyQuery
>;
export type MyInvitationsFromCompaniesQueryResult = Apollo.QueryResult<
  MyInvitationsFromCompaniesQuery,
  MyInvitationsFromCompaniesQueryVariables
>;
export const CompanyUserInvitationFromTokenDocument = gql`
  query companyUserInvitationFromToken($token: String!) {
    companyUserInvitationFromToken(token: $token) {
      id
      email
      company {
        id
        name
        baseLogo {
          name
          url
          thumb160: thumbnail(
            width: 160
            height: 160
            padding: true
            format: PNG
          ) {
            url
          }
        }
      }
      status
      sentAt
      repliedAt
      revokedAt
      expiredAt
    }
  }
`;

/**
 * __useCompanyUserInvitationFromTokenQuery__
 *
 * To run a query within a React component, call `useCompanyUserInvitationFromTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyUserInvitationFromTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyUserInvitationFromTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCompanyUserInvitationFromTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyUserInvitationFromTokenQuery,
    CompanyUserInvitationFromTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyUserInvitationFromTokenQuery,
    CompanyUserInvitationFromTokenQueryVariables
  >(CompanyUserInvitationFromTokenDocument, options);
}
export function useCompanyUserInvitationFromTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyUserInvitationFromTokenQuery,
    CompanyUserInvitationFromTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyUserInvitationFromTokenQuery,
    CompanyUserInvitationFromTokenQueryVariables
  >(CompanyUserInvitationFromTokenDocument, options);
}
export type CompanyUserInvitationFromTokenQueryHookResult = ReturnType<
  typeof useCompanyUserInvitationFromTokenQuery
>;
export type CompanyUserInvitationFromTokenLazyQueryHookResult = ReturnType<
  typeof useCompanyUserInvitationFromTokenLazyQuery
>;
export type CompanyUserInvitationFromTokenQueryResult = Apollo.QueryResult<
  CompanyUserInvitationFromTokenQuery,
  CompanyUserInvitationFromTokenQueryVariables
>;
export const MeStaffDocument = gql`
  query MeStaff {
    me {
      id
      avatar300: avatar(size: 300)
      displayName
      email
    }
  }
`;

/**
 * __useMeStaffQuery__
 *
 * To run a query within a React component, call `useMeStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeStaffQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeStaffQuery(
  baseOptions?: Apollo.QueryHookOptions<MeStaffQuery, MeStaffQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeStaffQuery, MeStaffQueryVariables>(
    MeStaffDocument,
    options,
  );
}
export function useMeStaffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MeStaffQuery,
    MeStaffQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeStaffQuery, MeStaffQueryVariables>(
    MeStaffDocument,
    options,
  );
}
export type MeStaffQueryHookResult = ReturnType<typeof useMeStaffQuery>;
export type MeStaffLazyQueryHookResult = ReturnType<typeof useMeStaffLazyQuery>;
export type MeStaffQueryResult = Apollo.QueryResult<
  MeStaffQuery,
  MeStaffQueryVariables
>;
export const AllUsersDocument = gql`
  query allUsers {
    allUsers {
      edges {
        node {
          id
          email
          displayName
        }
      }
    }
  }
`;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(
    AllUsersDocument,
    options,
  );
}
export function useAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllUsersQuery,
    AllUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(
    AllUsersDocument,
    options,
  );
}
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<
  typeof useAllUsersLazyQuery
>;
export type AllUsersQueryResult = Apollo.QueryResult<
  AllUsersQuery,
  AllUsersQueryVariables
>;
export const SendChannelMessageDocument = gql`
  mutation sendChannelMessage($input: SendChannelMessageInput!) {
    sendChannelMessage(input: $input) {
      status
    }
  }
`;
export type SendChannelMessageMutationFn = Apollo.MutationFunction<
  SendChannelMessageMutation,
  SendChannelMessageMutationVariables
>;

/**
 * __useSendChannelMessageMutation__
 *
 * To run a mutation, you first call `useSendChannelMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChannelMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChannelMessageMutation, { data, loading, error }] = useSendChannelMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendChannelMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendChannelMessageMutation,
    SendChannelMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendChannelMessageMutation,
    SendChannelMessageMutationVariables
  >(SendChannelMessageDocument, options);
}
export type SendChannelMessageMutationHookResult = ReturnType<
  typeof useSendChannelMessageMutation
>;
export type SendChannelMessageMutationResult =
  Apollo.MutationResult<SendChannelMessageMutation>;
export type SendChannelMessageMutationOptions = Apollo.BaseMutationOptions<
  SendChannelMessageMutation,
  SendChannelMessageMutationVariables
>;
export const CompanyUserInvitationAcceptDocument = gql`
  mutation companyUserInvitationAccept(
    $input: CompanyUserInvitationAcceptMutationInput!
  ) {
    companyUserInvitationAccept(input: $input) {
      errors {
        field
        message
      }
      companyUserInvitation {
        ...companyUserInvitationFragment
      }
    }
  }
  ${CompanyUserInvitationFragmentFragmentDoc}
`;
export type CompanyUserInvitationAcceptMutationFn = Apollo.MutationFunction<
  CompanyUserInvitationAcceptMutation,
  CompanyUserInvitationAcceptMutationVariables
>;

/**
 * __useCompanyUserInvitationAcceptMutation__
 *
 * To run a mutation, you first call `useCompanyUserInvitationAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUserInvitationAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUserInvitationAcceptMutation, { data, loading, error }] = useCompanyUserInvitationAcceptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyUserInvitationAcceptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyUserInvitationAcceptMutation,
    CompanyUserInvitationAcceptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyUserInvitationAcceptMutation,
    CompanyUserInvitationAcceptMutationVariables
  >(CompanyUserInvitationAcceptDocument, options);
}
export type CompanyUserInvitationAcceptMutationHookResult = ReturnType<
  typeof useCompanyUserInvitationAcceptMutation
>;
export type CompanyUserInvitationAcceptMutationResult =
  Apollo.MutationResult<CompanyUserInvitationAcceptMutation>;
export type CompanyUserInvitationAcceptMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyUserInvitationAcceptMutation,
    CompanyUserInvitationAcceptMutationVariables
  >;
export const CompanyUserInvitationDeclineDocument = gql`
  mutation companyUserInvitationDecline(
    $input: CompanyUserInvitationDeclineMutationInput!
  ) {
    companyUserInvitationDecline(input: $input) {
      errors {
        field
        message
      }
      companyUserInvitation {
        ...companyUserInvitationFragment
      }
    }
  }
  ${CompanyUserInvitationFragmentFragmentDoc}
`;
export type CompanyUserInvitationDeclineMutationFn = Apollo.MutationFunction<
  CompanyUserInvitationDeclineMutation,
  CompanyUserInvitationDeclineMutationVariables
>;

/**
 * __useCompanyUserInvitationDeclineMutation__
 *
 * To run a mutation, you first call `useCompanyUserInvitationDeclineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUserInvitationDeclineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUserInvitationDeclineMutation, { data, loading, error }] = useCompanyUserInvitationDeclineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyUserInvitationDeclineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyUserInvitationDeclineMutation,
    CompanyUserInvitationDeclineMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyUserInvitationDeclineMutation,
    CompanyUserInvitationDeclineMutationVariables
  >(CompanyUserInvitationDeclineDocument, options);
}
export type CompanyUserInvitationDeclineMutationHookResult = ReturnType<
  typeof useCompanyUserInvitationDeclineMutation
>;
export type CompanyUserInvitationDeclineMutationResult =
  Apollo.MutationResult<CompanyUserInvitationDeclineMutation>;
export type CompanyUserInvitationDeclineMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyUserInvitationDeclineMutation,
    CompanyUserInvitationDeclineMutationVariables
  >;
export const CompanyUserInvitationRevokeDocument = gql`
  mutation companyUserInvitationRevoke(
    $input: CompanyUserInvitationRevokeMutationInput!
  ) {
    companyUserInvitationRevoke(input: $input) {
      errors {
        field
        message
      }
      companyUserInvitation {
        ...companyUserInvitationFragment
      }
    }
  }
  ${CompanyUserInvitationFragmentFragmentDoc}
`;
export type CompanyUserInvitationRevokeMutationFn = Apollo.MutationFunction<
  CompanyUserInvitationRevokeMutation,
  CompanyUserInvitationRevokeMutationVariables
>;

/**
 * __useCompanyUserInvitationRevokeMutation__
 *
 * To run a mutation, you first call `useCompanyUserInvitationRevokeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUserInvitationRevokeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUserInvitationRevokeMutation, { data, loading, error }] = useCompanyUserInvitationRevokeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyUserInvitationRevokeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyUserInvitationRevokeMutation,
    CompanyUserInvitationRevokeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyUserInvitationRevokeMutation,
    CompanyUserInvitationRevokeMutationVariables
  >(CompanyUserInvitationRevokeDocument, options);
}
export type CompanyUserInvitationRevokeMutationHookResult = ReturnType<
  typeof useCompanyUserInvitationRevokeMutation
>;
export type CompanyUserInvitationRevokeMutationResult =
  Apollo.MutationResult<CompanyUserInvitationRevokeMutation>;
export type CompanyUserInvitationRevokeMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyUserInvitationRevokeMutation,
    CompanyUserInvitationRevokeMutationVariables
  >;
export const CompanyUserInvitationConnectUserDocument = gql`
  mutation companyUserInvitationConnectUser(
    $input: CompanyUserInvitationConnectMutationInput!
  ) {
    companyUserInvitationConnectUser(input: $input) {
      errors {
        field
        message
      }
      companyUserInvitation {
        id
        token
        email
        firstName
        lastName
        company {
          id
          name
          baseLogo {
            name
            url
            thumb160: thumbnail(
              width: 160
              height: 160
              padding: true
              format: PNG
            ) {
              url
            }
          }
        }
        status
        sentAt
        repliedAt
        revokedAt
        expiredAt
      }
    }
  }
`;
export type CompanyUserInvitationConnectUserMutationFn =
  Apollo.MutationFunction<
    CompanyUserInvitationConnectUserMutation,
    CompanyUserInvitationConnectUserMutationVariables
  >;

/**
 * __useCompanyUserInvitationConnectUserMutation__
 *
 * To run a mutation, you first call `useCompanyUserInvitationConnectUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUserInvitationConnectUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUserInvitationConnectUserMutation, { data, loading, error }] = useCompanyUserInvitationConnectUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyUserInvitationConnectUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyUserInvitationConnectUserMutation,
    CompanyUserInvitationConnectUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyUserInvitationConnectUserMutation,
    CompanyUserInvitationConnectUserMutationVariables
  >(CompanyUserInvitationConnectUserDocument, options);
}
export type CompanyUserInvitationConnectUserMutationHookResult = ReturnType<
  typeof useCompanyUserInvitationConnectUserMutation
>;
export type CompanyUserInvitationConnectUserMutationResult =
  Apollo.MutationResult<CompanyUserInvitationConnectUserMutation>;
export type CompanyUserInvitationConnectUserMutationOptions =
  Apollo.BaseMutationOptions<
    CompanyUserInvitationConnectUserMutation,
    CompanyUserInvitationConnectUserMutationVariables
  >;
export const AuthorUpdateDocument = gql`
  mutation authorUpdate($input: AuthorUpdateMutationInput!) {
    authorUpdate(input: $input) {
      author {
        ...authorFields
      }
    }
  }
  ${AuthorFieldsFragmentDoc}
`;
export type AuthorUpdateMutationFn = Apollo.MutationFunction<
  AuthorUpdateMutation,
  AuthorUpdateMutationVariables
>;

/**
 * __useAuthorUpdateMutation__
 *
 * To run a mutation, you first call `useAuthorUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorUpdateMutation, { data, loading, error }] = useAuthorUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthorUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthorUpdateMutation,
    AuthorUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthorUpdateMutation,
    AuthorUpdateMutationVariables
  >(AuthorUpdateDocument, options);
}
export type AuthorUpdateMutationHookResult = ReturnType<
  typeof useAuthorUpdateMutation
>;
export type AuthorUpdateMutationResult =
  Apollo.MutationResult<AuthorUpdateMutation>;
export type AuthorUpdateMutationOptions = Apollo.BaseMutationOptions<
  AuthorUpdateMutation,
  AuthorUpdateMutationVariables
>;
export const AuthorCreateDocument = gql`
  mutation authorCreate($input: AuthorCreateMutationInput!) {
    authorCreate(input: $input) {
      author {
        ...authorFields
      }
    }
  }
  ${AuthorFieldsFragmentDoc}
`;
export type AuthorCreateMutationFn = Apollo.MutationFunction<
  AuthorCreateMutation,
  AuthorCreateMutationVariables
>;

/**
 * __useAuthorCreateMutation__
 *
 * To run a mutation, you first call `useAuthorCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorCreateMutation, { data, loading, error }] = useAuthorCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthorCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthorCreateMutation,
    AuthorCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthorCreateMutation,
    AuthorCreateMutationVariables
  >(AuthorCreateDocument, options);
}
export type AuthorCreateMutationHookResult = ReturnType<
  typeof useAuthorCreateMutation
>;
export type AuthorCreateMutationResult =
  Apollo.MutationResult<AuthorCreateMutation>;
export type AuthorCreateMutationOptions = Apollo.BaseMutationOptions<
  AuthorCreateMutation,
  AuthorCreateMutationVariables
>;
export const AuthorsGroupCreateDocument = gql`
  mutation authorsGroupCreate($input: AuthorsGroupCreateMutationInput!) {
    authorsGroupCreate(input: $input) {
      authorsGroup {
        id
        name
        description
      }
    }
  }
`;
export type AuthorsGroupCreateMutationFn = Apollo.MutationFunction<
  AuthorsGroupCreateMutation,
  AuthorsGroupCreateMutationVariables
>;

/**
 * __useAuthorsGroupCreateMutation__
 *
 * To run a mutation, you first call `useAuthorsGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorsGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorsGroupCreateMutation, { data, loading, error }] = useAuthorsGroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthorsGroupCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthorsGroupCreateMutation,
    AuthorsGroupCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthorsGroupCreateMutation,
    AuthorsGroupCreateMutationVariables
  >(AuthorsGroupCreateDocument, options);
}
export type AuthorsGroupCreateMutationHookResult = ReturnType<
  typeof useAuthorsGroupCreateMutation
>;
export type AuthorsGroupCreateMutationResult =
  Apollo.MutationResult<AuthorsGroupCreateMutation>;
export type AuthorsGroupCreateMutationOptions = Apollo.BaseMutationOptions<
  AuthorsGroupCreateMutation,
  AuthorsGroupCreateMutationVariables
>;
export const AuthorsGroupUpdateDocument = gql`
  mutation authorsGroupUpdate($input: AuthorsGroupUpdateMutationInput!) {
    authorsGroupUpdate(input: $input) {
      authorsGroup {
        id
        name
        description
      }
    }
  }
`;
export type AuthorsGroupUpdateMutationFn = Apollo.MutationFunction<
  AuthorsGroupUpdateMutation,
  AuthorsGroupUpdateMutationVariables
>;

/**
 * __useAuthorsGroupUpdateMutation__
 *
 * To run a mutation, you first call `useAuthorsGroupUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorsGroupUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorsGroupUpdateMutation, { data, loading, error }] = useAuthorsGroupUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthorsGroupUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthorsGroupUpdateMutation,
    AuthorsGroupUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthorsGroupUpdateMutation,
    AuthorsGroupUpdateMutationVariables
  >(AuthorsGroupUpdateDocument, options);
}
export type AuthorsGroupUpdateMutationHookResult = ReturnType<
  typeof useAuthorsGroupUpdateMutation
>;
export type AuthorsGroupUpdateMutationResult =
  Apollo.MutationResult<AuthorsGroupUpdateMutation>;
export type AuthorsGroupUpdateMutationOptions = Apollo.BaseMutationOptions<
  AuthorsGroupUpdateMutation,
  AuthorsGroupUpdateMutationVariables
>;
export const CertificateTemplateCreateDocument = gql`
  mutation certificateTemplateCreate(
    $input: CertificateTemplateCreateMutationInput!
  ) {
    certificateTemplateCreate(input: $input) {
      certificateTemplate {
        id
        emailBody
        customSubject
        certificateFileName
        file {
          url
          name
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CertificateTemplateCreateMutationFn = Apollo.MutationFunction<
  CertificateTemplateCreateMutation,
  CertificateTemplateCreateMutationVariables
>;

/**
 * __useCertificateTemplateCreateMutation__
 *
 * To run a mutation, you first call `useCertificateTemplateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCertificateTemplateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [certificateTemplateCreateMutation, { data, loading, error }] = useCertificateTemplateCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCertificateTemplateCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CertificateTemplateCreateMutation,
    CertificateTemplateCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CertificateTemplateCreateMutation,
    CertificateTemplateCreateMutationVariables
  >(CertificateTemplateCreateDocument, options);
}
export type CertificateTemplateCreateMutationHookResult = ReturnType<
  typeof useCertificateTemplateCreateMutation
>;
export type CertificateTemplateCreateMutationResult =
  Apollo.MutationResult<CertificateTemplateCreateMutation>;
export type CertificateTemplateCreateMutationOptions =
  Apollo.BaseMutationOptions<
    CertificateTemplateCreateMutation,
    CertificateTemplateCreateMutationVariables
  >;
export const CertificateTemplateUpdateDocument = gql`
  mutation certificateTemplateUpdate(
    $input: CertificateTemplateUpdateMutationInput!
  ) {
    certificateTemplateUpdate(input: $input) {
      certificateTemplate {
        emailBody
        customSubject
        certificateFileName
        id
        file {
          url
          name
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CertificateTemplateUpdateMutationFn = Apollo.MutationFunction<
  CertificateTemplateUpdateMutation,
  CertificateTemplateUpdateMutationVariables
>;

/**
 * __useCertificateTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useCertificateTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCertificateTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [certificateTemplateUpdateMutation, { data, loading, error }] = useCertificateTemplateUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCertificateTemplateUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CertificateTemplateUpdateMutation,
    CertificateTemplateUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CertificateTemplateUpdateMutation,
    CertificateTemplateUpdateMutationVariables
  >(CertificateTemplateUpdateDocument, options);
}
export type CertificateTemplateUpdateMutationHookResult = ReturnType<
  typeof useCertificateTemplateUpdateMutation
>;
export type CertificateTemplateUpdateMutationResult =
  Apollo.MutationResult<CertificateTemplateUpdateMutation>;
export type CertificateTemplateUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    CertificateTemplateUpdateMutation,
    CertificateTemplateUpdateMutationVariables
  >;
export const CourseModuleCertificateMapsCreateDocument = gql`
  mutation courseModuleCertificateMapsCreate(
    $input: CourseModuleCertificateMapsCreateMutationInput!
  ) {
    courseModuleCertificateMapsCreate(input: $input) {
      courseModuleCertificateTemplateMap {
        id
        sendMail
        generationRole
        availableFrom
        availableUntil
        certificateTemplate {
          emailBody
          customSubject
          certificateFileName
          file {
            url
            name
          }
        }
        coursemodule {
          id
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CourseModuleCertificateMapsCreateMutationFn =
  Apollo.MutationFunction<
    CourseModuleCertificateMapsCreateMutation,
    CourseModuleCertificateMapsCreateMutationVariables
  >;

/**
 * __useCourseModuleCertificateMapsCreateMutation__
 *
 * To run a mutation, you first call `useCourseModuleCertificateMapsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseModuleCertificateMapsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseModuleCertificateMapsCreateMutation, { data, loading, error }] = useCourseModuleCertificateMapsCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseModuleCertificateMapsCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseModuleCertificateMapsCreateMutation,
    CourseModuleCertificateMapsCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseModuleCertificateMapsCreateMutation,
    CourseModuleCertificateMapsCreateMutationVariables
  >(CourseModuleCertificateMapsCreateDocument, options);
}
export type CourseModuleCertificateMapsCreateMutationHookResult = ReturnType<
  typeof useCourseModuleCertificateMapsCreateMutation
>;
export type CourseModuleCertificateMapsCreateMutationResult =
  Apollo.MutationResult<CourseModuleCertificateMapsCreateMutation>;
export type CourseModuleCertificateMapsCreateMutationOptions =
  Apollo.BaseMutationOptions<
    CourseModuleCertificateMapsCreateMutation,
    CourseModuleCertificateMapsCreateMutationVariables
  >;
export const CourseModuleCertificateMapsUpdateDocument = gql`
  mutation courseModuleCertificateMapsUpdate(
    $input: CourseModuleCertificateMapsUpdateMutationInput!
  ) {
    courseModuleCertificateMapsUpdate(input: $input) {
      courseModuleCertificateTemplateMap {
        id
        sendMail
        generationRole
        availableFrom
        availableUntil
        certificateTemplate {
          emailBody
          customSubject
          certificateFileName
          file {
            url
            name
          }
        }
        coursemodule {
          id
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CourseModuleCertificateMapsUpdateMutationFn =
  Apollo.MutationFunction<
    CourseModuleCertificateMapsUpdateMutation,
    CourseModuleCertificateMapsUpdateMutationVariables
  >;

/**
 * __useCourseModuleCertificateMapsUpdateMutation__
 *
 * To run a mutation, you first call `useCourseModuleCertificateMapsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseModuleCertificateMapsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseModuleCertificateMapsUpdateMutation, { data, loading, error }] = useCourseModuleCertificateMapsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseModuleCertificateMapsUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseModuleCertificateMapsUpdateMutation,
    CourseModuleCertificateMapsUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseModuleCertificateMapsUpdateMutation,
    CourseModuleCertificateMapsUpdateMutationVariables
  >(CourseModuleCertificateMapsUpdateDocument, options);
}
export type CourseModuleCertificateMapsUpdateMutationHookResult = ReturnType<
  typeof useCourseModuleCertificateMapsUpdateMutation
>;
export type CourseModuleCertificateMapsUpdateMutationResult =
  Apollo.MutationResult<CourseModuleCertificateMapsUpdateMutation>;
export type CourseModuleCertificateMapsUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    CourseModuleCertificateMapsUpdateMutation,
    CourseModuleCertificateMapsUpdateMutationVariables
  >;
export const CourseModuleCertificateMapsDeleteDocument = gql`
  mutation courseModuleCertificateMapsDelete(
    $input: CourseModuleCertificateMapsDeleteMutationInput!
  ) {
    courseModuleCertificateMapsDelete(input: $input) {
      courseModuleCertificateTemplateMap {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CourseModuleCertificateMapsDeleteMutationFn =
  Apollo.MutationFunction<
    CourseModuleCertificateMapsDeleteMutation,
    CourseModuleCertificateMapsDeleteMutationVariables
  >;

/**
 * __useCourseModuleCertificateMapsDeleteMutation__
 *
 * To run a mutation, you first call `useCourseModuleCertificateMapsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseModuleCertificateMapsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseModuleCertificateMapsDeleteMutation, { data, loading, error }] = useCourseModuleCertificateMapsDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseModuleCertificateMapsDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseModuleCertificateMapsDeleteMutation,
    CourseModuleCertificateMapsDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseModuleCertificateMapsDeleteMutation,
    CourseModuleCertificateMapsDeleteMutationVariables
  >(CourseModuleCertificateMapsDeleteDocument, options);
}
export type CourseModuleCertificateMapsDeleteMutationHookResult = ReturnType<
  typeof useCourseModuleCertificateMapsDeleteMutation
>;
export type CourseModuleCertificateMapsDeleteMutationResult =
  Apollo.MutationResult<CourseModuleCertificateMapsDeleteMutation>;
export type CourseModuleCertificateMapsDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    CourseModuleCertificateMapsDeleteMutation,
    CourseModuleCertificateMapsDeleteMutationVariables
  >;
export const CourseCertificateMapsCreateDocument = gql`
  mutation courseCertificateMapsCreate(
    $input: CourseCertificateMapsCreateMutationInput!
  ) {
    courseCertificateMapsCreate(input: $input) {
      courseCertificateTemplateMap {
        id
        sendMail
        generationRole
        availableFrom
        availableUntil
        certificateTemplate {
          emailBody
          customSubject
          certificateFileName
          file {
            url
            name
          }
        }
        course {
          id
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CourseCertificateMapsCreateMutationFn = Apollo.MutationFunction<
  CourseCertificateMapsCreateMutation,
  CourseCertificateMapsCreateMutationVariables
>;

/**
 * __useCourseCertificateMapsCreateMutation__
 *
 * To run a mutation, you first call `useCourseCertificateMapsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseCertificateMapsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseCertificateMapsCreateMutation, { data, loading, error }] = useCourseCertificateMapsCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseCertificateMapsCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseCertificateMapsCreateMutation,
    CourseCertificateMapsCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseCertificateMapsCreateMutation,
    CourseCertificateMapsCreateMutationVariables
  >(CourseCertificateMapsCreateDocument, options);
}
export type CourseCertificateMapsCreateMutationHookResult = ReturnType<
  typeof useCourseCertificateMapsCreateMutation
>;
export type CourseCertificateMapsCreateMutationResult =
  Apollo.MutationResult<CourseCertificateMapsCreateMutation>;
export type CourseCertificateMapsCreateMutationOptions =
  Apollo.BaseMutationOptions<
    CourseCertificateMapsCreateMutation,
    CourseCertificateMapsCreateMutationVariables
  >;
export const CourseCertificateMapsUpdateDocument = gql`
  mutation courseCertificateMapsUpdate(
    $input: CourseCertificateMapsUpdateMutationInput!
  ) {
    courseCertificateMapsUpdate(input: $input) {
      courseCertificateTemplateMap {
        id
        sendMail
        generationRole
        availableFrom
        availableUntil
        certificateTemplate {
          emailBody
          customSubject
          certificateFileName
          file {
            url
            name
          }
        }
        course {
          id
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CourseCertificateMapsUpdateMutationFn = Apollo.MutationFunction<
  CourseCertificateMapsUpdateMutation,
  CourseCertificateMapsUpdateMutationVariables
>;

/**
 * __useCourseCertificateMapsUpdateMutation__
 *
 * To run a mutation, you first call `useCourseCertificateMapsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseCertificateMapsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseCertificateMapsUpdateMutation, { data, loading, error }] = useCourseCertificateMapsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseCertificateMapsUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseCertificateMapsUpdateMutation,
    CourseCertificateMapsUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseCertificateMapsUpdateMutation,
    CourseCertificateMapsUpdateMutationVariables
  >(CourseCertificateMapsUpdateDocument, options);
}
export type CourseCertificateMapsUpdateMutationHookResult = ReturnType<
  typeof useCourseCertificateMapsUpdateMutation
>;
export type CourseCertificateMapsUpdateMutationResult =
  Apollo.MutationResult<CourseCertificateMapsUpdateMutation>;
export type CourseCertificateMapsUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    CourseCertificateMapsUpdateMutation,
    CourseCertificateMapsUpdateMutationVariables
  >;
export const CourseCertificateMapsDeleteDocument = gql`
  mutation courseCertificateMapsDelete(
    $input: CourseCertificateMapsDeleteMutationInput!
  ) {
    courseCertificateMapsDelete(input: $input) {
      courseCertificateTemplateMap {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CourseCertificateMapsDeleteMutationFn = Apollo.MutationFunction<
  CourseCertificateMapsDeleteMutation,
  CourseCertificateMapsDeleteMutationVariables
>;

/**
 * __useCourseCertificateMapsDeleteMutation__
 *
 * To run a mutation, you first call `useCourseCertificateMapsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseCertificateMapsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseCertificateMapsDeleteMutation, { data, loading, error }] = useCourseCertificateMapsDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseCertificateMapsDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseCertificateMapsDeleteMutation,
    CourseCertificateMapsDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseCertificateMapsDeleteMutation,
    CourseCertificateMapsDeleteMutationVariables
  >(CourseCertificateMapsDeleteDocument, options);
}
export type CourseCertificateMapsDeleteMutationHookResult = ReturnType<
  typeof useCourseCertificateMapsDeleteMutation
>;
export type CourseCertificateMapsDeleteMutationResult =
  Apollo.MutationResult<CourseCertificateMapsDeleteMutation>;
export type CourseCertificateMapsDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    CourseCertificateMapsDeleteMutation,
    CourseCertificateMapsDeleteMutationVariables
  >;
export const CertificateEmailTestDocument = gql`
  mutation certificateEmailTest($input: CertificateEmailTestMutationInput!) {
    certificateEmailTest(input: $input) {
      status
    }
  }
`;
export type CertificateEmailTestMutationFn = Apollo.MutationFunction<
  CertificateEmailTestMutation,
  CertificateEmailTestMutationVariables
>;

/**
 * __useCertificateEmailTestMutation__
 *
 * To run a mutation, you first call `useCertificateEmailTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCertificateEmailTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [certificateEmailTestMutation, { data, loading, error }] = useCertificateEmailTestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCertificateEmailTestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CertificateEmailTestMutation,
    CertificateEmailTestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CertificateEmailTestMutation,
    CertificateEmailTestMutationVariables
  >(CertificateEmailTestDocument, options);
}
export type CertificateEmailTestMutationHookResult = ReturnType<
  typeof useCertificateEmailTestMutation
>;
export type CertificateEmailTestMutationResult =
  Apollo.MutationResult<CertificateEmailTestMutation>;
export type CertificateEmailTestMutationOptions = Apollo.BaseMutationOptions<
  CertificateEmailTestMutation,
  CertificateEmailTestMutationVariables
>;
export const CourseModuleUpdateJsxContentDocument = gql`
  mutation courseModuleUpdateJsxContent(
    $input: CourseModuleUpdateMutationInput!
  ) {
    courseModuleUpdate(input: $input) {
      courseModule {
        id
        jsxContent
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CourseModuleUpdateJsxContentMutationFn = Apollo.MutationFunction<
  CourseModuleUpdateJsxContentMutation,
  CourseModuleUpdateJsxContentMutationVariables
>;

/**
 * __useCourseModuleUpdateJsxContentMutation__
 *
 * To run a mutation, you first call `useCourseModuleUpdateJsxContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseModuleUpdateJsxContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseModuleUpdateJsxContentMutation, { data, loading, error }] = useCourseModuleUpdateJsxContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseModuleUpdateJsxContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseModuleUpdateJsxContentMutation,
    CourseModuleUpdateJsxContentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseModuleUpdateJsxContentMutation,
    CourseModuleUpdateJsxContentMutationVariables
  >(CourseModuleUpdateJsxContentDocument, options);
}
export type CourseModuleUpdateJsxContentMutationHookResult = ReturnType<
  typeof useCourseModuleUpdateJsxContentMutation
>;
export type CourseModuleUpdateJsxContentMutationResult =
  Apollo.MutationResult<CourseModuleUpdateJsxContentMutation>;
export type CourseModuleUpdateJsxContentMutationOptions =
  Apollo.BaseMutationOptions<
    CourseModuleUpdateJsxContentMutation,
    CourseModuleUpdateJsxContentMutationVariables
  >;
export const CourseParticipationImportFileUpdateDocument = gql`
  mutation CourseParticipationImportFileUpdate(
    $input: CourseParticipationImportFileUpdateMutationInput!
  ) {
    courseParticipationImportFileUpdate(input: $input) {
      courseParticipationImportFile {
        id
        course {
          id
        }
        file {
          fileName
          url
          extension
        }
      }
    }
  }
`;
export type CourseParticipationImportFileUpdateMutationFn =
  Apollo.MutationFunction<
    CourseParticipationImportFileUpdateMutation,
    CourseParticipationImportFileUpdateMutationVariables
  >;

/**
 * __useCourseParticipationImportFileUpdateMutation__
 *
 * To run a mutation, you first call `useCourseParticipationImportFileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseParticipationImportFileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseParticipationImportFileUpdateMutation, { data, loading, error }] = useCourseParticipationImportFileUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseParticipationImportFileUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseParticipationImportFileUpdateMutation,
    CourseParticipationImportFileUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseParticipationImportFileUpdateMutation,
    CourseParticipationImportFileUpdateMutationVariables
  >(CourseParticipationImportFileUpdateDocument, options);
}
export type CourseParticipationImportFileUpdateMutationHookResult = ReturnType<
  typeof useCourseParticipationImportFileUpdateMutation
>;
export type CourseParticipationImportFileUpdateMutationResult =
  Apollo.MutationResult<CourseParticipationImportFileUpdateMutation>;
export type CourseParticipationImportFileUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    CourseParticipationImportFileUpdateMutation,
    CourseParticipationImportFileUpdateMutationVariables
  >;
export const CourseParticipationImportFileCreateDocument = gql`
  mutation CourseParticipationImportFileCreate(
    $input: CourseParticipationImportFileCreateMutationInput!
  ) {
    courseParticipationImportFileCreate(input: $input) {
      courseParticipationImportFile {
        id
        course {
          id
        }
        file {
          fileName
          url
          extension
        }
      }
    }
  }
`;
export type CourseParticipationImportFileCreateMutationFn =
  Apollo.MutationFunction<
    CourseParticipationImportFileCreateMutation,
    CourseParticipationImportFileCreateMutationVariables
  >;

/**
 * __useCourseParticipationImportFileCreateMutation__
 *
 * To run a mutation, you first call `useCourseParticipationImportFileCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseParticipationImportFileCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseParticipationImportFileCreateMutation, { data, loading, error }] = useCourseParticipationImportFileCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseParticipationImportFileCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseParticipationImportFileCreateMutation,
    CourseParticipationImportFileCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseParticipationImportFileCreateMutation,
    CourseParticipationImportFileCreateMutationVariables
  >(CourseParticipationImportFileCreateDocument, options);
}
export type CourseParticipationImportFileCreateMutationHookResult = ReturnType<
  typeof useCourseParticipationImportFileCreateMutation
>;
export type CourseParticipationImportFileCreateMutationResult =
  Apollo.MutationResult<CourseParticipationImportFileCreateMutation>;
export type CourseParticipationImportFileCreateMutationOptions =
  Apollo.BaseMutationOptions<
    CourseParticipationImportFileCreateMutation,
    CourseParticipationImportFileCreateMutationVariables
  >;
export const CourseParticipationApplyImportDocument = gql`
  mutation CourseParticipationApplyImport(
    $input: CourseParticipationApplyImportMutationInput!
  ) {
    courseParticipationApplyImport(input: $input) {
      result {
        errorCount
        errorEmails
        updateCount
        importCount
      }
    }
  }
`;
export type CourseParticipationApplyImportMutationFn = Apollo.MutationFunction<
  CourseParticipationApplyImportMutation,
  CourseParticipationApplyImportMutationVariables
>;

/**
 * __useCourseParticipationApplyImportMutation__
 *
 * To run a mutation, you first call `useCourseParticipationApplyImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseParticipationApplyImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseParticipationApplyImportMutation, { data, loading, error }] = useCourseParticipationApplyImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseParticipationApplyImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseParticipationApplyImportMutation,
    CourseParticipationApplyImportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseParticipationApplyImportMutation,
    CourseParticipationApplyImportMutationVariables
  >(CourseParticipationApplyImportDocument, options);
}
export type CourseParticipationApplyImportMutationHookResult = ReturnType<
  typeof useCourseParticipationApplyImportMutation
>;
export type CourseParticipationApplyImportMutationResult =
  Apollo.MutationResult<CourseParticipationApplyImportMutation>;
export type CourseParticipationApplyImportMutationOptions =
  Apollo.BaseMutationOptions<
    CourseParticipationApplyImportMutation,
    CourseParticipationApplyImportMutationVariables
  >;
export const CreateCourseByIdDocument = gql`
  mutation createCourseById($input: CourseCreateMutationInput!) {
    courseCreate(input: $input) {
      course {
        ...CourseFields
      }
    }
  }
  ${CourseFieldsFragmentDoc}
`;
export type CreateCourseByIdMutationFn = Apollo.MutationFunction<
  CreateCourseByIdMutation,
  CreateCourseByIdMutationVariables
>;

/**
 * __useCreateCourseByIdMutation__
 *
 * To run a mutation, you first call `useCreateCourseByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseByIdMutation, { data, loading, error }] = useCreateCourseByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourseByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCourseByIdMutation,
    CreateCourseByIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCourseByIdMutation,
    CreateCourseByIdMutationVariables
  >(CreateCourseByIdDocument, options);
}
export type CreateCourseByIdMutationHookResult = ReturnType<
  typeof useCreateCourseByIdMutation
>;
export type CreateCourseByIdMutationResult =
  Apollo.MutationResult<CreateCourseByIdMutation>;
export type CreateCourseByIdMutationOptions = Apollo.BaseMutationOptions<
  CreateCourseByIdMutation,
  CreateCourseByIdMutationVariables
>;
export const UpdateCourseByIdDocument = gql`
  mutation updateCourseById($input: CourseUpdateMutationInput!) {
    courseUpdate(input: $input) {
      course {
        ...CourseFields
      }
    }
  }
  ${CourseFieldsFragmentDoc}
`;
export type UpdateCourseByIdMutationFn = Apollo.MutationFunction<
  UpdateCourseByIdMutation,
  UpdateCourseByIdMutationVariables
>;

/**
 * __useUpdateCourseByIdMutation__
 *
 * To run a mutation, you first call `useUpdateCourseByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseByIdMutation, { data, loading, error }] = useUpdateCourseByIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourseByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCourseByIdMutation,
    UpdateCourseByIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCourseByIdMutation,
    UpdateCourseByIdMutationVariables
  >(UpdateCourseByIdDocument, options);
}
export type UpdateCourseByIdMutationHookResult = ReturnType<
  typeof useUpdateCourseByIdMutation
>;
export type UpdateCourseByIdMutationResult =
  Apollo.MutationResult<UpdateCourseByIdMutation>;
export type UpdateCourseByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateCourseByIdMutation,
  UpdateCourseByIdMutationVariables
>;
export const CourseCreateFromTemplateDocument = gql`
  mutation courseCreateFromTemplate(
    $input: CourseCreateFromTemplateMutationInput!
  ) {
    courseCreateFromTemplate(input: $input) {
      course {
        id
        name
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CourseCreateFromTemplateMutationFn = Apollo.MutationFunction<
  CourseCreateFromTemplateMutation,
  CourseCreateFromTemplateMutationVariables
>;

/**
 * __useCourseCreateFromTemplateMutation__
 *
 * To run a mutation, you first call `useCourseCreateFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseCreateFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseCreateFromTemplateMutation, { data, loading, error }] = useCourseCreateFromTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseCreateFromTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseCreateFromTemplateMutation,
    CourseCreateFromTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseCreateFromTemplateMutation,
    CourseCreateFromTemplateMutationVariables
  >(CourseCreateFromTemplateDocument, options);
}
export type CourseCreateFromTemplateMutationHookResult = ReturnType<
  typeof useCourseCreateFromTemplateMutation
>;
export type CourseCreateFromTemplateMutationResult =
  Apollo.MutationResult<CourseCreateFromTemplateMutation>;
export type CourseCreateFromTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    CourseCreateFromTemplateMutation,
    CourseCreateFromTemplateMutationVariables
  >;
export const CourseCloneDocument = gql`
  mutation courseClone($input: CourseCloneMutationInput!) {
    courseClone(input: $input) {
      course {
        name
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CourseCloneMutationFn = Apollo.MutationFunction<
  CourseCloneMutation,
  CourseCloneMutationVariables
>;

/**
 * __useCourseCloneMutation__
 *
 * To run a mutation, you first call `useCourseCloneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseCloneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseCloneMutation, { data, loading, error }] = useCourseCloneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseCloneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseCloneMutation,
    CourseCloneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CourseCloneMutation, CourseCloneMutationVariables>(
    CourseCloneDocument,
    options,
  );
}
export type CourseCloneMutationHookResult = ReturnType<
  typeof useCourseCloneMutation
>;
export type CourseCloneMutationResult =
  Apollo.MutationResult<CourseCloneMutation>;
export type CourseCloneMutationOptions = Apollo.BaseMutationOptions<
  CourseCloneMutation,
  CourseCloneMutationVariables
>;
export const CourseUpdateJsxContentDocument = gql`
  mutation courseUpdateJsxContent($input: CourseUpdateMutationInput!) {
    courseUpdate(input: $input) {
      course {
        id
        jsxContent
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CourseUpdateJsxContentMutationFn = Apollo.MutationFunction<
  CourseUpdateJsxContentMutation,
  CourseUpdateJsxContentMutationVariables
>;

/**
 * __useCourseUpdateJsxContentMutation__
 *
 * To run a mutation, you first call `useCourseUpdateJsxContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseUpdateJsxContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseUpdateJsxContentMutation, { data, loading, error }] = useCourseUpdateJsxContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseUpdateJsxContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseUpdateJsxContentMutation,
    CourseUpdateJsxContentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseUpdateJsxContentMutation,
    CourseUpdateJsxContentMutationVariables
  >(CourseUpdateJsxContentDocument, options);
}
export type CourseUpdateJsxContentMutationHookResult = ReturnType<
  typeof useCourseUpdateJsxContentMutation
>;
export type CourseUpdateJsxContentMutationResult =
  Apollo.MutationResult<CourseUpdateJsxContentMutation>;
export type CourseUpdateJsxContentMutationOptions = Apollo.BaseMutationOptions<
  CourseUpdateJsxContentMutation,
  CourseUpdateJsxContentMutationVariables
>;
export const CourseInvitationCreateDocument = gql`
  mutation courseInvitationCreate(
    $input: CourseInvitationCreateMutationInput!
  ) {
    courseInvitationCreate(input: $input) {
      errors {
        field
        message
      }
      courseInvitation {
        ...courseInvitationtNode
      }
    }
  }
  ${CourseInvitationtNodeFragmentDoc}
`;
export type CourseInvitationCreateMutationFn = Apollo.MutationFunction<
  CourseInvitationCreateMutation,
  CourseInvitationCreateMutationVariables
>;

/**
 * __useCourseInvitationCreateMutation__
 *
 * To run a mutation, you first call `useCourseInvitationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseInvitationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseInvitationCreateMutation, { data, loading, error }] = useCourseInvitationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseInvitationCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseInvitationCreateMutation,
    CourseInvitationCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseInvitationCreateMutation,
    CourseInvitationCreateMutationVariables
  >(CourseInvitationCreateDocument, options);
}
export type CourseInvitationCreateMutationHookResult = ReturnType<
  typeof useCourseInvitationCreateMutation
>;
export type CourseInvitationCreateMutationResult =
  Apollo.MutationResult<CourseInvitationCreateMutation>;
export type CourseInvitationCreateMutationOptions = Apollo.BaseMutationOptions<
  CourseInvitationCreateMutation,
  CourseInvitationCreateMutationVariables
>;
export const CourseInvitationUpdateDocument = gql`
  mutation courseInvitationUpdate(
    $input: CourseInvitationUpdateMutationInput!
  ) {
    courseInvitationUpdate(input: $input) {
      errors {
        field
        message
      }
      courseInvitation {
        ...courseInvitationtNode
      }
    }
  }
  ${CourseInvitationtNodeFragmentDoc}
`;
export type CourseInvitationUpdateMutationFn = Apollo.MutationFunction<
  CourseInvitationUpdateMutation,
  CourseInvitationUpdateMutationVariables
>;

/**
 * __useCourseInvitationUpdateMutation__
 *
 * To run a mutation, you first call `useCourseInvitationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseInvitationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseInvitationUpdateMutation, { data, loading, error }] = useCourseInvitationUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseInvitationUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseInvitationUpdateMutation,
    CourseInvitationUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseInvitationUpdateMutation,
    CourseInvitationUpdateMutationVariables
  >(CourseInvitationUpdateDocument, options);
}
export type CourseInvitationUpdateMutationHookResult = ReturnType<
  typeof useCourseInvitationUpdateMutation
>;
export type CourseInvitationUpdateMutationResult =
  Apollo.MutationResult<CourseInvitationUpdateMutation>;
export type CourseInvitationUpdateMutationOptions = Apollo.BaseMutationOptions<
  CourseInvitationUpdateMutation,
  CourseInvitationUpdateMutationVariables
>;
export const CourseInvitationDeleteDocument = gql`
  mutation courseInvitationDelete(
    $input: CourseInvitationDeleteMutationInput!
  ) {
    courseInvitationDelete(input: $input) {
      errors {
        field
        message
      }
      courseInvitation {
        id
      }
    }
  }
`;
export type CourseInvitationDeleteMutationFn = Apollo.MutationFunction<
  CourseInvitationDeleteMutation,
  CourseInvitationDeleteMutationVariables
>;

/**
 * __useCourseInvitationDeleteMutation__
 *
 * To run a mutation, you first call `useCourseInvitationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseInvitationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseInvitationDeleteMutation, { data, loading, error }] = useCourseInvitationDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseInvitationDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseInvitationDeleteMutation,
    CourseInvitationDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseInvitationDeleteMutation,
    CourseInvitationDeleteMutationVariables
  >(CourseInvitationDeleteDocument, options);
}
export type CourseInvitationDeleteMutationHookResult = ReturnType<
  typeof useCourseInvitationDeleteMutation
>;
export type CourseInvitationDeleteMutationResult =
  Apollo.MutationResult<CourseInvitationDeleteMutation>;
export type CourseInvitationDeleteMutationOptions = Apollo.BaseMutationOptions<
  CourseInvitationDeleteMutation,
  CourseInvitationDeleteMutationVariables
>;
export const CourseSendAllInvitationsDocument = gql`
  mutation courseSendAllInvitations($input: CourseSendAllInvitationsInput!) {
    courseSendAllInvitations(input: $input) {
      sentCount
      skypCount
      failCount
      errors {
        field
        message
      }
    }
  }
`;
export type CourseSendAllInvitationsMutationFn = Apollo.MutationFunction<
  CourseSendAllInvitationsMutation,
  CourseSendAllInvitationsMutationVariables
>;

/**
 * __useCourseSendAllInvitationsMutation__
 *
 * To run a mutation, you first call `useCourseSendAllInvitationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseSendAllInvitationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseSendAllInvitationsMutation, { data, loading, error }] = useCourseSendAllInvitationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseSendAllInvitationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseSendAllInvitationsMutation,
    CourseSendAllInvitationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseSendAllInvitationsMutation,
    CourseSendAllInvitationsMutationVariables
  >(CourseSendAllInvitationsDocument, options);
}
export type CourseSendAllInvitationsMutationHookResult = ReturnType<
  typeof useCourseSendAllInvitationsMutation
>;
export type CourseSendAllInvitationsMutationResult =
  Apollo.MutationResult<CourseSendAllInvitationsMutation>;
export type CourseSendAllInvitationsMutationOptions =
  Apollo.BaseMutationOptions<
    CourseSendAllInvitationsMutation,
    CourseSendAllInvitationsMutationVariables
  >;
export const CourseDeleteAllPossibleInvitationsDocument = gql`
  mutation courseDeleteAllPossibleInvitations(
    $input: CourseDeleteAllPossibleInvitationsInput!
  ) {
    courseDeleteAllPossibleInvitations(input: $input) {
      count
      errors {
        field
        message
      }
    }
  }
`;
export type CourseDeleteAllPossibleInvitationsMutationFn =
  Apollo.MutationFunction<
    CourseDeleteAllPossibleInvitationsMutation,
    CourseDeleteAllPossibleInvitationsMutationVariables
  >;

/**
 * __useCourseDeleteAllPossibleInvitationsMutation__
 *
 * To run a mutation, you first call `useCourseDeleteAllPossibleInvitationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseDeleteAllPossibleInvitationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseDeleteAllPossibleInvitationsMutation, { data, loading, error }] = useCourseDeleteAllPossibleInvitationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseDeleteAllPossibleInvitationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseDeleteAllPossibleInvitationsMutation,
    CourseDeleteAllPossibleInvitationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseDeleteAllPossibleInvitationsMutation,
    CourseDeleteAllPossibleInvitationsMutationVariables
  >(CourseDeleteAllPossibleInvitationsDocument, options);
}
export type CourseDeleteAllPossibleInvitationsMutationHookResult = ReturnType<
  typeof useCourseDeleteAllPossibleInvitationsMutation
>;
export type CourseDeleteAllPossibleInvitationsMutationResult =
  Apollo.MutationResult<CourseDeleteAllPossibleInvitationsMutation>;
export type CourseDeleteAllPossibleInvitationsMutationOptions =
  Apollo.BaseMutationOptions<
    CourseDeleteAllPossibleInvitationsMutation,
    CourseDeleteAllPossibleInvitationsMutationVariables
  >;
export const CourseInvitationSendEmailDocument = gql`
  mutation courseInvitationSendEmail(
    $input: CourseInvitationSendEmailMutationInput!
  ) {
    courseInvitationSendEmail(input: $input) {
      courseInvitation {
        ...courseInvitationtNode
      }
      errors {
        field
        message
      }
    }
  }
  ${CourseInvitationtNodeFragmentDoc}
`;
export type CourseInvitationSendEmailMutationFn = Apollo.MutationFunction<
  CourseInvitationSendEmailMutation,
  CourseInvitationSendEmailMutationVariables
>;

/**
 * __useCourseInvitationSendEmailMutation__
 *
 * To run a mutation, you first call `useCourseInvitationSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseInvitationSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseInvitationSendEmailMutation, { data, loading, error }] = useCourseInvitationSendEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseInvitationSendEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseInvitationSendEmailMutation,
    CourseInvitationSendEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseInvitationSendEmailMutation,
    CourseInvitationSendEmailMutationVariables
  >(CourseInvitationSendEmailDocument, options);
}
export type CourseInvitationSendEmailMutationHookResult = ReturnType<
  typeof useCourseInvitationSendEmailMutation
>;
export type CourseInvitationSendEmailMutationResult =
  Apollo.MutationResult<CourseInvitationSendEmailMutation>;
export type CourseInvitationSendEmailMutationOptions =
  Apollo.BaseMutationOptions<
    CourseInvitationSendEmailMutation,
    CourseInvitationSendEmailMutationVariables
  >;
export const CourseInvitationPublicResendEmailDocument = gql`
  mutation courseInvitationPublicResendEmail(
    $input: CourseInvitationPublicResendEmailMutationInput!
  ) {
    courseInvitationPublicResendEmail(input: $input) {
      sentResult
      errors {
        field
        message
      }
    }
  }
`;
export type CourseInvitationPublicResendEmailMutationFn =
  Apollo.MutationFunction<
    CourseInvitationPublicResendEmailMutation,
    CourseInvitationPublicResendEmailMutationVariables
  >;

/**
 * __useCourseInvitationPublicResendEmailMutation__
 *
 * To run a mutation, you first call `useCourseInvitationPublicResendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseInvitationPublicResendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseInvitationPublicResendEmailMutation, { data, loading, error }] = useCourseInvitationPublicResendEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseInvitationPublicResendEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseInvitationPublicResendEmailMutation,
    CourseInvitationPublicResendEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseInvitationPublicResendEmailMutation,
    CourseInvitationPublicResendEmailMutationVariables
  >(CourseInvitationPublicResendEmailDocument, options);
}
export type CourseInvitationPublicResendEmailMutationHookResult = ReturnType<
  typeof useCourseInvitationPublicResendEmailMutation
>;
export type CourseInvitationPublicResendEmailMutationResult =
  Apollo.MutationResult<CourseInvitationPublicResendEmailMutation>;
export type CourseInvitationPublicResendEmailMutationOptions =
  Apollo.BaseMutationOptions<
    CourseInvitationPublicResendEmailMutation,
    CourseInvitationPublicResendEmailMutationVariables
  >;
export const CourseInvitationPublicSearchAndSendByEmailDocument = gql`
  mutation courseInvitationPublicSearchAndSendByEmail(
    $input: CourseInvitationSearchAndSendByEmailInput!
  ) {
    courseInvitationPublicSearchAndSendByEmail(input: $input) {
      sentResult
      errors {
        field
        message
      }
    }
  }
`;
export type CourseInvitationPublicSearchAndSendByEmailMutationFn =
  Apollo.MutationFunction<
    CourseInvitationPublicSearchAndSendByEmailMutation,
    CourseInvitationPublicSearchAndSendByEmailMutationVariables
  >;

/**
 * __useCourseInvitationPublicSearchAndSendByEmailMutation__
 *
 * To run a mutation, you first call `useCourseInvitationPublicSearchAndSendByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseInvitationPublicSearchAndSendByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseInvitationPublicSearchAndSendByEmailMutation, { data, loading, error }] = useCourseInvitationPublicSearchAndSendByEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseInvitationPublicSearchAndSendByEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseInvitationPublicSearchAndSendByEmailMutation,
    CourseInvitationPublicSearchAndSendByEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseInvitationPublicSearchAndSendByEmailMutation,
    CourseInvitationPublicSearchAndSendByEmailMutationVariables
  >(CourseInvitationPublicSearchAndSendByEmailDocument, options);
}
export type CourseInvitationPublicSearchAndSendByEmailMutationHookResult =
  ReturnType<typeof useCourseInvitationPublicSearchAndSendByEmailMutation>;
export type CourseInvitationPublicSearchAndSendByEmailMutationResult =
  Apollo.MutationResult<CourseInvitationPublicSearchAndSendByEmailMutation>;
export type CourseInvitationPublicSearchAndSendByEmailMutationOptions =
  Apollo.BaseMutationOptions<
    CourseInvitationPublicSearchAndSendByEmailMutation,
    CourseInvitationPublicSearchAndSendByEmailMutationVariables
  >;
export const CourseModuleUpdateDocument = gql`
  mutation courseModuleUpdate($input: CourseModuleUpdateMutationInput!) {
    courseModuleUpdate(input: $input) {
      courseModule {
        ...courseModuleUnion
      }
      errors {
        field
        message
      }
    }
  }
  ${CourseModuleUnionFragmentDoc}
`;
export type CourseModuleUpdateMutationFn = Apollo.MutationFunction<
  CourseModuleUpdateMutation,
  CourseModuleUpdateMutationVariables
>;

/**
 * __useCourseModuleUpdateMutation__
 *
 * To run a mutation, you first call `useCourseModuleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseModuleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseModuleUpdateMutation, { data, loading, error }] = useCourseModuleUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseModuleUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseModuleUpdateMutation,
    CourseModuleUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseModuleUpdateMutation,
    CourseModuleUpdateMutationVariables
  >(CourseModuleUpdateDocument, options);
}
export type CourseModuleUpdateMutationHookResult = ReturnType<
  typeof useCourseModuleUpdateMutation
>;
export type CourseModuleUpdateMutationResult =
  Apollo.MutationResult<CourseModuleUpdateMutation>;
export type CourseModuleUpdateMutationOptions = Apollo.BaseMutationOptions<
  CourseModuleUpdateMutation,
  CourseModuleUpdateMutationVariables
>;
export const UserModuleAssessmentStartDocument = gql`
  mutation userModuleAssessmentStart($input: UserModuleAssessmentStartInput!) {
    userModuleAssessmentStart(input: $input) {
      moduleAssessment {
        ...courseModuleAssessmentUnion
      }
    }
  }
  ${CourseModuleAssessmentUnionFragmentDoc}
`;
export type UserModuleAssessmentStartMutationFn = Apollo.MutationFunction<
  UserModuleAssessmentStartMutation,
  UserModuleAssessmentStartMutationVariables
>;

/**
 * __useUserModuleAssessmentStartMutation__
 *
 * To run a mutation, you first call `useUserModuleAssessmentStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserModuleAssessmentStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userModuleAssessmentStartMutation, { data, loading, error }] = useUserModuleAssessmentStartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserModuleAssessmentStartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserModuleAssessmentStartMutation,
    UserModuleAssessmentStartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserModuleAssessmentStartMutation,
    UserModuleAssessmentStartMutationVariables
  >(UserModuleAssessmentStartDocument, options);
}
export type UserModuleAssessmentStartMutationHookResult = ReturnType<
  typeof useUserModuleAssessmentStartMutation
>;
export type UserModuleAssessmentStartMutationResult =
  Apollo.MutationResult<UserModuleAssessmentStartMutation>;
export type UserModuleAssessmentStartMutationOptions =
  Apollo.BaseMutationOptions<
    UserModuleAssessmentStartMutation,
    UserModuleAssessmentStartMutationVariables
  >;
export const UserModuleAssessmentSetCompletedDocument = gql`
  mutation userModuleAssessmentSetCompleted(
    $input: UserModuleAssessmentSetCompletedInput!
  ) {
    userModuleAssessmentSetCompleted(input: $input) {
      moduleAssessment {
        ...courseModuleAssessmentUnion
      }
    }
  }
  ${CourseModuleAssessmentUnionFragmentDoc}
`;
export type UserModuleAssessmentSetCompletedMutationFn =
  Apollo.MutationFunction<
    UserModuleAssessmentSetCompletedMutation,
    UserModuleAssessmentSetCompletedMutationVariables
  >;

/**
 * __useUserModuleAssessmentSetCompletedMutation__
 *
 * To run a mutation, you first call `useUserModuleAssessmentSetCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserModuleAssessmentSetCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userModuleAssessmentSetCompletedMutation, { data, loading, error }] = useUserModuleAssessmentSetCompletedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserModuleAssessmentSetCompletedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserModuleAssessmentSetCompletedMutation,
    UserModuleAssessmentSetCompletedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserModuleAssessmentSetCompletedMutation,
    UserModuleAssessmentSetCompletedMutationVariables
  >(UserModuleAssessmentSetCompletedDocument, options);
}
export type UserModuleAssessmentSetCompletedMutationHookResult = ReturnType<
  typeof useUserModuleAssessmentSetCompletedMutation
>;
export type UserModuleAssessmentSetCompletedMutationResult =
  Apollo.MutationResult<UserModuleAssessmentSetCompletedMutation>;
export type UserModuleAssessmentSetCompletedMutationOptions =
  Apollo.BaseMutationOptions<
    UserModuleAssessmentSetCompletedMutation,
    UserModuleAssessmentSetCompletedMutationVariables
  >;
export const UserModuleAssessmentSurveyCreateNewResponseDocument = gql`
  mutation userModuleAssessmentSurveyCreateNewResponse(
    $input: UserModuleAssessmentSurveyCreateNewResponseInput!
  ) {
    userModuleAssessmentSurveyCreateNewResponse(input: $input) {
      moduleAssessment {
        ...courseModuleAssessmentUnion
      }
    }
  }
  ${CourseModuleAssessmentUnionFragmentDoc}
`;
export type UserModuleAssessmentSurveyCreateNewResponseMutationFn =
  Apollo.MutationFunction<
    UserModuleAssessmentSurveyCreateNewResponseMutation,
    UserModuleAssessmentSurveyCreateNewResponseMutationVariables
  >;

/**
 * __useUserModuleAssessmentSurveyCreateNewResponseMutation__
 *
 * To run a mutation, you first call `useUserModuleAssessmentSurveyCreateNewResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserModuleAssessmentSurveyCreateNewResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userModuleAssessmentSurveyCreateNewResponseMutation, { data, loading, error }] = useUserModuleAssessmentSurveyCreateNewResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserModuleAssessmentSurveyCreateNewResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserModuleAssessmentSurveyCreateNewResponseMutation,
    UserModuleAssessmentSurveyCreateNewResponseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserModuleAssessmentSurveyCreateNewResponseMutation,
    UserModuleAssessmentSurveyCreateNewResponseMutationVariables
  >(UserModuleAssessmentSurveyCreateNewResponseDocument, options);
}
export type UserModuleAssessmentSurveyCreateNewResponseMutationHookResult =
  ReturnType<typeof useUserModuleAssessmentSurveyCreateNewResponseMutation>;
export type UserModuleAssessmentSurveyCreateNewResponseMutationResult =
  Apollo.MutationResult<UserModuleAssessmentSurveyCreateNewResponseMutation>;
export type UserModuleAssessmentSurveyCreateNewResponseMutationOptions =
  Apollo.BaseMutationOptions<
    UserModuleAssessmentSurveyCreateNewResponseMutation,
    UserModuleAssessmentSurveyCreateNewResponseMutationVariables
  >;
export const VideoModuleAssessmentUpdateDocument = gql`
  mutation videoModuleAssessmentUpdate(
    $input: UserVideoModuleAssessmentUpdateMutationInput!
  ) {
    videoModuleAssessmentUpdate(input: $input) {
      courseModuleAssessmentVideo {
        id
        status
        videoPlayedSeconds
        userLivePercentile
      }
      errors {
        field
        message
      }
    }
  }
`;
export type VideoModuleAssessmentUpdateMutationFn = Apollo.MutationFunction<
  VideoModuleAssessmentUpdateMutation,
  VideoModuleAssessmentUpdateMutationVariables
>;

/**
 * __useVideoModuleAssessmentUpdateMutation__
 *
 * To run a mutation, you first call `useVideoModuleAssessmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoModuleAssessmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoModuleAssessmentUpdateMutation, { data, loading, error }] = useVideoModuleAssessmentUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVideoModuleAssessmentUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VideoModuleAssessmentUpdateMutation,
    VideoModuleAssessmentUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VideoModuleAssessmentUpdateMutation,
    VideoModuleAssessmentUpdateMutationVariables
  >(VideoModuleAssessmentUpdateDocument, options);
}
export type VideoModuleAssessmentUpdateMutationHookResult = ReturnType<
  typeof useVideoModuleAssessmentUpdateMutation
>;
export type VideoModuleAssessmentUpdateMutationResult =
  Apollo.MutationResult<VideoModuleAssessmentUpdateMutation>;
export type VideoModuleAssessmentUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    VideoModuleAssessmentUpdateMutation,
    VideoModuleAssessmentUpdateMutationVariables
  >;
export const UserModuleAssessmentCreateDocument = gql`
  mutation userModuleAssessmentCreate(
    $input: UserModuleAssessmentCreateInput!
  ) {
    userModuleAssessmentCreate(input: $input) {
      moduleAssessment {
        ...courseModuleAssessmentUnion
      }
      module {
        ...courseModuleUnionOverviewWithPartecipationData
      }
      errors {
        field
        message
      }
    }
  }
  ${CourseModuleAssessmentUnionFragmentDoc}
  ${CourseModuleUnionOverviewWithPartecipationDataFragmentDoc}
`;
export type UserModuleAssessmentCreateMutationFn = Apollo.MutationFunction<
  UserModuleAssessmentCreateMutation,
  UserModuleAssessmentCreateMutationVariables
>;

/**
 * __useUserModuleAssessmentCreateMutation__
 *
 * To run a mutation, you first call `useUserModuleAssessmentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserModuleAssessmentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userModuleAssessmentCreateMutation, { data, loading, error }] = useUserModuleAssessmentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserModuleAssessmentCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserModuleAssessmentCreateMutation,
    UserModuleAssessmentCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserModuleAssessmentCreateMutation,
    UserModuleAssessmentCreateMutationVariables
  >(UserModuleAssessmentCreateDocument, options);
}
export type UserModuleAssessmentCreateMutationHookResult = ReturnType<
  typeof useUserModuleAssessmentCreateMutation
>;
export type UserModuleAssessmentCreateMutationResult =
  Apollo.MutationResult<UserModuleAssessmentCreateMutation>;
export type UserModuleAssessmentCreateMutationOptions =
  Apollo.BaseMutationOptions<
    UserModuleAssessmentCreateMutation,
    UserModuleAssessmentCreateMutationVariables
  >;
export const UserModuleAssessmentGateCheckDocument = gql`
  mutation userModuleAssessmentGateCheck(
    $input: UserModuleAssessmentGateCheckInput!
  ) {
    userModuleAssessmentGateCheck(input: $input) {
      moduleAssessment {
        ...courseModuleAssessmentUnion
      }
      email
      firstName
      lastName
      errors {
        field
        message
      }
    }
  }
  ${CourseModuleAssessmentUnionFragmentDoc}
`;
export type UserModuleAssessmentGateCheckMutationFn = Apollo.MutationFunction<
  UserModuleAssessmentGateCheckMutation,
  UserModuleAssessmentGateCheckMutationVariables
>;

/**
 * __useUserModuleAssessmentGateCheckMutation__
 *
 * To run a mutation, you first call `useUserModuleAssessmentGateCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserModuleAssessmentGateCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userModuleAssessmentGateCheckMutation, { data, loading, error }] = useUserModuleAssessmentGateCheckMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserModuleAssessmentGateCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserModuleAssessmentGateCheckMutation,
    UserModuleAssessmentGateCheckMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserModuleAssessmentGateCheckMutation,
    UserModuleAssessmentGateCheckMutationVariables
  >(UserModuleAssessmentGateCheckDocument, options);
}
export type UserModuleAssessmentGateCheckMutationHookResult = ReturnType<
  typeof useUserModuleAssessmentGateCheckMutation
>;
export type UserModuleAssessmentGateCheckMutationResult =
  Apollo.MutationResult<UserModuleAssessmentGateCheckMutation>;
export type UserModuleAssessmentGateCheckMutationOptions =
  Apollo.BaseMutationOptions<
    UserModuleAssessmentGateCheckMutation,
    UserModuleAssessmentGateCheckMutationVariables
  >;
export const CourseParticipationCreateDocument = gql`
  mutation courseParticipationCreate(
    $input: CourseParticipationCreateMutationInput!
  ) {
    courseParticipationCreate(input: $input) {
      errors {
        field
        message
      }
      courseParticipation {
        id
        qrcode {
          url
        }
        data
        course {
          id
          participation {
            id
            status
          }
          submodules {
            totalCount
            edges {
              node {
                ...courseModuleUnionOverviewWithPartecipationData
              }
            }
          }
        }
        user {
          id
        }
        manager {
          id
        }
        status
      }
    }
  }
  ${CourseModuleUnionOverviewWithPartecipationDataFragmentDoc}
`;
export type CourseParticipationCreateMutationFn = Apollo.MutationFunction<
  CourseParticipationCreateMutation,
  CourseParticipationCreateMutationVariables
>;

/**
 * __useCourseParticipationCreateMutation__
 *
 * To run a mutation, you first call `useCourseParticipationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseParticipationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseParticipationCreateMutation, { data, loading, error }] = useCourseParticipationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseParticipationCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseParticipationCreateMutation,
    CourseParticipationCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseParticipationCreateMutation,
    CourseParticipationCreateMutationVariables
  >(CourseParticipationCreateDocument, options);
}
export type CourseParticipationCreateMutationHookResult = ReturnType<
  typeof useCourseParticipationCreateMutation
>;
export type CourseParticipationCreateMutationResult =
  Apollo.MutationResult<CourseParticipationCreateMutation>;
export type CourseParticipationCreateMutationOptions =
  Apollo.BaseMutationOptions<
    CourseParticipationCreateMutation,
    CourseParticipationCreateMutationVariables
  >;
export const CourseParticipationUpdateDocument = gql`
  mutation courseParticipationUpdate(
    $input: CourseParticipationUpdateMutationInput!
  ) {
    courseParticipationUpdate(input: $input) {
      errors {
        field
        message
      }
      courseParticipation {
        id
        data
        user {
          id
        }
        manager {
          id
        }
      }
    }
  }
`;
export type CourseParticipationUpdateMutationFn = Apollo.MutationFunction<
  CourseParticipationUpdateMutation,
  CourseParticipationUpdateMutationVariables
>;

/**
 * __useCourseParticipationUpdateMutation__
 *
 * To run a mutation, you first call `useCourseParticipationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseParticipationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseParticipationUpdateMutation, { data, loading, error }] = useCourseParticipationUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseParticipationUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseParticipationUpdateMutation,
    CourseParticipationUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseParticipationUpdateMutation,
    CourseParticipationUpdateMutationVariables
  >(CourseParticipationUpdateDocument, options);
}
export type CourseParticipationUpdateMutationHookResult = ReturnType<
  typeof useCourseParticipationUpdateMutation
>;
export type CourseParticipationUpdateMutationResult =
  Apollo.MutationResult<CourseParticipationUpdateMutation>;
export type CourseParticipationUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    CourseParticipationUpdateMutation,
    CourseParticipationUpdateMutationVariables
  >;
export const CourseParticipationDeleteDocument = gql`
  mutation courseParticipationDelete(
    $input: CourseParticipationDeleteMutationInput!
  ) {
    courseParticipationDelete(input: $input) {
      errors {
        field
        message
      }
    }
  }
`;
export type CourseParticipationDeleteMutationFn = Apollo.MutationFunction<
  CourseParticipationDeleteMutation,
  CourseParticipationDeleteMutationVariables
>;

/**
 * __useCourseParticipationDeleteMutation__
 *
 * To run a mutation, you first call `useCourseParticipationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseParticipationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseParticipationDeleteMutation, { data, loading, error }] = useCourseParticipationDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseParticipationDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseParticipationDeleteMutation,
    CourseParticipationDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseParticipationDeleteMutation,
    CourseParticipationDeleteMutationVariables
  >(CourseParticipationDeleteDocument, options);
}
export type CourseParticipationDeleteMutationHookResult = ReturnType<
  typeof useCourseParticipationDeleteMutation
>;
export type CourseParticipationDeleteMutationResult =
  Apollo.MutationResult<CourseParticipationDeleteMutation>;
export type CourseParticipationDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    CourseParticipationDeleteMutation,
    CourseParticipationDeleteMutationVariables
  >;
export const CourseParticipationManageStatusDocument = gql`
  mutation courseParticipationManageStatus(
    $input: CoursePartecipationManageStatusMutationInput!
  ) {
    coursePartecipationManageStatus(input: $input) {
      participation {
        id
        status
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CourseParticipationManageStatusMutationFn = Apollo.MutationFunction<
  CourseParticipationManageStatusMutation,
  CourseParticipationManageStatusMutationVariables
>;

/**
 * __useCourseParticipationManageStatusMutation__
 *
 * To run a mutation, you first call `useCourseParticipationManageStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseParticipationManageStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseParticipationManageStatusMutation, { data, loading, error }] = useCourseParticipationManageStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseParticipationManageStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseParticipationManageStatusMutation,
    CourseParticipationManageStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseParticipationManageStatusMutation,
    CourseParticipationManageStatusMutationVariables
  >(CourseParticipationManageStatusDocument, options);
}
export type CourseParticipationManageStatusMutationHookResult = ReturnType<
  typeof useCourseParticipationManageStatusMutation
>;
export type CourseParticipationManageStatusMutationResult =
  Apollo.MutationResult<CourseParticipationManageStatusMutation>;
export type CourseParticipationManageStatusMutationOptions =
  Apollo.BaseMutationOptions<
    CourseParticipationManageStatusMutation,
    CourseParticipationManageStatusMutationVariables
  >;
export const CoursePartecipationResendConfirmEmailDocument = gql`
  mutation coursePartecipationResendConfirmEmail(
    $input: CoursePartecipationResendConfirmEmailInput!
  ) {
    coursePartecipationResendConfirmEmail(input: $input) {
      participation {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CoursePartecipationResendConfirmEmailMutationFn =
  Apollo.MutationFunction<
    CoursePartecipationResendConfirmEmailMutation,
    CoursePartecipationResendConfirmEmailMutationVariables
  >;

/**
 * __useCoursePartecipationResendConfirmEmailMutation__
 *
 * To run a mutation, you first call `useCoursePartecipationResendConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoursePartecipationResendConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coursePartecipationResendConfirmEmailMutation, { data, loading, error }] = useCoursePartecipationResendConfirmEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoursePartecipationResendConfirmEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CoursePartecipationResendConfirmEmailMutation,
    CoursePartecipationResendConfirmEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CoursePartecipationResendConfirmEmailMutation,
    CoursePartecipationResendConfirmEmailMutationVariables
  >(CoursePartecipationResendConfirmEmailDocument, options);
}
export type CoursePartecipationResendConfirmEmailMutationHookResult =
  ReturnType<typeof useCoursePartecipationResendConfirmEmailMutation>;
export type CoursePartecipationResendConfirmEmailMutationResult =
  Apollo.MutationResult<CoursePartecipationResendConfirmEmailMutation>;
export type CoursePartecipationResendConfirmEmailMutationOptions =
  Apollo.BaseMutationOptions<
    CoursePartecipationResendConfirmEmailMutation,
    CoursePartecipationResendConfirmEmailMutationVariables
  >;
export const ZoomMeetingAuthorizeMutationDocument = gql`
  mutation ZoomMeetingAuthorizeMutation(
    $input: ZoomMeetingAuthorizeMutationInput!
  ) {
    zoomMeetingAuthorize(input: $input) {
      authData {
        token
        sdkKey
      }
      errors {
        field
        message
      }
    }
  }
`;
export type ZoomMeetingAuthorizeMutationMutationFn = Apollo.MutationFunction<
  ZoomMeetingAuthorizeMutationMutation,
  ZoomMeetingAuthorizeMutationMutationVariables
>;

/**
 * __useZoomMeetingAuthorizeMutationMutation__
 *
 * To run a mutation, you first call `useZoomMeetingAuthorizeMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZoomMeetingAuthorizeMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zoomMeetingAuthorizeMutationMutation, { data, loading, error }] = useZoomMeetingAuthorizeMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useZoomMeetingAuthorizeMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ZoomMeetingAuthorizeMutationMutation,
    ZoomMeetingAuthorizeMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ZoomMeetingAuthorizeMutationMutation,
    ZoomMeetingAuthorizeMutationMutationVariables
  >(ZoomMeetingAuthorizeMutationDocument, options);
}
export type ZoomMeetingAuthorizeMutationMutationHookResult = ReturnType<
  typeof useZoomMeetingAuthorizeMutationMutation
>;
export type ZoomMeetingAuthorizeMutationMutationResult =
  Apollo.MutationResult<ZoomMeetingAuthorizeMutationMutation>;
export type ZoomMeetingAuthorizeMutationMutationOptions =
  Apollo.BaseMutationOptions<
    ZoomMeetingAuthorizeMutationMutation,
    ZoomMeetingAuthorizeMutationMutationVariables
  >;
export const ZoomMeetingCreateMutationDocument = gql`
  mutation ZoomMeetingCreateMutation($input: ZoomMeetingCreateMutationInput!) {
    zoomMeetingCreate(input: $input) {
      meeting {
        meetingNo
        id
        meetingPwd
        topic
        startTime
        data
      }
      errors {
        field
        message
      }
    }
  }
`;
export type ZoomMeetingCreateMutationMutationFn = Apollo.MutationFunction<
  ZoomMeetingCreateMutationMutation,
  ZoomMeetingCreateMutationMutationVariables
>;

/**
 * __useZoomMeetingCreateMutationMutation__
 *
 * To run a mutation, you first call `useZoomMeetingCreateMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZoomMeetingCreateMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zoomMeetingCreateMutationMutation, { data, loading, error }] = useZoomMeetingCreateMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useZoomMeetingCreateMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ZoomMeetingCreateMutationMutation,
    ZoomMeetingCreateMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ZoomMeetingCreateMutationMutation,
    ZoomMeetingCreateMutationMutationVariables
  >(ZoomMeetingCreateMutationDocument, options);
}
export type ZoomMeetingCreateMutationMutationHookResult = ReturnType<
  typeof useZoomMeetingCreateMutationMutation
>;
export type ZoomMeetingCreateMutationMutationResult =
  Apollo.MutationResult<ZoomMeetingCreateMutationMutation>;
export type ZoomMeetingCreateMutationMutationOptions =
  Apollo.BaseMutationOptions<
    ZoomMeetingCreateMutationMutation,
    ZoomMeetingCreateMutationMutationVariables
  >;
export const ZoomMeetingUpdateMutationDocument = gql`
  mutation ZoomMeetingUpdateMutation($input: ZoomMeetingUpdateMutationInput!) {
    zoomMeetingUpdate(input: $input) {
      meeting {
        meetingNo
        id
        meetingPwd
        topic
        startTime
        data
      }
      errors {
        field
        message
      }
    }
  }
`;
export type ZoomMeetingUpdateMutationMutationFn = Apollo.MutationFunction<
  ZoomMeetingUpdateMutationMutation,
  ZoomMeetingUpdateMutationMutationVariables
>;

/**
 * __useZoomMeetingUpdateMutationMutation__
 *
 * To run a mutation, you first call `useZoomMeetingUpdateMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZoomMeetingUpdateMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zoomMeetingUpdateMutationMutation, { data, loading, error }] = useZoomMeetingUpdateMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useZoomMeetingUpdateMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ZoomMeetingUpdateMutationMutation,
    ZoomMeetingUpdateMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ZoomMeetingUpdateMutationMutation,
    ZoomMeetingUpdateMutationMutationVariables
  >(ZoomMeetingUpdateMutationDocument, options);
}
export type ZoomMeetingUpdateMutationMutationHookResult = ReturnType<
  typeof useZoomMeetingUpdateMutationMutation
>;
export type ZoomMeetingUpdateMutationMutationResult =
  Apollo.MutationResult<ZoomMeetingUpdateMutationMutation>;
export type ZoomMeetingUpdateMutationMutationOptions =
  Apollo.BaseMutationOptions<
    ZoomMeetingUpdateMutationMutation,
    ZoomMeetingUpdateMutationMutationVariables
  >;
export const ZoomMeetingDeleteMutationDocument = gql`
  mutation ZoomMeetingDeleteMutation($input: ZoomMeetingDeleteMutationInput!) {
    zoomMeetingDelete(input: $input) {
      success
      errors {
        field
        message
      }
    }
  }
`;
export type ZoomMeetingDeleteMutationMutationFn = Apollo.MutationFunction<
  ZoomMeetingDeleteMutationMutation,
  ZoomMeetingDeleteMutationMutationVariables
>;

/**
 * __useZoomMeetingDeleteMutationMutation__
 *
 * To run a mutation, you first call `useZoomMeetingDeleteMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useZoomMeetingDeleteMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [zoomMeetingDeleteMutationMutation, { data, loading, error }] = useZoomMeetingDeleteMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useZoomMeetingDeleteMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ZoomMeetingDeleteMutationMutation,
    ZoomMeetingDeleteMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ZoomMeetingDeleteMutationMutation,
    ZoomMeetingDeleteMutationMutationVariables
  >(ZoomMeetingDeleteMutationDocument, options);
}
export type ZoomMeetingDeleteMutationMutationHookResult = ReturnType<
  typeof useZoomMeetingDeleteMutationMutation
>;
export type ZoomMeetingDeleteMutationMutationResult =
  Apollo.MutationResult<ZoomMeetingDeleteMutationMutation>;
export type ZoomMeetingDeleteMutationMutationOptions =
  Apollo.BaseMutationOptions<
    ZoomMeetingDeleteMutationMutation,
    ZoomMeetingDeleteMutationMutationVariables
  >;
export const CourseUserParticipationsQuotaDeleteDocument = gql`
  mutation courseUserParticipationsQuotaDelete(
    $input: CourseUserParticipationsQuotaDeleteMutationInput!
  ) {
    courseUserParticipationsQuotaDelete(input: $input) {
      errors {
        field
        message
      }
      courseUserParticipationQuotas {
        id
      }
    }
  }
`;
export type CourseUserParticipationsQuotaDeleteMutationFn =
  Apollo.MutationFunction<
    CourseUserParticipationsQuotaDeleteMutation,
    CourseUserParticipationsQuotaDeleteMutationVariables
  >;

/**
 * __useCourseUserParticipationsQuotaDeleteMutation__
 *
 * To run a mutation, you first call `useCourseUserParticipationsQuotaDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseUserParticipationsQuotaDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseUserParticipationsQuotaDeleteMutation, { data, loading, error }] = useCourseUserParticipationsQuotaDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseUserParticipationsQuotaDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseUserParticipationsQuotaDeleteMutation,
    CourseUserParticipationsQuotaDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseUserParticipationsQuotaDeleteMutation,
    CourseUserParticipationsQuotaDeleteMutationVariables
  >(CourseUserParticipationsQuotaDeleteDocument, options);
}
export type CourseUserParticipationsQuotaDeleteMutationHookResult = ReturnType<
  typeof useCourseUserParticipationsQuotaDeleteMutation
>;
export type CourseUserParticipationsQuotaDeleteMutationResult =
  Apollo.MutationResult<CourseUserParticipationsQuotaDeleteMutation>;
export type CourseUserParticipationsQuotaDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    CourseUserParticipationsQuotaDeleteMutation,
    CourseUserParticipationsQuotaDeleteMutationVariables
  >;
export const CourseUserParticipationsQuotaCreateDocument = gql`
  mutation courseUserParticipationsQuotaCreate(
    $input: CourseUserParticipationsQuotaCreateMutationInput!
  ) {
    courseUserParticipationsQuotaCreate(input: $input) {
      errors {
        field
        message
      }
      courseUserParticipationQuotas {
        id
        participationsQuota
        label
        user {
          id
          displayName
          email
        }
      }
    }
  }
`;
export type CourseUserParticipationsQuotaCreateMutationFn =
  Apollo.MutationFunction<
    CourseUserParticipationsQuotaCreateMutation,
    CourseUserParticipationsQuotaCreateMutationVariables
  >;

/**
 * __useCourseUserParticipationsQuotaCreateMutation__
 *
 * To run a mutation, you first call `useCourseUserParticipationsQuotaCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseUserParticipationsQuotaCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseUserParticipationsQuotaCreateMutation, { data, loading, error }] = useCourseUserParticipationsQuotaCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseUserParticipationsQuotaCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseUserParticipationsQuotaCreateMutation,
    CourseUserParticipationsQuotaCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseUserParticipationsQuotaCreateMutation,
    CourseUserParticipationsQuotaCreateMutationVariables
  >(CourseUserParticipationsQuotaCreateDocument, options);
}
export type CourseUserParticipationsQuotaCreateMutationHookResult = ReturnType<
  typeof useCourseUserParticipationsQuotaCreateMutation
>;
export type CourseUserParticipationsQuotaCreateMutationResult =
  Apollo.MutationResult<CourseUserParticipationsQuotaCreateMutation>;
export type CourseUserParticipationsQuotaCreateMutationOptions =
  Apollo.BaseMutationOptions<
    CourseUserParticipationsQuotaCreateMutation,
    CourseUserParticipationsQuotaCreateMutationVariables
  >;
export const CourseUserParticipationsQuotaUpdateDocument = gql`
  mutation courseUserParticipationsQuotaUpdate(
    $input: CourseUserParticipationsQuotaUpdateMutationInput!
  ) {
    courseUserParticipationsQuotaUpdate(input: $input) {
      errors {
        field
        message
      }
      courseUserParticipationQuotas {
        id
        participationsQuota
        label
        user {
          id
          displayName
          email
        }
      }
    }
  }
`;
export type CourseUserParticipationsQuotaUpdateMutationFn =
  Apollo.MutationFunction<
    CourseUserParticipationsQuotaUpdateMutation,
    CourseUserParticipationsQuotaUpdateMutationVariables
  >;

/**
 * __useCourseUserParticipationsQuotaUpdateMutation__
 *
 * To run a mutation, you first call `useCourseUserParticipationsQuotaUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseUserParticipationsQuotaUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseUserParticipationsQuotaUpdateMutation, { data, loading, error }] = useCourseUserParticipationsQuotaUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCourseUserParticipationsQuotaUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CourseUserParticipationsQuotaUpdateMutation,
    CourseUserParticipationsQuotaUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CourseUserParticipationsQuotaUpdateMutation,
    CourseUserParticipationsQuotaUpdateMutationVariables
  >(CourseUserParticipationsQuotaUpdateDocument, options);
}
export type CourseUserParticipationsQuotaUpdateMutationHookResult = ReturnType<
  typeof useCourseUserParticipationsQuotaUpdateMutation
>;
export type CourseUserParticipationsQuotaUpdateMutationResult =
  Apollo.MutationResult<CourseUserParticipationsQuotaUpdateMutation>;
export type CourseUserParticipationsQuotaUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    CourseUserParticipationsQuotaUpdateMutation,
    CourseUserParticipationsQuotaUpdateMutationVariables
  >;
export const DbtemplateUpdateDocument = gql`
  mutation dbtemplateUpdate($input: DbtemplateUpdateMutationInput!) {
    dbtemplateUpdate(input: $input) {
      template {
        content
        design
        name
        subject
        label
        preview
        getNameDisplay
      }
      errors {
        message
      }
    }
  }
`;
export type DbtemplateUpdateMutationFn = Apollo.MutationFunction<
  DbtemplateUpdateMutation,
  DbtemplateUpdateMutationVariables
>;

/**
 * __useDbtemplateUpdateMutation__
 *
 * To run a mutation, you first call `useDbtemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDbtemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dbtemplateUpdateMutation, { data, loading, error }] = useDbtemplateUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDbtemplateUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DbtemplateUpdateMutation,
    DbtemplateUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DbtemplateUpdateMutation,
    DbtemplateUpdateMutationVariables
  >(DbtemplateUpdateDocument, options);
}
export type DbtemplateUpdateMutationHookResult = ReturnType<
  typeof useDbtemplateUpdateMutation
>;
export type DbtemplateUpdateMutationResult =
  Apollo.MutationResult<DbtemplateUpdateMutation>;
export type DbtemplateUpdateMutationOptions = Apollo.BaseMutationOptions<
  DbtemplateUpdateMutation,
  DbtemplateUpdateMutationVariables
>;
export const DbtemplateCreateDocument = gql`
  mutation dbtemplateCreate($input: DbtemplateCreateMutationInput!) {
    dbtemplateCreate(input: $input) {
      template {
        content
        design
        name
        subject
        label
        preview
        getNameDisplay
        courseSet {
          edges {
            node {
              id
            }
          }
        }
      }
      errors {
        message
      }
    }
  }
`;
export type DbtemplateCreateMutationFn = Apollo.MutationFunction<
  DbtemplateCreateMutation,
  DbtemplateCreateMutationVariables
>;

/**
 * __useDbtemplateCreateMutation__
 *
 * To run a mutation, you first call `useDbtemplateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDbtemplateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dbtemplateCreateMutation, { data, loading, error }] = useDbtemplateCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDbtemplateCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DbtemplateCreateMutation,
    DbtemplateCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DbtemplateCreateMutation,
    DbtemplateCreateMutationVariables
  >(DbtemplateCreateDocument, options);
}
export type DbtemplateCreateMutationHookResult = ReturnType<
  typeof useDbtemplateCreateMutation
>;
export type DbtemplateCreateMutationResult =
  Apollo.MutationResult<DbtemplateCreateMutation>;
export type DbtemplateCreateMutationOptions = Apollo.BaseMutationOptions<
  DbtemplateCreateMutation,
  DbtemplateCreateMutationVariables
>;
export const CreateDemoCourseDocument = gql`
  mutation createDemoCourse($input: CreateDemoCourseInput!) {
    createDemoCourse(input: $input) {
      status
      courseUrl
      course {
        name
      }
    }
  }
`;
export type CreateDemoCourseMutationFn = Apollo.MutationFunction<
  CreateDemoCourseMutation,
  CreateDemoCourseMutationVariables
>;

/**
 * __useCreateDemoCourseMutation__
 *
 * To run a mutation, you first call `useCreateDemoCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDemoCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDemoCourseMutation, { data, loading, error }] = useCreateDemoCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDemoCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDemoCourseMutation,
    CreateDemoCourseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDemoCourseMutation,
    CreateDemoCourseMutationVariables
  >(CreateDemoCourseDocument, options);
}
export type CreateDemoCourseMutationHookResult = ReturnType<
  typeof useCreateDemoCourseMutation
>;
export type CreateDemoCourseMutationResult =
  Apollo.MutationResult<CreateDemoCourseMutation>;
export type CreateDemoCourseMutationOptions = Apollo.BaseMutationOptions<
  CreateDemoCourseMutation,
  CreateDemoCourseMutationVariables
>;
export const SurveyCloneDocument = gql`
  mutation surveyClone($input: SurveyCloneMutationInput!) {
    surveyClone(input: $input) {
      survey {
        id
        name
        label
      }
      errors {
        field
        message
      }
    }
  }
`;
export type SurveyCloneMutationFn = Apollo.MutationFunction<
  SurveyCloneMutation,
  SurveyCloneMutationVariables
>;

/**
 * __useSurveyCloneMutation__
 *
 * To run a mutation, you first call `useSurveyCloneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyCloneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyCloneMutation, { data, loading, error }] = useSurveyCloneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSurveyCloneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyCloneMutation,
    SurveyCloneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SurveyCloneMutation, SurveyCloneMutationVariables>(
    SurveyCloneDocument,
    options,
  );
}
export type SurveyCloneMutationHookResult = ReturnType<
  typeof useSurveyCloneMutation
>;
export type SurveyCloneMutationResult =
  Apollo.MutationResult<SurveyCloneMutation>;
export type SurveyCloneMutationOptions = Apollo.BaseMutationOptions<
  SurveyCloneMutation,
  SurveyCloneMutationVariables
>;
export const SurveyCreateDocument = gql`
  mutation surveyCreate($input: SurveyCreateMutationInput!) {
    surveyCreate(input: $input) {
      survey {
        ownership {
          name
        }
        id
        name
        label
        description
        type
        randomizeQuestions
        isPublished
        needLoggedUser
        editableAnswers
      }
      errors {
        field
        message
      }
    }
  }
`;
export type SurveyCreateMutationFn = Apollo.MutationFunction<
  SurveyCreateMutation,
  SurveyCreateMutationVariables
>;

/**
 * __useSurveyCreateMutation__
 *
 * To run a mutation, you first call `useSurveyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyCreateMutation, { data, loading, error }] = useSurveyCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSurveyCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyCreateMutation,
    SurveyCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SurveyCreateMutation,
    SurveyCreateMutationVariables
  >(SurveyCreateDocument, options);
}
export type SurveyCreateMutationHookResult = ReturnType<
  typeof useSurveyCreateMutation
>;
export type SurveyCreateMutationResult =
  Apollo.MutationResult<SurveyCreateMutation>;
export type SurveyCreateMutationOptions = Apollo.BaseMutationOptions<
  SurveyCreateMutation,
  SurveyCreateMutationVariables
>;
export const SurveyUpdateDocument = gql`
  mutation surveyUpdate($input: SurveyUpdateMutationInput!) {
    surveyUpdate(input: $input) {
      survey {
        ownership {
          name
        }
        id
        name
        label
        description
        type
        isPublished
        needLoggedUser
        editableAnswers
        randomizeQuestions
      }
      errors {
        field
        message
      }
    }
  }
`;
export type SurveyUpdateMutationFn = Apollo.MutationFunction<
  SurveyUpdateMutation,
  SurveyUpdateMutationVariables
>;

/**
 * __useSurveyUpdateMutation__
 *
 * To run a mutation, you first call `useSurveyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyUpdateMutation, { data, loading, error }] = useSurveyUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSurveyUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyUpdateMutation,
    SurveyUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SurveyUpdateMutation,
    SurveyUpdateMutationVariables
  >(SurveyUpdateDocument, options);
}
export type SurveyUpdateMutationHookResult = ReturnType<
  typeof useSurveyUpdateMutation
>;
export type SurveyUpdateMutationResult =
  Apollo.MutationResult<SurveyUpdateMutation>;
export type SurveyUpdateMutationOptions = Apollo.BaseMutationOptions<
  SurveyUpdateMutation,
  SurveyUpdateMutationVariables
>;
export const SurveyDeleteDocument = gql`
  mutation surveyDelete($input: SurveyDeleteMutationInput!) {
    surveyDelete(input: $input) {
      survey {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
export type SurveyDeleteMutationFn = Apollo.MutationFunction<
  SurveyDeleteMutation,
  SurveyDeleteMutationVariables
>;

/**
 * __useSurveyDeleteMutation__
 *
 * To run a mutation, you first call `useSurveyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyDeleteMutation, { data, loading, error }] = useSurveyDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSurveyDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyDeleteMutation,
    SurveyDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SurveyDeleteMutation,
    SurveyDeleteMutationVariables
  >(SurveyDeleteDocument, options);
}
export type SurveyDeleteMutationHookResult = ReturnType<
  typeof useSurveyDeleteMutation
>;
export type SurveyDeleteMutationResult =
  Apollo.MutationResult<SurveyDeleteMutation>;
export type SurveyDeleteMutationOptions = Apollo.BaseMutationOptions<
  SurveyDeleteMutation,
  SurveyDeleteMutationVariables
>;
export const QuestionCreateDocument = gql`
  mutation questionCreate($input: QuestionCreateMutationInput!) {
    questionCreate(input: $input) {
      question {
        ...QuestionUnionIdFragment
      }
      errors {
        field
        message
      }
    }
  }
  ${QuestionUnionIdFragmentFragmentDoc}
`;
export type QuestionCreateMutationFn = Apollo.MutationFunction<
  QuestionCreateMutation,
  QuestionCreateMutationVariables
>;

/**
 * __useQuestionCreateMutation__
 *
 * To run a mutation, you first call `useQuestionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuestionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [questionCreateMutation, { data, loading, error }] = useQuestionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuestionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QuestionCreateMutation,
    QuestionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QuestionCreateMutation,
    QuestionCreateMutationVariables
  >(QuestionCreateDocument, options);
}
export type QuestionCreateMutationHookResult = ReturnType<
  typeof useQuestionCreateMutation
>;
export type QuestionCreateMutationResult =
  Apollo.MutationResult<QuestionCreateMutation>;
export type QuestionCreateMutationOptions = Apollo.BaseMutationOptions<
  QuestionCreateMutation,
  QuestionCreateMutationVariables
>;
export const QuestionCloneDocument = gql`
  mutation questionClone($input: QuestionCloneMutationInput!) {
    questionClone(input: $input) {
      question {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
export type QuestionCloneMutationFn = Apollo.MutationFunction<
  QuestionCloneMutation,
  QuestionCloneMutationVariables
>;

/**
 * __useQuestionCloneMutation__
 *
 * To run a mutation, you first call `useQuestionCloneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuestionCloneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [questionCloneMutation, { data, loading, error }] = useQuestionCloneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuestionCloneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QuestionCloneMutation,
    QuestionCloneMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QuestionCloneMutation,
    QuestionCloneMutationVariables
  >(QuestionCloneDocument, options);
}
export type QuestionCloneMutationHookResult = ReturnType<
  typeof useQuestionCloneMutation
>;
export type QuestionCloneMutationResult =
  Apollo.MutationResult<QuestionCloneMutation>;
export type QuestionCloneMutationOptions = Apollo.BaseMutationOptions<
  QuestionCloneMutation,
  QuestionCloneMutationVariables
>;
export const QuestionUpdateDocument = gql`
  mutation questionUpdate($input: QuestionUpdateMutationInput!) {
    questionUpdate(input: $input) {
      question {
        ...QuestionUnionFragment
      }
      errors {
        field
        message
      }
    }
  }
  ${QuestionUnionFragmentFragmentDoc}
`;
export type QuestionUpdateMutationFn = Apollo.MutationFunction<
  QuestionUpdateMutation,
  QuestionUpdateMutationVariables
>;

/**
 * __useQuestionUpdateMutation__
 *
 * To run a mutation, you first call `useQuestionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuestionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [questionUpdateMutation, { data, loading, error }] = useQuestionUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuestionUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QuestionUpdateMutation,
    QuestionUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QuestionUpdateMutation,
    QuestionUpdateMutationVariables
  >(QuestionUpdateDocument, options);
}
export type QuestionUpdateMutationHookResult = ReturnType<
  typeof useQuestionUpdateMutation
>;
export type QuestionUpdateMutationResult =
  Apollo.MutationResult<QuestionUpdateMutation>;
export type QuestionUpdateMutationOptions = Apollo.BaseMutationOptions<
  QuestionUpdateMutation,
  QuestionUpdateMutationVariables
>;
export const QuestionDeleteDocument = gql`
  mutation questionDelete($input: QuestionDeleteMutationInput!) {
    questionDelete(input: $input) {
      question {
        ...QuestionUnionIdFragment
      }
      errors {
        field
        message
      }
    }
  }
  ${QuestionUnionIdFragmentFragmentDoc}
`;
export type QuestionDeleteMutationFn = Apollo.MutationFunction<
  QuestionDeleteMutation,
  QuestionDeleteMutationVariables
>;

/**
 * __useQuestionDeleteMutation__
 *
 * To run a mutation, you first call `useQuestionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuestionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [questionDeleteMutation, { data, loading, error }] = useQuestionDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuestionDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QuestionDeleteMutation,
    QuestionDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QuestionDeleteMutation,
    QuestionDeleteMutationVariables
  >(QuestionDeleteDocument, options);
}
export type QuestionDeleteMutationHookResult = ReturnType<
  typeof useQuestionDeleteMutation
>;
export type QuestionDeleteMutationResult =
  Apollo.MutationResult<QuestionDeleteMutation>;
export type QuestionDeleteMutationOptions = Apollo.BaseMutationOptions<
  QuestionDeleteMutation,
  QuestionDeleteMutationVariables
>;
export const UpdateBirthDayDocument = gql`
  mutation updateBirthDay($input: MeUpdateMutationInput!) {
    meUpdate(input: $input) {
      user {
        id
        profile {
          id
          birthDay
        }
      }
    }
  }
`;
export type UpdateBirthDayMutationFn = Apollo.MutationFunction<
  UpdateBirthDayMutation,
  UpdateBirthDayMutationVariables
>;

/**
 * __useUpdateBirthDayMutation__
 *
 * To run a mutation, you first call `useUpdateBirthDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBirthDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBirthDayMutation, { data, loading, error }] = useUpdateBirthDayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBirthDayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBirthDayMutation,
    UpdateBirthDayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBirthDayMutation,
    UpdateBirthDayMutationVariables
  >(UpdateBirthDayDocument, options);
}
export type UpdateBirthDayMutationHookResult = ReturnType<
  typeof useUpdateBirthDayMutation
>;
export type UpdateBirthDayMutationResult =
  Apollo.MutationResult<UpdateBirthDayMutation>;
export type UpdateBirthDayMutationOptions = Apollo.BaseMutationOptions<
  UpdateBirthDayMutation,
  UpdateBirthDayMutationVariables
>;
export const UpdateMeDocument = gql`
  mutation updateMe($input: MeUpdateMutationInput!) {
    meUpdate(input: $input) {
      user {
        id
        firstName
        lastName
        profile {
          id
          birthPlace {
            cityState
          }
          birthDay
          gender
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
export type UpdateMeMutationFn = Apollo.MutationFunction<
  UpdateMeMutation,
  UpdateMeMutationVariables
>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMeMutation,
    UpdateMeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(
    UpdateMeDocument,
    options,
  );
}
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<
  UpdateMeMutation,
  UpdateMeMutationVariables
>;
export const AvatarCreateDocument = gql`
  mutation avatarCreate($input: AvatarCreateMutationInput!) {
    avatarCreate(input: $input) {
      avatar {
        user {
          id
        }
        primary
        dateUploaded
        avatar {
          url
          name
          size
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
export type AvatarCreateMutationFn = Apollo.MutationFunction<
  AvatarCreateMutation,
  AvatarCreateMutationVariables
>;

/**
 * __useAvatarCreateMutation__
 *
 * To run a mutation, you first call `useAvatarCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvatarCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [avatarCreateMutation, { data, loading, error }] = useAvatarCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvatarCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AvatarCreateMutation,
    AvatarCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AvatarCreateMutation,
    AvatarCreateMutationVariables
  >(AvatarCreateDocument, options);
}
export type AvatarCreateMutationHookResult = ReturnType<
  typeof useAvatarCreateMutation
>;
export type AvatarCreateMutationResult =
  Apollo.MutationResult<AvatarCreateMutation>;
export type AvatarCreateMutationOptions = Apollo.BaseMutationOptions<
  AvatarCreateMutation,
  AvatarCreateMutationVariables
>;
export const PasswordResetDocument = gql`
  mutation passwordReset($input: PasswordResetInput!) {
    passwordReset(input: $input) {
      sended
    }
  }
`;
export type PasswordResetMutationFn = Apollo.MutationFunction<
  PasswordResetMutation,
  PasswordResetMutationVariables
>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordResetMutation,
    PasswordResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PasswordResetMutation,
    PasswordResetMutationVariables
  >(PasswordResetDocument, options);
}
export type PasswordResetMutationHookResult = ReturnType<
  typeof usePasswordResetMutation
>;
export type PasswordResetMutationResult =
  Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<
  PasswordResetMutation,
  PasswordResetMutationVariables
>;
export const PasswordChangeDocument = gql`
  mutation passwordChange($input: PasswordChangeInput!) {
    passwordChange(input: $input) {
      errors {
        field
        message
      }
      success
    }
  }
`;
export type PasswordChangeMutationFn = Apollo.MutationFunction<
  PasswordChangeMutation,
  PasswordChangeMutationVariables
>;

/**
 * __usePasswordChangeMutation__
 *
 * To run a mutation, you first call `usePasswordChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordChangeMutation, { data, loading, error }] = usePasswordChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePasswordChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PasswordChangeMutation,
    PasswordChangeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PasswordChangeMutation,
    PasswordChangeMutationVariables
  >(PasswordChangeDocument, options);
}
export type PasswordChangeMutationHookResult = ReturnType<
  typeof usePasswordChangeMutation
>;
export type PasswordChangeMutationResult =
  Apollo.MutationResult<PasswordChangeMutation>;
export type PasswordChangeMutationOptions = Apollo.BaseMutationOptions<
  PasswordChangeMutation,
  PasswordChangeMutationVariables
>;
export const LogInDocument = gql`
  mutation logIn($input: LogInInput!) {
    logIn(input: $input) {
      user {
        id
        avatar(size: 40)
        firstName
        lastName
        isAnonymous
        isActive
      }
    }
  }
`;
export type LogInMutationFn = Apollo.MutationFunction<
  LogInMutation,
  LogInMutationVariables
>;

/**
 * __useLogInMutation__
 *
 * To run a mutation, you first call `useLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInMutation, { data, loading, error }] = useLogInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogInMutation,
    LogInMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogInMutation, LogInMutationVariables>(
    LogInDocument,
    options,
  );
}
export type LogInMutationHookResult = ReturnType<typeof useLogInMutation>;
export type LogInMutationResult = Apollo.MutationResult<LogInMutation>;
export type LogInMutationOptions = Apollo.BaseMutationOptions<
  LogInMutation,
  LogInMutationVariables
>;
export const LogOutDocument = gql`
  mutation logOut {
    logOut {
      message
      ok
      errors {
        field
        message
      }
    }
  }
`;
export type LogOutMutationFn = Apollo.MutationFunction<
  LogOutMutation,
  LogOutMutationVariables
>;

/**
 * __useLogOutMutation__
 *
 * To run a mutation, you first call `useLogOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOutMutation, { data, loading, error }] = useLogOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogOutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogOutMutation,
    LogOutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogOutMutation, LogOutMutationVariables>(
    LogOutDocument,
    options,
  );
}
export type LogOutMutationHookResult = ReturnType<typeof useLogOutMutation>;
export type LogOutMutationResult = Apollo.MutationResult<LogOutMutation>;
export type LogOutMutationOptions = Apollo.BaseMutationOptions<
  LogOutMutation,
  LogOutMutationVariables
>;
export const UserRequestDeactivationDocument = gql`
  mutation UserRequestDeactivation(
    $input: UserRequestDeactivationMutationInput!
  ) {
    userRequestDeactivation(input: $input) {
      requestDeactivationSent
      errors {
        field
        message
      }
    }
  }
`;
export type UserRequestDeactivationMutationFn = Apollo.MutationFunction<
  UserRequestDeactivationMutation,
  UserRequestDeactivationMutationVariables
>;

/**
 * __useUserRequestDeactivationMutation__
 *
 * To run a mutation, you first call `useUserRequestDeactivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRequestDeactivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRequestDeactivationMutation, { data, loading, error }] = useUserRequestDeactivationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserRequestDeactivationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserRequestDeactivationMutation,
    UserRequestDeactivationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserRequestDeactivationMutation,
    UserRequestDeactivationMutationVariables
  >(UserRequestDeactivationDocument, options);
}
export type UserRequestDeactivationMutationHookResult = ReturnType<
  typeof useUserRequestDeactivationMutation
>;
export type UserRequestDeactivationMutationResult =
  Apollo.MutationResult<UserRequestDeactivationMutation>;
export type UserRequestDeactivationMutationOptions = Apollo.BaseMutationOptions<
  UserRequestDeactivationMutation,
  UserRequestDeactivationMutationVariables
>;
export const UserDeactivateDocument = gql`
  mutation UserDeactivate($input: UserDeactivateMutationInput!) {
    userDeactivate(input: $input) {
      user {
        isActive
      }
      errors {
        field
        message
      }
    }
  }
`;
export type UserDeactivateMutationFn = Apollo.MutationFunction<
  UserDeactivateMutation,
  UserDeactivateMutationVariables
>;

/**
 * __useUserDeactivateMutation__
 *
 * To run a mutation, you first call `useUserDeactivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeactivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeactivateMutation, { data, loading, error }] = useUserDeactivateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserDeactivateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserDeactivateMutation,
    UserDeactivateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UserDeactivateMutation,
    UserDeactivateMutationVariables
  >(UserDeactivateDocument, options);
}
export type UserDeactivateMutationHookResult = ReturnType<
  typeof useUserDeactivateMutation
>;
export type UserDeactivateMutationResult =
  Apollo.MutationResult<UserDeactivateMutation>;
export type UserDeactivateMutationOptions = Apollo.BaseMutationOptions<
  UserDeactivateMutation,
  UserDeactivateMutationVariables
>;
export const VerificationCodeMatchMutationDocument = gql`
  mutation verificationCodeMatchMutation($input: VerificationCodeMatchInput!) {
    verificationCodeMatch(input: $input) {
      match
      verificationLink
    }
  }
`;
export type VerificationCodeMatchMutationMutationFn = Apollo.MutationFunction<
  VerificationCodeMatchMutationMutation,
  VerificationCodeMatchMutationMutationVariables
>;

/**
 * __useVerificationCodeMatchMutationMutation__
 *
 * To run a mutation, you first call `useVerificationCodeMatchMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerificationCodeMatchMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verificationCodeMatchMutationMutation, { data, loading, error }] = useVerificationCodeMatchMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerificationCodeMatchMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerificationCodeMatchMutationMutation,
    VerificationCodeMatchMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerificationCodeMatchMutationMutation,
    VerificationCodeMatchMutationMutationVariables
  >(VerificationCodeMatchMutationDocument, options);
}
export type VerificationCodeMatchMutationMutationHookResult = ReturnType<
  typeof useVerificationCodeMatchMutationMutation
>;
export type VerificationCodeMatchMutationMutationResult =
  Apollo.MutationResult<VerificationCodeMatchMutationMutation>;
export type VerificationCodeMatchMutationMutationOptions =
  Apollo.BaseMutationOptions<
    VerificationCodeMatchMutationMutation,
    VerificationCodeMatchMutationMutationVariables
  >;
export const AllCompaniesDocument = gql`
  query allCompanies {
    allCompanies {
      totalCount
      edges {
        node {
          id
          name
          slug
          frontendUrl
          courses {
            totalCount
          }
          getCategoryDisplay
          defaultTheme {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useAllCompaniesQuery__
 *
 * To run a query within a React component, call `useAllCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllCompaniesQuery,
    AllCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllCompaniesQuery, AllCompaniesQueryVariables>(
    AllCompaniesDocument,
    options,
  );
}
export function useAllCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCompaniesQuery,
    AllCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllCompaniesQuery, AllCompaniesQueryVariables>(
    AllCompaniesDocument,
    options,
  );
}
export type AllCompaniesQueryHookResult = ReturnType<
  typeof useAllCompaniesQuery
>;
export type AllCompaniesLazyQueryHookResult = ReturnType<
  typeof useAllCompaniesLazyQuery
>;
export type AllCompaniesQueryResult = Apollo.QueryResult<
  AllCompaniesQuery,
  AllCompaniesQueryVariables
>;
export const CategoryCreateDocument = gql`
  mutation categoryCreate($input: CategoryCreateMutationInput!) {
    categoryCreate(input: $input) {
      courseCategory {
        name
        description
        order
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CategoryCreateMutationFn = Apollo.MutationFunction<
  CategoryCreateMutation,
  CategoryCreateMutationVariables
>;

/**
 * __useCategoryCreateMutation__
 *
 * To run a mutation, you first call `useCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [categoryCreateMutation, { data, loading, error }] = useCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCategoryCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CategoryCreateMutation,
    CategoryCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CategoryCreateMutation,
    CategoryCreateMutationVariables
  >(CategoryCreateDocument, options);
}
export type CategoryCreateMutationHookResult = ReturnType<
  typeof useCategoryCreateMutation
>;
export type CategoryCreateMutationResult =
  Apollo.MutationResult<CategoryCreateMutation>;
export type CategoryCreateMutationOptions = Apollo.BaseMutationOptions<
  CategoryCreateMutation,
  CategoryCreateMutationVariables
>;
export const CategoryUpdateDocument = gql`
  mutation categoryUpdate($input: CategoryUpdateMutationInput!) {
    categoryUpdate(input: $input) {
      courseCategory {
        name
        description
        order
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CategoryUpdateMutationFn = Apollo.MutationFunction<
  CategoryUpdateMutation,
  CategoryUpdateMutationVariables
>;

/**
 * __useCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [categoryUpdateMutation, { data, loading, error }] = useCategoryUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCategoryUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CategoryUpdateMutation,
    CategoryUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CategoryUpdateMutation,
    CategoryUpdateMutationVariables
  >(CategoryUpdateDocument, options);
}
export type CategoryUpdateMutationHookResult = ReturnType<
  typeof useCategoryUpdateMutation
>;
export type CategoryUpdateMutationResult =
  Apollo.MutationResult<CategoryUpdateMutation>;
export type CategoryUpdateMutationOptions = Apollo.BaseMutationOptions<
  CategoryUpdateMutation,
  CategoryUpdateMutationVariables
>;
export const CategoryDeleteDocument = gql`
  mutation categoryDelete($input: CategoryDeleteMutationInput!) {
    categoryDelete(input: $input) {
      courseCategory {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CategoryDeleteMutationFn = Apollo.MutationFunction<
  CategoryDeleteMutation,
  CategoryDeleteMutationVariables
>;

/**
 * __useCategoryDeleteMutation__
 *
 * To run a mutation, you first call `useCategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [categoryDeleteMutation, { data, loading, error }] = useCategoryDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCategoryDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CategoryDeleteMutation,
    CategoryDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CategoryDeleteMutation,
    CategoryDeleteMutationVariables
  >(CategoryDeleteDocument, options);
}
export type CategoryDeleteMutationHookResult = ReturnType<
  typeof useCategoryDeleteMutation
>;
export type CategoryDeleteMutationResult =
  Apollo.MutationResult<CategoryDeleteMutation>;
export type CategoryDeleteMutationOptions = Apollo.BaseMutationOptions<
  CategoryDeleteMutation,
  CategoryDeleteMutationVariables
>;
export const CategoryDocument = gql`
  query category($id: ID!) {
    category(id: $id) {
      id
      name
      description
    }
  }
`;

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(
    CategoryDocument,
    options,
  );
}
export function useCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CategoryQuery,
    CategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(
    CategoryDocument,
    options,
  );
}
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<
  typeof useCategoryLazyQuery
>;
export type CategoryQueryResult = Apollo.QueryResult<
  CategoryQuery,
  CategoryQueryVariables
>;
export const GetRoomPresenceDocument = gql`
  query getRoomPresence($channelName: ID!, $withUsers: Boolean!) {
    roomPresence(channelName: $channelName) {
      id
      uuid
      channelName
      users {
        totalCount
        edges @include(if: $withUsers) {
          node {
            id
            displayName
            avatar
          }
        }
      }
    }
  }
`;

/**
 * __useGetRoomPresenceQuery__
 *
 * To run a query within a React component, call `useGetRoomPresenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomPresenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomPresenceQuery({
 *   variables: {
 *      channelName: // value for 'channelName'
 *      withUsers: // value for 'withUsers'
 *   },
 * });
 */
export function useGetRoomPresenceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoomPresenceQuery,
    GetRoomPresenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoomPresenceQuery, GetRoomPresenceQueryVariables>(
    GetRoomPresenceDocument,
    options,
  );
}
export function useGetRoomPresenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoomPresenceQuery,
    GetRoomPresenceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRoomPresenceQuery,
    GetRoomPresenceQueryVariables
  >(GetRoomPresenceDocument, options);
}
export type GetRoomPresenceQueryHookResult = ReturnType<
  typeof useGetRoomPresenceQuery
>;
export type GetRoomPresenceLazyQueryHookResult = ReturnType<
  typeof useGetRoomPresenceLazyQuery
>;
export type GetRoomPresenceQueryResult = Apollo.QueryResult<
  GetRoomPresenceQuery,
  GetRoomPresenceQueryVariables
>;
export const GetRoomPresenceGraphDocument = gql`
  query getRoomPresenceGraph(
    $channelName: ID!
    $start: DateTime
    $end: DateTime
    $resolution: Int
  ) {
    roomPresence(channelName: $channelName) {
      id
      uuid
      channelName
      users {
        totalCount
      }
      graphs(start: $start, end: $end, resolution: $resolution)
    }
  }
`;

/**
 * __useGetRoomPresenceGraphQuery__
 *
 * To run a query within a React component, call `useGetRoomPresenceGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomPresenceGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomPresenceGraphQuery({
 *   variables: {
 *      channelName: // value for 'channelName'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      resolution: // value for 'resolution'
 *   },
 * });
 */
export function useGetRoomPresenceGraphQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoomPresenceGraphQuery,
    GetRoomPresenceGraphQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRoomPresenceGraphQuery,
    GetRoomPresenceGraphQueryVariables
  >(GetRoomPresenceGraphDocument, options);
}
export function useGetRoomPresenceGraphLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoomPresenceGraphQuery,
    GetRoomPresenceGraphQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRoomPresenceGraphQuery,
    GetRoomPresenceGraphQueryVariables
  >(GetRoomPresenceGraphDocument, options);
}
export type GetRoomPresenceGraphQueryHookResult = ReturnType<
  typeof useGetRoomPresenceGraphQuery
>;
export type GetRoomPresenceGraphLazyQueryHookResult = ReturnType<
  typeof useGetRoomPresenceGraphLazyQuery
>;
export type GetRoomPresenceGraphQueryResult = Apollo.QueryResult<
  GetRoomPresenceGraphQuery,
  GetRoomPresenceGraphQueryVariables
>;
export const GetPresenceOfOuserDocument = gql`
  query getPresenceOfOuser($roomName: ID!, $userId: ID!) {
    presencesOfUser(roomName: $roomName, userId: $userId) {
      totalCount
      edges {
        node {
          id
          channelName
          firstSeen
          lastSeen
          onLine
        }
      }
    }
  }
`;

/**
 * __useGetPresenceOfOuserQuery__
 *
 * To run a query within a React component, call `useGetPresenceOfOuserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresenceOfOuserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresenceOfOuserQuery({
 *   variables: {
 *      roomName: // value for 'roomName'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPresenceOfOuserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPresenceOfOuserQuery,
    GetPresenceOfOuserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPresenceOfOuserQuery,
    GetPresenceOfOuserQueryVariables
  >(GetPresenceOfOuserDocument, options);
}
export function useGetPresenceOfOuserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPresenceOfOuserQuery,
    GetPresenceOfOuserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPresenceOfOuserQuery,
    GetPresenceOfOuserQueryVariables
  >(GetPresenceOfOuserDocument, options);
}
export type GetPresenceOfOuserQueryHookResult = ReturnType<
  typeof useGetPresenceOfOuserQuery
>;
export type GetPresenceOfOuserLazyQueryHookResult = ReturnType<
  typeof useGetPresenceOfOuserLazyQuery
>;
export type GetPresenceOfOuserQueryResult = Apollo.QueryResult<
  GetPresenceOfOuserQuery,
  GetPresenceOfOuserQueryVariables
>;
export const GetChatTokenDocument = gql`
  query getChatToken {
    getChatToken {
      token
      id
    }
  }
`;

/**
 * __useGetChatTokenQuery__
 *
 * To run a query within a React component, call `useGetChatTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChatTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChatTokenQuery,
    GetChatTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatTokenQuery, GetChatTokenQueryVariables>(
    GetChatTokenDocument,
    options,
  );
}
export function useGetChatTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChatTokenQuery,
    GetChatTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatTokenQuery, GetChatTokenQueryVariables>(
    GetChatTokenDocument,
    options,
  );
}
export type GetChatTokenQueryHookResult = ReturnType<
  typeof useGetChatTokenQuery
>;
export type GetChatTokenLazyQueryHookResult = ReturnType<
  typeof useGetChatTokenLazyQuery
>;
export type GetChatTokenQueryResult = Apollo.QueryResult<
  GetChatTokenQuery,
  GetChatTokenQueryVariables
>;
export const GetCompanyDocument = gql`
  query getCompany($id: ID!) {
    company(id: $id) {
      ...company
    }
  }
  ${CompanyFragmentDoc}
`;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyQuery,
    GetCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(
    GetCompanyDocument,
    options,
  );
}
export function useGetCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyQuery,
    GetCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(
    GetCompanyDocument,
    options,
  );
}
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<
  typeof useGetCompanyLazyQuery
>;
export type GetCompanyQueryResult = Apollo.QueryResult<
  GetCompanyQuery,
  GetCompanyQueryVariables
>;
export const GetCompanyDefaultValueDocument = gql`
  query getCompanyDefaultValue($id: ID!) {
    company(id: $id) {
      name
      id
    }
  }
`;

/**
 * __useGetCompanyDefaultValueQuery__
 *
 * To run a query within a React component, call `useGetCompanyDefaultValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDefaultValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDefaultValueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyDefaultValueQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyDefaultValueQuery,
    GetCompanyDefaultValueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyDefaultValueQuery,
    GetCompanyDefaultValueQueryVariables
  >(GetCompanyDefaultValueDocument, options);
}
export function useGetCompanyDefaultValueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyDefaultValueQuery,
    GetCompanyDefaultValueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyDefaultValueQuery,
    GetCompanyDefaultValueQueryVariables
  >(GetCompanyDefaultValueDocument, options);
}
export type GetCompanyDefaultValueQueryHookResult = ReturnType<
  typeof useGetCompanyDefaultValueQuery
>;
export type GetCompanyDefaultValueLazyQueryHookResult = ReturnType<
  typeof useGetCompanyDefaultValueLazyQuery
>;
export type GetCompanyDefaultValueQueryResult = Apollo.QueryResult<
  GetCompanyDefaultValueQuery,
  GetCompanyDefaultValueQueryVariables
>;
export const CompanyHomeJsxUpdateDocument = gql`
  mutation companyHomeJsxUpdate($input: CompanyUpdateMutationInput!) {
    companyUpdate(input: $input) {
      clientMutationId
      company {
        homeJsxContent
      }
    }
  }
`;
export type CompanyHomeJsxUpdateMutationFn = Apollo.MutationFunction<
  CompanyHomeJsxUpdateMutation,
  CompanyHomeJsxUpdateMutationVariables
>;

/**
 * __useCompanyHomeJsxUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyHomeJsxUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyHomeJsxUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyHomeJsxUpdateMutation, { data, loading, error }] = useCompanyHomeJsxUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyHomeJsxUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyHomeJsxUpdateMutation,
    CompanyHomeJsxUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyHomeJsxUpdateMutation,
    CompanyHomeJsxUpdateMutationVariables
  >(CompanyHomeJsxUpdateDocument, options);
}
export type CompanyHomeJsxUpdateMutationHookResult = ReturnType<
  typeof useCompanyHomeJsxUpdateMutation
>;
export type CompanyHomeJsxUpdateMutationResult =
  Apollo.MutationResult<CompanyHomeJsxUpdateMutation>;
export type CompanyHomeJsxUpdateMutationOptions = Apollo.BaseMutationOptions<
  CompanyHomeJsxUpdateMutation,
  CompanyHomeJsxUpdateMutationVariables
>;
export const CompanyFooterJsxUpdateDocument = gql`
  mutation companyFooterJsxUpdate($input: CompanyUpdateMutationInput!) {
    companyUpdate(input: $input) {
      clientMutationId
      company {
        footerJsxContent
      }
    }
  }
`;
export type CompanyFooterJsxUpdateMutationFn = Apollo.MutationFunction<
  CompanyFooterJsxUpdateMutation,
  CompanyFooterJsxUpdateMutationVariables
>;

/**
 * __useCompanyFooterJsxUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyFooterJsxUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyFooterJsxUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyFooterJsxUpdateMutation, { data, loading, error }] = useCompanyFooterJsxUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyFooterJsxUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyFooterJsxUpdateMutation,
    CompanyFooterJsxUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyFooterJsxUpdateMutation,
    CompanyFooterJsxUpdateMutationVariables
  >(CompanyFooterJsxUpdateDocument, options);
}
export type CompanyFooterJsxUpdateMutationHookResult = ReturnType<
  typeof useCompanyFooterJsxUpdateMutation
>;
export type CompanyFooterJsxUpdateMutationResult =
  Apollo.MutationResult<CompanyFooterJsxUpdateMutation>;
export type CompanyFooterJsxUpdateMutationOptions = Apollo.BaseMutationOptions<
  CompanyFooterJsxUpdateMutation,
  CompanyFooterJsxUpdateMutationVariables
>;
export const CompanyUpdateDocument = gql`
  mutation companyUpdate($input: CompanyUpdateMutationInput!) {
    companyUpdate(input: $input) {
      clientMutationId
      company {
        ...company
      }
    }
  }
  ${CompanyFragmentDoc}
`;
export type CompanyUpdateMutationFn = Apollo.MutationFunction<
  CompanyUpdateMutation,
  CompanyUpdateMutationVariables
>;

/**
 * __useCompanyUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUpdateMutation, { data, loading, error }] = useCompanyUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyUpdateMutation,
    CompanyUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyUpdateMutation,
    CompanyUpdateMutationVariables
  >(CompanyUpdateDocument, options);
}
export type CompanyUpdateMutationHookResult = ReturnType<
  typeof useCompanyUpdateMutation
>;
export type CompanyUpdateMutationResult =
  Apollo.MutationResult<CompanyUpdateMutation>;
export type CompanyUpdateMutationOptions = Apollo.BaseMutationOptions<
  CompanyUpdateMutation,
  CompanyUpdateMutationVariables
>;
export const CompanyDeleteDocument = gql`
  mutation companyDelete($input: CompanyDeleteMutationInput!) {
    companyDelete(input: $input) {
      clientMutationId
      company {
        id
        name
      }
    }
  }
`;
export type CompanyDeleteMutationFn = Apollo.MutationFunction<
  CompanyDeleteMutation,
  CompanyDeleteMutationVariables
>;

/**
 * __useCompanyDeleteMutation__
 *
 * To run a mutation, you first call `useCompanyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyDeleteMutation, { data, loading, error }] = useCompanyDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyDeleteMutation,
    CompanyDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyDeleteMutation,
    CompanyDeleteMutationVariables
  >(CompanyDeleteDocument, options);
}
export type CompanyDeleteMutationHookResult = ReturnType<
  typeof useCompanyDeleteMutation
>;
export type CompanyDeleteMutationResult =
  Apollo.MutationResult<CompanyDeleteMutation>;
export type CompanyDeleteMutationOptions = Apollo.BaseMutationOptions<
  CompanyDeleteMutation,
  CompanyDeleteMutationVariables
>;
export const CompanyCreateDocument = gql`
  mutation companyCreate($input: CompanyCreateMutationInput!) {
    companyCreate(input: $input) {
      clientMutationId
      company {
        ...company
      }
    }
  }
  ${CompanyFragmentDoc}
`;
export type CompanyCreateMutationFn = Apollo.MutationFunction<
  CompanyCreateMutation,
  CompanyCreateMutationVariables
>;

/**
 * __useCompanyCreateMutation__
 *
 * To run a mutation, you first call `useCompanyCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyCreateMutation, { data, loading, error }] = useCompanyCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyCreateMutation,
    CompanyCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyCreateMutation,
    CompanyCreateMutationVariables
  >(CompanyCreateDocument, options);
}
export type CompanyCreateMutationHookResult = ReturnType<
  typeof useCompanyCreateMutation
>;
export type CompanyCreateMutationResult =
  Apollo.MutationResult<CompanyCreateMutation>;
export type CompanyCreateMutationOptions = Apollo.BaseMutationOptions<
  CompanyCreateMutation,
  CompanyCreateMutationVariables
>;
export const GetCompanyPrivateDocument = gql`
  query getCompanyPrivate($id: ID!) {
    company(id: $id) {
      id
      name
      slug
      homeJsxContent
      footerJsxContent
      frontendUrl
      defaultLanguage
      courses {
        totalCount
      }
      defaultTheme {
        id
        enableInDashboard
      }
      baseLogo {
        name
        url
        thumb160: thumbnail(
          width: 160
          height: 160
          padding: true
          format: PNG
        ) {
          url
        }
      }
    }
  }
`;

/**
 * __useGetCompanyPrivateQuery__
 *
 * To run a query within a React component, call `useGetCompanyPrivateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPrivateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPrivateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyPrivateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyPrivateQuery,
    GetCompanyPrivateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPrivateQuery,
    GetCompanyPrivateQueryVariables
  >(GetCompanyPrivateDocument, options);
}
export function useGetCompanyPrivateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPrivateQuery,
    GetCompanyPrivateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPrivateQuery,
    GetCompanyPrivateQueryVariables
  >(GetCompanyPrivateDocument, options);
}
export type GetCompanyPrivateQueryHookResult = ReturnType<
  typeof useGetCompanyPrivateQuery
>;
export type GetCompanyPrivateLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPrivateLazyQuery
>;
export type GetCompanyPrivateQueryResult = Apollo.QueryResult<
  GetCompanyPrivateQuery,
  GetCompanyPrivateQueryVariables
>;
export const GetCompanyPrivateCategoriesDocument = gql`
  query getCompanyPrivateCategories($id: ID!) {
    company(id: $id) {
      categories {
        edges {
          node {
            id
            name
            description
            order
            courses {
              edges {
                node {
                  id
                  name
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCompanyPrivateCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCompanyPrivateCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyPrivateCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyPrivateCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyPrivateCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyPrivateCategoriesQuery,
    GetCompanyPrivateCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyPrivateCategoriesQuery,
    GetCompanyPrivateCategoriesQueryVariables
  >(GetCompanyPrivateCategoriesDocument, options);
}
export function useGetCompanyPrivateCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyPrivateCategoriesQuery,
    GetCompanyPrivateCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyPrivateCategoriesQuery,
    GetCompanyPrivateCategoriesQueryVariables
  >(GetCompanyPrivateCategoriesDocument, options);
}
export type GetCompanyPrivateCategoriesQueryHookResult = ReturnType<
  typeof useGetCompanyPrivateCategoriesQuery
>;
export type GetCompanyPrivateCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyPrivateCategoriesLazyQuery
>;
export type GetCompanyPrivateCategoriesQueryResult = Apollo.QueryResult<
  GetCompanyPrivateCategoriesQuery,
  GetCompanyPrivateCategoriesQueryVariables
>;
export const CompanyActiveUsersDocument = gql`
  query companyActiveUsers($id: ID!) {
    company(id: $id) {
      id
      activeUsersMonthGroups {
        month
        count
      }
    }
  }
`;

/**
 * __useCompanyActiveUsersQuery__
 *
 * To run a query within a React component, call `useCompanyActiveUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyActiveUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyActiveUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyActiveUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyActiveUsersQuery,
    CompanyActiveUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyActiveUsersQuery,
    CompanyActiveUsersQueryVariables
  >(CompanyActiveUsersDocument, options);
}
export function useCompanyActiveUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyActiveUsersQuery,
    CompanyActiveUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyActiveUsersQuery,
    CompanyActiveUsersQueryVariables
  >(CompanyActiveUsersDocument, options);
}
export type CompanyActiveUsersQueryHookResult = ReturnType<
  typeof useCompanyActiveUsersQuery
>;
export type CompanyActiveUsersLazyQueryHookResult = ReturnType<
  typeof useCompanyActiveUsersLazyQuery
>;
export type CompanyActiveUsersQueryResult = Apollo.QueryResult<
  CompanyActiveUsersQuery,
  CompanyActiveUsersQueryVariables
>;
export const CompanyParticipationsMonthGroupCounterDocument = gql`
  query companyParticipationsMonthGroupCounter($companyId: ID!) {
    allCompanyParticipations(companyId: $companyId) {
      totalCount
      monthGroupCount {
        month
        count
      }
    }
  }
`;

/**
 * __useCompanyParticipationsMonthGroupCounterQuery__
 *
 * To run a query within a React component, call `useCompanyParticipationsMonthGroupCounterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyParticipationsMonthGroupCounterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyParticipationsMonthGroupCounterQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyParticipationsMonthGroupCounterQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyParticipationsMonthGroupCounterQuery,
    CompanyParticipationsMonthGroupCounterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CompanyParticipationsMonthGroupCounterQuery,
    CompanyParticipationsMonthGroupCounterQueryVariables
  >(CompanyParticipationsMonthGroupCounterDocument, options);
}
export function useCompanyParticipationsMonthGroupCounterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyParticipationsMonthGroupCounterQuery,
    CompanyParticipationsMonthGroupCounterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CompanyParticipationsMonthGroupCounterQuery,
    CompanyParticipationsMonthGroupCounterQueryVariables
  >(CompanyParticipationsMonthGroupCounterDocument, options);
}
export type CompanyParticipationsMonthGroupCounterQueryHookResult = ReturnType<
  typeof useCompanyParticipationsMonthGroupCounterQuery
>;
export type CompanyParticipationsMonthGroupCounterLazyQueryHookResult =
  ReturnType<typeof useCompanyParticipationsMonthGroupCounterLazyQuery>;
export type CompanyParticipationsMonthGroupCounterQueryResult =
  Apollo.QueryResult<
    CompanyParticipationsMonthGroupCounterQuery,
    CompanyParticipationsMonthGroupCounterQueryVariables
  >;
export const PublicCompanyBySlugDocument = gql`
  query publicCompanyBySlug($slug: String!) {
    publicCompanyBySlug(slug: $slug) {
      ...publicCompanyCustomizationData
    }
  }
  ${PublicCompanyCustomizationDataFragmentDoc}
`;

/**
 * __usePublicCompanyBySlugQuery__
 *
 * To run a query within a React component, call `usePublicCompanyBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicCompanyBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicCompanyBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicCompanyBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublicCompanyBySlugQuery,
    PublicCompanyBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PublicCompanyBySlugQuery,
    PublicCompanyBySlugQueryVariables
  >(PublicCompanyBySlugDocument, options);
}
export function usePublicCompanyBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicCompanyBySlugQuery,
    PublicCompanyBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PublicCompanyBySlugQuery,
    PublicCompanyBySlugQueryVariables
  >(PublicCompanyBySlugDocument, options);
}
export type PublicCompanyBySlugQueryHookResult = ReturnType<
  typeof usePublicCompanyBySlugQuery
>;
export type PublicCompanyBySlugLazyQueryHookResult = ReturnType<
  typeof usePublicCompanyBySlugLazyQuery
>;
export type PublicCompanyBySlugQueryResult = Apollo.QueryResult<
  PublicCompanyBySlugQuery,
  PublicCompanyBySlugQueryVariables
>;
export const AllCompanySurveysDocument = gql`
  query allCompanySurveys(
    $companyId: ID!
    $search: String
    $type_In: [SurveyType]
  ) {
    allCompanySurveys(
      companyId: $companyId
      search: $search
      type_In: $type_In
    ) {
      totalCount
      edges {
        node {
          id
          name
          getTypeDisplay
          type
          label
          questions {
            totalCount
          }
        }
      }
    }
  }
`;

/**
 * __useAllCompanySurveysQuery__
 *
 * To run a query within a React component, call `useAllCompanySurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompanySurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompanySurveysQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      search: // value for 'search'
 *      type_In: // value for 'type_In'
 *   },
 * });
 */
export function useAllCompanySurveysQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllCompanySurveysQuery,
    AllCompanySurveysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllCompanySurveysQuery,
    AllCompanySurveysQueryVariables
  >(AllCompanySurveysDocument, options);
}
export function useAllCompanySurveysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCompanySurveysQuery,
    AllCompanySurveysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllCompanySurveysQuery,
    AllCompanySurveysQueryVariables
  >(AllCompanySurveysDocument, options);
}
export type AllCompanySurveysQueryHookResult = ReturnType<
  typeof useAllCompanySurveysQuery
>;
export type AllCompanySurveysLazyQueryHookResult = ReturnType<
  typeof useAllCompanySurveysLazyQuery
>;
export type AllCompanySurveysQueryResult = Apollo.QueryResult<
  AllCompanySurveysQuery,
  AllCompanySurveysQueryVariables
>;
export const GetCompanyBillingDocument = gql`
  query getCompanyBilling($id: ID!) {
    company(id: $id) {
      ...companyBillingData
    }
  }
  ${CompanyBillingDataFragmentDoc}
`;

/**
 * __useGetCompanyBillingQuery__
 *
 * To run a query within a React component, call `useGetCompanyBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyBillingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyBillingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyBillingQuery,
    GetCompanyBillingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyBillingQuery,
    GetCompanyBillingQueryVariables
  >(GetCompanyBillingDocument, options);
}
export function useGetCompanyBillingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyBillingQuery,
    GetCompanyBillingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyBillingQuery,
    GetCompanyBillingQueryVariables
  >(GetCompanyBillingDocument, options);
}
export type GetCompanyBillingQueryHookResult = ReturnType<
  typeof useGetCompanyBillingQuery
>;
export type GetCompanyBillingLazyQueryHookResult = ReturnType<
  typeof useGetCompanyBillingLazyQuery
>;
export type GetCompanyBillingQueryResult = Apollo.QueryResult<
  GetCompanyBillingQuery,
  GetCompanyBillingQueryVariables
>;
export const GetCompanyAddressDocument = gql`
  query getCompanyAddress($id: ID!) {
    company(id: $id) {
      ...companyAddressData
    }
  }
  ${CompanyAddressDataFragmentDoc}
`;

/**
 * __useGetCompanyAddressQuery__
 *
 * To run a query within a React component, call `useGetCompanyAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyAddressQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyAddressQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyAddressQuery,
    GetCompanyAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyAddressQuery,
    GetCompanyAddressQueryVariables
  >(GetCompanyAddressDocument, options);
}
export function useGetCompanyAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyAddressQuery,
    GetCompanyAddressQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyAddressQuery,
    GetCompanyAddressQueryVariables
  >(GetCompanyAddressDocument, options);
}
export type GetCompanyAddressQueryHookResult = ReturnType<
  typeof useGetCompanyAddressQuery
>;
export type GetCompanyAddressLazyQueryHookResult = ReturnType<
  typeof useGetCompanyAddressLazyQuery
>;
export type GetCompanyAddressQueryResult = Apollo.QueryResult<
  GetCompanyAddressQuery,
  GetCompanyAddressQueryVariables
>;
export const UpdateCompanyBillingDocument = gql`
  mutation updateCompanyBilling($input: CompanyUpdateMutationInput!) {
    companyUpdate(input: $input) {
      clientMutationId
      company {
        ...companyBillingData
      }
      errors {
        field
        message
      }
    }
  }
  ${CompanyBillingDataFragmentDoc}
`;
export type UpdateCompanyBillingMutationFn = Apollo.MutationFunction<
  UpdateCompanyBillingMutation,
  UpdateCompanyBillingMutationVariables
>;

/**
 * __useUpdateCompanyBillingMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyBillingMutation, { data, loading, error }] = useUpdateCompanyBillingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyBillingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyBillingMutation,
    UpdateCompanyBillingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompanyBillingMutation,
    UpdateCompanyBillingMutationVariables
  >(UpdateCompanyBillingDocument, options);
}
export type UpdateCompanyBillingMutationHookResult = ReturnType<
  typeof useUpdateCompanyBillingMutation
>;
export type UpdateCompanyBillingMutationResult =
  Apollo.MutationResult<UpdateCompanyBillingMutation>;
export type UpdateCompanyBillingMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyBillingMutation,
  UpdateCompanyBillingMutationVariables
>;
export const UpdateCompanyAddressDocument = gql`
  mutation updateCompanyAddress($input: CompanyUpdateMutationInput!) {
    companyUpdate(input: $input) {
      clientMutationId
      company {
        ...companyAddressData
      }
      errors {
        field
        message
      }
    }
  }
  ${CompanyAddressDataFragmentDoc}
`;
export type UpdateCompanyAddressMutationFn = Apollo.MutationFunction<
  UpdateCompanyAddressMutation,
  UpdateCompanyAddressMutationVariables
>;

/**
 * __useUpdateCompanyAddressMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyAddressMutation, { data, loading, error }] = useUpdateCompanyAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyAddressMutation,
    UpdateCompanyAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompanyAddressMutation,
    UpdateCompanyAddressMutationVariables
  >(UpdateCompanyAddressDocument, options);
}
export type UpdateCompanyAddressMutationHookResult = ReturnType<
  typeof useUpdateCompanyAddressMutation
>;
export type UpdateCompanyAddressMutationResult =
  Apollo.MutationResult<UpdateCompanyAddressMutation>;
export type UpdateCompanyAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyAddressMutation,
  UpdateCompanyAddressMutationVariables
>;
export const CompanyThemeDocument = gql`
  query companyTheme($id: ID!) {
    companyTheme(id: $id) {
      ...companyTheme
    }
  }
  ${CompanyThemeFragmentDoc}
`;

/**
 * __useCompanyThemeQuery__
 *
 * To run a query within a React component, call `useCompanyThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyThemeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyThemeQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyThemeQuery,
    CompanyThemeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyThemeQuery, CompanyThemeQueryVariables>(
    CompanyThemeDocument,
    options,
  );
}
export function useCompanyThemeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyThemeQuery,
    CompanyThemeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyThemeQuery, CompanyThemeQueryVariables>(
    CompanyThemeDocument,
    options,
  );
}
export type CompanyThemeQueryHookResult = ReturnType<
  typeof useCompanyThemeQuery
>;
export type CompanyThemeLazyQueryHookResult = ReturnType<
  typeof useCompanyThemeLazyQuery
>;
export type CompanyThemeQueryResult = Apollo.QueryResult<
  CompanyThemeQuery,
  CompanyThemeQueryVariables
>;
export const CompanyThemeUpdateDocument = gql`
  mutation companyThemeUpdate(
    $input: CompanyThemeCustomizationUpdateMutationInput!
  ) {
    companyThemeUpdate(input: $input) {
      clientMutationId
      theme {
        ...companyTheme
      }
    }
  }
  ${CompanyThemeFragmentDoc}
`;
export type CompanyThemeUpdateMutationFn = Apollo.MutationFunction<
  CompanyThemeUpdateMutation,
  CompanyThemeUpdateMutationVariables
>;

/**
 * __useCompanyThemeUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyThemeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyThemeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyThemeUpdateMutation, { data, loading, error }] = useCompanyThemeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyThemeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyThemeUpdateMutation,
    CompanyThemeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompanyThemeUpdateMutation,
    CompanyThemeUpdateMutationVariables
  >(CompanyThemeUpdateDocument, options);
}
export type CompanyThemeUpdateMutationHookResult = ReturnType<
  typeof useCompanyThemeUpdateMutation
>;
export type CompanyThemeUpdateMutationResult =
  Apollo.MutationResult<CompanyThemeUpdateMutation>;
export type CompanyThemeUpdateMutationOptions = Apollo.BaseMutationOptions<
  CompanyThemeUpdateMutation,
  CompanyThemeUpdateMutationVariables
>;
export const ContactDocument = gql`
  mutation contact($input: ContactMutationInput!) {
    contact(input: $input) {
      status
      firstName
      errors {
        field
        message
      }
    }
  }
`;
export type ContactMutationFn = Apollo.MutationFunction<
  ContactMutation,
  ContactMutationVariables
>;

/**
 * __useContactMutation__
 *
 * To run a mutation, you first call `useContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactMutation, { data, loading, error }] = useContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContactMutation,
    ContactMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ContactMutation, ContactMutationVariables>(
    ContactDocument,
    options,
  );
}
export type ContactMutationHookResult = ReturnType<typeof useContactMutation>;
export type ContactMutationResult = Apollo.MutationResult<ContactMutation>;
export type ContactMutationOptions = Apollo.BaseMutationOptions<
  ContactMutation,
  ContactMutationVariables
>;
export const GetCourseModuleDocument = gql`
  query getCourseModule($id: ID!) {
    courseModule(id: $id) {
      ...courseModuleUnion
    }
  }
  ${CourseModuleUnionFragmentDoc}
`;

/**
 * __useGetCourseModuleQuery__
 *
 * To run a query within a React component, call `useGetCourseModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseModuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseModuleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseModuleQuery,
    GetCourseModuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCourseModuleQuery, GetCourseModuleQueryVariables>(
    GetCourseModuleDocument,
    options,
  );
}
export function useGetCourseModuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseModuleQuery,
    GetCourseModuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseModuleQuery,
    GetCourseModuleQueryVariables
  >(GetCourseModuleDocument, options);
}
export type GetCourseModuleQueryHookResult = ReturnType<
  typeof useGetCourseModuleQuery
>;
export type GetCourseModuleLazyQueryHookResult = ReturnType<
  typeof useGetCourseModuleLazyQuery
>;
export type GetCourseModuleQueryResult = Apollo.QueryResult<
  GetCourseModuleQuery,
  GetCourseModuleQueryVariables
>;
export const GetAuthorByIdDocument = gql`
  query getAuthorById($id: ID!) {
    author(id: $id) {
      ...authorNode
    }
  }
  ${AuthorNodeFragmentDoc}
`;

/**
 * __useGetAuthorByIdQuery__
 *
 * To run a query within a React component, call `useGetAuthorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAuthorByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuthorByIdQuery,
    GetAuthorByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuthorByIdQuery, GetAuthorByIdQueryVariables>(
    GetAuthorByIdDocument,
    options,
  );
}
export function useGetAuthorByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuthorByIdQuery,
    GetAuthorByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAuthorByIdQuery, GetAuthorByIdQueryVariables>(
    GetAuthorByIdDocument,
    options,
  );
}
export type GetAuthorByIdQueryHookResult = ReturnType<
  typeof useGetAuthorByIdQuery
>;
export type GetAuthorByIdLazyQueryHookResult = ReturnType<
  typeof useGetAuthorByIdLazyQuery
>;
export type GetAuthorByIdQueryResult = Apollo.QueryResult<
  GetAuthorByIdQuery,
  GetAuthorByIdQueryVariables
>;
export const GetAuthorsGroupByIdDocument = gql`
  query getAuthorsGroupById($id: ID!) {
    authorsGroup(id: $id) {
      id
      name
      description
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
    }
  }
  ${AuthorNodeFragmentDoc}
`;

/**
 * __useGetAuthorsGroupByIdQuery__
 *
 * To run a query within a React component, call `useGetAuthorsGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorsGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorsGroupByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAuthorsGroupByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuthorsGroupByIdQuery,
    GetAuthorsGroupByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAuthorsGroupByIdQuery,
    GetAuthorsGroupByIdQueryVariables
  >(GetAuthorsGroupByIdDocument, options);
}
export function useGetAuthorsGroupByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuthorsGroupByIdQuery,
    GetAuthorsGroupByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAuthorsGroupByIdQuery,
    GetAuthorsGroupByIdQueryVariables
  >(GetAuthorsGroupByIdDocument, options);
}
export type GetAuthorsGroupByIdQueryHookResult = ReturnType<
  typeof useGetAuthorsGroupByIdQuery
>;
export type GetAuthorsGroupByIdLazyQueryHookResult = ReturnType<
  typeof useGetAuthorsGroupByIdLazyQuery
>;
export type GetAuthorsGroupByIdQueryResult = Apollo.QueryResult<
  GetAuthorsGroupByIdQuery,
  GetAuthorsGroupByIdQueryVariables
>;
export const CourseAuthorsDocument = gql`
  query courseAuthors($id: ID!) {
    courseAuthors(id: $id) {
      edges {
        node {
          ...authorNode
        }
      }
    }
  }
  ${AuthorNodeFragmentDoc}
`;

/**
 * __useCourseAuthorsQuery__
 *
 * To run a query within a React component, call `useCourseAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseAuthorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseAuthorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseAuthorsQuery,
    CourseAuthorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CourseAuthorsQuery, CourseAuthorsQueryVariables>(
    CourseAuthorsDocument,
    options,
  );
}
export function useCourseAuthorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseAuthorsQuery,
    CourseAuthorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CourseAuthorsQuery, CourseAuthorsQueryVariables>(
    CourseAuthorsDocument,
    options,
  );
}
export type CourseAuthorsQueryHookResult = ReturnType<
  typeof useCourseAuthorsQuery
>;
export type CourseAuthorsLazyQueryHookResult = ReturnType<
  typeof useCourseAuthorsLazyQuery
>;
export type CourseAuthorsQueryResult = Apollo.QueryResult<
  CourseAuthorsQuery,
  CourseAuthorsQueryVariables
>;
export const CourseModuleCertificateMapsDocument = gql`
  query courseModuleCertificateMaps($moduleId: ID!) {
    courseModuleCertificateMaps(moduleId: $moduleId) {
      totalCount
      edges {
        node {
          id
          sendMail
          generationRole
          availableFrom
          availableUntil
          getGenerationRoleDisplay
          coursemodule {
            id
          }
          certificateTemplate {
            id
            emailBody
            customSubject
            certificateFileName
            file {
              name
              url
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCourseModuleCertificateMapsQuery__
 *
 * To run a query within a React component, call `useCourseModuleCertificateMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseModuleCertificateMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseModuleCertificateMapsQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useCourseModuleCertificateMapsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseModuleCertificateMapsQuery,
    CourseModuleCertificateMapsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourseModuleCertificateMapsQuery,
    CourseModuleCertificateMapsQueryVariables
  >(CourseModuleCertificateMapsDocument, options);
}
export function useCourseModuleCertificateMapsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseModuleCertificateMapsQuery,
    CourseModuleCertificateMapsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourseModuleCertificateMapsQuery,
    CourseModuleCertificateMapsQueryVariables
  >(CourseModuleCertificateMapsDocument, options);
}
export type CourseModuleCertificateMapsQueryHookResult = ReturnType<
  typeof useCourseModuleCertificateMapsQuery
>;
export type CourseModuleCertificateMapsLazyQueryHookResult = ReturnType<
  typeof useCourseModuleCertificateMapsLazyQuery
>;
export type CourseModuleCertificateMapsQueryResult = Apollo.QueryResult<
  CourseModuleCertificateMapsQuery,
  CourseModuleCertificateMapsQueryVariables
>;
export const CourseModuleCertificateMapDocument = gql`
  query courseModuleCertificateMap($id: ID!) {
    courseModuleCertificateMap(id: $id) {
      id
      sendMail
      generationRole
      getGenerationRoleDisplay
      availableFrom
      availableUntil
      coursemodule {
        id
      }
      certificateTemplate {
        id
        emailBody
        customSubject
        certificateFileName
        file {
          name
          url
        }
      }
    }
  }
`;

/**
 * __useCourseModuleCertificateMapQuery__
 *
 * To run a query within a React component, call `useCourseModuleCertificateMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseModuleCertificateMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseModuleCertificateMapQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseModuleCertificateMapQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseModuleCertificateMapQuery,
    CourseModuleCertificateMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourseModuleCertificateMapQuery,
    CourseModuleCertificateMapQueryVariables
  >(CourseModuleCertificateMapDocument, options);
}
export function useCourseModuleCertificateMapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseModuleCertificateMapQuery,
    CourseModuleCertificateMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourseModuleCertificateMapQuery,
    CourseModuleCertificateMapQueryVariables
  >(CourseModuleCertificateMapDocument, options);
}
export type CourseModuleCertificateMapQueryHookResult = ReturnType<
  typeof useCourseModuleCertificateMapQuery
>;
export type CourseModuleCertificateMapLazyQueryHookResult = ReturnType<
  typeof useCourseModuleCertificateMapLazyQuery
>;
export type CourseModuleCertificateMapQueryResult = Apollo.QueryResult<
  CourseModuleCertificateMapQuery,
  CourseModuleCertificateMapQueryVariables
>;
export const CourseCertificateMapDocument = gql`
  query courseCertificateMap($id: ID!) {
    courseCertificateMap(id: $id) {
      id
      sendMail
      availableFrom
      availableUntil
      generationRole
      getGenerationRoleDisplay
      course {
        id
      }
      certificateTemplate {
        id
        emailBody
        customSubject
        certificateFileName
        file {
          name
          url
        }
      }
    }
  }
`;

/**
 * __useCourseCertificateMapQuery__
 *
 * To run a query within a React component, call `useCourseCertificateMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCertificateMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCertificateMapQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseCertificateMapQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseCertificateMapQuery,
    CourseCertificateMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourseCertificateMapQuery,
    CourseCertificateMapQueryVariables
  >(CourseCertificateMapDocument, options);
}
export function useCourseCertificateMapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseCertificateMapQuery,
    CourseCertificateMapQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourseCertificateMapQuery,
    CourseCertificateMapQueryVariables
  >(CourseCertificateMapDocument, options);
}
export type CourseCertificateMapQueryHookResult = ReturnType<
  typeof useCourseCertificateMapQuery
>;
export type CourseCertificateMapLazyQueryHookResult = ReturnType<
  typeof useCourseCertificateMapLazyQuery
>;
export type CourseCertificateMapQueryResult = Apollo.QueryResult<
  CourseCertificateMapQuery,
  CourseCertificateMapQueryVariables
>;
export const CourseCertificateMapsDocument = gql`
  query courseCertificateMaps($courseId: ID!) {
    courseCertificateMaps(courseId: $courseId) {
      totalCount
      edges {
        node {
          id
          sendMail
          availableFrom
          availableUntil
          generationRole
          getGenerationRoleDisplay
          course {
            id
          }
          certificateTemplate {
            id
            emailBody
            customSubject
            certificateFileName
            file {
              name
              url
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCourseCertificateMapsQuery__
 *
 * To run a query within a React component, call `useCourseCertificateMapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseCertificateMapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseCertificateMapsQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCourseCertificateMapsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseCertificateMapsQuery,
    CourseCertificateMapsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourseCertificateMapsQuery,
    CourseCertificateMapsQueryVariables
  >(CourseCertificateMapsDocument, options);
}
export function useCourseCertificateMapsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseCertificateMapsQuery,
    CourseCertificateMapsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourseCertificateMapsQuery,
    CourseCertificateMapsQueryVariables
  >(CourseCertificateMapsDocument, options);
}
export type CourseCertificateMapsQueryHookResult = ReturnType<
  typeof useCourseCertificateMapsQuery
>;
export type CourseCertificateMapsLazyQueryHookResult = ReturnType<
  typeof useCourseCertificateMapsLazyQuery
>;
export type CourseCertificateMapsQueryResult = Apollo.QueryResult<
  CourseCertificateMapsQuery,
  CourseCertificateMapsQueryVariables
>;
export const CertificateTemplateDocument = gql`
  query certificateTemplate($id: ID!) {
    certificateTemplate(id: $id) {
      id
      emailBody
      customSubject
      certificateFileName
      file {
        name
        url
      }
    }
  }
`;

/**
 * __useCertificateTemplateQuery__
 *
 * To run a query within a React component, call `useCertificateTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCertificateTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCertificateTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCertificateTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    CertificateTemplateQuery,
    CertificateTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CertificateTemplateQuery,
    CertificateTemplateQueryVariables
  >(CertificateTemplateDocument, options);
}
export function useCertificateTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CertificateTemplateQuery,
    CertificateTemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CertificateTemplateQuery,
    CertificateTemplateQueryVariables
  >(CertificateTemplateDocument, options);
}
export type CertificateTemplateQueryHookResult = ReturnType<
  typeof useCertificateTemplateQuery
>;
export type CertificateTemplateLazyQueryHookResult = ReturnType<
  typeof useCertificateTemplateLazyQuery
>;
export type CertificateTemplateQueryResult = Apollo.QueryResult<
  CertificateTemplateQuery,
  CertificateTemplateQueryVariables
>;
export const AllPrivateCoursesForOptionsDocument = gql`
  query allPrivateCoursesForOptions($search: String, $companyId: ID) {
    allPrivateCourses(search: $search, companyId: $companyId) {
      edges {
        node {
          id
          name
          label
          cover {
            thumbnail(height: 24, width: 24) {
              url
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAllPrivateCoursesForOptionsQuery__
 *
 * To run a query within a React component, call `useAllPrivateCoursesForOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPrivateCoursesForOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPrivateCoursesForOptionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAllPrivateCoursesForOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllPrivateCoursesForOptionsQuery,
    AllPrivateCoursesForOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllPrivateCoursesForOptionsQuery,
    AllPrivateCoursesForOptionsQueryVariables
  >(AllPrivateCoursesForOptionsDocument, options);
}
export function useAllPrivateCoursesForOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllPrivateCoursesForOptionsQuery,
    AllPrivateCoursesForOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllPrivateCoursesForOptionsQuery,
    AllPrivateCoursesForOptionsQueryVariables
  >(AllPrivateCoursesForOptionsDocument, options);
}
export type AllPrivateCoursesForOptionsQueryHookResult = ReturnType<
  typeof useAllPrivateCoursesForOptionsQuery
>;
export type AllPrivateCoursesForOptionsLazyQueryHookResult = ReturnType<
  typeof useAllPrivateCoursesForOptionsLazyQuery
>;
export type AllPrivateCoursesForOptionsQueryResult = Apollo.QueryResult<
  AllPrivateCoursesForOptionsQuery,
  AllPrivateCoursesForOptionsQueryVariables
>;
export const AllPrivateCoursesDocument = gql`
  query allPrivateCourses($search: String, $companyId: ID) {
    allPrivateCourses(search: $search, companyId: $companyId) {
      edges {
        node {
          id
          name
          headline
          overview
          description
          slug
          listed
          label
          startDate
          endDate
          frontendUrl
          dashboardUrl
          theme {
            id
            name
          }
          cover {
            thumbnail(height: 400) {
              url
            }
          }
          sites {
            edges {
              node {
                domain
                id
                name
              }
            }
          }
          company {
            name
            defaultTheme {
              id
              name
            }
            sites {
              edges {
                node {
                  domain
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAllPrivateCoursesQuery__
 *
 * To run a query within a React component, call `useAllPrivateCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPrivateCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPrivateCoursesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAllPrivateCoursesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllPrivateCoursesQuery,
    AllPrivateCoursesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllPrivateCoursesQuery,
    AllPrivateCoursesQueryVariables
  >(AllPrivateCoursesDocument, options);
}
export function useAllPrivateCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllPrivateCoursesQuery,
    AllPrivateCoursesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllPrivateCoursesQuery,
    AllPrivateCoursesQueryVariables
  >(AllPrivateCoursesDocument, options);
}
export type AllPrivateCoursesQueryHookResult = ReturnType<
  typeof useAllPrivateCoursesQuery
>;
export type AllPrivateCoursesLazyQueryHookResult = ReturnType<
  typeof useAllPrivateCoursesLazyQuery
>;
export type AllPrivateCoursesQueryResult = Apollo.QueryResult<
  AllPrivateCoursesQuery,
  AllPrivateCoursesQueryVariables
>;
export const AllPublicCoursesDocument = gql`
  query allPublicCourses(
    $categoriesName: String
    $isAvailable: Boolean
    $listed: Boolean
    $categories: ID
  ) {
    allPublicCourses(
      categoriesName: $categoriesName
      isAvailable: $isAvailable
      listed: $listed
      categories: $categories
    ) {
      edges {
        node {
          id
          name
          headline
          overview
          description
          slug
          listed
          startDate
          endDate
          theme {
            id
          }
          cover {
            thumbnail(height: 400) {
              url
            }
          }
          company {
            name
            defaultTheme {
              id
            }
          }
          participation {
            id
            status
          }
        }
      }
    }
  }
`;

/**
 * __useAllPublicCoursesQuery__
 *
 * To run a query within a React component, call `useAllPublicCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPublicCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPublicCoursesQuery({
 *   variables: {
 *      categoriesName: // value for 'categoriesName'
 *      isAvailable: // value for 'isAvailable'
 *      listed: // value for 'listed'
 *      categories: // value for 'categories'
 *   },
 * });
 */
export function useAllPublicCoursesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllPublicCoursesQuery,
    AllPublicCoursesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllPublicCoursesQuery, AllPublicCoursesQueryVariables>(
    AllPublicCoursesDocument,
    options,
  );
}
export function useAllPublicCoursesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllPublicCoursesQuery,
    AllPublicCoursesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllPublicCoursesQuery,
    AllPublicCoursesQueryVariables
  >(AllPublicCoursesDocument, options);
}
export type AllPublicCoursesQueryHookResult = ReturnType<
  typeof useAllPublicCoursesQuery
>;
export type AllPublicCoursesLazyQueryHookResult = ReturnType<
  typeof useAllPublicCoursesLazyQuery
>;
export type AllPublicCoursesQueryResult = Apollo.QueryResult<
  AllPublicCoursesQuery,
  AllPublicCoursesQueryVariables
>;
export const AllCourseCategoriesDocument = gql`
  query allCourseCategories(
    $isAvailable: Boolean
    $name: String
    $listed: Boolean
    $ids: [ID]
  ) {
    allCourseCategories(name: $name, ids: $ids) {
      edges {
        node {
          id
          name
          description
          courses(isAvailable: $isAvailable, listed: $listed) {
            edges {
              node {
                id
                name
                headline
                overview
                label
                frontendUrl
                dashboardUrl
                owner {
                  id
                  displayName
                  email
                }
                participation {
                  id
                  status
                  created
                }
                userHasPermission {
                  viewCourse
                  changeCourse
                  moderateChatCourse
                  manageParticipationCourse
                  manageInvitationsCourse
                  addParticipationCourse
                  downloadFullReport
                  anyPermission
                }
                theme {
                  id
                }
                slug
                listed
                startDate
                available
                endDate
                cover {
                  thumbnail(height: 400) {
                    url
                  }
                }
                background {
                  thumbnail(height: 916) {
                    url
                  }
                }
                company {
                  id
                  name
                  defaultTheme {
                    id
                  }
                }
                authorsGroups {
                  edges {
                    node {
                      ...authorsGroupNode
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${AuthorsGroupNodeFragmentDoc}
`;

/**
 * __useAllCourseCategoriesQuery__
 *
 * To run a query within a React component, call `useAllCourseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCourseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCourseCategoriesQuery({
 *   variables: {
 *      isAvailable: // value for 'isAvailable'
 *      name: // value for 'name'
 *      listed: // value for 'listed'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAllCourseCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllCourseCategoriesQuery,
    AllCourseCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllCourseCategoriesQuery,
    AllCourseCategoriesQueryVariables
  >(AllCourseCategoriesDocument, options);
}
export function useAllCourseCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCourseCategoriesQuery,
    AllCourseCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllCourseCategoriesQuery,
    AllCourseCategoriesQueryVariables
  >(AllCourseCategoriesDocument, options);
}
export type AllCourseCategoriesQueryHookResult = ReturnType<
  typeof useAllCourseCategoriesQuery
>;
export type AllCourseCategoriesLazyQueryHookResult = ReturnType<
  typeof useAllCourseCategoriesLazyQuery
>;
export type AllCourseCategoriesQueryResult = Apollo.QueryResult<
  AllCourseCategoriesQuery,
  AllCourseCategoriesQueryVariables
>;
export const AllCourseCategoriesNamesDocument = gql`
  query allCourseCategoriesNames($name: String, $ids: [ID]) {
    allCourseCategories(name: $name, ids: $ids) {
      edges {
        node {
          id
          name
          description
        }
      }
    }
  }
`;

/**
 * __useAllCourseCategoriesNamesQuery__
 *
 * To run a query within a React component, call `useAllCourseCategoriesNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCourseCategoriesNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCourseCategoriesNamesQuery({
 *   variables: {
 *      name: // value for 'name'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAllCourseCategoriesNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllCourseCategoriesNamesQuery,
    AllCourseCategoriesNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllCourseCategoriesNamesQuery,
    AllCourseCategoriesNamesQueryVariables
  >(AllCourseCategoriesNamesDocument, options);
}
export function useAllCourseCategoriesNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCourseCategoriesNamesQuery,
    AllCourseCategoriesNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllCourseCategoriesNamesQuery,
    AllCourseCategoriesNamesQueryVariables
  >(AllCourseCategoriesNamesDocument, options);
}
export type AllCourseCategoriesNamesQueryHookResult = ReturnType<
  typeof useAllCourseCategoriesNamesQuery
>;
export type AllCourseCategoriesNamesLazyQueryHookResult = ReturnType<
  typeof useAllCourseCategoriesNamesLazyQuery
>;
export type AllCourseCategoriesNamesQueryResult = Apollo.QueryResult<
  AllCourseCategoriesNamesQuery,
  AllCourseCategoriesNamesQueryVariables
>;
export const GetCourseByIdDocument = gql`
  query getCourseById($id: ID!) {
    course(id: $id) {
      ...CourseFields
    }
  }
  ${CourseFieldsFragmentDoc}
`;

/**
 * __useGetCourseByIdQuery__
 *
 * To run a query within a React component, call `useGetCourseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseByIdQuery,
    GetCourseByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCourseByIdQuery, GetCourseByIdQueryVariables>(
    GetCourseByIdDocument,
    options,
  );
}
export function useGetCourseByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseByIdQuery,
    GetCourseByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCourseByIdQuery, GetCourseByIdQueryVariables>(
    GetCourseByIdDocument,
    options,
  );
}
export type GetCourseByIdQueryHookResult = ReturnType<
  typeof useGetCourseByIdQuery
>;
export type GetCourseByIdLazyQueryHookResult = ReturnType<
  typeof useGetCourseByIdLazyQuery
>;
export type GetCourseByIdQueryResult = Apollo.QueryResult<
  GetCourseByIdQuery,
  GetCourseByIdQueryVariables
>;
export const GetCommonCourseInfoBySlugDocument = gql`
  query getCommonCourseInfoBySlug($slug: String!) {
    publicCourseBySlug(slug: $slug) {
      id
      name
      slug
      overview
      code
      demo
      headline
      description
      created
      startDate
      layoutType
      navigationType
      jsxContent
      displayParticipationSuccessToast
      participationCustomToastSuccessMessage
      displayEndCourseContent
      endCourseContent
      endDate
      authorListLabel
      participationExternalLink
      mosaico {
        id
      }
      commonAuthors {
        edges {
          node {
            id
          }
        }
      }
      authorsGroups {
        edges {
          node {
            ...authorsGroupNode
          }
        }
      }
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
      tags {
        name
        slug
        id
      }
      participationsCount
      defaultLanguage
      theme {
        id
        enableInDashboard
      }
      displayParticipantsCounter
      displayContinueButton
      acceptAnonyumousEnrollment
      forceAnonyumousEnrollment
      onlyOneParticipationPerInvitation
      signupButtonEnable
      loginGoogleButtonEnable
      forceDisplayAuthComponentIfNotLogged
      requireDataParticipation
      acceptsParticipationByExternalUuid
      externalUuidParameterName
      externalUuidParameterRequired
      generateParticipationQrcode
      participationForm {
        id
      }
      cover {
        thumbnail(width: 1680, height: 400, crop: "center") {
          url
        }
      }
      background {
        thumbnail(width: 1280, height: 916, crop: "center") {
          url
        }
      }
      backgroundFullScreen: background {
        thumbnail(width: 1920, height: 1080, crop: "center") {
          url
        }
      }
    }
  }
  ${AuthorsGroupNodeFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;

/**
 * __useGetCommonCourseInfoBySlugQuery__
 *
 * To run a query within a React component, call `useGetCommonCourseInfoBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommonCourseInfoBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommonCourseInfoBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetCommonCourseInfoBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCommonCourseInfoBySlugQuery,
    GetCommonCourseInfoBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCommonCourseInfoBySlugQuery,
    GetCommonCourseInfoBySlugQueryVariables
  >(GetCommonCourseInfoBySlugDocument, options);
}
export function useGetCommonCourseInfoBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommonCourseInfoBySlugQuery,
    GetCommonCourseInfoBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCommonCourseInfoBySlugQuery,
    GetCommonCourseInfoBySlugQueryVariables
  >(GetCommonCourseInfoBySlugDocument, options);
}
export type GetCommonCourseInfoBySlugQueryHookResult = ReturnType<
  typeof useGetCommonCourseInfoBySlugQuery
>;
export type GetCommonCourseInfoBySlugLazyQueryHookResult = ReturnType<
  typeof useGetCommonCourseInfoBySlugLazyQuery
>;
export type GetCommonCourseInfoBySlugQueryResult = Apollo.QueryResult<
  GetCommonCourseInfoBySlugQuery,
  GetCommonCourseInfoBySlugQueryVariables
>;
export const GetCourseSubmodulesOverviewDocument = gql`
  query getCourseSubmodulesOverview($slug: String!) {
    publicCourseBySlug(slug: $slug) {
      id
      submodules {
        totalCount
        edges {
          node {
            ...courseModuleUnionOverview
          }
        }
      }
    }
  }
  ${CourseModuleUnionOverviewFragmentDoc}
`;

/**
 * __useGetCourseSubmodulesOverviewQuery__
 *
 * To run a query within a React component, call `useGetCourseSubmodulesOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseSubmodulesOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseSubmodulesOverviewQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetCourseSubmodulesOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseSubmodulesOverviewQuery,
    GetCourseSubmodulesOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCourseSubmodulesOverviewQuery,
    GetCourseSubmodulesOverviewQueryVariables
  >(GetCourseSubmodulesOverviewDocument, options);
}
export function useGetCourseSubmodulesOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseSubmodulesOverviewQuery,
    GetCourseSubmodulesOverviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseSubmodulesOverviewQuery,
    GetCourseSubmodulesOverviewQueryVariables
  >(GetCourseSubmodulesOverviewDocument, options);
}
export type GetCourseSubmodulesOverviewQueryHookResult = ReturnType<
  typeof useGetCourseSubmodulesOverviewQuery
>;
export type GetCourseSubmodulesOverviewLazyQueryHookResult = ReturnType<
  typeof useGetCourseSubmodulesOverviewLazyQuery
>;
export type GetCourseSubmodulesOverviewQueryResult = Apollo.QueryResult<
  GetCourseSubmodulesOverviewQuery,
  GetCourseSubmodulesOverviewQueryVariables
>;
export const GetCourseSubmodulesWithParticipationDataDocument = gql`
  query getCourseSubmodulesWithParticipationData($slug: String!) {
    publicCourseBySlug(slug: $slug) {
      id
      submodules {
        totalCount
        edges {
          node {
            ...courseModuleUnionOverviewWithPartecipationData
          }
        }
      }
    }
  }
  ${CourseModuleUnionOverviewWithPartecipationDataFragmentDoc}
`;

/**
 * __useGetCourseSubmodulesWithParticipationDataQuery__
 *
 * To run a query within a React component, call `useGetCourseSubmodulesWithParticipationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseSubmodulesWithParticipationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseSubmodulesWithParticipationDataQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetCourseSubmodulesWithParticipationDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseSubmodulesWithParticipationDataQuery,
    GetCourseSubmodulesWithParticipationDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCourseSubmodulesWithParticipationDataQuery,
    GetCourseSubmodulesWithParticipationDataQueryVariables
  >(GetCourseSubmodulesWithParticipationDataDocument, options);
}
export function useGetCourseSubmodulesWithParticipationDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseSubmodulesWithParticipationDataQuery,
    GetCourseSubmodulesWithParticipationDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseSubmodulesWithParticipationDataQuery,
    GetCourseSubmodulesWithParticipationDataQueryVariables
  >(GetCourseSubmodulesWithParticipationDataDocument, options);
}
export type GetCourseSubmodulesWithParticipationDataQueryHookResult =
  ReturnType<typeof useGetCourseSubmodulesWithParticipationDataQuery>;
export type GetCourseSubmodulesWithParticipationDataLazyQueryHookResult =
  ReturnType<typeof useGetCourseSubmodulesWithParticipationDataLazyQuery>;
export type GetCourseSubmodulesWithParticipationDataQueryResult =
  Apollo.QueryResult<
    GetCourseSubmodulesWithParticipationDataQuery,
    GetCourseSubmodulesWithParticipationDataQueryVariables
  >;
export const GetCourseOverviewContentDocument = gql`
  query getCourseOverviewContent($slug: String!) {
    publicCourseBySlug(slug: $slug) {
      id
      description
      jsxContent
      mosaico {
        id
      }
      pdfProgramButtonLabel
      pdfProgram {
        fileName
        url
        extension
      }
      authorListLabel
      authorsGroups {
        edges {
          node {
            ...authorsGroupNode
          }
        }
      }
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
    }
  }
  ${AuthorsGroupNodeFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;

/**
 * __useGetCourseOverviewContentQuery__
 *
 * To run a query within a React component, call `useGetCourseOverviewContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseOverviewContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseOverviewContentQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetCourseOverviewContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseOverviewContentQuery,
    GetCourseOverviewContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCourseOverviewContentQuery,
    GetCourseOverviewContentQueryVariables
  >(GetCourseOverviewContentDocument, options);
}
export function useGetCourseOverviewContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseOverviewContentQuery,
    GetCourseOverviewContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseOverviewContentQuery,
    GetCourseOverviewContentQueryVariables
  >(GetCourseOverviewContentDocument, options);
}
export type GetCourseOverviewContentQueryHookResult = ReturnType<
  typeof useGetCourseOverviewContentQuery
>;
export type GetCourseOverviewContentLazyQueryHookResult = ReturnType<
  typeof useGetCourseOverviewContentLazyQuery
>;
export type GetCourseOverviewContentQueryResult = Apollo.QueryResult<
  GetCourseOverviewContentQuery,
  GetCourseOverviewContentQueryVariables
>;
export const GetCourseNextWebinarDocument = gql`
  query getCourseNextWebinar($slug: String!) {
    publicCourseBySlug(slug: $slug) {
      id
      nextWebinar {
        name
        status
        id
        plannedStartDate
        plannedEndDate
        testingMode
      }
    }
  }
`;

/**
 * __useGetCourseNextWebinarQuery__
 *
 * To run a query within a React component, call `useGetCourseNextWebinarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseNextWebinarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseNextWebinarQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetCourseNextWebinarQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseNextWebinarQuery,
    GetCourseNextWebinarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCourseNextWebinarQuery,
    GetCourseNextWebinarQueryVariables
  >(GetCourseNextWebinarDocument, options);
}
export function useGetCourseNextWebinarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseNextWebinarQuery,
    GetCourseNextWebinarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseNextWebinarQuery,
    GetCourseNextWebinarQueryVariables
  >(GetCourseNextWebinarDocument, options);
}
export type GetCourseNextWebinarQueryHookResult = ReturnType<
  typeof useGetCourseNextWebinarQuery
>;
export type GetCourseNextWebinarLazyQueryHookResult = ReturnType<
  typeof useGetCourseNextWebinarLazyQuery
>;
export type GetCourseNextWebinarQueryResult = Apollo.QueryResult<
  GetCourseNextWebinarQuery,
  GetCourseNextWebinarQueryVariables
>;
export const GetCoursePermissionDocument = gql`
  query getCoursePermission($id: ID!) {
    course(id: $id) {
      id
      slug
      userHasPermission {
        viewCourse
        changeCourse
        moderateChatCourse
        manageParticipationCourse
        manageInvitationsCourse
        addParticipationCourse
        downloadFullReport
        anyPermission
      }
    }
  }
`;

/**
 * __useGetCoursePermissionQuery__
 *
 * To run a query within a React component, call `useGetCoursePermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursePermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursePermissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCoursePermissionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCoursePermissionQuery,
    GetCoursePermissionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCoursePermissionQuery,
    GetCoursePermissionQueryVariables
  >(GetCoursePermissionDocument, options);
}
export function useGetCoursePermissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoursePermissionQuery,
    GetCoursePermissionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCoursePermissionQuery,
    GetCoursePermissionQueryVariables
  >(GetCoursePermissionDocument, options);
}
export type GetCoursePermissionQueryHookResult = ReturnType<
  typeof useGetCoursePermissionQuery
>;
export type GetCoursePermissionLazyQueryHookResult = ReturnType<
  typeof useGetCoursePermissionLazyQuery
>;
export type GetCoursePermissionQueryResult = Apollo.QueryResult<
  GetCoursePermissionQuery,
  GetCoursePermissionQueryVariables
>;
export const GetCourseParticipationFormQuestionsDocument = gql`
  query getCourseParticipationFormQuestions($courseId: ID!) {
    course(id: $courseId) {
      id
      participationForm {
        id
        questions {
          edges {
            node {
              __typename
              ... on QuestionNode {
                id
                question
                title
                label
                description
                placeholder
                helpText
                type
                slug
                enumType
                required
                staffOnly
                choiches {
                  value
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCourseParticipationFormQuestionsQuery__
 *
 * To run a query within a React component, call `useGetCourseParticipationFormQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseParticipationFormQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseParticipationFormQuestionsQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseParticipationFormQuestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseParticipationFormQuestionsQuery,
    GetCourseParticipationFormQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCourseParticipationFormQuestionsQuery,
    GetCourseParticipationFormQuestionsQueryVariables
  >(GetCourseParticipationFormQuestionsDocument, options);
}
export function useGetCourseParticipationFormQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseParticipationFormQuestionsQuery,
    GetCourseParticipationFormQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseParticipationFormQuestionsQuery,
    GetCourseParticipationFormQuestionsQueryVariables
  >(GetCourseParticipationFormQuestionsDocument, options);
}
export type GetCourseParticipationFormQuestionsQueryHookResult = ReturnType<
  typeof useGetCourseParticipationFormQuestionsQuery
>;
export type GetCourseParticipationFormQuestionsLazyQueryHookResult = ReturnType<
  typeof useGetCourseParticipationFormQuestionsLazyQuery
>;
export type GetCourseParticipationFormQuestionsQueryResult = Apollo.QueryResult<
  GetCourseParticipationFormQuestionsQuery,
  GetCourseParticipationFormQuestionsQueryVariables
>;
export const GetCourseInvitationsDocument = gql`
  query getCourseInvitations($id: ID!) {
    course(id: $id) {
      id
      invitations {
        edges {
          node {
            ...courseInvitationtNode
          }
        }
      }
    }
  }
  ${CourseInvitationtNodeFragmentDoc}
`;

/**
 * __useGetCourseInvitationsQuery__
 *
 * To run a query within a React component, call `useGetCourseInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseInvitationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseInvitationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseInvitationsQuery,
    GetCourseInvitationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCourseInvitationsQuery,
    GetCourseInvitationsQueryVariables
  >(GetCourseInvitationsDocument, options);
}
export function useGetCourseInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseInvitationsQuery,
    GetCourseInvitationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseInvitationsQuery,
    GetCourseInvitationsQueryVariables
  >(GetCourseInvitationsDocument, options);
}
export type GetCourseInvitationsQueryHookResult = ReturnType<
  typeof useGetCourseInvitationsQuery
>;
export type GetCourseInvitationsLazyQueryHookResult = ReturnType<
  typeof useGetCourseInvitationsLazyQuery
>;
export type GetCourseInvitationsQueryResult = Apollo.QueryResult<
  GetCourseInvitationsQuery,
  GetCourseInvitationsQueryVariables
>;
export const GetPublicCourseAuthorsDocument = gql`
  query getPublicCourseAuthors($slug: String!) {
    publicCourseBySlug(slug: $slug) {
      id
      authorsGroups {
        edges {
          node {
            ...authorsGroupNode
          }
        }
      }
      commonAuthors {
        edges {
          node {
            ...authorNode
          }
        }
      }
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
    }
  }
  ${AuthorsGroupNodeFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;

/**
 * __useGetPublicCourseAuthorsQuery__
 *
 * To run a query within a React component, call `useGetPublicCourseAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicCourseAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicCourseAuthorsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPublicCourseAuthorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPublicCourseAuthorsQuery,
    GetPublicCourseAuthorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPublicCourseAuthorsQuery,
    GetPublicCourseAuthorsQueryVariables
  >(GetPublicCourseAuthorsDocument, options);
}
export function useGetPublicCourseAuthorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicCourseAuthorsQuery,
    GetPublicCourseAuthorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPublicCourseAuthorsQuery,
    GetPublicCourseAuthorsQueryVariables
  >(GetPublicCourseAuthorsDocument, options);
}
export type GetPublicCourseAuthorsQueryHookResult = ReturnType<
  typeof useGetPublicCourseAuthorsQuery
>;
export type GetPublicCourseAuthorsLazyQueryHookResult = ReturnType<
  typeof useGetPublicCourseAuthorsLazyQuery
>;
export type GetPublicCourseAuthorsQueryResult = Apollo.QueryResult<
  GetPublicCourseAuthorsQuery,
  GetPublicCourseAuthorsQueryVariables
>;
export const GetCourseAuthorsDocument = gql`
  query getCourseAuthors($id: ID!) {
    course(id: $id) {
      id
      authorsGroups {
        edges {
          node {
            ...authorsGroupNode
          }
        }
      }
      commonAuthors {
        edges {
          node {
            ...authorNode
          }
        }
      }
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
    }
  }
  ${AuthorsGroupNodeFragmentDoc}
  ${AuthorNodeFragmentDoc}
`;

/**
 * __useGetCourseAuthorsQuery__
 *
 * To run a query within a React component, call `useGetCourseAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseAuthorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseAuthorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseAuthorsQuery,
    GetCourseAuthorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCourseAuthorsQuery, GetCourseAuthorsQueryVariables>(
    GetCourseAuthorsDocument,
    options,
  );
}
export function useGetCourseAuthorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseAuthorsQuery,
    GetCourseAuthorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseAuthorsQuery,
    GetCourseAuthorsQueryVariables
  >(GetCourseAuthorsDocument, options);
}
export type GetCourseAuthorsQueryHookResult = ReturnType<
  typeof useGetCourseAuthorsQuery
>;
export type GetCourseAuthorsLazyQueryHookResult = ReturnType<
  typeof useGetCourseAuthorsLazyQuery
>;
export type GetCourseAuthorsQueryResult = Apollo.QueryResult<
  GetCourseAuthorsQuery,
  GetCourseAuthorsQueryVariables
>;
export const PublicCourseBySlugDocument = gql`
  query publicCourseBySlug($slug: String!) {
    publicCourseBySlug(slug: $slug) {
      id
      name
      slug
      overview
      code
      demo
      headline
      description
      created
      startDate
      endDate
      layoutType
      navigationType
      jsxContent
      displayEndCourseContent
      endCourseContent
      displayParticipationSuccessToast
      participationCustomToastSuccessMessage
      participationExternalLink
      mosaico {
        id
      }
      tags {
        name
        slug
        id
      }
      participationsCount
      defaultLanguage
      theme {
        id
        enableInDashboard
      }
      displayParticipantsCounter
      displayContinueButton
      acceptAnonyumousEnrollment
      forceAnonyumousEnrollment
      onlyOneParticipationPerInvitation
      signupButtonEnable
      loginGoogleButtonEnable
      forceDisplayAuthComponentIfNotLogged
      requireDataParticipation
      acceptsParticipationByExternalUuid
      externalUuidParameterName
      externalUuidParameterRequired
      generateParticipationQrcode
      participationForm {
        id
      }
      userHasPermission {
        viewCourse
        changeCourse
        moderateChatCourse
        manageParticipationCourse
        manageInvitationsCourse
        addParticipationCourse
        downloadFullReport
        anyPermission
      }
      pdfProgramButtonLabel
      pdfProgram {
        fileName
        url
        extension
      }
      cover {
        thumbnail(width: 1680, height: 400, crop: "center") {
          url
        }
      }
      background {
        thumbnail(width: 1280, height: 916, crop: "center") {
          url
        }
      }
      backgroundFullScreen: background {
        thumbnail(width: 1920, height: 1080, crop: "center") {
          url
        }
      }
      authorListLabel
      authorsGroups {
        edges {
          node {
            ...authorsGroupNode
          }
        }
      }
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
      participation {
        id
        status
        qrcode {
          url
        }
        lockedModules {
          ...courseModuleUnionId
        }
        data
      }
      submodules {
        totalCount
        edges {
          node {
            ...courseModuleUnion
          }
        }
      }
    }
  }
  ${AuthorsGroupNodeFragmentDoc}
  ${AuthorNodeFragmentDoc}
  ${CourseModuleUnionIdFragmentDoc}
  ${CourseModuleUnionFragmentDoc}
`;

/**
 * __usePublicCourseBySlugQuery__
 *
 * To run a query within a React component, call `usePublicCourseBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicCourseBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicCourseBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicCourseBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublicCourseBySlugQuery,
    PublicCourseBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PublicCourseBySlugQuery,
    PublicCourseBySlugQueryVariables
  >(PublicCourseBySlugDocument, options);
}
export function usePublicCourseBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicCourseBySlugQuery,
    PublicCourseBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PublicCourseBySlugQuery,
    PublicCourseBySlugQueryVariables
  >(PublicCourseBySlugDocument, options);
}
export type PublicCourseBySlugQueryHookResult = ReturnType<
  typeof usePublicCourseBySlugQuery
>;
export type PublicCourseBySlugLazyQueryHookResult = ReturnType<
  typeof usePublicCourseBySlugLazyQuery
>;
export type PublicCourseBySlugQueryResult = Apollo.QueryResult<
  PublicCourseBySlugQuery,
  PublicCourseBySlugQueryVariables
>;
export const GetCourseCategoriesDocument = gql`
  query getCourseCategories($id: ID!) {
    course(id: $id) {
      categories {
        edges {
          node {
            id
            name
            description
            order
          }
        }
      }
    }
  }
`;

/**
 * __useGetCourseCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCourseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseCategoriesQuery,
    GetCourseCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCourseCategoriesQuery,
    GetCourseCategoriesQueryVariables
  >(GetCourseCategoriesDocument, options);
}
export function useGetCourseCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseCategoriesQuery,
    GetCourseCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseCategoriesQuery,
    GetCourseCategoriesQueryVariables
  >(GetCourseCategoriesDocument, options);
}
export type GetCourseCategoriesQueryHookResult = ReturnType<
  typeof useGetCourseCategoriesQuery
>;
export type GetCourseCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetCourseCategoriesLazyQuery
>;
export type GetCourseCategoriesQueryResult = Apollo.QueryResult<
  GetCourseCategoriesQuery,
  GetCourseCategoriesQueryVariables
>;
export const GetCourseGroupsDocument = gql`
  query getCourseGroups($id: ID!) {
    course(id: $id) {
      id
      groups {
        edges {
          node {
            id
            name
            label
          }
        }
      }
    }
  }
`;

/**
 * __useGetCourseGroupsQuery__
 *
 * To run a query within a React component, call `useGetCourseGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseGroupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseGroupsQuery,
    GetCourseGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCourseGroupsQuery, GetCourseGroupsQueryVariables>(
    GetCourseGroupsDocument,
    options,
  );
}
export function useGetCourseGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseGroupsQuery,
    GetCourseGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseGroupsQuery,
    GetCourseGroupsQueryVariables
  >(GetCourseGroupsDocument, options);
}
export type GetCourseGroupsQueryHookResult = ReturnType<
  typeof useGetCourseGroupsQuery
>;
export type GetCourseGroupsLazyQueryHookResult = ReturnType<
  typeof useGetCourseGroupsLazyQuery
>;
export type GetCourseGroupsQueryResult = Apollo.QueryResult<
  GetCourseGroupsQuery,
  GetCourseGroupsQueryVariables
>;
export const GetCourseGuestsCounterDocument = gql`
  query getCourseGuestsCounter($id: ID!) {
    course(id: $id) {
      id
      participationsCount
      activeUsersMonthGroups {
        month
        count
      }
    }
  }
`;

/**
 * __useGetCourseGuestsCounterQuery__
 *
 * To run a query within a React component, call `useGetCourseGuestsCounterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseGuestsCounterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseGuestsCounterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseGuestsCounterQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseGuestsCounterQuery,
    GetCourseGuestsCounterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCourseGuestsCounterQuery,
    GetCourseGuestsCounterQueryVariables
  >(GetCourseGuestsCounterDocument, options);
}
export function useGetCourseGuestsCounterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseGuestsCounterQuery,
    GetCourseGuestsCounterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseGuestsCounterQuery,
    GetCourseGuestsCounterQueryVariables
  >(GetCourseGuestsCounterDocument, options);
}
export type GetCourseGuestsCounterQueryHookResult = ReturnType<
  typeof useGetCourseGuestsCounterQuery
>;
export type GetCourseGuestsCounterLazyQueryHookResult = ReturnType<
  typeof useGetCourseGuestsCounterLazyQuery
>;
export type GetCourseGuestsCounterQueryResult = Apollo.QueryResult<
  GetCourseGuestsCounterQuery,
  GetCourseGuestsCounterQueryVariables
>;
export const CourseParticipationImportFileDocument = gql`
  query CourseParticipationImportFile($courseParticipationImportFileId: ID!) {
    courseParticipationImportFile(id: $courseParticipationImportFileId) {
      file {
        name
        extension
        url
      }
    }
  }
`;

/**
 * __useCourseParticipationImportFileQuery__
 *
 * To run a query within a React component, call `useCourseParticipationImportFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseParticipationImportFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseParticipationImportFileQuery({
 *   variables: {
 *      courseParticipationImportFileId: // value for 'courseParticipationImportFileId'
 *   },
 * });
 */
export function useCourseParticipationImportFileQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseParticipationImportFileQuery,
    CourseParticipationImportFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourseParticipationImportFileQuery,
    CourseParticipationImportFileQueryVariables
  >(CourseParticipationImportFileDocument, options);
}
export function useCourseParticipationImportFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseParticipationImportFileQuery,
    CourseParticipationImportFileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourseParticipationImportFileQuery,
    CourseParticipationImportFileQueryVariables
  >(CourseParticipationImportFileDocument, options);
}
export type CourseParticipationImportFileQueryHookResult = ReturnType<
  typeof useCourseParticipationImportFileQuery
>;
export type CourseParticipationImportFileLazyQueryHookResult = ReturnType<
  typeof useCourseParticipationImportFileLazyQuery
>;
export type CourseParticipationImportFileQueryResult = Apollo.QueryResult<
  CourseParticipationImportFileQuery,
  CourseParticipationImportFileQueryVariables
>;
export const GetCourseInvitationDocument = gql`
  query getCourseInvitation($id: ID!) {
    courseInvitation(id: $id) {
      ...courseInvitationtNode
    }
  }
  ${CourseInvitationtNodeFragmentDoc}
`;

/**
 * __useGetCourseInvitationQuery__
 *
 * To run a query within a React component, call `useGetCourseInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseInvitationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseInvitationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseInvitationQuery,
    GetCourseInvitationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCourseInvitationQuery,
    GetCourseInvitationQueryVariables
  >(GetCourseInvitationDocument, options);
}
export function useGetCourseInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseInvitationQuery,
    GetCourseInvitationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseInvitationQuery,
    GetCourseInvitationQueryVariables
  >(GetCourseInvitationDocument, options);
}
export type GetCourseInvitationQueryHookResult = ReturnType<
  typeof useGetCourseInvitationQuery
>;
export type GetCourseInvitationLazyQueryHookResult = ReturnType<
  typeof useGetCourseInvitationLazyQuery
>;
export type GetCourseInvitationQueryResult = Apollo.QueryResult<
  GetCourseInvitationQuery,
  GetCourseInvitationQueryVariables
>;
export const GetMyCourseInvitationsDocument = gql`
  query getMyCourseInvitations(
    $owner: ID
    $course: ID
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    courseInvitations(
      owner: $owner
      course: $course
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      edges {
        node {
          ...courseInvitationtNode
        }
      }
    }
  }
  ${CourseInvitationtNodeFragmentDoc}
`;

/**
 * __useGetMyCourseInvitationsQuery__
 *
 * To run a query within a React component, call `useGetMyCourseInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCourseInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCourseInvitationsQuery({
 *   variables: {
 *      owner: // value for 'owner'
 *      course: // value for 'course'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetMyCourseInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyCourseInvitationsQuery,
    GetMyCourseInvitationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyCourseInvitationsQuery,
    GetMyCourseInvitationsQueryVariables
  >(GetMyCourseInvitationsDocument, options);
}
export function useGetMyCourseInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyCourseInvitationsQuery,
    GetMyCourseInvitationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyCourseInvitationsQuery,
    GetMyCourseInvitationsQueryVariables
  >(GetMyCourseInvitationsDocument, options);
}
export type GetMyCourseInvitationsQueryHookResult = ReturnType<
  typeof useGetMyCourseInvitationsQuery
>;
export type GetMyCourseInvitationsLazyQueryHookResult = ReturnType<
  typeof useGetMyCourseInvitationsLazyQuery
>;
export type GetMyCourseInvitationsQueryResult = Apollo.QueryResult<
  GetMyCourseInvitationsQuery,
  GetMyCourseInvitationsQueryVariables
>;
export const UserMatchInvitationDocument = gql`
  query userMatchInvitation($id: ID!) {
    userMatchInvitation(id: $id) {
      match
      hideEmail
      email
      autoCreateParticipation
    }
  }
`;

/**
 * __useUserMatchInvitationQuery__
 *
 * To run a query within a React component, call `useUserMatchInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserMatchInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserMatchInvitationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserMatchInvitationQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserMatchInvitationQuery,
    UserMatchInvitationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UserMatchInvitationQuery,
    UserMatchInvitationQueryVariables
  >(UserMatchInvitationDocument, options);
}
export function useUserMatchInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserMatchInvitationQuery,
    UserMatchInvitationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserMatchInvitationQuery,
    UserMatchInvitationQueryVariables
  >(UserMatchInvitationDocument, options);
}
export type UserMatchInvitationQueryHookResult = ReturnType<
  typeof useUserMatchInvitationQuery
>;
export type UserMatchInvitationLazyQueryHookResult = ReturnType<
  typeof useUserMatchInvitationLazyQuery
>;
export type UserMatchInvitationQueryResult = Apollo.QueryResult<
  UserMatchInvitationQuery,
  UserMatchInvitationQueryVariables
>;
export const GetModuleWithSubModulesDocument = gql`
  query getModuleWithSubModules($courseModuleId: ID!) {
    courseModule(id: $courseModuleId) {
      ... on CourseModuleUnion {
        ...courseModuleUnionWithSubmodules
      }
    }
  }
  ${CourseModuleUnionWithSubmodulesFragmentDoc}
`;

/**
 * __useGetModuleWithSubModulesQuery__
 *
 * To run a query within a React component, call `useGetModuleWithSubModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleWithSubModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleWithSubModulesQuery({
 *   variables: {
 *      courseModuleId: // value for 'courseModuleId'
 *   },
 * });
 */
export function useGetModuleWithSubModulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetModuleWithSubModulesQuery,
    GetModuleWithSubModulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetModuleWithSubModulesQuery,
    GetModuleWithSubModulesQueryVariables
  >(GetModuleWithSubModulesDocument, options);
}
export function useGetModuleWithSubModulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetModuleWithSubModulesQuery,
    GetModuleWithSubModulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetModuleWithSubModulesQuery,
    GetModuleWithSubModulesQueryVariables
  >(GetModuleWithSubModulesDocument, options);
}
export type GetModuleWithSubModulesQueryHookResult = ReturnType<
  typeof useGetModuleWithSubModulesQuery
>;
export type GetModuleWithSubModulesLazyQueryHookResult = ReturnType<
  typeof useGetModuleWithSubModulesLazyQuery
>;
export type GetModuleWithSubModulesQueryResult = Apollo.QueryResult<
  GetModuleWithSubModulesQuery,
  GetModuleWithSubModulesQueryVariables
>;
export const GetModuleRemindersDocument = gql`
  query getModuleReminders($courseModuleId: ID!) {
    courseModule(id: $courseModuleId) {
      ... on CourseModuleUnion {
        ...courseModuleUnionReminders
      }
    }
  }
  ${CourseModuleUnionRemindersFragmentDoc}
`;

/**
 * __useGetModuleRemindersQuery__
 *
 * To run a query within a React component, call `useGetModuleRemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleRemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleRemindersQuery({
 *   variables: {
 *      courseModuleId: // value for 'courseModuleId'
 *   },
 * });
 */
export function useGetModuleRemindersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetModuleRemindersQuery,
    GetModuleRemindersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetModuleRemindersQuery,
    GetModuleRemindersQueryVariables
  >(GetModuleRemindersDocument, options);
}
export function useGetModuleRemindersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetModuleRemindersQuery,
    GetModuleRemindersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetModuleRemindersQuery,
    GetModuleRemindersQueryVariables
  >(GetModuleRemindersDocument, options);
}
export type GetModuleRemindersQueryHookResult = ReturnType<
  typeof useGetModuleRemindersQuery
>;
export type GetModuleRemindersLazyQueryHookResult = ReturnType<
  typeof useGetModuleRemindersLazyQuery
>;
export type GetModuleRemindersQueryResult = Apollo.QueryResult<
  GetModuleRemindersQuery,
  GetModuleRemindersQueryVariables
>;
export const GetModuleAuthorsDocument = gql`
  query getModuleAuthors($id: ID!) {
    courseModule(id: $id) {
      ... on CourseModuleUnion {
        ...courseModuleUnionAuthors
      }
    }
  }
  ${CourseModuleUnionAuthorsFragmentDoc}
`;

/**
 * __useGetModuleAuthorsQuery__
 *
 * To run a query within a React component, call `useGetModuleAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleAuthorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModuleAuthorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetModuleAuthorsQuery,
    GetModuleAuthorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetModuleAuthorsQuery, GetModuleAuthorsQueryVariables>(
    GetModuleAuthorsDocument,
    options,
  );
}
export function useGetModuleAuthorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetModuleAuthorsQuery,
    GetModuleAuthorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetModuleAuthorsQuery,
    GetModuleAuthorsQueryVariables
  >(GetModuleAuthorsDocument, options);
}
export type GetModuleAuthorsQueryHookResult = ReturnType<
  typeof useGetModuleAuthorsQuery
>;
export type GetModuleAuthorsLazyQueryHookResult = ReturnType<
  typeof useGetModuleAuthorsLazyQuery
>;
export type GetModuleAuthorsQueryResult = Apollo.QueryResult<
  GetModuleAuthorsQuery,
  GetModuleAuthorsQueryVariables
>;
export const VideoCourseModuleAssessmentDocument = gql`
  query videoCourseModuleAssessment($id: ID!) {
    videoCourseModuleAssessment(id: $id) {
      id
      userLivePercentile
      videoPlayedSeconds
      status
    }
  }
`;

/**
 * __useVideoCourseModuleAssessmentQuery__
 *
 * To run a query within a React component, call `useVideoCourseModuleAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoCourseModuleAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoCourseModuleAssessmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVideoCourseModuleAssessmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    VideoCourseModuleAssessmentQuery,
    VideoCourseModuleAssessmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VideoCourseModuleAssessmentQuery,
    VideoCourseModuleAssessmentQueryVariables
  >(VideoCourseModuleAssessmentDocument, options);
}
export function useVideoCourseModuleAssessmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VideoCourseModuleAssessmentQuery,
    VideoCourseModuleAssessmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VideoCourseModuleAssessmentQuery,
    VideoCourseModuleAssessmentQueryVariables
  >(VideoCourseModuleAssessmentDocument, options);
}
export type VideoCourseModuleAssessmentQueryHookResult = ReturnType<
  typeof useVideoCourseModuleAssessmentQuery
>;
export type VideoCourseModuleAssessmentLazyQueryHookResult = ReturnType<
  typeof useVideoCourseModuleAssessmentLazyQuery
>;
export type VideoCourseModuleAssessmentQueryResult = Apollo.QueryResult<
  VideoCourseModuleAssessmentQuery,
  VideoCourseModuleAssessmentQueryVariables
>;
export const GetExternalWebinarModuleUpdateDocument = gql`
  query getExternalWebinarModuleUpdate($id: ID!) {
    courseModule(id: $id) {
      ... on ExternalWebinarModuleNode {
        ...externalWebinarModuleNode
      }
    }
  }
  ${ExternalWebinarModuleNodeFragmentDoc}
`;

/**
 * __useGetExternalWebinarModuleUpdateQuery__
 *
 * To run a query within a React component, call `useGetExternalWebinarModuleUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalWebinarModuleUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalWebinarModuleUpdateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExternalWebinarModuleUpdateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExternalWebinarModuleUpdateQuery,
    GetExternalWebinarModuleUpdateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExternalWebinarModuleUpdateQuery,
    GetExternalWebinarModuleUpdateQueryVariables
  >(GetExternalWebinarModuleUpdateDocument, options);
}
export function useGetExternalWebinarModuleUpdateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExternalWebinarModuleUpdateQuery,
    GetExternalWebinarModuleUpdateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExternalWebinarModuleUpdateQuery,
    GetExternalWebinarModuleUpdateQueryVariables
  >(GetExternalWebinarModuleUpdateDocument, options);
}
export type GetExternalWebinarModuleUpdateQueryHookResult = ReturnType<
  typeof useGetExternalWebinarModuleUpdateQuery
>;
export type GetExternalWebinarModuleUpdateLazyQueryHookResult = ReturnType<
  typeof useGetExternalWebinarModuleUpdateLazyQuery
>;
export type GetExternalWebinarModuleUpdateQueryResult = Apollo.QueryResult<
  GetExternalWebinarModuleUpdateQuery,
  GetExternalWebinarModuleUpdateQueryVariables
>;
export const GetExternalWebinarCurrentStatusDocument = gql`
  query getExternalWebinarCurrentStatus($id: ID!) {
    courseModule(id: $id) {
      ... on ExternalWebinarModuleNode {
        id
        status
        lastStatusEvent {
          timestamp
          status
          user {
            id
            email
          }
        }
      }
    }
  }
`;

/**
 * __useGetExternalWebinarCurrentStatusQuery__
 *
 * To run a query within a React component, call `useGetExternalWebinarCurrentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalWebinarCurrentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalWebinarCurrentStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExternalWebinarCurrentStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExternalWebinarCurrentStatusQuery,
    GetExternalWebinarCurrentStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExternalWebinarCurrentStatusQuery,
    GetExternalWebinarCurrentStatusQueryVariables
  >(GetExternalWebinarCurrentStatusDocument, options);
}
export function useGetExternalWebinarCurrentStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExternalWebinarCurrentStatusQuery,
    GetExternalWebinarCurrentStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExternalWebinarCurrentStatusQuery,
    GetExternalWebinarCurrentStatusQueryVariables
  >(GetExternalWebinarCurrentStatusDocument, options);
}
export type GetExternalWebinarCurrentStatusQueryHookResult = ReturnType<
  typeof useGetExternalWebinarCurrentStatusQuery
>;
export type GetExternalWebinarCurrentStatusLazyQueryHookResult = ReturnType<
  typeof useGetExternalWebinarCurrentStatusLazyQuery
>;
export type GetExternalWebinarCurrentStatusQueryResult = Apollo.QueryResult<
  GetExternalWebinarCurrentStatusQuery,
  GetExternalWebinarCurrentStatusQueryVariables
>;
export const ExternalWebinarModuleChangeStatusDocument = gql`
  mutation externalWebinarModuleChangeStatus(
    $input: ExternalWebinarModuleChangeStatusMutationInput!
  ) {
    externalWebinarModuleChangeStatus(input: $input) {
      lockedWarning
      externalWebinarModule {
        id
        status
        lastStatusEvent {
          timestamp
          status
          user {
            id
            email
          }
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
export type ExternalWebinarModuleChangeStatusMutationFn =
  Apollo.MutationFunction<
    ExternalWebinarModuleChangeStatusMutation,
    ExternalWebinarModuleChangeStatusMutationVariables
  >;

/**
 * __useExternalWebinarModuleChangeStatusMutation__
 *
 * To run a mutation, you first call `useExternalWebinarModuleChangeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExternalWebinarModuleChangeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [externalWebinarModuleChangeStatusMutation, { data, loading, error }] = useExternalWebinarModuleChangeStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExternalWebinarModuleChangeStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExternalWebinarModuleChangeStatusMutation,
    ExternalWebinarModuleChangeStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExternalWebinarModuleChangeStatusMutation,
    ExternalWebinarModuleChangeStatusMutationVariables
  >(ExternalWebinarModuleChangeStatusDocument, options);
}
export type ExternalWebinarModuleChangeStatusMutationHookResult = ReturnType<
  typeof useExternalWebinarModuleChangeStatusMutation
>;
export type ExternalWebinarModuleChangeStatusMutationResult =
  Apollo.MutationResult<ExternalWebinarModuleChangeStatusMutation>;
export type ExternalWebinarModuleChangeStatusMutationOptions =
  Apollo.BaseMutationOptions<
    ExternalWebinarModuleChangeStatusMutation,
    ExternalWebinarModuleChangeStatusMutationVariables
  >;
export const WebinarModuleClearTimeIntervalsDocument = gql`
  mutation webinarModuleClearTimeIntervals(
    $input: WebinarModuleClearTimeIntervalsInput!
  ) {
    webinarModuleClearTimeIntervals(input: $input) {
      status
      cleanedData
      errors {
        field
        message
      }
    }
  }
`;
export type WebinarModuleClearTimeIntervalsMutationFn = Apollo.MutationFunction<
  WebinarModuleClearTimeIntervalsMutation,
  WebinarModuleClearTimeIntervalsMutationVariables
>;

/**
 * __useWebinarModuleClearTimeIntervalsMutation__
 *
 * To run a mutation, you first call `useWebinarModuleClearTimeIntervalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebinarModuleClearTimeIntervalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webinarModuleClearTimeIntervalsMutation, { data, loading, error }] = useWebinarModuleClearTimeIntervalsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWebinarModuleClearTimeIntervalsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WebinarModuleClearTimeIntervalsMutation,
    WebinarModuleClearTimeIntervalsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WebinarModuleClearTimeIntervalsMutation,
    WebinarModuleClearTimeIntervalsMutationVariables
  >(WebinarModuleClearTimeIntervalsDocument, options);
}
export type WebinarModuleClearTimeIntervalsMutationHookResult = ReturnType<
  typeof useWebinarModuleClearTimeIntervalsMutation
>;
export type WebinarModuleClearTimeIntervalsMutationResult =
  Apollo.MutationResult<WebinarModuleClearTimeIntervalsMutation>;
export type WebinarModuleClearTimeIntervalsMutationOptions =
  Apollo.BaseMutationOptions<
    WebinarModuleClearTimeIntervalsMutation,
    WebinarModuleClearTimeIntervalsMutationVariables
  >;
export const GetExternalWebinarLiveTimeDocument = gql`
  query getExternalWebinarLiveTime($id: ID!) {
    courseModule(id: $id) {
      ... on ExternalWebinarModuleNode {
        id
        liveTime
        status
      }
    }
  }
`;

/**
 * __useGetExternalWebinarLiveTimeQuery__
 *
 * To run a query within a React component, call `useGetExternalWebinarLiveTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalWebinarLiveTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalWebinarLiveTimeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExternalWebinarLiveTimeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetExternalWebinarLiveTimeQuery,
    GetExternalWebinarLiveTimeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetExternalWebinarLiveTimeQuery,
    GetExternalWebinarLiveTimeQueryVariables
  >(GetExternalWebinarLiveTimeDocument, options);
}
export function useGetExternalWebinarLiveTimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExternalWebinarLiveTimeQuery,
    GetExternalWebinarLiveTimeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetExternalWebinarLiveTimeQuery,
    GetExternalWebinarLiveTimeQueryVariables
  >(GetExternalWebinarLiveTimeDocument, options);
}
export type GetExternalWebinarLiveTimeQueryHookResult = ReturnType<
  typeof useGetExternalWebinarLiveTimeQuery
>;
export type GetExternalWebinarLiveTimeLazyQueryHookResult = ReturnType<
  typeof useGetExternalWebinarLiveTimeLazyQuery
>;
export type GetExternalWebinarLiveTimeQueryResult = Apollo.QueryResult<
  GetExternalWebinarLiveTimeQuery,
  GetExternalWebinarLiveTimeQueryVariables
>;
export const GetLayoutSubModulesDocument = gql`
  query getLayoutSubModules($courseModuleId: ID!) {
    courseModule(id: $courseModuleId) {
      ... on LayoutModuleNode {
        id
        submodules {
          totalCount
          edges {
            node {
              ...courseModuleUnionOverviewWithPartecipationData
            }
          }
        }
      }
    }
  }
  ${CourseModuleUnionOverviewWithPartecipationDataFragmentDoc}
`;

/**
 * __useGetLayoutSubModulesQuery__
 *
 * To run a query within a React component, call `useGetLayoutSubModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLayoutSubModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLayoutSubModulesQuery({
 *   variables: {
 *      courseModuleId: // value for 'courseModuleId'
 *   },
 * });
 */
export function useGetLayoutSubModulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLayoutSubModulesQuery,
    GetLayoutSubModulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLayoutSubModulesQuery,
    GetLayoutSubModulesQueryVariables
  >(GetLayoutSubModulesDocument, options);
}
export function useGetLayoutSubModulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLayoutSubModulesQuery,
    GetLayoutSubModulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLayoutSubModulesQuery,
    GetLayoutSubModulesQueryVariables
  >(GetLayoutSubModulesDocument, options);
}
export type GetLayoutSubModulesQueryHookResult = ReturnType<
  typeof useGetLayoutSubModulesQuery
>;
export type GetLayoutSubModulesLazyQueryHookResult = ReturnType<
  typeof useGetLayoutSubModulesLazyQuery
>;
export type GetLayoutSubModulesQueryResult = Apollo.QueryResult<
  GetLayoutSubModulesQuery,
  GetLayoutSubModulesQueryVariables
>;
export const AllCompanyParticipationsDocument = gql`
  query allCompanyParticipations(
    $companyId: ID
    $first: Int
    $last: Int
    $before: String
    $after: String
    $search: String
  ) {
    allCompanyParticipations(
      companyId: $companyId
      first: $first
      last: $last
      before: $before
      after: $after
      search: $search
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          data
          id
          status
          isOnline
          created
          course {
            id
            name
          }
          user {
            email
            displayName
          }
        }
      }
    }
  }
`;

/**
 * __useAllCompanyParticipationsQuery__
 *
 * To run a query within a React component, call `useAllCompanyParticipationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCompanyParticipationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCompanyParticipationsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAllCompanyParticipationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllCompanyParticipationsQuery,
    AllCompanyParticipationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllCompanyParticipationsQuery,
    AllCompanyParticipationsQueryVariables
  >(AllCompanyParticipationsDocument, options);
}
export function useAllCompanyParticipationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCompanyParticipationsQuery,
    AllCompanyParticipationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllCompanyParticipationsQuery,
    AllCompanyParticipationsQueryVariables
  >(AllCompanyParticipationsDocument, options);
}
export type AllCompanyParticipationsQueryHookResult = ReturnType<
  typeof useAllCompanyParticipationsQuery
>;
export type AllCompanyParticipationsLazyQueryHookResult = ReturnType<
  typeof useAllCompanyParticipationsLazyQuery
>;
export type AllCompanyParticipationsQueryResult = Apollo.QueryResult<
  AllCompanyParticipationsQuery,
  AllCompanyParticipationsQueryVariables
>;
export const GetCourseParticipationsDocument = gql`
  query getCourseParticipations(
    $id: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $isOnline: Boolean
    $status: CourseParticipationStatus
    $searchText: String
  ) {
    course(id: $id) {
      id
      participations(
        first: $first
        last: $last
        before: $before
        after: $after
        isOnline: $isOnline
        status: $status
        search: $searchText
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
        edges {
          node {
            id
            getStatusDisplay
            status
            data
            dataChanges
            created
            modified
            isOnline
            invitation {
              id
            }
            formResponse {
              id
            }
            user {
              id
              avatar(size: 40)
              isAnonymous
              isStaff
              displayName
              email
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCourseParticipationsQuery__
 *
 * To run a query within a React component, call `useGetCourseParticipationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseParticipationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseParticipationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      isOnline: // value for 'isOnline'
 *      status: // value for 'status'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetCourseParticipationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseParticipationsQuery,
    GetCourseParticipationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCourseParticipationsQuery,
    GetCourseParticipationsQueryVariables
  >(GetCourseParticipationsDocument, options);
}
export function useGetCourseParticipationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseParticipationsQuery,
    GetCourseParticipationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseParticipationsQuery,
    GetCourseParticipationsQueryVariables
  >(GetCourseParticipationsDocument, options);
}
export type GetCourseParticipationsQueryHookResult = ReturnType<
  typeof useGetCourseParticipationsQuery
>;
export type GetCourseParticipationsLazyQueryHookResult = ReturnType<
  typeof useGetCourseParticipationsLazyQuery
>;
export type GetCourseParticipationsQueryResult = Apollo.QueryResult<
  GetCourseParticipationsQuery,
  GetCourseParticipationsQueryVariables
>;
export const GetCourseParticipationsTotalCountDocument = gql`
  query getCourseParticipationsTotalCount($id: ID!) {
    course(id: $id) {
      id
      participations {
        totalCount
      }
    }
  }
`;

/**
 * __useGetCourseParticipationsTotalCountQuery__
 *
 * To run a query within a React component, call `useGetCourseParticipationsTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseParticipationsTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseParticipationsTotalCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseParticipationsTotalCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseParticipationsTotalCountQuery,
    GetCourseParticipationsTotalCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCourseParticipationsTotalCountQuery,
    GetCourseParticipationsTotalCountQueryVariables
  >(GetCourseParticipationsTotalCountDocument, options);
}
export function useGetCourseParticipationsTotalCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseParticipationsTotalCountQuery,
    GetCourseParticipationsTotalCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseParticipationsTotalCountQuery,
    GetCourseParticipationsTotalCountQueryVariables
  >(GetCourseParticipationsTotalCountDocument, options);
}
export type GetCourseParticipationsTotalCountQueryHookResult = ReturnType<
  typeof useGetCourseParticipationsTotalCountQuery
>;
export type GetCourseParticipationsTotalCountLazyQueryHookResult = ReturnType<
  typeof useGetCourseParticipationsTotalCountLazyQuery
>;
export type GetCourseParticipationsTotalCountQueryResult = Apollo.QueryResult<
  GetCourseParticipationsTotalCountQuery,
  GetCourseParticipationsTotalCountQueryVariables
>;
export const GetMyCourseParticipationStatusDocument = gql`
  query getMyCourseParticipationStatus($slug: String!) {
    publicCourseBySlug(slug: $slug) {
      id
      participation {
        id
        status
        created
      }
    }
  }
`;

/**
 * __useGetMyCourseParticipationStatusQuery__
 *
 * To run a query within a React component, call `useGetMyCourseParticipationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCourseParticipationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCourseParticipationStatusQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetMyCourseParticipationStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyCourseParticipationStatusQuery,
    GetMyCourseParticipationStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyCourseParticipationStatusQuery,
    GetMyCourseParticipationStatusQueryVariables
  >(GetMyCourseParticipationStatusDocument, options);
}
export function useGetMyCourseParticipationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyCourseParticipationStatusQuery,
    GetMyCourseParticipationStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyCourseParticipationStatusQuery,
    GetMyCourseParticipationStatusQueryVariables
  >(GetMyCourseParticipationStatusDocument, options);
}
export type GetMyCourseParticipationStatusQueryHookResult = ReturnType<
  typeof useGetMyCourseParticipationStatusQuery
>;
export type GetMyCourseParticipationStatusLazyQueryHookResult = ReturnType<
  typeof useGetMyCourseParticipationStatusLazyQuery
>;
export type GetMyCourseParticipationStatusQueryResult = Apollo.QueryResult<
  GetMyCourseParticipationStatusQuery,
  GetMyCourseParticipationStatusQueryVariables
>;
export const GetMyCourseParticipationDocument = gql`
  query getMyCourseParticipation($slug: String!) {
    publicCourseBySlug(slug: $slug) {
      id
      participation {
        qrcode {
          url
        }
        data
        id
        status
        lockedModules {
          ...courseModuleUnionId
        }
      }
    }
  }
  ${CourseModuleUnionIdFragmentDoc}
`;

/**
 * __useGetMyCourseParticipationQuery__
 *
 * To run a query within a React component, call `useGetMyCourseParticipationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCourseParticipationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCourseParticipationQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetMyCourseParticipationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyCourseParticipationQuery,
    GetMyCourseParticipationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyCourseParticipationQuery,
    GetMyCourseParticipationQueryVariables
  >(GetMyCourseParticipationDocument, options);
}
export function useGetMyCourseParticipationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyCourseParticipationQuery,
    GetMyCourseParticipationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyCourseParticipationQuery,
    GetMyCourseParticipationQueryVariables
  >(GetMyCourseParticipationDocument, options);
}
export type GetMyCourseParticipationQueryHookResult = ReturnType<
  typeof useGetMyCourseParticipationQuery
>;
export type GetMyCourseParticipationLazyQueryHookResult = ReturnType<
  typeof useGetMyCourseParticipationLazyQuery
>;
export type GetMyCourseParticipationQueryResult = Apollo.QueryResult<
  GetMyCourseParticipationQuery,
  GetMyCourseParticipationQueryVariables
>;
export const GetMyCourseParticipationsDocument = gql`
  query getMyCourseParticipations(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $course: ID
    $company: ID
    $user: ID
    $manager: ID
    $hasCertificate: Boolean
  ) {
    courseParticipations(
      first: $first
      last: $last
      before: $before
      after: $after
      course: $course
      course_Company: $company
      user: $user
      manager: $manager
      hasCertificate: $hasCertificate
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          status
          getStatusDisplay
          created
          partial
          data
          course {
            id
            slug
            name
            startDate
            endDate
            listed
            available
            company {
              id
              name
            }
            cover {
              thumbnail(height: 400) {
                url
              }
            }
          }
          certificate {
            id
            created
            file {
              url
            }
            png {
              id
              format
              file {
                name
                fileName
                url
              }
            }
            pdf {
              id
              format
              file {
                name
                fileName
                url
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMyCourseParticipationsQuery__
 *
 * To run a query within a React component, call `useGetMyCourseParticipationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCourseParticipationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCourseParticipationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      course: // value for 'course'
 *      company: // value for 'company'
 *      user: // value for 'user'
 *      manager: // value for 'manager'
 *      hasCertificate: // value for 'hasCertificate'
 *   },
 * });
 */
export function useGetMyCourseParticipationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyCourseParticipationsQuery,
    GetMyCourseParticipationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyCourseParticipationsQuery,
    GetMyCourseParticipationsQueryVariables
  >(GetMyCourseParticipationsDocument, options);
}
export function useGetMyCourseParticipationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyCourseParticipationsQuery,
    GetMyCourseParticipationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyCourseParticipationsQuery,
    GetMyCourseParticipationsQueryVariables
  >(GetMyCourseParticipationsDocument, options);
}
export type GetMyCourseParticipationsQueryHookResult = ReturnType<
  typeof useGetMyCourseParticipationsQuery
>;
export type GetMyCourseParticipationsLazyQueryHookResult = ReturnType<
  typeof useGetMyCourseParticipationsLazyQuery
>;
export type GetMyCourseParticipationsQueryResult = Apollo.QueryResult<
  GetMyCourseParticipationsQuery,
  GetMyCourseParticipationsQueryVariables
>;
export const GetManagerCourseParticipationsTotalCountDocument = gql`
  query getManagerCourseParticipationsTotalCount($manager: ID, $id: ID!) {
    course(id: $id) {
      id
      participations(manager: $manager) {
        totalCount
        edges {
          node {
            manager {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetManagerCourseParticipationsTotalCountQuery__
 *
 * To run a query within a React component, call `useGetManagerCourseParticipationsTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagerCourseParticipationsTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagerCourseParticipationsTotalCountQuery({
 *   variables: {
 *      manager: // value for 'manager'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetManagerCourseParticipationsTotalCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagerCourseParticipationsTotalCountQuery,
    GetManagerCourseParticipationsTotalCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManagerCourseParticipationsTotalCountQuery,
    GetManagerCourseParticipationsTotalCountQueryVariables
  >(GetManagerCourseParticipationsTotalCountDocument, options);
}
export function useGetManagerCourseParticipationsTotalCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagerCourseParticipationsTotalCountQuery,
    GetManagerCourseParticipationsTotalCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManagerCourseParticipationsTotalCountQuery,
    GetManagerCourseParticipationsTotalCountQueryVariables
  >(GetManagerCourseParticipationsTotalCountDocument, options);
}
export type GetManagerCourseParticipationsTotalCountQueryHookResult =
  ReturnType<typeof useGetManagerCourseParticipationsTotalCountQuery>;
export type GetManagerCourseParticipationsTotalCountLazyQueryHookResult =
  ReturnType<typeof useGetManagerCourseParticipationsTotalCountLazyQuery>;
export type GetManagerCourseParticipationsTotalCountQueryResult =
  Apollo.QueryResult<
    GetManagerCourseParticipationsTotalCountQuery,
    GetManagerCourseParticipationsTotalCountQueryVariables
  >;
export const GetUserCourseParticipationsDocument = gql`
  query getUserCourseParticipations(
    $user: ID
    $manager: ID
    $id: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $searchText: String
  ) {
    course(id: $id) {
      id
      participations(
        user: $user
        manager: $manager
        first: $first
        last: $last
        before: $before
        after: $after
        search: $searchText
      ) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
          __typename
        }
        totalCount
        edges {
          node {
            id
            status
            getStatusDisplay
            created
            modified
            formResponse {
              id
            }
            data
            dataChanges
            isOnline
            course {
              id
            }
            user {
              id
              avatar(size: 40)
              displayName
              email
              isAnonymous
            }
            manager {
              id
              avatar(size: 40)
              displayName
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetUserCourseParticipationsQuery__
 *
 * To run a query within a React component, call `useGetUserCourseParticipationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCourseParticipationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCourseParticipationsQuery({
 *   variables: {
 *      user: // value for 'user'
 *      manager: // value for 'manager'
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetUserCourseParticipationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserCourseParticipationsQuery,
    GetUserCourseParticipationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserCourseParticipationsQuery,
    GetUserCourseParticipationsQueryVariables
  >(GetUserCourseParticipationsDocument, options);
}
export function useGetUserCourseParticipationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserCourseParticipationsQuery,
    GetUserCourseParticipationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserCourseParticipationsQuery,
    GetUserCourseParticipationsQueryVariables
  >(GetUserCourseParticipationsDocument, options);
}
export type GetUserCourseParticipationsQueryHookResult = ReturnType<
  typeof useGetUserCourseParticipationsQuery
>;
export type GetUserCourseParticipationsLazyQueryHookResult = ReturnType<
  typeof useGetUserCourseParticipationsLazyQuery
>;
export type GetUserCourseParticipationsQueryResult = Apollo.QueryResult<
  GetUserCourseParticipationsQuery,
  GetUserCourseParticipationsQueryVariables
>;
export const GetMyCertificateDocument = gql`
  query getMyCertificate($company: ID, $user: ID!) {
    courseCertificates(company: $company, user: $user) {
      user {
        id
      }
      courseParticipation {
        course {
          name
        }
      }
      courseModuleAssessment {
        courseModule {
          name
        }
        courseParticipation {
          course {
            name
          }
        }
      }
      certificate {
        id
        created
        file {
          url
        }
        png {
          id
          format
          file {
            name
            fileName
            url
          }
        }
        pdf {
          id
          format
          file {
            name
            fileName
            url
          }
        }
      }
    }
  }
`;

/**
 * __useGetMyCertificateQuery__
 *
 * To run a query within a React component, call `useGetMyCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCertificateQuery({
 *   variables: {
 *      company: // value for 'company'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetMyCertificateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyCertificateQuery,
    GetMyCertificateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyCertificateQuery, GetMyCertificateQueryVariables>(
    GetMyCertificateDocument,
    options,
  );
}
export function useGetMyCertificateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyCertificateQuery,
    GetMyCertificateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyCertificateQuery,
    GetMyCertificateQueryVariables
  >(GetMyCertificateDocument, options);
}
export type GetMyCertificateQueryHookResult = ReturnType<
  typeof useGetMyCertificateQuery
>;
export type GetMyCertificateLazyQueryHookResult = ReturnType<
  typeof useGetMyCertificateLazyQuery
>;
export type GetMyCertificateQueryResult = Apollo.QueryResult<
  GetMyCertificateQuery,
  GetMyCertificateQueryVariables
>;
export const CourseUserParticipationsQuotasDocument = gql`
  query courseUserParticipationsQuotas($id: ID!) {
    courseUserParticipationsQuotas(id: $id) {
      id
      participationsQuota
      label
      user {
        id
        avatar
        displayName
        email
      }
    }
  }
`;

/**
 * __useCourseUserParticipationsQuotasQuery__
 *
 * To run a query within a React component, call `useCourseUserParticipationsQuotasQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseUserParticipationsQuotasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseUserParticipationsQuotasQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourseUserParticipationsQuotasQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseUserParticipationsQuotasQuery,
    CourseUserParticipationsQuotasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourseUserParticipationsQuotasQuery,
    CourseUserParticipationsQuotasQueryVariables
  >(CourseUserParticipationsQuotasDocument, options);
}
export function useCourseUserParticipationsQuotasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseUserParticipationsQuotasQuery,
    CourseUserParticipationsQuotasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourseUserParticipationsQuotasQuery,
    CourseUserParticipationsQuotasQueryVariables
  >(CourseUserParticipationsQuotasDocument, options);
}
export type CourseUserParticipationsQuotasQueryHookResult = ReturnType<
  typeof useCourseUserParticipationsQuotasQuery
>;
export type CourseUserParticipationsQuotasLazyQueryHookResult = ReturnType<
  typeof useCourseUserParticipationsQuotasLazyQuery
>;
export type CourseUserParticipationsQuotasQueryResult = Apollo.QueryResult<
  CourseUserParticipationsQuotasQuery,
  CourseUserParticipationsQuotasQueryVariables
>;
export const CourseMyParticipationsQuotasDocument = gql`
  query courseMyParticipationsQuotas($courseId: ID!) {
    courseMyParticipationsQuotas(courseId: $courseId) {
      id
      participationsQuota
      label
    }
  }
`;

/**
 * __useCourseMyParticipationsQuotasQuery__
 *
 * To run a query within a React component, call `useCourseMyParticipationsQuotasQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseMyParticipationsQuotasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseMyParticipationsQuotasQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCourseMyParticipationsQuotasQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseMyParticipationsQuotasQuery,
    CourseMyParticipationsQuotasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourseMyParticipationsQuotasQuery,
    CourseMyParticipationsQuotasQueryVariables
  >(CourseMyParticipationsQuotasDocument, options);
}
export function useCourseMyParticipationsQuotasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseMyParticipationsQuotasQuery,
    CourseMyParticipationsQuotasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourseMyParticipationsQuotasQuery,
    CourseMyParticipationsQuotasQueryVariables
  >(CourseMyParticipationsQuotasDocument, options);
}
export type CourseMyParticipationsQuotasQueryHookResult = ReturnType<
  typeof useCourseMyParticipationsQuotasQuery
>;
export type CourseMyParticipationsQuotasLazyQueryHookResult = ReturnType<
  typeof useCourseMyParticipationsQuotasLazyQuery
>;
export type CourseMyParticipationsQuotasQueryResult = Apollo.QueryResult<
  CourseMyParticipationsQuotasQuery,
  CourseMyParticipationsQuotasQueryVariables
>;
export const CourseUsersParticipationsQuotasDocument = gql`
  query courseUsersParticipationsQuotas($courseId: ID!) {
    courseUsersParticipationsQuotas(courseId: $courseId) {
      totalCount
      edges {
        node {
          id
          participationsQuota
          label
          user {
            id
            avatar
            displayName
            email
          }
        }
      }
    }
  }
`;

/**
 * __useCourseUsersParticipationsQuotasQuery__
 *
 * To run a query within a React component, call `useCourseUsersParticipationsQuotasQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourseUsersParticipationsQuotasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourseUsersParticipationsQuotasQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCourseUsersParticipationsQuotasQuery(
  baseOptions: Apollo.QueryHookOptions<
    CourseUsersParticipationsQuotasQuery,
    CourseUsersParticipationsQuotasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CourseUsersParticipationsQuotasQuery,
    CourseUsersParticipationsQuotasQueryVariables
  >(CourseUsersParticipationsQuotasDocument, options);
}
export function useCourseUsersParticipationsQuotasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CourseUsersParticipationsQuotasQuery,
    CourseUsersParticipationsQuotasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CourseUsersParticipationsQuotasQuery,
    CourseUsersParticipationsQuotasQueryVariables
  >(CourseUsersParticipationsQuotasDocument, options);
}
export type CourseUsersParticipationsQuotasQueryHookResult = ReturnType<
  typeof useCourseUsersParticipationsQuotasQuery
>;
export type CourseUsersParticipationsQuotasLazyQueryHookResult = ReturnType<
  typeof useCourseUsersParticipationsQuotasLazyQuery
>;
export type CourseUsersParticipationsQuotasQueryResult = Apollo.QueryResult<
  CourseUsersParticipationsQuotasQuery,
  CourseUsersParticipationsQuotasQueryVariables
>;
export const OverviewPublicCourseBySlugDocument = gql`
  query overviewPublicCourseBySlug($slug: String!) {
    publicCourseBySlug(slug: $slug) {
      id
      name
      slug
      overview
      code
      demo
      description
      headline
      created
      startDate
      layoutType
      navigationType
      jsxContent
      displayEndCourseContent
      displayParticipationSuccessToast
      participationCustomToastSuccessMessage
      endCourseContent
      endDate
      tags {
        name
        slug
        id
      }
      userHasPermission {
        viewCourse
        changeCourse
        moderateChatCourse
        manageParticipationCourse
        downloadFullReport
        anyPermission
      }
      pdfProgramButtonLabel
      pdfProgram {
        fileName
        url
        extension
      }
      cover {
        thumbnail(width: 1680, height: 400, crop: "center") {
          url
        }
      }
      background {
        thumbnail(width: 1280, height: 916, crop: "center") {
          url
        }
      }
      backgroundFullScreen: background {
        thumbnail(width: 1920, height: 1080, crop: "center") {
          url
        }
      }
      acceptAnonyumousEnrollment
      defaultLanguage
      theme {
        id
        enableInDashboard
      }
      forceAnonyumousEnrollment
      onlyOneParticipationPerInvitation
      requireDataParticipation
      acceptsParticipationByExternalUuid
      externalUuidParameterName
      externalUuidParameterRequired
      generateParticipationQrcode
      participationForm {
        id
      }
      participationsCount
      displayParticipantsCounter
      displayContinueButton
      authorListLabel
      authorsGroups {
        edges {
          node {
            ...authorsGroupNode
          }
        }
      }
      authors {
        edges {
          node {
            ...authorNode
          }
        }
      }
      participation {
        id
        status
      }
      categories {
        edges {
          node {
            id
            name
            description
          }
        }
      }
      submodules {
        totalCount
        edges {
          node {
            ...courseModuleUnionOverview
          }
        }
      }
    }
  }
  ${AuthorsGroupNodeFragmentDoc}
  ${AuthorNodeFragmentDoc}
  ${CourseModuleUnionOverviewFragmentDoc}
`;

/**
 * __useOverviewPublicCourseBySlugQuery__
 *
 * To run a query within a React component, call `useOverviewPublicCourseBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewPublicCourseBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewPublicCourseBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useOverviewPublicCourseBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    OverviewPublicCourseBySlugQuery,
    OverviewPublicCourseBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OverviewPublicCourseBySlugQuery,
    OverviewPublicCourseBySlugQueryVariables
  >(OverviewPublicCourseBySlugDocument, options);
}
export function useOverviewPublicCourseBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OverviewPublicCourseBySlugQuery,
    OverviewPublicCourseBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OverviewPublicCourseBySlugQuery,
    OverviewPublicCourseBySlugQueryVariables
  >(OverviewPublicCourseBySlugDocument, options);
}
export type OverviewPublicCourseBySlugQueryHookResult = ReturnType<
  typeof useOverviewPublicCourseBySlugQuery
>;
export type OverviewPublicCourseBySlugLazyQueryHookResult = ReturnType<
  typeof useOverviewPublicCourseBySlugLazyQuery
>;
export type OverviewPublicCourseBySlugQueryResult = Apollo.QueryResult<
  OverviewPublicCourseBySlugQuery,
  OverviewPublicCourseBySlugQueryVariables
>;
export const ReminderTestDocument = gql`
  mutation reminderTest($input: ReminderTestMutationInput!) {
    reminderTest(input: $input) {
      status
      errors {
        field
        message
      }
    }
  }
`;
export type ReminderTestMutationFn = Apollo.MutationFunction<
  ReminderTestMutation,
  ReminderTestMutationVariables
>;

/**
 * __useReminderTestMutation__
 *
 * To run a mutation, you first call `useReminderTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReminderTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reminderTestMutation, { data, loading, error }] = useReminderTestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReminderTestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReminderTestMutation,
    ReminderTestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReminderTestMutation,
    ReminderTestMutationVariables
  >(ReminderTestDocument, options);
}
export type ReminderTestMutationHookResult = ReturnType<
  typeof useReminderTestMutation
>;
export type ReminderTestMutationResult =
  Apollo.MutationResult<ReminderTestMutation>;
export type ReminderTestMutationOptions = Apollo.BaseMutationOptions<
  ReminderTestMutation,
  ReminderTestMutationVariables
>;
export const ReminderCreateDocument = gql`
  mutation reminderCreate($input: ReminderCreateMutationInput!) {
    reminderCreate(input: $input) {
      reminder {
        name
        kwargs
        enabled
        task
        id
        clocked {
          clockedTime
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
export type ReminderCreateMutationFn = Apollo.MutationFunction<
  ReminderCreateMutation,
  ReminderCreateMutationVariables
>;

/**
 * __useReminderCreateMutation__
 *
 * To run a mutation, you first call `useReminderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReminderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reminderCreateMutation, { data, loading, error }] = useReminderCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReminderCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReminderCreateMutation,
    ReminderCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReminderCreateMutation,
    ReminderCreateMutationVariables
  >(ReminderCreateDocument, options);
}
export type ReminderCreateMutationHookResult = ReturnType<
  typeof useReminderCreateMutation
>;
export type ReminderCreateMutationResult =
  Apollo.MutationResult<ReminderCreateMutation>;
export type ReminderCreateMutationOptions = Apollo.BaseMutationOptions<
  ReminderCreateMutation,
  ReminderCreateMutationVariables
>;
export const ReminderUpdateDocument = gql`
  mutation reminderUpdate($input: ReminderUpdateMutationInput!) {
    reminderUpdate(input: $input) {
      reminder {
        name
        kwargs
        enabled
        task
        id
        clocked {
          clockedTime
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
export type ReminderUpdateMutationFn = Apollo.MutationFunction<
  ReminderUpdateMutation,
  ReminderUpdateMutationVariables
>;

/**
 * __useReminderUpdateMutation__
 *
 * To run a mutation, you first call `useReminderUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReminderUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reminderUpdateMutation, { data, loading, error }] = useReminderUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReminderUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReminderUpdateMutation,
    ReminderUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReminderUpdateMutation,
    ReminderUpdateMutationVariables
  >(ReminderUpdateDocument, options);
}
export type ReminderUpdateMutationHookResult = ReturnType<
  typeof useReminderUpdateMutation
>;
export type ReminderUpdateMutationResult =
  Apollo.MutationResult<ReminderUpdateMutation>;
export type ReminderUpdateMutationOptions = Apollo.BaseMutationOptions<
  ReminderUpdateMutation,
  ReminderUpdateMutationVariables
>;
export const ReminderDeleteDocument = gql`
  mutation reminderDelete($input: ReminderDeleteMutationInput!) {
    reminderDelete(input: $input) {
      reminder {
        name
        kwargs
        enabled
        task
        id
        clocked {
          clockedTime
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
export type ReminderDeleteMutationFn = Apollo.MutationFunction<
  ReminderDeleteMutation,
  ReminderDeleteMutationVariables
>;

/**
 * __useReminderDeleteMutation__
 *
 * To run a mutation, you first call `useReminderDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReminderDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reminderDeleteMutation, { data, loading, error }] = useReminderDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReminderDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReminderDeleteMutation,
    ReminderDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReminderDeleteMutation,
    ReminderDeleteMutationVariables
  >(ReminderDeleteDocument, options);
}
export type ReminderDeleteMutationHookResult = ReturnType<
  typeof useReminderDeleteMutation
>;
export type ReminderDeleteMutationResult =
  Apollo.MutationResult<ReminderDeleteMutation>;
export type ReminderDeleteMutationOptions = Apollo.BaseMutationOptions<
  ReminderDeleteMutation,
  ReminderDeleteMutationVariables
>;
export const ReminderDocument = gql`
  query reminder($id: ID!) {
    reminder(id: $id) {
      name
      kwargs
      enabled
      task
      result {
        edges {
          node {
            status
            taskKwargs
            dateDone
            result
          }
        }
      }
      id
      clocked {
        clockedTime
        id
      }
    }
  }
`;

/**
 * __useReminderQuery__
 *
 * To run a query within a React component, call `useReminderQuery` and pass it any options that fit your needs.
 * When your component renders, `useReminderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReminderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReminderQuery(
  baseOptions: Apollo.QueryHookOptions<ReminderQuery, ReminderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReminderQuery, ReminderQueryVariables>(
    ReminderDocument,
    options,
  );
}
export function useReminderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReminderQuery,
    ReminderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReminderQuery, ReminderQueryVariables>(
    ReminderDocument,
    options,
  );
}
export type ReminderQueryHookResult = ReturnType<typeof useReminderQuery>;
export type ReminderLazyQueryHookResult = ReturnType<
  typeof useReminderLazyQuery
>;
export type ReminderQueryResult = Apollo.QueryResult<
  ReminderQuery,
  ReminderQueryVariables
>;
export const CreateExternalWebinarModuleDocument = gql`
  mutation createExternalWebinarModule(
    $input: ExternalWebinarModuleCreateMutationInput!
  ) {
    externalWebinarModuleCreate(input: $input) {
      externalWebinarModule {
        id
        liveUrl
        recordedUrl
        status
        endedAt
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CreateExternalWebinarModuleMutationFn = Apollo.MutationFunction<
  CreateExternalWebinarModuleMutation,
  CreateExternalWebinarModuleMutationVariables
>;

/**
 * __useCreateExternalWebinarModuleMutation__
 *
 * To run a mutation, you first call `useCreateExternalWebinarModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalWebinarModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalWebinarModuleMutation, { data, loading, error }] = useCreateExternalWebinarModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExternalWebinarModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExternalWebinarModuleMutation,
    CreateExternalWebinarModuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExternalWebinarModuleMutation,
    CreateExternalWebinarModuleMutationVariables
  >(CreateExternalWebinarModuleDocument, options);
}
export type CreateExternalWebinarModuleMutationHookResult = ReturnType<
  typeof useCreateExternalWebinarModuleMutation
>;
export type CreateExternalWebinarModuleMutationResult =
  Apollo.MutationResult<CreateExternalWebinarModuleMutation>;
export type CreateExternalWebinarModuleMutationOptions =
  Apollo.BaseMutationOptions<
    CreateExternalWebinarModuleMutation,
    CreateExternalWebinarModuleMutationVariables
  >;
export const CreateSurveyModuleDocument = gql`
  mutation createSurveyModule($input: SurveyModuleCreateMutationInput!) {
    surveyModuleCreate(input: $input) {
      surveyModule {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CreateSurveyModuleMutationFn = Apollo.MutationFunction<
  CreateSurveyModuleMutation,
  CreateSurveyModuleMutationVariables
>;

/**
 * __useCreateSurveyModuleMutation__
 *
 * To run a mutation, you first call `useCreateSurveyModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyModuleMutation, { data, loading, error }] = useCreateSurveyModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSurveyModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSurveyModuleMutation,
    CreateSurveyModuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSurveyModuleMutation,
    CreateSurveyModuleMutationVariables
  >(CreateSurveyModuleDocument, options);
}
export type CreateSurveyModuleMutationHookResult = ReturnType<
  typeof useCreateSurveyModuleMutation
>;
export type CreateSurveyModuleMutationResult =
  Apollo.MutationResult<CreateSurveyModuleMutation>;
export type CreateSurveyModuleMutationOptions = Apollo.BaseMutationOptions<
  CreateSurveyModuleMutation,
  CreateSurveyModuleMutationVariables
>;
export const CreateLayoutModuleDocument = gql`
  mutation createLayoutModule($input: LayoutModuleCreateMutationInput!) {
    layoutModuleCreate(input: $input) {
      layoutModule {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CreateLayoutModuleMutationFn = Apollo.MutationFunction<
  CreateLayoutModuleMutation,
  CreateLayoutModuleMutationVariables
>;

/**
 * __useCreateLayoutModuleMutation__
 *
 * To run a mutation, you first call `useCreateLayoutModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLayoutModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLayoutModuleMutation, { data, loading, error }] = useCreateLayoutModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLayoutModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLayoutModuleMutation,
    CreateLayoutModuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLayoutModuleMutation,
    CreateLayoutModuleMutationVariables
  >(CreateLayoutModuleDocument, options);
}
export type CreateLayoutModuleMutationHookResult = ReturnType<
  typeof useCreateLayoutModuleMutation
>;
export type CreateLayoutModuleMutationResult =
  Apollo.MutationResult<CreateLayoutModuleMutation>;
export type CreateLayoutModuleMutationOptions = Apollo.BaseMutationOptions<
  CreateLayoutModuleMutation,
  CreateLayoutModuleMutationVariables
>;
export const CreateVideoModuleDocument = gql`
  mutation createVideoModule($input: VideoModuleCreateMutationInput!) {
    videoModuleCreate(input: $input) {
      videoModule {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CreateVideoModuleMutationFn = Apollo.MutationFunction<
  CreateVideoModuleMutation,
  CreateVideoModuleMutationVariables
>;

/**
 * __useCreateVideoModuleMutation__
 *
 * To run a mutation, you first call `useCreateVideoModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoModuleMutation, { data, loading, error }] = useCreateVideoModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVideoModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVideoModuleMutation,
    CreateVideoModuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateVideoModuleMutation,
    CreateVideoModuleMutationVariables
  >(CreateVideoModuleDocument, options);
}
export type CreateVideoModuleMutationHookResult = ReturnType<
  typeof useCreateVideoModuleMutation
>;
export type CreateVideoModuleMutationResult =
  Apollo.MutationResult<CreateVideoModuleMutation>;
export type CreateVideoModuleMutationOptions = Apollo.BaseMutationOptions<
  CreateVideoModuleMutation,
  CreateVideoModuleMutationVariables
>;
export const CreateIframeModuleDocument = gql`
  mutation createIframeModule($input: IframeModuleCreateMutationInput!) {
    iframeModuleCreate(input: $input) {
      iframeModule {
        id
        url
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CreateIframeModuleMutationFn = Apollo.MutationFunction<
  CreateIframeModuleMutation,
  CreateIframeModuleMutationVariables
>;

/**
 * __useCreateIframeModuleMutation__
 *
 * To run a mutation, you first call `useCreateIframeModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIframeModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIframeModuleMutation, { data, loading, error }] = useCreateIframeModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateIframeModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIframeModuleMutation,
    CreateIframeModuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateIframeModuleMutation,
    CreateIframeModuleMutationVariables
  >(CreateIframeModuleDocument, options);
}
export type CreateIframeModuleMutationHookResult = ReturnType<
  typeof useCreateIframeModuleMutation
>;
export type CreateIframeModuleMutationResult =
  Apollo.MutationResult<CreateIframeModuleMutation>;
export type CreateIframeModuleMutationOptions = Apollo.BaseMutationOptions<
  CreateIframeModuleMutation,
  CreateIframeModuleMutationVariables
>;
export const CreateDocumentModuleDocument = gql`
  mutation createDocumentModule($input: DocumentModuleCreateMutationInput!) {
    documentModuleCreate(input: $input) {
      documentModule {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CreateDocumentModuleMutationFn = Apollo.MutationFunction<
  CreateDocumentModuleMutation,
  CreateDocumentModuleMutationVariables
>;

/**
 * __useCreateDocumentModuleMutation__
 *
 * To run a mutation, you first call `useCreateDocumentModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentModuleMutation, { data, loading, error }] = useCreateDocumentModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDocumentModuleMutation,
    CreateDocumentModuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDocumentModuleMutation,
    CreateDocumentModuleMutationVariables
  >(CreateDocumentModuleDocument, options);
}
export type CreateDocumentModuleMutationHookResult = ReturnType<
  typeof useCreateDocumentModuleMutation
>;
export type CreateDocumentModuleMutationResult =
  Apollo.MutationResult<CreateDocumentModuleMutation>;
export type CreateDocumentModuleMutationOptions = Apollo.BaseMutationOptions<
  CreateDocumentModuleMutation,
  CreateDocumentModuleMutationVariables
>;
export const CreateChatModuleDocument = gql`
  mutation createChatModule($input: ChatModuleCreateMutationInput!) {
    chatModuleCreate(input: $input) {
      chatModule {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CreateChatModuleMutationFn = Apollo.MutationFunction<
  CreateChatModuleMutation,
  CreateChatModuleMutationVariables
>;

/**
 * __useCreateChatModuleMutation__
 *
 * To run a mutation, you first call `useCreateChatModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatModuleMutation, { data, loading, error }] = useCreateChatModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChatModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateChatModuleMutation,
    CreateChatModuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateChatModuleMutation,
    CreateChatModuleMutationVariables
  >(CreateChatModuleDocument, options);
}
export type CreateChatModuleMutationHookResult = ReturnType<
  typeof useCreateChatModuleMutation
>;
export type CreateChatModuleMutationResult =
  Apollo.MutationResult<CreateChatModuleMutation>;
export type CreateChatModuleMutationOptions = Apollo.BaseMutationOptions<
  CreateChatModuleMutation,
  CreateChatModuleMutationVariables
>;
export const SurveyModuleUpdateDocument = gql`
  mutation surveyModuleUpdate($input: SurveyModuleUpdateMutationInput!) {
    surveyModuleUpdate(input: $input) {
      surveyModule {
        id
        name
        slug
        overview
        headline
        description
        jsxContent
        minimumScore
        minimumScoreType
        numberOfAttempts
        hideUserDataInReports
        showScoreResults
        sendEmailWhenCompleted
        disableAutoComplete
        disableCheckComplete
        completedCustomEmailContent
        completedCustomEmailSubject
        routable
        routableExact
        testingMode
        materialIcon
        order
        privacy
        availableFrom
        availableUntil
        forceLocked
        accessMode
        availableFromCustomText
        moduleLockedCustomText
        survey {
          ...SurveyNodeFragment
        }
      }
      errors {
        field
        message
      }
    }
  }
  ${SurveyNodeFragmentFragmentDoc}
`;
export type SurveyModuleUpdateMutationFn = Apollo.MutationFunction<
  SurveyModuleUpdateMutation,
  SurveyModuleUpdateMutationVariables
>;

/**
 * __useSurveyModuleUpdateMutation__
 *
 * To run a mutation, you first call `useSurveyModuleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyModuleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyModuleUpdateMutation, { data, loading, error }] = useSurveyModuleUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSurveyModuleUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyModuleUpdateMutation,
    SurveyModuleUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SurveyModuleUpdateMutation,
    SurveyModuleUpdateMutationVariables
  >(SurveyModuleUpdateDocument, options);
}
export type SurveyModuleUpdateMutationHookResult = ReturnType<
  typeof useSurveyModuleUpdateMutation
>;
export type SurveyModuleUpdateMutationResult =
  Apollo.MutationResult<SurveyModuleUpdateMutation>;
export type SurveyModuleUpdateMutationOptions = Apollo.BaseMutationOptions<
  SurveyModuleUpdateMutation,
  SurveyModuleUpdateMutationVariables
>;
export const LayoutModuleUpdateDocument = gql`
  mutation layoutModuleUpdate($input: LayoutModuleUpdateMutationInput!) {
    layoutModuleUpdate(input: $input) {
      layoutModule {
        id
        slug
        name
        overview
        headline
        description
        jsxContent
        type
        routable
        routableExact
        testingMode
        materialIcon
        disableAutoComplete
        disableCheckComplete
        completedCustomEmailContent
        completedCustomEmailSubject
        sendEmailWhenCompleted
        order
        privacy
        availableFrom
        availableUntil
        forceLocked
        accessMode
        availableFromCustomText
        moduleLockedCustomText
      }
      errors {
        field
        message
      }
    }
  }
`;
export type LayoutModuleUpdateMutationFn = Apollo.MutationFunction<
  LayoutModuleUpdateMutation,
  LayoutModuleUpdateMutationVariables
>;

/**
 * __useLayoutModuleUpdateMutation__
 *
 * To run a mutation, you first call `useLayoutModuleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLayoutModuleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [layoutModuleUpdateMutation, { data, loading, error }] = useLayoutModuleUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLayoutModuleUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LayoutModuleUpdateMutation,
    LayoutModuleUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LayoutModuleUpdateMutation,
    LayoutModuleUpdateMutationVariables
  >(LayoutModuleUpdateDocument, options);
}
export type LayoutModuleUpdateMutationHookResult = ReturnType<
  typeof useLayoutModuleUpdateMutation
>;
export type LayoutModuleUpdateMutationResult =
  Apollo.MutationResult<LayoutModuleUpdateMutation>;
export type LayoutModuleUpdateMutationOptions = Apollo.BaseMutationOptions<
  LayoutModuleUpdateMutation,
  LayoutModuleUpdateMutationVariables
>;
export const VideoModuleUpdateDocument = gql`
  mutation videoModuleUpdate($input: VideoModuleUpdateMutationInput!) {
    videoModuleUpdate(input: $input) {
      videoModule {
        id
        name
        slug
        overview
        headline
        description
        jsxContent
        url
        iframeMode
        videoDuration
        requiredUserLivePercentile
        routable
        routableExact
        testingMode
        materialIcon
        disableAutoComplete
        disableCheckComplete
        completedCustomEmailContent
        completedCustomEmailSubject
        sendEmailWhenCompleted
        order
        privacy
        availableFrom
        availableUntil
        forceLocked
        accessMode
        availableFromCustomText
        moduleLockedCustomText
      }
      errors {
        field
        message
      }
    }
  }
`;
export type VideoModuleUpdateMutationFn = Apollo.MutationFunction<
  VideoModuleUpdateMutation,
  VideoModuleUpdateMutationVariables
>;

/**
 * __useVideoModuleUpdateMutation__
 *
 * To run a mutation, you first call `useVideoModuleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoModuleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoModuleUpdateMutation, { data, loading, error }] = useVideoModuleUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVideoModuleUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VideoModuleUpdateMutation,
    VideoModuleUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VideoModuleUpdateMutation,
    VideoModuleUpdateMutationVariables
  >(VideoModuleUpdateDocument, options);
}
export type VideoModuleUpdateMutationHookResult = ReturnType<
  typeof useVideoModuleUpdateMutation
>;
export type VideoModuleUpdateMutationResult =
  Apollo.MutationResult<VideoModuleUpdateMutation>;
export type VideoModuleUpdateMutationOptions = Apollo.BaseMutationOptions<
  VideoModuleUpdateMutation,
  VideoModuleUpdateMutationVariables
>;
export const VideoModuleUserActionsDocument = gql`
  mutation videoModuleUserActions(
    $input: VideoModuleUserActionsMutationInput!
  ) {
    videoModuleUserActions(input: $input) {
      livePercentile
      errors {
        field
        message
      }
    }
  }
`;
export type VideoModuleUserActionsMutationFn = Apollo.MutationFunction<
  VideoModuleUserActionsMutation,
  VideoModuleUserActionsMutationVariables
>;

/**
 * __useVideoModuleUserActionsMutation__
 *
 * To run a mutation, you first call `useVideoModuleUserActionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoModuleUserActionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoModuleUserActionsMutation, { data, loading, error }] = useVideoModuleUserActionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVideoModuleUserActionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VideoModuleUserActionsMutation,
    VideoModuleUserActionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VideoModuleUserActionsMutation,
    VideoModuleUserActionsMutationVariables
  >(VideoModuleUserActionsDocument, options);
}
export type VideoModuleUserActionsMutationHookResult = ReturnType<
  typeof useVideoModuleUserActionsMutation
>;
export type VideoModuleUserActionsMutationResult =
  Apollo.MutationResult<VideoModuleUserActionsMutation>;
export type VideoModuleUserActionsMutationOptions = Apollo.BaseMutationOptions<
  VideoModuleUserActionsMutation,
  VideoModuleUserActionsMutationVariables
>;
export const IframeModuleUpdateDocument = gql`
  mutation iframeModuleUpdate($input: IframeModuleUpdateMutationInput!) {
    iframeModuleUpdate(input: $input) {
      iframeModule {
        id
        name
        slug
        overview
        headline
        description
        jsxContent
        url
        routable
        routableExact
        testingMode
        materialIcon
        disableAutoComplete
        disableCheckComplete
        completedCustomEmailContent
        completedCustomEmailSubject
        sendEmailWhenCompleted
        order
        privacy
        availableFrom
        availableUntil
        forceLocked
        accessMode
        availableFromCustomText
        moduleLockedCustomText
        iframeHeight
        iframeWidth
        external
        participationDataInUrlParams
      }
      errors {
        field
        message
      }
    }
  }
`;
export type IframeModuleUpdateMutationFn = Apollo.MutationFunction<
  IframeModuleUpdateMutation,
  IframeModuleUpdateMutationVariables
>;

/**
 * __useIframeModuleUpdateMutation__
 *
 * To run a mutation, you first call `useIframeModuleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIframeModuleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [iframeModuleUpdateMutation, { data, loading, error }] = useIframeModuleUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIframeModuleUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IframeModuleUpdateMutation,
    IframeModuleUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IframeModuleUpdateMutation,
    IframeModuleUpdateMutationVariables
  >(IframeModuleUpdateDocument, options);
}
export type IframeModuleUpdateMutationHookResult = ReturnType<
  typeof useIframeModuleUpdateMutation
>;
export type IframeModuleUpdateMutationResult =
  Apollo.MutationResult<IframeModuleUpdateMutation>;
export type IframeModuleUpdateMutationOptions = Apollo.BaseMutationOptions<
  IframeModuleUpdateMutation,
  IframeModuleUpdateMutationVariables
>;
export const DocumentModuleUpdateDocument = gql`
  mutation documentModuleUpdate($input: DocumentModuleUpdateMutationInput!) {
    documentModuleUpdate(input: $input) {
      documentModule {
        id
        name
        slug
        overview
        headline
        description
        jsxContent
        routable
        routableExact
        testingMode
        materialIcon
        disableAutoComplete
        disableCheckComplete
        completedCustomEmailContent
        completedCustomEmailSubject
        sendEmailWhenCompleted
        order
        privacy
        availableFrom
        availableUntil
        forceLocked
        accessMode
        availableFromCustomText
        moduleLockedCustomText
        document {
          fileName
          url
          extension
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
export type DocumentModuleUpdateMutationFn = Apollo.MutationFunction<
  DocumentModuleUpdateMutation,
  DocumentModuleUpdateMutationVariables
>;

/**
 * __useDocumentModuleUpdateMutation__
 *
 * To run a mutation, you first call `useDocumentModuleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentModuleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentModuleUpdateMutation, { data, loading, error }] = useDocumentModuleUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDocumentModuleUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DocumentModuleUpdateMutation,
    DocumentModuleUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DocumentModuleUpdateMutation,
    DocumentModuleUpdateMutationVariables
  >(DocumentModuleUpdateDocument, options);
}
export type DocumentModuleUpdateMutationHookResult = ReturnType<
  typeof useDocumentModuleUpdateMutation
>;
export type DocumentModuleUpdateMutationResult =
  Apollo.MutationResult<DocumentModuleUpdateMutation>;
export type DocumentModuleUpdateMutationOptions = Apollo.BaseMutationOptions<
  DocumentModuleUpdateMutation,
  DocumentModuleUpdateMutationVariables
>;
export const UpdateChatModuleDocument = gql`
  mutation updateChatModule($input: ChatModuleUpdateMutationInput!) {
    chatModuleUpdate(input: $input) {
      chatModule {
        id
        name
        slug
        overview
        headline
        description
        jsxContent
        routable
        routableExact
        testingMode
        materialIcon
        disableAutoComplete
        disableCheckComplete
        completedCustomEmailContent
        completedCustomEmailSubject
        sendEmailWhenCompleted
        order
        privacy
        availableFrom
        availableUntil
        forceLocked
        accessMode
        availableFromCustomText
        moduleLockedCustomText
        threadsEnabled
        broadcastChannelEnabled
        mode
      }
      errors {
        field
        message
      }
    }
  }
`;
export type UpdateChatModuleMutationFn = Apollo.MutationFunction<
  UpdateChatModuleMutation,
  UpdateChatModuleMutationVariables
>;

/**
 * __useUpdateChatModuleMutation__
 *
 * To run a mutation, you first call `useUpdateChatModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChatModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChatModuleMutation, { data, loading, error }] = useUpdateChatModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChatModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateChatModuleMutation,
    UpdateChatModuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateChatModuleMutation,
    UpdateChatModuleMutationVariables
  >(UpdateChatModuleDocument, options);
}
export type UpdateChatModuleMutationHookResult = ReturnType<
  typeof useUpdateChatModuleMutation
>;
export type UpdateChatModuleMutationResult =
  Apollo.MutationResult<UpdateChatModuleMutation>;
export type UpdateChatModuleMutationOptions = Apollo.BaseMutationOptions<
  UpdateChatModuleMutation,
  UpdateChatModuleMutationVariables
>;
export const CreateDefaultModuleDocument = gql`
  mutation createDefaultModule($input: CourseModuleCreateMutationInput!) {
    courseModuleCreate(input: $input) {
      courseModule {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
export type CreateDefaultModuleMutationFn = Apollo.MutationFunction<
  CreateDefaultModuleMutation,
  CreateDefaultModuleMutationVariables
>;

/**
 * __useCreateDefaultModuleMutation__
 *
 * To run a mutation, you first call `useCreateDefaultModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDefaultModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDefaultModuleMutation, { data, loading, error }] = useCreateDefaultModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDefaultModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDefaultModuleMutation,
    CreateDefaultModuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDefaultModuleMutation,
    CreateDefaultModuleMutationVariables
  >(CreateDefaultModuleDocument, options);
}
export type CreateDefaultModuleMutationHookResult = ReturnType<
  typeof useCreateDefaultModuleMutation
>;
export type CreateDefaultModuleMutationResult =
  Apollo.MutationResult<CreateDefaultModuleMutation>;
export type CreateDefaultModuleMutationOptions = Apollo.BaseMutationOptions<
  CreateDefaultModuleMutation,
  CreateDefaultModuleMutationVariables
>;
export const ExternalWebinarModuleUpdateDocument = gql`
  mutation externalWebinarModuleUpdate(
    $input: ExternalWebinarModuleUpdateMutationInput!
  ) {
    externalWebinarModuleUpdate(input: $input) {
      externalWebinarModule {
        id
        name
        slug
        order
        privacy
        availableFrom
        availableUntil
        forceLocked
        accessMode
        availableFromCustomText
        moduleLockedCustomText
        sendEmailWhenCompleted
        disableAutoComplete
        disableCheckComplete
        completedCustomEmailContent
        completedCustomEmailSubject
        headline
        overview
        description
        liveUrl
        recordedUrl
        liveMeeting {
          id
          meetingNo
          meetingPwd
          topic
          startTime
          data
        }
        iframeMode
        routable
        routableExact
        testingMode
        materialIcon
        externalLink
        externalLinkName
        plannedStartDate
        plannedEndDate
        requiredUserPresencePercentile
        iframeProps {
          key
          value
        }
        status
        endedAt
        chatEnabled
        wallChannelEnabled
        broadcastChannelEnabled
        variants {
          idx
          liveUrl
          recordedUrl
          iframeMode
          iframeProps {
            key
            value
          }
          name
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
export type ExternalWebinarModuleUpdateMutationFn = Apollo.MutationFunction<
  ExternalWebinarModuleUpdateMutation,
  ExternalWebinarModuleUpdateMutationVariables
>;

/**
 * __useExternalWebinarModuleUpdateMutation__
 *
 * To run a mutation, you first call `useExternalWebinarModuleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExternalWebinarModuleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [externalWebinarModuleUpdateMutation, { data, loading, error }] = useExternalWebinarModuleUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExternalWebinarModuleUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExternalWebinarModuleUpdateMutation,
    ExternalWebinarModuleUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExternalWebinarModuleUpdateMutation,
    ExternalWebinarModuleUpdateMutationVariables
  >(ExternalWebinarModuleUpdateDocument, options);
}
export type ExternalWebinarModuleUpdateMutationHookResult = ReturnType<
  typeof useExternalWebinarModuleUpdateMutation
>;
export type ExternalWebinarModuleUpdateMutationResult =
  Apollo.MutationResult<ExternalWebinarModuleUpdateMutation>;
export type ExternalWebinarModuleUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    ExternalWebinarModuleUpdateMutation,
    ExternalWebinarModuleUpdateMutationVariables
  >;
export const GetGetUserCourseParticipationDocument = gql`
  query getGetUserCourseParticipation($courseId: ID!, $participationId: ID!) {
    course(id: $courseId) {
      id
      submodules {
        edges {
          node {
            ...courseModuleUnionOverview
          }
        }
      }
      participation(participationId: $participationId) {
        id
        user {
          id
        }
      }
    }
  }
  ${CourseModuleUnionOverviewFragmentDoc}
`;

/**
 * __useGetGetUserCourseParticipationQuery__
 *
 * To run a query within a React component, call `useGetGetUserCourseParticipationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGetUserCourseParticipationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGetUserCourseParticipationQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      participationId: // value for 'participationId'
 *   },
 * });
 */
export function useGetGetUserCourseParticipationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGetUserCourseParticipationQuery,
    GetGetUserCourseParticipationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGetUserCourseParticipationQuery,
    GetGetUserCourseParticipationQueryVariables
  >(GetGetUserCourseParticipationDocument, options);
}
export function useGetGetUserCourseParticipationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGetUserCourseParticipationQuery,
    GetGetUserCourseParticipationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGetUserCourseParticipationQuery,
    GetGetUserCourseParticipationQueryVariables
  >(GetGetUserCourseParticipationDocument, options);
}
export type GetGetUserCourseParticipationQueryHookResult = ReturnType<
  typeof useGetGetUserCourseParticipationQuery
>;
export type GetGetUserCourseParticipationLazyQueryHookResult = ReturnType<
  typeof useGetGetUserCourseParticipationLazyQuery
>;
export type GetGetUserCourseParticipationQueryResult = Apollo.QueryResult<
  GetGetUserCourseParticipationQuery,
  GetGetUserCourseParticipationQueryVariables
>;
export const GetParticipationModuleAssessmentsDocument = gql`
  query getParticipationModuleAssessments($participationId: ID!) {
    courseParticipation(id: $participationId) {
      id
      created
      getStatusDisplay
      status
      data
      user {
        id
        displayName
        avatar
      }
      moduleAssessments {
        edges {
          node {
            id
            status
            startedAt
            created
            courseModule {
              id
              name
              realTypename
              materialIcon
            }
            completedAt
            getStatusDisplay
            coursemoduleassessmentexternalwebinar {
              presenceTime
              presencePercentile
              requiredUserPresencePercentile
            }
            coursemoduleassessmentsurvey {
              minimumScore
              minimumScoreType
              surveyResponsesList {
                edges {
                  node {
                    id
                    created
                    modified
                    score
                    completed
                    maxScore
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetParticipationModuleAssessmentsQuery__
 *
 * To run a query within a React component, call `useGetParticipationModuleAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipationModuleAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipationModuleAssessmentsQuery({
 *   variables: {
 *      participationId: // value for 'participationId'
 *   },
 * });
 */
export function useGetParticipationModuleAssessmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParticipationModuleAssessmentsQuery,
    GetParticipationModuleAssessmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetParticipationModuleAssessmentsQuery,
    GetParticipationModuleAssessmentsQueryVariables
  >(GetParticipationModuleAssessmentsDocument, options);
}
export function useGetParticipationModuleAssessmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParticipationModuleAssessmentsQuery,
    GetParticipationModuleAssessmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParticipationModuleAssessmentsQuery,
    GetParticipationModuleAssessmentsQueryVariables
  >(GetParticipationModuleAssessmentsDocument, options);
}
export type GetParticipationModuleAssessmentsQueryHookResult = ReturnType<
  typeof useGetParticipationModuleAssessmentsQuery
>;
export type GetParticipationModuleAssessmentsLazyQueryHookResult = ReturnType<
  typeof useGetParticipationModuleAssessmentsLazyQuery
>;
export type GetParticipationModuleAssessmentsQueryResult = Apollo.QueryResult<
  GetParticipationModuleAssessmentsQuery,
  GetParticipationModuleAssessmentsQueryVariables
>;
export const GetCourseInvitationConfigDocument = gql`
  query getCourseInvitationConfig($courseId: ID!) {
    course(id: $courseId) {
      id
      onlyOneParticipationPerInvitation
      useInsecureInvitation
    }
  }
`;

/**
 * __useGetCourseInvitationConfigQuery__
 *
 * To run a query within a React component, call `useGetCourseInvitationConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseInvitationConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseInvitationConfigQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetCourseInvitationConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCourseInvitationConfigQuery,
    GetCourseInvitationConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCourseInvitationConfigQuery,
    GetCourseInvitationConfigQueryVariables
  >(GetCourseInvitationConfigDocument, options);
}
export function useGetCourseInvitationConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourseInvitationConfigQuery,
    GetCourseInvitationConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourseInvitationConfigQuery,
    GetCourseInvitationConfigQueryVariables
  >(GetCourseInvitationConfigDocument, options);
}
export type GetCourseInvitationConfigQueryHookResult = ReturnType<
  typeof useGetCourseInvitationConfigQuery
>;
export type GetCourseInvitationConfigLazyQueryHookResult = ReturnType<
  typeof useGetCourseInvitationConfigLazyQuery
>;
export type GetCourseInvitationConfigQueryResult = Apollo.QueryResult<
  GetCourseInvitationConfigQuery,
  GetCourseInvitationConfigQueryVariables
>;
export const UpdateCourseInvitationConfigDocument = gql`
  mutation updateCourseInvitationConfig($input: CourseUpdateMutationInput!) {
    courseUpdate(input: $input) {
      course {
        id
        onlyOneParticipationPerInvitation
        useInsecureInvitation
      }
    }
  }
`;
export type UpdateCourseInvitationConfigMutationFn = Apollo.MutationFunction<
  UpdateCourseInvitationConfigMutation,
  UpdateCourseInvitationConfigMutationVariables
>;

/**
 * __useUpdateCourseInvitationConfigMutation__
 *
 * To run a mutation, you first call `useUpdateCourseInvitationConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseInvitationConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseInvitationConfigMutation, { data, loading, error }] = useUpdateCourseInvitationConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourseInvitationConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCourseInvitationConfigMutation,
    UpdateCourseInvitationConfigMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCourseInvitationConfigMutation,
    UpdateCourseInvitationConfigMutationVariables
  >(UpdateCourseInvitationConfigDocument, options);
}
export type UpdateCourseInvitationConfigMutationHookResult = ReturnType<
  typeof useUpdateCourseInvitationConfigMutation
>;
export type UpdateCourseInvitationConfigMutationResult =
  Apollo.MutationResult<UpdateCourseInvitationConfigMutation>;
export type UpdateCourseInvitationConfigMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCourseInvitationConfigMutation,
    UpdateCourseInvitationConfigMutationVariables
  >;
export const WebinarPollModulesDocument = gql`
  query webinarPollModules($courseId: ID!, $parent: ID) {
    courseModules(course: $courseId, parent: $parent) {
      edges {
        node {
          id
          surveymodule {
            assessmentsCounter
            id
            name
            slug
            forceLocked
            survey {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useWebinarPollModulesQuery__
 *
 * To run a query within a React component, call `useWebinarPollModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebinarPollModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebinarPollModulesQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      parent: // value for 'parent'
 *   },
 * });
 */
export function useWebinarPollModulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    WebinarPollModulesQuery,
    WebinarPollModulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WebinarPollModulesQuery,
    WebinarPollModulesQueryVariables
  >(WebinarPollModulesDocument, options);
}
export function useWebinarPollModulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WebinarPollModulesQuery,
    WebinarPollModulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WebinarPollModulesQuery,
    WebinarPollModulesQueryVariables
  >(WebinarPollModulesDocument, options);
}
export type WebinarPollModulesQueryHookResult = ReturnType<
  typeof useWebinarPollModulesQuery
>;
export type WebinarPollModulesLazyQueryHookResult = ReturnType<
  typeof useWebinarPollModulesLazyQuery
>;
export type WebinarPollModulesQueryResult = Apollo.QueryResult<
  WebinarPollModulesQuery,
  WebinarPollModulesQueryVariables
>;
export const PollGoLiveDocument = gql`
  mutation pollGoLive($input: SurveyModuleUpdateMutationInput!) {
    surveyModuleUpdate(input: $input) {
      surveyModule {
        id
        forceLocked
      }
      errors {
        field
        message
      }
    }
  }
`;
export type PollGoLiveMutationFn = Apollo.MutationFunction<
  PollGoLiveMutation,
  PollGoLiveMutationVariables
>;

/**
 * __usePollGoLiveMutation__
 *
 * To run a mutation, you first call `usePollGoLiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePollGoLiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pollGoLiveMutation, { data, loading, error }] = usePollGoLiveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePollGoLiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PollGoLiveMutation,
    PollGoLiveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PollGoLiveMutation, PollGoLiveMutationVariables>(
    PollGoLiveDocument,
    options,
  );
}
export type PollGoLiveMutationHookResult = ReturnType<
  typeof usePollGoLiveMutation
>;
export type PollGoLiveMutationResult =
  Apollo.MutationResult<PollGoLiveMutation>;
export type PollGoLiveMutationOptions = Apollo.BaseMutationOptions<
  PollGoLiveMutation,
  PollGoLiveMutationVariables
>;
export const AllCourseDbtemplatesDocument = gql`
  query allCourseDbtemplates($courseId: ID!) {
    allCourseDbtemplates(courseId: $courseId) {
      edges {
        node {
          id
          label
          name
          content
          design
          getNameDisplay
        }
      }
    }
  }
`;

/**
 * __useAllCourseDbtemplatesQuery__
 *
 * To run a query within a React component, call `useAllCourseDbtemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCourseDbtemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCourseDbtemplatesQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useAllCourseDbtemplatesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllCourseDbtemplatesQuery,
    AllCourseDbtemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllCourseDbtemplatesQuery,
    AllCourseDbtemplatesQueryVariables
  >(AllCourseDbtemplatesDocument, options);
}
export function useAllCourseDbtemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllCourseDbtemplatesQuery,
    AllCourseDbtemplatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllCourseDbtemplatesQuery,
    AllCourseDbtemplatesQueryVariables
  >(AllCourseDbtemplatesDocument, options);
}
export type AllCourseDbtemplatesQueryHookResult = ReturnType<
  typeof useAllCourseDbtemplatesQuery
>;
export type AllCourseDbtemplatesLazyQueryHookResult = ReturnType<
  typeof useAllCourseDbtemplatesLazyQuery
>;
export type AllCourseDbtemplatesQueryResult = Apollo.QueryResult<
  AllCourseDbtemplatesQuery,
  AllCourseDbtemplatesQueryVariables
>;
export const DbtemplatePreviewDocument = gql`
  query dbtemplatePreview($id: ID!, $courseId: ID) {
    dbtemplate(id: $id) {
      id
      preview(courseId: $courseId)
    }
  }
`;

/**
 * __useDbtemplatePreviewQuery__
 *
 * To run a query within a React component, call `useDbtemplatePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useDbtemplatePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDbtemplatePreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useDbtemplatePreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    DbtemplatePreviewQuery,
    DbtemplatePreviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DbtemplatePreviewQuery,
    DbtemplatePreviewQueryVariables
  >(DbtemplatePreviewDocument, options);
}
export function useDbtemplatePreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DbtemplatePreviewQuery,
    DbtemplatePreviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DbtemplatePreviewQuery,
    DbtemplatePreviewQueryVariables
  >(DbtemplatePreviewDocument, options);
}
export type DbtemplatePreviewQueryHookResult = ReturnType<
  typeof useDbtemplatePreviewQuery
>;
export type DbtemplatePreviewLazyQueryHookResult = ReturnType<
  typeof useDbtemplatePreviewLazyQuery
>;
export type DbtemplatePreviewQueryResult = Apollo.QueryResult<
  DbtemplatePreviewQuery,
  DbtemplatePreviewQueryVariables
>;
export const DbtemplateDocument = gql`
  query dbtemplate($id: ID!) {
    dbtemplate(id: $id) {
      id
      label
      subject
      name
      content
      design
      creationDate
      lastChanged
      getNameDisplay
      userHasPermission {
        viewTemplate
      }
    }
  }
`;

/**
 * __useDbtemplateQuery__
 *
 * To run a query within a React component, call `useDbtemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDbtemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDbtemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDbtemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    DbtemplateQuery,
    DbtemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DbtemplateQuery, DbtemplateQueryVariables>(
    DbtemplateDocument,
    options,
  );
}
export function useDbtemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DbtemplateQuery,
    DbtemplateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DbtemplateQuery, DbtemplateQueryVariables>(
    DbtemplateDocument,
    options,
  );
}
export type DbtemplateQueryHookResult = ReturnType<typeof useDbtemplateQuery>;
export type DbtemplateLazyQueryHookResult = ReturnType<
  typeof useDbtemplateLazyQuery
>;
export type DbtemplateQueryResult = Apollo.QueryResult<
  DbtemplateQuery,
  DbtemplateQueryVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      id
      avatar(size: 40)
      firstName
      lastName
      displayName
      isAnonymous
      isActive
      isStaff
      isSuperuser
      email
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UserDocument = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      avatar(size: 40)
      profile {
        id
        birthPlace {
          cityState
        }
        birthDay
        gender
      }
    }
  }
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options,
  );
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const GetMosaicoCredentialDocument = gql`
  query getMosaicoCredential($id: ID!) {
    mosaico(id: $id) {
      active
      plannedStartDate
      plannedEndDate
      name
      embedCode
      cameraPlaceholders {
        edges {
          node {
            file {
              url
            }
          }
        }
      }
      credential {
        type
        apiKey
        sessionId
        token
      }
    }
  }
`;

/**
 * __useGetMosaicoCredentialQuery__
 *
 * To run a query within a React component, call `useGetMosaicoCredentialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMosaicoCredentialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMosaicoCredentialQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMosaicoCredentialQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMosaicoCredentialQuery,
    GetMosaicoCredentialQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMosaicoCredentialQuery,
    GetMosaicoCredentialQueryVariables
  >(GetMosaicoCredentialDocument, options);
}
export function useGetMosaicoCredentialLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMosaicoCredentialQuery,
    GetMosaicoCredentialQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMosaicoCredentialQuery,
    GetMosaicoCredentialQueryVariables
  >(GetMosaicoCredentialDocument, options);
}
export type GetMosaicoCredentialQueryHookResult = ReturnType<
  typeof useGetMosaicoCredentialQuery
>;
export type GetMosaicoCredentialLazyQueryHookResult = ReturnType<
  typeof useGetMosaicoCredentialLazyQuery
>;
export type GetMosaicoCredentialQueryResult = Apollo.QueryResult<
  GetMosaicoCredentialQuery,
  GetMosaicoCredentialQueryVariables
>;
export const AllNotesDocument = gql`
  query allNotes {
    allNotes {
      edges {
        node {
          id
          text
        }
      }
    }
  }
`;

/**
 * __useAllNotesQuery__
 *
 * To run a query within a React component, call `useAllNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNotesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllNotesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllNotesQuery, AllNotesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllNotesQuery, AllNotesQueryVariables>(
    AllNotesDocument,
    options,
  );
}
export function useAllNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllNotesQuery,
    AllNotesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllNotesQuery, AllNotesQueryVariables>(
    AllNotesDocument,
    options,
  );
}
export type AllNotesQueryHookResult = ReturnType<typeof useAllNotesQuery>;
export type AllNotesLazyQueryHookResult = ReturnType<
  typeof useAllNotesLazyQuery
>;
export type AllNotesQueryResult = Apollo.QueryResult<
  AllNotesQuery,
  AllNotesQueryVariables
>;
export const AllNotesByContentObjectDocument = gql`
  query allNotesByContentObject($contentObject: String!) {
    allNotes(contentObject: $contentObject) {
      edges {
        node {
          id
          text
        }
      }
    }
  }
`;

/**
 * __useAllNotesByContentObjectQuery__
 *
 * To run a query within a React component, call `useAllNotesByContentObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNotesByContentObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNotesByContentObjectQuery({
 *   variables: {
 *      contentObject: // value for 'contentObject'
 *   },
 * });
 */
export function useAllNotesByContentObjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    AllNotesByContentObjectQuery,
    AllNotesByContentObjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllNotesByContentObjectQuery,
    AllNotesByContentObjectQueryVariables
  >(AllNotesByContentObjectDocument, options);
}
export function useAllNotesByContentObjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllNotesByContentObjectQuery,
    AllNotesByContentObjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllNotesByContentObjectQuery,
    AllNotesByContentObjectQueryVariables
  >(AllNotesByContentObjectDocument, options);
}
export type AllNotesByContentObjectQueryHookResult = ReturnType<
  typeof useAllNotesByContentObjectQuery
>;
export type AllNotesByContentObjectLazyQueryHookResult = ReturnType<
  typeof useAllNotesByContentObjectLazyQuery
>;
export type AllNotesByContentObjectQueryResult = Apollo.QueryResult<
  AllNotesByContentObjectQuery,
  AllNotesByContentObjectQueryVariables
>;
export const GetGenericInstancePermissionsDocument = gql`
  query getGenericInstancePermissions($instanceId: ID!) {
    genericInstancePermissions(instanceId: $instanceId) {
      availablePermissionsOfInstance {
        ...permission
      }
      usersPermissionsOfInstance {
        ...userPermission
      }
      groupsPermissionsOfInstance {
        ...groupPermissions
      }
    }
  }
  ${PermissionFragmentDoc}
  ${UserPermissionFragmentDoc}
  ${GroupPermissionsFragmentDoc}
`;

/**
 * __useGetGenericInstancePermissionsQuery__
 *
 * To run a query within a React component, call `useGetGenericInstancePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenericInstancePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenericInstancePermissionsQuery({
 *   variables: {
 *      instanceId: // value for 'instanceId'
 *   },
 * });
 */
export function useGetGenericInstancePermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGenericInstancePermissionsQuery,
    GetGenericInstancePermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetGenericInstancePermissionsQuery,
    GetGenericInstancePermissionsQueryVariables
  >(GetGenericInstancePermissionsDocument, options);
}
export function useGetGenericInstancePermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGenericInstancePermissionsQuery,
    GetGenericInstancePermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetGenericInstancePermissionsQuery,
    GetGenericInstancePermissionsQueryVariables
  >(GetGenericInstancePermissionsDocument, options);
}
export type GetGenericInstancePermissionsQueryHookResult = ReturnType<
  typeof useGetGenericInstancePermissionsQuery
>;
export type GetGenericInstancePermissionsLazyQueryHookResult = ReturnType<
  typeof useGetGenericInstancePermissionsLazyQuery
>;
export type GetGenericInstancePermissionsQueryResult = Apollo.QueryResult<
  GetGenericInstancePermissionsQuery,
  GetGenericInstancePermissionsQueryVariables
>;
export const GroupCreateDocument = gql`
  mutation groupCreate($input: GroupCreateMutationInput!) {
    groupCreate(input: $input) {
      group {
        ...groupDataForPermissions
      }
      genericInstancePermissions {
        groupsPermissionsOfInstance {
          ...groupPermissions
        }
      }
      errors {
        field
        message
      }
    }
  }
  ${GroupDataForPermissionsFragmentDoc}
  ${GroupPermissionsFragmentDoc}
`;
export type GroupCreateMutationFn = Apollo.MutationFunction<
  GroupCreateMutation,
  GroupCreateMutationVariables
>;

/**
 * __useGroupCreateMutation__
 *
 * To run a mutation, you first call `useGroupCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupCreateMutation, { data, loading, error }] = useGroupCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupCreateMutation,
    GroupCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GroupCreateMutation, GroupCreateMutationVariables>(
    GroupCreateDocument,
    options,
  );
}
export type GroupCreateMutationHookResult = ReturnType<
  typeof useGroupCreateMutation
>;
export type GroupCreateMutationResult =
  Apollo.MutationResult<GroupCreateMutation>;
export type GroupCreateMutationOptions = Apollo.BaseMutationOptions<
  GroupCreateMutation,
  GroupCreateMutationVariables
>;
export const GroupDeleteDocument = gql`
  mutation groupDelete($input: GroupDeleteMutationInput!) {
    groupDelete(input: $input) {
      group {
        ...groupDataForPermissions
      }
      genericInstancePermissions {
        groupsPermissionsOfInstance {
          ...groupPermissions
        }
      }
      errors {
        field
        message
      }
    }
  }
  ${GroupDataForPermissionsFragmentDoc}
  ${GroupPermissionsFragmentDoc}
`;
export type GroupDeleteMutationFn = Apollo.MutationFunction<
  GroupDeleteMutation,
  GroupDeleteMutationVariables
>;

/**
 * __useGroupDeleteMutation__
 *
 * To run a mutation, you first call `useGroupDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupDeleteMutation, { data, loading, error }] = useGroupDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupDeleteMutation,
    GroupDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GroupDeleteMutation, GroupDeleteMutationVariables>(
    GroupDeleteDocument,
    options,
  );
}
export type GroupDeleteMutationHookResult = ReturnType<
  typeof useGroupDeleteMutation
>;
export type GroupDeleteMutationResult =
  Apollo.MutationResult<GroupDeleteMutation>;
export type GroupDeleteMutationOptions = Apollo.BaseMutationOptions<
  GroupDeleteMutation,
  GroupDeleteMutationVariables
>;
export const GroupRemoveUserDocument = gql`
  mutation groupRemoveUser($input: GroupRemoveUserMutationInput!) {
    groupRemoveUser(input: $input) {
      group {
        ...groupDataForPermissions
      }
      genericInstancePermissions {
        groupsPermissionsOfInstance {
          ...groupPermissions
        }
      }
      errors {
        field
        message
      }
    }
  }
  ${GroupDataForPermissionsFragmentDoc}
  ${GroupPermissionsFragmentDoc}
`;
export type GroupRemoveUserMutationFn = Apollo.MutationFunction<
  GroupRemoveUserMutation,
  GroupRemoveUserMutationVariables
>;

/**
 * __useGroupRemoveUserMutation__
 *
 * To run a mutation, you first call `useGroupRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupRemoveUserMutation, { data, loading, error }] = useGroupRemoveUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupRemoveUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupRemoveUserMutation,
    GroupRemoveUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GroupRemoveUserMutation,
    GroupRemoveUserMutationVariables
  >(GroupRemoveUserDocument, options);
}
export type GroupRemoveUserMutationHookResult = ReturnType<
  typeof useGroupRemoveUserMutation
>;
export type GroupRemoveUserMutationResult =
  Apollo.MutationResult<GroupRemoveUserMutation>;
export type GroupRemoveUserMutationOptions = Apollo.BaseMutationOptions<
  GroupRemoveUserMutation,
  GroupRemoveUserMutationVariables
>;
export const GroupAddUserDocument = gql`
  mutation groupAddUser($input: GroupAddUserMutationInput!) {
    groupAddUser(input: $input) {
      group {
        ...groupDataForPermissions
      }
      genericInstancePermissions {
        groupsPermissionsOfInstance {
          ...groupPermissions
        }
      }
      errors {
        field
        message
      }
    }
  }
  ${GroupDataForPermissionsFragmentDoc}
  ${GroupPermissionsFragmentDoc}
`;
export type GroupAddUserMutationFn = Apollo.MutationFunction<
  GroupAddUserMutation,
  GroupAddUserMutationVariables
>;

/**
 * __useGroupAddUserMutation__
 *
 * To run a mutation, you first call `useGroupAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupAddUserMutation, { data, loading, error }] = useGroupAddUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGroupAddUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GroupAddUserMutation,
    GroupAddUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GroupAddUserMutation,
    GroupAddUserMutationVariables
  >(GroupAddUserDocument, options);
}
export type GroupAddUserMutationHookResult = ReturnType<
  typeof useGroupAddUserMutation
>;
export type GroupAddUserMutationResult =
  Apollo.MutationResult<GroupAddUserMutation>;
export type GroupAddUserMutationOptions = Apollo.BaseMutationOptions<
  GroupAddUserMutation,
  GroupAddUserMutationVariables
>;
export const PermissionChangeDocument = gql`
  mutation permissionChange($input: PermissionChangeInput!) {
    permissionChange(input: $input) {
      genericInstancePermissions {
        availablePermissionsOfInstance {
          ...permission
        }
        usersPermissionsOfInstance {
          ...userPermission
        }
        groupsPermissionsOfInstance {
          ...groupPermissions
        }
      }
      errors {
        field
        message
      }
    }
  }
  ${PermissionFragmentDoc}
  ${UserPermissionFragmentDoc}
  ${GroupPermissionsFragmentDoc}
`;
export type PermissionChangeMutationFn = Apollo.MutationFunction<
  PermissionChangeMutation,
  PermissionChangeMutationVariables
>;

/**
 * __usePermissionChangeMutation__
 *
 * To run a mutation, you first call `usePermissionChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermissionChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permissionChangeMutation, { data, loading, error }] = usePermissionChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePermissionChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PermissionChangeMutation,
    PermissionChangeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PermissionChangeMutation,
    PermissionChangeMutationVariables
  >(PermissionChangeDocument, options);
}
export type PermissionChangeMutationHookResult = ReturnType<
  typeof usePermissionChangeMutation
>;
export type PermissionChangeMutationResult =
  Apollo.MutationResult<PermissionChangeMutation>;
export type PermissionChangeMutationOptions = Apollo.BaseMutationOptions<
  PermissionChangeMutation,
  PermissionChangeMutationVariables
>;
export const QuestionDocument = gql`
  query question($id: ID!) {
    question(id: $id) {
      ...QuestionUnionFragment
    }
  }
  ${QuestionUnionFragmentFragmentDoc}
`;

/**
 * __useQuestionQuery__
 *
 * To run a query within a React component, call `useQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuestionQuery(
  baseOptions: Apollo.QueryHookOptions<QuestionQuery, QuestionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuestionQuery, QuestionQueryVariables>(
    QuestionDocument,
    options,
  );
}
export function useQuestionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuestionQuery,
    QuestionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuestionQuery, QuestionQueryVariables>(
    QuestionDocument,
    options,
  );
}
export type QuestionQueryHookResult = ReturnType<typeof useQuestionQuery>;
export type QuestionLazyQueryHookResult = ReturnType<
  typeof useQuestionLazyQuery
>;
export type QuestionQueryResult = Apollo.QueryResult<
  QuestionQuery,
  QuestionQueryVariables
>;
export const SiteCurrentDocument = gql`
  query siteCurrent {
    siteCurrent {
      id
      faviconData {
        id
        htmlTags {
          tag
          attributes {
            key
            value
          }
        }
      }
      mosaico {
        id
      }
      company {
        ...companyCustomizationData
      }
      course {
        id
        slug
        layoutType
        demo
        name
        theme {
          ...theme
        }
        company {
          ...companyCustomizationData
        }
      }
    }
  }
  ${CompanyCustomizationDataFragmentDoc}
  ${ThemeFragmentDoc}
`;

/**
 * __useSiteCurrentQuery__
 *
 * To run a query within a React component, call `useSiteCurrentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteCurrentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteCurrentQuery({
 *   variables: {
 *   },
 * });
 */
export function useSiteCurrentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SiteCurrentQuery,
    SiteCurrentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SiteCurrentQuery, SiteCurrentQueryVariables>(
    SiteCurrentDocument,
    options,
  );
}
export function useSiteCurrentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SiteCurrentQuery,
    SiteCurrentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SiteCurrentQuery, SiteCurrentQueryVariables>(
    SiteCurrentDocument,
    options,
  );
}
export type SiteCurrentQueryHookResult = ReturnType<typeof useSiteCurrentQuery>;
export type SiteCurrentLazyQueryHookResult = ReturnType<
  typeof useSiteCurrentLazyQuery
>;
export type SiteCurrentQueryResult = Apollo.QueryResult<
  SiteCurrentQuery,
  SiteCurrentQueryVariables
>;
export const SaveAnswerToTheQuestionDocument = gql`
  mutation saveAnswerToTheQuestion($input: saveAnswerToTheQuestionInput!) {
    saveAnswerToTheQuestion(input: $input) {
      ok
      response {
        id
        partial
        answers {
          edges {
            node {
              id
              data
              question {
                ...QuestionUnionIdFragment
              }
            }
          }
        }
      }
    }
  }
  ${QuestionUnionIdFragmentFragmentDoc}
`;
export type SaveAnswerToTheQuestionMutationFn = Apollo.MutationFunction<
  SaveAnswerToTheQuestionMutation,
  SaveAnswerToTheQuestionMutationVariables
>;

/**
 * __useSaveAnswerToTheQuestionMutation__
 *
 * To run a mutation, you first call `useSaveAnswerToTheQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAnswerToTheQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAnswerToTheQuestionMutation, { data, loading, error }] = useSaveAnswerToTheQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveAnswerToTheQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveAnswerToTheQuestionMutation,
    SaveAnswerToTheQuestionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveAnswerToTheQuestionMutation,
    SaveAnswerToTheQuestionMutationVariables
  >(SaveAnswerToTheQuestionDocument, options);
}
export type SaveAnswerToTheQuestionMutationHookResult = ReturnType<
  typeof useSaveAnswerToTheQuestionMutation
>;
export type SaveAnswerToTheQuestionMutationResult =
  Apollo.MutationResult<SaveAnswerToTheQuestionMutation>;
export type SaveAnswerToTheQuestionMutationOptions = Apollo.BaseMutationOptions<
  SaveAnswerToTheQuestionMutation,
  SaveAnswerToTheQuestionMutationVariables
>;
export const SaveSurveyBulkResponseDocument = gql`
  mutation saveSurveyBulkResponse($input: saveSurveyBulkResponseInput!) {
    saveSurveyBulkResponse(input: $input) {
      ok
      response {
        id
        partial
        answers {
          edges {
            node {
              id
              data
              question {
                ...QuestionUnionIdFragment
              }
            }
          }
        }
      }
    }
  }
  ${QuestionUnionIdFragmentFragmentDoc}
`;
export type SaveSurveyBulkResponseMutationFn = Apollo.MutationFunction<
  SaveSurveyBulkResponseMutation,
  SaveSurveyBulkResponseMutationVariables
>;

/**
 * __useSaveSurveyBulkResponseMutation__
 *
 * To run a mutation, you first call `useSaveSurveyBulkResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSurveyBulkResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSurveyBulkResponseMutation, { data, loading, error }] = useSaveSurveyBulkResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveSurveyBulkResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveSurveyBulkResponseMutation,
    SaveSurveyBulkResponseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveSurveyBulkResponseMutation,
    SaveSurveyBulkResponseMutationVariables
  >(SaveSurveyBulkResponseDocument, options);
}
export type SaveSurveyBulkResponseMutationHookResult = ReturnType<
  typeof useSaveSurveyBulkResponseMutation
>;
export type SaveSurveyBulkResponseMutationResult =
  Apollo.MutationResult<SaveSurveyBulkResponseMutation>;
export type SaveSurveyBulkResponseMutationOptions = Apollo.BaseMutationOptions<
  SaveSurveyBulkResponseMutation,
  SaveSurveyBulkResponseMutationVariables
>;
export const AllSurveyResponseDocument = gql`
  query allSurveyResponse($first: Int, $after: String, $surveyType: String) {
    allSurveyResponse(first: $first, after: $after, surveyType: $surveyType) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          modified
          partial
        }
      }
    }
  }
`;

/**
 * __useAllSurveyResponseQuery__
 *
 * To run a query within a React component, call `useAllSurveyResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSurveyResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSurveyResponseQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      surveyType: // value for 'surveyType'
 *   },
 * });
 */
export function useAllSurveyResponseQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllSurveyResponseQuery,
    AllSurveyResponseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AllSurveyResponseQuery,
    AllSurveyResponseQueryVariables
  >(AllSurveyResponseDocument, options);
}
export function useAllSurveyResponseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllSurveyResponseQuery,
    AllSurveyResponseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AllSurveyResponseQuery,
    AllSurveyResponseQueryVariables
  >(AllSurveyResponseDocument, options);
}
export type AllSurveyResponseQueryHookResult = ReturnType<
  typeof useAllSurveyResponseQuery
>;
export type AllSurveyResponseLazyQueryHookResult = ReturnType<
  typeof useAllSurveyResponseLazyQuery
>;
export type AllSurveyResponseQueryResult = Apollo.QueryResult<
  AllSurveyResponseQuery,
  AllSurveyResponseQueryVariables
>;
export const GetSurveyResponseWithSurveyDocument = gql`
  query getSurveyResponseWithSurvey($id: ID!) {
    surveyResponse(id: $id) {
      survey {
        ...QuestionnaireUnionFragment
      }
      partial
      answers {
        edges {
          node {
            id
            data
            question {
              ...QuestionUnionIdFragment
            }
          }
        }
      }
    }
  }
  ${QuestionnaireUnionFragmentFragmentDoc}
  ${QuestionUnionIdFragmentFragmentDoc}
`;

/**
 * __useGetSurveyResponseWithSurveyQuery__
 *
 * To run a query within a React component, call `useGetSurveyResponseWithSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyResponseWithSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyResponseWithSurveyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSurveyResponseWithSurveyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSurveyResponseWithSurveyQuery,
    GetSurveyResponseWithSurveyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSurveyResponseWithSurveyQuery,
    GetSurveyResponseWithSurveyQueryVariables
  >(GetSurveyResponseWithSurveyDocument, options);
}
export function useGetSurveyResponseWithSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSurveyResponseWithSurveyQuery,
    GetSurveyResponseWithSurveyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSurveyResponseWithSurveyQuery,
    GetSurveyResponseWithSurveyQueryVariables
  >(GetSurveyResponseWithSurveyDocument, options);
}
export type GetSurveyResponseWithSurveyQueryHookResult = ReturnType<
  typeof useGetSurveyResponseWithSurveyQuery
>;
export type GetSurveyResponseWithSurveyLazyQueryHookResult = ReturnType<
  typeof useGetSurveyResponseWithSurveyLazyQuery
>;
export type GetSurveyResponseWithSurveyQueryResult = Apollo.QueryResult<
  GetSurveyResponseWithSurveyQuery,
  GetSurveyResponseWithSurveyQueryVariables
>;
export const GetSurveyResponseDocument = gql`
  query getSurveyResponse($id: ID!) {
    surveyResponse(id: $id) {
      partial
      answers {
        edges {
          node {
            id
            data
          }
        }
      }
    }
  }
`;

/**
 * __useGetSurveyResponseQuery__
 *
 * To run a query within a React component, call `useGetSurveyResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyResponseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSurveyResponseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSurveyResponseQuery,
    GetSurveyResponseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSurveyResponseQuery,
    GetSurveyResponseQueryVariables
  >(GetSurveyResponseDocument, options);
}
export function useGetSurveyResponseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSurveyResponseQuery,
    GetSurveyResponseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSurveyResponseQuery,
    GetSurveyResponseQueryVariables
  >(GetSurveyResponseDocument, options);
}
export type GetSurveyResponseQueryHookResult = ReturnType<
  typeof useGetSurveyResponseQuery
>;
export type GetSurveyResponseLazyQueryHookResult = ReturnType<
  typeof useGetSurveyResponseLazyQuery
>;
export type GetSurveyResponseQueryResult = Apollo.QueryResult<
  GetSurveyResponseQuery,
  GetSurveyResponseQueryVariables
>;
export const GetSurveyResponseResultsDocument = gql`
  query getSurveyResponseResults($id: ID!) {
    surveyResponse(id: $id) {
      partial
      score
      maxScore
      answers {
        edges {
          node {
            id
            data
            score
            maxScore
            question {
              ... on QuestionNode {
                title
                description
                slug
                required
                type
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetSurveyResponseResultsQuery__
 *
 * To run a query within a React component, call `useGetSurveyResponseResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyResponseResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyResponseResultsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSurveyResponseResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSurveyResponseResultsQuery,
    GetSurveyResponseResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSurveyResponseResultsQuery,
    GetSurveyResponseResultsQueryVariables
  >(GetSurveyResponseResultsDocument, options);
}
export function useGetSurveyResponseResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSurveyResponseResultsQuery,
    GetSurveyResponseResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSurveyResponseResultsQuery,
    GetSurveyResponseResultsQueryVariables
  >(GetSurveyResponseResultsDocument, options);
}
export type GetSurveyResponseResultsQueryHookResult = ReturnType<
  typeof useGetSurveyResponseResultsQuery
>;
export type GetSurveyResponseResultsLazyQueryHookResult = ReturnType<
  typeof useGetSurveyResponseResultsLazyQuery
>;
export type GetSurveyResponseResultsQueryResult = Apollo.QueryResult<
  GetSurveyResponseResultsQuery,
  GetSurveyResponseResultsQueryVariables
>;
export const GetAllSurveysDocument = gql`
  query getAllSurveys {
    allSurveys {
      totalCount
      edges {
        node {
          id
          description
          name
          created
        }
      }
    }
  }
`;

/**
 * __useGetAllSurveysQuery__
 *
 * To run a query within a React component, call `useGetAllSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSurveysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSurveysQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllSurveysQuery,
    GetAllSurveysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllSurveysQuery, GetAllSurveysQueryVariables>(
    GetAllSurveysDocument,
    options,
  );
}
export function useGetAllSurveysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllSurveysQuery,
    GetAllSurveysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllSurveysQuery, GetAllSurveysQueryVariables>(
    GetAllSurveysDocument,
    options,
  );
}
export type GetAllSurveysQueryHookResult = ReturnType<
  typeof useGetAllSurveysQuery
>;
export type GetAllSurveysLazyQueryHookResult = ReturnType<
  typeof useGetAllSurveysLazyQuery
>;
export type GetAllSurveysQueryResult = Apollo.QueryResult<
  GetAllSurveysQuery,
  GetAllSurveysQueryVariables
>;
export const GetSurveyDocument = gql`
  query getSurvey($id: ID!) {
    survey(id: $id) {
      ...SurveyNodeFragment
    }
  }
  ${SurveyNodeFragmentFragmentDoc}
`;

/**
 * __useGetSurveyQuery__
 *
 * To run a query within a React component, call `useGetSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSurveyQuery(
  baseOptions: Apollo.QueryHookOptions<GetSurveyQuery, GetSurveyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSurveyQuery, GetSurveyQueryVariables>(
    GetSurveyDocument,
    options,
  );
}
export function useGetSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSurveyQuery,
    GetSurveyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSurveyQuery, GetSurveyQueryVariables>(
    GetSurveyDocument,
    options,
  );
}
export type GetSurveyQueryHookResult = ReturnType<typeof useGetSurveyQuery>;
export type GetSurveyLazyQueryHookResult = ReturnType<
  typeof useGetSurveyLazyQuery
>;
export type GetSurveyQueryResult = Apollo.QueryResult<
  GetSurveyQuery,
  GetSurveyQueryVariables
>;
export const GetSurveyQuestionsDocument = gql`
  query getSurveyQuestions($id: ID!) {
    survey(id: $id) {
      id
      name
      label
      questions {
        edges {
          node {
            __typename
            ... on QuestionNode {
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetSurveyQuestionsQuery__
 *
 * To run a query within a React component, call `useGetSurveyQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyQuestionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSurveyQuestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSurveyQuestionsQuery,
    GetSurveyQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSurveyQuestionsQuery,
    GetSurveyQuestionsQueryVariables
  >(GetSurveyQuestionsDocument, options);
}
export function useGetSurveyQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSurveyQuestionsQuery,
    GetSurveyQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSurveyQuestionsQuery,
    GetSurveyQuestionsQueryVariables
  >(GetSurveyQuestionsDocument, options);
}
export type GetSurveyQuestionsQueryHookResult = ReturnType<
  typeof useGetSurveyQuestionsQuery
>;
export type GetSurveyQuestionsLazyQueryHookResult = ReturnType<
  typeof useGetSurveyQuestionsLazyQuery
>;
export type GetSurveyQuestionsQueryResult = Apollo.QueryResult<
  GetSurveyQuestionsQuery,
  GetSurveyQuestionsQueryVariables
>;
export const GetQuestionWithAnswersDocument = gql`
  query getQuestionWithAnswers($id: ID!) {
    question(id: $id) {
      id
      question
      survey {
        id
      }
      title
      description
      label
      slug
      type
      choiches {
        value
      }
      answers {
        edges {
          node {
            id
            data
          }
        }
      }
    }
  }
`;

/**
 * __useGetQuestionWithAnswersQuery__
 *
 * To run a query within a React component, call `useGetQuestionWithAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionWithAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionWithAnswersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionWithAnswersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetQuestionWithAnswersQuery,
    GetQuestionWithAnswersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetQuestionWithAnswersQuery,
    GetQuestionWithAnswersQueryVariables
  >(GetQuestionWithAnswersDocument, options);
}
export function useGetQuestionWithAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetQuestionWithAnswersQuery,
    GetQuestionWithAnswersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetQuestionWithAnswersQuery,
    GetQuestionWithAnswersQueryVariables
  >(GetQuestionWithAnswersDocument, options);
}
export type GetQuestionWithAnswersQueryHookResult = ReturnType<
  typeof useGetQuestionWithAnswersQuery
>;
export type GetQuestionWithAnswersLazyQueryHookResult = ReturnType<
  typeof useGetQuestionWithAnswersLazyQuery
>;
export type GetQuestionWithAnswersQueryResult = Apollo.QueryResult<
  GetQuestionWithAnswersQuery,
  GetQuestionWithAnswersQueryVariables
>;
export const GetMyLastSurveyResponseOfSurveyDocument = gql`
  query getMyLastSurveyResponseOfSurvey($surveyId: ID!) {
    lastSurveyResponseOfSurvey(surveyId: $surveyId) {
      id
      partial
      enough
    }
  }
`;

/**
 * __useGetMyLastSurveyResponseOfSurveyQuery__
 *
 * To run a query within a React component, call `useGetMyLastSurveyResponseOfSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLastSurveyResponseOfSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLastSurveyResponseOfSurveyQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useGetMyLastSurveyResponseOfSurveyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyLastSurveyResponseOfSurveyQuery,
    GetMyLastSurveyResponseOfSurveyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyLastSurveyResponseOfSurveyQuery,
    GetMyLastSurveyResponseOfSurveyQueryVariables
  >(GetMyLastSurveyResponseOfSurveyDocument, options);
}
export function useGetMyLastSurveyResponseOfSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyLastSurveyResponseOfSurveyQuery,
    GetMyLastSurveyResponseOfSurveyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyLastSurveyResponseOfSurveyQuery,
    GetMyLastSurveyResponseOfSurveyQueryVariables
  >(GetMyLastSurveyResponseOfSurveyDocument, options);
}
export type GetMyLastSurveyResponseOfSurveyQueryHookResult = ReturnType<
  typeof useGetMyLastSurveyResponseOfSurveyQuery
>;
export type GetMyLastSurveyResponseOfSurveyLazyQueryHookResult = ReturnType<
  typeof useGetMyLastSurveyResponseOfSurveyLazyQuery
>;
export type GetMyLastSurveyResponseOfSurveyQueryResult = Apollo.QueryResult<
  GetMyLastSurveyResponseOfSurveyQuery,
  GetMyLastSurveyResponseOfSurveyQueryVariables
>;
export const GetMyLastSurveyResponseDataOfSurveyDocument = gql`
  query getMyLastSurveyResponseDataOfSurvey($surveyId: ID!) {
    lastSurveyResponseOfSurvey(surveyId: $surveyId) {
      id
      partial
      enough
      answers {
        edges {
          node {
            id
            data
            question {
              __typename
              ... on QuestionNode {
                id
                title
                description
                label
                slug
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetMyLastSurveyResponseDataOfSurveyQuery__
 *
 * To run a query within a React component, call `useGetMyLastSurveyResponseDataOfSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLastSurveyResponseDataOfSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLastSurveyResponseDataOfSurveyQuery({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *   },
 * });
 */
export function useGetMyLastSurveyResponseDataOfSurveyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyLastSurveyResponseDataOfSurveyQuery,
    GetMyLastSurveyResponseDataOfSurveyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMyLastSurveyResponseDataOfSurveyQuery,
    GetMyLastSurveyResponseDataOfSurveyQueryVariables
  >(GetMyLastSurveyResponseDataOfSurveyDocument, options);
}
export function useGetMyLastSurveyResponseDataOfSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyLastSurveyResponseDataOfSurveyQuery,
    GetMyLastSurveyResponseDataOfSurveyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyLastSurveyResponseDataOfSurveyQuery,
    GetMyLastSurveyResponseDataOfSurveyQueryVariables
  >(GetMyLastSurveyResponseDataOfSurveyDocument, options);
}
export type GetMyLastSurveyResponseDataOfSurveyQueryHookResult = ReturnType<
  typeof useGetMyLastSurveyResponseDataOfSurveyQuery
>;
export type GetMyLastSurveyResponseDataOfSurveyLazyQueryHookResult = ReturnType<
  typeof useGetMyLastSurveyResponseDataOfSurveyLazyQuery
>;
export type GetMyLastSurveyResponseDataOfSurveyQueryResult = Apollo.QueryResult<
  GetMyLastSurveyResponseDataOfSurveyQuery,
  GetMyLastSurveyResponseDataOfSurveyQueryVariables
>;
export const GetThemeDocument = gql`
  query getTheme($id: ID!) {
    theme(id: $id) {
      ...theme
    }
  }
  ${ThemeFragmentDoc}
`;

/**
 * __useGetThemeQuery__
 *
 * To run a query within a React component, call `useGetThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetThemeQuery(
  baseOptions: Apollo.QueryHookOptions<GetThemeQuery, GetThemeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetThemeQuery, GetThemeQueryVariables>(
    GetThemeDocument,
    options,
  );
}
export function useGetThemeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetThemeQuery,
    GetThemeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetThemeQuery, GetThemeQueryVariables>(
    GetThemeDocument,
    options,
  );
}
export type GetThemeQueryHookResult = ReturnType<typeof useGetThemeQuery>;
export type GetThemeLazyQueryHookResult = ReturnType<
  typeof useGetThemeLazyQuery
>;
export type GetThemeQueryResult = Apollo.QueryResult<
  GetThemeQuery,
  GetThemeQueryVariables
>;
export const ThemeUpdateDocument = gql`
  mutation themeUpdate($input: ThemeUpdateMutationInput!) {
    themeUpdate(input: $input) {
      theme {
        ...theme
      }
    }
  }
  ${ThemeFragmentDoc}
`;
export type ThemeUpdateMutationFn = Apollo.MutationFunction<
  ThemeUpdateMutation,
  ThemeUpdateMutationVariables
>;

/**
 * __useThemeUpdateMutation__
 *
 * To run a mutation, you first call `useThemeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useThemeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [themeUpdateMutation, { data, loading, error }] = useThemeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useThemeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ThemeUpdateMutation,
    ThemeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ThemeUpdateMutation, ThemeUpdateMutationVariables>(
    ThemeUpdateDocument,
    options,
  );
}
export type ThemeUpdateMutationHookResult = ReturnType<
  typeof useThemeUpdateMutation
>;
export type ThemeUpdateMutationResult =
  Apollo.MutationResult<ThemeUpdateMutation>;
export type ThemeUpdateMutationOptions = Apollo.BaseMutationOptions<
  ThemeUpdateMutation,
  ThemeUpdateMutationVariables
>;
export const ThemeCreateDocument = gql`
  mutation themeCreate($input: ThemeCreateMutationInput!) {
    themeCreate(input: $input) {
      theme {
        ...theme
      }
    }
  }
  ${ThemeFragmentDoc}
`;
export type ThemeCreateMutationFn = Apollo.MutationFunction<
  ThemeCreateMutation,
  ThemeCreateMutationVariables
>;

/**
 * __useThemeCreateMutation__
 *
 * To run a mutation, you first call `useThemeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useThemeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [themeCreateMutation, { data, loading, error }] = useThemeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useThemeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ThemeCreateMutation,
    ThemeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ThemeCreateMutation, ThemeCreateMutationVariables>(
    ThemeCreateDocument,
    options,
  );
}
export type ThemeCreateMutationHookResult = ReturnType<
  typeof useThemeCreateMutation
>;
export type ThemeCreateMutationResult =
  Apollo.MutationResult<ThemeCreateMutation>;
export type ThemeCreateMutationOptions = Apollo.BaseMutationOptions<
  ThemeCreateMutation,
  ThemeCreateMutationVariables
>;
export const ThemeDeleteDocument = gql`
  mutation themeDelete($input: ThemeDeleteMutationInput!) {
    themeDelete(input: $input) {
      theme {
        ...theme
      }
      errors {
        field
        message
      }
    }
  }
  ${ThemeFragmentDoc}
`;
export type ThemeDeleteMutationFn = Apollo.MutationFunction<
  ThemeDeleteMutation,
  ThemeDeleteMutationVariables
>;

/**
 * __useThemeDeleteMutation__
 *
 * To run a mutation, you first call `useThemeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useThemeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [themeDeleteMutation, { data, loading, error }] = useThemeDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useThemeDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ThemeDeleteMutation,
    ThemeDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ThemeDeleteMutation, ThemeDeleteMutationVariables>(
    ThemeDeleteDocument,
    options,
  );
}
export type ThemeDeleteMutationHookResult = ReturnType<
  typeof useThemeDeleteMutation
>;
export type ThemeDeleteMutationResult =
  Apollo.MutationResult<ThemeDeleteMutation>;
export type ThemeDeleteMutationOptions = Apollo.BaseMutationOptions<
  ThemeDeleteMutation,
  ThemeDeleteMutationVariables
>;
export const ZoomMeetingDocument = gql`
  query ZoomMeeting($id: ID!) {
    zoomMeeting(id: $id) {
      id
      meetingNo
      meetingPwd
      topic
      startTime
      data
    }
  }
`;

/**
 * __useZoomMeetingQuery__
 *
 * To run a query within a React component, call `useZoomMeetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomMeetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomMeetingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useZoomMeetingQuery(
  baseOptions: Apollo.QueryHookOptions<
    ZoomMeetingQuery,
    ZoomMeetingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZoomMeetingQuery, ZoomMeetingQueryVariables>(
    ZoomMeetingDocument,
    options,
  );
}
export function useZoomMeetingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ZoomMeetingQuery,
    ZoomMeetingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZoomMeetingQuery, ZoomMeetingQueryVariables>(
    ZoomMeetingDocument,
    options,
  );
}
export type ZoomMeetingQueryHookResult = ReturnType<typeof useZoomMeetingQuery>;
export type ZoomMeetingLazyQueryHookResult = ReturnType<
  typeof useZoomMeetingLazyQuery
>;
export type ZoomMeetingQueryResult = Apollo.QueryResult<
  ZoomMeetingQuery,
  ZoomMeetingQueryVariables
>;
export const OnCourseModuleUpdateDocument = gql`
  subscription onCourseModuleUpdate($id: ID!) {
    onCourseModuleUpdateSubscription(id: $id) {
      courseModule {
        ...courseModuleUnion
      }
    }
  }
  ${CourseModuleUnionFragmentDoc}
`;

/**
 * __useOnCourseModuleUpdateSubscription__
 *
 * To run a query within a React component, call `useOnCourseModuleUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCourseModuleUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCourseModuleUpdateSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnCourseModuleUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnCourseModuleUpdateSubscription,
    OnCourseModuleUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnCourseModuleUpdateSubscription,
    OnCourseModuleUpdateSubscriptionVariables
  >(OnCourseModuleUpdateDocument, options);
}
export type OnCourseModuleUpdateSubscriptionHookResult = ReturnType<
  typeof useOnCourseModuleUpdateSubscription
>;
export type OnCourseModuleUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnCourseModuleUpdateSubscription>;
export const OnRoomPresenceUpdateDocument = gql`
  subscription onRoomPresenceUpdate($channelName: ID!, $withUsers: Boolean!) {
    onRoomPresenceUpdateSubscription(channelName: $channelName) {
      roomSummary {
        channelName
        usersCount
        anonymousUsersCount
      }
      userAdded @include(if: $withUsers) {
        id
        displayName
        avatar
      }
      userRemoved @include(if: $withUsers) {
        id
        displayName
        avatar
      }
      bulkChange
    }
  }
`;

/**
 * __useOnRoomPresenceUpdateSubscription__
 *
 * To run a query within a React component, call `useOnRoomPresenceUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnRoomPresenceUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnRoomPresenceUpdateSubscription({
 *   variables: {
 *      channelName: // value for 'channelName'
 *      withUsers: // value for 'withUsers'
 *   },
 * });
 */
export function useOnRoomPresenceUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnRoomPresenceUpdateSubscription,
    OnRoomPresenceUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnRoomPresenceUpdateSubscription,
    OnRoomPresenceUpdateSubscriptionVariables
  >(OnRoomPresenceUpdateDocument, options);
}
export type OnRoomPresenceUpdateSubscriptionHookResult = ReturnType<
  typeof useOnRoomPresenceUpdateSubscription
>;
export type OnRoomPresenceUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnRoomPresenceUpdateSubscription>;
export const OnRoomPresenceMessageDocument = gql`
  subscription onRoomPresenceMessage($channelName: ID!) {
    onRoomPresenceMessageSubscription(channelName: $channelName) {
      type
      content
      user {
        id
        displayName
        avatar
      }
    }
  }
`;

/**
 * __useOnRoomPresenceMessageSubscription__
 *
 * To run a query within a React component, call `useOnRoomPresenceMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnRoomPresenceMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnRoomPresenceMessageSubscription({
 *   variables: {
 *      channelName: // value for 'channelName'
 *   },
 * });
 */
export function useOnRoomPresenceMessageSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnRoomPresenceMessageSubscription,
    OnRoomPresenceMessageSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnRoomPresenceMessageSubscription,
    OnRoomPresenceMessageSubscriptionVariables
  >(OnRoomPresenceMessageDocument, options);
}
export type OnRoomPresenceMessageSubscriptionHookResult = ReturnType<
  typeof useOnRoomPresenceMessageSubscription
>;
export type OnRoomPresenceMessageSubscriptionResult =
  Apollo.SubscriptionResult<OnRoomPresenceMessageSubscription>;
export const OnRoomPresenceTrackDocument = gql`
  subscription onRoomPresenceTrack($roomChannelName: ID!, $userId: ID) {
    onRoomPresenceTrack(roomChannelName: $roomChannelName, userId: $userId) {
      registered
    }
  }
`;

/**
 * __useOnRoomPresenceTrackSubscription__
 *
 * To run a query within a React component, call `useOnRoomPresenceTrackSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnRoomPresenceTrackSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnRoomPresenceTrackSubscription({
 *   variables: {
 *      roomChannelName: // value for 'roomChannelName'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnRoomPresenceTrackSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnRoomPresenceTrackSubscription,
    OnRoomPresenceTrackSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnRoomPresenceTrackSubscription,
    OnRoomPresenceTrackSubscriptionVariables
  >(OnRoomPresenceTrackDocument, options);
}
export type OnRoomPresenceTrackSubscriptionHookResult = ReturnType<
  typeof useOnRoomPresenceTrackSubscription
>;
export type OnRoomPresenceTrackSubscriptionResult =
  Apollo.SubscriptionResult<OnRoomPresenceTrackSubscription>;
export const OnCourseParticipationUpdateDocument = gql`
  subscription onCourseParticipationUpdate($id: ID!) {
    onCourseParticipationUpdateSubscription(id: $id) {
      deleted
      participation {
        id
        status
        partial
      }
    }
  }
`;

/**
 * __useOnCourseParticipationUpdateSubscription__
 *
 * To run a query within a React component, call `useOnCourseParticipationUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCourseParticipationUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCourseParticipationUpdateSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnCourseParticipationUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnCourseParticipationUpdateSubscription,
    OnCourseParticipationUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnCourseParticipationUpdateSubscription,
    OnCourseParticipationUpdateSubscriptionVariables
  >(OnCourseParticipationUpdateDocument, options);
}
export type OnCourseParticipationUpdateSubscriptionHookResult = ReturnType<
  typeof useOnCourseParticipationUpdateSubscription
>;
export type OnCourseParticipationUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnCourseParticipationUpdateSubscription>;
export const OnAllCourseParticipationUpdateDocument = gql`
  subscription onAllCourseParticipationUpdate($courseId: ID!) {
    onCourseParticipationUpdateSubscription(courseId: $courseId) {
      deleted
      participation {
        id
        status
        data
        dataChanges
        isOnline
        created
        modified
        course {
          id
        }
        formResponse {
          id
        }
        invitation {
          id
        }
        user {
          id
          avatar(size: 40)
          isAnonymous
          isStaff
          displayName
          email
        }
      }
    }
  }
`;

/**
 * __useOnAllCourseParticipationUpdateSubscription__
 *
 * To run a query within a React component, call `useOnAllCourseParticipationUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAllCourseParticipationUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAllCourseParticipationUpdateSubscription({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useOnAllCourseParticipationUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnAllCourseParticipationUpdateSubscription,
    OnAllCourseParticipationUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnAllCourseParticipationUpdateSubscription,
    OnAllCourseParticipationUpdateSubscriptionVariables
  >(OnAllCourseParticipationUpdateDocument, options);
}
export type OnAllCourseParticipationUpdateSubscriptionHookResult = ReturnType<
  typeof useOnAllCourseParticipationUpdateSubscription
>;
export type OnAllCourseParticipationUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnAllCourseParticipationUpdateSubscription>;
export const OnAllCourseInvitationUpdateDocument = gql`
  subscription onAllCourseInvitationUpdate($courseId: ID!) {
    onCourseInvitationUpdateSubscription(courseId: $courseId) {
      created
      deleted
      invitation {
        ...courseInvitationtNode
      }
    }
  }
  ${CourseInvitationtNodeFragmentDoc}
`;

/**
 * __useOnAllCourseInvitationUpdateSubscription__
 *
 * To run a query within a React component, call `useOnAllCourseInvitationUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAllCourseInvitationUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAllCourseInvitationUpdateSubscription({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useOnAllCourseInvitationUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnAllCourseInvitationUpdateSubscription,
    OnAllCourseInvitationUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnAllCourseInvitationUpdateSubscription,
    OnAllCourseInvitationUpdateSubscriptionVariables
  >(OnAllCourseInvitationUpdateDocument, options);
}
export type OnAllCourseInvitationUpdateSubscriptionHookResult = ReturnType<
  typeof useOnAllCourseInvitationUpdateSubscription
>;
export type OnAllCourseInvitationUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnAllCourseInvitationUpdateSubscription>;
export const OnQuestionUpdateDocument = gql`
  subscription onQuestionUpdate($id: ID!) {
    onQuestionUpdateSubscription(id: $id) {
      question {
        id
        question
        title
        description
        label
        slug
        type
        answers {
          edges {
            node {
              id
              data
            }
          }
        }
      }
    }
  }
`;

/**
 * __useOnQuestionUpdateSubscription__
 *
 * To run a query within a React component, call `useOnQuestionUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnQuestionUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnQuestionUpdateSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnQuestionUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnQuestionUpdateSubscription,
    OnQuestionUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnQuestionUpdateSubscription,
    OnQuestionUpdateSubscriptionVariables
  >(OnQuestionUpdateDocument, options);
}
export type OnQuestionUpdateSubscriptionHookResult = ReturnType<
  typeof useOnQuestionUpdateSubscription
>;
export type OnQuestionUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnQuestionUpdateSubscription>;
export const OnThemeUpdateDocument = gql`
  subscription onThemeUpdate($id: ID!) {
    onThemeUpdateSubscription(id: $id) {
      created
      deleted
      theme {
        ...theme
      }
    }
  }
  ${ThemeFragmentDoc}
`;

/**
 * __useOnThemeUpdateSubscription__
 *
 * To run a query within a React component, call `useOnThemeUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnThemeUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnThemeUpdateSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnThemeUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnThemeUpdateSubscription,
    OnThemeUpdateSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OnThemeUpdateSubscription,
    OnThemeUpdateSubscriptionVariables
  >(OnThemeUpdateDocument, options);
}
export type OnThemeUpdateSubscriptionHookResult = ReturnType<
  typeof useOnThemeUpdateSubscription
>;
export type OnThemeUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnThemeUpdateSubscription>;
